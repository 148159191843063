import {
  recordFields,
  recordActionFields,
  addressFields,
} from '@xbcb/shared-queries';
import { iorNumberFields, passportFields } from '@xbcb/work-order-queries';
import { inquiryFields } from './inquiryFields';

export const usIsfFragment = `
fragment usIsfFields on UsIsf {
  ...recordFields
  loadType
  group {
    id
    shipper {
      id
      status
      billingDetails {
        subscriptionPlan
        status
      }
    }
    shipment {
      id
      friendlyId
      clientIdentifier
      preferredDutyCollectPartyType
      version
      clientReferences {
        bookingId
      }
      tags {
        key
        value
      }
    }
    forwarders {
      forwarder {
        tags {
          key
          value
        }
        id
        billingDetails {
          subscriptionPlan
          status
        }
      }
    }
    invoices {
      id
      status
      number
      lines {
        type
        amount {
          value
          currency
        }
      }
    }
  }
  operator {
    id
  }
  isfNumber 
  manufacturers {
    products {
      htsNumber 
      originCountryCode
      product {
        id
        version
      }
    } 
    name
    address {
      ...addressFields
    }
    duns
    supplier {
      id
      version
    }
  } 
  masterBills {
    number
    billMatchStatus
    houseBills {
      number
      billMatchStatus
    }
  } 
  ior {
    iorNumber {
      ...iorNumberFields
    } 
    name
    passport { 
    ...passportFields
    }
    usIor {
      id
      version
      status
      continuousBonds {
        present {
          number
        }
      }
      addresses {
        physical {
          ...addressFields
        }
        mailing {
          ...addressFields
        }
      }
    }
  }
  portOfUnladingCode
  placeOfDelivery
  singleTransactionBond {
    suretyCode 
    bondNumber
    referenceNumber
  } 
  stuffingLocation {
    name
    address {
      ...addressFields
    }
    duns
    stuffingLocation {
      ... on Supplier {
        id
        version
      }
      ... on Facility {
        id
        version
      }
    }
  } 
  consolidator {
    name
    address {
      ...addressFields
    }
    duns
    consolidator {
      ... on Supplier {
        id
        version
      }
      ... on Facility {
        id
        version
      }
    }
  } 
  shipTo {
    name
    address {
      ...addressFields
    }
    duns
    firmsCode
    shipTo {
      id
      version
    }
  } 
  buyer {
    name
    address {
      ...addressFields
    }
    duns 
    ein
    sameAsIor
    buyer {
      id
      version
    }
  } 
  sellers {
    name
    address {
      ...addressFields
    }
    duns
    ein
    seller {
      id
      version
    }
  } 
  consignee {
    isIndividual
    iorNumber {
      ...iorNumberFields
    }
    sameAsIor
    usConsignee {
      id
      version
    }
  } 
  bookingParty {
    name
    address {
      ...addressFields
    }
    duns
    bookingParty {
      ... on Supplier {
        id
        version
      }
      ... on Forwarder {
        id
        version
      }
    }
  }
  cbpStatus
  status
  isfType
  shipmentTypeCode
  broker {
    usCustomsBroker {
      id
    }
  }
  informal {
    estimatedValue {
      value
      currency
    }
    estimatedQuantity {
      value
      unit
    }
    estimatedWeight {
      value
      unit
    }
  }
  milestones {
    id
    name
    occurenceTime
    source
    created {
      client {
        name
      }
    }
  }
  stateMachine {
    workOrderType
  }
  ${inquiryFields}
}
`;
export const usIsfFragments = `
${usIsfFragment}
${recordFields}
${recordActionFields}
${addressFields}
${passportFields}
${iorNumberFields}
`;

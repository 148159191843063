import {
  RecordType,
  AccountType,
  KeywordSearchOperator,
  DateSearchOperator,
} from '@xbcb/shared-types';
import {
  workOrderTaskFragments,
  workOrderTaskStepFragments,
} from '@xbcb/work-order-queries';
import { getIsIorActivated } from '@xbcb/party-utils';
import { UsIorActivation } from '../../loadableComponents';
import { WorkOrderRoute } from '.';
import {
  appRecordAbiDataMenuItem,
  appRecordAssignWorkOrderTaskMenuItem,
  createQueryUsIorKebabMenuItem,
  createUsIorCreate5106KebabMenuItem,
  createUsIorRequestCbpAssignedNumberKebabMenuItem,
  createUsIorUpdate5106KebabMenuItem,
  inquireWorkOrderItem,
  unblockWorkOrderItem,
} from '../utils';
import { usIorActivationFragments } from 'libs/sharedQueries';
import { WorkOrderStatus } from '@xbcb/work-order-types';
import { UsBusinessStructure } from '@xbcb/party-types';
import { get } from 'lodash';
import type { UsIor } from '@xbcb/api-gateway-client';
import { DocumentTag } from '@xbcb/document-types';
import { showValidationErrors, setModal } from '@xbcb/ui-utils';
import { executeMutation } from 'libs/executeMutation';
import { gql } from '@apollo/client';
import { client as apolloClient } from '@xbcb/apollo-client';
import { getOneQueryLite, SearchQuery } from '@xbcb/shared-queries';
import { validateAddress } from 'libs/validateAddress';
import { ModalKey } from '@xbcb/ui-types';
import { appRecordSendAbiMessage } from '../utils/appRecordSendAbiMessage';

const GET_US_IOR_VERSION = getOneQueryLite({
  recordName: RecordType.US_IOR,
  fields: 'version iorNumber { value }',
  queryName: 'getUsIorVersion',
});

const searchQueryFields = `
  id
  version
  operator {
    id
  }
  tags {
    key
    value
  }
  activations {
    status
    customsBroker {
      id
    }
  }
  activationStatus
`;
const searchQuery = SearchQuery({
  recordName: RecordType.US_IOR,
  fields: searchQueryFields,
});

const fields = `
  ...usIorActivationFields
  tasks {
    ...workOrderTaskFields
    assignee {
      id
      name
    }
    steps {
      id
      name
      description
      completed {
        time
      }
      isCompletedBySystem
      isFinalConfirmation
    }
    definition {
      id
      workOrderTaskType
    }
  }
`;

const updateUsIorActivationStatus = gql`
  mutation updateUsIorActivationStatus(
    $id: ID!
    $version: Int!
    $usCustomsBrokerId: ID!
    $activationStatus: ActivationStatus!
  ) {
    updateUsIorActivationStatus(
      id: $id
      version: $version
      usCustomsBrokerId: $usCustomsBrokerId
      activationStatus: $activationStatus
    ) {
      usIor {
        id
        version
      }
    }
  }
`;

const validatePoa = (usIor: UsIor) => {
  const additionalErrors = [];
  let hasDirect = false;
  let hasMaster = false;
  let hasSub = false;
  let hasCC = false;
  const { documents = [], businessStructure, addresses } = usIor || {};
  documents.forEach(({ documentTags = [], deleted, tags = [] }) => {
    const rejected = tags.find((tag) => tag.key === 'rejected');
    if (documentTags.length && !deleted && !rejected) {
      for (const documentTag of documentTags) {
        switch (documentTag) {
          case DocumentTag.DIRECT_POWER_OF_ATTORNEY: {
            hasDirect = true;
            break;
          }
          case DocumentTag.MASTER_POWER_OF_ATTORNEY: {
            hasMaster = true;
            break;
          }
          case DocumentTag.SUB_POWER_OF_ATTORNEY: {
            hasSub = true;
            break;
          }
          case DocumentTag.CORPORATE_CERTIFICATION: {
            hasCC = true;
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  });

  if (
    addresses?.mailing?.countryCode !== 'US' &&
    businessStructure &&
    ![
      UsBusinessStructure.INDIVIDUAL,
      UsBusinessStructure.SOLE_PROPRIETOR,
    ].includes(businessStructure as UsBusinessStructure) &&
    !hasCC
  ) {
    additionalErrors.push({
      title: 'Missing CC',
      messages: [
        `Importer is foreign and a ${businessStructure} so needs a Corporate Certification.`,
      ],
    });
  }
  if (!hasDirect && (!hasMaster || !hasSub)) {
    additionalErrors.push({
      title: 'Missing Poa',
      messages: [`Importer needs a Power of Attorney.`],
    });
  }
  return additionalErrors;
};

// Here we need to validate the fields that are not required on the US IOR's
// page but are required for activation
const validateActivationFields = (usIor: UsIor) => {
  const { addresses, industry, businessStructure } = usIor;

  const additionalErrors = [
    // First add the validations for addresses
    ...validateAddress({
      addressType: 'mailing',
      addressObject: addresses?.mailing,
    }),
    ...validateAddress({
      addressType: 'physical',
      addressObject: addresses?.physical,
    }),
  ];

  // vertical is required for ops, whatever fields are required for import
  // sign need to be validated here as well
  if (!industry) {
    additionalErrors.push({
      title: 'Missing Vertical',
      messages: ['Importer must have a vertical'],
    });
  }

  if (!businessStructure) {
    additionalErrors.push({
      title: 'Missing Business Structure',
      messages: ['Importer must have a business structure'],
    });
  }

  return additionalErrors;
};

const pathToUsIor = ['ior', 'usIor'];
const usIorActivationRoute: WorkOrderRoute = {
  Page: UsIorActivation,
  recordType: RecordType.US_IOR_ACTIVATION,
  fields,
  fragments: `${usIorActivationFragments}${workOrderTaskFragments}${workOrderTaskStepFragments}`,
  kebabMenuItems: [
    appRecordAbiDataMenuItem,
    appRecordSendAbiMessage,
    appRecordAssignWorkOrderTaskMenuItem,
    inquireWorkOrderItem,
    unblockWorkOrderItem,
    createQueryUsIorKebabMenuItem(pathToUsIor),
    createUsIorCreate5106KebabMenuItem(pathToUsIor),
    createUsIorUpdate5106KebabMenuItem(pathToUsIor),
    createUsIorRequestCbpAssignedNumberKebabMenuItem(pathToUsIor),
  ],
  submitButtons: [
    {
      key: 'activateUsIor',
      text: 'Activate',
      skipUpdateRecord: () => true,
      skipValidation: () => true,
      show: ({ existingRecord, currentUser }) => {
        const isOperator = currentUser.accountType === AccountType.OPERATOR;
        // If the WO is completed, blocked, or canceled, don't allow activation
        const invalidWorkOrderStatus = [
          WorkOrderStatus.COMPLETED,
          WorkOrderStatus.BLOCKED,
          WorkOrderStatus.CANCELED,
        ].includes(existingRecord?.status);
        const isUsIorActivated = getIsIorActivated({
          ior: existingRecord?.ior?.usIor,
          customsBrokerId: existingRecord?.broker?.usCustomsBroker?.id,
        });
        return (
          isOperator &&
          !isUsIorActivated &&
          !existingRecord.deleted?.time &&
          !invalidWorkOrderStatus &&
          get(existingRecord, [...pathToUsIor, 'iorNumber', 'value']) &&
          !get(existingRecord, [...pathToUsIor, 'cbp5106RejectionReasons'])
        );
      },
      onSubmit: async ({ updatedRecord, dispatch }) => {
        const usIor = updatedRecord?.ior?.usIor;
        const additionalErrors = [
          ...validateActivationFields(usIor),
          ...validatePoa(usIor),
        ];

        if (additionalErrors?.length) {
          showValidationErrors(additionalErrors);
          return;
        } else {
          const { data } = await apolloClient.query({
            query: GET_US_IOR_VERSION,
            variables: { id: usIor.id },
            errorPolicy: 'all',
          });
          const { version, iorNumber } = data?.usIor as UsIor;
          const iorNumberValue = iorNumber?.value;
          if (iorNumberValue) {
            const { data: searchData } = await apolloClient.query({
              query: searchQuery,
              variables: {
                input: {
                  searchCriteria: {
                    iorNumber: {
                      values: [iorNumberValue],
                      operator: KeywordSearchOperator.EQUALS,
                    },
                    deletedTime: {
                      operator: DateSearchOperator.DOES_NOT_EXIST,
                    },
                  },
                },
              },
              errorPolicy: 'all',
            });
            const { results } = searchData || {};
            const usIor = results?.[0] as UsIor | undefined;
            if (usIor) {
              const { id, name } = usIor;
              dispatch(
                setModal({
                  key: ModalKey.MARK_WORK_ORDER_READY,
                  props: {
                    visible: true,
                    existingImporterId: id,
                    existingImporterName: name,
                    // TODO in legacy we also passed the forwarder ID. Consider if we should pass it here as `linkForwarderId` or do it in the component?
                  },
                }),
              );
              // We should not proceed to try to activate the importer, we've
              // determined it should not be activated since there is already
              // an existing IOR that's activated
              return;
            }
          }

          await executeMutation({
            mutation: updateUsIorActivationStatus,
            successMessage: 'IOR Activation status updated',
            variables: {
              id: usIor?.id,
              version: version || usIor.version,
              usCustomsBrokerId: updatedRecord.broker.usCustomsBroker.id,
              activationStatus: 'ACTIVATED',
            },
          });
        }
      },
    },
  ],
};

export default usIorActivationRoute;

import {
  recordFields,
  recordActionFields,
  addressFields,
} from '@xbcb/shared-queries';
import {
  continuousBondsFields,
  usContinuousCustomsBondFragment,
} from '@xbcb/party-queries';

export const usIorContinuousBondRequestFragment = `
fragment usIorContinuousBondRequestFields on UsIorContinuousBondRequest {
  ...recordFields
  group {
    id
    shipper {
      id
      billingDetails {
        subscriptionPlan
        customFeeConfig {
					disbursement {
						rate
					}
				}
      }
      forwarders {
				nodes {
					id
					billingDetails {
						subscriptionPlan
            customFeeConfig {
              disbursement {
                rate
              }
            }
					}
				}
			}
    }
    forwarders {
      forwarder {
        id
        billingDetails {
          subscriptionPlan
          customFeeConfig {
            disbursement {
              rate
            }
          }
        }
        workOrderConfig {
          usIorContinuousBondRequest {
            invoiceShipper
          }
        }
      }
    }
    invoices {
      id
      version
      status
      number
      billToParty {
        id 
        name
      }
      documents {
				content { 
					downloadLink
				}
			}
      payments {
			  totalAmount {
				  value
				  currency
			  }
		  }
		  creditNotes{
			  status
			  lines{
				  amount {
					  value
					  currency
				  }
				  type
				  invoiceLineId
			  }
		  }
      total{
				value
				currency
			}
      lines {
        type
        amount {
          value
          currency
        }
      }
      accountingSystemDetails {
			  references {
				  type
				  value
			  }
      } 
      tags {
        key
        value
      }   
    }
  }
  operator {
    id
  }
  usIor {
    id
    operator {
      id
    }
    name
    addresses {
      physical {
        ...addressFields
      }
      mailing {
        ...addressFields
      }
    }
    iorNumber {
      type
      value
    }
   ${continuousBondsFields}
  }
  milestones {
    id
    name
    occurenceTime
    source
    created {
      client {
        name
      }
    }
  }
  stateMachine {
    id
    version
  }
  productDescriptions
  productOriginCountryCodes
  previousYearShippingValueEstimate
  nextYearShippingValueEstimate
  requestedBondAmount
  bondAmount
  bondNumber
  bondSuretyCode
  shipper {
    id
    name
    addresses {
      physical {
        ...addressFields
      }
      mailing {
        ...addressFields
      }
    }
  }
  risk {
    type
    human
    honey
    alcohol
    ecig
    tobacco
  }
  status
  renewalScheduleArn
  renewalCanceled {
    time
  }
}
`;

export const usIorContinuousBondRequestFragments = `
${usIorContinuousBondRequestFragment}
${recordFields}
${recordActionFields}
${addressFields}
${usContinuousCustomsBondFragment}
`;

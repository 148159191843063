import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { SingleFormCard } from '@xbcb/display-components';
import {
  SUBSCRIPTIONS_DISABLED_FEATURE,
  subscriptionsDisabledFeatureName,
} from '@xbcb/feature-flags';
import { AccountType, AnyObject } from '@xbcb/shared-types';
import { uiStageToBackendStage } from '@xbcb/ui-types';
import { useCurrentUser } from 'libs/hooks';
import { getEnv, safeGetMessage } from '@xbcb/ui-utils';
import CustomerPlanDetails from 'components/CustomerPlanDetails';
import CustomerFees from 'components/CustomerFees';
import { useBundle } from '@amzn/react-arb-tools';

const { stage } = getEnv();
const isSubscriptionsDisabled = SUBSCRIPTIONS_DISABLED_FEATURE.isEnabled(
  subscriptionsDisabledFeatureName,
  {
    stage: uiStageToBackendStage[stage],
    time: new Date().toISOString(),
  },
);

interface CustomerDetailsProps {
  record: AnyObject;
  form: FormInstance;
  disabled?: boolean;
  readOnly?: boolean;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({
  record,
  form,
  disabled,
  readOnly,
}) => {
  const [partyBundle] = useBundle('components.Party');
  const { accountType } = useCurrentUser();
  if (accountType !== AccountType.OPERATOR) return null;
  if (!record?.billingDetails?.subscriptionPlan) return null;
  return (
    <SingleFormCard title={safeGetMessage(partyBundle, 'customer_details')}>
      <CustomerPlanDetails
        isSubscriptionsDisabled={isSubscriptionsDisabled}
        record={record}
        disabled={disabled}
        readOnly={readOnly}
      />
      <CustomerFees
        isSubscriptionsDisabled={isSubscriptionsDisabled}
        disabled={disabled}
        readOnly={readOnly}
      />
    </SingleFormCard>
  );
};

export default CustomerDetails;

import React, { memo } from 'react';
import { isMonsEnv, getEnv } from '@xbcb/ui-env';
import { UiStage } from '@xbcb/ui-types';
import { useCurrentUser } from 'libs/hooks';
import { StyledAlert } from './styles';

const monsDomainMap = {
  [UiStage.GAMMA]: 'gamma.cbms.agl.amazon.dev',
  [UiStage.PROD]: 'cbms.agl.amazon.dev',
  [UiStage.LOCAL]: 'gamma.cbms.agl.amazon.dev',
};

const amazonEmailRegex = new RegExp(/@amazon\.com$/);

export const SSOBanner: React.FC = () => {
  const { stage } = getEnv();
  const isMons = isMonsEnv();
  const monsDomain = monsDomainMap[stage as UiStage.GAMMA | UiStage.PROD];
  const user = useCurrentUser();
  if (!amazonEmailRegex.test(user?.email)) return null;
  if (isMons || ![UiStage.GAMMA, UiStage.LOCAL].includes(stage)) return null;
  const message = `Amazon employees should now log in to CBMS with Midway at ${monsDomain}. Click here to redirect.`;
  return (
    <StyledAlert
      type="info"
      message={message}
      onClick={() =>
        (window.location.href = `https://${monsDomain}${window.location.pathname}`)
      }
    />
  );
};

export default memo(SSOBanner);

import { Col, Row } from 'antd';
import React from 'react';
import type { UsConsumptionEntryCensusWarning } from '@xbcb/api-gateway-client';
import { FormItem, Option, Select } from '@xbcb/form-item-components';
import { DataCyPrefix, NamePath, createDataCyValue } from '@xbcb/ui-types';
import {
  CENSUS_OVERRIDE_MAP,
  CENSUS_WARNING_MAP,
  OVERRIDE_CODE_REVERSE_LOOKUP,
  CensusWarningCode,
  WarningStatus,
  WorkOrderTaskType,
} from '@xbcb/work-order-types';
import { useWorkOrderTaskType } from 'libs/hooks';
import { Container, StyledRow } from './styles';

export interface LineItemCensusWarningsTabProps {
  warnings: UsConsumptionEntryCensusWarning[];
  localNamePath: NamePath;
  readOnly?: boolean;
}

const LineItemCensusWarningsTab: React.FC<LineItemCensusWarningsTabProps> = ({
  warnings,
  localNamePath,
  readOnly,
}) => {
  /**
   * The entire tab should be read only unless the operator is working
   * on the Census Warnings WOT. This is to prevent unauthorized operators
   * from submitting overrides to CBP, since only authorized operators will
   * be assigned to this task.
   *
   * TODO: use team structure to provide more precise team-based access to
   * this tab, regardless of whether the WOT is in progress.
   */
  const [taskType] = useWorkOrderTaskType();
  const censusWarningsReadOnly =
    readOnly ||
    taskType !== WorkOrderTaskType.US_CONSUMPTION_ENTRY_CENSUS_WARNING;
  return (
    <>
      <StyledRow>
        <Col span={8}>
          <b>Census Warning</b>
        </Col>
        <Col span={8}>
          <b>Census Override</b>
        </Col>
      </StyledRow>
      {warnings.map((warning, index) => (
        <CensusWarning
          key={warning.warningCode}
          warning={warning}
          readOnly={censusWarningsReadOnly}
          localNamePath={[
            ...localNamePath,
            'censusWarnings',
            index,
            'overrideCode',
          ]}
        />
      ))}
    </>
  );
};

interface CensusWarningProps {
  localNamePath: NamePath;
  warning: UsConsumptionEntryCensusWarning;
  readOnly?: boolean;
}

const CensusWarning: React.FC<CensusWarningProps> = ({
  localNamePath,
  readOnly: _readOnly,
  warning,
}) => {
  if (
    !warning.overrideCode &&
    warning.warningStatus === WarningStatus.RESOLVED
  ) {
    /* 
      In this case a warning has been resolved (not overridden)
      by updating the entry data elsewhere. We don't want
      to display these in the UI, but we can't filter them out earlier
      because the FormItem below relies on the index of the warning
      in the array to manage its state.
    */
    return null;
  }

  if (!warning.warningCode) {
    return null;
  }

  const warningKey = CensusWarningCode[warning.warningCode];

  const { description, overrideCodes } = CENSUS_WARNING_MAP[warningKey];

  const selectOptions = overrideCodes.map((option) => (
    <Option
      key={option}
      value={OVERRIDE_CODE_REVERSE_LOOKUP[option]}
      data-cy={createDataCyValue(
        DataCyPrefix.CENSUS_WARNING_SELECT,
        OVERRIDE_CODE_REVERSE_LOOKUP[option],
      )}
    >
      {CENSUS_OVERRIDE_MAP[option]}
    </Option>
  ));

  /**
   * If a warning has been overridden and marked as resolved by the backend,
   * it is displayed as read-only for auditing. A resolved warning without an
   * override code has already been filtered out by this point so won't be displayed.
   */
  const readOnly =
    _readOnly || warning.warningStatus === WarningStatus.RESOLVED;

  return (
    <Row align="middle">
      <Col span={8}>
        <Container>{description}</Container>
      </Col>
      <Col span={8}>
        <FormItem name={localNamePath} $readOnly={readOnly}>
          <Select
            allowClear
            disabled={readOnly}
            data-cy={DataCyPrefix.CENSUS_WARNING_SELECT}
          >
            {selectOptions}
          </Select>
        </FormItem>
      </Col>
    </Row>
  );
};

export default LineItemCensusWarningsTab;

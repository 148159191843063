import React from 'react';
import {
  forwarderUserFragments,
  shipperUserFragments,
} from '@xbcb/client-queries';
import { CrudPermissionName } from '@xbcb/client-types';
import type { User as ApiGUser } from '@xbcb/api-gateway-client';
import { AccountType, AnyObject, RecordType } from '@xbcb/shared-types';
import copyTextToClipboard from 'libs/copyToClipboard';
import { formatDate } from 'libs/display';
import { transformUser } from 'libs/formTransforms';
import { operatorUserFragments } from 'libs/sharedQueries';
import validateOperatorUser from 'libs/validateOperatorUser';
import { AppRecordStatusContentProps } from 'components/AppRecordStatus';
import { User } from '../loadableComponents';
import { AppRecordRoute } from '../types';
import { executeMutation } from 'libs/executeMutation';
import { gql } from '@apollo/client';
import { checkAccess } from '@xbcb/client-utils';

const userLoginStatusContent = ({
  record,
}: AppRecordStatusContentProps): AnyObject => {
  const user = record as ApiGUser;
  if (user.id && user.cognito?.id) {
    return { text: 'Logged in', iconType: 'check-circle' };
  }
  return {
    iconType: 'warning',
    text: `Pending log in, invite sent ${formatDate(user.created?.time)}`,
    extraContent: user.loginLink ? (
      <a
        rel="noopener noreferrer"
        onClick={() => copyTextToClipboard(user.loginLink)}
      >
        {' Copy link'}
      </a>
    ) : undefined,
  };
};

const reassignWorkOrderTask = gql`
  mutation reassignWorkOrderTask($id: ID!, $version: Int!) {
    reassignWorkOrderTask(operatorUserId: $id, operatorUserVersion: $version) {
      result
    }
  }
`;

const userRoutes: AppRecordRoute[] = [
  {
    Page: User,
    recordType: RecordType.OPERATOR_USER,
    fields: `...operatorUserFields`,
    fragments: operatorUserFragments,
    transformUpdateRecordInput: transformUser.toSchema,
    transformRecordToInput: transformUser.toForm,
    validateUpdate: validateOperatorUser,
    status: {
      show: ({ currentUser }): boolean => {
        return checkAccess(
          currentUser,
          RecordType.OPERATOR_USER,
          CrudPermissionName.ADMIN,
        );
      },
      content: userLoginStatusContent,
    },
    kebabMenuItems: [
      {
        key: 'reassignWot',
        text: 'Reassign Work Order Tasks',
        show: ({ currentUser }) =>
          currentUser.accountType === AccountType.OPERATOR &&
          checkAccess(
            currentUser,
            RecordType.WORK_ORDER_TASK,
            CrudPermissionName.ADMIN,
          ),
        action: async ({ record }) => {
          await executeMutation({
            mutation: reassignWorkOrderTask,
            variables: {
              id: record.id,
              version: record.version,
            },
            successMessage: `Starting to reassign work order tasks for ${record.name}`,
          });
        },
      },
    ],
  },
  {
    Page: User,
    recordType: RecordType.FORWARDER_USER,
    fields: `...forwarderUserFields`,
    fragments: forwarderUserFragments,
    transformUpdateRecordInput: transformUser.toSchema,
    transformRecordToInput: transformUser.toForm,
    status: {
      show: ({ currentUser }): boolean => {
        return checkAccess(
          currentUser,
          RecordType.FORWARDER_USER,
          CrudPermissionName.ADMIN,
        );
      },
      content: userLoginStatusContent,
    },
  },
  {
    Page: User,
    recordType: RecordType.SHIPPER_USER,
    fields: `...shipperUserFields`,
    fragments: shipperUserFragments,
    transformUpdateRecordInput: transformUser.toSchema,
    transformRecordToInput: transformUser.toForm,
    status: {
      show: ({ currentUser }): boolean => {
        return checkAccess(
          currentUser,
          RecordType.SHIPPER_USER,
          CrudPermissionName.ADMIN,
        );
      },
      content: userLoginStatusContent,
    },
  },
];

export default userRoutes;

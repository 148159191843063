import accounting from 'accounting';
import type { Cost } from '@xbcb/api-gateway-client';
import { getCurrencyCodes } from '@xbcb/ui-utils';

export const convertCostToString = (
  { currency, value }: Cost,
  decimals = 2,
): string => {
  const symbol = getCurrencyCodes()[currency].symbol;
  return accounting.formatMoney(value, symbol, decimals);
};

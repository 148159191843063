import { BiStringMap } from '@xbcb/js-utils';
import { DocumentTag } from '@xbcb/document-types';

export const documentTypeToSignLinkTypeMap = new BiStringMap({
  [DocumentTag.DIRECT_POWER_OF_ATTORNEY]: 'direct-poa',
  [DocumentTag.MASTER_POWER_OF_ATTORNEY]: 'master-poa',
  [DocumentTag.CORPORATE_CERTIFICATION]: 'cc',
  [DocumentTag.SUB_POWER_OF_ATTORNEY]: 'sub-poa',
});

export const documentTypeToDescriptionMap = new BiStringMap({
  [DocumentTag.DIRECT_POWER_OF_ATTORNEY]: 'Direct Power of Attorney',
  [DocumentTag.MASTER_POWER_OF_ATTORNEY]: 'Master Power of Attorney',
  [DocumentTag.CORPORATE_CERTIFICATION]: 'Corporate Certification',
  [DocumentTag.SUB_POWER_OF_ATTORNEY]: 'Sub Power of Attorney',
});

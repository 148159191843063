import { Form } from 'antd';
import styled, { css } from 'styled-components';
import { FormItem } from '@xbcb/form-item-components';
import { spaceLeft } from '@xbcb/ui-styles';
import { CssSpace } from '@xbcb/ui-types';

export const StyledForm = styled(Form)`
  display: inline-block;
  vertical-align: top;
  ${spaceLeft};
`;

export const activeInputStyle = css`
  border: 1px solid var(--primary-color);
`;

// Styles overwriting the default FormItem styles as we want it to match the
// AppRecordTitleEdit exactly (per business feedback) rather than a normal
// FormItem styles. Since it's overwriting the styles, we need to use
// `!important`
const formItemOverwrites = css`
  font-size: 20px !important;
  background-color: transparent !important;
`;

export const StyledFormItem = styled(FormItem)`
  && {
    display: inline-flex;
  }
  align-items: center;
  ${spaceLeft}
  min-width: ${CssSpace.SPACE_8};
  max-width: ${CssSpace.SPACE_9};

  .ant-select-selector {
    ${formItemOverwrites}
    box-sizing: border-box;
    font-weight: 600;
    text-overflow: ellipsis;
    padding: 2px;
    border: 1px solid transparent;

    &:hover {
      ${activeInputStyle}
    }
    &:focus {
      ${activeInputStyle}
    }

    input {
      font-size: 20px;
      font-weight: 600;
    }
  }
`;

export const StyledTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTitleSubTextSpan = styled.span`
  font-size: 12px;
`;

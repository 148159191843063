import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledDiv = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  margin: 0 ${CssSpace.SPACE_4};
`;

export const StyledSpan = styled.span`
  margin: ${CssSpace.SPACE_2};
`;

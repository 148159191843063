import { RecordType, BillingPartyAccountStatus } from '@xbcb/shared-types';
import { titleCase } from '@xbcb/js-utils';
import { gql } from '@apollo/client';

export const modifyPayeeAccount = (
  accountType: RecordType,
  queryFields: string,
  status: BillingPartyAccountStatus,
) =>
  gql`mutation updatePayeeAccount(
    $id: ID!
    $version: Int!
    $input: Update${titleCase(
      accountType,
    )}Input! = { billingDetails: { status: ${status} }}) {
           update${titleCase(
             accountType,
           )} (id: $id, version: $version, input: $input) {
              ${queryFields}
           }
    }
`;

import styled from 'styled-components';

export const StyledHeader = styled.h3`
  margin-left: ${({ theme }) => theme.spacing.space_3};
`;

export const StyledAccessionNumberDiv = styled.div`
  display: flex;
  align-items: center;
  .or {
    margin-left: ${({ theme }) => theme.spacing.space_4};
  }
`;

import { createReducer } from '@reduxjs/toolkit';
import { codeVersionChange } from '../actions';
import { CodeVersionState } from '../types';

const initialState: CodeVersionState = { versionId: '' };

export default createReducer(initialState, (builder) =>
  builder.addCase(codeVersionChange, (state, { payload }) => ({
    versionId: payload,
  })),
);

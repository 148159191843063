import React from 'react';
import { SimpleSelect } from '@xbcb/form-item-components';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { FormInstance } from 'antd/lib/form';
import { disabled } from 'components/TeamEvent/fixtures';
import { StyledFormItem } from './style';

interface MultipleSelectInputProps {
  loading?: boolean;
  allowClear?: boolean;
  notFoundContent?: string;
  disabled?: boolean;
  required?: boolean;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  form: FormInstance;
  label?: string;
  maxTagCount?: number;
  readOnly?: boolean;
  debounce?: boolean;
  onChange?: (val: any, option: any) => void;
  getValueFromEvent?: any;
  placeholder?: string;
}

// Warpper over SimpleSelect, used for accepting multiple text inputs from the user, rather than predefined values.
const MultipleSelectInput = ({
  loading = false,
  debounce = false,
  form,
  allowClear = true,
  notFoundContent = '',
  required = false,
  fullNamePath,
  localNamePath,
  label,
  onChange,
  getValueFromEvent,
  placeholder,
  maxTagCount,
}: MultipleSelectInputProps) => {
  return (
    <StyledFormItem
      $itemSize={CssSize.TINY_SHORT}
      $inline
      name={localNamePath}
      getValueFromEvent={getValueFromEvent}
      debounce={debounce}
      label={label}
      required={required}
      noStyle
    >
      <SimpleSelect
        placeholder={placeholder}
        loading={loading}
        showAction={['click', 'focus']}
        mode="tags"
        maxTagCount={maxTagCount}
        form={form}
        localNamePath={localNamePath}
        fullNamePath={fullNamePath}
        allowClear={allowClear}
        filterOption={() => true}
        notFoundContent={notFoundContent}
        disabled={disabled}
        required={required}
        selectOptions={[]}
        onChange={onChange}
      />
    </StyledFormItem>
  );
};

export default MultipleSelectInput;

import React from 'react';
import { titleCase } from 'libs/format';
import SubMenuOptions, { SubMenuOptionsProps } from 'components/SubMenuOptions';
import MOTIcon from 'components/MOTIcon';
import { ModeOfTransport } from '@xbcb/shipment-types';
import { AnyObject } from '@xbcb/shared-types';
import { StyledSpan } from './styles';

const mots = ['ocean', 'air', 'truck', 'rail'];

// IF THIS STRUCTURE, need to edit references in CreateShipmentButton and ShipmentActions
const MOTMenuOptions = ({
  action,
  record,
  ...restProps
}: SubMenuOptionsProps) => {
  const exclude = [record.conveyance?.modeOfTransport, record.modeOfTransport];
  const items = mots
    .filter((mot) => !exclude.includes(mot.toUpperCase()))
    .reduce((acc: AnyObject, mot) => {
      acc[mot] = {
        item: (
          <>
            <MOTIcon mot={mot.toUpperCase() as ModeOfTransport} />{' '}
            <StyledSpan>{titleCase(mot)}</StyledSpan>
          </>
        ),
      };
      return acc;
    }, {});
  return (
    <SubMenuOptions
      {...restProps}
      items={items}
      action={action}
      record={record}
    />
  );
};

export default MOTMenuOptions;

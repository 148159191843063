import { Tooltip } from 'antd';
import type { ExternalIntegrationReference } from '@xbcb/api-gateway-client';
import {
  ExternalIntegrationType,
  ExternalIntegrationPartner,
} from '@xbcb/shared-types';
import cargowise from 'assets/cargowise.png';
import { StyledImg, StyledSpan } from './styles';

type CargowiseIconProps = {
  externalIntegrationReferences: ExternalIntegrationReference[];
};

const CargowiseIcon: React.FC<CargowiseIconProps> = ({
  externalIntegrationReferences,
}) => {
  const isCwIntegration = externalIntegrationReferences?.some(
    ({ externalIntegrationType, externalIntegrationPartner }) =>
      externalIntegrationType ===
        ExternalIntegrationType.TRANSPORTATION_MANAGEMENT_SYSTEM &&
      externalIntegrationPartner === ExternalIntegrationPartner.CHAIN_IO,
  );
  if (!isCwIntegration) return null;

  return (
    <Tooltip title="Linked to CW1" placement="top">
      <StyledSpan>
        <StyledImg src={cargowise} alt="Linked to CargoWise One" />
      </StyledSpan>
    </Tooltip>
  );
};

export default CargowiseIcon;

import { isEqual } from 'lodash';
/** Importing from dist since we want to avoid importing Bindings (which uses 'fs' and is prohibited from the UI due to security concerns) */
import {
  usConsumptionEntryGenerator,
  UsConsumptionEntryLevel,
  UsConsumptionEntryPgaGenerator,
} from '@xbcb/work-order-utils/dist/workOrderGenerators';
import type { UsConsumptionEntry } from '@xbcb/api-gateway-client';
import { NamePath } from '@xbcb/ui-types';

// recordNamePathsWithRequiredPointOfContacts returns NamePath[] where each NamePath
// is the path to the record (for example `['ior']`) which allows
// easy access to the record info (ior) as well as the `pointOfContact`
const recordNamePathsWithRequiredPointOfContacts = (
  usConsumptionEntry: UsConsumptionEntry,
) => {
  const pgaIterator = usConsumptionEntryGenerator({
    entry: usConsumptionEntry,
    level: UsConsumptionEntryLevel.PGAS,
  }) as Generator<UsConsumptionEntryPgaGenerator>;
  const recordNamePathsWithRequiredPointOfContacts: NamePath[] = [];
  for (const pga of pgaIterator) {
    // pga is the following type: [UsConsumptionEntryPga](https://cbms-schema.inlt.com/usconsumptionentrypga.doc.html)
    if (pga.disclaimerCode) continue;
    if (pga.agencyCode === 'FDA') {
      if (pga.programCode !== 'FOO' || pga.processingCode === 'CCW') {
        const iorNamePathIncluded =
          recordNamePathsWithRequiredPointOfContacts.find((namePath) =>
            isEqual(namePath, ['ior']),
          );
        if (!iorNamePathIncluded) {
          // Don't add ior again if we already did.
          recordNamePathsWithRequiredPointOfContacts.push(['ior']);
        }
      } else {
        // TODO for FOO other than CCW, may need POC info for consignee for FSV program.
      }
    }
  }
  // TODO need to handle the case of some entity other than INLT previously filing PN info.
  return recordNamePathsWithRequiredPointOfContacts;
};

export default recordNamePathsWithRequiredPointOfContacts;

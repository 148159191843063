export const rateFields = `
  adValorem
  specific
  other 
  startDate
  endDate
`;

export const supplementalFieldFields = `
  required
  name
  code
  list
  description
`;

export const pgaProgramFields = `
  code
  name
  processingCodes {
    code
    name
  }
`;

export const principalAdCvdFields = `
  ... on UsHts {
    principalAdCvdCases {
      id
      countryCode
      name
      principalCaseNumber
      scopeUrl
      status
      adCvdCases {
        caseNumber
        status
        id
        manufacturerName
      }
    }
}`;

export const searchHtsBaseFields = `
ad
${principalAdCvdFields}
additionalHTS
cv
description 
endDate
fees {
  class
  type
  name
  rateCode
  rateString
  rate { 
    ${rateFields}
  }
  required 
}
gspExcluded
license {
  name
  code
}
number
pga {
  flag
  agencyCode
  programCodes
  programs {
    ${pgaProgramFields}
  }
  description
  required
  disclaimerCodes
  likelyDisclaimerCode
  likelyProgramCode
  likelyProcessingCode
  fields {
    ${supplementalFieldFields}
    fields {
      ${supplementalFieldFields}
    }
  }
}
quota
rate {
  ${rateFields}
}
rateCode
restriction {
  countryOfOriginEditCode
  entryDates {
    code
    beginDate
    endDate
  }
  quantityCode
  quantity {
    code
    highBounds
    lowBounds
  }
  value {
    code
    highBounds
  }
}
spi {
  code
  name
  country
  group
}
spiRates {
  adValorem
  specific
  other
  country
}
startDate
text
textile
truncatedDescription
uom
`;

export const additionalHtssApplicableFields = `
additionalHtssApplicable(effectiveDate: $effectiveDate) {
    type
    number
    include
    exclude
    hts {
      ${searchHtsBaseFields}
      additionalHtssApplicable {
        hts {
          ${searchHtsBaseFields}
        }
      }
    }
  }
`;

export const searchUsHtsFields = `
  ${searchHtsBaseFields}
  trumpList
  ${additionalHtssApplicableFields}
`;

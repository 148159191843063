import React from 'react';
import { useHistory } from 'react-router-dom';
import { StyledButton, StyledDiv } from './style';
import { useBundle } from '@amzn/react-arb-tools';
import { safeGetMessage } from '@xbcb/ui-utils';

const HelpVideosButton = () => {
  const [appHeaderBundle] = useBundle('components.AppHeader');
  const handleOptionClick = () => {
    history.push('/help');
  };

  const history = useHistory();
  return (
    <StyledDiv>
      <StyledButton disabled={false} onClick={handleOptionClick}>
        {safeGetMessage(appHeaderBundle, 'help_center')}
      </StyledButton>
    </StyledDiv>
  );
};

export default HelpVideosButton;

import React from 'react';
import { FormItem } from '@xbcb/form-item-components';
import { Input } from 'antd';
import { ConditionalOnRemarksCodes } from '../types';
import { VneRequirements } from 'libs/pgaHelpers/epa';

export const BondPolicyNumber: ConditionalOnRemarksCodes = ({
  localNamePath,
  disabled,
  importCode,
  required,
}) => {
  const shouldShow = VneRequirements.isBondPolicyNumberRequired(importCode);

  return shouldShow ? (
    <FormItem
      label={'Bond Policy Number'}
      name={[...localNamePath, 'vneBondNumber']}
      rules={[{ required }]}
    >
      <Input
        aria-label={'Bond Policy Number Textbox'}
        disabled={disabled}
        maxLength={BOND_POLICY_NUMBER_MAX_LENGTH}
      />
    </FormItem>
  ) : null;
};

const BOND_POLICY_NUMBER_MAX_LENGTH = 33;

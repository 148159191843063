import type { UsIorContinuousBondRequest } from '@xbcb/api-gateway-client';
import { isAGLForwarder } from '@xbcb/party-utils';

interface DetermineBusinessUnitArgs {
  currentBondNumber: string;
  usIorContinuousBondRequests: UsIorContinuousBondRequest[];
}

export const determineBusinessUnit = ({
  currentBondNumber,
  usIorContinuousBondRequests,
}: DetermineBusinessUnitArgs): string => {
  const usIorContinuousBondRequest: UsIorContinuousBondRequest | undefined =
    usIorContinuousBondRequests.find(
      ({ bondNumber }: UsIorContinuousBondRequest) =>
        bondNumber && bondNumber === currentBondNumber,
    );
  const isFbaContinuousBond =
    usIorContinuousBondRequest?.group.forwarders?.some((forwarder) =>
      isAGLForwarder(forwarder.forwarder),
    );
  let businessUnit = 'Other';
  if (usIorContinuousBondRequest) {
    businessUnit = 'INLT';
    if (isFbaContinuousBond) {
      businessUnit = 'FBA';
    }
  }
  return businessUnit;
};

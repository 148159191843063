import styled from 'styled-components';

export const StyledConstituentElementDiv = styled.div`
  .minus-button {
    vertical-align: top;
  }
`;

export const StyledFlexDiv = styled.div`
  display: flex;
`;

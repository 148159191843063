import { createReducer } from '@reduxjs/toolkit';
import {
  userLoginChange,
  avatarChange,
  userChangeType,
  logout,
} from '../actions';
import { UserLoginState } from '../types';
import { merge } from 'lodash';

const defaultUserLoginState: UserLoginState = {};
export default createReducer(defaultUserLoginState, (builder) =>
  builder
    .addCase(userLoginChange, (state, { payload }) => ({
      ...merge({}, state, payload),
    }))
    .addCase(avatarChange, (state, { payload }) => ({
      ...state,
      avatarUrl: payload,
    }))
    .addCase(userChangeType, (state, { payload }) => ({
      ...state,
      'custom:type': payload,
    }))
    .addCase(logout, (state, { payload }) => defaultUserLoginState),
);

import { CssSpace } from '@xbcb/ui-types';
import styled from 'styled-components';

export const StyledSubCategoryDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${CssSpace.SPACE_4};
  label {
    display: block;
  }
`;

export const StyledTextDiv = styled.div`
  display: flex;
  margin-bottom: ${CssSpace.SPACE_2};
`;

export const StyledDiv = styled.div`
  margin-bottom: ${CssSpace.SPACE_4};
`;

export const StyledAdditionalTextDiv = styled.div`
  display: flex;
  margin-bottom: ${CssSpace.SPACE_4};
  font-weight: lighter;
  font-size: small;
`;

export const StyledAsteriskSymbol = styled.div`
  display: inline;
  margin-right: ${CssSpace.SPACE_2};
  color: red;
  ::before {
    content: '*';
  }
`;

export const StyledSpan = styled.span`
  margin-left: ${CssSpace.SPACE_2};
  font-weight: bold;
`;

import moment from 'moment';
import type { WorkOrder } from '@xbcb/api-gateway-client';
import { AppRecordKebabMenuItem } from '../../types';
import csvBuilder from 'libs/csvBuilder';

export const downloadMilestonesMenuItem: AppRecordKebabMenuItem = {
  key: 'downloadMilestones',
  text: 'Download Milestones',
  confirmAction: true,
  show: ({ existingRecord }) => existingRecord.milestones.length,
  action: async ({ record }) => {
    const { milestones }: WorkOrder = record;
    const milestoneName = 'Milestone Name';
    const milestoneCreatedBy = 'Created By';
    const milestoneOccurrenceTime = 'Occurrence Time (UTC)';
    csvBuilder({
      headers: [milestoneName, milestoneCreatedBy, milestoneOccurrenceTime],
      data: milestones
        .sort((a, b) => moment(a.occurenceTime).diff(moment(b.occurenceTime))) // latest last.
        .map(({ name, created, occurenceTime }) => {
          return {
            [milestoneName]: name,
            [milestoneCreatedBy]: created.client?.name || '',
            [milestoneOccurrenceTime]: occurenceTime
              ? moment(occurenceTime).toISOString()
              : '',
          };
        }),
      fileName: 'milestones',
      returnFile: false,
    });
  },
};

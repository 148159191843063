import { uiStageToBackendStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import {
  FFCD_CUSTOMS_MILESTONES_FEATURE,
  FfcdCustomsMilestonesFeature,
} from '@xbcb/feature-flags';

export const isFfcdCustomsMilestonesEnabled =
  FFCD_CUSTOMS_MILESTONES_FEATURE.isEnabled(FfcdCustomsMilestonesFeature, {
    stage: uiStageToBackendStage[getEnv().stage],
  });

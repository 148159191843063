import styled from 'styled-components';
import { FormItem } from '@xbcb/form-item-components';
import { Col } from 'antd';

export const StyledFlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0;
`;

export const StyledSectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.font.size.medium};
  margin-top: ${({ theme }) => theme.spacing.space_4};
`;

export const StyledCol = styled(Col)`
  width: ${({ theme }) => theme.size.short_medium};
`;

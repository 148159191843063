import { capitalCase, pascalCase } from 'change-case';
import { formatRecordName } from '@xbcb/js-utils';
import { RecordType, AccountType } from '@xbcb/shared-types';
import { WorkOrderMilestoneName } from '@xbcb/work-order-types';
import { formatAbbreviation } from 'libs/formatAbbreviations';

// Note: The "pascalPrefix" corresponds with ButterCMS based off its current
// use case (specifically the corresponding "milestone" tooltip for the
// particular WO type). Thus, we shouldn't just pascal the result from
// formatRecordName otherwise we risk the naming convention being incorrect.
// NOTE: Currently all 7 WO types supported in the UI are handled in this lib:
// Entry, ISF, PSC, DO, In-Bond, IOR Activation, and Continuous Bond
export const formatWorkOrderMilestoneName = (
  milestoneName: WorkOrderMilestoneName,
  accountType: AccountType,
  type: 'capital' | 'pascal' = 'pascal',
): string => {
  let pascalPrefix = '';
  let recordType;
  let split = '';
  const pscMilestonePrefix = 'US_POST_SUMMARY_CORRECTION_';
  const continuousBondMilestonePrefix = 'US_IOR_CONTINUOUS_BOND_REQUEST_';
  const iorActivationMilestonePrefix = 'US_IOR_ACTIVATION_';
  const deliveryOrderMilestonePrefix = 'DELIVERY_ORDER_';
  const customsEntryMilestonePrefix = 'CUSTOMS_ENTRY_';
  const type86MilestonePrefix = 'US_TYPE86_ENTRY_';
  if (milestoneName.includes('ISF')) {
    pascalPrefix = 'isf';
    recordType = RecordType.US_ISF;
    split = 'US_ISF_';
  } else if (milestoneName.includes(customsEntryMilestonePrefix)) {
    // TODO this prefix is easily changeable as the tooltip helpers have not
    // been created in ButterCMS yet, we are just speculating the name here

    // TODO Please note, don't use `entry` as this would map to the US consumption entry Butter CMS tooltips
    // which have CBP specific wording in it. Thus, it would be incorrect for EU since there is no concept of CBP for those WOs.
    pascalPrefix = 'customsEntry';
    // We must pass a proper RecordType, so we will select GB_CUSTOMS_ENTRY.
    // It doesn’t matter which customs entrty we chose because `formatRecordName` will format them all to simply “Customs Entry”
    recordType = RecordType.GB_CUSTOMS_ENTRY;
    split = customsEntryMilestonePrefix;
  } else if (milestoneName.includes(type86MilestonePrefix)) {
    // this prefix is easily changeable as the tooltip helpers have not
    // been created in ButterCMS yet, we are just speculating the name here
    pascalPrefix = 'UsType86Entry';
    recordType = RecordType.US_TYPE86_ENTRY;
    split = type86MilestonePrefix;
  } else if (milestoneName.includes('ENTRY')) {
    pascalPrefix = 'entry';
    recordType = RecordType.US_CONSUMPTION_ENTRY;
    split = 'US_CONSUMPTION_ENTRY_';
    if (milestoneName.includes('BOND')) {
      split = 'US_CONSUMPTION_ENTRY_SINGLE_ENTRY_';
    }
  } else if (milestoneName.includes('IN_BOND')) {
    pascalPrefix = 'inBond';
    recordType = RecordType.US_IN_BOND;
    split = 'US_IN_BOND_';
  } else if (milestoneName.includes(pscMilestonePrefix)) {
    // TODO this prefix is easily changeable as the tooltip helpers have not
    // been created in ButterCMS yet, we are just speculating the name here
    pascalPrefix = 'psc';
    recordType = RecordType.US_POST_SUMMARY_CORRECTION;
    split = pscMilestonePrefix;
  } else if (milestoneName.includes(continuousBondMilestonePrefix)) {
    // TODO this prefix is easily changeable as the tooltip helpers have not
    // been created in ButterCMS yet, we are just speculating the name here
    pascalPrefix = 'continuousBond';
    recordType = RecordType.US_IOR_CONTINUOUS_BOND_REQUEST;
    split = continuousBondMilestonePrefix;
  } else if (milestoneName.includes(iorActivationMilestonePrefix)) {
    // TODO this prefix is easily changeable as the tooltip helpers have not
    // been created in ButterCMS yet, we are just speculating the name here
    pascalPrefix = 'iorActivation';
    recordType = RecordType.US_IOR_ACTIVATION;
    split = iorActivationMilestonePrefix;
  } else if (milestoneName.includes(deliveryOrderMilestonePrefix)) {
    // TODO this prefix is easily changeable as the tooltip helpers have not
    // been created in ButterCMS yet, we are just speculating the name here
    pascalPrefix = 'deliveryOrder';
    recordType = RecordType.DELIVERY_ORDER;
    split = deliveryOrderMilestonePrefix;
  } else {
    // Fallback incase a UI becomes newly supported in the UI but is not yet
    // handled in this lib. But, ideally we should add the use case above as we
    // risk handling the naming convention incorrectly to the user
    return type === 'pascal'
      ? pascalCase(milestoneName)
      : formatAbbreviation(capitalCase(milestoneName));
  }
  const milestoneNameSplit = milestoneName.split(split);
  const context = milestoneNameSplit[1];
  const newContext = context.toLowerCase().replace('cbp', 'customs');

  // If it's capitalCase we are displaying it to the user, thus we should
  // use formatRecordName instead of the default capitalCase functionality. If
  // it's pascal, use the pascalPrefix we have defined
  return type === 'pascal'
    ? `${pascalPrefix}${pascalCase(context)}`
    : `${formatRecordName({
        recordType,
        accountType,
      })} ${capitalCase(newContext)}`;
};

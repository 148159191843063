import styled from 'styled-components';

export const StyledTable = styled.table`
  margin-bottom: ${({ theme }) => theme.spacing.space_4};
  font-size: var(--font-size-16);
  vertical-align: top;
  thead,
  tbody {
    vertical-align: top;
  }
`;

export const StyledSpan = styled.span`
  text-decoration: underline;
`;

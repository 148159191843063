import { HeavyDutyVneValidator } from './heavyDutyVneValidator';
import { BaseVneValidator } from './baseVneValidator';
import { BondNumberValidator } from './bondNumberValidator';
import { TestGroupValidator } from './testGroupValidator';
import { EnginePowerRatingValidator } from './enginePowerRating';
import { ExemptionNumberValidator } from './exemptionNumberValidator';

export const registeredVneFieldValidators = [
  new HeavyDutyVneValidator(),
  new BaseVneValidator(),
  new BondNumberValidator(),
  new TestGroupValidator(),
  new EnginePowerRatingValidator(),
  new ExemptionNumberValidator(),
];

import styled from 'styled-components';
import { appSiderWidthStyle } from 'components/AppSider';

export const StyledSection = styled.section`
  ${appSiderWidthStyle};
  display: flex;
  justify-content: center;
  img {
    align-self: center;
    width: 120px;
    max-width: 125px;
    max-height: 50px;
  }
`;

import { gql, useMutation } from '@apollo/client';
import { Button, Divider, Form, Input, Modal, message } from 'antd';
import { constantCase } from 'change-case';
import { find } from 'lodash';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Document,
  DocumentTag,
  InquireWorkOrderInput,
  ProductLevelInquiryInput,
  WorkOrder,
  WorkOrderGroupForwarder,
  WorkOrderInquiryReasonCode,
  WorkOrderTask,
} from '@xbcb/api-gateway-client';
import { retryMutation } from '@xbcb/apollo-client';
import { getDocumentTagForRecordType } from '@xbcb/document-types';
import {
  FormItem,
  Option,
  Select,
  TagSelect,
} from '@xbcb/form-item-components';
import { codeToText } from '@xbcb/js-utils';
import { RecordType, AnyObject } from '@xbcb/shared-types';
import { CssSize, DataCyPrefix, createDataCyValue } from '@xbcb/ui-types';
import {
  formatDocumentTag,
  locationToObjectType,
  selectFilter,
  shouldUpdate,
  useModal,
  safeGetMessage,
} from '@xbcb/ui-utils';
import { WorkOrderTaskStatus } from '@xbcb/work-order-types';
import InquireWorkOrderProductLevelErrors from 'components/InquireWorkOrderProductLevelErrors';
import { fetchLatestRecordAndExecuteMutation } from 'libs/fetchLatestRecordAndExecuteMutation';
import { getProductIdentifiersFromEntry } from 'libs/getProductIdentifiersFromEntry';
import { useWorkOrderTaskType } from 'libs/hooks';
import { isBrexitWorkOrder } from 'libs/isBrexitWorkOrder';
import { updateWorkOrderTaskStatus } from 'libs/sharedQueries';
import { Entry, ModalKey } from 'types';
import {
  InquiryReasonCategoryMap,
  InquiryReasonCategory,
  reasonCategoryCodeMap,
  euAbacusReasonCategoryCodeMap,
  typeCategoryMap,
  cuDeReasonCategoryMap,
} from './inquireReasonCodes';
import { StyledHeader } from './styles';
import { isWorkOrderCuDe } from 'libs/isWorkOrderCuDe';
import { useBundle } from '@amzn/react-arb-tools';
import { getImportCountryCodeForCuDe } from '../../libs/getImportCountryCodeForCuDe';
import { CustomsDeclaration } from '__generated__/graphql';

interface InquireWorkOrderModalProps {
  workOrder?: WorkOrder;
  workOrderId?: string;
  documents?: Document[];
  workOrderVersion?: number;
  workOrderTasks?: WorkOrderTask[];
  forwarders?: WorkOrderGroupForwarder[];
}

export interface InquiryFormState {
  productLevelErrors?: AnyObject[];
  reasonCodes: WorkOrderInquiryReasonCode[];
  description: string;
  clarifyDocuments?: string[];
  attachDocuments?: string[];
  missingDocTags?: string[];
}

const inquireWorkOrderMutation = gql`
  mutation InquireWorkOrder(
    $version: Int!
    $input: InquireWorkOrderInput!
    $id: ID!
  ) {
    inquireWorkOrder(version: $version, input: $input, id: $id) {
      workOrder {
        id
        version
        status
      }
    }
  }
`;

const InquireWorkOrderModal: React.FC<InquireWorkOrderModalProps> = ({
  workOrderId,
  documents,
  workOrder,
  workOrderVersion,
  workOrderTasks,
}) => {
  const [documentsPageBundle] = useBundle('components.DocumentsPage');
  const [sharedBundle] = useBundle('shared');
  // If the work order isn't an entry & doesn't have invoices, this will be an empty array.
  const productIdentifierSuggestions = getProductIdentifiersFromEntry(
    workOrder as Entry,
  );
  const isBrexit = isBrexitWorkOrder(workOrder);
  const [form] = Form.useForm<InquiryFormState>();
  const [isLoading, setIsLoading] = useState(false);
  const [workOrderTaskType] = useWorkOrderTaskType();
  const {
    visible: isInquireWorkOrderModalVisible,
    modalProps,
    closeModal,
  } = useModal(ModalKey.INQUIRE_WORK_ORDER);
  const { pathname } = useLocation();
  const objectType = locationToObjectType(pathname);
  let reasonCategories = [];
  if (isBrexit) {
    reasonCategories = [InquiryReasonCategory.SHIPMENT_DATA];
  } else {
    reasonCategories = objectType ? typeCategoryMap[objectType] : [''];
  }
  const document = modalProps?.document;
  // only allowing the user to inquire for the document they chose
  const documentsList = document ? [document] : documents;
  const initialValues = document
    ? { clarifyDocuments: [document.id] }
    : undefined;

  const handleClose = () => {
    closeModal();
  };

  const getInquireWorkOrderInput = () => {
    const {
      reasonCodes,
      description,
      productLevelErrors,
      clarifyDocuments,
      attachDocuments,
      missingDocTags,
    } = form.getFieldsValue() || {};

    const input: InquireWorkOrderInput = {
      reasonCodes,
      description,
      clarifyDocuments: clarifyDocuments?.map((doc: string) => ({
        id: doc,
      })),
      attachDocuments: attachDocuments?.map((doc: string) => ({
        id: doc,
      })),
      requestDocuments: missingDocTags?.map((tag: string) =>
        constantCase(tag),
      ) as DocumentTag[] | undefined,
    };

    const productLevelInquiries: ProductLevelInquiryInput[] = [];

    const feedbacks = (productLevelErrors || []).reduce((acc, error) => {
      if (!acc[error.clientIdentifier]) acc[error.clientIdentifier] = [];
      if (error.reasonCodes?.[0] && error.suggestionToFix)
        acc[error.clientIdentifier].push({
          reasonCode: error.reasonCodes[0],
          suggestionToFix: error.suggestionToFix,
        });
      return acc;
    }, {} as Record<string, AnyObject>);

    for (const [skuFeedback, feedbackValue] of Object.entries(feedbacks)) {
      const { sku, skuVersion, asin } =
        productIdentifierSuggestions.find(({ sku }) => sku === skuFeedback) ||
        {};
      if (sku) {
        productLevelInquiries.push({
          clientIdentifier: sku,
          clientIdentifierVersion: skuVersion,
          asin,
          feedback: feedbackValue,
        });
      }
    }
    input.productLevelInquiries = productLevelInquiries;

    // on task page
    if (workOrderTaskType) {
      const task = workOrderTasks?.find(
        ({ definition }: WorkOrderTask) =>
          definition?.workOrderTaskType === workOrderTaskType,
      );
      if (task) input.task = { id: task.id };
    }
    return input;
  };

  const [inquire] = useMutation(inquireWorkOrderMutation, {
    onError: async (error) => {
      try {
        if (!workOrderId || !objectType) throw new Error();
        await retryMutation({
          fields: '',
          id: workOrderId,
          mutation: inquire,
          mutationType: 'update',
          mutationVariables: {
            id: workOrderId,
            version: workOrderVersion,
            input: getInquireWorkOrderInput(),
          },
          recordType: objectType as RecordType,
        });
      } catch (e) {
        message.error(
          safeGetMessage(sharedBundle, 'general_error_message'),
          5.0,
        );
      }
    },
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await form.validateFields();
    } catch (e) {
      message.error(safeGetMessage(sharedBundle, 'fill_required_fields'));
      return;
    }
    if (!workOrderId || !workOrderVersion) {
      message.error(
        safeGetMessage(documentsPageBundle, 'missing_work_order_id_or_version'),
      );
      return;
    }

    try {
      const input = getInquireWorkOrderInput();
      setIsLoading(true);
      await inquire({
        variables: {
          id: workOrderId,
          input,
          version: workOrderVersion,
        },
      });
      message.success(
        safeGetMessage(documentsPageBundle, 'successfully_inquired_work_order'),
      );
      // This mutation is added so that the UI re-renders with the updated task status.
      if (workOrderTask) {
        await fetchLatestRecordAndExecuteMutation({
          id: workOrderTask.id,
          fields: 'status',
          recordType: 'WORK_ORDER_TASK' as RecordType,
          mutation: updateWorkOrderTaskStatus,
          successMessage: safeGetMessage(
            documentsPageBundle,
            'work_order_task_no_longer_in_progress',
          ),
          constructVariablesWithIdVersion: (id, version) => ({
            id,
            version,
            input: {
              status: WorkOrderTaskStatus.BLOCKED,
              subStatus: null,
            },
          }),
        });
      }
      setIsLoading(false);
      handleClose();
    } catch (e) {
      // The `onError` handler above handles showing an error message
      setIsLoading(false);
    }
  };

  const reasonCategoryOptions = reasonCategories.map((category) => (
    <Option
      key={category}
      value={category}
      data-cy={createDataCyValue(
        DataCyPrefix.REASON_CATEGORIES_OPTION,
        category.toString(),
      )}
    >
      {codeToText(category as InquiryReasonCategory)}
    </Option>
  ));

  const documentOptions = documentsList?.map(
    ({ id, fileName, documentTags }) => (
      <Option key={id} value={id}>
        {fileName} (
        {formatDocumentTag(
          documentTags && documentTags.length > 0
            ? documentTags.join(', ')
            : safeGetMessage(documentsPageBundle, 'no_tags_yet'),
        )}
        )
      </Option>
    ),
  );

  const getReasonCodeOptions = () => {
    const reasonCodeMap: InquiryReasonCategoryMap = isBrexit
      ? euAbacusReasonCategoryCodeMap
      : isWorkOrderCuDe(workOrderId)
      ? cuDeReasonCategoryMap
      : reasonCategoryCodeMap;
    const selectedCategories: InquiryReasonCategory[] =
      form.getFieldValue(['reasonCategories']) || [];
    const reasonCodes = [];
    for (const category of selectedCategories) {
      reasonCodes.push(...(reasonCodeMap[category] || []));
    }
    return reasonCodes.map((code) => (
      <Option
        key={code}
        value={code}
        data-cy={createDataCyValue(
          DataCyPrefix.REASON_CODES_OPTION,
          code.toString(),
        )}
      >
        {codeToText(code)}
      </Option>
    ));
  };

  const countryCode = getImportCountryCodeForCuDe(
    workOrder as CustomsDeclaration | undefined,
  );
  const availableTags = objectType
    ? getDocumentTagForRecordType(objectType as RecordType, countryCode)
    : ([] as DocumentTag[]);

  const workOrderTask = find(
    workOrderTasks,
    (task) => task.definition.workOrderTaskType === workOrderTaskType,
  );

  return (
    <Modal
      destroyOnClose={true}
      maskClosable={false}
      open={isInquireWorkOrderModalVisible}
      width={816}
      closable={false}
      footer={[
        <Button onClick={handleClose} size="large" key="cancelInquireWorkOrder">
          {safeGetMessage(sharedBundle, 'cancel')}
        </Button>,
        <Button
          onClick={handleSubmit}
          type="primary"
          size="large"
          loading={isLoading}
          htmlType="submit"
          key="inquireWorkOrder"
        >
          {safeGetMessage(documentsPageBundle, 'send_inquiry')}
        </Button>,
      ]}
      onCancel={handleClose}
      // Need to use a className instead of data-cy since
      // it's a 3p function that takes args, rather than a
      // component that would thread down the prop
      className={'inquire-work-order-modal'}
    >
      <Form form={form} initialValues={initialValues}>
        <StyledHeader>
          {safeGetMessage(documentsPageBundle, 'request_more_information')}
        </StyledHeader>
        <div>
          <FormItem
            $itemSize={CssSize.SHORT_MEDIUM}
            $inline
            label={safeGetMessage(documentsPageBundle, 'reason_categories')}
            name={['reasonCategories']}
            rules={[
              {
                required: !isBrexit,
                message: safeGetMessage(
                  documentsPageBundle,
                  'at_least_one_category',
                ),
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              mode="multiple"
              filterOption={selectFilter}
              maxTagCount={1}
              maxTagTextLength={29}
              data-cy={createDataCyValue(DataCyPrefix.REASON_CATEGORIES)}
            >
              {reasonCategoryOptions}
            </Select>
          </FormItem>
          <Form.Item
            noStyle
            shouldUpdate={shouldUpdate([['reasonCategories']])}
          >
            {() => {
              const reasonCodeOptions = getReasonCodeOptions();
              return (
                <FormItem
                  $inline
                  $itemSize={CssSize.SHORT_MEDIUM}
                  label={safeGetMessage(documentsPageBundle, 'reason_codes')}
                  name={['reasonCodes']}
                  rules={[
                    {
                      required: !isBrexit,
                      message: safeGetMessage(
                        documentsPageBundle,
                        'at_least_one_code',
                      ),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    filterOption={selectFilter}
                    maxTagCount={1}
                    maxTagTextLength={29}
                    data-cy={createDataCyValue(
                      DataCyPrefix.INQUIRY_REASON_CODE,
                    )}
                  >
                    {reasonCodeOptions}
                  </Select>
                </FormItem>
              );
            }}
          </Form.Item>
        </div>
        <FormItem
          name={['description']}
          rules={[
            {
              required: !isBrexit,
              message: safeGetMessage(
                documentsPageBundle,
                'description_is_required',
              ),
            },
          ]}
          label={safeGetMessage(documentsPageBundle, 'description')}
        >
          <Input.TextArea
            autoSize
            maxLength={10000}
            data-cy={createDataCyValue(DataCyPrefix.DESCRIPTION)}
          />
        </FormItem>

        {isBrexit && (
          <InquireWorkOrderProductLevelErrors
            productIdentifierSuggestions={productIdentifierSuggestions}
          />
        )}

        {!isBrexit ? (
          <>
            <Divider>
              {safeGetMessage(documentsPageBundle, 'clarify_documents')}
            </Divider>
            <FormItem
              name={['clarifyDocuments']}
              label={safeGetMessage(documentsPageBundle, 'documents')}
            >
              <Select
                showSearch
                allowClear
                mode="multiple"
                filterOption={selectFilter}
                maxTagCount={1}
                maxTagTextLength={29}
              >
                {documentOptions}
              </Select>
            </FormItem>

            <Divider>
              {safeGetMessage(documentsPageBundle, 'attach_documents')}
            </Divider>
            <FormItem
              name={['attachDocuments']}
              label={safeGetMessage(documentsPageBundle, 'documents')}
            >
              <Select
                showSearch
                allowClear
                mode="multiple"
                filterOption={selectFilter}
                maxTagCount={1}
                maxTagTextLength={29}
              >
                {documentOptions}
              </Select>
            </FormItem>

            <Divider>
              {safeGetMessage(documentsPageBundle, 'missing_documents')}
            </Divider>
            <TagSelect
              size={CssSize.MEDIUM_LARGE}
              tags={availableTags}
              formatTag={formatDocumentTag}
              form={form}
              field={['missingDocTags']}
              maxTagCount={2}
            />
          </>
        ) : null}
      </Form>
    </Modal>
  );
};

export default InquireWorkOrderModal;

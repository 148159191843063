import React from 'react';
import { get } from 'lodash';
import { StyledObjectIconImg } from './styles';

import shipmentGray from './icons/raw/shipment.svg';
import shipmentColor from './icons/raw/shipment-color.svg';
import productGray from './icons/raw/product.svg';
import productColor from './icons/raw/product-color.svg';
import importerGray from './icons/raw/importer.svg';
import importerColor from './icons/raw/importer-color.svg';
import supplierGray from './icons/raw/supplier.svg';
import supplierColor from './icons/raw/supplier-color.svg';
import destinationGray from './icons/raw/destination.svg';
import destinationColor from './icons/raw/destination-color.svg';
import forwarderGray from './icons/raw/forwarder.svg';
import forwarderColor from './icons/raw/forwarder-color.svg';
import truckerGray from './icons/raw/trucker.svg';
import truckerColor from './icons/raw/trucker-color.svg';
import dashboardGray from './icons/raw/dashboard.svg';
import dashboardColor from './icons/raw/dashboard-color.svg';
import classificationGray from './icons/raw/classification.svg';
import classificationColor from './icons/raw/classification-color.svg';
import settingsGray from './icons/raw/settings.svg';
import settingsColor from './icons/raw/settings-color.svg';
import invoiceGray from './icons/raw/invoice.svg';
import invoiceColor from './icons/raw/invoice-color.svg';
import amsQueryGray from './icons/raw/amsQuery.svg';
import amsQueryColor from './icons/raw/amsQuery-color.svg';
import utilitiesGray from './icons/raw/utilities.svg';
import utilitiesColor from './icons/raw/utilities-color.svg';

// Did this so we don't have lots of redundant files, but this isn't a good solution.
const iconMap = {
  dashboard: { gray: dashboardGray, color: dashboardColor },
  settings: { gray: settingsGray, color: settingsColor },
  shipments: { gray: shipmentGray, color: shipmentColor },
  products: { gray: productGray, color: productColor },
  importers: { gray: importerGray, color: importerColor },
  suppliers: { gray: supplierGray, color: supplierColor },
  destinations: { gray: destinationGray, color: destinationColor },
  forwarders: { gray: forwarderGray, color: forwarderColor },
  truckers: { gray: truckerGray, color: truckerColor },
  classifications: { gray: classificationGray, color: classificationColor },
  hts: { gray: classificationGray, color: classificationColor },
  consignees: { gray: importerGray, color: importerColor },
  users: { gray: importerGray, color: importerColor },
  invoices: { gray: invoiceGray, color: invoiceColor },
  amsQueries: { gray: amsQueryGray, color: amsQueryColor },
  utilities: { gray: utilitiesGray, color: utilitiesColor },
};

interface ObjectIconProps {
  recordType: string;
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
  height?: number;
}

const ObjectIcon = ({
  recordType,
  active = true,
  onClick,
  height,
}: ObjectIconProps) => {
  if (!recordType) return null;
  const image = active
    ? get(iconMap, [recordType, 'color'])
    : get(iconMap, [recordType, 'gray']);
  if (!image) return null;
  return (
    <StyledObjectIconImg
      $height={height}
      src={image}
      onClick={onClick}
      alt=""
    />
  );
};

export default ObjectIcon;

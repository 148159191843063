import { connect } from 'react-redux';
import Emails from './component';
// import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Emails);

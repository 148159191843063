import { WorkOrderMilestoneName } from '@xbcb/work-order-types';

export const warning = 'red';
export const success = 'green';
export const pending = 'blue';
export const inactive = 'gray';

export const workOrderMilestoneToColorMap = {
  [WorkOrderMilestoneName.US_ISF_BOND_ACQUISITION_SUCCEEDED]: success,
  [WorkOrderMilestoneName.US_ISF_BOND_ACQUISITION_REQUESTED]: pending,
  [WorkOrderMilestoneName.US_ISF_BOND_ACQUISITION_FAILED]: warning,
  [WorkOrderMilestoneName.WORK_ORDER_READY]: success,
  [WorkOrderMilestoneName.WORK_ORDER_ASSIGNED]: success,
  [WorkOrderMilestoneName.WORK_ORDER_BLOCKED]: warning,
  [WorkOrderMilestoneName.US_ISF_CREATED]: success,
  [WorkOrderMilestoneName.US_ISF_DELETED]: warning,
  [WorkOrderMilestoneName.US_ISF_FILED]: success,
  [WorkOrderMilestoneName.US_ISF_REJECTED]: warning,
  [WorkOrderMilestoneName.US_ISF_SUBMITTED]: success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_CREATED]: success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_DELETED]: warning,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_FILED]: success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_REJECTED]: warning,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_SUBMITTED]: success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_DUTY_CONFIRMED]: success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_DUTY_CONFIRMATION_REQUESTED]:
    pending,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_SINGLE_ENTRY_BOND_ACQUISITION_SUCCEEDED]:
    success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_SINGLE_ENTRY_BOND_ACQUISITION_REQUESTED]:
    pending,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_SINGLE_ENTRY_BOND_ACQUISITION_FAILED]:
    warning,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_CBP_RELEASE]: success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_CBP_EXAM]: warning,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_CBP_HOLD]: warning,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_CBP_NOTIFICATION]: pending,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_PGA_RELEASE]: success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_PGA_HOLD]: warning,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_PAYMENT_AUTHORIZED]: success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_DAILY_STATEMENT_FINALIZED]:
    success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_MONTHLY_STATEMENT_FINALIZED]:
    success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_LIQUIDATED]: success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_RE_LIQUIDATED]: success,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_LIQUIDATION_EXTENDED]: pending,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_LIQUIDATION_SUSPENDED]: pending,
  [WorkOrderMilestoneName.US_IN_BOND_CREATED]: success,
  [WorkOrderMilestoneName.US_IN_BOND_SUBMITTED]: success,
  [WorkOrderMilestoneName.US_IN_BOND_AUTHORIZED]: success,
  [WorkOrderMilestoneName.US_IN_BOND_ARRIVED]: success,
  [WorkOrderMilestoneName.US_IN_BOND_BILL_ARRIVED]: success,
  [WorkOrderMilestoneName.US_IN_BOND_CONTAINER_ARRIVED]: success,
  [WorkOrderMilestoneName.US_IN_BOND_EXPORTED]: success,
  [WorkOrderMilestoneName.US_IN_BOND_BILL_EXPORTED]: success,
  [WorkOrderMilestoneName.US_IN_BOND_CONTAINER_EXPORTED]: success,
  [WorkOrderMilestoneName.US_IN_BOND_TRANSFERRED]: success,
  [WorkOrderMilestoneName.US_IN_BOND_DIVERSION]: success,
  [WorkOrderMilestoneName.US_IN_BOND_DELETED]: warning,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_PREPAID_INVOICE_CREATED]:
    pending,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_PAYMENT_MISSED_FIRST_STRIKE]:
    warning,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_PAYMENT_MISSED_SECOND_STRIKE]:
    warning,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_PAYMENT_MISSED_ACTIONED]:
    warning,
  [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_PMS_PAYMENT_MISSED_ACTIONED]:
    warning,
  [WorkOrderMilestoneName.CUSTOMS_ENTRY_DOCUMENTS_REVIEWED]: success,
};

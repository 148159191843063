import styled from 'styled-components';
import { InlineTooltip } from '@xbcb/feedback-components';

// TODO We should try to avoid vendor prefixes (which is what we're doing
// when we take `verticalAlign` as a prop typically). Does the styling look
// the same in Chrome (uses Webkit) + Firefox (uses Gecko).
// It's possible that the W3C standard vertical-align: sub would work. If
// the styling does appear different in FF vs Chrome, but we need the
// vendor prefix, Gecko has vertical-align: -moz-middle-with-baseline.
// Check out this SO thread about this particular property: https://stackoverflow.com/questions/45852388/webkit-baseline-middle-and-moz-middle-with-baseline
export const StyledDiv = styled.div<{
  $inline?: boolean;
}>`
  ${({ $inline }) => $inline && 'display: inline-block;'}
  vertical-align: -webkit-baseline-middle;
  vertical-align: -moz-middle-with-baseline;
`;

export const StyledTooltip = styled(InlineTooltip)`
  display: inline-block;
`;

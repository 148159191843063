import React from 'react';
import { RocketOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import './NewBundleAvailable.less';
import { useBundle } from '@amzn/react-arb-tools';
import { safeGetMessage } from '@xbcb/ui-utils';

const NewBundleAvailable = () => {
  const [newBundleAvailableBundle] = useBundle('components.NewBundleAvailable');
  return (
    <div className="new-bundle button-wrapper">
      <Tooltip
        title={safeGetMessage(newBundleAvailableBundle, 'new_update_available')}
      >
        <Button
          icon={<RocketOutlined />}
          shape="circle"
          onClick={() => window.location.reload()}
        />
      </Tooltip>
    </div>
  );
};

export default NewBundleAvailable;

import { AccountType } from '@xbcb/shared-types';
import { KebabMenuItemKey } from '@xbcb/ui-types';
import { openModal } from 'actions';
import { ModalKey } from 'types';
import { AppRecordKebabMenuItem } from '../../types';
import { checkKebabMenuItemForWorkOrderTask } from './checkKebabMenuItemForWorkOrderTask';

export const appRecordAbiDataMenuItem: AppRecordKebabMenuItem = {
  text: 'ABI Data',
  key: KebabMenuItemKey.ABI_DATA,
  action: ({ dispatch }) => {
    dispatch(openModal({ key: ModalKey.TEAM_DATA }));
  },
  show: ({ currentUser, workOrderTaskType, workOrderTaskEvaluatedState }) =>
    currentUser.accountType === AccountType.OPERATOR &&
    checkKebabMenuItemForWorkOrderTask(
      workOrderTaskType,
      workOrderTaskEvaluatedState,
      KebabMenuItemKey.ABI_DATA,
    ),
};

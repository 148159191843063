import React from 'react';

import { PreferredDutyCollectPartyType } from '@xbcb/finance-types';
import SubMenuOptions, { SubMenuOptionsProps } from 'components/SubMenuOptions';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { getEnv } from '@xbcb/ui-utils';
import { getCustomerId } from '@xbcb/ar';
import { getRecordType } from '@xbcb/core';
const { stage } = getEnv();

const DutyCollectionPartyOptions = ({
  action,
  record,
  ...restProps
}: SubMenuOptionsProps) => {
  const parties = [
    PreferredDutyCollectPartyType.CUSTOMS,
    PreferredDutyCollectPartyType.FORWARDER,
  ];
  let shipper;
  const recordType = getRecordType(record.id);
  if (recordType === RecordType.SHIPMENT) {
    shipper = record.workOrderGroup?.shipper;
  } else {
    shipper = record.group?.shipper;
  }
  // Show BROKER option only if BROKER(non-CN shipper) is onboarded with AR
  if (getCustomerId(shipper)) {
    parties.push(PreferredDutyCollectPartyType.BROKER);
  }

  const items = parties.reduce((acc: AnyObject, party) => {
    acc[party] = {
      item: (
        <>
          <span> {party}</span>
        </>
      ),
    };
    return acc;
  }, {});

  return (
    <SubMenuOptions
      {...restProps}
      items={items}
      action={action}
      record={record}
    />
  );
};

export default DutyCollectionPartyOptions;

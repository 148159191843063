import type { ProductSecondaryIdentifier } from '@xbcb/api-gateway-client';
import { transformLines } from './workOrder/usConsumptionEntry';

export const transformProduct = {
  toForm: ({ existingRecord: newRecord }: { existingRecord: any }) => {
    transformLines.toForm({
      lines: newRecord.complianceDetails?.us?.entryLines,
    });
    return newRecord;
  },

  toCreateSchema: ({ input }: { input: any }) => {
    input.secondaryIdentifiers = input.secondaryIdentifiers?.filter(
      (si: ProductSecondaryIdentifier) => si.value,
    );
    transformLines.toSchema({ lines: input.complianceDetails?.us?.entryLines });
    return input;
  },

  toUpdateSchema: ({ input }: { input: any }) => {
    input.secondaryIdentifiers = input.secondaryIdentifiers?.filter(
      (si: ProductSecondaryIdentifier) => si.value,
    );
    transformLines.toSchema({ lines: input.complianceDetails?.us?.entryLines });
    delete input.shipper;
    return input;
  },
};

import { useQuery } from '@apollo/client';
import { useCurrentUser } from 'libs/hooks';
import { get } from 'lodash';
import React from 'react';
import badReleaseCodes from 'libs/badReleaseCodes';
import copyToClipboard from 'libs/copyToClipboard';
import titleCase from 'libs/titleCase';
import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import type { UsConsumptionEntry } from '@xbcb/api-gateway-client';
import { AccountType, RecordType, TagKey } from '@xbcb/shared-types';
import { getReleaseCodeNames } from '@xbcb/ui-utils';
import { getBadgeIcons } from 'components/AppRecordBadge';
import WorkOrderCensusWarning from 'components/WorkOrderCensusWarning';
import MOTIcon from 'components/MOTIcon';
import { getWorkOrderBadges } from 'libs/getRecordBadges';
import { AppRecordSecondaryHeaderContentProps } from 'routes';
import {
  StyledTag,
  StyledDiv,
  StyledSpan,
  StyledWarningOutline,
} from './styles';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';
import { paramCase } from 'change-case';
import { getOneQueryLite, getRecordFromResponse } from '@xbcb/shared-queries';

const UsConsumptionEntrySecondaryHeader: React.FC<
  AppRecordSecondaryHeaderContentProps
> = ({ record }) => {
  const { accountType } = useCurrentUser();
  const releaseCodeNames = getReleaseCodeNames();
  const motTitle = titleCase(record?.conveyance?.modeOfTransport);
  const releaseIssues =
    record?.releaseDispositions &&
    record?.releaseDispositions?.filter((x: { code: string }) =>
      badReleaseCodes.includes(x.code),
    );
  const badgeIcons = getBadgeIcons(record, getWorkOrderBadges);
  // const notes = get(data, 'objectNotes.notes', []).filter(
  //   (note) => !note.deleted,
  // );
  // const borderBeeIcon = (
  //   <Tooltip title="Canadian shipment" placement="top">
  //     <img src={canada} alt="Canadian Entry" />
  //   </Tooltip>
  // );

  const originalId = (record as UsConsumptionEntry)?.tags?.find(
    (tag) => tag.key === TagKey.CLONED_FROM_WO_ID,
  )?.value;
  const entryNumberResponse = useQuery(
    getOneQueryLite({
      recordName: RecordType.US_CONSUMPTION_ENTRY,
      fields: 'entryNumber',
      queryName: 'getEntryNumber',
    }),
    {
      variables: {
        id: originalId,
      },
      skip: !originalId,
    },
  );
  const OriginalEntryTag = () => {
    return originalId && !entryNumberResponse.loading ? (
      <Tooltip title="Go to Original Entry">
        <StyledTag key="original-entry">
          <Link
            to={`/${pluralize(
              paramCase(RecordType.US_CONSUMPTION_ENTRY),
            )}/${originalId}`}
          >
            {`Replacement for Entry Number: ${
              getRecordFromResponse(
                entryNumberResponse,
                'get',
                RecordType.US_CONSUMPTION_ENTRY,
              ).entryNumber
            }`}
          </Link>
        </StyledTag>
      </Tooltip>
    ) : null;
  };

  return (
    <StyledDiv>
      <Tooltip title="Copy the Entry URL" placement="top">
        <LinkOutlined onClick={() => copyToClipboard(window.location.href)} />
      </Tooltip>
      <Tooltip title={motTitle} placement="top">
        <StyledSpan>
          <MOTIcon mot={record?.conveyance?.modeOfTransport} size="18" />
        </StyledSpan>
      </Tooltip>
      {badgeIcons}
      {/* accountType === AccountType.OPERATOR && customerType && (
        <Tooltip title={titleCase(customerType) + ' Customer'} placement="top">
          <span className="margin-icon">
            <ObjectIcon
              className="custom-type-icon"
              recordType={customerType + 's'}
              size="16"
            />
          </span>
        </Tooltip>
      )*/}
      {/* cwShipmentId && (
        <Tooltip title="Linked to CW1" placement="top">
          <img
            src={cargowise}
            alt="Linked to CargoWise One"
          />
        </Tooltip>
      )*/}
      {/* borderBeeId &&
        (accountType === AccountType.OPERATOR ? (
          <a target="_blank" rel="noopener noreferrer" href={borderBeeLink}>
            {borderBeeIcon}
          </a>
        ) : (
          borderBeeIcon
        ))*/}
      {/* personalEffects && (
        <Tooltip
          title="Personal effects"
          placement="top"
          className="space-left"
        >
          <ShoppingOutlined />
        </Tooltip>
      )*/}
      {/* entryType === '06' && (
        <Tooltip
          title="Free-trade zone (FTZ)"
          placement="top"
          className="space-left"
        >
          <InteractionOutlined />
        </Tooltip>
      )*/}
      {/* canceled && (
        <Tooltip
          title="Shipment canceled"
          placement="top"
          className="space-left"
        >
          <CloseCircleFilled className="error-icon" />
        </Tooltip>
      )*/}
      {/* vertical && (
        <Tag closable={false} key={vertical} className="shipment-tag">
          {verticalCodes[vertical]}
        </Tag>
      )*/}
      {/* notes && notes.length > 0 && (
        <Tag
          color="red"
          onClick={() => toggleVisibility('notes')}
          className="shipment-tag notes-tag"
        >{`${notes.length} ${pluralize('note', notes.length)}`}</Tag>
      )*/}
      {/* accountType === AccountType.OPERATOR && pendingBond && !bond && (
        <Tooltip
          title={
            <div>
              Pending continuous bond since{' '}
              {moment
                .utc(pendingBond)
                .local()
                .calendar()}
            </div>
          }
          placement="top"
        >
          <StyledWarningOutline  />
        </Tooltip>
      )*/}
      {accountType === AccountType.OPERATOR &&
        !record?.deleted &&
        ['DELETED', 'REJECTED'].includes(record?.summaryStatus) && (
          <Tooltip
            title={<div>Entry Summary currently DELETED</div>}
            placement="top"
          >
            <StyledWarningOutline />
          </Tooltip>
        )}
      {accountType === AccountType.OPERATOR &&
        record?.deleted &&
        record?.summaryStatus === 'FILED' && (
          <Tooltip
            title={<div>Entry Summary currently FILED, should be DELETED</div>}
            placement="top"
          >
            <StyledWarningOutline />
          </Tooltip>
        )}
      {accountType === AccountType.OPERATOR &&
        record?.deleted &&
        record?.releaseStatus !== 'DELETED' && (
          <Tooltip
            title={
              <div>
                Entry Release is {record?.releaseStatus}, should be DELETED
              </div>
            }
            placement="top"
          >
            <StyledWarningOutline />
          </Tooltip>
        )}
      {accountType === AccountType.OPERATOR &&
        record?.summaryRejectionReasons && (
          <Tooltip
            title={
              <div>
                <p className="space-bottom">Entry Summary (AE/AX) rejected:</p>
                {record?.summaryRejectionReasons.map(
                  (r: { name: string; code: string }, i: number) => (
                    <p key={i}>{r.name}</p>
                  ),
                )}
              </div>
            }
            placement="top"
          >
            <StyledWarningOutline />
          </Tooltip>
        )}
      {accountType === AccountType.OPERATOR &&
        record?.releaseRejectionReasons && (
          <Tooltip
            title={
              <div>
                <p className="space-bottom">Entry Release (SE/SX) rejected:</p>
                {record?.releaseRejectionReasons.map(
                  (r: { name: string; code: string }, i: number) => (
                    <p key={i}>{r.name}</p>
                  ),
                )}
              </div>
            }
            placement="top"
          >
            <StyledWarningOutline />
          </Tooltip>
        )}
      {accountType === AccountType.OPERATOR &&
      releaseIssues &&
      releaseIssues.length ? (
        <Tooltip
          title={
            <div>
              <p className="space-bottom">Entry Release (SO) issues:</p>
              {record?.releaseDispositions.map(
                (r: { billNumber?: string; code: string }, i: number) => (
                  <p key={i}>
                    {r.billNumber ? `${r.billNumber} ` : ''}
                    {get(releaseCodeNames, r.code, r.code)}
                  </p>
                ),
              )}
            </div>
          }
          placement="top"
        >
          <StyledWarningOutline />
        </Tooltip>
      ) : null}
      <WorkOrderCensusWarning record={record as UsConsumptionEntry} />
      {/* requestedDocTags && requestedDocTags.length ? (
        <Tooltip
          title={
            <div>
              <p className="space-bottom">Requested documents:</p>
              {requestedDocTags.map((x, i) => (
                <p key={i}>{x}</p>
              ))}
            </div>
          }
          placement="top"
        >
          <InfoOutlined className="info-icon" />
        </Tooltip>
      ) : null*/}
      {record?.pgaDispositions?.length ? (
        <Tooltip
          title={
            <div>
              <p className="space-bottom">PGA release status:</p>
              {record?.pgaDispositions?.map(
                ({ code, status }: { code: string; status: string }) => (
                  <p key={code}>
                    {code}: {status}
                  </p>
                ),
              )}
            </div>
          }
        >
          <InfoCircleOutlined className="pga-release-icon" />
        </Tooltip>
      ) : null}
      {/* WE CURRENTLY OPT NOT TO SHOW THIS LINK, BUT PROVIDE THE ACTION IN THE KEBAB MENU, MAY WANT TO RETHINK
      !canceled &&
        accountType === AccountType.OPERATOR &&
        entryConfirmationLink && (
          <Tooltip title="Copy the entry confirmation link" placement="top">
            <AuditOutlined
              onClick={() => copyToClipboard(entryConfirmationLink)}
              className="space-left"
            />
          </Tooltip>
        )*/}
      <StyledSpan>
        <OriginalEntryTag />
      </StyledSpan>
    </StyledDiv>
  );
};

export default UsConsumptionEntrySecondaryHeader;

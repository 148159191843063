import React from 'react';
import SubMenuOptions, { SubMenuOptionsProps } from 'components/SubMenuOptions';
import { AnyObject } from '@xbcb/shared-types';

// TODO remove `props` from `KebabMenuItem` and name something better
// like `key`. Additionally, props should be required to prevent unneeded checks like this
// Task: https://app.asana.com/0/1200045859453442/1200113449635116

// Takes an array of options (string) and creates a component that ultimately
// renders SubMenuItems. Objects and enums must be converted to an array prior
export const createSubMenuOptions =
  (optionNames: { key: string; displayName?: string }[]) =>
  (props: SubMenuOptionsProps) =>
    (
      <SubMenuOptions
        {...props}
        items={optionNames.reduce((acc: AnyObject, { key, displayName }) => {
          acc[key] = {
            item: <span>{displayName || key}</span>,
          };
          return acc;
        }, {})}
      />
    );

import React from 'react';
import { Input, Form } from 'antd';
import {
  Select,
  Option,
  CountrySelect,
  FormItem,
  PickDate,
} from '@xbcb/form-item-components';
import { selectFilter, shouldUpdate, maxLength } from '@xbcb/ui-utils';
import { FormInstance } from 'antd/lib/form';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { StyledDiv, StyledFlexDiv } from './styles';
import { MinusButton } from '@xbcb/button-components';
import DecimalQuantity from 'components/DecimalQuantity';
import UOMSelect from 'components/UOMSelect';
import { lpcoMapping } from 'libs/aphMapping';
import { capitalCase } from 'change-case';

interface LpcoAphProps {
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  required?: boolean;
  onRemove?: any;
  allowDelete?: boolean;
  index?: number;
}

const LpcoAph = ({
  form,
  fullNamePath,
  localNamePath,
  readOnly,
  required,
  disabled,
  onRemove,
  allowDelete,
  index,
}: LpcoAphProps) => {
  const { programCode, categoryTypeCode } = form.getFieldValue(
    fullNamePath.slice(0, -2),
  );

  const lpcoList = lpcoMapping[programCode]?.[categoryTypeCode];
  const LpcoTypeCodes = lpcoList?.map((item) => (
    <Option key={item.fieldName} value={item.fieldName}>
      {item.typeCode} - {capitalCase(item.fieldName)}
    </Option>
  ));

  const firstRow = index === 0;

  return (
    <StyledDiv>
      <StyledFlexDiv className="form-inline">
        <FormItem
          $itemSize={CssSize.TINY}
          label={firstRow ? 'LPCO Type' : undefined}
          name={[...localNamePath, 'lpcoFieldName']}
          rules={[{ required }]}
          $readOnly={readOnly}
        >
          <Select
            disabled={disabled}
            showSearch
            allowClear
            filterOption={selectFilter}
            notFoundContent="None Found"
            dropdownMatchSelectWidth={false}
          >
            {LpcoTypeCodes}
          </Select>
        </FormItem>
        <Form.Item
          shouldUpdate={shouldUpdate([[...fullNamePath, 'lpcoFieldName']])}
          noStyle
        >
          {() => {
            return (
              <>
                <CountrySelect
                  $itemSize={CssSize.TINY}
                  localNamePath={[...localNamePath, 'issuerLocation']}
                  required={required}
                  label={firstRow ? 'Issuer Location' : undefined}
                  readOnly={readOnly}
                  disabled={disabled}
                  displayCode={true}
                />

                <FormItem
                  $itemSize={CssSize.TINY_SHORT}
                  label={firstRow ? 'Region Description' : undefined}
                  name={[...localNamePath, 'regionDescription']}
                  getValueFromEvent={maxLength(44)} // regionDescription maxLength is defined in CBP doc
                  $inline
                  $readOnly={readOnly}
                >
                  <Input disabled={disabled} />
                </FormItem>
                <FormItem
                  $itemSize={CssSize.TINY_SHORT}
                  label={firstRow ? 'LPCO Number(or Name)' : undefined}
                  name={[...localNamePath, 'number']}
                  rules={[{ required }]}
                  getValueFromEvent={maxLength(51)} // number maxLength is defined in CBP doc
                  $inline
                  $readOnly={readOnly}
                >
                  <Input disabled={disabled} />
                </FormItem>
                <PickDate
                  readOnly={readOnly}
                  disabled={disabled}
                  required={required}
                  fullNamePath={[...fullNamePath, 'date']}
                  localNamePath={[...localNamePath, 'date']}
                  label="Date Issued or Signed"
                  disabledDate={(m: any) => false}
                  form={form}
                  hideLabel={!firstRow}
                />
                <DecimalQuantity
                  field={[...localNamePath, 'quantity']}
                  hideLabel={!firstRow}
                  readOnly={readOnly}
                  disabled={disabled}
                  required={required}
                  max={999999999999}
                  $itemSize={CssSize.MICRO_TINY}
                />
                <UOMSelect
                  localNamePath={[...localNamePath, 'uomCode']}
                  hideLabel={!firstRow}
                  readOnly={readOnly}
                  disabled={disabled}
                  required={required}
                  size={CssSize.MICRO_TINY}
                />
              </>
            );
          }}
        </Form.Item>

        {allowDelete && !readOnly && (
          <MinusButton
            label="LPCO"
            firstRow={firstRow}
            disabled={disabled}
            onRemove={onRemove}
          />
        )}
      </StyledFlexDiv>
    </StyledDiv>
  );
};

export default LpcoAph;

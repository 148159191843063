import type { BulkComplianceUploaderResult } from '@xbcb/api-gateway-client';
import bulkUploadPageMaker from 'components/BulkUploadRequest';

const headers = [
  'identifierType',
  'identifier',
  'importEntryNumber',
  'status',
  'failureReason',
];

const resultMapFunc = (result: BulkComplianceUploaderResult) => {
  const { identifier, identifierType, importEntryNumber } =
    result?.fields || {};
  return {
    identifier,
    identifierType,
    importEntryNumber,
    status: result?.status,
    failureReason: result?.failureReason,
  };
};

const BulkComplianceUploadRequest = bulkUploadPageMaker({
  headers,
  resultMapFunc,
});

export default BulkComplianceUploadRequest;

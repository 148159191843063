import type { User } from '@xbcb/api-gateway-client';
import { TagKey, TagValue } from '@xbcb/shared-types';

export const isFinanceTeamUser = (currentUser: User): boolean => {
  const { teams = [] } = currentUser;
  return Boolean(
    teams?.find(({ tags }) =>
      tags.some(
        ({ key, value }) =>
          key === TagKey.STRATEGIC_BUSINESS_UNIT &&
          value === TagValue.CBMS_FINANCE_TEAM,
      ),
    ),
  );
};

import {
  UsType86EntryProduct,
  UsConsumptionEntryProduct,
  ClassifyUsProductInput,
  UsComplianceDetailsInput,
} from '@xbcb/api-gateway-client';
import { transformUsEntryLineToComplianceDetailInput } from './transformUsEntryLineToComplianceDetailInput';

export const transformUsEntryProductToClassifyUsProductInput = (
  entryProduct: UsType86EntryProduct | UsConsumptionEntryProduct,
  ignoreAdCvdAndFees = false,
): ClassifyUsProductInput | undefined => {
  const { quantity, lines } = entryProduct;

  if (!quantity || !lines || !lines.length) {
    return undefined;
  }

  const complianceDetails = lines.map((line) =>
    transformUsEntryLineToComplianceDetailInput(line, ignoreAdCvdAndFees),
  );
  if (complianceDetails.some((cd) => cd === undefined)) {
    return undefined;
  }

  return {
    quantity,
    complianceDetails: complianceDetails as UsComplianceDetailsInput[], // verified it's not undefined above,
  };
};

import { KebabMenuItemKey } from '@xbcb/ui-types';
import { AppRecordKebabMenuItem } from '../../types';
import { blockUnblockTaskShowFunctionHelper } from './blockUnblockTaskShowFunction';
import { blockUnblockTaskActionFunctionHelper } from './blockUnblockTaskActionFunction';

export const appRecordUnblockTaskMenuItem: AppRecordKebabMenuItem = {
  text: 'Unblock task',
  key: KebabMenuItemKey.UNBLOCK_TASK,
  action: async ({ record, location }) => {
    await blockUnblockTaskActionFunctionHelper(false, record, location);
  },
  show: ({ existingRecord, location }) => {
    return blockUnblockTaskShowFunctionHelper(false, existingRecord, location);
  },
};

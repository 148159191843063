import styled from 'styled-components';
import { Button, Spin, Table } from 'antd';
import { CssSpace } from '@xbcb/ui-types';
import { ellipsis } from '@xbcb/ui-styles';

export const StyledAnchor = styled.a`
  color: var(--primary-color);
  &:hover {
    color: #cc3d84;
    text-decoration-line: underline;
  }
`;

export const StyledButton = styled(Button)`
  border: 1px solid black;
`;

export const StyledFlexDiv = styled.div`
  display: flex;
  .ant-btn {
    margin-left: ${CssSpace.SPACE_4};
  }
`;

export const StyledHeaderParagraph = styled.p`
  font-size: 18px;
`;

// .sign-form-clear is targeting the clear signature button
// (the 'X' on the draw signature tab) in SignFormContents
export const StyledSignFormContentsDiv = styled.div`
  margin: auto;
  width: 50%;
  .sign-form-clear {
    top: initial;
    right: 17.4rem;
  }
`;

export const StyledSpin = styled(Spin)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 130px;
`;

export const StyledTable = styled(Table)`
  && {
    margin-bottom: ${CssSpace.SPACE_4};
  }
`;

export const StyledTruncatedParagraph = styled.p`
  ${ellipsis}
  display: block;
  margin-bottom: 0;
  max-width: 200px;
`;

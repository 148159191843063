import { AccountType } from '@xbcb/shared-types';

export const iorNumber = ({
  iorNumber,
  accountType,
}: {
  iorNumber: string | number;
  accountType: AccountType;
}) => {
  if (typeof iorNumber !== 'string') return iorNumber;
  // iorNumber includes the hyphen
  if (accountType === AccountType.FORWARDER) {
    const fullMask = iorNumber.replace(/\d/g, 'X');
    return fullMask.slice(0, -4) + iorNumber.slice(-4);
  } else {
    return iorNumber;
  }
};

/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import { List } from 'antd';
import { formatDate } from 'libs/display';
import copyTextToClipboard from 'libs/copyToClipboard';
import { getMailTo, safeGetMessage } from '@xbcb/ui-utils';
import {
  StyledList,
  StyledSentDateSpan,
  StyledDocumentTypeSpan,
} from './styles';
import type { DocumentSignRequest } from '@xbcb/api-gateway-client';
import {
  documentTypeToSignLinkTypeMap,
  documentTypeToDescriptionMap,
} from 'libs/documentTypeMap';
import { useBundle } from '@amzn/react-arb-tools';

export type SignLinksPropTypes = {
  dsrs: DocumentSignRequest[];
  hideSent?: boolean;
};

// Component to display only pending Document Sign Requests.
const SignLinks = ({ dsrs, hideSent = false }: SignLinksPropTypes) => {
  const [partyBundle] = useBundle('components.Party');
  if (dsrs.length === 0) return null;
  const filteredDsrs = dsrs.filter((dsr) => !dsr.signed);

  const content = (dsr: DocumentSignRequest) => {
    const actions = [
      <a rel="noopener noreferrer" onClick={() => copyTextToClipboard(dsr.url)}>
        {safeGetMessage(partyBundle, 'copy_link')}
      </a>,
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={getMailTo(
          documentTypeToSignLinkTypeMap.get(dsr.documentTags[0]),
          dsr.url,
        )}
      >
        {safeGetMessage(partyBundle, 'draft_email')}
      </a>,
    ];

    return (
      <List.Item actions={actions}>
        <StyledDocumentTypeSpan>
          {documentTypeToDescriptionMap.get(dsr.documentTags[0])}
        </StyledDocumentTypeSpan>
        {!hideSent && (
          <StyledSentDateSpan>
            {safeGetMessage(partyBundle, 'sent_date', {
              date: formatDate(dsr.created.time),
            })}
          </StyledSentDateSpan>
        )}
      </List.Item>
    );
  };

  return (
    <StyledList
      bordered={false}
      locale={{
        emptyText: safeGetMessage(partyBundle, 'no_pending_signature_request'),
      }}
      dataSource={filteredDsrs}
      renderItem={(dsr: any) => content(dsr)}
    />
  );
};

export default SignLinks;

import type { Document } from '@xbcb/api-gateway-client';
import { DocumentTag } from '@xbcb/document-types';
export const getAvatarUrl = (documents: Document[]) => {
  const avatarDocs = documents
    ?.filter((document) =>
      document?.documentTags.includes(DocumentTag.PHOTOGRAPH),
    )
    .sort((a, b) => (a.created.time > b.created.time ? -1 : 1));

  return avatarDocs?.[0]?.preview?.downloadLink;
};

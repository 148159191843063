import { AnyObject } from '@xbcb/shared-types';
import { transformWorkOrderBase } from './base';

export const transformUsIorContinuousBondRequest = {
  toSchema: ({
    input,
    existingRecord,
  }: {
    input: AnyObject;
    existingRecord: AnyObject;
  }) => {
    const cleanedOutput = transformWorkOrderBase.toSchema({
      input,
      existingRecord,
    });

    // The input only takes IdInput for `usIor` and `shipper`
    cleanedOutput.usIor = { id: cleanedOutput.usIor?.id };
    cleanedOutput.shipper = { id: cleanedOutput.shipper?.id };
    delete cleanedOutput.renewalCanceled;
    return cleanedOutput;
  },
};

import React, { memo, useState } from 'react';
import { Button, Modal } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { WorkOrderTaskStatus, WorkOrderTaskType } from '@xbcb/work-order-types';
import { useModal } from '@xbcb/ui-utils';
import { ModalKey } from 'types';
import { processNewAssignmentRequest } from 'libs/getNewAssignmentRequest';
import { useCurrentUser } from 'libs/hooks';
import type { UsConsumptionEntry } from '@xbcb/api-gateway-client';
import { StyledCloseCircleOutlined } from 'components/MarkWorkOrderReady/styles';

export interface CompleteWorkOrderTaskModalProps {
  taskStatus: WorkOrderTaskStatus;
}

/**
 * CompleteWorkOrderTaskModal
 *
 * This modal manages a two-step task completion process.
 *  * Completion confirmation
 *  * If completion is successful, offer the option to request a new assignment.
 */

export const CompleteWorkOrderTaskModal: React.FC<
  CompleteWorkOrderTaskModalProps
> = ({ taskStatus }) => {
  const hasCompletedTask = taskStatus === WorkOrderTaskStatus.COMPLETED;
  const { closeModal, visible, modalProps } = useModal(
    ModalKey.COMPLETE_WORK_ORDER_TASK,
  );
  // @todo use AppRecord-level updateLoading state instead of manual `submitting` modalProp
  const { onOk, onCancel, submitting, workOrder, workOrderTaskType } =
    modalProps;
  const history = useHistory();
  const user = useCurrentUser();

  const [isGetNewTaskLoading, setIsGetNewTaskLoading] = useState(false);

  const handleCancel = () => {
    closeModal();
    onCancel();
  };

  const handleCompleteTask = async () => {
    onOk();
  };

  const handleGetNewAssignmentRequest = async () => {
    setIsGetNewTaskLoading(true);
    const { redirectTo } = await processNewAssignmentRequest(user);
    if (redirectTo) {
      closeModal();
      history.push(redirectTo);
    }
    setIsGetNewTaskLoading(false);
  };

  // Returns true only if WOT type is US_CONSUMPTION_ENTRY_DOCUMENT_TAGGING
  // and the totalInvoiceLineCount is missing
  const missingTotalInvoiceLineCount = (
    workOrder: { totalInvoiceLineCount: number | undefined },
    workOrderTaskType: WorkOrderTaskType,
  ): boolean => {
    if (
      workOrderTaskType ===
      WorkOrderTaskType.US_CONSUMPTION_ENTRY_DOCUMENT_TAGGING
    ) {
      return !(workOrder as UsConsumptionEntry)?.totalInvoiceLineCount;
    }
    return false;
  };

  const isMissingTotalInvoiceLineCount = missingTotalInvoiceLineCount(
    workOrder,
    workOrderTaskType,
  );

  const modalBodyText = hasCompletedTask
    ? 'Task has been completed successfully.'
    : `Once the task is completed, it cannot be undone. If you want to make
      changes, select "Cancel" and make adjustments before continuing.`;

  return (
    <Modal
      title={hasCompletedTask ? 'Task Completed' : 'Complete Task?'}
      open={visible}
      onCancel={() => {
        closeModal();
      }}
      footer={
        <ModalFooter
          hasCompletedTask={hasCompletedTask}
          isCompletingTask={submitting}
          isRequestingNewTask={isGetNewTaskLoading}
          isMissingTotalInvoiceLineCount={isMissingTotalInvoiceLineCount}
          handleCancel={handleCancel}
          handleCompleteTask={handleCompleteTask}
          handleAssignmentRequest={handleGetNewAssignmentRequest}
          closeModal={closeModal}
        />
      }
    >
      {isMissingTotalInvoiceLineCount ? (
        <>
          <StyledCloseCircleOutlined />
          <>
            {' '}
            Please update invoice line count for Commercial Invoice document
            before completing task.
          </>
        </>
      ) : (
        modalBodyText
      )}
    </Modal>
  );
};

interface ModalFooterProps {
  hasCompletedTask: boolean;
  isCompletingTask: boolean;
  isRequestingNewTask: boolean;
  isMissingTotalInvoiceLineCount: boolean;
  handleCompleteTask: () => void;
  handleAssignmentRequest: () => void;
  handleCancel: () => void;
  closeModal: () => void;
}

const ModalFooter: React.FC<ModalFooterProps> = memo(
  ({
    hasCompletedTask,
    isCompletingTask,
    isRequestingNewTask,
    isMissingTotalInvoiceLineCount,
    handleAssignmentRequest,
    handleCompleteTask,
    handleCancel,
    closeModal,
  }) => {
    return (
      <>
        {hasCompletedTask
          ? [
              <Button key="navigate" onClick={closeModal}>
                <Link to="/assignment-workspace">
                  Go to Assignment Workspace
                </Link>
              </Button>,
              <Button
                key="get-task"
                type="primary"
                loading={isRequestingNewTask}
                onClick={handleAssignmentRequest}
              >
                Get new task assignment
              </Button>,
            ]
          : [
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                disabled={isMissingTotalInvoiceLineCount}
                key="complete-task"
                type="primary"
                loading={isCompletingTask}
                onClick={handleCompleteTask}
              >
                OK
              </Button>,
            ]}
      </>
    );
  },
);

export default memo(CompleteWorkOrderTaskModal);

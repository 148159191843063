import { gql, QueryResult, useQuery } from '@apollo/client';

export const customDomainRuntimeInfo = gql`
  query CustomDomainRuntimeInfo {
    customDomainRuntimeInfo {
      customDomain {
        id
      }
      environmentVariables {
        key
        value
      }
    }
  }
`;

export const useCustomDomainRuntimeInfo = (): QueryResult =>
  useQuery(customDomainRuntimeInfo);

import { createAction } from 'redux-actions';
import { logout } from '@xbcb/aws-utils';
import { MQTT_DISCONNECT } from '.';
import { logout as LOGOUT } from './userLogin';

export const PROFILE_VALIDATE_CACHE = createAction('PROFILE_VALIDATE_CACHE');
export const FLUSH_CACHE = createAction('FLUSH_CACHE');

export const LOGOUT_ASYNC = () => async (dispatch) => {
  dispatch(LOGOUT());
  await dispatch(MQTT_DISCONNECT());
  await logout();
};

export const LOGIN_CHANGE = createAction('LOGIN_CHANGE');

export const PROFILE_CACHE = createAction('PROFILE_CACHE');

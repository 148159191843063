import { RecordType } from '@xbcb/shared-types';

import { ModalKey } from 'types';
import { transformBulkUploadRequest } from 'libs/formTransforms/bulkUploadRequest';
import CreateBulkUploadRequestModal, {
  CreateBulkUploadRequestModalProps,
} from 'components/CreateBulkUploadRequestModal';

const createBulkMilestoneUploadRequestModalProps: CreateBulkUploadRequestModalProps =
  {
    modalKey: ModalKey.CREATE_BULK_MILESTONE_UPLOAD_REQUEST,
    recordName: RecordType.BULK_MILESTONE_UPLOAD_REQUEST,
    tagKey: `bulkMilestoneUploadRequestId`,
    title: 'Bulk Milestone Upload Request',
    transformCreateRecordInput: transformBulkUploadRequest.toSchema,
  };

const CreateBulkMilestoneUploadRequestModal = (): JSX.Element =>
  CreateBulkUploadRequestModal(createBulkMilestoneUploadRequestModalProps);

export default CreateBulkMilestoneUploadRequestModal;

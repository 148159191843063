import { AppRecordInterfaceRoute } from '../types';
import { UserProfile, CompanySettings } from '../loadableComponents';
import { RecordInterfaceType, RecordType } from '@xbcb/shared-types';
import { transformParty, transformUser } from 'libs/formTransforms';
import { Cognito } from '@xbcb/aws-utils';
import { camelCase } from 'change-case';
import {
  operatorUserFragments,
  forwarderUserFragments,
  shipperUserFragments,
} from '@xbcb/client-queries';
import {
  operatorFragments,
  forwarderFragments,
  shipperFragments,
} from '@xbcb/party-queries';
import { getRecordType } from '@xbcb/core';

// TODO: import this from shared lib.
const fragmentsMap = {
  [RecordType.FORWARDER]: forwarderFragments,
  [RecordType.OPERATOR]: operatorFragments,
  [RecordType.SHIPPER]: shipperFragments,
  [RecordType.FORWARDER_USER]: forwarderUserFragments,
  [RecordType.SHIPPER_USER]: shipperUserFragments,
  [RecordType.OPERATOR_USER]: operatorUserFragments,
};

export const appRecordInterfaceRoutes: AppRecordInterfaceRoute[] = [
  {
    Page: UserProfile,
    recordInterfaceType: RecordInterfaceType.USER,
    path: '/profile',
    getRecordParams: (props: { currentUser: any }) => {
      const { currentUser } = props;
      const recordType = getRecordType(currentUser.id) as RecordType;
      const recordId = currentUser.id;
      const fields = `...${camelCase(recordType)}Fields`;
      const fragments = fragmentsMap[recordType as keyof typeof fragmentsMap];
      return {
        recordType,
        recordId,
        fields,
        fragments,
      };
    },
    transformRecordToInput: transformUser.toForm,
    transformUpdateRecordInput: transformUser.toSchema,
    defaultOnSubmit: async ({ updatedRecord }) => {
      const getCognitoPhoneNumber = ({
        phoneNumberCountry,
        phoneNumber,
      }: {
        phoneNumber: string;
        phoneNumberCountry: string;
      }) => {
        if (!phoneNumberCountry || !phoneNumber) return '';
        return `+${phoneNumberCountry}${phoneNumber.replace(/\D/g, '')}`;
      };
      const { mobilePhone } = updatedRecord;
      const cognitoPhoneNumber = getCognitoPhoneNumber({
        phoneNumber: mobilePhone?.number,
        phoneNumberCountry: mobilePhone?.country,
      });
      await Cognito.updateAttributes([
        {
          Name: 'phone_number',
          Value: cognitoPhoneNumber,
        },
      ]);
    },
  },
  {
    Page: CompanySettings,
    recordInterfaceType: RecordInterfaceType.PARTY,
    path: '/company',
    getRecordParams: (props: { currentUser: any }) => {
      const { currentUser } = props;
      const recordType = getRecordType(currentUser.account.id) as RecordType;
      const recordId = currentUser.account.id;
      const fields = `...${camelCase(recordType)}Fields`;
      const fragments = fragmentsMap[recordType as keyof typeof fragmentsMap];
      return {
        recordType,
        recordId,
        fields,
        fragments,
      };
    },
    transformRecordToInput: transformParty.toForm,
    transformUpdateRecordInput: transformParty.toSchema,
  },
];

import React, { Component } from 'react';
import moment from 'moment';
import { formatBytes } from '@xbcb/js-utils';
import { get } from 'lodash';
import { Datum } from '@xbcb/display-components';
import './DuplicateDocModal.less';

class DuplicateDocModal extends Component {
  render() {
    const { docs = [], url = '' } = this.props;
    const title = `The document was found in the below shipment${
      docs.length > 1 ? 's' : ''
    }. If the current shipment was created accidentally, please cancel it.`;
    const tableEntries = docs.map((d, i) => {
      const longShipmentId = d.folder.split('/').splice(1, 2).join('');
      const link = url + '/shipments/' + longShipmentId + '?v=docs';
      const uploadedAt = moment(d.createdTime).format('MM/DD/YY h:MM a');
      return (
        <tbody key={i} className="dup-modal-row">
          <Datum
            key="filename"
            label="File name"
            value={d.fileName + '.' + d.extension}
          />
          <Datum
            key="shipment"
            label="Shipment"
            link={link}
            value={d.folder.split('/').splice(1, 2).join('')}
          />
          <Datum key="uploader" label="Uploader" value={d.createdByName} />
          <Datum key="uploadedTime" label="Uploaded time" value={uploadedAt} />
          <Datum
            key="tags"
            label="Tags"
            value={get(d, 'tags', []).join(', ')}
          />
          <Datum key="size" label="Size" value={formatBytes(d.size)} />
        </tbody>
      );
    });
    return (
      <>
        <h4 className="review-map-isf-details">{title}</h4>
        <table>{tableEntries}</table>
      </>
    );
  }
}
export default DuplicateDocModal;

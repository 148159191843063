import styled from 'styled-components';
import { Button } from 'antd';
import { CssSpace } from '@xbcb/ui-types';

export const StyledButton = styled(Button)`
  margin-top: ${CssSpace.SPACE_4};
  margin-left: 5px;
  border: none;
  font-size: 18px;
  font-weight: 400;
`;

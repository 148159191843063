import styled from 'styled-components';
import { Select } from 'antd';

export const StyledSelect: typeof Select = styled(Select)`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing.space_4};
  margin-left: ${({ theme }) => theme.spacing.space_3};
  width: 175px;
  overflow: hidden;
  textoverflow: ellipsis;
  whitespace: nowrap;
`;

import styled from 'styled-components';

export const StyledDiv = styled.div`
  border: 1px rgba(0, 0, 0, 0.08) solid;
  max-height: 384px;
  overflow-y: auto;

  ul {
    background-color: transparent !important;
  }

  div {
    white-space: pre;
    font-family: monospace;
  }
`;

import moment from 'moment';

const effectiveDateStringFormat = 'YYYY-MM-DD';

export const DEFAULT_EFFECTIVE_DATE: string = moment()
  .startOf('day')
  .format(effectiveDateStringFormat);

export const formatMomentAsEffectiveDateString: (
  effectiveDateMoment: moment.Moment,
) => string = (effectiveDateMoment) => {
  return effectiveDateMoment.format(effectiveDateStringFormat);
};

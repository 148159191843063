import { AccountType, AnyObject, RecordType } from '@xbcb/shared-types';
import { ImporterEntityActivation } from '../../../loadableComponents';
import { WorkOrderRoute } from '..';
import {
  getImporterEntityVersion,
  queryImporterEntityActivation,
  updateImporterEntityActivation,
  upsertImporterEntityActivationMutation,
} from './importerEntityActivation.query';
import {
  WorkOrderMilestoneName,
  WorkOrderStatus,
} from '@xbcb/work-order-types';
import { isImporterEntityStatusNonMutable } from '@xbcb/party-utils';
import { v4 } from 'uuid';
import { ActivationStatus } from '@xbcb/party-types';
import { setModal } from 'actions';
import { message } from 'antd';
import { executeMutation } from 'libs/executeMutation';
import { createWorkOrderMilestoneMutation } from 'libs/sharedQueries';
import { AppRecordOnConfirmPayload } from 'routes/types';
import { ModalKey } from 'types';

const showActivationButtons = (
  existingRecord: AnyObject,
  currentUser: AnyObject,
) => {
  const isOperator = currentUser.accountType === AccountType.OPERATOR;
  // If the WO is completed, blocked, or canceled, don't allow activation
  const invalidWorkOrderStatus = [
    WorkOrderStatus.COMPLETED,
    WorkOrderStatus.BLOCKED,
    WorkOrderStatus.CANCELED,
  ].includes(existingRecord?.status);
  const taskCreated = Boolean(existingRecord?.tasks.length);
  const invalidImporterEntityStatus = isImporterEntityStatusNonMutable({
    iorEntity: existingRecord?.importerEntity,
    customsBrokerId: existingRecord?.broker?.id,
  });
  return (
    isOperator &&
    !invalidImporterEntityStatus &&
    !existingRecord.deleted?.time &&
    !invalidWorkOrderStatus &&
    taskCreated
  );
};

const importerEntityActivationRoute: WorkOrderRoute = {
  Page: ImporterEntityActivation,
  recordType: RecordType.IMPORTER_ENTITY_ACTIVATION,
  queries: {
    get: queryImporterEntityActivation,
    update: updateImporterEntityActivation,
  },
  disableCancel: true,
  submitButtons: [
    {
      key: `activateImporterEntity`,
      text: 'Approve',
      skipUpdateRecord: () => true,
      skipValidation: () => true,
      show: ({ existingRecord, currentUser }) => {
        return showActivationButtons(existingRecord, currentUser);
      },
      onSubmit: async ({ updatedRecord }) => {
        const importerEntity = updatedRecord?.importerEntity;
        const version = await getImporterEntityVersion(importerEntity.id);
        await executeMutation({
          mutation: upsertImporterEntityActivationMutation(),
          successMessage: 'Importer Entity Activated',
          variables: {
            id: importerEntity?.id,
            version: version || importerEntity.version,
            input: {
              customsBroker: { id: updatedRecord?.broker?.id },
              status: ActivationStatus.ACTIVATED,
            },
          },
        });
        await executeMutation({
          mutation: createWorkOrderMilestoneMutation,
          successMessage: 'Milestone Created',
          variables: {
            idempotencyKey: v4(),
            input: {
              allowDuplicate: false,
              name: WorkOrderMilestoneName.IOR_ACTIVATED,
              workOrder: {
                id: updatedRecord.id,
                version: updatedRecord.version,
              },
            },
          },
        });
      },
    },
    {
      key: `rejectImporterEntity`,
      text: 'Reject',
      skipUpdateRecord: () => true,
      skipValidation: () => true,
      show: ({ existingRecord, currentUser }) => {
        return showActivationButtons(existingRecord, currentUser);
      },
      onConfirm: ({ dispatch, isValid }) =>
        new Promise<AppRecordOnConfirmPayload>((resolve, reject) => {
          if (isValid) {
            dispatch(
              setModal({
                key: ModalKey.REJECT_IOR_ACTIVATION,
                props: {
                  visible: true,
                  sendConfirmationDecision: resolve,
                },
              }),
            );
          } else {
            return resolve({ canceled: true });
          }
        }),
      onSubmit: async ({ updatedRecord, confirmationData }) => {
        const importerEntity = updatedRecord?.importerEntity;
        const { cancellationReason } = confirmationData || {};
        if (!cancellationReason) {
          message.error(`Please fill out all the fields`);
          return;
        }
        const version = await getImporterEntityVersion(importerEntity.id);
        await executeMutation({
          mutation: upsertImporterEntityActivationMutation(),
          successMessage: 'Importer Entity Rejected',
          variables: {
            id: importerEntity?.id,
            version: version || importerEntity.version,
            activationInput: {
              customsBroker: { id: updatedRecord?.broker?.id },
              status: ActivationStatus.REJECTED,
              rejectionReasons: cancellationReason,
            },
          },
        });
        await executeMutation({
          mutation: createWorkOrderMilestoneMutation,
          successMessage: 'Milestone Created',
          variables: {
            idempotencyKey: v4(),
            input: {
              allowDuplicate: false,
              name: WorkOrderMilestoneName.IOR_REJECTED,
              workOrder: {
                id: updatedRecord.id,
                version: updatedRecord.version,
              },
            },
          },
        });
      },
    },
  ],
};

export default importerEntityActivationRoute;

import styled, { css } from 'styled-components';
import { Modal, Form } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50px;
`;

export const StyledForm = styled(Form)`
  display: constant;
  margin: 0;
`;

export const StyledFileExcelOutlined = styled(FileExcelOutlined)`
  font-size: ${({ theme }) => theme.size.tiny};
  justify-content: center;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.space_4};
  margin-bottom: ${({ theme }) => theme.spacing.space_4};
`;

export const StyledSpan = styled.span`
  justify-content: center;
  display: flex;
`;

const fullWidth = css`
  width: 100%;
  margin-right: 0;
`;

export const StyledDiv = styled.div<{
  $fullWidth?: boolean;
}>`
  width: 25%;
  height: 158px;
  margin-bottom: ${({ theme }) => theme.spacing.space_4};

  ${({ $fullWidth }) => ($fullWidth ? fullWidth : 'min-width: 312px;')}
  .ant-upload {
    padding: 0;
    height: 158px;
    .anticon {
      font-size: ${({ theme }) => theme.spacing.space_6};
    }
  }
  .ant-upload-list {
    display: none;
  }
  & {
    :hover {
      .anticon {
        color: ${({ theme }) => theme.palette.primaryColor};
      }
    }
    :active {
      .anticon {
        color: ${({ theme }) => theme.palette.primaryColor};
      }
    }
  }
`;

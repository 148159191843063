import { FormItem, Option, Select } from '@xbcb/form-item-components';
import Quantity from 'components/Quantity';
import React from 'react';
import { useTheme } from 'styled-components';
import { ConditionalOnRemarksCodes } from '../types';
import { VneRequirements } from 'libs/pgaHelpers/epa';

export const EnginePowerRating: ConditionalOnRemarksCodes = ({
  localNamePath,
  disabled,
  importCode,
  required,
}) => {
  const theme = useTheme();

  const shouldShow = VneRequirements.isEnginePowerRatingRequired(importCode);

  return shouldShow ? (
    <>
      <Quantity
        field={[...localNamePath, 'enginePowerRating', 'value']}
        label="Engine Power Rating"
        disabled={disabled}
        $itemSize={theme.size.tiny_short}
        required={required}
      />
      <FormItem
        label={'Unit of Power'}
        name={[...localNamePath, 'enginePowerRating', 'unit']}
        $itemSize={theme.size.short}
        rules={[{ required }]}
      >
        <Select
          aria-label={'Engine Power Rating Unit Select'}
          disabled={disabled}
        >
          {enginePowerRatingUnits.map(EnginePowerRatingUnitOption)}
        </Select>
      </FormItem>
    </>
  ) : null;
};

type EnginePowerRatingUnit = {
  unit: string;
  description: string;
};

const enginePowerRatingUnits = [
  {
    unit: 'HP',
    description: 'Horsepower',
  },
  {
    unit: 'KW',
    description: 'Kilowatt',
  },
];

const EnginePowerRatingUnitOption = ({
  description,
  unit,
}: EnginePowerRatingUnit) => {
  const optionText = `${unit} - ${description}`;
  return (
    <Option value={unit} key={unit}>
      {optionText}
    </Option>
  );
};

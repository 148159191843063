import { Col, Divider, Form, Input, Row } from 'antd';
import { ValidatorRule } from 'rc-field-form/lib/interface';
import React from 'react';
import { Select, FormItem } from '@xbcb/form-item-components';
import {
  createDataCyValue,
  CssSize,
  DataCyPrefix,
  NamePath,
} from '@xbcb/ui-types';
import { codeToText } from '@xbcb/js-utils';
import { AnyObject } from '@xbcb/shared-types';
import { shouldUpdate, safeGetMessage } from '@xbcb/ui-utils';
import {
  AsinInquiryReasonCode,
  reasonCodesThatNeedSuggestions,
} from '@xbcb/work-order-types';
import {
  StyledClipboard,
  StyledIconBox,
  StyledTrashIcon,
  StyledRow,
} from './styles';
import { useBundle } from '@amzn/react-arb-tools';

interface Option {
  label: string;
  value: string;
  'data-cy': string;
}

const filterOptions = (searchText: string, currentOption?: Option) => {
  return currentOption?.label?.match(new RegExp(searchText, 'i'));
};

const formatClientIdentifier = (sku: string, asin: string): string =>
  `${asin} [${sku}]`;

const hasSuggestionReasonCode = (reasonCodes?: string[]) =>
  reasonCodes?.some((code: string) =>
    reasonCodesThatNeedSuggestions.includes(code),
  );

const reasonCodeOptions: Option[] = Object.values(AsinInquiryReasonCode).map(
  (reasonCode) => ({
    value: reasonCode,
    label: codeToText(reasonCode),
    'data-cy': createDataCyValue(
      DataCyPrefix.REASON_CODES_OPTION,
      reasonCode.toString(),
    ),
  }),
);

export interface ProductSuggestion {
  sku: string;
  asin: string;
  skuVersion?: number;
}

export type ProductLevelErrorRowProps = {
  index: number;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  productIdentifierSuggestions: ProductSuggestion[];
  firstRow?: boolean;
  showMinus?: boolean;
  add: (defaultValue: AnyObject) => void;
  remove: () => void;
};

const ProductLevelErrorRow = ({
  fullNamePath,
  localNamePath,
  productIdentifierSuggestions,
  firstRow,
  showMinus,
  index,
  add,
  remove,
}: ProductLevelErrorRowProps): React.ReactElement => {
  const [documentsPageBundle] = useBundle('components.DocumentsPage');
  const form = Form.useFormInstance();
  const { setFields } = form;
  const reasonCodes: string[] =
    Form.useWatch([...fullNamePath, 'reasonCodes'], form) ?? [];
  const suggestionToFix = Form.useWatch(
    [...fullNamePath, 'suggestionToFix'],
    form,
  );

  const clientIdentifier = Form.useWatch(
    [...fullNamePath, 'clientIdentifier'],
    form,
  );

  const shouldShowSuggestionInput = hasSuggestionReasonCode(reasonCodes);

  const validateProductIdentifier = (field: { fullField: string }) => {
    const row = form.getFieldValue(field.fullField.split('.').slice(0, -1));
    const { clientIdentifier, reasonCodes } = row;

    if (reasonCodes && reasonCodes.length > 0) {
      if (!clientIdentifier.length) {
        return Promise.reject(
          new Error(safeGetMessage(documentsPageBundle, 'please_fill_asin')),
        );
      }
    }
    return Promise.resolve();
  };

  const validateProductInquiryReasonCodes = (field: { fullField: string }) => {
    const row = form.getFieldValue(field.fullField.split('.').slice(0, -1));
    const { clientIdentifier, reasonCodes } = row;
    if (clientIdentifier && !reasonCodes?.length) {
      return Promise.reject(
        new Error(
          safeGetMessage(documentsPageBundle, 'please_fill_reason_code'),
        ),
      );
    }
    return Promise.resolve();
  };

  const productIdentifierOptions = productIdentifierSuggestions.map(
    (suggestion) => ({
      label: formatClientIdentifier(suggestion.sku, suggestion.asin),
      value: suggestion.sku,
      'data-cy': createDataCyValue(DataCyPrefix.ASIN_OPTION, suggestion.asin),
    }),
  );

  return (
    <Row>
      {!firstRow ? <Divider style={{ marginTop: 0 }} /> : null}
      <StyledRow>
        <FormItem
          $itemSize={CssSize.SHORT}
          $inline
          label={safeGetMessage(documentsPageBundle, 'asin_sku')}
          name={[...localNamePath, 'clientIdentifier']}
          rules={[
            {
              validator:
                // Actual Antd signature here doesn't match the types they're specifying internally
                validateProductIdentifier as unknown as ValidatorRule['validator'],
            },
          ]}
        >
          <Select
            showSearch
            data-cy={createDataCyValue(
              DataCyPrefix.ASIN_NUMBER,
              index.toString(),
            )}
            options={productIdentifierOptions}
            onSelect={(value) =>
              form.setFieldValue([...fullNamePath, 'clientIdentifier'], value)
            }
          />
        </FormItem>
        <Form.Item noStyle shouldUpdate={shouldUpdate([['reasonCodes']])}>
          <FormItem
            $inline
            $itemSize={CssSize.SHORT_MEDIUM}
            label={safeGetMessage(documentsPageBundle, 'reason_code')}
            name={[...localNamePath, 'reasonCodes']}
            rules={[
              {
                validator:
                  // Actual Antd signature here doesn't match the types they're specifying internally
                  validateProductInquiryReasonCodes as unknown as ValidatorRule['validator'],
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              // Future Ant versions let you specify the type of options you're sending, but not this one.
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              filterOption={filterOptions as any}
              options={reasonCodeOptions}
              maxTagCount={1}
              maxTagTextLength={29}
              onChange={(newValue) => {
                setFields([
                  {
                    name: [...fullNamePath, 'suggestionToFix'],
                    value: undefined,
                  },
                  // Schema expects an array of values
                  // even though we only allow one here.
                  {
                    name: [...fullNamePath, 'reasonCodes'],
                    value: newValue ? [newValue] : [],
                  },
                ]);
              }}
              data-cy={createDataCyValue(
                DataCyPrefix.REASON_CODES,
                index.toString(),
              )}
              data-testid={'reason-code-select'}
            />
          </FormItem>
        </Form.Item>
        <StyledIconBox>
          {showMinus ? (
            <StyledTrashIcon
              alt={safeGetMessage(
                documentsPageBundle,
                'remove_asin_level_error',
              )}
              onClick={remove}
              data-testid="delete-row"
            />
          ) : null}
          <StyledClipboard
            data-testid="copy-asin"
            onClick={() =>
              add({
                clientIdentifier,
                reasonCodes,
                suggestionToFix,
              })
            }
          />
        </StyledIconBox>
      </StyledRow>
      {shouldShowSuggestionInput ? (
        <Col data-testid="suggestion-input">
          <FormItem
            $inline
            $itemSize={CssSize.MEDIUM}
            label={safeGetMessage(documentsPageBundle, 'suggestion_to_fix')}
            name={[...localNamePath, 'suggestionToFix']}
            rules={[{ required: true }]}
          >
            <Input.TextArea
              autoSize
              placeholder={safeGetMessage(
                documentsPageBundle,
                'add_details_to_help_shipper',
              )}
              data-cy={createDataCyValue(
                DataCyPrefix.INQUIRY_SUGGESTION,
                index.toString(),
              )}
            />
          </FormItem>
        </Col>
      ) : null}
    </Row>
  );
};

export default ProductLevelErrorRow;

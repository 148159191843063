import styled from 'styled-components';
import { Button, Popover } from 'antd';

export const PopoverContentDiv = styled.div`
  margin-right: ${({ theme }) => theme.spacing.space_2};
  margin-left: ${({ theme }) => theme.spacing.space_2};
  margin-top: ${({ theme }) => theme.spacing.space_1};
  padding: ${({ theme }) => theme.spacing.space_2};
  width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
`;

export const LanguageButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.space_3};
  margin-bottom: ${({ theme }) => theme.spacing.space_3};
  border-color: ${({ theme }) => theme.palette.blues.aqua};
  font-size: ${({ theme }) => theme.font.size.small};
  padding-top: ${({ theme }) => theme.spacing.space_2};
  padding-bottom: ${({ theme }) => theme.spacing.space_2};
  display: flex;
  flex-direction: column;
  height: fit-content;
  font-weight: 400;
  width: 190px;
  &:focus {
    box-shadow: ${({ theme }) => theme.backgroundColor};
    background-color: ${({ theme }) => theme.palette.greys.grey};
  }
`;

export const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.space_4};
  margin-right: ${({ theme }) => theme.spacing.space_2};
  margin-left: ${({ theme }) => theme.spacing.space_2};
  border: none;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 400;
`;

export const StyledDiv = styled.div`
  margin-right: ${({ theme }) => theme.spacing.space_2};
  margin-left: ${({ theme }) => theme.spacing.space_2};
  button.ant-btn {
    border: none;
    .anticon {
      font-size: ${({ theme }) => theme.font.size.small};
      font-weight: 400px;
    }
  }
`;

export const StyledPopover = styled(Popover)`
  &:focus {
    box-shadow: ${({ theme }) => theme.backgroundColor};
  }
`;

import React from 'react';
import { useDispatch } from 'react-redux';
import type {
  WorkOrderGroup,
  Forwarder,
  Shipper,
} from '@xbcb/api-gateway-client';
import { formatRecordName } from '@xbcb/js-utils';
import { RecordType, AccountType } from '@xbcb/shared-types';
import { gaEvent, setModal } from '@xbcb/ui-utils';
import ArrayTooltip from 'components/ArrayTooltip';
import ObjectIcon from 'components/ObjectIcon';
import TagForwarder from 'components/TagForwarder';
import { useCurrentUser } from 'libs/hooks';
import { ModalKey } from 'types';
import { StyledDiv, StyledLink, StyledButton } from './styles';

type TagForwarderButtonProps = {
  workOrderGroup: WorkOrderGroup;
  isWorkOrder?: boolean;
};

const TagForwarderButton: React.FC<TagForwarderButtonProps> = ({
  workOrderGroup,
  isWorkOrder,
}) => {
  const { accountType, forwarder, shipper } = useCurrentUser();
  const dispatch = useDispatch();
  if (!workOrderGroup) return null;
  const { id, forwarders } = workOrderGroup;
  const forwarderCount = forwarders?.length || 0;

  const forwarderLinks =
    forwarders?.reduce((forwarderLinks: React.ReactNode[], { forwarder }) => {
      const { id, name } = forwarder || {};
      if (id && name) {
        forwarderLinks.push(
          <StyledLink to={`/forwarders/${id}`}>{name}</StyledLink>,
        );
      }
      return forwarderLinks;
    }, []) || [];

  const handleClick = () => {
    gaEvent(
      isWorkOrder ? 'Work Orders' : 'Shipments',
      'Tag Forwarder Button Clicked',
      id,
    );
    dispatch(
      setModal({
        key: ModalKey.TAG_FORWARDER,
        props: { visible: true },
      }),
    );
  };

  // Non-paying customers don't get access
  const getSubscriptionPlan = (party: Forwarder | Shipper) =>
    party?.billingDetails?.subscriptionPlan;
  const subscriptionPlan =
    getSubscriptionPlan(forwarder) || getSubscriptionPlan(shipper);
  const show =
    accountType === AccountType.OPERATOR || Boolean(subscriptionPlan);
  if (!show) return null;

  return (
    <StyledDiv>
      <TagForwarder workOrderGroup={workOrderGroup} isWorkOrder={isWorkOrder} />
      <ArrayTooltip array={forwarderLinks}>
        <StyledButton onClick={handleClick}>
          <ObjectIcon recordType="forwarders" active />
          {forwarderCount}
          &nbsp;
          {formatRecordName({
            recordType: RecordType.FORWARDER,
            accountType,
            pluralize: forwarderCount !== 1,
          })}
        </StyledButton>
      </ArrayTooltip>
    </StyledDiv>
  );
};

export default TagForwarderButton;

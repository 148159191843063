import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, message } from 'antd';
import { useMutation } from '@apollo/client';
import {
  getRecordFromResponse,
  updateOneMutation,
  getOneQueryLite,
} from '@xbcb/shared-queries';
import {
  AnyObject,
  KeywordSearchOperator,
  RecordType,
} from '@xbcb/shared-types';
import type {
  WorkOrderTask,
  UpdateWorkOrderTaskInput,
} from '@xbcb/api-gateway-client';
import { CssSize, DataCySuffix } from '@xbcb/ui-types';
import { WorkOrderTaskStatus } from '@xbcb/work-order-types';
import { client, retryMutation } from '@xbcb/apollo-client';
import RecordSelect from 'components/RecordSelect';
import { useWorkOrderTaskType } from 'libs/hooks';
import { useModal } from '@xbcb/ui-utils';
import { StyledActionsDiv, StyledModal } from './styles';
import { ModalKey } from 'types';
import { isEuCustomsEntry } from '@xbcb/work-order-utils/dist/isEuCustomsEntry';

const workOrderTaskQueryLite = getOneQueryLite({
  recordName: RecordType.WORK_ORDER_TASK,
  fields: 'version status',
  queryName: 'getWorkOrderTaskVersionData',
});

// This modal will be refactored after SPF launch to define record type and remove few hooks which are currently required on WOT page
interface AssignWorkOrderTaskModalProps {
  record: AnyObject;
  isInlineTableModal?: boolean;
}

const successMessage = 'Task is succesfully assigned to';
const errorMessage = 'Sorry, an error has occurred. Please try again later.';
const messageTimeOut = 5.0;
const placeholderValue = 'Select user';
const nonAssignmentStates: WorkOrderTaskStatus[] = [
  WorkOrderTaskStatus.COMPLETED,
  WorkOrderTaskStatus.CANCELED,
  WorkOrderTaskStatus.CLOSED,
];

const isValidAssignmentStatus = (status: WorkOrderTaskStatus) =>
  !nonAssignmentStates.includes(status as WorkOrderTaskStatus);

// TODO: isInlineTableModal prop is added to make the change backcompatible. flag will be removed after the launch of SPF and deprecation of current AssignWOT modal design from WOT page
const AssignWorkOrderTaskModal: React.FC<AssignWorkOrderTaskModalProps> = ({
  record,
  isInlineTableModal = false,
}) => {
  const [form] = Form.useForm();
  const [workOrderTaskType] = useWorkOrderTaskType();
  const [isLoading, setIsLoading] = useState(false);
  const retry = useRef<boolean>(true);
  const { closeModal, visible } = useModal(ModalKey.ASSIGN_WORK_ORDER_TASK);

  const fields = 'assignee { name }';
  const recordType = RecordType.WORK_ORDER_TASK;

  const [updateWorkOrderTask, { loading: mutationLoading }] = useMutation(
    updateOneMutation({
      recordName: recordType,
      fields,
    }),
    {
      onError: async (error) => {
        try {
          if (retry.current) {
            await updateWorkOrderTaskHandler(retry.current);
          }
        } catch (e) {
          message.error(errorMessage, messageTimeOut);
          retry.current = false;
        }
        setIsLoading(false);
        if (visible) {
          closeModal();
        }
      },
    },
  );

  const { operator, tasks } = record;
  const operatorId = operator?.id;
  const task = isInlineTableModal
    ? tasks[0]
    : tasks.find(
        (task: WorkOrderTask) =>
          task.definition?.workOrderTaskType === workOrderTaskType,
      );

  const {
    assignee,
    id: taskId,
    name: taskName,
    status,
    definition: taskDefinition,
    assignmentTeam,
  } = task || {};
  const assigneeName = assignee?.name;
  const [taskAssignee, setTaskAssignee] = useState(assigneeName);

  useEffect(() => {
    assigneeName && setTaskAssignee(assigneeName);
  }, [assigneeName]);

  if ((!task?.id || !isValidAssignmentStatus(status)) && visible) {
    return null;
  }

  const updateWorkOrderTaskHandler = async (handleError?: boolean) => {
    setIsLoading(true);
    const { data: latestTaskData } = await client.query({
      query: workOrderTaskQueryLite,
      variables: { id: taskId },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });
    const { version, status } = latestTaskData?.workOrderTask as WorkOrderTask;
    if (isValidAssignmentStatus(status as WorkOrderTaskStatus)) {
      const assignedOperator = form.getFieldValue('operatorUser');
      const input: UpdateWorkOrderTaskInput = {
        assignee: {
          id: assignedOperator.id,
        },
      };
      if (status === WorkOrderTaskStatus.NOT_ASSIGNED) {
        input.status = WorkOrderTaskStatus.ASSIGNED;
      }

      const variables = {
        id: taskId,
        version,
        input,
      };
      const response = handleError
        ? await retryMutation({
            fields,
            id: taskId,
            mutation: updateWorkOrderTask,
            mutationType: 'update',
            mutationVariables: variables,
            recordType,
          })
        : await updateWorkOrderTask({
            variables,
          });
      if (response) {
        const { assignee } = getRecordFromResponse(
          response,
          'update',
          recordType,
        );
        setTaskAssignee(assignee.name);
        message.success(`${successMessage} ${assignee.name}`, messageTimeOut);
        closeModal();
      }

      setIsLoading(false);
    }
    if (isInlineTableModal) {
      form.resetFields();
      closeModal();
    }
  };

  let teamsToBeIncluded: string[] = [];
  if (assignmentTeam) {
    teamsToBeIncluded = assignmentTeam.backupTeam
      ? [assignmentTeam.id, assignmentTeam.backupTeam.id]
      : [assignmentTeam.id];
  }
  const isEuEntry = isEuCustomsEntry(record?.id);
  const searchOperatorUsersInput: AnyObject = {
    operatorId: {
      values: [operatorId],
      operator: KeywordSearchOperator.EQUALS,
    },
    ...(teamsToBeIncluded.length
      ? {
          assignmentTeamId: {
            values: teamsToBeIncluded,
            operator: KeywordSearchOperator.ONE_OF,
          },
        }
      : // if user is not on any assignment team, fall back to filter by user task config
        // TODO: Check with Matt R. if workOrderTaskDefinitionId is safe remove fallback
        // on workOrderTaskDefinitionId now
        {
          workOrderTaskDefinitionId: {
            values: [taskDefinition?.id],
            operator: KeywordSearchOperator.EQUALS,
          },
        }),
  };
  // TODO enable once we have WOT vertical assignments working properly
  // if (industryVertical) {
  //   searchOperatorUsersInput.vertical = {
  //     values: [industryVertical],
  //     operator: KeywordSearchOperator.EQUALS,
  //   };
  // }

  const initialValues: AnyObject = {};
  if (!isInlineTableModal && assignee?.id)
    initialValues.operatorUser = { id: assignee.id };

  return (
    <>
      <StyledModal
        destroyOnClose
        maskClosable={!isLoading || !mutationLoading}
        open={visible}
        onCancel={closeModal}
        width={500}
        closable={isInlineTableModal ? false : true}
        footer={
          <StyledActionsDiv>
            {isInlineTableModal && <Button onClick={closeModal}>Cancel</Button>}
            <Button
              disabled={isLoading || mutationLoading}
              loading={mutationLoading}
              key="assign"
              type="primary"
              onClick={async () => {
                try {
                  await form.validateFields();
                } catch (e) {
                  message.error('Please select an operator.');
                  return;
                }
                await updateWorkOrderTaskHandler();
              }}
            >
              {isInlineTableModal ? 'Submit' : 'Assign task'}
            </Button>
          </StyledActionsDiv>
        }
      >
        <Form
          initialValues={initialValues}
          form={form}
          name="assignWorkOrderTaskForm"
        >
          {!isInlineTableModal ? (
            <>
              <h3>Assign {taskName}</h3>
              {taskAssignee ? (
                <p>Currently assigned to: {taskAssignee}</p>
              ) : (
                <p>Not assigned to anyone</p>
              )}
            </>
          ) : (
            <h3>Assignee</h3>
          )}
          <RecordSelect
            required
            dataCySuffix={DataCySuffix.ASSIGN_WORK_ORDER_TASK}
            additionalSearchCriteria={searchOperatorUsersInput}
            fullNamePath={['operatorUser']}
            form={form}
            hideLabel={true}
            isLoading={isLoading || mutationLoading}
            recordType={RecordType.OPERATOR_USER}
            $itemSize={CssSize.SHORT}
            placeHolder={isInlineTableModal ? placeholderValue : ''}
            pageSize={1000}
          />
        </Form>
      </StyledModal>
    </>
  );
};

export default AssignWorkOrderTaskModal;

import React from 'react';
export default ({ size }) => (
  <svg className="mot-icon" height={size} width={size} viewBox="0 0 256 256">
    <path d="M15.365 95.017v75.51H240.46v-75.51zm28.43 62.756h-5.269V108.27h5.269zm24.808 0h-5.269V108.27h5.269zm24.81 0h-5.268V108.27h5.268zm24.808 0h-5.268V108.27h5.268zm24.816 0h-5.271V108.27h5.27zm24.808 0h-5.268V108.27h5.268zm24.81 0h-5.268V108.27h5.268zm24.808 0h-5.268V108.27h5.268zM255.99 176.325H0v12.98h18.104a17.054 17.054 0 0 0-1.883 7.789c0 9.471 7.674 17.145 17.143 17.145 6.654 0 12.414-3.805 15.251-9.35h8.083c2.837 5.545 8.594 9.35 15.254 9.35 9.469 0 17.142-7.674 17.142-17.145 0-2.81-.687-5.45-1.885-7.79h31.26v12.204h19.222v-12.203h32.646a17.025 17.025 0 0 0-1.888 7.789c0 9.471 7.674 17.145 17.143 17.145 6.657 0 12.414-3.805 15.256-9.35h8.078c2.84 5.545 8.594 9.35 15.254 9.35 9.469 0 17.142-7.674 17.142-17.145 0-2.81-.687-5.45-1.883-7.79H256v-12.98zM33.36 203.43a6.334 6.334 0 0 1 0-12.666 6.336 6.336 0 0 1 6.336 6.33 6.335 6.335 0 0 1-6.336 6.336zm38.586 0a6.337 6.337 0 0 1-6.336-6.336 6.336 6.336 0 0 1 12.671 0 6.335 6.335 0 0 1-6.335 6.336zm113.637 0a6.337 6.337 0 0 1-6.336-6.336 6.336 6.336 0 0 1 12.672 0 6.337 6.337 0 0 1-6.336 6.336zm38.588 0a6.337 6.337 0 0 1-6.336-6.336 6.336 6.336 0 0 1 6.336-6.33 6.336 6.336 0 0 1 6.336 6.33 6.335 6.335 0 0 1-6.336 6.336z" />
    <g transform="matrix(2.57237 0 0 2.57237 -.054 1.732)">
      <circle r="2.418" cy="31.283" cx="30.28" />
      <circle r="2.418" cy="31.283" cx="38.688" />
      <circle r="2.418" cy="31.283" cx="21.74" />
      <circle r="2.418" cy="31.283" cx="47.095" />
      <circle r="2.418" cy="31.283" cx="55.502" />
      <circle r="2.418" cy="17.979" cx="55.502" />
      <circle r="2.418" cy="31.283" cx="63.909" />
      <circle r="2.418" cy="24.523" cx="34.28" />
      <circle r="2.418" cy="24.523" cx="51.095" />
      <circle r="2.418" cy="24.523" cx="59.502" />
      <circle r="2.418" cy="24.523" cx="67.908" />
      <circle r="2.418" cy="31.283" cx="72.316" />
      <circle r="2.418" cy="31.283" cx="80.724" />
    </g>
  </svg>
);

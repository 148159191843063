import type { BulkChargesUploaderResult } from '@xbcb/api-gateway-client';
import bulkUploadPageMaker from 'components/BulkUploadRequest';

const headers = [
  'identifierType',
  'identifier',
  'charge',
  'amount',
  'currency',
  'status',
  'failureReason',
];

const resultMapFunc = (result: BulkChargesUploaderResult) => {
  const { amount, charge, identifier, identifierType, currency } =
    result?.fields || {};
  return {
    identifier,
    identifierType,
    charge,
    amount,
    status: result?.status,
    failureReason: result?.failureReason,
    currency,
  };
};

const BulkChargesUploadRequest = bulkUploadPageMaker({
  headers,
  resultMapFunc,
});

export default BulkChargesUploadRequest;

import React from 'react';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { ConnectionStatus, OperatingRegion } from '@xbcb/shared-types';
import { ModalKey, DataCyPrefix } from '@xbcb/ui-types';
import { ConnectionStatusOperatingRegion } from 'types';
import {
  connectionStatusModalTextMap,
  connectionStatusEventDescriptionMap,
} from './constants';
import { StyledDiv, StyledModal, StyledParagraph } from './style';

interface HistoryEvent {
  status: string;
  time: string;
}

const connectionStatusEventIconMap: {
  [key in ConnectionStatus]: React.ReactNode;
} = {
  [ConnectionStatus.CONNECTED]: (
    <CheckCircleOutlined style={{ color: 'green', margin: '5px', flex: 1 }} />
  ),
  [ConnectionStatus.DISCONNECTED]: (
    <ExclamationCircleOutlined
      style={{ color: 'red', margin: '5px', flex: 1 }}
    />
  ),
  [ConnectionStatus.UNKNOWN]: (
    <ExclamationCircleOutlined
      style={{ color: 'red', margin: '5px', flex: 1 }}
    />
  ),
};

interface ConnectionHistoryEventProps extends HistoryEvent {
  operatingRegion: ConnectionStatusOperatingRegion;
}

const ConnectionHistoryEvent = ({
  status,
  time,
  operatingRegion,
}: ConnectionHistoryEventProps) => {
  return (
    <StyledDiv data-cy={DataCyPrefix.CHANNEL_CONNECTION_HISTORY_EVENT}>
      {connectionStatusEventIconMap[status as ConnectionStatus]}
      <StyledParagraph>{time}</StyledParagraph>
      <StyledParagraph>
        {
          connectionStatusEventDescriptionMap[operatingRegion][
            status as ConnectionStatus
          ]
        }
      </StyledParagraph>
    </StyledDiv>
  );
};

type ChannelConnectionStatusModalProps = {
  closeModal: () => {
    payload: {
      key: ModalKey;
    };
    type: 'CLOSE_MODAL';
  };
  open: boolean;
  channelConnectionStatus: ConnectionStatus;
  connectionHistory: HistoryEvent[];
  operatingRegion: OperatingRegion.US;
};

const ChannelConnectionStatusModal = ({
  closeModal,
  open,
  channelConnectionStatus,
  connectionHistory,
  operatingRegion,
}: ChannelConnectionStatusModalProps): JSX.Element => {
  const sortedConnectionHistory = [...connectionHistory].sort((a, b) =>
    a.time.localeCompare(b.time),
  );

  return (
    <StyledModal
      open={open}
      closable={true}
      width={600}
      footer={null}
      onCancel={closeModal}
    >
      <StyledParagraph data-cy={DataCyPrefix.CHANNEL_CONNECTION_STATUS_MODAL}>
        {(channelConnectionStatus === ConnectionStatus.UNKNOWN ||
          connectionHistory?.length === 0) &&
          connectionStatusModalTextMap[operatingRegion][
            channelConnectionStatus
          ]}
      </StyledParagraph>

      {sortedConnectionHistory?.map((h) => {
        const formattedTime = moment(h.time).isValid()
          ? moment(h.time).local().format('MMMM D YYYY, h:mm a')
          : h.time;
        return (
          <ConnectionHistoryEvent
            status={h.status}
            time={formattedTime}
            operatingRegion={operatingRegion}
            key={`${h.status}-${h.time}`}
          />
        );
      })}
    </StyledModal>
  );
};

export default ChannelConnectionStatusModal;

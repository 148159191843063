import {
  EuCustomsEntryExceptionTaskType,
  WorkOrderType,
} from '@xbcb/work-order-types';
import { completeWorkOrderTaskButton } from './completeWorkOrderTaskButton';
import { markWorkOrderButton } from './markWorkOrdersReadyButton';
import { markWorkOrderHotButton } from './markWorkOrderHotButton';
import { AppRecordKebabMenuItem, AppRecordSubmitButton } from 'routes/types';
import {
  inquireWorkOrderItem,
  unblockWorkOrderItem,
} from './inquireWorkOrderItem';
import { appRecordMemoMenuItem } from './appRecordMemoMenuItem';
import { downloadMilestonesMenuItem } from './downloadKebabMenuItem';
import { appRecordCreateExceptionTaskMenuItem } from './appRecordCreateExceptionTaskMenuItem';
import { appRecordAssignWorkOrderTaskMenuItem } from './appRecordAssignWorkOrderTaskMenuItem';
import { approveEntryConfirmation } from './approveEntryConfirmation';
import { getEnv } from '@xbcb/ui-utils';
import { UiStage } from '@xbcb/ui-types';

const { stage } = getEnv();

export const sharedCustomsEntryFields = `
status
badges
deadline
confirmations {
  id
  status
  disputedReason
  additionalComments
}
milestones { 
  tags {
    key
    value
  }
  id
  name
  occurenceTime
  source
  created {
    client {
      name
    }
  }
}
tasks {
  ...recordFields
  name
  workOrderType
  status
  assignee {
    id
    name
  }
  deadline
  steps {
    id
    name
    description
    isCompletedBySystem
    isFinalConfirmation
    completed {
      ...recordActionFields
    }
  }
  definition {
    id
    estimatedDuration
    workOrderTaskType
  }
}
stateMachine {
  states {
    taskDefinition {
      id
      version
      workOrderTaskType
    }
  }
}
`;

export const sharedCustomsEntryKebabMenuItems: AppRecordKebabMenuItem[] = [
  inquireWorkOrderItem,
  unblockWorkOrderItem,
  appRecordAssignWorkOrderTaskMenuItem,
  appRecordMemoMenuItem,
  downloadMilestonesMenuItem,
  appRecordCreateExceptionTaskMenuItem,
  stage !== UiStage.PROD ? approveEntryConfirmation : undefined,
].filter((item): item is AppRecordKebabMenuItem => Boolean(item));

export const getSharedCustomsEntrySubmitButtons = (
  workOrderType: WorkOrderType,
): AppRecordSubmitButton[] => [
  markWorkOrderButton(workOrderType),
  markWorkOrderHotButton(workOrderType),
  completeWorkOrderTaskButton({
    skipValidationForWorkOrderTaskTypes: Object.values(
      EuCustomsEntryExceptionTaskType,
    ),
    skipUpdateRecordForWorkOrderTaskTypes: Object.values(
      EuCustomsEntryExceptionTaskType,
    ),
  }),
];

import { gql } from '@apollo/client';

export const createTemplateDocumentSignRequest = gql`
  mutation CreateTemplateDocumentSignRequest(
    $idempotencyKey: String!
    $input: CreateTemplateDocumentSignRequestInput!
  ) {
    createTemplateDocumentSignRequest(
      idempotencyKey: $idempotencyKey
      input: $input
    ) {
      record {
        id
        version
        url
        documentTags
      }
    }
  }
`;

import { OperatorUserWorkOrderTaskPermissionName } from '@xbcb/client-types';
import { AnyObject } from '@xbcb/shared-types';
import { WorkOrderTaskStatus } from '@xbcb/work-order-types';
import { setModal } from 'actions';
import { ModalKey } from 'types';
import getQueryParameters from 'libs/getQueryParameters';
import { AppRecordKebabMenuItem } from '../../types';

export const appRecordAssignWorkOrderTaskMenuItem: AppRecordKebabMenuItem = {
  text: 'Assign',
  key: 'assign',
  action: async ({ dispatch }) => {
    dispatch(
      setModal({
        key: ModalKey.ASSIGN_WORK_ORDER_TASK,
        props: {
          visible: true,
        },
      }),
    );
  },
  show: ({ currentUser, existingRecord, location }) => {
    const { workOrderTask } = currentUser?.operatorUserPermissions || {};
    const queryParameters = getQueryParameters(location);
    const workOrderTaskType = queryParameters['task'];
    const isAuthorized =
      Boolean(workOrderTask) &&
      workOrderTask.some(
        ({ name }: { name: string }) =>
          name === OperatorUserWorkOrderTaskPermissionName.ADMIN,
      );
    const { tasks } = existingRecord;
    const task = tasks.find(
      (task: AnyObject) =>
        task.definition?.workOrderTaskType === workOrderTaskType,
    );
    const isActiveWorkOrderTask =
      task &&
      ![WorkOrderTaskStatus.CANCELED, WorkOrderTaskStatus.COMPLETED].includes(
        task.status,
      );
    return workOrderTaskType && isAuthorized && isActiveWorkOrderTask;
  },
};

import React from 'react';
import { Form, Input } from 'antd';
import {
  Option,
  FormItem,
  Select,
  CountrySelect,
  PickDate,
} from '@xbcb/form-item-components';
import {
  selectFilter,
  getCodes,
  shouldUpdate,
  getCountryCodes,
} from '@xbcb/ui-utils';
import { get } from 'lodash';
import { useTheme } from 'styled-components';
import { StyledFlexDiv } from './styles';
import { NamePath } from '@xbcb/ui-types';
import ConstituentElements from 'components/ConstituentElements';
import AphCoreFormLpcos from 'components/AphCoreFormLpcos';
import AphCommodityCodes from 'components/AphCommodityCodes';
import ProductPackaging from 'components/ProductPackaging';
import {
  sourceTypeMap,
  categoryTypeCodesMapping,
  sourceProcessingTypeCodesMapping,
} from 'libs/aphMapping';
import { clearFields } from 'libs/clearFields';
import RecordSelect from 'components/RecordSelect';
import { RecordType } from '@xbcb/shared-types';

interface AphCoreFormProps {
  readOnly?: boolean;
  localNamePath: NamePath;
  fullNamePath: NamePath;
  required?: boolean;
  disabled?: boolean;
}

const AphCoreForm = ({
  readOnly,
  disabled,
  localNamePath,
  fullNamePath,
  required,
}: AphCoreFormProps) => {
  const codes = getCodes();
  const {
    source: sourceTypeCodes,
    product,
    itemIdentifier,
    category,
    processing: processingTypeCodes,
  } = codes.CBP.CATAIR.PGA;
  const form = Form.useFormInstance();
  const theme = useTheme();
  const countryCodes = getCountryCodes();

  const qualifierCodesOptions = Object.entries(product).map(
    ([key, value]: [string, any]) => (
      <Option key={key} value={key}>
        {key} - {value.name}
      </Option>
    ),
  );

  const consumptionEntryType =
    fullNamePath && fullNamePath.includes('invoices');

  const embeddedFields = `name ...addressesFields`;

  const handleCategoryTypeChange = () => {
    clearFields({
      form,
      fullNamePath,
      fields: [
        'LPCO',
        'categoryCode',
        'characteristics',
        'itemIdentity',
        'cropGrower',
      ],
    })();
    clearFields({
      form,
      fullNamePath: [...fullNamePath, 'source'],
    })();
  };

  return (
    <div>
      {/* for PG02*/}
      <StyledFlexDiv>
        <FormItem
          label="Product Code Qualifier"
          name={[...localNamePath, 'productQualifierCode']}
          $readOnly={readOnly}
          $itemSize={theme.size.short}
        >
          <Select
            disabled={disabled}
            showSearch
            allowClear
            filterOption={selectFilter}
            notFoundContent={'None Found'}
            dropdownMatchSelectWidth={false}
          >
            {qualifierCodesOptions}
          </Select>
        </FormItem>
        <FormItem
          name={[...localNamePath, 'aphProductCode']}
          label="Product Code Number"
          $itemSize={theme.size.short}
          $readOnly={readOnly}
          $inline
          debounce
        >
          <Input disabled={disabled} maxLength={19} />
        </FormItem>
      </StyledFlexDiv>

      {/* for PG32 */}
      <StyledFlexDiv>
        <CountrySelect
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          localNamePath={[...localNamePath, 'originCountryCode']}
          label="Commodity Routing Country"
        />
        <FormItem
          name={[...localNamePath, 'politicalSubunitName']}
          label="Commodity Political Subunit of Routing Name"
          $itemSize={theme.size.short}
          $readOnly={readOnly}
          $inline
          debounce
        >
          <Input disabled={disabled} maxLength={55} />
        </FormItem>
      </StyledFlexDiv>
      {/* for PG10 */}
      <StyledFlexDiv>
        <Form.Item
          shouldUpdate={shouldUpdate([[...fullNamePath, 'programCode']])}
          noStyle
        >
          {() => {
            const programCode = form.getFieldValue([
              ...fullNamePath,
              'programCode',
            ]);
            const categoryTypeCodes = categoryTypeCodesMapping[
              programCode
            ]?.map((code) => (
              <Option key={code} value={code}>
                {code} - {get(category, [code, 'name'])}
              </Option>
            ));
            return (
              <FormItem
                $itemSize={theme.size.short}
                label="Category Type Code"
                name={[...localNamePath, 'categoryTypeCode']}
                rules={[{ required, message: ' ' }]}
                $readOnly={readOnly}
              >
                <Select
                  disabled={disabled}
                  showSearch
                  allowClear
                  filterOption={selectFilter}
                  notFoundContent="None Found"
                  dropdownMatchSelectWidth={false}
                  onChange={handleCategoryTypeChange}
                >
                  {categoryTypeCodes}
                </Select>
              </FormItem>
            );
          }}
        </Form.Item>
        <Form.Item
          shouldUpdate={shouldUpdate([[...fullNamePath, 'categoryTypeCode']])}
          noStyle
        >
          {() => {
            const categoryTypeCode = form.getFieldValue([
              ...fullNamePath,
              'categoryTypeCode',
            ]);
            const categoryCodes = get(category, [
              categoryTypeCode,
              'categoryCodes',
            ]);
            const categoryCodesOptions = categoryCodes
              ? Object.entries(categoryCodes).map(
                  ([key, value]: [string, any]) => (
                    <Option key={key} value={key}>
                      {key} - {value.name}
                    </Option>
                  ),
                )
              : [];
            return (
              <FormItem
                $itemSize={theme.size.short}
                label="Category Code"
                name={[...localNamePath, 'categoryCode']}
                rules={[{ required, message: ' ' }]}
                $readOnly={readOnly}
              >
                <Select
                  disabled={disabled}
                  showSearch
                  allowClear
                  filterOption={selectFilter}
                  notFoundContent="None Found"
                  dropdownMatchSelectWidth={false}
                >
                  {categoryCodesOptions}
                </Select>
              </FormItem>
            );
          }}
        </Form.Item>
      </StyledFlexDiv>
      {/* for PG06 */}
      <StyledFlexDiv>
        <Form.Item
          shouldUpdate={shouldUpdate([
            [...fullNamePath, 'programCode'],
            [...fullNamePath, 'categoryTypeCode'],
          ])}
          noStyle
        >
          {() => {
            const programCode = form.getFieldValue([
              ...fullNamePath,
              'programCode',
            ]);
            const categoryTypeCode = form.getFieldValue([
              ...fullNamePath,
              'categoryTypeCode',
            ]);
            const sourceType =
              categoryTypeCode === 'AP0100'
                ? ['267']
                : sourceTypeMap[programCode];
            const sourceTypeOptions = sourceType?.map((codeOption) => (
              <Option key={codeOption} value={codeOption}>
                {codeOption} - {get(sourceTypeCodes, [codeOption, 'name'])}
              </Option>
            ));
            return (
              <FormItem
                $itemSize={theme.size.short}
                label="Source Type"
                name={[...localNamePath, 'source', 'sourceCountryType']}
                rules={[{ required }]}
                $readOnly={readOnly}
              >
                <Select
                  disabled={disabled}
                  showSearch
                  allowClear
                  filterOption={selectFilter}
                  notFoundContent="None Found"
                  dropdownMatchSelectWidth={false}
                >
                  {sourceTypeOptions}
                </Select>
              </FormItem>
            );
          }}
        </Form.Item>
        <CountrySelect
          $itemSize={theme.size.short}
          localNamePath={[...localNamePath, 'source', 'countryCode']}
          required={required}
          label="Country"
          disabled={disabled}
          readOnly={readOnly}
          onChange={clearFields({
            form,
            fullNamePath: [...fullNamePath, 'source'],
            fields: ['geographicLocation'],
          })}
        />

        <Form.Item
          shouldUpdate={shouldUpdate([
            [...fullNamePath, 'source', 'countryCode'],
          ])}
          noStyle
        >
          {() => {
            const currentCountryCode = form.getFieldValue([
              ...fullNamePath,
              'source',
              'countryCode',
            ]);
            const stateOptions = currentCountryCode
              ? Object.entries(countryCodes[currentCountryCode].subdivision)
                  .sort(
                    (
                      [code0, data0]: [string, any],
                      [code1, data1]: [string, any],
                    ) => data0.name.localeCompare(data1.name),
                  )
                  .map(([code, data]: [string, any]) => (
                    <Select.Option key={code} value={code}>
                      {code} - {data.name}
                    </Select.Option>
                  ))
              : [];
            return (
              <FormItem
                label="Geographic Location(State/Province)"
                $itemSize={theme.size.short}
                name={[...localNamePath, 'source', 'geographicLocation']}
                rules={[{ required }]}
                $readOnly={readOnly}
              >
                <Select
                  disabled={disabled}
                  showSearch
                  allowClear
                  filterOption={selectFilter}
                  notFoundContent="None Found"
                >
                  {stateOptions}
                </Select>
              </FormItem>
            );
          }}
        </Form.Item>
      </StyledFlexDiv>

      <Form.Item
        shouldUpdate={shouldUpdate([
          [...fullNamePath, 'programCode'],
          [...fullNamePath, 'categoryTypeCode'],
        ])}
        noStyle
      >
        {() => {
          const programCode = form.getFieldValue([
            ...fullNamePath,
            'programCode',
          ]);
          const categoryTypeCode = form.getFieldValue([
            ...fullNamePath,
            'categoryTypeCode',
          ]);
          const processingObj =
            sourceProcessingTypeCodesMapping[programCode]?.[categoryTypeCode];
          if (processingObj) {
            const processingTypeCodesOptions = processingObj.typeCodes.map(
              (code) => (
                <Option key={code} value={code}>
                  {code} - {get(processingTypeCodes, [code, 'name'])}
                </Option>
              ),
            );
            return (
              <StyledFlexDiv>
                <FormItem
                  $itemSize={theme.size.short}
                  label="Processing Type Code"
                  name={[...localNamePath, 'source', 'processingTypeCode']}
                  rules={[{ required }]}
                  $readOnly={readOnly}
                >
                  <Select
                    disabled={disabled}
                    showSearch
                    allowClear
                    filterOption={selectFilter}
                    notFoundContent="None Found"
                    dropdownMatchSelectWidth={false}
                  >
                    {processingTypeCodesOptions}
                  </Select>
                </FormItem>
                <PickDate
                  readOnly={readOnly}
                  disabled={disabled}
                  required={required}
                  fullNamePath={[
                    ...fullNamePath,
                    'source',
                    `processing${processingObj.dateField}Date`,
                  ]}
                  localNamePath={[
                    ...localNamePath,
                    'source',
                    `processing${processingObj.dateField}Date`,
                  ]}
                  label={`Processing ${processingObj.dateField} Date`}
                  disabledDate={(m: any) => false}
                  form={form}
                />
              </StyledFlexDiv>
            );
          } else {
            return null;
          }
        }}
      </Form.Item>

      {/* for PG07 */}
      <Form.Item
        shouldUpdate={shouldUpdate([[...fullNamePath, 'categoryTypeCode']])}
        noStyle
      >
        {() => {
          const categoryTypeCode = form.getFieldValue([
            ...fullNamePath,
            'categoryTypeCode',
          ]);
          if (['AP0100', 'AP0800'].includes(categoryTypeCode)) {
            const itemIdentityQualifierCodes =
              categoryTypeCode === 'AP0100'
                ? ['LAT', 'BND', 'BRD', 'CHP', 'NMT', 'RID', 'SRY', 'SRX']
                : ['BQG'];

            const QualifierCodesOptions = itemIdentityQualifierCodes.map(
              (codeOption) => (
                <Option key={codeOption} value={codeOption}>
                  {codeOption} - {get(itemIdentifier, [codeOption, 'name'])}
                </Option>
              ),
            );
            return (
              <StyledFlexDiv>
                <FormItem
                  $itemSize={theme.size.short}
                  label="Item Identity Number Qualifier"
                  name={[...localNamePath, 'itemIdentity', 'qualifierCode']}
                  rules={[{ required, message: ' ' }]}
                  $readOnly={readOnly}
                >
                  <Select
                    disabled={disabled}
                    showSearch
                    allowClear
                    filterOption={selectFilter}
                    notFoundContent="None Found"
                    dropdownMatchSelectWidth={false}
                  >
                    {QualifierCodesOptions}
                  </Select>
                </FormItem>
                <FormItem
                  name={[...localNamePath, 'itemIdentity', 'value']}
                  label="Item Identity Number"
                  $itemSize={theme.size.short}
                  $readOnly={readOnly}
                  $inline
                  debounce
                >
                  <Input disabled={disabled} maxLength={17} />
                </FormItem>
              </StyledFlexDiv>
            );
          } else {
            return;
          }
        }}
      </Form.Item>

      {/* for PG19 */}
      <Form.Item
        shouldUpdate={shouldUpdate([[...fullNamePath, 'categoryTypeCode']])}
        noStyle
      >
        {() => {
          const categoryTypeCode = form.getFieldValue([
            ...fullNamePath,
            'categoryTypeCode',
          ]);
          return (
            ['AP0800'].includes(categoryTypeCode) &&
            (consumptionEntryType ? (
              <RecordSelect
                label="Grower"
                fullNamePath={[...fullNamePath, 'cropGrower', 'supplier']}
                localNamePath={[...localNamePath, 'cropGrower', 'supplier']}
                recordType={RecordType.SUPPLIER}
                form={form}
                create={true}
                disabled={disabled}
                readOnly={readOnly}
                $itemSize={theme.size.short}
                versioned
                embeddedFields={embeddedFields}
                mapEmbeddedFields={(record: any) => {
                  const { addresses, ...newRecord } = record;
                  newRecord.address = addresses?.mailing || addresses?.physical;
                  return newRecord;
                }}
              />
            ) : (
              <RecordSelect
                label="Grower"
                fullNamePath={[...fullNamePath, 'cropGrower']}
                localNamePath={[...localNamePath, 'cropGrower']}
                recordType={RecordType.SUPPLIER}
                form={form}
                create={true}
                disabled={disabled}
                readOnly={readOnly}
                $itemSize={theme.size.short}
              />
            ))
          );
        }}
      </Form.Item>

      <AphCommodityCodes
        form={form}
        disabled={disabled}
        readOnly={readOnly}
        fullNamePath={[...fullNamePath, 'characteristics']}
        localNamePath={[...localNamePath, 'characteristics']}
        required={required}
      />
      {/* for PG05 */}
      <ConstituentElements
        form={form}
        disabled={disabled}
        readOnly={readOnly}
        fullNamePath={[...fullNamePath, 'constituentElements']}
        localNamePath={[...localNamePath, 'constituentElements']}
        required={required}
      />
      {/* PG13 & PG14 */}
      <AphCoreFormLpcos
        form={form}
        disabled={disabled}
        readOnly={readOnly}
        fullNamePath={[...fullNamePath, 'LPCO']}
        localNamePath={[...localNamePath, 'LPCO']}
        required={required}
      />
      {/* for PG19 */}
      {consumptionEntryType ? (
        <RecordSelect
          label="LPCO Authorized Party(Required if LPCO is reported)"
          fullNamePath={[...fullNamePath, 'lpcoAuthorizedParty', 'facility']}
          localNamePath={[...localNamePath, 'lpcoAuthorizedParty', 'facility']}
          recordType={RecordType.FACILITY}
          form={form}
          create={true}
          disabled={disabled}
          readOnly={readOnly}
          $itemSize={theme.size.short_medium}
          versioned
          embeddedFields={embeddedFields}
          mapEmbeddedFields={(record: any) => {
            const { addresses, ...newRecord } = record;
            newRecord.address = addresses?.mailing || addresses?.physical;
            return newRecord;
          }}
        />
      ) : (
        <RecordSelect
          label="LPCO Authorized Party(Required if LPCO is reported)"
          fullNamePath={[...fullNamePath, 'lpcoAuthorizedParty']}
          localNamePath={[...localNamePath, 'lpcoAuthorizedParty']}
          recordType={RecordType.FACILITY}
          form={form}
          create
          disabled={disabled}
          readOnly={readOnly}
          $itemSize={theme.size.short_medium}
        />
      )}
      {/* for PG26 */}
      <ProductPackaging
        disabled={disabled}
        readOnly={readOnly}
        fullNamePath={[...fullNamePath, 'packaging']}
        localNamePath={[...localNamePath, 'packaging']}
        form={form}
        optional={false}
        required
        agencyCode={'APH'}
      />
    </div>
  );
};

export default AphCoreForm;

import { usConsigneeFragments } from '@xbcb/party-queries';
import { UsIorCbpStatus } from '@xbcb/party-types';
import { AccountType, RecordType } from '@xbcb/shared-types';
import { executeMutation } from 'libs/executeMutation';
import { transformParty } from 'libs/formTransforms';
import {
  createUsConsigneeCbp5106,
  queryUsConsigneeCbp,
  updateUsConsigneeCbp5106,
} from 'libs/sharedQueries';
import validateUsConsignee from 'libs/validateUsConsignee';
import { Consignee } from '../../loadableComponents';
import { AppRecordRoute } from '../../types';
import { appRecordAbiDataMenuItem } from '../utils';

const consigneeRoute: AppRecordRoute = {
  Page: Consignee,
  recordType: RecordType.US_CONSIGNEE,
  fields: `...usConsigneeFields`,
  fragments: usConsigneeFragments,
  transformUpdateRecordInput: transformParty.toSchema,
  transformRecordToInput: transformParty.toForm,
  validateUpdate: validateUsConsignee,
  kebabMenuItems: [
    appRecordAbiDataMenuItem,
    {
      key: 'queryUsConsignee',
      text: 'Query CBP',
      show: ({ existingRecord, currentUser }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        existingRecord?.iorNumber?.value,
      action: async ({ record }) => {
        await executeMutation({
          mutation: queryUsConsigneeCbp,
          variables: { usConsigneeId: record.id },
        });
      },
    },
    {
      key: 'createUsConsigneeCbp5106',
      text: 'Add 5106',
      show: ({ existingRecord, currentUser }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        // must be queried first (which sets CBP status)
        [UsIorCbpStatus.NOT_ON_FILE, UsIorCbpStatus.INACTIVE].includes(
          existingRecord?.cbpStatus,
        ) &&
        existingRecord?.iorNumber?.value,
      action: async ({ record }) => {
        await executeMutation({
          mutation: createUsConsigneeCbp5106,
          variables: { usConsigneeId: record.id },
        });
      },
    },
    {
      key: 'updateUsConsigneeCbp5106',
      text: 'Update 5106',
      show: ({ existingRecord, currentUser }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        // must be queried first (which sets CBP status)
        [
          UsIorCbpStatus.ACTIVE_WITH_BOND,
          UsIorCbpStatus.ACTIVE_NO_BOND,
        ].includes(existingRecord?.cbpStatus) &&
        existingRecord?.iorNumber?.value,
      action: async ({ record }) => {
        await executeMutation({
          mutation: updateUsConsigneeCbp5106,
          variables: { usConsigneeId: record.id },
        });
      },
    },
  ],
};

export default consigneeRoute;

import React from 'react';
import { Input, Checkbox } from 'antd';
import { get } from 'lodash';
import { getCodes, show } from '@xbcb/ui-utils';
import { FormInstance } from 'antd/lib/form';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { FormItem } from '@xbcb/form-item-components';
import { AocCode, aocFieldsMap } from './aocFields';

interface AocProps {
  form: FormInstance;
  code: AocCode;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  required?: boolean;
  size?: CssSize;
  inline?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  mask?: any;
  placeholder?: string;
  checkbox?: boolean;
  children?: any;
}
const Aoc = ({
  code,
  children,
  form,
  fullNamePath,
  localNamePath,
  required,
  size,
  inline,
  readOnly,
  disabled,
  mask,
  placeholder,
  checkbox,
}: AocProps) => {
  const aocCodes = get(getCodes(), 'USFDA.compliance', {});

  const label = get(aocCodes, [code, 'name']);

  const showAOC =
    !checkbox ||
    show({ readOnly, form, field: [...fullNamePath, ...aocFieldsMap[code]] });

  if (!showAOC) return null;

  return (
    <FormItem
      $itemSize={size}
      $inline={inline}
      label={checkbox ? undefined : label}
      rules={[{ required: !checkbox && required, message: ' ' }]}
      valuePropName={checkbox ? 'checked' : 'value'}
      getValueFromEvent={mask}
      $readOnly={readOnly}
      name={[...localNamePath, ...aocFieldsMap[code]]}
    >
      {children ||
        (checkbox ? (
          <Checkbox disabled={disabled}>{label}</Checkbox>
        ) : (
          <Input disabled={disabled} placeholder={placeholder} />
        ))}
    </FormItem>
  );
};

export default Aoc;

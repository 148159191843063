import { UsCbpEntryFeeType, UsCbpEntryTaxType } from '@xbcb/compliance-types';
import { BiStringMap } from '@xbcb/js-utils';

export const usCbpEntryFeeCodeToTypeMap = new BiStringMap({
  '311': UsCbpEntryFeeType.INFORMAL_MERCHANDISE_PROCESSING_FEE,
  '496': UsCbpEntryFeeType.DUTIABLE_MAIL_FEE,
  '500': UsCbpEntryFeeType.MANUAL_ENTRY_SURCHARGE,
  '053': UsCbpEntryFeeType.BEEF_FEE,
  '054': UsCbpEntryFeeType.PORK_FEE,
  '055': UsCbpEntryFeeType.HONEY_FEE,
  '056': UsCbpEntryFeeType.COTTON_FEE,
  '057': UsCbpEntryFeeType.RASPBERRY_FEE,
  '079': UsCbpEntryFeeType.SUGAR_FEE,
  '090': UsCbpEntryFeeType.POTATO_FEE,
  '102': UsCbpEntryFeeType.LIME_FEE,
  '103': UsCbpEntryFeeType.MUSHROOM_FEE,
  '104': UsCbpEntryFeeType.WATERMELON_FEE,
  '105': UsCbpEntryFeeType.SHEEP_FEE,
  '106': UsCbpEntryFeeType.BLUEBERRY_FEE,
  '107': UsCbpEntryFeeType.AVOCADO_FEE,
  '108': UsCbpEntryFeeType.MANGO_FEE,
  '109': UsCbpEntryFeeType.SORGHUM_FEE,
  '110': UsCbpEntryFeeType.DAIRY_PRODUCT_FEE,
  '499': UsCbpEntryFeeType.FORMAL_MERCHANDISE_PROCESSING_FEE,
  '501': UsCbpEntryFeeType.HARBOR_MAINTENANCE_FEE,
  '672': UsCbpEntryFeeType.COFFEE_IMPORTS_TO_PUERTO_RICO,
});

export const usCbpEntryTaxCodeToTypeMap = new BiStringMap({
  '016': UsCbpEntryTaxType.DISTILLED_SPIRIT_TAX,
  '017': UsCbpEntryTaxType.WINE_TAX,
  '018': UsCbpEntryTaxType.TOBACCO_TAX,
  '022': UsCbpEntryTaxType.OTHER_TAX,
});

import { RecordType } from '@xbcb/shared-types';
import { EuIor } from '../../loadableComponents';
import euIorRoute from './euIor';
import { frIorFragments } from '@xbcb/party-queries';

const frIorRoute = euIorRoute({
  page: EuIor,
  recordType: RecordType.FR_IOR,
  fields: `...frIorFields`,
  fragments: frIorFragments,
});

export default frIorRoute;

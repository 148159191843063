import React from 'react';
import { Modal, message, Form } from 'antd';
import { useModal } from '@xbcb/ui-utils';
import { ModalKey } from 'types';
import { FormInstance } from 'antd/lib/form';
import { AppRecordOnConfirmPayloadConfirmationData } from 'routes';

interface ConstructConfirmationDataProps {
  form: FormInstance;
}
interface CancelModalBaseProps {
  modalKey: ModalKey;
  modalTitle: string;
  className: string;
  allowCancel: boolean;
  ModalContent: React.FC<{ form: FormInstance }>;
  constructConfirmationData?: (
    props: ConstructConfirmationDataProps,
  ) => AppRecordOnConfirmPayloadConfirmationData;
}

const CancelModalBase: React.FC<CancelModalBaseProps> = ({
  modalKey,
  className,
  modalTitle,
  allowCancel,
  ModalContent,
  constructConfirmationData,
}) => {
  const [form] = Form.useForm();
  const { closeModal, visible, modalProps } = useModal(modalKey);
  const { sendConfirmationDecision } = modalProps;

  const onCancel = () => {
    sendConfirmationDecision({ canceled: true });
    closeModal();
  };
  return (
    <Modal
      okButtonProps={{
        disabled: !allowCancel,
        style: { display: !allowCancel ? 'none' : 'inline' }, // we want to hide ok button when not allow to cancel
      }}
      open={visible}
      className={className}
      width={'580px'}
      title={modalTitle}
      cancelText={!allowCancel ? 'Ok' : 'Back'} // when not allow to cancel, display cancel button with 'OK'
      onCancel={onCancel}
      destroyOnClose
      onOk={async () => {
        try {
          await form.validateFields();
        } catch (e) {
          message.error('Please fill out all the fields.');
          return;
        }
        let confirmationData;
        if (constructConfirmationData) {
          confirmationData = constructConfirmationData({ form });
        }
        sendConfirmationDecision({
          canceled: false,
          confirmationData,
        });
        closeModal();
      }}
    >
      <Form form={form}>
        <ModalContent form={form} />
      </Form>
    </Modal>
  );
};

export default CancelModalBase;

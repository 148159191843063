import { capitalCase, noCase } from 'change-case';
import type { Address } from '@xbcb/api-gateway-client';
import { AdditionalFormError } from '@xbcb/ui-types';

interface ValidateAddressParams {
  addressType: 'mailing' | 'physical';
  addressObject?: Address;
  shouldValidateStateCode?: boolean;
}

export const validateAddress = (
  params: ValidateAddressParams,
): AdditionalFormError[] => {
  const additionalErrors: { title: string; messages: string[] }[] = [];
  const { addressType, addressObject, shouldValidateStateCode = true } = params;
  const { address, countryCode, postalCode, city, stateCode } =
    addressObject || {};
  const messages: string[] = [];
  const addAddressErrorToMessages = (addressPart: string) => {
    messages.push(
      `Importer of Record must have a mailing ${noCase(addressPart)}`,
    );
  };
  if (!address) addAddressErrorToMessages('Address');
  if (!countryCode) addAddressErrorToMessages('Country');
  if (!postalCode) addAddressErrorToMessages('Postal Code');
  if (!city) addAddressErrorToMessages('City');
  if (shouldValidateStateCode && !stateCode) addAddressErrorToMessages('State');
  if (messages.length) {
    additionalErrors.push({
      title: `Missing ${capitalCase(addressType)}`,
      messages,
    });
  }
  return additionalErrors;
};

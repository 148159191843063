import { AutoComplete } from 'antd';
import styled from 'styled-components';

export const StyledAutoComplete = styled(AutoComplete)<{ $readOnly?: boolean }>`
  ${({ $readOnly }) =>
    $readOnly &&
    `&&& {
      .ant-select-selector{
        .ant-select-selection-search {
          left:-2px;
        }
      }
  }`};
`;

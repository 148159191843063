import { Button, Dropdown, Menu } from 'antd';
import { useMutation } from '@apollo/client';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import type {
  Forwarder,
  ForwarderUser,
  Ior,
  Tag,
  UsIor,
} from '@xbcb/api-gateway-client';
import { getRecordType } from '@xbcb/core';
import { RecordType, TagKey } from '@xbcb/shared-types';
import { documentTypeToSignLinkTypeMap } from 'libs/documentTypeMap';
import { linkModalContent, openLinkModal } from 'libs/linkModal';
import { createTemplateDocumentSignRequest } from 'libs/sharedQueries';
import { validateIorSignLink } from 'libs/validateIorSignLinks';
import { DocumentTag, DocumentTemplateType } from '@xbcb/document-types';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

export type SignLinkButtonProps = {
  ior: Ior;
  forwarders: Forwarder[];
  dsrTags: Tag[];
};

// Forwarder that created the IOR profile should be able to view the direct poa and cc documents.
// See: https://t.corp.amazon.com/V913355627
export const addForwarderIdTag = (tags: Tag[], ior: Ior) => {
  const createdUserId = ior?.created?.client?.id;
  if (
    createdUserId &&
    getRecordType(createdUserId) === RecordType.FORWARDER_USER
  ) {
    const forwarderId = (ior?.created?.client as ForwarderUser)?.forwarder?.id;
    if (forwarderId) {
      tags.push({
        key: TagKey.FORWARDER_ID,
        value: forwarderId,
      });
    }
  }
  return tags;
};

const SignLinkButton: React.FC<SignLinkButtonProps> = ({
  ior,
  forwarders,
  dsrTags,
}) => {
  const [partyBundle] = useBundle('components.Party');
  const [createTempalteDsr] = useMutation(createTemplateDocumentSignRequest, {
    onCompleted: (data: any) => {
      const {
        createTemplateDocumentSignRequest: { record },
      } = data;
      const modalContent = linkModalContent({
        heading: safeGetMessage(partyBundle, 'sign_link_created'),
        link: record.url,
        type: documentTypeToSignLinkTypeMap.get(record.documentTags[0]),
      });
      openLinkModal(modalContent);
    },
  });
  const poaIsSigned = !!(ior as UsIor).documentSignRequests?.some(
    (dsr) =>
      dsr.signed &&
      dsr.documentTags.some(
        (docTag) => docTag === DocumentTag.DIRECT_POWER_OF_ATTORNEY,
      ),
  );
  // we should only show Corporate Certification if the IOR is outside the US and if the POA has been signed
  const showCC =
    poaIsSigned &&
    ior.addresses?.mailing?.countryCode &&
    ior.addresses?.mailing?.countryCode !== 'US';

  const onClick = async ({ key }: any) => {
    if (!validateIorSignLink(ior, key)) {
      return;
    }
    let templateType;
    if (key === 'direct-poa') {
      templateType = DocumentTemplateType.DIRECT_POWER_OF_ATTORNEY;
    } else if (key === 'cc') {
      templateType = DocumentTemplateType.CORPORATE_CERTIFICATION;
    }
    createTempalteDsr({
      variables: {
        idempotencyKey: uuidV4(),
        input: {
          tags: addForwarderIdTag(dsrTags, ior),
          documentTags: [templateType],
        },
      },
    });
  };

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="direct-poa">
        {safeGetMessage(partyBundle, 'direct_poa')}
      </Menu.Item>
      {showCC && (
        <Menu.Item key="cc">
          {safeGetMessage(partyBundle, 'corporate_certification')}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button>{safeGetMessage(partyBundle, 'plus_sign_link')}</Button>
    </Dropdown>
  );
};

export default SignLinkButton;

import { DocumentNode, GraphQLError } from 'graphql';
import { message } from 'antd';
import { v4 as uuidV4 } from 'uuid';
import { client as apolloClient } from '@xbcb/apollo-client';
import { AnyObject } from '@xbcb/shared-types';
import { reportError } from '@xbcb/ui-utils';

type RefetchQueriesIncludeShorthand = 'all' | 'active';
export type RefetchQueryDescriptor =
  | { query: DocumentNode; variables: AnyObject }
  | string
  | DocumentNode;
export type RefetchQueriesInclude =
  | RefetchQueryDescriptor[]
  | RefetchQueriesIncludeShorthand;

type ExecuteMutationProps = {
  mutation: DocumentNode;
  variables: AnyObject;
  refetchQueries?: RefetchQueriesInclude;
  successMessage?: string;
  constructSuccessMessage?: (data: AnyObject) => string;
  // Function to run after a successful mutation. Passed-in data is likely to
  // be the response Payload type of mutation.  Will only run if there are no
  // errors.
  onSuccess?: (data: AnyObject) => Promise<void>;
  // Function that can be used to handle particular errors. A boolean
  // representing whether to proceed with the default error handling or not
  // must be returned. For example `true` when the custom error was not found
  // and `false` if it was handled properly.
  attemptCustomErrorHandling?: (errors: readonly GraphQLError[]) => boolean;
  shouldThrowError?: boolean;
};

// This is a helper function that calls a given mutation with the
// variables provided and handles the response. Given the logic was needed so
// much throughout `/appRecordRoutes` it was consolidated to a helper function.
// It may also be helpful in other places as well.
export const executeMutation = async ({
  mutation,
  variables,
  refetchQueries,
  successMessage = 'Transaction created',
  constructSuccessMessage,
  attemptCustomErrorHandling,
  shouldThrowError,
  onSuccess,
}: ExecuteMutationProps): Promise<void> => {
  try {
    const { errors, data } = await apolloClient.mutate({
      mutation,
      variables: { ...variables, idempotencyKey: uuidV4() },
      errorPolicy: shouldThrowError ? undefined : 'all', // this means any errors returned will not throw and are handled directly below
      refetchQueries, // automatically refetches queries after the mutation is fired
    });
    const duration = 5.0;
    if (errors) {
      if (!attemptCustomErrorHandling || attemptCustomErrorHandling(errors)) {
        message.error(
          `Sorry, the transaction failed, please try again later.`,
          duration,
        );
        // surface specific error messages in addition to generic error above
        errors.forEach(
          (error) => error.message && message.error(error.message, duration),
        );
      }
    } else {
      if (constructSuccessMessage) {
        message.success(constructSuccessMessage(data), duration);
      } else if (successMessage) {
        message.success(successMessage, duration);
      }
      if (onSuccess) await onSuccess(data);
    }
  } catch (e) {
    reportError(e);
    if (shouldThrowError) {
      throw e;
    }
  }
};

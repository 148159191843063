import {
  WorkOrderStatus,
  usCbpEntryControlStatusCode,
} from '@xbcb/work-order-types';
import { RecordStatus, AnyObject } from '@xbcb/shared-types';

export const isRecordCanceled = (record: AnyObject): boolean =>
  record.status === RecordStatus.CANCELED;

export const isRecordReplaced = (record: AnyObject): boolean =>
  record?.status === WorkOrderStatus.REPLACED;

export const isRecordDeleted = (record: AnyObject): boolean =>
  Boolean(record?.deleted);

export const hasEntrySummaryStatus = (record: AnyObject): boolean =>
  Boolean(record?.summaryStatus);

export const hasEntryReleaseStatus = (record: AnyObject): boolean =>
  Boolean(record?.releaseStatus);

export const hasEntryNumber = (record: AnyObject): boolean =>
  Boolean(record?.entryNumber);

export const hasBrokerNumber = (record: AnyObject): boolean =>
  Boolean(record?.brokerNumber);

export const isEntryUnderCBPControl = (record: AnyObject): boolean =>
  record?.controlStatus === usCbpEntryControlStatusCode.CBP;

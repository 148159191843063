import { InputNumber } from 'antd';
import styled from 'styled-components';
import { FormItem } from '@xbcb/form-item-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledDiv = styled.div`
  display: inline-block;
  margin-top: ${CssSpace.SPACE_4};
  margin-right: ${CssSpace.SPACE_4};
  line-height: 30px;
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const StyledWrapper = styled.div`
  display: inline-block;
`;

export const StyledFormItem = styled(FormItem)<{ $readOnly?: boolean }>`
  ${({ $readOnly }) =>
    $readOnly &&
    `.ant-input-number-group-addon {
      background: none;
      border: none;
    }`}
`;

export const isComponent = (intendedUseCode) => {
  return ['081.001', '081.007', '081.008'].includes(intendedUseCode);
};

export const isImportForExport = (intendedUseCode) => {
  return ['170.000', '970.000', '970.001'].includes(intendedUseCode);
};

export const isKit = (intendedUseCode) => {
  return ['081.003', '081.004'].includes(intendedUseCode);
};

import { fetchLatestRecord } from '@xbcb/apollo-client';
import { DocumentNode } from 'graphql';
import { executeMutation, RefetchQueriesInclude } from 'libs/executeMutation';
import { AnyObject, RecordType } from '@xbcb/shared-types';

type FetchLatestRecordAndExecuteMutationProps = {
  id: string;
  recordType: RecordType;
  mutation: DocumentNode;
  fields?: string;
  constructVariablesWithIdVersion: (id: string, version: number) => AnyObject;
  successMessage?: string;
  shouldThrowError?: boolean;
  constructSuccessMessage?: (data: AnyObject) => string;
  shouldSkipMutation?: (latestRecord: AnyObject) => boolean;
  refetchQueries?: RefetchQueriesInclude;
};

// This is a helper function that fetch the latest record from server and execute
// mutation with latest version and other variables
export const fetchLatestRecordAndExecuteMutation = async ({
  mutation,
  id,
  recordType,
  fields,
  constructVariablesWithIdVersion,
  successMessage,
  constructSuccessMessage,
  shouldSkipMutation,
  shouldThrowError = false,
  refetchQueries = [],
}: FetchLatestRecordAndExecuteMutationProps): Promise<void> => {
  const latestRecord = await fetchLatestRecord({
    id,
    recordType,
    fields: fields || '',
  });
  const variables = constructVariablesWithIdVersion(id, latestRecord.version);
  const willSkipMutation =
    shouldSkipMutation && shouldSkipMutation(latestRecord);
  if (!willSkipMutation) {
    await executeMutation({
      mutation,
      variables,
      constructSuccessMessage,
      successMessage,
      shouldThrowError,
      refetchQueries,
    });
  }
};

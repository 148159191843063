import { CssSpace } from '@xbcb/ui-types';
import styled from 'styled-components';

type StyledDivProps = {
  $highlight?: boolean;
};
export const StyledDiv = styled.div<StyledDivProps>`
  .ant-form-item-control-input-content {
    ${({ $highlight }) => $highlight && 'border: 1.5px solid blue;'}
  }
  display: inline;
`;

type StyledButtonProps = {
  $inline?: boolean;
};
export const StyledButton = styled.button<StyledButtonProps>`
  margin-top: 10px;
  margin-left: ${CssSpace.SPACE_4};
  display: ${({ $inline }) => ($inline ? 'inline' : 'none')};
`;

export const StyledSpan = styled.span`
  margin-right: 10px;
  color: #000000;
  font-size: medium;
`;

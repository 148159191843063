import { gql } from '@apollo/client';

export const reviewWorkOrderConfirmation = gql`
  mutation ReviewWorkOrderConfirmation(
    $id: ID!
    $status: WorkOrderConfirmationReviewApprovalStatus!
    $disputedReason: String
  ) {
    reviewWorkOrderConfirmation(
      id: $id
      status: $status
      disputedReason: $disputedReason
    ) {
      workOrderConfirmation {
        id
        version
        status
      }
    }
  }
`;

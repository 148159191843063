import React from 'react';
import { StyledInputNumber } from './styles';
import { FormItem } from '@xbcb/form-item-components';
import {
  CssSize,
  NamePath,
  createDataCyValue,
  DataCyPrefix,
} from '@xbcb/ui-types';

export type QuantityProps = {
  field: NamePath;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  $itemSize?: string;
  hideLabel?: boolean;
  required?: boolean;
  onChange?: (value: number | undefined) => void;
  min?: number;
  max?: number;
  formatter?: (value: number | string | undefined) => string;
  debounce?: boolean;
  parser?: (displayValue: string | undefined) => number | string;
  className?: string;
  dataCySuffix?: string;
};

const Quantity: React.FC<QuantityProps> = ({
  field,
  label = 'Quantity',
  disabled,
  readOnly,
  // TODO this seems too small as a default but pretty large blast radius to
  // change without having the time to deep dive. Look into which components
  // rely on the default. See if it makes sense for them to be `TINY` instead.
  // Then ideally change this to `TINY.
  $itemSize = CssSize.MICRO_TINY,
  hideLabel,
  required = false,
  onChange,
  min = 0,
  max = 1000000,
  formatter,
  debounce = false,
  parser,
  dataCySuffix,
}) => (
  <FormItem
    $itemSize={$itemSize}
    $inline
    label={hideLabel ? undefined : label}
    name={field}
    rules={[{ required, message: ' ' }]}
    $readOnly={readOnly}
    debounce={debounce}
    data-cy={createDataCyValue(DataCyPrefix.QUANTITY, dataCySuffix)}
  >
    <StyledInputNumber
      disabled={disabled}
      onChange={(val) =>
        onChange && (typeof val === 'number' ? onChange(val) : undefined)
      }
      aria-label={label}
      max={max}
      min={min}
      formatter={formatter}
      parser={parser}
      precision={0}
    />
  </FormItem>
);

export default Quantity;

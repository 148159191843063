import { get, uniqBy } from 'lodash';
import React, { memo } from 'react';
import type { AdditionalHts } from '@xbcb/api-gateway-client';
import { AccountType, AnyObject } from '@xbcb/shared-types';
import { NamePath } from '@xbcb/ui-types';
import { shouldUpdate } from '@xbcb/ui-utils';
import { Tooltip } from 'antd';
import ButterContent from 'components/ButterContent';
import LoadingSpinner from 'components/LoadingSpinner';
import ObjectIcon from 'components/ObjectIcon';
import additionalHTSTypes from 'libs/additionalHTSTypes';
import dutyComputation from 'libs/dutyDisplay';
import { HtsObject } from 'types';
import { showHTSModal } from 'libs/showHTSModal';
import {
  StyledAdditionalHtsButton,
  StyledFlexDiv,
  StyledHtsDutyParagraph,
  StyledHtsSummaryDiv,
  StyledLoadingWrapper,
  StyledObjIconDiv,
  StyledSectionFormItem,
  StyledTextParagraph,
  StyledWarningOutlined,
} from './styles';

export interface LineItemHTSSummaryProps {
  accountType?: AccountType;
  additional232ExclusionNumber: string;
  fullNamePath: NamePath;
  htsNumber: string;
  htsObj?: HtsObject;
  htsValues: AnyObject;
  index: number;
  loading: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  addAdditionalTariff: (index: number, tariff: AnyObject) => void;
  isSection232: (tariffType: string) => boolean;
  makeHtsValues: () => AnyObject;
  removeAdditionalTariff: (index: number) => void;
}

const openHTS = (htsNumber: string): void => {
  // id is not available here
  showHTSModal(null, htsNumber);
};

const LineItemHTSSummary: React.FC<LineItemHTSSummaryProps> = ({
  accountType,
  addAdditionalTariff,
  additional232ExclusionNumber,
  fullNamePath,
  htsNumber,
  htsValues,
  htsObj,
  index,
  loading,
  readOnly,
  isSection232,
  makeHtsValues,
  removeAdditionalTariff,
}) => {
  if (loading && !htsObj)
    return (
      <StyledLoadingWrapper>
        <LoadingSpinner size="small" />
      </StyledLoadingWrapper>
    );

  return (
    <StyledHtsSummaryDiv
      data-testid={'line-item-hts-summary'}
      readOnly={readOnly}
    >
      {htsObj && (
        <Tooltip title={htsObj.fullText} placement="top">
          <StyledTextParagraph>{htsObj.text}</StyledTextParagraph>
        </Tooltip>
      )}
      {htsObj && (
        <StyledFlexDiv>
          <StyledHtsDutyParagraph>
            Duty:{' '}
            {dutyComputation({
              htsNumber: htsNumber,
              ...htsObj,
            })}
          </StyledHtsDutyParagraph>
          <StyledObjIconDiv data-testid="object-icon">
            <ObjectIcon
              recordType={'hts'}
              height={18}
              active={true}
              onClick={() => openHTS(htsNumber)}
            />
          </StyledObjIconDiv>
          <StyledSectionFormItem
            shouldUpdate={shouldUpdate([
              [...fullNamePath, 'origin', 'countryCode'],
            ])}
          >
            {() => {
              const htsValues = makeHtsValues();
              const additional = (get(htsObj, 'additional', []) ?? []).filter(
                (a: AdditionalHts) =>
                  !htsValues.originCountryCode ||
                  (a.include &&
                    a.include.some(
                      (i: string) => i === htsValues.originCountryCode,
                    )) ||
                  (a.exclude &&
                    !a.exclude.some(
                      (i: string) => i === htsValues.originCountryCode,
                    )),
              );
              const additionalGrouped = uniqBy(additional, 'type');
              return (
                <>
                  {additionalGrouped.map((a: any, ai) => (
                    <StyledFlexDiv key={ai}>
                      <Tooltip
                        key={ai}
                        title={
                          <ButterContent code={`additionalHTS_${a.type}`} />
                        }
                        placement="top"
                      >
                        <div>
                          <StyledWarningOutlined type="warning" />{' '}
                          {(additionalHTSTypes as any)[a.type] || a.type}
                        </div>
                      </Tooltip>
                      {index === 0 &&
                        !(
                          isSection232(a.type) && additional232ExclusionNumber
                        ) &&
                        // This section has been indefinitely suspended. Remove this check
                        // if it goes live (https://ustr.gov/about-us/policy-offices/press-office/press-releases/2019/december/united-states-and-china-reach)
                        !['section301List4B', 'SECTION_301_LIST_4B'].includes(
                          a.type,
                        ) &&
                        accountType === AccountType.OPERATOR &&
                        !readOnly && (
                          <StyledAdditionalHtsButton
                            size="small"
                            type="primary"
                            onClick={() => {
                              addAdditionalTariff(index, a);
                            }}
                          >
                            Add
                          </StyledAdditionalHtsButton>
                        )}
                    </StyledFlexDiv>
                  ))}
                </>
              );
            }}
          </StyledSectionFormItem>

          {accountType === AccountType.OPERATOR &&
            !readOnly &&
            htsObj &&
            htsObj.additionalHTS &&
            ((index === 0 && htsValues.secondaryHTSObj) ||
              (index === 1 && htsValues.tertiaryHTSObj)) && (
              <StyledAdditionalHtsButton
                size="small"
                type="dashed"
                onClick={() => removeAdditionalTariff(index)}
              >
                Remove
              </StyledAdditionalHtsButton>
            )}
        </StyledFlexDiv>
      )}
    </StyledHtsSummaryDiv>
  );
};

export default memo(LineItemHTSSummary);

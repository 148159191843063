import { AppRecordValidateResult } from '@xbcb/ui-types';
import { VneFieldValidator, VneValidationArgs } from '../types';
import { VneRequirements } from 'libs/pgaHelpers/epa';

export class EnginePowerRatingValidator implements VneFieldValidator {
  isApplicable(args: VneValidationArgs): boolean {
    const { vneImportCode } = args.pga;
    if (vneImportCode) {
      return VneRequirements.isEnginePowerRatingRequired(vneImportCode);
    }
    return false;
  }

  validate(_: VneValidationArgs): AppRecordValidateResult {
    return {
      validateFields: [
        ['enginePowerRating', 'value'],
        ['enginePowerRating', 'unit'],
      ],
    };
  }
}

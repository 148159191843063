import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserStatus } from 'actions';
import { useTypedSelector } from 'reducers';
import { UserStatus } from '@xbcb/client-types';
import { useTimer } from '../useTimer';
import {
  FEATURE_RELEASE_KEY,
  restrictedDeployment,
} from 'libs/restrictedDeployment';

const isPersonnelDashboardEnabled = !restrictedDeployment(
  FEATURE_RELEASE_KEY.PERSONNEL_DASHBOARD,
);

export const IDLE_TIMEOUT = 5 * 60 * 1000; // 5 minutes
export const eventTypes = ['keypress', 'mousedown'];

interface UseStatus {
  currentStatus: UserStatus;
  setStatus: (newStatus: UserStatus) => void;
}

export const useStatus = (): UseStatus => {
  const currentStatus = useTypedSelector(
    (state) => state.userStatus.currentStatus,
  );
  const dispatch = useDispatch();
  const setStatus = useCallback(
    (status: UserStatus) => dispatch(setUserStatus(status)),
    [dispatch],
  );

  const setStatusIdle = useCallback(
    () => setStatus(UserStatus.IDLE),
    [setStatus],
  );
  const { startTimer, resetTimer } = useTimer(IDLE_TIMEOUT, setStatusIdle);

  /**
   * After 5 minutes with no user activity, set status to IDLE.
   * Any keyboard/mouse activity resets the timer.
   * Timer continues even if the tab is inactive/not visible.
   */
  useEffect(() => {
    if (!isPersonnelDashboardEnabled) return;
    const handleUserActivity = () => {
      if (
        // Don't need to set to active if we already are; OOO isn't affected by user activity.
        ![UserStatus.ACTIVE, UserStatus.OUT_OF_OFFICE].includes(currentStatus)
      ) {
        setStatus(UserStatus.ACTIVE);
      }
      resetTimer();
    };
    eventTypes.forEach((event) =>
      window.addEventListener(event, handleUserActivity),
    );
    return () => {
      eventTypes.forEach((event) =>
        window.removeEventListener(event, handleUserActivity),
      );
    };
  }, [resetTimer, setStatus, currentStatus]);

  useEffect(() => {
    if (!isPersonnelDashboardEnabled) return;
    startTimer();
  }, [setStatus, startTimer]);

  return {
    currentStatus,
    setStatus,
  };
};

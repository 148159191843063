import { handleActions } from 'redux-actions';

const ownerReducer = handleActions(
  {
    OWNER_CHANGE: (state, { payload }) => ({ ...state, ...payload }),
  },
  {},
);

export default ownerReducer;

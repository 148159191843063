import React from 'react';
import { Row } from 'antd';
import {
  BondExemptionStatus,
  BondPolicyNumber,
  EnginePowerRating,
  ModelYear,
  TestGroup,
  ExemptionNumber,
} from './components';
import { ConditionalOnRemarksCodes } from './types';

const conditionalComponents: ConditionalOnRemarksCodes[] = [
  BondExemptionStatus,
  BondPolicyNumber,
  EnginePowerRating,
  ExemptionNumber,
  ModelYear,
  TestGroup,
];

export const ConditionalRemarks: ConditionalOnRemarksCodes = (props) => {
  return (
    <>
      {conditionalComponents.map((Component, index) => (
        <Row key={index}>
          <Component {...props} />
        </Row>
      ))}
    </>
  );
};

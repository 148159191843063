import React, { useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { Input } from 'antd';
import { conformToMask } from 'text-mask-core';
import { FormItem } from '@xbcb/form-item-components';
import {
  CssSize,
  NamePath,
  createDataCyValue,
  DataCyPrefix,
} from '@xbcb/ui-types';
import { ModeOfTransport } from '@xbcb/shipment-types';
import { AmsQueryReferenceType } from '@xbcb/work-order-types';
import { useWorkOrderTaskConfiguration } from 'libs/hooks';

const oceanMask = [/[A-Z]/, /[A-Z]/, /[A-Z]/, /[A-Z]/];
for (let i = 0; i < 12; i++) {
  oceanMask.push(/[A-Z0-9]/);
}
const airMask = [/\d/, /\d/, /\d/, '-'];

for (let i = 0; i < 8; i++) {
  airMask.push(/[A-Z0-9]/);
}

type MasterBillProps = {
  mot: ModeOfTransport;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  form: FormInstance;
  createAmsQuery?: any;
  hideLabel?: boolean;
  label?: string;
  isQueriable?: boolean;
  onBlur?: any;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  debounce?: boolean;
  onChange?: (val: any) => void;
};

const MasterBill = ({
  mot,
  fullNamePath,
  localNamePath,
  form,
  createAmsQuery,
  hideLabel,
  label,
  isQueriable,
  onBlur,
  required,
  disabled,
  readOnly,
  debounce = false,
  onChange,
}: MasterBillProps) => {
  const [previousConformedValue, setPreviousConformedValue] = useState('');
  const { readWriteControlsConfig } = useWorkOrderTaskConfiguration();
  const isRequired =
    required || !!readWriteControlsConfig['conveyance']?.required;
  const handleChange = (e: any) => {
    if (!e || !e.target) {
      return e;
    }

    const value = e.target.value.toUpperCase();

    const regex = mot === ModeOfTransport.AIR ? airMask : oceanMask;

    const conformedValue = conformToMask(value, regex, {
      guide: false,
      previousConformedValue,
    });

    setPreviousConformedValue(conformedValue.conformedValue);
    return conformedValue.conformedValue;
  };

  const handleSearch = async () => {
    const { getFieldValue } = form;
    const searchTerm = getFieldValue(fullNamePath);
    if (!searchTerm) return;
    if (mot === ModeOfTransport.AIR) {
      await createAmsQuery({
        amsQueryReferences: [
          {
            type: AmsQueryReferenceType.AIRWAY_BILL_NUMBER,
            value: searchTerm,
          },
        ],
      });
    } else {
      await createAmsQuery({
        amsQueryReferences: [
          {
            type: AmsQueryReferenceType.BILL_OF_LADING_NUMBER,
            value: searchTerm,
          },
        ],
      });
    }
  };

  const text = label
    ? label
    : mot === ModeOfTransport.AIR
    ? 'Master air waybill'
    : 'Master bill of lading';
  const C = isQueriable ? Input.Search : Input;

  type ChildProps = {
    onBlur: any;
    disabled?: boolean;
    placeholder: string;
    onSearch?: any;
    onChange?: any;
  };

  const childProps: ChildProps = {
    onBlur: onBlur,
    disabled: disabled,
    placeholder: mot === ModeOfTransport.AIR ? '123-45678901' : 'MAEU123456789',
    onChange,
  };
  if (isQueriable) childProps.onSearch = handleSearch;

  return (
    <FormItem
      label={!hideLabel ? text : undefined}
      $itemSize={CssSize.SHORT}
      $inline
      name={localNamePath}
      rules={[{ required: isRequired, message: ' ' }]}
      getValueFromEvent={handleChange}
      $readOnly={readOnly || !!readWriteControlsConfig['conveyance']?.readOnly}
      debounce={debounce}
      data-cy={createDataCyValue(DataCyPrefix.MANIFEST_MASTER_BILL_OF_LANDING)}
    >
      <C {...childProps} />
    </FormItem>
  );
};

export default MasterBill;

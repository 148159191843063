import React, { useEffect, useRef } from 'react';
import { Form, Row } from 'antd';
import { ImportCode } from './importCode';
import { IndustryCode } from './industryCode';
import { VneRegulatoryCategory } from '__generated__/graphql';
import { VehiclesAndEnginesFormProps } from '../../../types';

export const RemarksTypeCodes: React.FC<
  VehiclesAndEnginesFormProps & { regulatoryCategory: VneRegulatoryCategory }
> = (props) => {
  const { fullNamePath, regulatoryCategory } = props;
  const form = Form.useFormInstance();

  const clearField = (fieldPath: string[]) =>
    form.setFieldValue([...fullNamePath, ...fieldPath], undefined);

  const clearConditionalFields = () => {
    clearField(['epaTestGroup']);
    clearField(['vneExemptionNumber']);
    clearField(['vneBondNumber']);
    clearField(['vneBondExemptionNumber']);
    clearField(['vehicle', 'modelYear']);
    clearField(['enginePowerRating']);
  };

  const remarksCodesProps = {
    ...props,
    onChange: clearConditionalFields,
  };

  const previousRegulatoryCategory = useRef(regulatoryCategory);

  useEffect(() => {
    if (
      previousRegulatoryCategory.current &&
      previousRegulatoryCategory.current !== regulatoryCategory
    ) {
      clearConditionalFields();
    }
    previousRegulatoryCategory.current = regulatoryCategory;
  }, [regulatoryCategory, clearConditionalFields]);

  return (
    <Row>
      {regulatoryCategory && <ImportCode {...remarksCodesProps} />}
      {regulatoryCategory && <IndustryCode {...remarksCodesProps} />}
    </Row>
  );
};

import { useSelector, useDispatch } from 'react-redux';
import { useCurrentUser, useDebouncedCallback } from 'libs/hooks';
import { setGlobalSearchText } from 'actions';
import { gql, useQuery } from '@apollo/client';

// Hook used for global search form and table. Method `setSearchText` is used by GlobalSearchForm to set searchText (in redux) which triggers query and makes result and loading available to both the components. The search term was set in redux, since hooks cant share state b/w two components.

const GLOBAL_SEARCH_QUERY = gql`
  query globalSearch($query: String!, $userId: String!) {
    globalSearch(input: { query: $query, userId: $userId }) {
      results {
        deleted
        field
        highlight
        id
        recordType
        title
      }
    }
  }
`;

export const useGlobalSearch = () => {
  const dispatch = useDispatch();
  const { id: userId } = useCurrentUser();
  const { searchText = '' } = useSelector(
    (state: any) => state?.ui?.globalSearch || {},
  );

  // Currently throws error since the api is not present in beta. Will test before pushing, or hide search form if api is not ready until then.
  const { data, loading } = useQuery(GLOBAL_SEARCH_QUERY, {
    variables: {
      query: searchText,
      userId,
    },
    skip: !searchText,
  });
  const results = data?.globalSearch?.results || [];

  // Sets the search text in redux with debounce.
  const setSearchText = useDebouncedCallback(async (searchText: string) => {
    dispatch(setGlobalSearchText(searchText));
  }, 300);

  return { setSearchText, results, isLoading: loading, searchText };
};

import styled from 'styled-components';
import SiderMenu from 'components/SiderMenu';

const { SubMenu, Item: MenuItem } = SiderMenu;

export const StyledMenuItem = styled(MenuItem)`
  &&& {
    background-color: transparent;
  }
`;
// TODO: typed to `any` to fix the antd bug, remove the typing asa we've a long term solution
// https://app.asana.com/0/1160161841055950/1202199211207217
export const StyledSubMenu = styled<any>(SubMenu)`
  .ant-menu-sub {
    background-color: transparent;
  }
`;

import React from 'react';
import { Input } from 'antd';
import { maxLength } from '@xbcb/ui-utils';
import DecimalQuantity from 'components/DecimalQuantity';
import UOMSelect from 'components/UOMSelect';
import { MinusButton } from '@xbcb/button-components';
import { CountrySelect, FormItem } from '@xbcb/form-item-components';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { FormInstance } from 'antd/lib/form';
import { StyledConstituentElementDiv, StyledFlexDiv } from './styles';

interface ConstituentElementProps {
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  required?: boolean;
  index: number;
  onRemove?: any;
  allowDelete?: boolean;
}

const ConstituentElement: React.FC<ConstituentElementProps> = ({
  form,
  readOnly,
  disabled,
  fullNamePath,
  localNamePath,
  index,
  onRemove,
  allowDelete,
  required,
}) => {
  const firstRow = index === 0;

  const scientificName =
    form.getFieldValue([...fullNamePath, 'scientificName']) || '';

  return (
    <StyledConstituentElementDiv key={index}>
      <StyledFlexDiv className="form-inline">
        {/* PG04 */}
        <FormItem
          $itemSize={CssSize.TINY_SHORT}
          label={firstRow ? 'Name' : undefined}
          name={[...localNamePath, 'name']}
          rules={[{ required, message: ' ' }]}
          getValueFromEvent={maxLength(51)}
          $inline
          $readOnly={readOnly}
        >
          <Input disabled={disabled} />
        </FormItem>
        <FormItem
          $itemSize={CssSize.TINY_SHORT}
          label={firstRow ? 'Scientific Name' : undefined}
          name={[...localNamePath, 'scientificName']}
          rules={[{ required, message: ' ' }]}
          getValueFromEvent={maxLength(44)}
          $inline
          $readOnly={readOnly}
        >
          <Input disabled={disabled} />
        </FormItem>
        <CountrySelect
          $itemSize={CssSize.TINY_SHORT}
          localNamePath={[...localNamePath, 'harvestCountryCode']}
          required={required}
          label={firstRow ? 'Country of harvest' : undefined}
          readOnly={readOnly}
          disabled={disabled}
          displayCode={true}
        />
        <DecimalQuantity
          field={[...localNamePath, 'quantity', 'value']}
          hideLabel={!firstRow}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
          max={999999999999}
          $itemSize={CssSize.TINY}
        />
        <UOMSelect
          localNamePath={[...localNamePath, 'quantity', 'unit']}
          hideLabel={!firstRow}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
          size={CssSize.TINY}
          isLaceyAct
        />
        {/* TODO Ask Andre if this sort of logic is cool */}
        {scientificName.toUpperCase().endsWith('RECYCLED') && (
          <DecimalQuantity
            field={[...localNamePath, 'percentageRecycled']}
            label="Percent"
            hideLabel={index !== 0}
            readOnly={readOnly}
            disabled={disabled}
            units="%"
            max={100}
            $itemSize={CssSize.MICRO}
            required={required}
          />
        )}
        {allowDelete && !readOnly && (
          <MinusButton
            label="constituent element"
            firstRow={firstRow}
            disabled={disabled}
            onRemove={onRemove}
          />
        )}
      </StyledFlexDiv>
      {/* PG05 */}
      <div className="form-inline" />
    </StyledConstituentElementDiv>
  );
};

export default ConstituentElement;

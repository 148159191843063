import styled from 'styled-components';
import { Tooltip } from 'antd';

export const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const StyledTooltip = styled(Tooltip)`
  .underline {
    text-decoration: underline;
  }
`;

import { VehiclesAndEnginesFormProps } from '../../../types';
import { VneRegulatoryCategory } from '__generated__/graphql';

export type RemarksTypeCodeProps = VehiclesAndEnginesFormProps & {
  regulatoryCategory: VneRegulatoryCategory;
  onChange: () => void;
};

export type RemarksCode = {
  code: string;
  description: string;
};

export type RemarksCodeOption = {
  value: string;
  label: string;
};

export const toRemarksCodeOption = ({
  code,
  description,
}: RemarksCode): RemarksCodeOption => ({
  value: code,
  label: `${code} - ${description}`,
});

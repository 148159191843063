import type { AppRecordValidateResult } from '@xbcb/ui-types';

/**
 * Tiny utility for accumulating validation results
 * when using multiple validators with the same output.
 * const accumulator = createAccumulator({ errors, fields});
 * accumulator([doSomeValidation(input)]);
 *
 * Shorthand for
 * const result = doSomeValidation(input);
 * errors.push(...result.errors)
 * fields.push(...result.fields)
 */

export const createAccumulator =
  ({ additionalErrors = [], validateFields = [] }: AppRecordValidateResult) =>
  (newData: AppRecordValidateResult[]) => {
    newData.forEach(
      ({
        additionalErrors: newErrors = [],
        validateFields: newFields = [],
      }) => {
        additionalErrors.push(...newErrors);
        validateFields.push(...newFields);
      },
    );
  };

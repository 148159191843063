import { getEnv } from '@xbcb/ui-utils';
import { UiStage, uiStageToBackendStage } from '@xbcb/ui-types';
import {
  RENEWAL_DATE_FEATURE,
  renewalDateFeature,
  cbpReg2ChangesFeature,
  CBP_REG_2_CHANGES_FEATURE,
  AP_INTEGRATION_FEATURE,
  apIntegrationFeature,
} from '@xbcb/feature-flags';

import { Tag } from '@xbcb/api-gateway-client';
import log from '@xbcb/log';

const { stage } = getEnv();

export const iscbpReg2ChangesFeatureEnabled =
  CBP_REG_2_CHANGES_FEATURE.isEnabled(cbpReg2ChangesFeature, {
    stage: uiStageToBackendStage[stage],
  });

export const isVehiclesAndEnginesPgaSupportEnabled = (
  operatorUserId?: string,
) =>
  stage !== UiStage.PROD ||
  [
    'operatorUser_a196f24d-06c1-49b2-8ba3-c8a166c85a40', // travsmar@
    'operatorUser_ecfb29b0-b4c2-4c4a-989c-78d45f20e5d4', // parkeima@
    'operatorUser_c80a0461-a81c-4c34-86d0-1c7af105e7eb', // valeshat@
  ].some((id) => id === operatorUserId);

export const isPesticidesPgaSupportEnabled = (operatorUserId?: string) =>
  stage !== UiStage.PROD ||
  [
    'operatorUser_a196f24d-06c1-49b2-8ba3-c8a166c85a40', // travsmar@
    'operatorUser_ecfb29b0-b4c2-4c4a-989c-78d45f20e5d4', // parkeima@
    'operatorUser_c80a0461-a81c-4c34-86d0-1c7af105e7eb', // valeshat@
  ].some((id) => id === operatorUserId);

export const isRenewalDateEnabled: boolean = RENEWAL_DATE_FEATURE.isEnabled(
  renewalDateFeature,
  {
    stage: uiStageToBackendStage[stage],
  },
);

// Convert the launch time string to a Date object
const AP_LAUNCH_TIME = new Date('2024-11-15T00:00:00.000Z');

export const isApIntegrationEnabled = ({
  tags,
  time,
}: {
  tags?: Tag[];
  time: string;
}): boolean => {
  const isFeatureEnabled = AP_INTEGRATION_FEATURE.isEnabled(
    apIntegrationFeature,
    { stage: uiStageToBackendStage[getEnv().stage] },
  );

  if (stage === UiStage.PROD) {
    log.info(
      `Checking if AP need to be enabled, launch time: ${AP_LAUNCH_TIME} processTime: ${time}`,
      {
        key: 'isApIntegrationEnabled',
        params: { tags },
      },
    );
    if (isAfterLaunchTime(time)) {
      return isFeatureEnabled;
    }

    // Before the launch time, check if the AP_ENABLED tag is present
    return isFeatureEnabled && hasApEnabledTag(tags);
  }

  return isFeatureEnabled;
};

const isAfterLaunchTime = (time: string): boolean => {
  const processTime = new Date(time);
  return processTime >= AP_LAUNCH_TIME;
};

// Check if the AP_ENABLED tag is present
const hasApEnabledTag = (tags?: Tag[]): boolean => {
  return tags?.some((tag) => tag.key === 'AP_ENABLED') ?? false;
};

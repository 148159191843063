import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormItem } from '@xbcb/form-item-components';

export const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  margin-left: ${({ theme }) => theme.spacing.space_3};
`;

export const StyledCheckboxFormItem = styled(FormItem)`
  display: inline-block;
  margin: var(--space-4) 0 0 ${({ theme }) => theme.spacing.space_4};
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0;
`;

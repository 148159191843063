import React from 'react';
import { StyledFlexDiv, StyledSpacerDiv } from './styles';

const RightContentSpace: React.FC = ({ children }) => (
  <StyledFlexDiv>
    <StyledSpacerDiv />
    {children}
  </StyledFlexDiv>
);

export default RightContentSpace;

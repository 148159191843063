import { RecordType } from '@xbcb/shared-types';
import { appRecordMemoConfiguration } from 'libs/appRecordMemoConfiguration';
import { useCurrentUser } from 'libs/hooks';

export const useMemos = (recordType: RecordType) => {
  const { accountType } = useCurrentUser();
  const configurations =
    appRecordMemoConfiguration[
      recordType as keyof typeof appRecordMemoConfiguration
    ] || [];
  const authorizedConfigurations = configurations.filter(
    (config) => !config.blocklist?.includes(accountType),
  );
  return {
    configurations: authorizedConfigurations,
  };
};

import { conformToMask } from 'text-mask-core';

export default class {
  constructor(mask) {
    this.previousValue = '';
    this.mask = mask;
  }

  handleChange(e) {
    if (!e) return e;

    // TODO: when `<Select mode="tags">` pass down the `e`, e.target.value will case error
    // this is a temporary fix to make page render
    if (Array.isArray(e)) {
      return e;
    }
    const result = conformToMask(e.target.value.toUpperCase(), this.mask, {
      guide: false,
      previousConformedValue: this.previousValue,
    }).conformedValue;
    this.previousValue = result;
    return result;
  }
}

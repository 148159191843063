import React from 'react';
import { FormItem } from '@xbcb/form-item-components';
import { Input } from 'antd';
import { ConditionalOnRemarksCodes } from '../types';
import { VneRequirements } from 'libs/pgaHelpers/epa';

export const TestGroup: ConditionalOnRemarksCodes = ({
  localNamePath,
  disabled,
  importCode,
  required,
}) => {
  const shouldShow =
    VneRequirements.isTestGroupRequired(importCode) ||
    VneRequirements.isTestGroupConditionallyRequired(importCode);

  return shouldShow ? (
    <FormItem
      label={'Test Group'}
      name={[...localNamePath, 'epaTestGroup']}
      rules={[{ required }]}
    >
      <Input
        aria-label={'Test Group Textbox'}
        disabled={disabled}
        maxLength={TEST_GROUP_MAX_LENGTH}
      />
    </FormItem>
  ) : null;
};

const TEST_GROUP_MAX_LENGTH = 33;

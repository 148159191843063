import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import AphCommodityCode from 'components/AphCommodityCode';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from '@xbcb/ui-types';
import { StyledDiv, StyledButton } from './styles';

export interface AphCommodityCodesProps {
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  required?: boolean;
}

const AphCommodityCodes = ({
  form,
  readOnly,
  disabled,
  localNamePath,
  fullNamePath,
  required,
}: AphCommodityCodesProps) => {
  return (
    <StyledDiv>
      <h4 className="space-bottom">Commodity codes</h4>
      <Form.List name={localNamePath}>
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <AphCommodityCode
                    key={field.key}
                    form={form}
                    readOnly={readOnly}
                    disabled={disabled}
                    fullNamePath={[...fullNamePath, field.name]}
                    localNamePath={[field.name]}
                    index={index}
                    onRemove={() => remove(field.name)}
                    required={required}
                    allowDelete
                  />
                </Form.Item>
              </div>
            ))}
            {!readOnly && (
              <StyledButton onClick={add}>
                <PlusOutlined />
                Commodity code
              </StyledButton>
            )}
          </div>
        )}
      </Form.List>
    </StyledDiv>
  );
};

export default AphCommodityCodes;

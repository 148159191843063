import React from 'react';
import { RecordType } from '@xbcb/shared-types';
import PartyForm from 'components/PartyForm';
import { RecordProps } from 'routes';

const CustomsAgent: React.FC<RecordProps> = ({
  disabled,
  readOnly,
  form,
  hideDocs,
}) => (
  <PartyForm
    recordType={RecordType.CUSTOMS_AGENT}
    disabled={disabled}
    readOnly={readOnly}
    form={form}
    addressRequired
    hideDocs={hideDocs}
    hideBusinessStructure={true}
  />
);

export default CustomsAgent;

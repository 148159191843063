export class VneRequirements {
  static isBondPolicyNumberRequired(importCode: string): boolean {
    return BOND_POLICY_NUMBER_REQUIRED_CODES.has(importCode);
  }

  static isExemptionNumberRequired(importCode: string): boolean {
    return EXEMPTION_NUMBER_REQUIRED_CODES.has(importCode);
  }

  static isEnginePowerRatingRequired(importCode: string): boolean {
    return ENGINE_POWER_RATING_REQUIRED_CODES.has(importCode);
  }

  static isModelYearRequired(importCode: string): boolean {
    return MODEL_YEAR_REQUIRED_CODES.has(importCode);
  }

  static isTestGroupRequired(importCode: string): boolean {
    return TEST_GROUP_REQUIRED_CODES.has(importCode);
  }

  static isTestGroupConditionallyRequired(importCode: string): boolean {
    return TEST_GROUP_CONDITIONALLY_REQUIRED_CODES.has(importCode);
  }
}

export const BOND_POLICY_NUMBER_REQUIRED_CODES = new Set(['G', 'I', 'K', 'J']);

export const EXEMPTION_NUMBER_REQUIRED_CODES = new Set([
  'G',
  'I',
  'K',
  'L',
  'O',
]);

export const ENGINE_POWER_RATING_REQUIRED_CODES = new Set(['U']);

export const MODEL_YEAR_REQUIRED_CODES = new Set(['A', 'C', 'U', 'Y', 'Z']);

export const TEST_GROUP_REQUIRED_CODES = new Set(['B', 'F']);
export const TEST_GROUP_CONDITIONALLY_REQUIRED_CODES = new Set(['A', 'W']);

import { pick } from 'lodash';
import { AnyObject } from '@xbcb/shared-types';
import { transformWorkOrderBase } from '../base';
import { transformLines } from './lines';

export const transformEuCustomsEntry = {
  toSchema: ({
    input,
    existingRecord,
  }: {
    input: AnyObject;
    existingRecord: AnyObject;
  }): AnyObject => {
    const cleanedOutput = transformWorkOrderBase.toSchema({
      input,
      existingRecord,
    });
    delete cleanedOutput.ior;
    delete cleanedOutput.arrival;
    delete cleanedOutput.departure;
    delete cleanedOutput.deadline;
    if (cleanedOutput.broker?.customsBroker) {
      cleanedOutput.broker.customsBroker = pick(
        cleanedOutput.broker.customsBroker,
        ['id'],
      );
    }
    cleanedOutput.invoices?.forEach((invoice: any) => {
      delete invoice.currency;

      invoice.products?.forEach((product: any) => {
        delete product.totalReporting;
        delete product.product?.clientIdentifier;
        delete product.product?.clientIdentifierVersion;
        delete product.product?.secondaryIdentifiers;
        transformLines.toSchema({ lines: product.lines });
      });
    });

    return cleanedOutput;
  },
};

import { pick, set } from 'lodash';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { transformWorkOrderBase } from '../base';
import { transformLines } from './lines';
import { getRecordType } from '@xbcb/core';
export * from './lines';

export const transformUsConsumptionEntry = {
  toForm: ({ existingRecord }: { existingRecord: AnyObject }) => {
    const newRecord = transformWorkOrderBase.toForm({ existingRecord });
    if (newRecord.arrival?.inBond) {
      newRecord.arrival.isIt = true;
    }
    if (newRecord.exam?.containers) {
      const transformedContainers = newRecord.exam.containers.map(
        (container: { number: string }) => container.number,
      );
      newRecord.exam.containers = transformedContainers;
    }
    newRecord.invoices?.forEach((invoice: any) => {
      set(invoice, ['currency'], invoice.value?.currency);
      invoice.products?.forEach((product: any) => {
        transformLines.toForm({ lines: product.lines });
      });
    });
    const continuousBonds = newRecord.ior?.usIor?.continuousBonds; // TODO refactor RecordSelect nesting to fix this.
    if (continuousBonds) {
      newRecord.ior.continuousBonds = continuousBonds;
    }
    return newRecord;
  },
  toSchema: ({
    input,
    existingRecord,
  }: {
    input: AnyObject;
    existingRecord: AnyObject;
  }) => {
    const cleanedOutput = transformWorkOrderBase.toSchema({
      input,
      existingRecord,
    });
    delete cleanedOutput.broker;
    delete cleanedOutput.arrival?.isIt;
    delete cleanedOutput.ior?.continuousBonds; // we don't transmit this field, but we need to fetch it and keep it in the form to know whether to render the Bond tab.
    delete cleanedOutput.badges;

    if (cleanedOutput.exam?.containers) {
      cleanedOutput.exam.containers = cleanedOutput.containers?.filter(
        (container: { number: string }) =>
          cleanedOutput.exam.containers?.includes(container.number),
      );
    }
    cleanedOutput.invoices?.forEach((invoice: any) => {
      delete invoice.currency;

      invoice.products?.forEach((product: any) => {
        delete product.totalReporting;
        delete product.product?.clientIdentifier;
        delete product.product?.secondaryIdentifiers;
        transformLines.toSchema({ lines: product.lines });
      });
    });
    if (cleanedOutput.ior?.usIor) {
      // usIor is an IdVersion input
      cleanedOutput.ior.usIor = pick(cleanedOutput.ior.usIor, [
        'id',
        'version',
      ]);
    }
    const { mailing, physical } = cleanedOutput.ior?.addresses || {};
    if (mailing || physical) {
      cleanedOutput.ior.address = mailing || physical;
    }
    delete cleanedOutput.ior?.addresses;
    // adding this check because this transformer is used for non UsConsumptionEntry Records as well
    if (getRecordType(existingRecord.id) === RecordType.US_CONSUMPTION_ENTRY)
      cleanedOutput.invoicesReference = null;
    return cleanedOutput;
  },
};

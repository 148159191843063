import styled from 'styled-components';
import { Modal, Form, Alert } from 'antd';
import { FormItem } from '@xbcb/form-item-components';

// is there a standard way we are doing so right now?
// Also the question mark icon from antd is black. is there a stanrdard way to change the color other than hard coding color?
export const StyledModal = styled(Modal)`
  padding: 48px;
  font-size: 16px;
  label {
    font-size: 18px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
  h4 {
    margin-left: 10px;
    margin-bottom: 0px;
    padding-right: 4px;
  }
`;

export const StyledForm = styled(Form)`
  display: inline;
`;

export const StyledFormInlineDiv = styled.div`
  display: flex;
  align-items: center;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item-control-input {
    min-height: 22px;
  }
`;

export const StyledIorCard = styled.div`
  .ant-form-item {
    margin-bottom: 0px;
  }
  flex-direction: row;
  display: flex;
  margin-bottom: 16px;
`;

export const StyledIorWarningCard = styled.div`
  flex-direction: row;

  a {
    text-decoration: underline;
  }
`;

export const StyledAnchor = styled.a`
  text-decoration: underline;
`;

export const StyledAlert = styled(Alert)`
  width: calc(var(--short) - var(--space-4));
  margin-top: 8px;
  margin-left: 8px;
  ul {
    padding-left: 12px;
  }
`;

export const StyledBillCard = styled.div`
  margin-bottom: 16px;
  flex-direction: row;
  display: flex;
  .ant-form-item {
    width: calc(var(--short) - var(--space-4));
    margin-bottom: 0;
  }
  .ant-form-item-control {
    height: 32px;
  }
`;

export const StyledMotCard = styled.div`
  flex-direction: row;
  display: flex;
  .ant-form-item {
    width: calc(var(--short) - var(--space-4));
  }
`;

export const StyledClientIdCard = styled.div`
  margin-bottom: 16px;
  flex-direction: row;
  display: flex;
`;

export const StyledClientId = styled.div`
  flex-flow: row wrap;
  display: flex;
  .ant-form-item {
    width: calc(var(--short) - var(--space-4));
    margin-bottom: 0;
  }
  a {
    text-decoration: underline;
  }
`;

export const StyledPartyCard = styled.div`
  flex-direction: row;
  display: flex;
`;

export const StyledManualCreationFeeCard = styled(FormItem)`
  label {
    font-size: medium;
  }
`;

export const StyledManualCreationFeePrompt = styled.div`
  margin-left: 20px;
`;

import { RecordType } from '@xbcb/shared-types';
import { validateCbp5106Party } from '@xbcb/ui-utils';
import { AppRecordProps } from 'routes';

const validateUsConsignee: AppRecordProps['validateUpdate'] = ({
  input,
  currentUser,
}) =>
  validateCbp5106Party({
    input,
    recordType: RecordType.US_CONSIGNEE,
    currentUser,
  });

export default validateUsConsignee;

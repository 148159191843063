import { AppRecordAfterUpdate } from 'routes';
import { getEnv, reportError } from '@xbcb/ui-utils';
import { uiStageToBackendStage } from '@xbcb/ui-types';
import type { UsType86Entry } from '@xbcb/api-gateway-client';
import {
  PATHFINDER_FEATURE_FLAG_SERVICE,
  PathfinderFeatures,
} from '@xbcb/feature-flags';
import { UsFilingType } from '@xbcb/compliance-types';
import { classifyUsCustomsFiling } from 'libs/usEntryCustomsClassificationTransformers/classifyCustomsFiling';
import { message } from 'antd';

const { stage } = getEnv();

const PATHFINDER_PHASE_2_ENABLED = PATHFINDER_FEATURE_FLAG_SERVICE.isEnabled(
  PathfinderFeatures.PHASE_2,

  { stage: uiStageToBackendStage[stage] },
);

export const afterUsType86EntryUpdate: AppRecordAfterUpdate = async ({
  updatedRecord,
  skipValidate,
}) => {
  // If validation was skipped on save we still want to check if the
  // updated record is eligible for T86 and show a message to the operator
  // letting them know that it's not eligible anymore because they may
  // need to flip the T86 entry back to USCE
  if (PATHFINDER_PHASE_2_ENABLED && skipValidate) {
    try {
      // Operations doesn't want undisclaimed ad/cvd or fees to flag entry as not eligible when saving
      const classifications = await classifyUsCustomsFiling(
        updatedRecord as UsType86Entry,
        { ignoreAdCvdAndFees: true },
      );
      if (!classifications) {
        message.warning(
          'Shipment is not eligible for US Type86 Entry because of missing data',
        );
      } else if (
        !classifications?.some(
          (classification) =>
            classification.filingType === UsFilingType.US_TYPE86_ENTRY,
        )
      ) {
        message.warning('Shipment is not eligible for US Type86 Entry');
      } else {
        message.success('Shipment is eligible for US Type86 Entry');
      }
    } catch (e) {
      message.warning('Unable to determine shipment US Type86 eligiblity');
      reportError(e);
    }
  }
};

import React from 'react';
import { Menu } from 'antd';
import { StyledMenu } from './styles';

type SiderMenuProps = {
  selectedKeys: string[];
};

const SiderMenu: React.FC<SiderMenuProps> = ({ children, selectedKeys }) => (
  <StyledMenu mode="inline" inlineIndent={14} selectedKeys={selectedKeys}>
    {children}
  </StyledMenu>
);

// copies SubMenu, Menu.Item, etc.
const SiderMenuWithSubComponents = Object.assign(SiderMenu, Menu);
export default SiderMenuWithSubComponents;

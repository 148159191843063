import { handleActions } from 'redux-actions';

const defaults = {
  loaded: false,
};

const mqttReducer = handleActions(
  {
    MQTT_LOADED: (state, { payload }) => ({ loaded: payload }),
  },
  defaults,
);

export default mqttReducer;

import { ProductSuggestion } from 'components/InquireWorkOrderProductLevelErrorRow';
import { Entry } from 'types';
export const getProductIdentifiersFromEntry = (
  entry: Entry,
): ProductSuggestion[] =>
  (entry.invoices
    ?.map((invoice) =>
      invoice.products?.map((product) => {
        const sku = product.product?.clientIdentifier;
        if (!sku) return null;
        const skuVersion =
          // Always use the first line since externalReference is same for all lines
          // Fallback to clientIdentifierVersion in product record due to missing lines issue (fixed in CR-116668482)
          product.lines?.[0]?.externalReference?.skuComplianceVersion ||
          product.product?.clientIdentifierVersion;
        const asin = product.product?.secondaryIdentifiers?.find(
          (identifier) => identifier.type === 'ASIN',
        );
        return {
          asin: asin?.value,
          sku,
          skuVersion,
        };
      }),
    )
    .flat()
    .filter(Boolean) as ProductSuggestion[]) ?? [];

import { AppRecordValidateResult } from '@xbcb/ui-types';
import { Pga, PgaValidationArgs, PgaValidator } from './types';

export abstract class CompoundPgaValidator<T extends Pga>
  implements PgaValidator<T>
{
  constructor(protected validators: PgaValidator<T>[]) {}

  abstract isApplicable(args: PgaValidationArgs<T>): boolean;

  validate(args: PgaValidationArgs<T>): AppRecordValidateResult {
    return this.validators.reduce(
      (acc, validator) => {
        if (validator.isApplicable(args)) {
          const { validateFields, additionalErrors } = validator.validate(args);

          if (validateFields?.length) {
            acc?.validateFields?.push(...validateFields);
          }
          if (additionalErrors?.length) {
            acc?.additionalErrors?.push(...additionalErrors);
          }
        }

        return acc;
      },
      {
        validateFields: [],
        additionalErrors: [],
      } as AppRecordValidateResult,
    );
  }
}

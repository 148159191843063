import React, { memo, useEffect, useState } from 'react';
import { Modal } from 'antd';
import FeatureToggles from './FeatureToggles';

export const DevTools = (): React.ReactElement => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'F1' && e.altKey) {
        setOpen(true);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [setOpen]);
  return (
    <Modal
      title="CBMS Dev Tools"
      open={open}
      footer={null}
      onCancel={() => setOpen(false)}
      destroyOnClose
    >
      <FeatureToggles />
    </Modal>
  );
};

export default memo(DevTools);

import { clearRecentlyViewed, pushRecentlyViewed } from '../actions';
import { createReducer } from '@reduxjs/toolkit';
import { AnyObject } from '@xbcb/shared-types';

const initialRecent: AnyObject = [];

const maxLength = 10;

export default createReducer(initialRecent, (builder) =>
  builder
    .addCase(pushRecentlyViewed, (state, { payload }) => {
      let existingIdx;
      let recent: AnyObject[] = [];
      const { viewedRecords = [] } = state;
      const viewedRecordsLength = viewedRecords.length;

      for (let i = 0; i < viewedRecordsLength; i++) {
        const { recordType, id } = state.viewedRecords[i];
        if (recordType === payload.recordType && id === payload.id) {
          existingIdx = i;
          break;
        }
      }

      if (viewedRecordsLength > 0) recent = [...state.viewedRecords];

      if (existingIdx !== undefined) {
        recent.splice(existingIdx, 1);
      }
      const newState = [payload, ...recent];
      if (newState.length > maxLength) newState.pop();

      return { viewedRecords: newState };
    })
    .addCase(clearRecentlyViewed, () => {
      return { viewedRecords: [] };
    }),
);

import {
  LoadingOutlined,
  SearchOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import React, { useState, useRef } from 'react';
import { Popover, Tooltip, InputRef } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { gaEvent, safeGetMessage } from '@xbcb/ui-utils';
import { RawHTML } from '@xbcb/static-text-components';
import showHTSModal from 'libs/showHTSModal';
import controlClick from 'libs/controlClick';
import { paramCase } from 'change-case';
import { formatRecordName, pluralize } from '@xbcb/js-utils';
import { RecordType } from '@xbcb/shared-types';
import {
  StyledPopOverTable,
  StyledMoreButton,
  StyledSearchInput,
} from './styles';
import { useCurrentUser, useGlobalSearch } from 'libs/hooks';
import { useBundle } from '@amzn/react-arb-tools';

const GlobalSearchForm = () => {
  const [sharedBundle] = useBundle('shared');
  const [appHeaderBundle] = useBundle('components.AppHeader');
  const { accountType } = useCurrentUser();
  const { pathname } = useLocation();
  const history = useHistory();
  const {
    isLoading,
    results: searchResults,
    setSearchText: setUseGlobalSearchText,
  } = useGlobalSearch();
  const [listening, setListening] = useState(false);

  const [searchText, setSearchText] = useState(''); // Used local state here for input rendering, since setUseGlobalSearchText uses debounce and would cause lag for end users.

  const searchInputRef = useRef<InputRef>(null);
  const searchResultsRef = useRef<HTMLDivElement>(null);
  const outsideClickListenerRef = useRef<((event: any) => void) | null>(null);
  const onSearchPage = pathname === '/search';

  const outsideClickListener = (event: any) => {
    if (onSearchPage) {
      return;
    }
    if (
      !searchInputRef.current?.input?.contains(event.target) &&
      !searchResultsRef.current?.contains(event.target)
    ) {
      clear();
    }
  };

  const removeClickListener = () => {
    if (onSearchPage) {
      return;
    }
    if (outsideClickListenerRef.current) {
      document.removeEventListener('click', outsideClickListenerRef.current);
      setListening(false);
    }
  };

  const clear = () => {
    removeClickListener();
    setSearchText('');
    setUseGlobalSearchText('');
  };

  const addClickListener = () => {
    if (onSearchPage) {
      return;
    }
    if (!listening) {
      document.addEventListener('click', outsideClickListener);
      outsideClickListenerRef.current = outsideClickListener;
      setListening(true);
    }
  };

  const handleChange = async (event: any) => {
    event.preventDefault();
    const newSearchText = event.target.value;
    if (!onSearchPage && newSearchText) {
      addClickListener();
    }
    setSearchText(newSearchText);
    setUseGlobalSearchText(newSearchText);
  };

  const handleFocus = () => {
    if (searchText) {
      addClickListener();
    }
  };

  const getDropdownColumns = () => [
    {
      key: 'title',
      render: (text: string, { recordType, title, deleted }: any) => (
        <p>
          {formatRecordName({ recordType, accountType })} : {title}
          {deleted ? (
            <Tooltip
              title={safeGetMessage(sharedBundle, 'deleted')}
              placement="right"
            >
              <DeleteOutlined />
            </Tooltip>
          ) : null}
        </p>
      ),
    },
    {
      key: 'text',
      render: (text: string, record: any) => (
        <RawHTML html={record.highlight || ''} />
      ),
    },
  ];

  const dropdownResultsLimit = 15;

  const handleSubmit = async () => {
    gaEvent('GlobalSearch', 'Submitted', searchText);
    if (!onSearchPage) {
      removeClickListener();
      history.push('/search');
    }
  };

  const tableFooter = () => {
    const resultSize = searchResults?.length || 0;
    return resultSize ? (
      <StyledMoreButton size="large" onClick={handleSubmit}>
        {resultSize > dropdownResultsLimit
          ? safeGetMessage(appHeaderBundle, 'view_more_results', {
              results: resultSize - dropdownResultsLimit,
            })
          : safeGetMessage(appHeaderBundle, 'view_full_results')}
      </StyledMoreButton>
    ) : null;
  };

  const handleRowClick = (event: any, record: any) => {
    clear();
    const { recordType, id, title } = record;
    gaEvent('Search', 'Popover Row Clicked', id);
    if (recordType === RecordType.HTS) {
      showHTSModal(id, title);
    } else {
      const path = `/${pluralize(paramCase(recordType))}/${id}`;
      if (pathname === path) {
        return;
      }
      controlClick({
        event,
        history,
        path,
      });
    }
  };

  const getResultsTable = () => (
    // Creating a wrapper div to get reference as ref is not supported on Table https://github.com/ant-design/ant-design/issues/25306
    <div ref={searchResultsRef}>
      <StyledPopOverTable
        columns={getDropdownColumns()}
        locale={{
          emptyText: isLoading
            ? safeGetMessage(appHeaderBundle, 'searching')
            : safeGetMessage(appHeaderBundle, 'no_results_found'),
        }}
        loading={isLoading}
        dataSource={searchResults.slice(0, dropdownResultsLimit)}
        showHeader={false}
        footer={tableFooter}
        pagination={false}
        onRow={(record) => ({
          onClick: (event) => handleRowClick(event, record),
        })}
        rowKey="id"
      />
    </div>
  );

  return (
    <div>
      <Popover
        placement="bottom"
        open={Boolean(searchText?.length && !onSearchPage)}
        content={getResultsTable()}
      >
        <StyledSearchInput
          ref={searchInputRef}
          onChange={handleChange}
          onSearch={handleSubmit}
          onFocus={handleFocus}
          value={searchText}
          enterButton={
            isLoading ? (
              <LoadingOutlined />
            ) : (
              <SearchOutlined style={{ verticalAlign: 'baseline' }} />
            )
          }
          placeholder={safeGetMessage(
            appHeaderBundle,
            'find_shipments_and_more',
          )}
          size="large"
        />
      </Popover>
    </div>
  );
};

export default GlobalSearchForm;

import React from 'react';
import { camelCase } from 'change-case';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { AppRecordKebabSubMenuItemAction } from 'routes';
import { AnyObject } from '@xbcb/shared-types';
import { DataCyPrefix, createDataCyValue } from '@xbcb/ui-types';
import { useCustomsBrokerId } from 'libs/hooks';

interface SubMenuOptionItem {
  item: React.ReactNode;
  itemProps?: AnyObject;
}

export type SubMenuOptionItems = {
  [key: string]: SubMenuOptionItem;
};

export interface SubMenuOptionsProps {
  items: SubMenuOptionItems;
  action: AppRecordKebabSubMenuItemAction;
  record: any;
}
const SubMenuOptions: React.FC<SubMenuOptionsProps> = ({
  items,
  action,
  record,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const customsBrokerId = useCustomsBrokerId();
  const menu = Object.entries(items).map(([key, { item, itemProps = {} }]) => (
    <Menu.Item
      key={key}
      {...restProps}
      onClick={(event) => {
        action({
          event: event as any,
          dispatch,
          history,
          location,
          record,
          customsBrokerId,
          props: { key, ...itemProps },
        });
      }}
      data-cy={createDataCyValue(
        DataCyPrefix.APP_RECORD_KEBAB_MENU_SUB_MENU_OPTION,
        camelCase(key),
      )}
    >
      {item}
    </Menu.Item>
  ));
  return <>{menu}</>;
};

export default SubMenuOptions;

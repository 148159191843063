import React from 'react';
import { getOneQuery, getRecordFromResponse } from '@xbcb/shared-queries';
import { additionalCaseFields as fields } from '@xbcb/trade-data-queries';
import { LinkOutlined } from '@ant-design/icons';
import { RecordType } from '@xbcb/shared-types';
import { useQuery } from '@apollo/client';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from '@xbcb/ui-types';
import { StyledAdCvdCaseLinkDiv, StyledContainer } from './styles';
export type LineItemCaseInfoProps = {
  recordId: string;
  form: FormInstance;
  htsNumber: string;
  fullNamePath: NamePath;
};
const LineItemCaseLink: React.FC<LineItemCaseInfoProps> = ({ recordId }) => {
  const recordType = RecordType.AD_CVD_CASE;
  const getQuery = getOneQuery({
    recordName: recordType,
    fields,
  });
  const queryResponse = useQuery(getQuery, {
    variables: { id: recordId },
  });
  const currentCase = getRecordFromResponse(queryResponse, 'get', recordType);

  return (
    <StyledContainer>
      {currentCase && currentCase.caseNumber && (
        <StyledAdCvdCaseLinkDiv>
          <a
            href={`https://aceservices.cbp.dhs.gov/adcvdweb/ad_cvd_msgs/search?utf8=%E2%9C%93&page=1&filter_cat=ALL&filter_type=ALL&search=${currentCase.caseNumber.substr(
              0,
              9,
            )}&commit=Search&per_page=10`}
            target="_blank"
            rel="noopener noreferrer"
          >
            AD/CVD Search <LinkOutlined />
          </a>
        </StyledAdCvdCaseLinkDiv>
      )}
      {currentCase && currentCase.scopeUrl && (
        <StyledAdCvdCaseLinkDiv>
          <a
            href={currentCase.scopeUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            AD/CVD ITA Scope <LinkOutlined />
          </a>
        </StyledAdCvdCaseLinkDiv>
      )}
    </StyledContainer>
  );
};
export default LineItemCaseLink;

import { message } from 'antd';
import { AppRecordSubmitButton } from 'routes';
import { AccountType, RecordType } from '@xbcb/shared-types';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { WorkOrderTaskType } from '@xbcb/work-order-types';
import {
  hasEntrySummaryStatus,
  isRecordCanceled,
  isRecordDeleted,
} from 'libs/workOrderConditions';

export const showSubmitButton = (
  workOrderTaskType: string | WorkOrderTaskType | undefined,
) => {
  return (
    !workOrderTaskType ||
    [
      WorkOrderTaskType.US_CONSUMPTION_ENTRY_FILING,
      WorkOrderTaskType.US_CONSUMPTION_ENTRY_COMPLETION_SUBMISSION,
      WorkOrderTaskType.US_CONSUMPTION_ENTRY_CBP_EXAM,
      WorkOrderTaskType.US_CONSUMPTION_ENTRY_CBP_HOLD,
      WorkOrderTaskType.US_CONSUMPTION_ENTRY_EXCEPTION,
      WorkOrderTaskType.US_CONSUMPTION_ENTRY_REVISION,
      WorkOrderTaskType.ACE_SYSTEM_ERROR_EXCEPTION,
      WorkOrderTaskType.US_CONSUMPTION_ENTRY_CENSUS_WARNING,
    ].includes(workOrderTaskType as any)
  );
};

// This is ONLY used to validate the current page of large entries (>20 lines).
export const validateEntryButton = (): AppRecordSubmitButton => {
  const name = `Validate Entry Page`;
  const workOrderButton: AppRecordSubmitButton = {
    key: `validateEntry`,
    text: name,
    show: ({ existingRecord, editMode, currentUser, workOrderTaskType }) => {
      const isLargeEntry =
        existingRecord?.invoices &&
        existingRecord?.invoices[0]?.products?.length > 20;
      return (
        isLargeEntry &&
        showSubmitButton(workOrderTaskType) &&
        !hasEntrySummaryStatus(existingRecord) &&
        editMode === 'draft' &&
        !isRecordDeleted(existingRecord) &&
        !isRecordCanceled(existingRecord) &&
        !currentUser.loading &&
        currentUser.accountType === AccountType.OPERATOR &&
        checkAccess(
          currentUser,
          RecordType.US_CONSUMPTION_ENTRY,
          UserPermissions.UPSERT_CBP_TRANSACTION,
        )
      );
    },
    skipUpdateRecord: () => true,
    onSubmit: async () => {
      // If validations succeed, just display a success message.
      message.success('Entry Page Valid', 5);
    },
  };
  return workOrderButton;
};

import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledActionsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: ${({ theme }) => theme.spacing.space_3};
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    background: var(--layout-body-background);

    .ant-page-header {
      max-width: unset;
    }
  }
`;

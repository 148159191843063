import { useCallback, useRef } from 'react';
import { debounce } from 'lodash';

export const useDebouncedCallback = (
  callback: (...args: any) => any,
  delay: number,
  options?: any,
) => {
  const callbackRef = useRef(callback);
  return useCallback(debounce(callbackRef.current, delay, options), []);
};

import { ProcessingErrorBase, processingErrorsModalKey } from './types';
import { StyledModal, StyledInlineH4, StyledDiv } from './styles';
import React from 'react';
import moment from 'moment';
import { useModal } from '@xbcb/ui-utils';
export interface ProcessingErrorsModalProps {
  errors: ProcessingErrorBase[];
}

type ProcessingErrorProps = {
  error: ProcessingErrorBase;
};

const ProcessingError: React.FC<ProcessingErrorProps> = ({ error }) => {
  const { created, name } = error;

  const { time } = created;
  const formattedTime = moment(time).local().format('MMMM D YYYY, h:mm a');
  return (
    <StyledDiv>
      <h4>{name}</h4>
      <StyledInlineH4>{formattedTime}</StyledInlineH4>
    </StyledDiv>
  );
};

export const ProcessingErrorsModal: React.FC<ProcessingErrorsModalProps> = ({
  errors,
}) => {
  const { closeModal, visible } = useModal(processingErrorsModalKey);

  return (
    <StyledModal
      title={'Processing errors'}
      onCancel={closeModal}
      footer={null}
      width={'50%'}
      open={visible}
    >
      {errors.map((it, index) => (
        <ProcessingError error={it} key={index} />
      ))}
    </StyledModal>
  );
};

export default ProcessingErrorsModal;

import { getRecordType } from '@xbcb/core';
import { AppRecordValidateUpdate } from '../routes';
import { getValidationParams } from './getValidationParams';

const validateDeliveryOrder: AppRecordValidateUpdate = async ({
  input,
  existingRecord,
}) => {
  const { validateFields, additionalErrors } = getValidationParams({ input });

  const agentId =
    existingRecord.group?.forwarders?.[0]?.forwarder?.id ||
    existingRecord.group?.customsBroker?.id;

  const recordName = getRecordType(agentId);

  if (!recordName) {
    additionalErrors.push({
      title: 'Unknown Agent Type',
      messages: ['Agent not found for delivery order. Please check.'],
    });
  }

  return { additionalErrors, validateFields };
};

export default validateDeliveryOrder;

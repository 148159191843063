import React from 'react';
import { Modal, Input, Form, Radio, message } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useModal } from '@xbcb/ui-utils';
import { ModalKey } from 'types';
import {
  StyledAdditionalTextDiv,
  StyledAsteriskSymbol,
  StyledDiv,
  StyledSpan,
  StyledSubCategoryDiv,
  StyledTextDiv,
} from './styles';
import { codeToText } from '@xbcb/js-utils';
import { FormItem, HiddenField } from '@xbcb/form-item-components';
import {
  EscalationReasonCategory,
  EscalationReasonCategoryToSubCatoriesMap,
} from '@xbcb/work-order-types';
import { AppRecordOnConfirmPayload } from 'routes';
import { createDataCyValue, DataCyPrefix } from '@xbcb/ui-types';

interface EscalateWorkOrderTaskModalProps {
  recordId: string;
}

const EscalateWorkOrderTaskModal: React.FC<EscalateWorkOrderTaskModalProps> =
  React.memo(({ recordId }) => {
    const {
      closeModal,
      visible,
      modalProps,
    }: {
      closeModal: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
      visible?: boolean;
      modalProps: {
        sendConfirmationDecision: (
          value?:
            | AppRecordOnConfirmPayload
            | Promise<AppRecordOnConfirmPayload>,
        ) => void;
      };
    } = useModal(ModalKey.ESCALATE_WORK_ORDER_TASK);
    const { sendConfirmationDecision } = modalProps;
    const [form] = Form.useForm();
    const mainCategory = Form.useWatch(['category'], form);

    const handleCategoryChange = (event: RadioChangeEvent) => {
      form.setFields([
        { name: ['subCategory'], value: undefined },
        { name: `${mainCategory}-subCategory`, value: undefined },
      ]);
    };

    const handleSubCategoryChange = (event: RadioChangeEvent) => {
      const subCategory = event.target.value;
      form.setFieldValue('subCategory', subCategory);
    };

    const onCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      sendConfirmationDecision({ canceled: true });
      closeModal(e);
    };

    const renderSubCategoryRadioGroup = (
      category: string,
      subCategories: string[],
    ) => (
      <StyledSubCategoryDiv>
        <Form.Item name={`${category}-subCategory`}>
          <Radio.Group>
            {subCategories.map((subCategory) => (
              <Radio
                key={subCategory}
                value={subCategory}
                onChange={handleSubCategoryChange}
                data-cy={createDataCyValue(
                  DataCyPrefix.RADIO_BUTTON,
                  subCategory,
                )}
              >
                {codeToText(subCategory)}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </StyledSubCategoryDiv>
    );

    const categoryRadioGroup = (
      <StyledDiv>
        <Form.Item name="category">
          <Radio.Group>
            {Object.entries(EscalationReasonCategoryToSubCatoriesMap).map(
              ([category, subCategories]) => {
                const hasSubCategories = !!subCategories.length;
                const shouldRenderSubCategory =
                  hasSubCategories && mainCategory === category;
                return (
                  <div key={category}>
                    <Radio value={category} onChange={handleCategoryChange}>
                      {codeToText(category)}
                      {hasSubCategories && <StyledSpan>+</StyledSpan>}
                      {shouldRenderSubCategory &&
                        renderSubCategoryRadioGroup(category, subCategories)}
                    </Radio>
                  </div>
                );
              },
            )}
          </Radio.Group>
        </Form.Item>
      </StyledDiv>
    );

    return (
      <Modal
        key={recordId}
        open={visible}
        width={'580px'}
        title={`Escalate Work Order Task`}
        onCancel={onCancel}
        cancelText={'Cancel'}
        okText={'Escalate'}
        onOk={async (e) => {
          const { category, subCategory, details } = form.getFieldsValue();
          try {
            await form.validateFields(['details']);
            const isSubCategoryRequired = (
              [
                EscalationReasonCategory.CLASSIFICATION,
                EscalationReasonCategory.PGA,
                EscalationReasonCategory.GENERAL_ISSUE,
              ] as string[]
            ).includes(category);
            if (!category || (isSubCategoryRequired && !subCategory)) {
              throw new Error(`Please select an escalation reason category`);
            }
          } catch (e) {
            if (e.message) {
              message.error(e.message);
            } else {
              e.errorFields?.forEach(({ errors }: { errors: string[] }) => {
                errors?.forEach((error: string) => message.error(error));
              });
            }
            return;
          }
          sendConfirmationDecision({
            canceled: false,
            confirmationData: {
              category,
              subCategory,
              details,
            },
          });
          closeModal(e);
        }}
      >
        <Form form={form}>
          <HiddenField localNamePath={['subCategory']} form={form} />
          <StyledTextDiv>
            <StyledAsteriskSymbol />
            <>
              Which of the following categories best describes the help you
              need?
            </>
          </StyledTextDiv>
          <StyledAdditionalTextDiv>
            <>
              This will be used to identify the best SME to look into the task.
              If "General Issues" is chosen, the ticket will be escalated to the
              team lead.
            </>
          </StyledAdditionalTextDiv>
          {categoryRadioGroup}
          <StyledTextDiv>
            <StyledAsteriskSymbol />
            <> Details</>
          </StyledTextDiv>
          <FormItem
            name="details"
            rules={[{ required: true, message: 'Detail is required' }]}
          >
            <Input.TextArea
              maxLength={10000}
              data-cy={createDataCyValue(DataCyPrefix.ESCALATION_DETAILS)}
            />
          </FormItem>
        </Form>
      </Modal>
    );
  });

export default EscalateWorkOrderTaskModal;

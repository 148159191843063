import {
  UsType86Entry,
  UsConsumptionEntry,
  UsCustomsFilingClassification,
  ClassifyUsCustomsFilingInput,
  UsType86EntryMasterBill,
  UsConsumptionEntryMasterBill,
} from '@xbcb/api-gateway-client';
import { transformUsEntryCommercialInvoicesToClassifyUsCustomsFilingCommercialInvoices } from './transformUsEntryCommercialInvoicesToClassifyUsCustomsFilingCommercialInvoices';
import { gql } from '@apollo/client';
import { client as apolloClient } from '@xbcb/apollo-client';

const QUERY_CLASSIFY_US_CUSTOMS_FILING = gql`
  query ClassifyUsCustomsFiling($input: ClassifyUsCustomsFilingInput!) {
    classifyUsCustomsFiling(input: $input) {
      classifications {
        filingType
      }
    }
  }
`;

type ClassifyUsCustomsFilingOptions = {
  ignoreAdCvdAndFees?: boolean;
};

export const classifyUsCustomsFiling = async (
  usEntry: UsType86Entry | UsConsumptionEntry,
  options: ClassifyUsCustomsFilingOptions = {},
): Promise<UsCustomsFilingClassification[] | undefined> => {
  const { ignoreAdCvdAndFees = false } = options;
  const importDate = usEntry.arrival?.importDate;
  const iorNumber = usEntry.ior?.iorNumber;
  if (!iorNumber || !importDate) {
    return undefined;
  }
  const commercialInvoices =
    transformUsEntryCommercialInvoicesToClassifyUsCustomsFilingCommercialInvoices(
      usEntry.invoices,
      ignoreAdCvdAndFees,
    );
  if (!commercialInvoices) {
    return undefined;
  }

  type MasterBill = UsType86EntryMasterBill | UsConsumptionEntryMasterBill;
  const masterBills: MasterBill[] | undefined = usEntry.masterBills;
  type MasterBillWithNumber = Required<MasterBill>;

  const input: ClassifyUsCustomsFilingInput = {
    arrival: {
      country: 'US',
      date: importDate as string,
    },
    iorNumber,
    commercialInvoices,
    masterBills: masterBills
      ?.filter((mbl): mbl is MasterBillWithNumber => !!mbl.number)
      ?.map(({ number }) => ({ number })),
    excludedCbmsWorkOrderIds: [usEntry.id],
  };
  const { data } = await apolloClient.query({
    query: QUERY_CLASSIFY_US_CUSTOMS_FILING,
    variables: { input },
  });

  return data.classifyUsCustomsFiling.classifications;
};

import styled from 'styled-components';
import { FormItem } from '@xbcb/form-item-components';

export const StyledCheckboxFormItem = styled(FormItem)`
  height: 16px;
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
`;

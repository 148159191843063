import { aphLpcoFields } from '@xbcb/entry-utils/dist/lib/aphLpcoList';
export const lpcoMapping: { [key: string]: { [key: string]: any[] } } = {
  APQ: {
    AP0400: [
      { fieldName: aphLpcoFields.PHYTOSANITARY_CERTIFICATE, typeCode: 'A01' },
      { fieldName: aphLpcoFields.TREATMENT_CERTIFICATE, typeCode: 'A05' },
      {
        fieldName: aphLpcoFields.POST_ENTRY_QUARANTINE_PERMIT,
        typeCode: 'A11',
      },
      { fieldName: aphLpcoFields.TRANSIT_PLANTS_PERMIT, typeCode: 'A13' },
      { fieldName: aphLpcoFields.COTTON_PERMIT, typeCode: 'A14' },
      { fieldName: aphLpcoFields.SUGARCANE_PERMIT, typeCode: 'A15' },
      { fieldName: aphLpcoFields.NURSERY_PLANTS_PERMIT, typeCode: 'A16' },
      { fieldName: aphLpcoFields.KHAPRA_BEETLE_PERMIT, typeCode: 'A20' },
      {
        fieldName: aphLpcoFields.PLANTS_OF_CANADIAN_ORIGIN_PERMIT,
        typeCode: 'A21',
      },
      {
        fieldName: aphLpcoFields.PROHIBITED_PLANT_FOR_RESEARCH_PERMIT,
        typeCode: 'A22',
      },
      { fieldName: aphLpcoFields.PROTECTED_PLANT_PERMIT, typeCode: 'A23' },
      { fieldName: aphLpcoFields.SEED_ANALYSIS_CERTIFICATE, typeCode: 'A27' },
      { fieldName: aphLpcoFields.NOTICE_OF_ARRIVAL_PERMIT, typeCode: 'A32' },
      { fieldName: aphLpcoFields.CERTIFICATE_OF_ORIGIN, typeCode: 'A33' },
    ],
    AP0500: [
      { fieldName: aphLpcoFields.PHYTOSANITARY_CERTIFICATE, typeCode: 'A01' },
      { fieldName: aphLpcoFields.TREATMENT_CERTIFICATE, typeCode: 'A05' },
      { fieldName: aphLpcoFields.PLANT_PESTS_PERMIT, typeCode: 'A10' },
      { fieldName: aphLpcoFields.COTTON_PERMIT, typeCode: 'A14' },
      { fieldName: aphLpcoFields.SUGARCANE_PERMIT, typeCode: 'A15' },
      { fieldName: aphLpcoFields.NURSERY_PLANTS_PERMIT, typeCode: 'A16' },
      { fieldName: aphLpcoFields.KHAPRA_BEETLE_PERMIT, typeCode: 'A20' },
      {
        fieldName: aphLpcoFields.PLANTS_OF_CANADIAN_ORIGIN_PERMIT,
        typeCode: 'A21',
      },
      {
        fieldName: aphLpcoFields.PROHIBITED_PLANT_FOR_RESEARCH_PERMIT,
        typeCode: 'A22',
      },
      { fieldName: aphLpcoFields.PROTECTED_PLANT_PERMIT, typeCode: 'A23' },
      { fieldName: aphLpcoFields.SEED_ANALYSIS_CERTIFICATE, typeCode: 'A27' },
      { fieldName: aphLpcoFields.NOTICE_OF_ARRIVAL_PERMIT, typeCode: 'A32' },
      { fieldName: aphLpcoFields.CERTIFICATE_OF_ORIGIN, typeCode: 'A33' },
    ],
    AP0600: [
      { fieldName: aphLpcoFields.PHYTOSANITARY_CERTIFICATE, typeCode: 'A01' },
      { fieldName: aphLpcoFields.TREATMENT_CERTIFICATE, typeCode: 'A05' },
      {
        fieldName: aphLpcoFields.FOREIGN_SITE_CERTIFICATE_OF_INSPECTION,
        typeCode: 'A07',
      },
      { fieldName: aphLpcoFields.PLANT_PESTS_PERMIT, typeCode: 'A10' },
      { fieldName: aphLpcoFields.COTTON_PERMIT, typeCode: 'A14' },
      { fieldName: aphLpcoFields.SUGARCANE_PERMIT, typeCode: 'A15' },
      { fieldName: aphLpcoFields.NURSERY_PLANTS_PERMIT, typeCode: 'A16' },
      { fieldName: aphLpcoFields.CORN_PERMIT, typeCode: 'A17' },
      { fieldName: aphLpcoFields.RICE_PERMIT, typeCode: 'A18' },
      {
        fieldName: aphLpcoFields.FRUITS_AND_VEGETABLES_PERMIT,
        typeCode: 'A19',
      },
      { fieldName: aphLpcoFields.KHAPRA_BEETLE_PERMIT, typeCode: 'A20' },
      {
        fieldName: aphLpcoFields.PLANTS_OF_CANADIAN_ORIGIN_PERMIT,
        typeCode: 'A21',
      },
      { fieldName: aphLpcoFields.CERTIFICATE_OF_ORIGIN, typeCode: 'A33' },
    ],
    AP0700: [
      { fieldName: aphLpcoFields.PHYTOSANITARY_CERTIFICATE, typeCode: 'A01' },
      { fieldName: aphLpcoFields.HEALTH_CERTIFICATE, typeCode: 'A02' },
      { fieldName: aphLpcoFields.TREATMENT_CERTIFICATE, typeCode: 'A05' },
      {
        fieldName: aphLpcoFields.FOREIGN_SITE_CERTIFICATE_OF_INSPECTION,
        typeCode: 'A07',
      },
      { fieldName: aphLpcoFields.SOIL_PERMIT, typeCode: 'A09' },
      { fieldName: aphLpcoFields.PLANT_PESTS_PERMIT, typeCode: 'A10' },
      { fieldName: aphLpcoFields.TIMBER_PERMIT, typeCode: 'A12' },
      { fieldName: aphLpcoFields.TRANSIT_PLANTS_PERMIT, typeCode: 'A13' },
      { fieldName: aphLpcoFields.SUGARCANE_PERMIT, typeCode: 'A15' },
      { fieldName: aphLpcoFields.CORN_PERMIT, typeCode: 'A17' },
      { fieldName: aphLpcoFields.RICE_PERMIT, typeCode: 'A18' },
      {
        fieldName: aphLpcoFields.FRUITS_AND_VEGETABLES_PERMIT,
        typeCode: 'A19',
      },
      { fieldName: aphLpcoFields.KHAPRA_BEETLE_PERMIT, typeCode: 'A20' },
      {
        fieldName: aphLpcoFields.PLANTS_OF_CANADIAN_ORIGIN_PERMIT,
        typeCode: 'A21',
      },
      {
        fieldName: aphLpcoFields.PROHIBITED_PLANT_FOR_RESEARCH_PERMIT,
        typeCode: 'A22',
      },
      { fieldName: aphLpcoFields.PROTECTED_PLANT_PERMIT, typeCode: 'A23' },
      { fieldName: aphLpcoFields.NOTICE_OF_ARRIVAL_PERMIT, typeCode: 'A32' },
      { fieldName: aphLpcoFields.CERTIFICATE_OF_ORIGIN, typeCode: 'A33' },
    ],
    AP0800: [
      { fieldName: aphLpcoFields.PHYTOSANITARY_CERTIFICATE, typeCode: 'A01' },
      { fieldName: aphLpcoFields.TREATMENT_CERTIFICATE, typeCode: 'A05' },
      { fieldName: aphLpcoFields.KHAPRA_BEETLE_PERMIT, typeCode: 'A20' },
      {
        fieldName: aphLpcoFields.PLANTS_OF_CANADIAN_ORIGIN_PERMIT,
        typeCode: 'A21',
      },
      {
        fieldName: aphLpcoFields.PROHIBITED_PLANT_FOR_RESEARCH_PERMIT,
        typeCode: 'A22',
      },
      { fieldName: aphLpcoFields.PROTECTED_PLANT_PERMIT, typeCode: 'A23' },
      { fieldName: aphLpcoFields.CERTIFICATE_OF_ORIGIN, typeCode: 'A33' },
    ],
  },
  AVS: {
    AP0100: [
      { fieldName: aphLpcoFields.HEALTH_CERTIFICATE, typeCode: 'A02' },
      { fieldName: aphLpcoFields.TREATMENT_CERTIFICATE, typeCode: 'A05' },
      { fieldName: aphLpcoFields.DECLARATION_OF_IMPORTATION, typeCode: 'A26' },
      { fieldName: aphLpcoFields.LIVE_ANIMALS_PERMIT, typeCode: 'A28' },
      {
        fieldName: aphLpcoFields.APPLICATION_FOR_INSPECTION_AND_DIPPING,
        typeCode: 'A29',
      },
      { fieldName: aphLpcoFields.CERTIFICATE_OF_ORIGIN, typeCode: 'A33' },
    ],
    AP0200: [
      { fieldName: aphLpcoFields.TREATMENT_CERTIFICATE, typeCode: 'A05' },
    ],
    AP0300: [
      { fieldName: aphLpcoFields.TREATMENT_CERTIFICATE, typeCode: 'A02' },
      { fieldName: aphLpcoFields.MEAT_CERTIFICATE, typeCode: 'A03' },
      {
        fieldName: aphLpcoFields.IMPORTATION_OF_VETERINARY_BIOLOGICAL_PRODUCTS,
        typeCode: 'A06',
      },
      {
        fieldName:
          aphLpcoFields.VETERINARY_PERMIT_TO_IMPORT_CONTROLLED_MATERIALS,
        typeCode: 'A24',
      },
      { fieldName: aphLpcoFields.STATEMENTS_PERMIT, typeCode: 'A25' },
      { fieldName: aphLpcoFields.CERTIFICATE_OF_ORIGIN, typeCode: 'A33' },
    ],
  },
  AAC: {
    AP0100: [
      { fieldName: aphLpcoFields.HEALTH_CERTIFICATE, typeCode: 'A02' },
      {
        fieldName: aphLpcoFields.RABIES_VACCINATION_CERTIFCATE,
        typeCode: 'A30',
      },
      { fieldName: aphLpcoFields.DOGS_PERMIT, typeCode: 'A31' },
    ],
  },
  ABS: {
    AP1000: [
      { fieldName: aphLpcoFields.GMO_PERMIT, typeCode: 'A34' },
      { fieldName: aphLpcoFields.BRS_NOTIFICATION, typeCode: 'A35' },
      { fieldName: aphLpcoFields.BRS_ACKNOWLEDGEMENT_LETTER, typeCode: 'A36' },
    ],
  },
};

export const categoryTypeCodesMapping: { [key: string]: string[] } = {
  APQ: ['AP0400', 'AP0500', 'AP0600', 'AP0700', 'AP0800'],
  AVS: ['AP0100', 'AP0200', 'AP0300'],
  ABS: ['AP1000'],
  AAC: ['AP0100'],
};

// PG06
export const sourceTypeMap: { [key: string]: string[] } = {
  APQ: ['HRV', '262', '267'],
  AAC: ['267'],
  ABS: ['HRV', '262', '267', 'CST'],
  AVS: [
    '30',
    'CDB',
    'CMN',
    '244',
    'CPK',
    'CPR',
    '39',
    '243',
    'CSL',
    '256',
    '267',
  ],
};
export enum DateField {
  END = 'End',
  START = 'Start',
}
export const sourceProcessingTypeCodesMapping: {
  [key: string]: {
    [key: string]: { typeCodes: string[]; dateField: DateField };
  };
} = {
  APQ: {
    AP0400: {
      typeCodes: [
        'ACD01',
        'ACH01',
        'ACHW1',
        'ACS01',
        'ACW01',
        'ADF01',
        'ADH01',
        'ADP01',
        'AEX01',
        'AFH01',
        'AFRZ1',
        'AGRD1',
        'AHP01',
        'AHPS1',
        'AHPW1',
        'AHR01',
        'AHT01',
        'AHTS1',
        'AHW01',
        'AKS01',
        'AMS01',
        'APH01',
        'APSS1',
        'ASCR1',
        'ASF01',
        'AST01',
        'AVS01',
        'AWW01',
        'MB001',
      ],
      dateField: DateField.START,
    },
    AP0500: {
      typeCodes: [
        'AAD01',
        'ACH01',
        'ACHW1',
        'ACS01',
        'ACT01',
        'ACW01',
        'ADF01',
        'ADH01',
        'AFH01',
        'AFRZ1',
        'AGRD1',
        'AHPS1',
        'AHPW1',
        'AHT01',
        'AHTF1',
        'AHTS1',
        'AHW01',
        'AIR01',
        'AKS01',
        'AMBC1',
        'AMS01',
        'APSS1',
        'AQF01',
        'ASCR1',
        'ASF01',
        'AST01',
        'AVH01',
        'AVS01',
        'AWW01',
        'MB001',
      ],
      dateField: DateField.END,
    },
    AP0600: {
      typeCodes: [
        'ACH01',
        'ACHW1',
        'ACS01',
        'ACT01',
        'ACTM1',
        'ACW01',
        'ADF01',
        'ADH01',
        'AFH01',
        'AFRZ1',
        'AGRD1',
        'AHPS1',
        'AHPW1',
        'AHT01',
        'AHTF1',
        'AHTS1',
        'AHW01',
        'AIR01',
        'AKS01',
        'AMBC1',
        'AMS01',
        'APSS1',
        'AQF01',
        'ASCR1',
        'ASF01',
        'AST01',
        'AVH01',
        'AVS01',
        'AWW01',
        'MB001',
      ],
      dateField: DateField.END,
    },
    AP0700: {
      typeCodes: [
        'AAD01',
        'ACGR1',
        'ACH01',
        'ACHW1',
        'ACS01',
        'ACW01',
        'ADF01',
        'ADH01',
        'AFH01',
        'AFRZ1',
        'AGRD1',
        'AHPS1',
        'AHPW1',
        'AHT01',
        'AHTS1',
        'AHW01',
        'AKS01',
        'AMS01',
        'APSS1',
        'ASCR1',
        'ASF01',
        'AST01',
        'AVS01',
        'AWW01',
        'MB001',
      ],
      dateField: DateField.END,
    },
    AP0800: {
      typeCodes: [
        'ACH01',
        'ACHW1',
        'ACS01',
        'ACW01',
        'ADF01',
        'ADH01',
        'AFH01',
        'AFRZ1',
        'AGRD1',
        'AHPS1',
        'AHPW1',
        'AHT01',
        'AHTS1',
        'AHW01',
        'AKS01',
        'AMS01',
        'APSS1',
        'ASCR1',
        'ASF01',
        'AST01',
        'AVS01',
        'AWW01',
        'MB001',
      ],
      dateField: DateField.START,
    },
  },
  AVS: {
    AP0100: { typeCodes: ['AVRAB', 'AVDIP'], dateField: DateField.END },
  },
  AAC: { AP0100: { typeCodes: ['AVRAB'], dateField: DateField.START } },
};

import styled from 'styled-components';
import { Dropdown } from 'antd';
import { FormItem } from '@xbcb/form-item-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledTariffUnitValueWrapperDiv = styled.div`
  vertical-align: top;
  display: inline-block;
  .ant-input-number-disabled {
    background: transparent;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  .ant-form-item {
    margin-bottom: 0;
    margin-left: ${CssSpace.SPACE_3};
  }
`;

export const StyledExtraComplianceDiv = styled.div`
  width: ${CssSpace.SPACE_8};
  .ant-form-item {
    margin-bottom: ${CssSpace.SPACE_4};
  }
`;

export const StyledDropdown = styled(Dropdown)`
  margin-top: 4px;
}`;

export const StyledHtsInfoDiv = styled.div`
  margin-bottom: ${CssSpace.SPACE_4};
`;

export const StyledHtsDutyParagraph = styled.p`
  display: inline;
  margin-right: ${CssSpace.SPACE_4};
`;

export const StyledUomQuantityDiv = styled.div`
  display: inline-block;
`;

export const StyledFormInlineDiv = styled.div`
  display: inline-block;
`;

export const StyledTextParagraph = styled.p`
  margin-bottom: 0;
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0 !important;
`;

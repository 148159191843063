import { AppRecordValidateUpdate } from 'routes';
import { sharedValidateUsEntrySummary } from '../sharedValidateUsEntrySummary';

const validateUsPostSummaryCorrection: AppRecordValidateUpdate = ({
  input,
  existingRecord,
  currentUser,
  location,
}) => {
  const { additionalErrors, validateFields, informalEntry } =
    sharedValidateUsEntrySummary({
      input,
      currentUser,
    });
  validateFields.push(['entryNumber'], ['headerReasons'], ['explanation']);
  return {
    additionalErrors,
    validateFields,
  };
};

export default validateUsPostSummaryCorrection;

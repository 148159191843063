import { RecordType } from '@xbcb/shared-types';
import type { WorkOrder } from '@xbcb/api-gateway-client';
import { isAGLForwarder } from '@xbcb/party-utils';

const euWorkOrderType: RecordType[] = [
  RecordType.GB_CUSTOMS_ENTRY,
  RecordType.DE_CUSTOMS_ENTRY,
  RecordType.NL_CUSTOMS_ENTRY,
  RecordType.FR_CUSTOMS_ENTRY,
  RecordType.GB_IOR_ACTIVATION,
  RecordType.DE_IOR_ACTIVATION,
  RecordType.NL_IOR_ACTIVATION,
  RecordType.FR_IOR_ACTIVATION,
];

export const isAbbeyRoadWorkOrder = (
  workOrderRecordType?: RecordType,
  workOrder?: WorkOrder,
): boolean => {
  const isAglForwarder = workOrder?.group?.forwarders?.some((forwarder) =>
    isAGLForwarder(forwarder.forwarder),
  );
  return Boolean(
    workOrderRecordType &&
      euWorkOrderType.includes(workOrderRecordType) &&
      isAglForwarder,
  );
};

import { WorkOrderStatus, WorkOrderTaskType } from '@xbcb/work-order-types';
import type { WorkOrderTask } from '@xbcb/api-gateway-client';
import { taskIsInProgress } from '@xbcb/work-order-utils/dist/findMostRecentInProgressTask';

const permissibleInquireWorkOrderStates = [WorkOrderStatus.IN_PROGRESS];

export const showInvoiceLineCountKebabMenuItemFunc = ({
  status,
  taskType,
  workOrderTasks,
}: {
  status?: WorkOrderStatus;
  taskType?: WorkOrderTaskType;
  workOrderTasks?: WorkOrderTask[];
}): boolean => {
  const task = workOrderTasks?.find(
    (task: WorkOrderTask) => task.definition?.workOrderTaskType === taskType,
  );
  if (status && permissibleInquireWorkOrderStates.includes(status)) {
    return Boolean(task && taskIsInProgress(task));
  }

  return false;
};

import React, { ReactNode } from 'react';
import { FormItem, Select, Option } from '@xbcb/form-item-components';
import { selectFilter } from '@xbcb/ui-utils';
import {
  NamePath,
  DataCySuffix,
  DataCyPrefix,
  createDataCyValue,
} from '@xbcb/ui-types';
import {
  RecordType,
  AccountType,
  recordTypeToCancellationReasonMap,
} from '@xbcb/shared-types';
import { Input } from 'antd';

interface CancelReasonsDropdownProps {
  reasonNamePath: NamePath;
  recordType: RecordType;
  dataCySuffix: DataCySuffix;
  instructionText?: string;
  customCancelReasonOptions?: React.ReactElement[];
  extraContent?: ReactNode;
  accountType?: AccountType;
}

const CancelReasonsDropdown: React.FC<CancelReasonsDropdownProps> = ({
  instructionText,
  reasonNamePath,
  recordType,
  accountType,
  dataCySuffix,
  customCancelReasonOptions,
  extraContent,
}) => {
  const dataCy = createDataCyValue(
    DataCyPrefix.CANCEL_REASON_DROPDOWN,
    dataCySuffix,
  );
  if (!customCancelReasonOptions && accountType) {
    const cancelReasonCodesByRecordType =
      recordTypeToCancellationReasonMap[accountType];

    customCancelReasonOptions = cancelReasonCodesByRecordType[
      recordType as keyof typeof cancelReasonCodesByRecordType
    ]?.reduce((acc: React.ReactElement[], reasonCode, index) => {
      acc.push(
        <Option
          key={index}
          value={reasonCode}
          data-cy={`${createDataCyValue(
            DataCyPrefix.CANCEL_REASON_DROPDOWN,
            reasonCode,
          )}`}
        >
          {reasonCode}
        </Option>,
      );
      return acc;
    }, []);
  }

  return (
    <>
      {instructionText && <p>{instructionText}</p>}
      <FormItem
        label="Reason"
        name={reasonNamePath}
        rules={[{ required: true, message: ' ' }]}
      >
        {customCancelReasonOptions?.length && accountType ? (
          <Select
            showSearch
            allowClear
            filterOption={selectFilter}
            notFoundContent={`None Found`}
            dropdownMatchSelectWidth={false}
            data-cy={dataCy}
          >
            {customCancelReasonOptions}
          </Select>
        ) : (
          <Input disabled={false} data-cy={dataCy} />
        )}
      </FormItem>
      {extraContent || <></>}
    </>
  );
};
export default CancelReasonsDropdown;

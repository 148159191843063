import { executeMutation } from 'libs/executeMutation';
import {
  createUsIorCbp5106,
  queryUsIorCbp,
  updateUsIorCbp5106,
} from 'libs/sharedQueries';
import { get } from 'lodash';
import { AccountType } from '@xbcb/shared-types';
import { UsIorCbpStatus } from '@xbcb/party-types';
import { setModal } from 'actions';
import { AppRecordKebabMenuItem } from 'routes';
import { ModalKey } from 'types';
import { checkKebabMenuItemForWorkOrderTask } from './checkKebabMenuItemForWorkOrderTask';

export const createQueryUsIorKebabMenuItem = (
  pathToUsIor: string[] = [],
): AppRecordKebabMenuItem => ({
  key: 'queryUsIor',
  text: 'Query CBP',
  show: ({
    existingRecord,
    currentUser,
    workOrderTaskType,
    workOrderTaskEvaluatedState,
  }) =>
    currentUser.accountType === AccountType.OPERATOR &&
    get(existingRecord, [...pathToUsIor, 'iorNumber', 'value']) &&
    checkKebabMenuItemForWorkOrderTask(
      workOrderTaskType,
      workOrderTaskEvaluatedState,
    ),
  action: ({ record }) =>
    executeMutation({
      mutation: queryUsIorCbp,
      variables: { usIorId: get(record, [...pathToUsIor, 'id']) },
    }),
});

export const createUsIorCreate5106KebabMenuItem = (
  pathToUsIor: string[] = [],
): AppRecordKebabMenuItem => ({
  key: 'createUsIorCbp5106',
  text: 'Add 5106',
  show: ({
    existingRecord,
    currentUser,
    workOrderTaskType,
    workOrderTaskEvaluatedState,
  }) =>
    currentUser.accountType === AccountType.OPERATOR &&
    [UsIorCbpStatus.NOT_ON_FILE, UsIorCbpStatus.INACTIVE].includes(
      get(existingRecord, [...pathToUsIor, 'cbpStatus']),
    ) &&
    get(existingRecord, [...pathToUsIor, 'iorNumber', 'value']) &&
    checkKebabMenuItemForWorkOrderTask(
      workOrderTaskType,
      workOrderTaskEvaluatedState,
    ),
  action: ({ record }) =>
    executeMutation({
      mutation: createUsIorCbp5106,
      variables: { usIorId: get(record, [...pathToUsIor, 'id']) },
    }),
});

export const createUsIorUpdate5106KebabMenuItem = (
  pathToUsIor: string[] = [],
): AppRecordKebabMenuItem => ({
  key: 'updateUsIorCbp5106',
  text: 'Update 5106 ',
  show: ({
    existingRecord,
    currentUser,
    workOrderTaskType,
    workOrderTaskEvaluatedState,
  }) =>
    currentUser.accountType === AccountType.OPERATOR &&
    [UsIorCbpStatus.ACTIVE_WITH_BOND, UsIorCbpStatus.ACTIVE_NO_BOND].includes(
      get(existingRecord, [...pathToUsIor, 'cbpStatus']),
    ) &&
    get(existingRecord, [...pathToUsIor, 'iorNumber', 'value']) &&
    checkKebabMenuItemForWorkOrderTask(
      workOrderTaskType,
      workOrderTaskEvaluatedState,
    ),
  action: ({ record }) =>
    executeMutation({
      mutation: updateUsIorCbp5106,
      variables: { usIorId: get(record, [...pathToUsIor, 'id']) },
    }),
});

export const createUsIorRequestCbpAssignedNumberKebabMenuItem = (
  pathToUsIor: string[] = [],
): AppRecordKebabMenuItem => ({
  key: 'requestUsIorCbpAssignedNumber',
  text: 'Request CBP Assigned Number',
  show: ({
    existingRecord,
    currentUser,
    workOrderTaskType,
    workOrderTaskEvaluatedState,
  }) => {
    const addresses = get(existingRecord, [...pathToUsIor, 'addresses']);
    const { countryCode } = addresses?.mailing || addresses?.physical || {};
    return (
      currentUser.accountType === AccountType.OPERATOR &&
      countryCode &&
      !get(existingRecord, [...pathToUsIor, 'iorNumber', 'value']) &&
      checkKebabMenuItemForWorkOrderTask(
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      )
    );
  },
  action: ({ dispatch, record }) => {
    dispatch(
      setModal({
        key: ModalKey.REQUEST_CBP_NUMBER,
        props: {
          visible: true,
          usIorId: get(record, [...pathToUsIor, 'id']),
        },
      }),
    );
  },
});

import { AnyObject } from '@xbcb/shared-types';
export const transformBulkUploadRequest = {
  toSchema: ({ input }: { input: AnyObject }): AnyObject => {
    const documentId = input.documentId;
    const operatorId = input.operatorId;
    const newInput: AnyObject = {
      ...input,
      document: { id: documentId },
      operator: { id: operatorId },
    };
    delete newInput.documentId;
    delete newInput.operatorId;
    return newInput;
  },
};

import { RecordType } from '@xbcb/shared-types';
import { nlIorActivationFragments } from 'libs/sharedQueries';
import { EuIorActivation } from '../../loadableComponents';
import euIorActivationRoute from './euIorActivation';

const nlIorActivationRoute = euIorActivationRoute({
  page: EuIorActivation,
  recordType: RecordType.NL_IOR_ACTIVATION,
  fields: `...nlIorActivationFields`,
  fragments: nlIorActivationFragments,
});

export default nlIorActivationRoute;

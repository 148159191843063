import React from 'react';
import { Select, Tooltip } from 'antd';
import IntendedUseMask from 'libs/intendedUseMask';
import { getIntendedUse } from 'selectors';
import { FormInstance } from 'antd/lib/form';
import { FormItem } from '@xbcb/form-item-components';
import { CssSize, NamePath } from '@xbcb/ui-types';
import {
  StyledIntendedUseInfoDiv,
  StyledInlineTooltip,
  StyledIntendedUseDiv,
} from './styles';

const { Option } = Select;

interface IntendedUseSelectProps {
  form: FormInstance;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (value: any, option: any) => void;
  required?: boolean;
  codes?: any[];
}

const IntendedUseSelect: React.FC<IntendedUseSelectProps> = ({
  form,
  fullNamePath,
  localNamePath,
  readOnly,
  required,
  disabled,
  onChange,
  codes = [],
}) => {
  const options = codes.map((code) => (
    <Option key={code} value={code}>
      {code}
    </Option>
  ));

  const intendedUseMask = new IntendedUseMask();

  const handleChange = (e: string) => {
    const intendedUse = intendedUseMask.handleChange(e);
    if (!intendedUse) return undefined;
    return intendedUse;
  };

  const intendedUseCode = form.getFieldValue(fullNamePath) || '';
  const intendedUse: any = getIntendedUse(intendedUseCode) || {};
  return (
    <StyledIntendedUseDiv>
      <FormItem
        name={localNamePath}
        label="Intended Use Code"
        rules={[
          {
            required,
            message: ' ',
          },
        ]}
        getValueFromEvent={handleChange}
        $readOnly={readOnly}
        $itemSize={CssSize.TINY_SHORT}
      >
        <Select
          allowClear
          showSearch
          disabled={disabled}
          placeholder="NNN.NNN"
          notFoundContent="None found"
          onChange={onChange}
        >
          {options}
        </Select>
      </FormItem>
      {intendedUseCode && (
        <StyledIntendedUseInfoDiv>
          <div>
            <span>{intendedUse.baseName}</span>
            <Tooltip placement="right" title={intendedUse.baseDescription}>
              <StyledInlineTooltip />
            </Tooltip>
          </div>
          <div>
            <span>{intendedUse.subName}</span>
            <Tooltip placement="right" title={intendedUse.subDescription}>
              <StyledInlineTooltip />
            </Tooltip>
          </div>
        </StyledIntendedUseInfoDiv>
      )}
    </StyledIntendedUseDiv>
  );
};

export default IntendedUseSelect;

import styled from 'styled-components';
import { spaceRight } from '@xbcb/ui-styles';

const imageSize = '18px';

export const StyledImg = styled.img`
  width: ${imageSize};
  height: ${imageSize};
`;

export const StyledSpan = styled.span`
  display: inline-block;
  ${spaceRight}
`;

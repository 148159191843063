import React from 'react';
import { Skeleton } from 'antd';
import { useBundle } from '@amzn/react-arb-tools';
import { SingleFormCard } from '@xbcb/display-components';
import { InfoTooltip } from '@xbcb/form-item-components';
import { safeGetMessage } from '@xbcb/ui-utils';
import tzString from 'libs/tzString';
import { StyledDiv, StyledFooter, StyledParagraph } from './styles';

export interface WorkOrderInfo {
  label: string;
  value?: string;
}

interface WorkOrderInfoCardProps {
  loading?: boolean;
  workOrderInfo: WorkOrderInfo[];
}

const WorkOrderInfoCard = ({
  loading,
  workOrderInfo,
}: WorkOrderInfoCardProps) => {
  const [workOrderBundle] = useBundle('components.WorkOrder');
  const [sharedBundle] = useBundle('shared');
  const timezone = tzString();
  return (
    <SingleFormCard
      title={safeGetMessage(workOrderBundle, 'work_order_info')}
      titlePosition="top"
      $noMarginBottom
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <StyledDiv>
          {workOrderInfo.map(({ label, value }, idx) => {
            if (!value) return null;
            return (
              <StyledParagraph key={idx}>
                {label}: {value}
              </StyledParagraph>
            );
          })}
          <StyledFooter>
            <InfoTooltip
              title={safeGetMessage(sharedBundle, 'timezone_info', {
                timezone,
              })}
              placement="left"
            />
          </StyledFooter>
        </StyledDiv>
      )}
    </SingleFormCard>
  );
};

export default WorkOrderInfoCard;

import type { UsHts } from '@xbcb/api-gateway-client';
import { htsResponseToObject } from 'libs/htsHelpers';
import { HtsData } from 'types';

export const getAdditionalHtsFromResponse = (
  result: HtsData,
  response: UsHts[],
): HtsData => {
  response.forEach((htsRecord: UsHts) => {
    const htsObj = htsResponseToObject(htsRecord);
    result[htsRecord.number] = htsObj;
    // HTS records with additionalHtsNumbers return the related
    // object under record.additional.hts; we want to add that to
    // state to avoid making extra search queries.
    const additional = [htsRecord.additionalHtssApplicable];
    while (additional.length) {
      const current = additional.shift();
      if (current) {
        current.forEach(({ number, hts }) => {
          if (hts) {
            result[number] = htsResponseToObject(hts);
            if (hts.additional) {
              additional.push(hts.additional);
            }
          }
        });
      }
    }
  });
  return result;
};

import styled from 'styled-components';
import { InlineTooltip } from '@xbcb/feedback-components';

export const StyledDiv = styled.div<{
  $inline?: boolean;
  $verticalAlign?: string;
}>`
  ${({ $inline }) => $inline && 'display: inline-block;'}
  ${({ $verticalAlign }) =>
    $verticalAlign && `vertical-align: ${$verticalAlign}`};
`;

export const StyledTooltip = styled(InlineTooltip)`
  display: inline-block;
`;

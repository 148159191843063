import { WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledWarningOutline = styled(WarningOutlined)`
  color: var(--primary-color);
`;

export const StyledWarningTitle = styled.p`
  padding-bottom: ${CssSpace.SPACE_3};
`;

import React from 'react';
import { Form, Divider } from 'antd';
import moment from 'moment';
import type { Forwarder, BillingDetails } from '@xbcb/api-gateway-client';
import { PickDate } from '@xbcb/form-item-components';
import { AnyObject, SubscriptionPaymentPeriod } from '@xbcb/shared-types';
import PlanSelect from 'components/PlanSelect';
import { titleCase } from 'libs/display';
import { StyledAppRadioGroup, StyledParagraph } from './styles';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

export interface CustomerPlanDetailsProps {
  isSubscriptionsDisabled: boolean;
  record: AnyObject;
  disabled?: boolean;
  readOnly?: boolean;
}

const CustomerPlanDetails: React.FC<CustomerPlanDetailsProps> = ({
  isSubscriptionsDisabled,
  record,
  disabled,
  readOnly,
}) => {
  if (isSubscriptionsDisabled || !record?.billingDetails?.subscriptionPlan) {
    return null;
  }

  const { billingDetails } = record as Forwarder;
  const { trialEndTime } = billingDetails || {};
  // Needs to be in UTC as this is how we store dates. Additionally, it should
  // use the end of the day because technically their trial is active until the
  // day is over
  const today = moment().endOf('day').utc();
  const isInTrial = Boolean(
    trialEndTime && today.isBefore(moment(trialEndTime)),
  );
  return (
    <>
      {isInTrial ? (
        <TrialDetails disabled={disabled} readOnly={readOnly} />
      ) : (
        <PlanDetails billingDetails={billingDetails} />
      )}
      <Divider />
    </>
  );
};

interface TrialDetailsProps {
  disabled?: boolean;
  readOnly?: boolean;
}

const TrialDetails: React.FC<TrialDetailsProps> = ({ disabled, readOnly }) => {
  const [partyBundle] = useBundle('components.Party');
  const form = Form.useFormInstance();
  const billingDetailsNamePath = ['billingDetails'];
  const trialEndTimeNamePath = [...billingDetailsNamePath, 'trialEndTime'];
  return (
    <>
      <h4>{safeGetMessage(partyBundle, 'trial_details')}</h4>
      <div data-testid="trial-details">
        <PickDate
          form={form}
          label={safeGetMessage(partyBundle, 'trial_end_date')}
          fullNamePath={trialEndTimeNamePath}
          localNamePath={trialEndTimeNamePath}
          disabled={disabled}
          readOnly={readOnly}
        />
        <PlanSelect
          localNamePath={[...billingDetailsNamePath, 'subscriptionPlan']}
          disabled={disabled}
          readOnly={readOnly}
        />
        <StyledAppRadioGroup
          disabled={disabled}
          readOnly={readOnly}
          options={[
            {
              value: SubscriptionPaymentPeriod.MONTHLY,
              label: safeGetMessage(partyBundle, 'paid_monthly'),
            },
            {
              value: SubscriptionPaymentPeriod.ANNUALLY,
              label: safeGetMessage(partyBundle, 'paid_annually'),
            },
          ]}
          localNamePath={[
            ...billingDetailsNamePath,
            'subscriptionPaymentPeriod',
          ]}
        />
      </div>
    </>
  );
};

interface SubscriptionDetailsProps {
  billingDetails?: BillingDetails;
}

const PlanDetails: React.FC<SubscriptionDetailsProps> = ({
  billingDetails,
}) => {
  const [partyBundle] = useBundle('components.Party');
  const { trialEndTime, subscriptionPlan, subscriptionPaymentPeriod } =
    billingDetails || {};
  return (
    <>
      <h4>{safeGetMessage(partyBundle, 'plan_details')}</h4>

      <div data-testid="plan-details">
        <StyledParagraph>
          {safeGetMessage(partyBundle, 'subscription_plan', {
            plan: () => <b>{titleCase(subscriptionPlan)}</b>,
          })}
        </StyledParagraph>
        <StyledParagraph>
          {safeGetMessage(partyBundle, 'subscription_period', {
            period: () => <b>{titleCase(subscriptionPaymentPeriod)}</b>,
          })}
        </StyledParagraph>
        <StyledParagraph>
          {safeGetMessage(partyBundle, 'trial_ended')}{' '}
          {trialEndTime ? (
            <b>{moment(trialEndTime).format('MM/DD/YY')}</b>
          ) : (
            <b>{safeGetMessage(partyBundle, 'did_not_have_one')}</b>
          )}
        </StyledParagraph>
      </div>
    </>
  );
};

export default CustomerPlanDetails;

import { useCurrentUser } from 'libs/hooks';
import { RecordType } from '@xbcb/shared-types';
import { forwarderFragments } from '@xbcb/party-queries';
import { SearchQuery } from '@xbcb/shared-queries';
import { useQuery } from '@apollo/client';

export const useGlobalMileForwarder = () => {
  const { operator } = useCurrentUser();
  const searchForwarderQuery = SearchQuery({
    recordName: RecordType.FORWARDER,
    fields: '...forwarderFields',
    fragments: forwarderFragments,
  });

  const { data: forwarderData } = useQuery(searchForwarderQuery, {
    variables: {
      input: {
        searchCriteria: {
          tags: {
            values: {
              key: 'strategicBusinessUnit',
              value: 'AmazonGlobalMileForwarding',
            },
            operator: 'EQUALS',
          },
          operatorId: {
            values: operator?.id,
            operator: 'EQUALS',
          },
        },
      },
    },
  });

  const forwarders: any[] = forwarderData?.searchForwarders?.results || [];

  return forwarders?.[0] || {}; // If there are multiple forwarders, take the first one. We should only have AGL forwarder per operator
};

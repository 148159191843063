import React from 'react';
import JSONTree from 'react-json-tree';
import treeTheme from 'libs/treeTheme';
import { RawHTML } from '@xbcb/static-text-components';
import { StyledDiv } from './styles';

const renderJSONTree = (data: any) => (
  <JSONTree
    data={data}
    hideRoot={true}
    theme={treeTheme}
    invertTheme={false}
    shouldExpandNode={() => true}
  />
);

const renderEDI = (data: string) => <RawHTML html={data} />;

interface TeamDatumProps {
  data: any;
  dataType: string;
}

const TeamDatum: React.FC<TeamDatumProps> = ({ data, dataType }) => (
  <StyledDiv>
    {dataType === 'json' ? renderJSONTree(data) : renderEDI(data)}
  </StyledDiv>
);

export default TeamDatum;

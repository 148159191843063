import type { WorkOrder } from '@xbcb/api-gateway-client';
import { isFbaScotForwarder } from '@xbcb/party-utils';
import { isEuCustomsEntry } from '@xbcb/work-order-utils/dist/isEuCustomsEntry';

export const isBrexitWorkOrder = (workOrder?: WorkOrder): boolean => {
  const isBrexitForwarder = workOrder?.group?.forwarders?.some((forwarder) =>
    isFbaScotForwarder(forwarder.forwarder),
  );
  return Boolean(isEuCustomsEntry(workOrder?.id) && isBrexitForwarder);
};

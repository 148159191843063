import {
  PgaValidationArgs,
  PgaValidator,
} from 'libs/entryValidation/pga/types';
import { PgaFlag } from 'libs/pgaHelpers';
import { ProductUsPgaInput } from '__generated__/graphql';
import { AppRecordValidateResult } from '@xbcb/ui-types';

export class EpaRegistrationNumberValidator
  implements PgaValidator<ProductUsPgaInput>
{
  isApplicable(args: PgaValidationArgs<ProductUsPgaInput>): boolean {
    return PgaFlag.subjectToPesticides(args.pgaFlag);
  }

  validate(): AppRecordValidateResult {
    return {
      validateFields: [['epaRegistrationNumber']],
    };
  }
}

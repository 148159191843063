import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';
import { Modal } from 'antd';

export const StyledDiv = styled.div`
  text-align: left;
  display: flex;
`;

export const StyledParagraph = styled.p`
  margin-bottom: ${CssSpace.SPACE_4};
  flex: 2;
`;

export const StyledModal = styled(Modal)`
  padding: 48px;
  font-size: 16px;
  label {
    font-size: 18px;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
  .ant-modal-content {
    max-height: 400px;
    overflow-y: auto;
  }
`;

import styled from 'styled-components';
import { Button } from 'antd';

export const StyledButton = styled(Button)`
  height: auto;
  padding: 0;
  box-shadow: none;
  margin-left: ${({ theme }) => theme.spacing.space_4};
  border: none;
`;

import { message } from 'antd';
import { reportError } from '@xbcb/ui-utils';

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  let successful;
  try {
    successful = document.execCommand('copy');
  } catch (e) {
    successful = false;
    reportError(e);
  }

  document.body.removeChild(textArea);
  return successful;
};

const copyTextToClipboard = async (text) => {
  let successful;
  if (!navigator.clipboard) {
    successful = fallbackCopyTextToClipboard(text);
  } else {
    successful = await new Promise((resolve) => {
      navigator.clipboard.writeText(text).then(
        () => {
          resolve(true);
        },
        () => {
          resolve(false);
        },
      );
    });
  }

  if (successful) {
    message.success('The link was copied to your clipboard successfully.');
  } else {
    message.error('The link could not be copied.');
  }
};

export default copyTextToClipboard;

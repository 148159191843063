import React, { MutableRefObject } from 'react';
import get from 'lodash/get';
import { MinusButton } from '@xbcb/button-components';
import LineItem from 'components/LineItem';
import { Tooltip, Form } from 'antd';
import { RecordType, AccountType } from '@xbcb/shared-types';
import { FormInstance } from 'antd/lib/form';
import { PlusOutlined } from '@ant-design/icons';
import { StyledLineItemsDiv, StyledButton } from './styles';
import { NamePath } from '@xbcb/ui-types';

interface LineItemsProps {
  recordType?: RecordType;
  fullNamePath: NamePath;
  localNamePath?: NamePath;
  form: FormInstance;
  keyRef?: MutableRefObject<string>;
  disabled?: boolean;
  readOnly?: boolean;
  importerId?: string;
  consumptionEntry: any;
  isPsc?: boolean;
}
const LineItems: React.FC<LineItemsProps> = ({
  importerId,
  consumptionEntry,
  fullNamePath,
  localNamePath,
  readOnly,
  form,
  keyRef,
  disabled,
  recordType,
  isPsc,
}) => {
  const { getFieldValue, getFieldError } = form;
  if (!localNamePath) localNamePath = fullNamePath;

  const getLastHTSNumberKey = (fields: any) => [
    ...fullNamePath,
    fields[fields.length - 1].name,
    'tariffs',
    0,
    'htsNumber',
  ];

  const lineActions = (
    index: number,
    fields: any,
    field: any,
    remove: any,
    add: any,
  ) => (
    <div>
      {fields.length > 1 && !readOnly && (
        <MinusButton
          label="line item"
          lineItem={true}
          disabled={disabled}
          onRemove={() => remove(field.name)}
          tabIndex={-1}
        />
      )}
      {index === fields.length - 1 && !readOnly && (
        <Tooltip placement="right" title="Add line item">
          <StyledButton
            disabled={
              disabled ||
              !getFieldValue(getLastHTSNumberKey(fields)) ||
              getFieldError(getLastHTSNumberKey(fields)).length > 0
            }
            size="small"
            shape="circle"
            onClick={add}
            tabIndex={-1}
          >
            <PlusOutlined />
          </StyledButton>
        </Tooltip>
      )}
    </div>
  );

  return (
    <StyledLineItemsDiv>
      <Form.List name={localNamePath} minimum={1}>
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Form.Item
                  shouldUpdate={(prev, curr) => {
                    const tariffsPath = [
                      ...fullNamePath,
                      field.name,
                      'tariffs',
                    ];
                    const tariffsCurr = get(curr, tariffsPath);
                    const tariffsPrev = get(prev, tariffsPath);
                    if (!tariffsCurr && !tariffsPrev) {
                      // undefined => undeifned, never update.
                      return false;
                    } else if (tariffsCurr && tariffsPrev) {
                      if (tariffsCurr.length !== tariffsPrev.length) {
                        return true;
                      }
                      // update when htsNumber changed, or extraPgaFlags changed.
                      for (let i = 0; i < tariffsCurr.length; i++) {
                        if (
                          tariffsCurr?.[i]?.htsNumber !==
                            tariffsPrev?.[i]?.htsNumber ||
                          tariffsCurr?.[i]?.extraPgaFlags !==
                            tariffsPrev?.[i]?.extraPgaFlags
                        ) {
                          return true;
                        }
                      }
                      // do not update if htsNumbers are all the same.
                      return false;
                    } else {
                      // undefined to defined or vice versa, always update.
                      return true;
                    }
                  }}
                  style={{ marginBottom: 0 }}
                >
                  {() => {
                    return (
                      <LineItem
                        required={
                          recordType === RecordType.US_CONSUMPTION_ENTRY ||
                          recordType === RecordType.US_TYPE86_ENTRY
                        }
                        htsRequired={recordType === RecordType.CLASSIFICATION}
                        accountType={AccountType.OPERATOR}
                        form={form}
                        key={field.key}
                        keyRef={keyRef}
                        readOnly={readOnly}
                        disabled={disabled}
                        consumptionEntry={consumptionEntry}
                        localNamePath={[field.name]}
                        fullNamePath={[...fullNamePath, field.name]}
                        $noMarginBottom={index === fields.length - 1}
                        isPsc={isPsc}
                        recordType={recordType}
                        lineActions={lineActions(
                          index,
                          fields,
                          field,
                          remove,
                          add,
                        )}
                      />
                    );
                  }}
                </Form.Item>
              </div>
            ))}
          </div>
        )}
      </Form.List>
    </StyledLineItemsDiv>
  );
};
export default LineItems;

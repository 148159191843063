import React from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { AccountType } from '@xbcb/shared-types';
import { useCurrentUser } from 'libs/hooks';
import copyToClipboard from 'libs/copyToClipboard';
import { AppRecordSecondaryHeaderContentProps } from 'routes';
import { continuousBondFailedFromMilestones } from 'libs/continuousBondFailedFromMilestones';
import { StyledDiv, StyledWarningOutline, StyledParagraph } from './styles';

const UsIorContinuousBondRequestSecondaryHeader: React.FC<
  AppRecordSecondaryHeaderContentProps
> = ({ record }) => {
  const { accountType } = useCurrentUser();
  const bondAcquisitionFailed = continuousBondFailedFromMilestones(record);
  return (
    <StyledDiv>
      <Tooltip title="Copy the work order URL" placement="top">
        <LinkOutlined onClick={() => copyToClipboard(window.location.href)} />
      </Tooltip>

      {accountType === AccountType.OPERATOR && bondAcquisitionFailed && (
        <Tooltip
          title={
            <StyledParagraph>
              Bond acquisition failed, please acquire the bond manually and use
              charge bond from the kebab menu
            </StyledParagraph>
          }
          placement="top"
        >
          <StyledWarningOutline />
        </Tooltip>
      )}
    </StyledDiv>
  );
};

export default UsIorContinuousBondRequestSecondaryHeader;

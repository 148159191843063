export const invoiceTypeMap = {
  dutyPMS: 'Duty Monthly Statement',
  dutyDaily: 'Duty Daily Statement',
  sub: 'INLT Subscription',
  statement: 'Services Statement',
  shipment: 'Shipment Invoice',
  continuousBond: 'Continuous Bond',
};

export const invoiceSourceMap = {
  achDebit: 'ACH debit',
  creditCard: 'Credit Card',
  check: 'Check',
  wire: 'Wire',
  paypal: 'PayPal',
};

import {
  AnyObject,
  ClassificationSubjectMatterSubCategory,
  PgaSubjectMatterSubCategory,
  SubjectMatterCategory,
  SubjectMatterCategoryToSubCatoriesMap,
  SubjectMatterSubCategory,
} from '@xbcb/shared-types';
import {
  ExpertiseCategory,
  SubjectMatterCategory as ApigSubjectMatterCategory,
} from '@xbcb/api-gateway-client';

const smeCategoriesWithoutSubCategory = Object.entries(
  SubjectMatterCategoryToSubCatoriesMap,
)
  .filter(([, value]) => !value.length)
  .map(([key]) => key);

export const transformSubjectMatterExpertTeam = {
  toForm: ({
    existingRecord: newRecord,
  }: {
    existingRecord: AnyObject;
  }): AnyObject => {
    const { operatorUsers } = newRecord;
    operatorUsers.edges.forEach((edge: AnyObject) => {
      const expertiseCategories = edge.relationship.expertiseCategories;
      const operatorUserId = edge.node.id;
      expertiseCategories.forEach(
        (expertiseCategory: {
          category: SubjectMatterCategory;
          subCategories: [SubjectMatterSubCategory];
        }) => {
          const subCategories = expertiseCategory.subCategories;
          const category = expertiseCategory.category;
          // isAllClassification and isAllPga are true only when all their subcategories are selected
          const isAllClassification =
            category === SubjectMatterCategory.CLASSIFICATION &&
            subCategories.length ===
              Object.keys(ClassificationSubjectMatterSubCategory).length;
          const isAllPga =
            category === SubjectMatterCategory.PGA &&
            subCategories.length ===
              Object.keys(PgaSubjectMatterSubCategory).length;
          if (
            smeCategoriesWithoutSubCategory.includes(category) ||
            // The name of the expert is added to classification or pga field only if the operator is present in all the respective subcategories
            isAllClassification ||
            isAllPga
          ) {
            if (newRecord[category]?.id) {
              newRecord[category].id.push(operatorUserId);
            } else {
              newRecord[category] = { id: [operatorUserId] };
            }
          }
          subCategories.forEach((subCategory: SubjectMatterSubCategory) => {
            if (newRecord[subCategory]) {
              newRecord[subCategory].id.push(operatorUserId);
            } else {
              newRecord[subCategory] = { id: [operatorUserId] };
            }
          });
        },
      );
    });
    return newRecord;
  },
  toSchema: ({
    input,
    isCreate,
  }: {
    input: any;
    isCreate?: boolean;
  }): AnyObject => {
    const values = JSON.parse(JSON.stringify(input));
    const { accountId, customsBrokerId } = values;
    const members: {
      userId: string;
      expertiseCategories: ExpertiseCategory[];
    }[] = [];
    if (isCreate) {
      if (accountId) {
        values.operator = { id: accountId };
      }
      if (customsBrokerId) {
        values.customsBroker = { id: customsBrokerId };
      }
    } else {
      delete values.operator;
    }
    const smeSubCategories = Object.values(
      SubjectMatterSubCategory,
    ) as string[];
    const classificationSubCategories = Object.values(
      ClassificationSubjectMatterSubCategory,
    ) as string[];
    Object.keys(values).forEach((key) => {
      if (smeCategoriesWithoutSubCategory.includes(key)) {
        if (values[key]) {
          values[key].id.forEach((id: any) => {
            const expertiseCategory: ExpertiseCategory = {
              category: key as ApigSubjectMatterCategory,
              subCategories: [],
            };
            const user = members.find(
              (member: { userId: string; expertiseCategories: AnyObject }) =>
                member.userId === id,
            );
            if (user) {
              user.expertiseCategories.push(expertiseCategory);
            } else {
              members.push({
                expertiseCategories: [expertiseCategory],
                userId: id,
              });
            }
          });
        }
        delete values[key];
      } else if (smeSubCategories.includes(key)) {
        if (values[key]) {
          values[key].id.forEach((id: string) => {
            const category = classificationSubCategories.includes(key)
              ? SubjectMatterCategory.CLASSIFICATION
              : SubjectMatterCategory.PGA;
            const expertiseCategory: ExpertiseCategory = {
              category,
              subCategories: [key as SubjectMatterSubCategory],
            };
            const user = members.find(
              (member: { userId: string }) => member.userId === id,
            );
            if (user) {
              const existingExpertiseCategory = user.expertiseCategories.find(
                (expertiseCategory: ExpertiseCategory) =>
                  expertiseCategory.category === category,
              );
              if (existingExpertiseCategory?.subCategories) {
                existingExpertiseCategory.subCategories.push(
                  key as SubjectMatterSubCategory,
                );
              } else {
                user.expertiseCategories.push(expertiseCategory);
              }
            } else {
              members.push({
                expertiseCategories: [expertiseCategory],
                userId: id,
              });
            }
          });
        }
        delete values[key];
      }
    });
    values.members = members;
    delete values.accountId;
    delete values.operatorUsers;
    delete values[SubjectMatterCategory.CLASSIFICATION];
    delete values[SubjectMatterCategory.PGA];
    delete values.customsBrokerId;
    return values;
  },
};

import styled from 'styled-components';
import { Form } from 'antd';

export const StyledFlexDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const StyledFormItem = styled(Form.Item)`
  p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 300;
    background: transparent;
  }
`;

import type { ProductUsPgaInput } from '__generated__/graphql';
import { safeGet } from '@xbcb/js-utils';
import { AppRecordValidateResult, NamePath } from '@xbcb/ui-types';
import {
  hasCountryRequirement,
  hasImporterPermitRequirement,
  hasLabelApprovalRequirement,
  hasQuantityRequirement,
} from './pgaValidationHelpers';

interface validateTtbProps {
  pga: ProductUsPgaInput;
  getField: (field: string | string[]) => NamePath;
}

export const validateTtb = ({
  pga = {},
  getField,
}: validateTtbProps): AppRecordValidateResult => {
  const validateFields: NamePath[] = [];
  const ttbFields: (string | string[])[] = [];
  const programCode = pga.programCode ?? '';
  const processingCode = pga.processingCode ?? '';

  if (hasImporterPermitRequirement(processingCode)) {
    const importerPermitExempt = safeGet(pga, [
      'ttbImporterPermitNumber',
      'exempt',
    ]);
    if (importerPermitExempt) {
      ttbFields.push(['ttbImporterPermitNumber', 'exemptionCode']);
    } else {
      ttbFields.push(['ttbImporterPermitNumber', 'number']);
    }
  }
  if (hasLabelApprovalRequirement(programCode)) {
    const labelApprovalExempt = safeGet(pga, [
      'certificateOfLabelApproval',
      'exempt',
    ]);
    if (labelApprovalExempt) {
      ttbFields.push(['certificateOfLabelApproval', 'exemptionCode']);
    } else {
      ttbFields.push(['certificateOfLabelApproval', 'number']);
    }
  }
  if (hasCountryRequirement(programCode)) {
    ttbFields.push(['foreignCertificate', 'countryCode']);
  }
  if (hasQuantityRequirement(processingCode)) {
    ttbFields.push('paperOrTubeQuantity');
  }

  ttbFields.forEach((field) => {
    validateFields.push(getField(field));
  });

  return { validateFields };
};

import styled from 'styled-components';
import { FormItem } from '@xbcb/form-item-components';

export const StyledLpcoDiv = styled.div`
  display: flex;
  .checkbox {
    margin-left: ${({ theme }) => theme.spacing.space_4};
  }
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0;
`;

import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledDiv = styled.div`
  font-size: 18px;
  display: flex;

  .anticon:not(:first-child) {
    margin-left: ${CssSpace.SPACE_4};
  }
`;

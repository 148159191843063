import moment from 'moment';
import { AnyObject } from '@xbcb/shared-types';
import { SingleFormCard, Datum } from '@xbcb/display-components';
import { capitalCase } from 'change-case';
import { UsIorPaymentTerms } from '@xbcb/party-types';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

type PaymentTermsCardProps = {
  record: AnyObject;
};

const PaymentTermsCard: React.FC<PaymentTermsCardProps> = ({ record }) => {
  const [partyBundle] = useBundle('components.Party');
  const { paymentTerms, paymentTermsUpdated } = record;
  const formattedPaymentTerms = paymentTerms
    ? paymentTerms === UsIorPaymentTerms.PMS
      ? safeGetMessage(partyBundle, 'monthly_periodic_statement')
      : capitalCase(paymentTerms)
    : safeGetMessage(partyBundle, 'not_defined');
  const paymentTermsUpdatedTime = paymentTermsUpdated?.time;
  const now = moment();
  const diff = paymentTermsUpdatedTime
    ? safeGetMessage(partyBundle, 'date_last_updated', {
        days: now
          .diff(moment(paymentTermsUpdatedTime), 'days')
          .toLocaleString(),
      })
    : safeGetMessage(partyBundle, 'not_available');
  return (
    <SingleFormCard
      title={safeGetMessage(partyBundle, 'payment_terms')}
      titlePosition="top"
    >
      <tbody>
        <Datum
          label={safeGetMessage(partyBundle, 'terms')}
          value={formattedPaymentTerms}
        />
        <Datum label={safeGetMessage(partyBundle, 'updated')} value={diff} />
      </tbody>
    </SingleFormCard>
  );
};

export default PaymentTermsCard;

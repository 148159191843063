import {
  WorkOrderTaskStatus,
  WorkOrderTaskSubStatus,
} from '@xbcb/work-order-types';
import { fetchLatestRecordAndExecuteMutation } from 'libs/fetchLatestRecordAndExecuteMutation';
import { AppRecordSubmitButton } from 'routes';
import { RecordType } from '@xbcb/shared-types';
import { updateWorkOrderTaskStatus } from 'libs/sharedQueries';
import { findMatchingWorkOrderTask } from 'libs/findMatchingWorkOrderTask';
import { WorkOrderTaskEvaluatedState } from 'libs/evaluateWorkOrderTaskState';
import { isAbbeyRoadWorkOrder } from './isAbbeyRoadWorkOrder';

export const startWorkOrderTaskButton = (
  workOrderType: RecordType,
): AppRecordSubmitButton => {
  const workOrderButton: AppRecordSubmitButton = {
    key: 'openTask',
    text: 'Open Task',
    show: ({ workOrderTaskEvaluatedState, existingRecord }) => {
      return (
        !isAbbeyRoadWorkOrder(workOrderType, existingRecord) &&
        workOrderTaskEvaluatedState ===
          WorkOrderTaskEvaluatedState.READY_TO_START
      );
    },
    skipValidation: () => true,
    skipUpdateRecord: () => true,
    onSubmit: async ({ updatedRecord, workOrderTaskType }) => {
      if (workOrderTaskType) {
        const workOrderTask = findMatchingWorkOrderTask(
          updatedRecord,
          workOrderTaskType,
        );
        await fetchLatestRecordAndExecuteMutation({
          id: workOrderTask?.id,
          fields: 'status',
          recordType: 'WORK_ORDER_TASK' as RecordType,
          mutation: updateWorkOrderTaskStatus,
          successMessage: `Work Order Task is now actively in progress`,
          constructVariablesWithIdVersion: (id, version) => ({
            id,
            version,
            input: {
              status: WorkOrderTaskStatus.IN_PROGRESS,
              subStatus: WorkOrderTaskSubStatus.ACTIVELY_IN_PROGRESS,
            },
          }),
        });
      }
    },
  };
  return workOrderButton;
};

export const stopWorkOrderTaskButton = (
  recordType: RecordType,
): AppRecordSubmitButton => {
  const workOrderButton: AppRecordSubmitButton = {
    key: 'pauseTask',
    text: 'Pause Task',
    show: ({ workOrderTaskEvaluatedState, existingRecord }) => {
      return (
        !isAbbeyRoadWorkOrder(recordType, existingRecord) &&
        workOrderTaskEvaluatedState === WorkOrderTaskEvaluatedState.STARTED
      );
    },
    skipValidation: () => true,
    skipUpdateRecord: () => true,
    onSubmit: async ({ updatedRecord, workOrderTaskType }) => {
      if (workOrderTaskType) {
        const workOrderTask = findMatchingWorkOrderTask(
          updatedRecord,
          workOrderTaskType,
        );
        await fetchLatestRecordAndExecuteMutation({
          id: workOrderTask?.id,
          fields: 'status',
          recordType: 'WORK_ORDER_TASK' as RecordType,
          mutation: updateWorkOrderTaskStatus,
          successMessage: `Work Order Task is no longer actively in progress`,
          constructVariablesWithIdVersion: (id, version) => ({
            id,
            version,
            input: {
              status: WorkOrderTaskStatus.IN_PROGRESS,
              subStatus: WorkOrderTaskSubStatus.PASSIVELY_IN_PROGRESS,
            },
          }),
        });
      }
    },
  };
  return workOrderButton;
};

import {
  tablesSave,
  tablesUpdate,
  tableReplace,
  tablesReplace,
  tableMoveColumn,
} from '../actions';
import { createReducer } from '@reduxjs/toolkit';
import { TablesState } from '../types';
import { get } from 'lodash';

const initialTablesState: TablesState = {};

export default createReducer(initialTablesState, (builder) =>
  builder
    .addCase(tablesSave, (state, { payload }) => ({
      ...state,
      ...payload,
    }))
    .addCase(tablesUpdate, (state, { payload }) => {
      return {
        ...payload,
      };
    })
    .addCase(tableReplace, (state, { payload }) => {
      const { tableName, table } = payload;
      const objectSlice = state[tableName] || {};
      return {
        ...state,
        [tableName]: {
          ...objectSlice,
          ...table,
        },
      };
    })
    .addCase(tablesReplace, (state, { payload = [] }) => ({
      ...state,
      ...payload.reduce(
        (acc, { tableName, table } = {} as (typeof payload)[0]) => ({
          ...acc,
          [tableName]: {
            ...get(state, tableName, {}),
            ...table,
          },
        }),
        {},
      ),
    }))
    .addCase(tableMoveColumn, (state, { payload }) => {
      const { columnIndex, tableName, direction, columnOptions } = payload;
      const tableSlice = state[tableName];
      let columns = tableSlice?.columns || [];
      columns = columns.filter((column) => columnOptions.includes(column));
      if (direction === 'left') {
        columns[columnIndex] = columns.splice(
          columnIndex - 1,
          1,
          columns[columnIndex],
        )[0];
      } else if (direction === 'right') {
        columns[columnIndex] = columns.splice(
          columnIndex + 1,
          1,
          columns[columnIndex],
        )[0];
      }
      return {
        ...state,
        [tableName]: {
          ...tableSlice,
          columns,
        },
      };
    }),
);

import { gql } from '@apollo/client';

const fragments = gql`
  fragment recordActionFields on RecordAction {
    source {
      __typename
      ... on InternalSystem {
        internalSystemName: name
      }
      ... on InternalWebsite {
        internalWebsiteName: name
      }
      ... on ClientSystem {
        clientSystemName: name
      }
    }
    time
    reason {
      code
      text
    }
    client {
      id
    }
    request {
      ipAddress
      userAgent
      requestId
    }
  }

  fragment recordFields on Record {
    created {
      ...recordActionFields
    }
    updated {
      ...recordActionFields
    }
    deleted {
      ...recordActionFields
    }
    id
    version
    tags {
      key
      value
    }
  }

  fragment memoRecordActionFields on RecordAction {
    source {
      __typename
      ... on InternalSystem {
        internalSystemName: name
      }
      ... on InternalWebsite {
        internalWebsiteName: name
      }
      ... on ClientSystem {
        clientSystemName: name
      }
    }
    time
    reason {
      code
      text
    }
    client {
      id
      name
    }
    request {
      ipAddress
      userAgent
      requestId
    }
  }

  fragment memoFields on Memo {
    text
    isPublic
    operator {
      id
    }
    created {
      ...memoRecordActionFields
    }
    updated {
      ...memoRecordActionFields
    }
    deleted {
      ...memoRecordActionFields
    }
    id
    version
    tags {
      key
      value
    }
  }
`;

export const goodsShipmentFragment = gql`
  fragment goodsShipmentFields on GoodsShipment {
    importer {
      name
    }
    importCountry {
      country
    }
    exportCountry {
      country
    }
    consignment {
      borderTransportationMean {
        journey
        name
        arrival
        arrivalLocation {
          identification
        }
        departureLocation {
          identification
        }
      }
      transportContractDocument {
        identification
        type
      }
      transportEquipment {
        fullness
        identification
      }
    }
  }
`;
export const queryCustomsDeclaration = gql`
  query CustomsDeclaration($id: ID!) {
    customsDeclaration(id: $id) {
      id
      identification
      version
      status
      deadline
      operator {
        id
      }
      confirmations {
        id
        status
      }
      goodsShipment {
        ...goodsShipmentFields
      }
      milestones {
        tags {
          key
          value
        }
        id
        name
        occurenceTime
        source
        created {
          client {
            name
          }
        }
      }
      tasks {
        ...recordFields
        name
        workOrderType
        status
        assignee {
          id
          name
        }
        deadline
        steps {
          id
          name
          description
          isCompletedBySystem
          isFinalConfirmation
          completed {
            ...recordActionFields
          }
        }
        definition {
          id
          estimatedDuration
          workOrderTaskType
        }
      }
      group {
        documents {
          documentTags
        }
        customsBroker {
          tags {
            key
            value
          }
        }
        forwarders {
          forwarder {
            id
            name
            billingDetails {
              disbursement
              status
              subscriptionPlan
            }
          }
        }
        shipment {
          id
          clientReferences {
            bookingId
          }
          legs {
            transportationServiceName
          }
          preferredDutyCollectPartyType
        }
        invoices {
          id
        }
        id
        memos {
          ...memoFields
        }
        shipper {
          id
          shipperExternallySourcedDetails {
            externalReferenceId
          }
          billingDetails {
            disbursement
            status
            subscriptionPlan
          }
          memos {
            ...memoFields
          }
        }
      }
    }
  }
  ${fragments}
  ${goodsShipmentFragment}
`;

export const updateCustomsDeclaration = gql`
  mutation UpdateCustomsDeclaration(
    $id: ID!
    $version: Int!
    $input: UpdateCustomsDeclarationInput!
  ) {
    updateCustomsDeclaration(input: $input, version: $version, id: $id) {
      record {
        id
      }
    }
  }
`;

import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Button } from 'antd';
import ConstituentElement from 'components/ConstituentElement';
import { FormInstance } from 'antd/lib/form';
import { InfoTooltip } from '@xbcb/form-item-components';
import { NamePath } from '@xbcb/ui-types';
import { StyledConstituentElementsDiv } from './styles';

export interface ConstituentElementsProps {
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  required?: boolean;
}

const ConstituentElements: React.FC<ConstituentElementsProps> = ({
  form,
  readOnly,
  disabled,
  localNamePath,
  fullNamePath,
  required,
}) => (
  <StyledConstituentElementsDiv className="constituent-elements">
    <h4>Constituent Elements</h4>
    <InfoTooltip
      className="space-left"
      title="To activate the 'percentage' input, enter 'SPECIAL RECYCLED' as the scientific name. The percentage of recycled material in the product/component is for paper, paperboard, and pulp. If the percent varies indicate the average amount."
    />
    <Form.List name={[...localNamePath]}>
      {(fields, { add, remove }) => (
        <div>
          {fields.map((field, index) => (
            <div key={field.key}>
              <Form.Item style={{ marginBottom: 0 }}>
                <ConstituentElement
                  key={field.key}
                  form={form}
                  readOnly={readOnly}
                  disabled={disabled}
                  fullNamePath={[...fullNamePath, field.name]}
                  localNamePath={[field.name]}
                  index={index}
                  onRemove={() => remove(field.name)}
                  allowDelete // TODO we should add a prop minimumRequired so FDA can enforce at least one ConstituentElement while it can remain optional for APH. This is how it worked in legacy
                  required={required}
                />
              </Form.Item>
            </div>
          ))}
          {!readOnly && (
            <Button onClick={add}>
              <PlusOutlined />
              Constituent element
            </Button>
          )}
        </div>
      )}
    </Form.List>
  </StyledConstituentElementsDiv>
);

export default ConstituentElements;

/*
 * Returns if the packaging is needed for the product
 * @param
 * @return Boolean indicating if packaging is required for the product or not
 */

export default (
  subjectToPN: boolean,
  pnConditionsMet: any,
  performanceStandard: any,
) => {
  return (subjectToPN && !pnConditionsMet) || performanceStandard;
};

export const validateFdaProductCode = (fdaProductCode: string): void => {
  // https://www.fda.gov/industry/import-program-tools/product-codes-and-product-code-builder#structure
  const industry = fdaProductCode.substring(0, 2);
  const productClass = fdaProductCode[2];
  const subclass = fdaProductCode[3];
  const processIndicatorCode = fdaProductCode[4];
  if (industry === '52') {
    // If productClass is not A, subclass and processIndicatorCode MUST be Y
    if (
      productClass !== 'A' &&
      (subclass !== 'Y' || processIndicatorCode !== 'Y')
    ) {
      throw new Error(
        'Subclass and process indicator code must be set to Y for the FDA product code',
      );
    }
  }
};

const worker = `() => {
  importScripts('https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.11.5/xlsx.full.min.js');
  onmessage = evt => {
    const file = evt.data;
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      let binary = '';
      const bytes = new Uint8Array(reader.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      const workbook = XLSX.read(binary, {type: 'binary'});
      const data = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {header:'A'});
      postMessage(data);
    });
    reader.readAsArrayBuffer(file);
  }
}`;

const code = worker.substring(worker.indexOf('{') + 1, worker.lastIndexOf('}'));
const blob = new Blob([code], { type: 'application/javascript' });
const workerScript = URL.createObjectURL(blob);

export default workerScript;

import { gql } from '@apollo/client';

export const createWorkOrderPresignedUrlGqlString = gql`
  mutation CreateWorkOrderPresignedUrl {
    createWorkOrderPresignedUrl {
      uploadLink
      referenceKey
    }
  }
`;

import styled from 'styled-components';
import { FormItem } from '@xbcb/form-item-components';
import { InlineTooltip } from '@xbcb/feedback-components';
import { inlineBlockFormItem } from '@xbcb/ui-styles';

export const StyledDiv = styled.div<{
  $inline?: boolean;
}>`
  ${({ $inline }) => $inline && 'display: inline-block;'}
  // TODO same as 'StyledDunsWrapperDiv' in 'PartyForm' we could not figure out
  //  a more elegant way, explore later
  vertical-align: -webkit-baseline-middle;
`;

export const StyledFormItem = styled(FormItem)`
  ${inlineBlockFormItem}
`;

export const StyledTooltip = styled(InlineTooltip)`
  ${inlineBlockFormItem}
`;

export const documentFragment = `
fragment documentFields on Document {
  extension
  fileName
  contentType
  antivirusScanStatus
  passwordStatus
  sha1
  size
  documentTags
  content {
    downloadLink
    uploadLink
  }
  preview {
    downloadLink
    uploadLink
  }
  disSubmissions {
    disStatus
    disDocName
    transaction {
      ... on SubmitUsConsumptionEntryDisTransaction {
        id
        version
        created {
          time
          client {
            name
          }
        }
      }
      ... on SubmitUsType86EntryDisTransaction {
        id
        version
        created {
          time
          client {
            name
          }
        }
      }
    }
  }
  status
}
`;

// technically we dont need this variable but it's to add more fragments in documentFragment later on and not change the exports in other files
export const documentFragments = `
  ${documentFragment}
`;

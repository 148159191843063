import { Form, Input } from 'antd';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { FormItem, INLTSwitch } from '@xbcb/form-item-components';
import { RecordType } from '@xbcb/shared-types';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { shouldUpdate } from '@xbcb/ui-utils';
import { StyledInlineDiv } from 'components/LineItem/styles';
import { NRBS_RECORD_NUMBER_LEN } from 'libs/constant';
import { StyledContainer } from './styles';

export interface LineItemNrbsFormProps {
  isDisclaimed?: boolean;
  parentFullNamePath: NamePath;
  parentLocalNamePath: NamePath;
  isAdCase: boolean;
  caseId?: string;
  readOnly?: boolean;
  disabled?: boolean;
  recordType?: RecordType;
}

const nrbsRecordNumberRegex = /[^a-zA-Z0-9]+/g;
const IS_INDIVIDUAL_NRBS_DEFAULT = true;

const LineItemNrbsForm: React.FC<LineItemNrbsFormProps> = ({
  caseId,
  disabled,
  isAdCase,
  isDisclaimed,
  parentFullNamePath,
  parentLocalNamePath,
  readOnly,
  recordType,
}) => {
  const form = Form.useFormInstance();
  const { setFields } = form;

  const isIndividualNrbsKey = useMemo(
    () => [...parentFullNamePath, 'isNrbsOnFile'],
    [parentFullNamePath],
  );
  const nrbsRecordNumberKey = useMemo(
    () => [...parentFullNamePath, 'nrbsRecordNumber'],
    [parentFullNamePath],
  );

  const isIndividualNrbs = Form.useWatch(isIndividualNrbsKey, form);

  const handleIsIndividualNrbsChange = (checked: boolean) => {
    if (checked) {
      clearNrbsRecordNumber();
    }

    setFields([
      {
        name: isIndividualNrbsKey,
        value: checked,
      },
    ]);
  };

  const clearIsIndividualNrbs = useCallback(() => {
    const newVal = isAdCase && caseId ? IS_INDIVIDUAL_NRBS_DEFAULT : undefined;
    setFields([
      {
        name: isIndividualNrbsKey,
        value: newVal,
      },
    ]);
  }, [caseId, isAdCase, isIndividualNrbsKey, setFields]);

  const handleNrbsRecordNumberChange = (val: string) => {
    val = val.replace(nrbsRecordNumberRegex, '');
    setFields([
      {
        name: nrbsRecordNumberKey,
        value: val,
      },
    ]);
  };
  const clearNrbsRecordNumber = useCallback(() => {
    setFields([
      {
        name: nrbsRecordNumberKey,
        value: undefined,
      },
    ]);
  }, [setFields, nrbsRecordNumberKey]);

  useEffect(() => {
    if (isAdCase && !caseId) {
      clearIsIndividualNrbs();
      clearNrbsRecordNumber();
    }
  }, [isAdCase, caseId, clearIsIndividualNrbs, clearNrbsRecordNumber]);

  useEffect(() => {
    // If we are disclaiming the case, we shouldn't include
    // NRBS info in the payload.
    if (isDisclaimed) {
      clearIsIndividualNrbs();
      clearNrbsRecordNumber();
    }
  }, [isDisclaimed, clearIsIndividualNrbs, clearNrbsRecordNumber]);

  /**
   * This component can also be rendered on the Product creation form,
   * but NRBS isn't relevant there (and not part of the schema)
   */
  const isProductForm = recordType === RecordType.PRODUCT;
  if (!caseId || !isAdCase || isDisclaimed || isProductForm) return null;
  return (
    <StyledContainer data-testid="nrbs-form-container">
      <FormItem
        shouldUpdate={shouldUpdate([isIndividualNrbsKey, nrbsRecordNumberKey])}
      >
        <>
          <INLTSwitch
            initialValue={IS_INDIVIDUAL_NRBS_DEFAULT}
            onWord="Yes"
            offWord="No"
            readOnly={readOnly}
            disabled={disabled}
            field={[...parentLocalNamePath, 'isNrbsOnFile']}
            onChange={handleIsIndividualNrbsChange}
            label="Individual NRBS"
          />

          {!isIndividualNrbs ? (
            <StyledInlineDiv>
              <FormItem
                name={[...parentLocalNamePath, 'nrbsRecordNumber']}
                label="Blanket NRBS"
                $itemSize={CssSize.MEDIUM}
                $readOnly={readOnly}
              >
                <Input.TextArea
                  autoSize={{ maxRows: NRBS_RECORD_NUMBER_LEN }}
                  disabled={disabled}
                  maxLength={NRBS_RECORD_NUMBER_LEN}
                  onChange={(e) => handleNrbsRecordNumberChange(e.target.value)}
                  readOnly={isIndividualNrbs}
                />
              </FormItem>
            </StyledInlineDiv>
          ) : null}
        </>
      </FormItem>
    </StyledContainer>
  );
};

export default memo(LineItemNrbsForm);

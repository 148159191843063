import styled from 'styled-components';
import { AppRadioGroup } from '@xbcb/form-item-components';

export const StyledAppRadioGroup = styled(AppRadioGroup)`
  margin-top: ${({ theme }) => theme.spacing.space_4};
`;

export const StyledParagraph = styled.p`
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: ${({ theme }) => theme.spacing.space_3};
`;

import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import OpenModalButton, {
  OpenModalButtonProps,
} from 'components/OpenModalButton';

const createRecordModalContent = (
  <>
    <PlusOutlined /> Create
  </>
);

interface CreateButtonModalInterface extends OpenModalButtonProps {
  modalContent?: React.ReactNode | JSX.Element;
}
// TODO Add support for Modals that have required properties such as CreateUsIorContinuousBondRequestModal.
// They would render but have an inconsistent state as the props that are required would be `undefined`
export const createButtonModal = ({
  modalContent,
  ...props
}: CreateButtonModalInterface) => {
  return (
    <OpenModalButton key={`${props.modalKey}Button`} {...props}>
      {modalContent ? modalContent : createRecordModalContent}
    </OpenModalButton>
  );
};

import styled from 'styled-components';
import { Button, Collapse, Modal, Switch } from 'antd';

export const StyledH2 = styled.h2`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.space_4};
`;

export const StyledModal = styled(Modal)`
  padding: 48px;
  font-size: 16px;
  label {
    font-size: 18px;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
`;

export const StyledSpan = styled.span`
  text-align: right;
`;

export const StyledButton = styled(Button)`
  align-self: flex-end;
  margin-right: ${({ theme }) => theme.spacing.space_4};
`;

export const StyledSwitch = styled(Switch)`
  align-self: flex-end;
  margin-right: ${({ theme }) => theme.spacing.space_5};
`;

export const StyledCollapse = styled(Collapse)`
  background-color: transparent;
`;

export const StyledPanel = styled(Collapse.Panel)`
  &.ant-collapse-item {
    border: none;
  }

  .ant-collapse-header {
    padding-top: 0;
    padding-bottom: 0;
    border: none;

    .anticon {
      bottom: ${({ theme }) => theme.spacing.space_3};
    }
  }
`;

import { gql } from '@apollo/client';

export const cancelUsIorContinuousBondRequestRenewal = gql`
  mutation CancelUsIorContinuousBondRequestRenewal($id: ID!, $version: Int!) {
    cancelUsIorContinuousBondRequestRenewal(id: $id, version: $version) {
      usIorContinuousBondRequest {
        id
        version
        renewalCanceled {
          time
        }
      }
    }
  }
`;

import React from 'react';
import { omit } from 'lodash';
import { Select, Option, FormItem } from '@xbcb/form-item-components';
import { selectFilter, getCATAIR } from '@xbcb/ui-utils';
import { CssSize, NamePath } from '@xbcb/ui-types';

type UOMSelectProps = {
  localNamePath: NamePath;
  hideLabel?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value: any) => void;
  required?: boolean;
  size?: CssSize;
  isLaceyAct?: boolean;
};

const UOMSelect: React.FC<UOMSelectProps> = ({
  localNamePath,
  hideLabel,
  disabled,
  readOnly,
  onChange,
  required,
  size = CssSize.TINY,
  isLaceyAct,
}) => {
  let uomCodes: any;
  if (isLaceyAct) {
    uomCodes = getCATAIR().PGA.laceyActUnitCodes;
  } else {
    uomCodes = getCATAIR().unit;
  }
  const uomKeys = Object.keys(omit(uomCodes, ['X', 'ASTM'])); // X is only for HTS statistical reporting
  const uomOptions = uomKeys.map((uom, index) => (
    <Option key={index} value={uom}>
      {uom} - {uomCodes[uom]}
    </Option>
  ));
  return (
    <FormItem
      $itemSize={size}
      name={localNamePath}
      label={hideLabel ? undefined : 'Unit of measure'}
      rules={[{ required, message: ' ' }]}
      $readOnly={readOnly}
      $inline
    >
      <Select
        disabled={disabled}
        showSearch
        allowClear
        onChange={onChange}
        filterOption={selectFilter}
        notFoundContent="None Found"
        optionLabelProp="value"
        dropdownMatchSelectWidth={false}
      >
        {uomOptions}
      </Select>
    </FormItem>
  );
};

export default UOMSelect;

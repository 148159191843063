import { gql } from '@apollo/client';
import { workOrderTaskFragments } from '@xbcb/work-order-queries';

export const getWorkOrderQuery = gql`
  query getWorkOrderQuery($id: ID!, $version: Int) {
    workOrder(id: $id, version: $version) {
      id
      version
      status
      milestones {
        id
        version
        name
        occurenceTime
        source
        created {
          client {
            name
          }
        }
      }
      tasks {
        ...workOrderTaskFields
      }
    }
  }

  ${workOrderTaskFragments}
`;

import { RecordType, TransactionRecordType } from '@xbcb/shared-types';
import { transactionQueries } from '@xbcb/shared-queries';

export const queryUsIorCbp = transactionQueries({
  record: { name: RecordType.US_IOR },
  transaction: { name: TransactionRecordType.QUERY_US_IOR_CBP_TRANSACTION },
}).mutation;

export const queryUsIorCbpWithChargeContinuousBond = transactionQueries({
  record: { name: RecordType.US_IOR },
  transaction: {
    name: TransactionRecordType.QUERY_US_IOR_CBP_TRANSACTION,
    customInputName: 'QueryUsIorCbpCreateInput',
    input: true,
  },
}).mutation;

export const queryCbpByIorNumber = transactionQueries({
  transaction: {
    name: TransactionRecordType.QUERY_CBP_BY_IOR_NUMBER_TRANSACTION,
    customInputName: 'QueryCbpByIorNumberCreateInput',
    input: true,
  },
}).mutation;

export const queryCbpByFirmsCode = transactionQueries({
  transaction: {
    name: TransactionRecordType.QUERY_FIRMS_CODE_CBP_TRANSACTION,
    customInputName: 'QueryFirmsCodeCbpCreateInput',
    input: true,
  },
}).mutation;

export const queryCbpByAdCvdCaseNumber = transactionQueries({
  transaction: {
    name: TransactionRecordType.QUERY_AD_CVD_CASE_CBP_TRANSACTION,
    customInputName: 'QueryAdCvdCaseCbpInput',
    input: true,
  },
}).mutation;
export const queryCbpByCarrierCode = transactionQueries({
  transaction: {
    name: TransactionRecordType.QUERY_CARRIER_CODE_CBP_TRANSACTION,
    customInputName: 'QueryCarrierCodeCbpCreateInput',
    input: true,
  },
}).mutation;

export const createUsIorCbp5106 = transactionQueries({
  record: { name: RecordType.US_IOR },
  transaction: {
    name: TransactionRecordType.CREATE_US_IOR_CBP_5106_TRANSACTION,
  },
}).mutation;

export const updateUsIorCbp5106 = transactionQueries({
  record: { name: RecordType.US_IOR },
  transaction: {
    name: TransactionRecordType.UPDATE_US_IOR_CBP_5106_TRANSACTION,
  },
}).mutation;

export const requestUsIorCbpAssignedNumber = transactionQueries({
  record: { name: RecordType.US_IOR },
  transaction: {
    name: TransactionRecordType.REQUEST_US_IOR_CBP_ASSIGNED_NUMBER_TRANSACTION,
    input: true,
  },
}).mutation;

export const queryUsConsigneeCbp = transactionQueries({
  record: { name: RecordType.US_CONSIGNEE },
  transaction: {
    name: TransactionRecordType.QUERY_US_CONSIGNEE_CBP_TRANSACTION,
  },
}).mutation;

export const createUsConsigneeCbp5106 = transactionQueries({
  record: { name: RecordType.US_CONSIGNEE },
  transaction: {
    name: TransactionRecordType.CREATE_US_CONSIGNEE_CBP_5106_TRANSACTION,
  },
}).mutation;

export const updateUsConsigneeCbp5106 = transactionQueries({
  record: { name: RecordType.US_CONSIGNEE },
  transaction: {
    name: TransactionRecordType.UPDATE_US_CONSIGNEE_CBP_5106_TRANSACTION,
  },
}).mutation;

export const createUsIsfCbp = transactionQueries({
  record: { name: RecordType.US_ISF },
  transaction: { name: TransactionRecordType.CREATE_US_ISF_CBP_TRANSACTION },
}).mutation;

export const updateUsIsfCbp = transactionQueries({
  record: { name: RecordType.US_ISF },
  transaction: { name: TransactionRecordType.UPDATE_US_ISF_CBP_TRANSACTION },
}).mutation;

export const deleteUsIsfCbp = transactionQueries({
  record: { name: RecordType.US_ISF },
  transaction: { name: TransactionRecordType.DELETE_US_ISF_CBP_TRANSACTION },
}).mutation;

export const upsertSupplierCbpMid = transactionQueries({
  record: { name: RecordType.SUPPLIER },
  transaction: {
    name: TransactionRecordType.UPSERT_SUPPLIER_CBP_MID_TRANSACTION,
  },
}).mutation;

export const upsertUsConsumptionEntrySummaryCbp = transactionQueries({
  record: { name: RecordType.US_CONSUMPTION_ENTRY },
  transaction: {
    name: TransactionRecordType.UPSERT_US_CONSUMPTION_ENTRY_SUMMARY_CBP_TRANSACTION,
    input: true,
  },
}).mutation;

export const createUsType86EntryReleaseCbp = transactionQueries({
  record: { name: RecordType.US_TYPE86_ENTRY },
  transaction: {
    name: TransactionRecordType.CREATE_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION,
  },
}).mutation;

export const queryUsConsumptionEntrySummaryCbp = transactionQueries({
  record: { name: RecordType.US_CONSUMPTION_ENTRY },
  transaction: {
    name: TransactionRecordType.QUERY_US_CONSUMPTION_ENTRY_SUMMARY_CBP_TRANSACTION,
  },
}).mutation;

export const queryUsType86EntryReleaseCbp = transactionQueries({
  record: { name: RecordType.US_TYPE86_ENTRY },
  transaction: {
    name: TransactionRecordType.QUERY_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION,
  },
}).mutation;

export const updateUsConsumptionEntryReleaseCbp = transactionQueries({
  record: { name: RecordType.US_CONSUMPTION_ENTRY },
  transaction: {
    name: TransactionRecordType.UPDATE_US_CONSUMPTION_ENTRY_RELEASE_CBP_TRANSACTION,
  },
}).mutation;

export const updateUsType86EntryReleaseCbp = transactionQueries({
  record: { name: RecordType.US_TYPE86_ENTRY },
  transaction: {
    name: TransactionRecordType.UPDATE_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION,
  },
}).mutation;

export const upsertUsPostSummaryCorrectionCbp = transactionQueries({
  record: { name: RecordType.US_POST_SUMMARY_CORRECTION },
  transaction: {
    name: TransactionRecordType.UPSERT_US_POST_SUMMARY_CORRECTION_CBP_TRANSACTION,
  },
}).mutation;

export const queryUsPostSummaryCorrectionCbp = transactionQueries({
  record: { name: RecordType.US_POST_SUMMARY_CORRECTION },
  transaction: {
    name: TransactionRecordType.QUERY_US_POST_SUMMARY_CORRECTION_CBP_TRANSACTION,
  },
}).mutation;

export const replaceUsConsumptionEntryReleaseCbp = transactionQueries({
  record: { name: RecordType.US_CONSUMPTION_ENTRY },
  transaction: {
    name: TransactionRecordType.REPLACE_US_CONSUMPTION_ENTRY_RELEASE_CBP_TRANSACTION,
  },
}).mutation;

export const replaceUsType86EntryReleaseCbp = transactionQueries({
  record: { name: RecordType.US_TYPE86_ENTRY },
  transaction: {
    name: TransactionRecordType.REPLACE_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION,
  },
}).mutation;

export const updateUsConsumptionEntryPgaCbp = transactionQueries({
  record: { name: RecordType.US_CONSUMPTION_ENTRY },
  transaction: {
    name: TransactionRecordType.UPDATE_US_CONSUMPTION_ENTRY_PGA_CBP_TRANSACTION,
  },
}).mutation;

export const updateUsType86EntryPgaCbp = transactionQueries({
  record: { name: RecordType.US_TYPE86_ENTRY },
  transaction: {
    name: TransactionRecordType.UPDATE_US_TYPE86_ENTRY_PGA_CBP_TRANSACTION,
  },
}).mutation;

export const deleteUsConsumptionEntrySummaryCbp = transactionQueries({
  record: { name: RecordType.US_CONSUMPTION_ENTRY },
  transaction: {
    name: TransactionRecordType.DELETE_US_CONSUMPTION_ENTRY_SUMMARY_CBP_TRANSACTION,
  },
}).mutation;

export const deleteUsConsumptionEntryReleaseCbp = transactionQueries({
  record: { name: RecordType.US_CONSUMPTION_ENTRY },
  transaction: {
    name: TransactionRecordType.DELETE_US_CONSUMPTION_ENTRY_RELEASE_CBP_TRANSACTION,
    input: true,
  },
}).mutation;

export const submitInBondCbp = transactionQueries({
  record: { name: RecordType.US_IN_BOND },
  transaction: {
    name: TransactionRecordType.SUBMIT_IN_BOND_CBP_TRANSACTION,
    input: true,
  },
}).mutation;

export const actionInBondCbp = transactionQueries({
  record: { name: RecordType.US_IN_BOND },
  transaction: {
    name: TransactionRecordType.ACTION_IN_BOND_CBP_TRANSACTION,
    input: true,
  },
}).mutation;

export const deleteInBondCbp = transactionQueries({
  record: { name: RecordType.US_IN_BOND },
  transaction: {
    name: TransactionRecordType.DELETE_IN_BOND_CBP_TRANSACTION,
    input: true,
  },
}).mutation;

export const deleteInBondBillCbp = transactionQueries({
  record: { name: RecordType.US_IN_BOND },
  transaction: {
    name: TransactionRecordType.DELETE_IN_BOND_BILL_CBP_TRANSACTION,
    input: true,
  },
}).mutation;

export const deleteUsType86EntryReleaseCbp = transactionQueries({
  record: { name: RecordType.US_TYPE86_ENTRY },
  transaction: {
    name: TransactionRecordType.DELETE_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION,
    input: true,
  },
}).mutation;

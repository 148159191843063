import { AnyObject } from '@xbcb/shared-types';
import { AppRecordBadge, AppRecordGetBadges } from 'routes';

/**
 * Gets transportationServiceName from Shipment Legs.
 * @param {AnyObject[]} shipmentLegs Legs of the shipment
 * @return {string} transportationServiceName
 */
export const getTransportationServiceName = (
  shipmentLegs?: AnyObject[],
): string | undefined => {
  if (!shipmentLegs) return undefined;
  for (const leg of shipmentLegs) {
    if (leg?.transportationServiceName) {
      return leg.transportationServiceName;
    }
  }
  return undefined;
};

const getAppRecordBadges = (record: AnyObject, shipmentLegs: AnyObject[]) => {
  const recordBadgeToolTipText = getTransportationServiceName(shipmentLegs);
  const recordBadges: AppRecordBadge[] = [];
  record?.badges?.forEach((recordBadgeName: string) => {
    recordBadges.push({
      text: recordBadgeName,
      tooltipText: recordBadgeToolTipText,
    });
  });
  return recordBadges;
};

export const getShipmentBadges: AppRecordGetBadges = ({ record }) => {
  return getAppRecordBadges(record, record?.legs);
};

export const getWorkOrderBadges: AppRecordGetBadges = ({ record }) => {
  return getAppRecordBadges(record, record?.group?.shipment?.legs);
};

import { setActiveProduct } from '../actions';
import { createReducer } from '@reduxjs/toolkit';

// First element is invoice ID and second is product ID
// By Default first product of the first invoice is active
const initialState: [number, number] = [0, 0];

export default createReducer(initialState, (builder) => {
  builder.addCase(setActiveProduct, (state, { payload }) => {
    const { invoiceId, productId } = payload;
    state = [invoiceId, productId];
  });
});

import { AnyObject, AccountType } from '@xbcb/shared-types';
import { constantCase } from '@xbcb/js-utils';

export const transformUser = {
  toForm: ({
    existingRecord: newRecord,
  }: {
    existingRecord: any;
  }): AnyObject => {
    const {
      permissions,
      workingHours,
      assignmentConfig,
      assignmentTeams,
      subjectMatterExpertTeams,
    } = newRecord;
    if (permissions) {
      const newPermissions: { [key: string]: string[] } = {};
      newRecord.assignmentTeams = {
        id: assignmentTeams?.nodes?.map((node: any) => node.name),
      };
      newRecord.subjectMatterExpertTeams = {
        id: subjectMatterExpertTeams?.nodes?.map((node: any) => node.name),
      };

      for (const [recordType, recordTypePermissions] of Object.entries(
        permissions,
      )) {
        if (Array.isArray(recordTypePermissions)) {
          newPermissions[recordType] = (
            recordTypePermissions as {
              name: string;
            }[]
          ).map((recordTypePermission) => recordTypePermission.name);
        }
      }
      newRecord.permissions = newPermissions;
    }

    if (workingHours) {
      for (const day of Object.keys(workingHours)) {
        const workingHoursOnDay = workingHours[day];
        if (workingHoursOnDay?.start) {
          workingHoursOnDay.works = true;
        }
      }
    }
    if (assignmentConfig) {
      // N.B. In the GraphQL schema, task configurations are stored as an array.
      // In the UI, we want to group the configurations by Work Order type, so we massage the shape of the data here.
      // Additionally, the order of the task configurations is determined by the order of the respective states in the Work Order's state machine.
      // In order to ensure that the order of tasks in the State Machine and the order of configurations on the user record never get out of sync, we transform the array of task configurations to a map w/ Work Order Task Definition IDs pointing to the task configuration.
      // So the resulting shape is { [workOrderType]: { [taskDefinitionId]: taskConfiguration } }

      // activeTaskDefinitions is not a field defined in the schema, it's
      // used only by the UI to determine which state machines to show as
      // "active" (i.e. toggled on the operator user page)
      newRecord.activeTaskDefinitions = {};

      assignmentConfig?.taskConfigs?.forEach((taskConfig: AnyObject) => {
        const { workOrderTaskDefinition = {}, timePercentage } = taskConfig;
        const { workOrderType } = workOrderTaskDefinition;
        if (!assignmentConfig[workOrderType]) {
          assignmentConfig[workOrderType] = {
            taskConfigs: {},
          };
        }

        assignmentConfig[workOrderType].taskConfigs[
          workOrderTaskDefinition.id
        ] = taskConfig;

        // this initializes the toggle switch for the group of task configs for a particular work order.
        if (timePercentage > 0) {
          newRecord.activeTaskDefinitions[workOrderTaskDefinition.id] = {
            active: true,
          };
        }

        // we only need the work order type to for grouping purposes. It is not a field in the input, so we delete it here.
        delete workOrderTaskDefinition.workOrderType;
      }, {});

      // taskConfigs have now been grouped by work order type, get rid of the initial array field.
      delete assignmentConfig?.taskConfigs;
    }
    return newRecord;
  },
  toSchema: ({
    input,
    existingRecord,
    isCreate,
  }: {
    input: any;
    existingRecord?: any;
    isCreate?: boolean;
  }) => {
    const values = JSON.parse(JSON.stringify(input));
    const {
      permissions,
      assignmentConfig,
      workingHours,
      notifications,
      teams,
      activeTaskDefinitions,
      accountId,
      currentUserAccountType,
    } = values;
    if (permissions) {
      const newPermissions: { [key: string]: { name: string }[] } = {};

      for (const [recordType, recordTypePermissions] of Object.entries(
        permissions,
      )) {
        const permissionOnRecord =
          constantCase(recordType) === currentUserAccountType
            ? 'company'
            : recordType;
        if (recordTypePermissions) {
          newPermissions[permissionOnRecord] = (
            recordTypePermissions as string[]
          ).map((recordTypePermission) => ({
            name: recordTypePermission,
          }));
        }
      }
      values.permissions = newPermissions;
    }

    if (assignmentConfig) {
      const mergedTaskedConfigs: any[] = [];
      // N.B. Here we have to reverse what was done in toForm above.
      // In the form, task configurations are stored first by work order type, then by task id.
      // The GraphQL schema required a flat array (w/ task id embedded in each member of the array).
      for (const workOrderType of Object.keys(assignmentConfig)) {
        const { taskConfigs } = assignmentConfig[workOrderType];
        for (const taskDefinitionId of Object.keys(taskConfigs)) {
          const taskConfig = taskConfigs[taskDefinitionId];
          if (
            // Only if the tab is not closed (active might be true or undefined for an open tab) and the time percentage is non zero, add it to the input.
            activeTaskDefinitions?.[taskDefinitionId]?.active !== false &&
            taskConfig.timePercentage > 0
          ) {
            mergedTaskedConfigs.push({
              ...taskConfig,
              workOrderTaskDefinition: { id: taskDefinitionId },
            });
          }
        }

        // no longer need task configurations grouped by work order type.
        delete assignmentConfig[workOrderType];
      }
      assignmentConfig.taskConfigs = mergedTaskedConfigs;
    }
    delete values.activeTaskDefinitions;

    if (workingHours) {
      for (const day of Object.keys(workingHours)) {
        delete workingHours[day].works;
      }
    }
    if (notifications === null) {
      values.notifications = [];
    }
    // Remove all fields other than `id` from teams as IdInput is expected
    teams?.forEach((team: AnyObject, index: number) => {
      teams[index] = { id: team.id };
    });
    if (accountId) {
      values[currentUserAccountType.toLowerCase()] = {
        id: accountId,
      };
    }
    delete values.loginLink;
    delete values.cognito;
    delete values.operator;
    // If it's not create, we should always attempt to delete the shipper/forwarder since they are not defined on the update inputs
    if (currentUserAccountType !== AccountType.SHIPPER || !isCreate) {
      delete values.shipper;
    }
    if (currentUserAccountType !== AccountType.FORWARDER || !isCreate) {
      delete values.forwarder;
    }
    delete values.currentUserAccountType;
    delete values.newNotifications;
    delete values.accountId;
    delete values.assignmentTeams;
    delete values.subjectMatterExpertTeams;
    if (existingRecord) delete values.email;
    return values;
  },
};

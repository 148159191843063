import styled from 'styled-components';
import { AppRadioGroup } from '@xbcb/form-item-components';
import { Button } from 'antd';
import { CssSpace } from '@xbcb/ui-types';

export const StyledAppRadioGroup = styled(AppRadioGroup)`
  margin-top: ${CssSpace.SPACE_4};
`;

export const StyledButton = styled(Button)`
  width: ${CssSpace.SPACE_8};
  margin: 0 auto;
`;

export const StyledDiv = styled.div`
  text-align: center;
`;

export const StyledParagraph = styled.p`
  margin-bottom: ${CssSpace.SPACE_4};
`;

import React from 'react';
import type { Shipment } from '@xbcb/api-gateway-client';
import { AppRecordSecondaryHeaderContentProps } from 'routes';
import { getBadgeIcons } from 'components/AppRecordBadge';
import CargowiseIcon from 'components/CargowiseIcon';
import { getShipmentBadges } from 'libs/getRecordBadges';
import { StyledDiv } from './styles';

const ShipmentSecondaryHeader: React.FC<
  AppRecordSecondaryHeaderContentProps
> = ({ record }) => {
  const badgeIcons = getBadgeIcons(record, getShipmentBadges);
  const { externalIntegrationReferences = [] } = record as Shipment;
  return (
    <StyledDiv>
      <CargowiseIcon
        externalIntegrationReferences={externalIntegrationReferences}
      />
      {badgeIcons}
    </StyledDiv>
  );
};

export default ShipmentSecondaryHeader;

import { handleActions } from 'redux-actions';

const defaultProfile = {
  simpleCache: {},
  cacheValid: true,
};

const profileReducer = handleActions(
  {
    PROFILE_VALIDATE_CACHE: (state, { payload }) => ({
      ...state,
      cacheValid: payload,
    }),
    PROFILE_CACHE: (state, { payload }) => ({
      ...state,
      cacheValid: true,
      simpleCache: payload,
    }),
    LOGOUT: () => defaultProfile,
  },
  defaultProfile,
);

export default profileReducer;

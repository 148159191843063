import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination, Spin } from 'antd';
import { get, last } from 'lodash';
import moment from 'moment';
import { camelCase } from 'change-case';
import { gql, useLazyQuery, NetworkStatus } from '@apollo/client';
import { RecordType } from '@xbcb/shared-types';
import TeamDatum from 'components/TeamDatum';
import DownloadEDIButton from 'components/DownloadEDIButton';
import {
  StyledH2,
  StyledModal,
  StyledSpan,
  StyledSwitch,
  StyledCollapse,
  StyledPanel,
  StyledButton,
  StyledModalHeader,
} from './styles';
import { useModal, safeGetMessage } from '@xbcb/ui-utils';
import { ModalKey } from 'types';
import { DocumentNode } from 'graphql';
import { SyncOutlined } from '@ant-design/icons';
import { DataCySuffix } from '@xbcb/ui-types';
import { useBundle } from '@amzn/react-arb-tools';

interface TeamDataModalProps {
  closeModal: any;
  recordType: RecordType;
  showSwitch?: boolean; // default true
  transactional?: boolean;
  // Provided when the abiExchangeMessages should be queried for a record
  // other than the id in the `pathname`
  customRecordId?: string;
  dataPageSize?: number;
}

const TeamDataModal: React.FC<TeamDataModalProps> = ({
  closeModal,
  recordType,
  showSwitch = true,
  transactional,
  customRecordId,
  dataPageSize = 25,
}) => {
  const [partyBundle] = useBundle('components.Party');
  const defaultFirstPageNumber = 1;

  const [dataType, setDataType] = useState('json');
  const { pathname } = useLocation();
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(
    defaultFirstPageNumber,
  );
  const [currentPageSize, setCurrentPageSize] = useState<number>(dataPageSize);
  const { visible } = useModal(ModalKey.TEAM_DATA);

  const handleCancel = () => {
    closeModal();
  };

  const getAbiQuery: DocumentNode = gql`
    query getAbiExchangeMessages(
      $input: ExchangeMessageSearchCriteriaInput!
      $searchPaginationInput: SearchPaginationInput!
    ) {
      getAbiExchangeMessages(
        input: $input
        searchPaginationInput: $searchPaginationInput
      ) {
        paginationInfo {
          pageSize
          totalPages
        }
        messages {
          edi
          json
          key
          time
        }
      }
    }
  `;

  const onPageChange = (page: number, pageSize?: number) => {
    setCurrentPageNumber(page);
    if (pageSize) {
      setCurrentPageSize(pageSize);
    }
    getAbi();
  };

  const [getAbi, { loading, data, networkStatus }] = useLazyQuery(getAbiQuery, {
    variables: {
      input: {
        tags: {
          values: [
            {
              key: `${camelCase(recordType)}Id`,
              value: customRecordId || `${last(pathname.split('/'))}`,
            },
          ],
        },
      },
      searchPaginationInput: {
        pageNumber: currentPageNumber,
        pageSize: currentPageSize,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const isRefetching = networkStatus === NetworkStatus.refetch;

  // we want to re-query every time the modal is opened
  useEffect(() => {
    if (visible) {
      getAbi();
    }
  }, [getAbi, visible]);

  const onShowSizeChange = (current: number, size: number) => {
    setCurrentPageNumber(defaultFirstPageNumber);
    setCurrentPageSize(size);
    getAbi();
  };

  // AbiExchangeMessage[]
  const messages: any[] = get(data, 'getAbiExchangeMessages.messages', []);
  const totalNumberOfPages: number = get(
    data,
    'getAbiExchangeMessages.paginationInfo.totalPages',
  );

  const paginationComponent = () => (
    <Pagination
      size="small"
      onChange={onPageChange}
      pageSize={currentPageSize}
      total={totalNumberOfPages * currentPageSize}
      current={currentPageNumber}
      onShowSizeChange={onShowSizeChange}
      showSizeChanger={true}
      pageSizeOptions={['10', '20', '50', '100']}
    />
  );

  const shouldShowSpinner = (loading || isRefetching) && !data;

  return (
    <StyledModal
      destroyOnClose
      footer={!loading && !isRefetching && paginationComponent()}
      maskClosable={true}
      onCancel={handleCancel}
      open={visible}
      width={1024}
    >
      <StyledModalHeader>
        <StyledButton
          size="small"
          shape="circle"
          data-cy={DataCySuffix.ABI_REFRESH}
          loading={loading}
          icon={<SyncOutlined />}
          onClick={() => getAbi()}
        />
        {showSwitch && (
          <StyledSwitch
            onChange={() => {
              const switchType = dataType === 'json' ? 'edi' : 'json';
              setDataType(switchType);
            }}
            loading={loading}
            checkedChildren="EDI"
            unCheckedChildren="JSON"
          />
        )}
      </StyledModalHeader>
      {shouldShowSpinner ? (
        <Spin />
      ) : !messages.length ? (
        <p>{safeGetMessage(partyBundle, 'no_data_found')}</p>
      ) : (
        <StyledCollapse bordered={false}>
          {messages.map(({ edi, json, key, time }, idx) => {
            const formattedTime = moment(time).isValid()
              ? moment(time).local().format('MMMM D YYYY, h:mm a')
              : time;
            const data = dataType === 'edi' ? edi : json;
            return (
              <StyledPanel
                key={time + idx}
                header={
                  <>
                    <StyledH2>{key}</StyledH2>
                    <StyledSpan>{formattedTime}</StyledSpan>
                    {dataType === 'edi' && !transactional ? (
                      <DownloadEDIButton
                        edi={edi}
                        lastModified={formattedTime}
                        loading={loading || isRefetching}
                      />
                    ) : null}
                  </>
                }
              >
                <TeamDatum dataType={dataType} data={data} />
              </StyledPanel>
            );
          })}
        </StyledCollapse>
      )}
    </StyledModal>
  );
};

export default TeamDataModal;

import React, { memo, useState } from 'react';
import { Col, Row, Switch } from 'antd';

interface Toggle {
  localStorageKey: string;
  displayValue: string;
  enabledText?: string;
  disabledText?: string;
}

const toggles: Toggle[] = [
  {
    localStorageKey: 'DISABLE_APOLLO_CACHE',
    displayValue: 'Disable Apollo cache persistence',
    enabledText: 'Disabled',
    disabledText: 'Enabled',
  },
  {
    localStorageKey: 'LARGE_ENTRY_UX',
    displayValue: 'Large entry UX for Project Nacrene',
  },
];

const getToggleState = () => {
  return toggles.reduce((state: Record<string, boolean>, toggle: Toggle) => {
    state[toggle.localStorageKey] =
      localStorage.getItem(toggle.localStorageKey) === 'true';
    return state;
  }, {});
};

export const FeatureToggles: React.FC = () => {
  const [toggleState, setToggleState] = useState(getToggleState);
  const handleToggle = (key: string, checked: boolean) => {
    if (checked) {
      localStorage.setItem(key, 'true');
    } else {
      localStorage.removeItem(key);
    }
    setToggleState(getToggleState());
  };
  return (
    <div>
      {toggles.map((toggle) => (
        <Row
          gutter={[4, 16]}
          align="middle"
          key={toggle.localStorageKey}
          style={{ marginBottom: '12px' }}
        >
          <Col span={8}>
            <Switch
              checkedChildren={toggle.enabledText || 'On'}
              unCheckedChildren={toggle.disabledText || 'Off'}
              checked={toggleState[toggle.localStorageKey]}
              onChange={(checked) =>
                handleToggle(toggle.localStorageKey, checked)
              }
            />
          </Col>
          <Col span={16}>{toggle.displayValue}</Col>
        </Row>
      ))}
    </div>
  );
};

export default memo(FeatureToggles);

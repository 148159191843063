import { createReducer } from '@reduxjs/toolkit';
import { menuCollapse } from '../actions';

const defaultState = {
  menuCollapse: false,
};

const menuReducer = createReducer(defaultState, (builder) =>
  builder.addCase(menuCollapse, (state, { payload }) => {
    state.menuCollapse = payload;
    return state;
  }),
);

export default menuReducer;

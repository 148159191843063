import { get } from 'lodash';

export const getAMSQueryMessage = (record) => {
  if (!record) return record;
  let msg;
  const { response: r } = record;
  if (!r) msg = 'No response yet';
  else
    msg =
      get(r, 'message') ||
      get(r, [0, 'oceanTransaction', 'message']) ||
      get(
        r,
        [0, 'flightTransaction', 0, 'message'] ||
          get(r, [0, 'flight', 'conveyanceName']),
      ) ||
      get(r, [0, 'conveyanceName']);
  return msg;
};

export const getUOM = (tariff, i) =>
  tariff &&
  `${+get(tariff, `quantity${i}`, 0).toFixed(2)}${get(
    tariff,
    `uomCode${i}`,
    'X',
  )}`;

export const getFee = (feeCodes, htsObj, i) => {
  if (!htsObj) return;
  const feeObj = get(htsObj, ['fees', i], '');
  const fee =
    feeObj &&
    `${get(feeCodes, ['62', feeObj.classCode], '')} Fee (${feeObj.classCode})`;
  return fee;
};

export const validateFDAProductCode = (productCode, industryCodes) => {
  if (!productCode) return undefined;
  if (!industryCodes) return undefined;
  const raw = productCode;

  const industry = raw.substr(0, 2);
  const _class = raw.substr(2, 1);
  const subClass = raw.substr(3, 1);
  const pic = raw.substr(4, 1);
  const product = raw.substr(5, 2);

  const industryObj = get(industryCodes, [industry]);
  const classObj = get(industryObj, ['class', _class]);
  const validSubClass =
    subClass === '-' || get(industryObj, ['subClass', subClass]);
  const validPIC = pic === '-' || get(industryObj, ['PIC', pic]);
  const productObj = get(classObj, ['product', product]);
  if (industryObj && classObj && productObj && validSubClass && validPIC) {
    return true;
  } else {
    return false;
  }
};

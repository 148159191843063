import type { BulkMilestoneUploaderResult } from '@xbcb/api-gateway-client';
import bulkUploadPageMaker from 'components/BulkUploadRequest';

const headers = [
  'identifierType',
  'identifier',
  'milestoneName',
  'occurenceTime',
  'status',
  'failureReason',
];

const resultMapFunc = (result: BulkMilestoneUploaderResult) => {
  const { identifier, milestoneName, identifierType, occurenceTime } =
    result?.fields || {};
  return {
    identifier,
    identifierType,
    milestoneName,
    occurenceTime,
    status: result?.status,
    failureReason: result?.failureReason,
  };
};

const BulkMilestoneUploadRequest = bulkUploadPageMaker({
  headers,
  resultMapFunc,
});

export default BulkMilestoneUploadRequest;

import styled, { css } from 'styled-components';
import { List } from 'antd';

const sharedPadding = css`
  padding-left: ${({ theme }) => theme.spacing.space_4};
`;

export const StyledDocumentTypeSpan = styled.span`
  ${sharedPadding}
  font-weight: 400;
`;

export const StyledSentDateSpan = styled.span`
  ${sharedPadding}
`;

export const StyledList = styled(List)`
  .anticon {
    align-self: center;
  }
`;

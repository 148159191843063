import { Modal } from 'antd';
import { uncancelOneMutation } from '@xbcb/shared-queries';
import { AppRecordSubmitButton } from 'routes';
import { formatRecordName } from '@xbcb/js-utils';
import {
  AccountType,
  RecordType,
  RecordStatus,
  ObjectType,
} from '@xbcb/shared-types';
import { AppRecordOnConfirmPayload } from '../../types';
import { executeMutation } from 'libs/executeMutation';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { pascalCase } from 'change-case';

export const uncancelWorkOrderButton = (
  recordType: RecordType,
): AppRecordSubmitButton => {
  const displayName = formatRecordName({
    recordType,
    accountType: AccountType.OPERATOR,
  });
  const name = `Reopen ${displayName}`;
  const workOrderButton: AppRecordSubmitButton = {
    key: `uncancel${pascalCase(recordType)}`,
    text: name,
    show: ({ existingRecord, currentUser }) => {
      return (
        existingRecord?.status === RecordStatus.CANCELED &&
        !currentUser.loading &&
        checkAccess(currentUser, recordType, UserPermissions.UPDATE)
      );
    },
    skipValidation: () => true,
    skipUpdateRecord: () => true,
    onSubmit: async ({ updatedRecord }) => {
      const uncancelWorkOrderMutation = uncancelOneMutation({
        recordName: ObjectType.WORK_ORDER,
      });
      await executeMutation({
        mutation: uncancelWorkOrderMutation,
        variables: {
          id: updatedRecord.id,
          version: updatedRecord.version,
        },
        successMessage: `${displayName} uncanceled`,
      });
    },
    onConfirm: ({ dispatch, isValid, existingRecord }) =>
      new Promise<AppRecordOnConfirmPayload>((resolve, reject) => {
        if (isValid) {
          Modal.confirm({
            title: name,
            content: `Are you sure you want to uncancel this ${displayName}? All associated
          tasks will be uncancelled.`,
            className: 'uncancel-work-order-modal',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
              resolve({ canceled: false });
            },
          });
        } else {
          return resolve({ canceled: true });
        }
      }),
  };
  return workOrderButton;
};

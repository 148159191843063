import { UpCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { paramCase } from 'change-case';
import { getRecordType } from '@xbcb/core';
import { pluralize } from '@xbcb/js-utils';
import { AppRecordSecondaryHeaderContentProps } from 'routes';
import { StyledSpan } from './style';

const NavigateToWorkOrder: React.FC<AppRecordSecondaryHeaderContentProps> = ({
  record,
}) => {
  const { id } = record;

  const workOrderRecordType = getRecordType(id);

  const isTaskPage = window.location.href.includes('task');

  return workOrderRecordType && isTaskPage ? (
    <Tooltip title="go to work order" placement="top">
      <StyledSpan>
        <Link to={`/${pluralize(paramCase(workOrderRecordType))}/${id}`}>
          <UpCircleOutlined style={{ height: '24', width: '24' }} />
        </Link>
      </StyledSpan>
    </Tooltip>
  ) : null;
};

export default NavigateToWorkOrder;

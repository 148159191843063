import {
  getOneQuery,
  recordFields,
  recordActionFields,
} from '@xbcb/shared-queries';
import {
  RecordType,
  KeywordSearchOperator,
  DateSearchOperator,
  AnyObject,
} from '@xbcb/shared-types';
import { gql } from '@apollo/client';
import { client as apolloClient } from '@xbcb/apollo-client';
import { WorkOrderTaskStatus } from '@xbcb/work-order-types';

type CompletedTaskProps = {
  operatorUserId: string;
  showAll?: boolean;
};

export const workOrderTaskFields = `
  ...recordFields 
  name 
  workOrderType 
  status
  subStatus
  assignee { 
    id 
    name 
  } 
  deadline 
  steps { 
    id 
    name 
    description 
    isCompletedBySystem 
    isFinalConfirmation 
    completed { 
      ...recordActionFields 
    } 
  } 
  definition { 
    id
    estimatedDuration
    workOrderTaskType 
  }
  executionResult {
    status
    errors
  }
`;

export const workOrderTaskFragment = `${recordFields}${recordActionFields}`;

export const workOrderTaskQuery = getOneQuery({
  recordName: RecordType.WORK_ORDER_TASK,
  fields: workOrderTaskFields,
  fragments: workOrderTaskFragment,
});

export const SEARCH_TASKS = gql`
  query SearchWorkOrderTasks($input: SearchWorkOrderTaskInput!) {
    searchWorkOrderTasks(input: $input) {
      totalResults
    }
  }
`;

// Convert UTC time to local time
const localNow = new Date(
  new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000,
).toISOString();
const currentDay = localNow.split('T')[0];

const getWorkOrderTaskCount = async (
  searchCriteria: AnyObject,
): Promise<number> => {
  const searchResponse = await apolloClient.query({
    query: SEARCH_TASKS,
    variables: {
      input: {
        searchCriteria,
      },
    },
  });
  return searchResponse.data?.searchWorkOrderTasks.totalResults || 0;
};
export const getCompletedTask = async ({
  operatorUserId,
  showAll,
}: CompletedTaskProps): Promise<number> => {
  const searchCriteria = {
    // Active tasks
    deletedTime: {
      operator: DateSearchOperator.DOES_NOT_EXIST,
    },
    status: {
      values: [WorkOrderTaskStatus.COMPLETED],
      operator: KeywordSearchOperator.EQUALS,
    },
    completedTime: {
      operator: DateSearchOperator.BETWEEN,
      min: showAll ? undefined : currentDay, // if showAll is false, it fetches task completed for the current day.
      max: currentDay,
    },
    assigneeId: {
      values: operatorUserId,
      operator: KeywordSearchOperator.EQUALS,
    },
  };
  const totalResults = await getWorkOrderTaskCount(searchCriteria);
  return totalResults;
};

export const getEscalatedToMeTask = async (
  operatorUserId: string,
): Promise<number> => {
  const searchCriteria = {
    currentlyEscalatedToId: {
      values: operatorUserId,
      operator: KeywordSearchOperator.EQUALS,
    },
    status: {
      values: WorkOrderTaskStatus.ESCALATED,
      operator: KeywordSearchOperator.EQUALS,
    },
  };
  const totalResults = await getWorkOrderTaskCount(searchCriteria);
  return totalResults;
};

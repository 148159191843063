import styled from 'styled-components';
import { Button } from 'antd';
import { FormItem } from '@xbcb/form-item-components';

export const StyledProductCodeBuilderHeader = styled.h2`
  margin: var(--space-4) 0;
  text-align: center;
`;

export const StyledProductCodeHeader = styled.h1`
  color: ${({ theme }) => theme.palette.purples.gamma};
  margin-bottom: ${({ theme }) => theme.spacing.space_4};
  letter-spacing: 4px;
  font-size: 36px;
  text-align: center;
`;
export const StyledButton = styled(Button)`
  margin: 0 auto;
  display: block;
  width: 50%;
  margin-bottom: ${({ theme }) => theme.spacing.space_4};
`;

export const StyledLinkDiv = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0;
`;

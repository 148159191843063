import { useCurrentUser } from 'libs/hooks';
import { checkAccess, permissionsByObjectType } from '@xbcb/client-utils';
import { ObjectType } from '@xbcb/shared-types';

/**
 * @param { ObjectType } objectType Record type of interest for extracting permissions
 * @return { {} } Object containing object of possible permission and boolean key-value pairs, where boolean is true if user has corresponding permission
 */
export const usePermissions = (objectType: ObjectType) => {
  const { loading, ...user } = useCurrentUser();
  const possiblePermissions = permissionsByObjectType[objectType];
  const permissions: {
    [key: string]: boolean;
  } = {};
  if (!loading && possiblePermissions) {
    if (checkAccess(user, objectType, 'ADMIN')) {
      Object.keys(possiblePermissions).forEach((permission) => {
        permissions[permission] = true;
      });
    } else {
      Object.keys(possiblePermissions).forEach((permission) => {
        permissions[permission] = checkAccess(user, objectType, permission);
      });
    }
  }
  return { permissions, loading };
};

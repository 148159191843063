import styled from 'styled-components';

import { InfoCircleOutlined } from '@ant-design/icons';

export const StyledIntendedUseInfoDiv = styled.div`
  display: inline-block;
  margin-top: 10px;
`;

export const StyledInlineTooltip = styled(InfoCircleOutlined)`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing.space_3};
`;

export const StyledIntendedUseDiv = styled.div`
  display: flex;
`;

import { ConnectionStatus, OperatingRegion } from '@xbcb/shared-types';

export const connectionStatusModalTextMap = {
  [OperatingRegion.US]: {
    [ConnectionStatus.CONNECTED]:
      'CBMS has been connected to ACE for the past 72 hours.',
    [ConnectionStatus.DISCONNECTED]:
      'CBMS has been disconnected from ACE for the past 72 hours.',
    [ConnectionStatus.UNKNOWN]:
      'CBMS connection status to ACE is currently unknown.',
  },
};

export const connectionStatusEventDescriptionMap = {
  [OperatingRegion.US]: {
    [ConnectionStatus.CONNECTED]: 'Connected to ACE',
    [ConnectionStatus.DISCONNECTED]: 'No connection detected',
    [ConnectionStatus.UNKNOWN]: 'Connection status unknown',
  },
};

import { useQuery } from '@apollo/client';
import { Button, Col, Form, Row, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import type { UsPrincipalAdCvdCase } from '@xbcb/api-gateway-client';
import { AdCvdDisclaimerCodeMap } from '@xbcb/compliance-types';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { NamePath } from '@xbcb/ui-types';
import Disclaim from 'components/Disclaim';
import LineItemCaseInfo from 'components/LineItemCaseInfo';
import LineItemCaseLink from 'components/LineItemCaseLink';
import LineItemCaseHeader from 'components/LineItemCaseHeader';
import LineItemCaseSelect from 'components/LineItemCaseSelect';
import LineItemNrbsForm from 'components/LineItemNrbsForm';
import RecordSelect from 'components/RecordSelect';
import { executeMutation } from 'libs/executeMutation';
import { queryCbpByAdCvdCaseNumber } from 'libs/sharedQueries';
import { getOneQuery, getRecordFromResponse } from '@xbcb/shared-queries';
import { additionalCaseFields as fields } from '@xbcb/trade-data-queries';

import { StyledFlexDiv, StyledFormItem } from './styles';

const queryCaseNumber = async (caseNumber: string) => {
  if (!caseNumber) return;
  try {
    await executeMutation({
      mutation: queryCbpByAdCvdCaseNumber,
      variables: { input: { caseNumbers: caseNumber } },
      successMessage: 'ABI query transmitted',
    });
  } catch (e) {
    message.error('Sorry, an error has occurred. Please try again later.', 5.0);
  }
};

export interface LineItemCaseProps {
  recordType?: RecordType;
  principalAdCvdCases?: UsPrincipalAdCvdCase[];
  type: 'ad' | 'cv';
  fullNamePath: NamePath;
  localNamePath: NamePath;
  htsNumber: string;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
}

const LineItemCase: React.FC<LineItemCaseProps> = ({
  principalAdCvdCases = [],
  type,
  recordType: lineItemRecordType,
  fullNamePath,
  localNamePath,
  htsNumber,
  readOnly,
  required,
  disabled,
}) => {
  const theme = useTheme();
  const form = Form.useFormInstance();
  const { getFieldValue, setFieldValue } = form;
  const caseId = Form.useWatch([...fullNamePath, 'id'], form);
  const parentFullNamePath = fullNamePath.slice(0, -1);
  const parentLocalNamePath = localNamePath.slice(0, -1);
  const disclaimFieldName = `${type}DisclaimerCode`;
  const [isDisclaimed, setIsDisclaimed] = useState(() =>
    Boolean(getFieldValue([...parentFullNamePath, disclaimFieldName])),
  );

  const recordType = RecordType.AD_CVD_CASE;
  const getQuery = getOneQuery({
    recordName: recordType,
    fields,
  });
  const queryResponse = useQuery(getQuery, {
    variables: { id: caseId },
    skip: !caseId,
  });
  const currentCase = getRecordFromResponse(queryResponse, 'get', recordType);

  useEffect(() => {
    if (isDisclaimed) {
      setFieldValue([...fullNamePath], undefined);
    }
  }, [isDisclaimed, fullNamePath, setFieldValue]);

  const additionalSearchCriteria: AnyObject = {};
  const isAdCase = type === 'ad';
  if (isAdCase) {
    additionalSearchCriteria.caseNumber = {
      values: ['A'],
      operator: 'STARTS_WITH',
    };
  } else if (type === 'cv') {
    additionalSearchCriteria.caseNumber = {
      values: ['C'],
      operator: 'STARTS_WITH',
    };
  }

  const label = `${type.toUpperCase()} Case Number`;

  return (
    <div>
      <StyledFormItem shouldUpdate>
        <>
          <Row>
            <LineItemCaseHeader
              caseType={type}
              principalAdCvdCases={principalAdCvdCases}
            />
          </Row>

          <Row justify="space-between">
            <Col>
              <StyledFlexDiv>
                {principalAdCvdCases.length ? (
                  !isDisclaimed ? (
                    <>
                      <LineItemCaseSelect
                        principalAdCvdCases={principalAdCvdCases}
                        currentCase={currentCase}
                        caseType={type}
                        fullNamePath={fullNamePath}
                        form={form}
                        queryCaseNumber={queryCaseNumber}
                      />

                      <Button
                        href="https://share.amazon.com/sites/currentadcvdactivecasesworkbook/_layouts/15/WopiFrame2.aspx?sourcedoc=%7bFD4B9CBB-04DC-4C34-A58F-42621A46A7DE%7d&file=Active%20ADCVD%20Cases%20Workbook.xlsx&action=default"
                        target="blank"
                      >
                        ADCVD Cases Workbook
                      </Button>
                    </>
                  ) : null
                ) : (
                  <RecordSelect
                    label={label}
                    includeHyperlinkInLabel={false}
                    recordType={RecordType.AD_CVD_CASE}
                    fullNamePath={fullNamePath}
                    localNamePath={localNamePath}
                    form={form}
                    disabled={disabled}
                    versioned={false}
                    readOnly={readOnly}
                    additionalSearchCriteria={additionalSearchCriteria}
                    $itemSize={theme.size.short}
                    notFoundText="Case Number not found, click here to query from CBP"
                    notFoundAction={queryCaseNumber}
                  />
                )}
                <Disclaim
                  fieldName={disclaimFieldName}
                  form={form}
                  isDisclaimed={isDisclaimed}
                  setIsDisclaimed={setIsDisclaimed}
                  localNamePath={parentLocalNamePath}
                  fullNamePath={parentFullNamePath}
                  disclaimerCodes={AdCvdDisclaimerCodeMap}
                  readOnly={readOnly}
                  disabled={disabled}
                />
              </StyledFlexDiv>
              {caseId && !isDisclaimed ? (
                <LineItemCaseLink
                  recordId={caseId}
                  form={form}
                  htsNumber={htsNumber}
                  fullNamePath={fullNamePath}
                />
              ) : null}
              {caseId && !isDisclaimed ? (
                <LineItemCaseInfo
                  currentCase={currentCase}
                  form={form}
                  htsNumber={htsNumber}
                  fullNamePath={fullNamePath}
                  queryCbpForCaseInfo={queryCaseNumber}
                />
              ) : null}
            </Col>
            <Col span={12}>
              <LineItemNrbsForm
                parentFullNamePath={parentFullNamePath}
                parentLocalNamePath={parentLocalNamePath}
                caseId={caseId}
                recordType={lineItemRecordType}
                isAdCase={isAdCase}
                isDisclaimed={isDisclaimed}
                readOnly={readOnly}
                disabled={disabled}
              />
            </Col>
          </Row>
        </>
      </StyledFormItem>
    </div>
  );
};

export default LineItemCase;

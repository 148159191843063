import { decrypt, encrypt } from '@xbcb/js-utils';
import { AnyObject } from '@xbcb/shared-types';
import { getEnv } from '@xbcb/ui-utils';

const ENCRYPTION_KEY_NAME = 'REFERENCED_RECORDS_ENCRYPTION_KEY';

export const encryptRecord = (record: AnyObject): string => {
  return encrypt(JSON.stringify(record), getEnv()[ENCRYPTION_KEY_NAME]);
};

export const decryptRecord = (
  encryptedRecordString?: string,
): AnyObject | undefined => {
  if (!encryptedRecordString) return undefined;
  return JSON.parse(
    decrypt(encryptedRecordString, getEnv()[ENCRYPTION_KEY_NAME]),
  );
};

import styled from 'styled-components';
import { UserStatus } from '@xbcb/client-types';
import { CssSpace } from '@xbcb/ui-types';

export const StyledStatusDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
`;

export const StyledStatusIcon = styled.span<{ currentStatus: UserStatus }>`
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: ${({ currentStatus, theme }) =>
    theme.status[currentStatus]};
  margin-right: ${CssSpace.SPACE_3};
`;

import styled from 'styled-components';
import { Button } from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import { FormItem } from '@xbcb/form-item-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledFormItem = styled(FormItem)<{ $hasSyncButton?: boolean }>`
  max-width: calc(
    100% - ${CssSpace.SPACE_4}
      ${({ $hasSyncButton }) => $hasSyncButton && '- 24px'}
  );
`;

const baseMargin = CssSpace.SPACE_2;
const labelHeight = CssSpace.SPACE_4;
const hasLabelMargin = `calc(${baseMargin} + ${labelHeight})`;

export const StyledButton = styled(Button)<{
  $isHidden?: boolean;
  $hasLabel?: boolean;
  $removeRefreshSpaceRight?: boolean;
}>`
  margin-top: ${({ $hasLabel }) => ($hasLabel ? hasLabelMargin : baseMargin)};
  ${({ $isHidden }) => $isHidden && 'visibility: hidden;'}
  ${({ $removeRefreshSpaceRight }) =>
    !$removeRefreshSpaceRight && `margin-right: ${CssSpace.SPACE_4};`}
`;

export const StyledSpan = styled.span`
  margin-right: ${CssSpace.SPACE_3};
`;

export const StyledSelectOutlined = styled(SelectOutlined)`
  height: ${labelHeight};
  // Icon width and height should be the same
  width: ${labelHeight};
`;

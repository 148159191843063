const truncate = (num) => parseFloat(num.toFixed(2)).toString();

const format = (num) => {
  if (num < 1) return `${truncate(num * 100)}¢`;
  return `$${truncate(num)}`;
};

const rateFormatter = ({ duty, rate, uom, type }) => {
  if (
    !rate ||
    (type === 'specific' && !rate.specific) ||
    (type === 'other' && !rate.other)
  ) {
    return 'Free';
  } else {
    const r = type === 'specific' ? rate.specific : rate.other;
    const res = format(r);
    if (
      !uom ||
      (duty === '1' && !uom[0]) ||
      (duty === '2' && !uom[1]) ||
      (duty === '3' && !uom[2])
    ) {
      return 'Unknown';
    }
    if (duty === '1') {
      return `${res}/${uom[0].toLowerCase()}`;
    } else if (duty === '2') {
      return `${res}/${uom[1].toLowerCase()}`;
    } else if (duty === '3') {
      return `${res}/${uom[2].toLowerCase()}`;
    }
  }
};

const adValoremRate = ({ rate }) => {
  if (!rate || !rate.adValorem) {
    return 'Free';
  }
  return `${truncate(rate.adValorem * 100)}%`;
};

const displayString = ({ specific, other, adValorem }) => {
  if (!specific && !other && !adValorem) return 'Unknown';
  const freeSpecific = specific === 'Free' || !specific;
  const freeOther = other === 'Free' || !other;
  const freeAdValorem = adValorem === 'Free' || !adValorem;
  if (freeSpecific || freeOther || freeAdValorem) {
    if (freeSpecific && freeOther && freeAdValorem) {
      return 'Free';
    } else if (freeSpecific) {
      if (freeOther) {
        return adValorem;
      } else if (freeAdValorem) {
        return other;
      } else {
        return `${adValorem} + ${other}`;
      }
    } else if (freeOther) {
      if (freeSpecific) {
        return adValorem;
      } else if (freeAdValorem) {
        return specific;
      } else {
        return `${specific} + ${adValorem}`;
      }
    } else {
      if (freeSpecific) {
        return other;
      } else if (freeOther) {
        return specific;
      } else {
        return `${specific} + ${other}`;
      }
    }
  } else {
    return `${specific} + ${adValorem} + ${other}`;
  }
};

// https://t.corp.amazon.com/V499760602/communication
const noDutyHts = [
  '9017900105',
  '9017900115',
  '9017900120',
  '9017900132',
  '9017900136',
  '9017900140',
  '9017900150',
  '9017900160',
];

export default ({ duty, rate, uom = [], htsNumber }) => {
  const hts = htsNumber?.replace(/\./g, '');
  if (noDutyHts.includes(hts)) {
    return 'Calculated';
  }
  const P1 = rate && rate.specific && format(rate.specific);
  const Q1 = uom[0];
  const Q2 = uom[1];
  const Q3 = uom[2];
  if (duty === '0' || !duty) {
    return 'Free';
  } else if (duty === '1' || duty === '2') {
    return rateFormatter({ duty, rate, uom, type: 'specific' });
  } else if (duty === '3') {
    const specific = rateFormatter({ duty: '1', rate, uom, type: 'specific' });
    const other = rateFormatter({ duty: '2', rate, uom, type: 'other' });
    return displayString({ specific, other });
  } else if (duty === '4') {
    const specific = rateFormatter({ duty: '1', rate, uom, type: 'specific' });
    const adValorem = adValoremRate({ rate });
    return displayString({ specific, adValorem });
  } else if (duty === '5') {
    const specific = rateFormatter({ duty: '2', rate, uom, type: 'specific' });
    const adValorem = adValoremRate({ rate });
    return displayString({ specific, adValorem });
  } else if (duty === '6' || duty === 'E') {
    const specific = rateFormatter({
      duty: duty === '6' ? '1' : '2',
      rate,
      uom,
      type: 'specific',
    });
    const other = rateFormatter({
      duty: duty === '6' ? '2' : '3',
      rate,
      uom,
      type: 'other',
    });
    const adValorem = adValoremRate({ rate });
    return displayString({ specific, other, adValorem });
  } else if (duty === '7') {
    return adValoremRate({ rate });
  } else if (duty === 'A') {
    const adValorem = adValoremRate({ rate });
    const other = rateFormatter({ duty: '3', rate, uom, type: 'other' });
    return displayString({ adValorem, other });
  } else if (duty === 'B') {
    const specific = rateFormatter({ duty: '2', rate, uom, type: 'specific' });
    const adValorem = adValoremRate({ rate });
    const other = rateFormatter({ duty: '3', rate, uom, type: 'other' });
    return displayString({ specific, other, adValorem });
  } else if (duty === 'C') {
    return 'Calculated'; // TODO
  } else if (duty === 'D') {
    const specific = rateFormatter({ duty: '3', rate, uom, type: 'specific' });
    const adValorem = adValoremRate({ rate });
    return displayString({ specific, adValorem });
  } else if (duty === 'F') {
    const other = rateFormatter({ duty: '2', rate, uom, type: 'other' });
    if (other === 'Free') {
      return `${P1}/${Q1}`;
    }
    return `(${P1} + ${other})/${Q1}`;
  } else if (duty === 'J' || duty === 'K') {
    const adValorem = adValoremRate({ rate });
    const other = rateFormatter({
      duty: duty === 'J' ? '2' : '1',
      rate,
      uom,
      type: 'other',
    });
    const first = `(${P1} - ${adValorem} * (100 - ${duty === 'J' ? Q3 : Q2}))/${
      duty === 'J' ? Q2 : Q1
    }`;
    if (other === 'Free') {
      return `${first}`;
    }
    return `Greater of ${first} or ${other}`;
  } else if (duty === '9' || duty === 'X' || duty === 'T') {
    return 'Calculated'; // TODO
  } else {
    return 'Unknown';
  }
};

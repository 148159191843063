import { DeleteUsCbpEntryReleaseReason } from '@xbcb/work-order-types';

export const cbpReasonCodeToCbmsEnum = {
  '01': DeleteUsCbpEntryReleaseReason.CLERICAL_ERROR,
  '02': DeleteUsCbpEntryReleaseReason.REPLACED_BY_IN_BOND,
  '03': DeleteUsCbpEntryReleaseReason.REPLACED_BY_FORMAL_ENTRY,
  '04': DeleteUsCbpEntryReleaseReason.REPLACED_BY_FTZ_ADMISSION,
  '05': DeleteUsCbpEntryReleaseReason.REPLACED_BY_INFORMAL_ENTRY,
  '06': DeleteUsCbpEntryReleaseReason.MERCHANDISE_SEIZED,
  '07': DeleteUsCbpEntryReleaseReason.MERCHANDISE_DESTROYED,
  '08': DeleteUsCbpEntryReleaseReason.NON_ARRIVAL,
  '09': DeleteUsCbpEntryReleaseReason.REFUSED_BY_IMPORTER,
  '10': DeleteUsCbpEntryReleaseReason.NOT_AUTHORIZED_FOR_IMPORT,
  '11': DeleteUsCbpEntryReleaseReason.SYSTEM_ERROR,
  '12': DeleteUsCbpEntryReleaseReason.NO_FOREIGN_STATUS_GOOD_REMOVED_FROM_FTZ,
};

export const referenceNames = {
  [DeleteUsCbpEntryReleaseReason.REPLACED_BY_IN_BOND]:
    'Replacement in-bond number',
  [DeleteUsCbpEntryReleaseReason.REPLACED_BY_FORMAL_ENTRY]:
    'Replacement entry number',
  [DeleteUsCbpEntryReleaseReason.REPLACED_BY_FTZ_ADMISSION]:
    'Replacement FTZ admission number',
};

export const constructDeleteUsConsumptionEntryReleaseCbpInput = (
  reason: string,
  reference?: string,
) => {
  const input: {
    reason: string;
    replacementInBondNumber?: string;
    replacementEntryNumber?: string;
    replacementFtzAdmissionNumber?: string;
  } = { reason };
  if (reason === DeleteUsCbpEntryReleaseReason.REPLACED_BY_IN_BOND) {
    input.replacementInBondNumber = reference;
  } else if (
    reason === DeleteUsCbpEntryReleaseReason.REPLACED_BY_FORMAL_ENTRY
  ) {
    input.replacementEntryNumber = reference;
  } else if (
    reason === DeleteUsCbpEntryReleaseReason.REPLACED_BY_FTZ_ADMISSION
  ) {
    input.replacementFtzAdmissionNumber = reference;
  }
  return input;
};

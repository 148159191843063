import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import {
  AppRecordStatusContentProps,
  AppRecordStatusShowProps,
} from 'components/AppRecordStatus';
import { isEuCustomsEntry } from '@xbcb/work-order-utils/dist/isEuCustomsEntry';
import { DataCyPrefix } from '@xbcb/ui-types';
import { getTransportationServiceName } from 'libs/getRecordBadges';

// the hover color comes from breadcrumb hover, I can't find it in themeVars though
const StyledLink = styled(Link)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.blues.poseidon};
  transition: color 0.3s;

  &:hover {
    color: var(--primary-color);
  }
`;

const StyledDiv = styled.div`
  font-size: 14px;
  transition: color 0.3s;

  &:hover {
    color: var(--primary-color);
  }
`;

// link back to shipment
export const appRecordWorkOrderStatus = {
  show: ({ record }: AppRecordStatusShowProps) =>
    Boolean(record?.group?.shipment?.id),
  content: ({ record }: AppRecordStatusContentProps) => {
    const shipmentId = record?.group?.shipment?.id;
    // Client booking id, Xbtms booking id if shipment is created by xbtms
    const bookingId = record?.group?.shipment?.clientReferences?.bookingId;
    const clientIdentifier = record?.group?.shipment?.clientIdentifier;
    const transportationServiceName = getTransportationServiceName(
      record?.group?.shipment?.legs,
    );
    const friendlyId = record?.group?.shipment?.friendlyId;
    const shipmentDisplayContent =
      bookingId || clientIdentifier || friendlyId || 'Shipment';
    // TODO create permalinks to specific tabs of the shipment page, and link to the work orders tab directly
    // We prefer booking id, if no booking id we would display shipment friendlyId
    const shipmentLink = isEuCustomsEntry(record?.id) ? (
      <StyledDiv>{shipmentDisplayContent}</StyledDiv>
    ) : (
      <StyledLink
        to={`/shipments/${shipmentId}`}
        data-cy={DataCyPrefix.SHIPMENT_LINK}
      >
        {shipmentDisplayContent}
      </StyledLink>
    );
    let tooltipText = null;
    const FriendlyIdDiv = <div>ID: {friendlyId}</div>;
    if (bookingId) {
      tooltipText = (
        <>
          {transportationServiceName && (
            <div>Carrier: {transportationServiceName}</div>
          )}
          {clientIdentifier && <div>Client #: {clientIdentifier}</div>}
          {FriendlyIdDiv}
        </>
      );
    } else if (clientIdentifier) {
      tooltipText = FriendlyIdDiv;
    }
    return {
      extraContent: tooltipText ? (
        <Tooltip title={tooltipText}>{shipmentLink}</Tooltip>
      ) : (
        shipmentLink
      ),
    };
  },
};

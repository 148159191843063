import { memoFragments } from '@xbcb/client-queries';
import { RecordType } from '@xbcb/shared-types';
import {
  workOrderTaskFragments,
  workOrderTaskStepFragments,
  generateDeliveryOrderMutation,
} from '@xbcb/work-order-queries';
import type { DeliveryOrder as ApigDeliveryOrder } from '@xbcb/api-gateway-client';
import { WorkOrderType, WorkOrderStatus } from '@xbcb/work-order-types';
import { DeliveryOrder } from '../../loadableComponents';
import { deliveryOrderFragments } from 'libs/sharedQueries';
import { executeMutation } from 'libs/executeMutation';
import { transformDeliveryOrder } from 'libs/formTransforms';
import { WorkOrderRoute } from '.';
import { markWorkOrderButton } from '../utils/markWorkOrdersReadyButton';
import validateDeliveryOrder from 'libs/validateDeliveryOrder';
import { isRecordCanceled, isRecordDeleted } from 'libs/workOrderConditions';

const editDeliveryOrder = 'editDeliveryOrder';
// We determine the condition to be in draft mode to be that status should be completed
const isDraftMode = (record: ApigDeliveryOrder) => {
  return record?.status === WorkOrderStatus.COMPLETED;
};

const fields = `
  ...deliveryOrderFields
  deadline
  group {
    memos {
      ...memoFields
    }
  }
  documents {
    id
    tags {
      key
      value
    }
  }
  tasks {
    ...workOrderTaskFields
    assignee {
      id
      name
    }
    steps {
      id
      name
      description
      isCompletedBySystem
      isFinalConfirmation
    }
    definition {
      id
      workOrderTaskType
    }
  }
`;

const deliveryOrderRoute: WorkOrderRoute = {
  Page: DeliveryOrder,
  recordType: RecordType.DELIVERY_ORDER,
  fields,
  fragments: `${deliveryOrderFragments}${workOrderTaskFragments}${workOrderTaskStepFragments}${memoFragments}`,
  transformUpdateRecordInput: transformDeliveryOrder.toSchema,
  transformRecordToInput: transformDeliveryOrder.toForm,
  validateUpdate: validateDeliveryOrder,
  draftEditMode: ({ existingRecord }) => {
    return !isDraftMode(existingRecord);
  },
  defaultEditMode: false,
  submitButtons: [
    markWorkOrderButton(WorkOrderType.DeliveryOrder),
    {
      key: 'generateDeliveryOrder',
      text: 'Generate Delivery Order',
      show: ({ existingRecord }) =>
        !isDraftMode(existingRecord) && !isRecordCanceled(existingRecord),
      onSubmit: async ({ updatedRecord }): Promise<void> => {
        const { id } = updatedRecord;

        await executeMutation({
          mutation: generateDeliveryOrderMutation,
          variables: { deliveryOrderId: id },
        });
      },
    },
  ],
  editModes: [
    {
      key: editDeliveryOrder,
      submitText: 'Update Delivery Order',
      onSubmit: async ({ updatedRecord }) => {
        const { id } = updatedRecord;

        await executeMutation({
          mutation: generateDeliveryOrderMutation,
          variables: { deliveryOrderId: id },
        });
      },
    },
  ],
  editButtons: [
    {
      key: editDeliveryOrder,
      text: 'Edit',
      show: ({ existingRecord }) => {
        return (
          !isRecordDeleted(existingRecord) &&
          isDraftMode(existingRecord) &&
          !isRecordCanceled(existingRecord)
        );
      },
    },
  ],
};

export default deliveryOrderRoute;

import { AppRecordValidateUpdate } from 'routes';
import { sharedValidateUsEntryRelease } from '../sharedValidateUsEntryRelease/index';
import { formatRecordName } from '@xbcb/js-utils';
import { RecordType, AnyObject } from '@xbcb/shared-types';
import { isLargeEntryUXEnabled } from '@xbcb/ui-utils';
import { validateTariffPga } from 'libs/validateTariffPga';
import { validateTariffPga as validateTariffPgaV2 } from 'libs/entryValidation';

const validateUsType86Entry: AppRecordValidateUpdate = async ({
  input,
  existingRecord,
  currentUser,
}) => {
  const {
    additionalErrors,
    validateFields,
    validatePartySnapshot,
    validateTabs,
  } = await sharedValidateUsEntryRelease({
    input,
    existingRecord,
    currentUser,
  });
  const isLargeEntryUX = isLargeEntryUXEnabled(currentUser?.id);
  const validateTariff = isLargeEntryUX
    ? validateTariffPgaV2
    : validateTariffPga;
  const { invoices, ior, conveyance } = input;
  const { accountType } = currentUser;

  const iorName = formatRecordName({
    recordType: RecordType.US_IOR,
    accountType,
  });

  if (validateTabs.commercialInvoices) {
    validatePartySnapshot(['ior', 'usIor', 'id'], iorName);
    invoices?.forEach((invoice: AnyObject, invoiceIndex: number) => {
      invoice.products?.forEach((product: AnyObject, productIndex: number) => {
        product?.lines?.forEach((line: AnyObject, lineIndex: number) => {
          line.tariffs?.forEach((tariff: AnyObject, tariffIndex: number) => {
            const tariffNamePath = [
              'invoices',
              invoiceIndex,
              'products',
              productIndex,
              'lines',
              lineIndex,
              'tariffs',
              tariffIndex,
            ];
            const descriptionSameAsCBP = tariff?.pgaDescription?.sameAsCbp;
            if (!descriptionSameAsCBP) {
              validateFields.push([
                ...tariffNamePath,
                'pgaDescription',
                'text',
              ]);
            }
            const tariffErrorString = `Invoice ${invoiceIndex + 1}, product ${
              productIndex + 1
            }, line ${lineIndex + 1}, tariff ${tariffIndex + 1}`;
            // Skipping for now to unblock pipeline; we're validating too many PGA fields
            if (tariff.pga && !isLargeEntryUX) {
              const {
                validateFields: validateFieldsToAppend,
                additionalErrors: additionalErrorsToAppend,
              } = validateTariff({
                tariff,
                iorName,
                validatePartySnapshot,
                ior,
                tariffNamePath,
                tariffErrorString,
                line,
                mot: conveyance?.modeOfTransport,
              });
              validateFields.push(...validateFieldsToAppend);
              additionalErrors.push(...additionalErrorsToAppend);
            }
          });
        });
      });
    });
  }

  return {
    additionalErrors,
    validateFields,
  };
};

export default validateUsType86Entry;

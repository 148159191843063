import styled from 'styled-components';
import { FormItem } from '@xbcb/form-item-components';

export const StyledLicensesDiv = styled.div`
  display: flex;
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0;
`;

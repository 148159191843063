import { gql } from '@apollo/client';

export const SearchCustomsBrokersQuery = gql`
  query SearchCustomsBrokers($input: SearchCustomsBrokerInput!) {
    searchCustomsBrokers(input: $input) {
      results {
        id
        name
        tags {
          key
          value
        }
      }
    }
  }
`;

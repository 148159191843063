import { Form } from 'antd';
import {
  arrivalNamePath,
  importDateNamePath,
  getArrivalAsMoment,
} from 'libs/htsHelpers';
export const useArrivalDate = (): string | undefined => {
  const form = Form.useFormInstance();
  const arrivalDate = Form.useWatch(arrivalNamePath, form);
  const importDate = Form.useWatch(importDateNamePath, form);

  const effectiveDate = arrivalDate || importDate;
  return getArrivalAsMoment(effectiveDate);
};

import React from 'react';
import { AccountType } from '@xbcb/shared-types';
import { useCurrentUser } from 'libs/hooks';
import titleCase from 'libs/titleCase';
import copyToClipboard from 'libs/copyToClipboard';
import MOTIcon from 'components/MOTIcon';
import { AppRecordSecondaryHeaderContentProps } from 'routes';
import { LinkOutlined, WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

const StyledDiv = styled.div`
  font-size: 18px;

  .anticon:not(:first-child) {
    margin-left: var(--space-4);
  }
`;

const StyledSpan = styled.span`
  margin-left: var(--space-4);
`;

const StyledWarningOutline = styled(WarningOutlined)`
  color: var(--primary-color);
`;

const UsPostSummaryCorrectionSecondaryHeader: React.FC<
  AppRecordSecondaryHeaderContentProps
> = ({ record = {} }) => {
  const { conveyance, summaryRejectionReasons } = record;
  const modeOfTransport = conveyance?.modeOfTransport;
  const { accountType } = useCurrentUser();
  return (
    <StyledDiv>
      <Tooltip title="Copy the Post Summary Correction URL" placement="top">
        <LinkOutlined onClick={() => copyToClipboard(window.location.href)} />
      </Tooltip>
      <Tooltip title={titleCase(modeOfTransport)} placement="top">
        <StyledSpan>
          <MOTIcon mot={modeOfTransport} size="18" />
        </StyledSpan>
      </Tooltip>
      {accountType === AccountType.OPERATOR && summaryRejectionReasons && (
        <Tooltip
          title={
            <>
              <p className="space-bottom">Entry Summary (AE/AX) rejected:</p>
              {summaryRejectionReasons.map(
                (r: { name: string; code: string }, i: number) => (
                  <p key={i}>{r.name}</p>
                ),
              )}
            </>
          }
          placement="top"
        >
          <StyledWarningOutline />
        </Tooltip>
      )}
    </StyledDiv>
  );
};

export default UsPostSummaryCorrectionSecondaryHeader;

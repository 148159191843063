const additionalHtsTypes = {
  section301List1: 'Section 301 List 1',
  section301List2: 'Section 301 List 2',
  section301List3: 'Section 301 List 3',
  section301List4A: 'Section 301 List 4A',
  section301List4B: 'Section 301 List 4B',
  section232Steel: 'Section 232 Steel',
  section232Aluminum: 'Section 232 Aluminum',
  section201SolarCells: 'Section 201 Solar Cells/Modules',
  section201WasherFinishedGoods: 'Section 201 Washer Finished Goods',
  section201WasherParts: 'Section 201 Washer Parts',
  SECTION_301_LIST_1: 'Section 301 List 1',
  SECTION_301_LIST_2: 'Section 301 List 2',
  SECTION_301_LIST_3: 'Section 301 List 3',
  SECTION_301_LIST_4A: 'Section 301 List 4A',
  SECTION_301_LIST_4B: 'Section 301 List 4B',
  SECTION_232_STEEL: 'Section 232 Steel',
  SECTION_232_ALUMINIUM: 'Section 232 Aluminum',
  DIGITAL_SERVICES_SECTION_301_TURKEY:
    "Turkey's Digital Services Tax Section 301 Additional Duty",
  DIGITAL_SERVICES_SECTION_301_SPAIN:
    "Spain's Digital Services Tax Section 301 Additional Duty",
  DIGITAL_SERVICES_SECTION_301_ITALY:
    "Italy's Digital Services Tax Section 301 Additional Duty",
  DIGITAL_SERVICES_SECTION_301_FRANCE:
    "France's Digital Services Tax Section 301 Additional Duty",
  DIGITAL_SERVICES_SECTION_301_UK:
    "United Kingdom's Digital Services Tax Section 301 Additional Duty",
  DIGITAL_SERVICES_SECTION_301_INDIA:
    "India's Digital Services Tax Section 301 Additional Duty",
  DIGITAL_SERVICES_SECTION_301_AUSTRIA:
    "Austria's Digital Services Tax Section 301 Additional Duty",
  RUSSIA_ALUMINUM: 'Russia Additional Aluminum Duty',
  RUSSIA_ALUMINUM_PRIVILEGED:
    'Russia Additional Aluminum Duty "privileged foreign status"',
  EU_LARGE_CIVIL_AIRCRAFT_SECTION_301:
    'EU Large Civil Aircraft Section 301 Additional Duty',
};

export default additionalHtsTypes;

import { AnyObject } from '@xbcb/shared-types';
import { Tooltip } from 'antd';
import React from 'react';
import { AppRecordBadge, AppRecordGetBadges } from 'routes';
import styled from 'styled-components';
import { hotIcon, premiumIcon, refileIcon } from './icons';

interface GetAppRecordBadgeIconProps {
  badgeName: string;
  size?: string;
}

const badgeNameToIconMap: Record<string, any> = {
  PREMIUM: premiumIcon,
  HOT: hotIcon,
  REFILE: refileIcon,
};

const iconSize = '20';

const StyledSpan = styled.span`
  margin-left: var(--space-4);
`;

export const AppRecordBadgeIcon: React.FC<GetAppRecordBadgeIconProps> = ({
  badgeName,
}) => {
  if (!badgeName) return null;
  const Icon = badgeNameToIconMap[badgeName];
  return Icon && <Icon size={iconSize} />;
};

export const getBadgeIcons = (
  record: AnyObject,
  getAppRecordBadges: AppRecordGetBadges,
) => {
  const badges: AppRecordBadge[] = getAppRecordBadges({ record: record });
  const badgeIcons: JSX.Element[] = [];
  badges?.forEach((badge) => {
    if (badgeNameToIconMap[badge.text]) {
      const badgeIcon = (
        <Tooltip title={badge.tooltipText || badge.text} placement="top">
          <StyledSpan>
            <AppRecordBadgeIcon badgeName={badge.text}></AppRecordBadgeIcon>
          </StyledSpan>
        </Tooltip>
      );
      badgeIcons.push(badgeIcon);
    }
  });
  return <>{badgeIcons}</>;
};

import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { useCurrentUser, useCustomsBrokerId } from 'libs/hooks';
import { StyledDiv, StyledSpan } from './styles';
import { AnyObject, RecordType, AccountType } from '@xbcb/shared-types';
import { ActivationStatus } from '@xbcb/party-types';
import { getRecordFromResponseV2, SearchQuery } from '@xbcb/shared-queries';
import { useQuery } from '@apollo/client';
import { formatRecordName } from '@xbcb/js-utils';
import { DataCyPrefix } from '@xbcb/ui-types';

type UsIorAdditionalTitleContentProps = {
  record: AnyObject;
};

const activationStatusToDisplayStatusMap: {
  [key in ActivationStatus]?: string;
} = {
  [ActivationStatus.PENDING_ACTIVATION]: 'Pending Activation',
  [ActivationStatus.PENDING_DIRECT]: 'Pending Direct',
  [ActivationStatus.PENDING_DIRECT_CERT]: 'Pending Direct & Cert',
  [ActivationStatus.PENDING_MASTER_SUB_CERT]: 'Pending Master, Sub & Cert',
  [ActivationStatus.PENDING_MASTER_SUB]: 'Pending Master & Sub',
  [ActivationStatus.PENDING_MASTER]: 'Pending Master',
  [ActivationStatus.PENDING_SUB]: 'Pending Sub',
  [ActivationStatus.PENDING_CERT]: 'Pending Cert',
  [ActivationStatus.PENDING_MASTER_CERT]: 'Pending Master & Cert',
  [ActivationStatus.PENDING_SUB_CERT]: 'Pending Sub & Cert',
  [ActivationStatus.PENDING_INQUIRY]: 'Pending Inquiry',
  [ActivationStatus.ACTIVATED]: 'Activated',
};

// Displays US IOR current activation status, IOR activation work order link in header
const UsIorAdditionalTitleContent: React.FC<
  UsIorAdditionalTitleContentProps
> = ({ record }) => {
  const brokerId = useCustomsBrokerId();
  const history = useHistory();
  const { accountType } = useCurrentUser();
  const searchUsIorActivationQuery = SearchQuery({
    recordName: RecordType.US_IOR_ACTIVATION,
    fields: 'id',
  });
  // Code below assumes that firewall logic will automatically filter result by forwarder id.
  const searchQueryVariables = {
    input: {
      searchCriteria: {
        iorId: {
          values: [record?.id],
          operator: 'EQUALS',
        },
      },
    },
  };
  const { data, loading: iorActivationQueryLoading } = useQuery(
    searchUsIorActivationQuery,
    {
      variables: searchQueryVariables,
      skip: !record?.id,
    },
  );

  const { results = [] } =
    getRecordFromResponseV2({
      response: { data },
      crudOrSearchType: 'search',
      recordName: RecordType.US_IOR_ACTIVATION,
    }) || {};

  // if there is an IOR activation work order, get its id.
  const usIorActivationId = results[0]?.id;

  let activationStatus = record?.activations?.find(
    (activation: any) => brokerId === activation.customsBroker.id,
  )?.status;

  // fallback on deprecated field for existing IOR prior to externalization launch
  if (!activationStatus) {
    activationStatus = record?.activationStatus;
  }
  const iorDisplayStatus =
    activationStatusToDisplayStatusMap[
      activationStatus as keyof typeof ActivationStatus
    ];
  return (
    <>
      {iorDisplayStatus && (
        <StyledDiv>
          <StyledSpan>
            {activationStatus === ActivationStatus.ACTIVATED ? (
              <CheckCircleOutlined />
            ) : (
              <ExclamationCircleOutlined />
            )}
          </StyledSpan>
          {iorDisplayStatus}
        </StyledDiv>
      )}
      {activationStatus !== ActivationStatus.ACTIVATED &&
        !iorActivationQueryLoading &&
        accountType === AccountType.OPERATOR &&
        usIorActivationId && (
          <Tooltip
            title={formatRecordName({
              recordType: RecordType.US_IOR_ACTIVATION,
              accountType,
            })}
            placement="top"
          >
            <LinkOutlined
              data-cy={DataCyPrefix.US_IOR_ACTIVATION_WORK_ORDER_LINK}
              onClick={() => {
                history.push(`/us-ior-activations/${usIorActivationId}`);
              }}
            />
          </Tooltip>
        )}
    </>
  );
};

export default UsIorAdditionalTitleContent;

import { createAction } from '@reduxjs/toolkit';
import { AnyObject } from '@xbcb/shared-types';

export const SET_REFERENCED_RECORD = 'SET_REFERENCED_RECORD';

export interface SetReferencedRecordAction {
  referenceField: string;
  referenceKey: string;
  record: AnyObject;
}

export const setReferencedRecord = createAction<SetReferencedRecordAction>(
  SET_REFERENCED_RECORD,
);

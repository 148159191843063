import styled from 'styled-components';
import { Empty } from 'antd';

export const StyledFooter = styled.footer`
  padding: ${({ theme }) => theme.spacing.space_4};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledSpan = styled.span`
  margin-right: ${({ theme }) => theme.spacing.space_3};
  font-size: 12px;
`;

export const StyledDiv = styled.div`
  background-color: white;
  padding: ${({ theme }) => theme.spacing.space_2};
  && .ant-timeline {
    min-height: 0;
  }
  .ant-timeline-item:last-child {
    .ant-timeline-item-tail {
      display: none;
    }
  }
`;

export const StyledEmpty = styled(Empty)`
  p {
    margin-bottom: ${({ theme }) => theme.spacing.space_3};
  }
`;

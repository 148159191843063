import { get } from 'lodash';

// find a work order task on a work order that matches the task query string in the URL
export const findMatchingWorkOrderTask = (
  workOrderRecord: any,
  taskType: string,
) => {
  const workOrderTasks = get(workOrderRecord, 'tasks', []);
  return workOrderTasks.find((task: any) => {
    const workOrderTaskType = get(task, 'definition.workOrderTaskType', '');
    return taskType === workOrderTaskType;
  });
};

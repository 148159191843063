import type { WorkOrderGroup } from '@xbcb/api-gateway-client';
import { TagKey } from '@xbcb/shared-types';

// The poNumbers are stored on the WOG tags, thus this function takes a WOG and
// returns all of the poNumbers (actual string value, not the tags)
export const getPoNumbersFromWorkOrderGroup = (
  workOrderGroup?: WorkOrderGroup,
): string[] => {
  const { tags = [] } = workOrderGroup || {};
  const poNumbers: string[] = [];
  tags.forEach(({ key, value }) => {
    if (key === TagKey.PO_NUMBER) poNumbers.push(value);
  });
  return poNumbers;
};

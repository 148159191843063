import {
  getCountryAbbreviationFromWorkOrderType,
  WorkOrderType,
} from '@xbcb/work-order-types';
import {
  AnyObject,
  WorkOrderRecordType,
  PartyRecordType,
  RecordType,
} from '@xbcb/shared-types';
import { pascalCase } from 'change-case';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';

const entryRecordTypes = [
  WorkOrderRecordType.US_CONSUMPTION_ENTRY,
  WorkOrderRecordType.GB_CUSTOMS_ENTRY,
  WorkOrderRecordType.DE_CUSTOMS_ENTRY,
  WorkOrderRecordType.NL_CUSTOMS_ENTRY,
  WorkOrderRecordType.FR_CUSTOMS_ENTRY,
  WorkOrderRecordType.US_IOR_ACTIVATION,
  WorkOrderRecordType.GB_IOR_ACTIVATION,
  WorkOrderRecordType.DE_IOR_ACTIVATION,
  WorkOrderRecordType.NL_IOR_ACTIVATION,
  WorkOrderRecordType.FR_IOR_ACTIVATION,
];

const iorRecordTypes = [
  PartyRecordType.US_IOR,
  PartyRecordType.DE_IOR,
  PartyRecordType.GB_IOR,
  PartyRecordType.NL_IOR,
  PartyRecordType.FR_IOR,
];

const getPermissions = (user: AnyObject, recordTypes: RecordType[]) =>
  recordTypes.map((recordType) =>
    checkAccess(user, recordType, UserPermissions.READ),
  );

interface FormatCountryAbbreviationProps {
  user: AnyObject;
  recordType: PartyRecordType | WorkOrderRecordType;
  recordTypes?: RecordType[];
  permissions?: boolean[];
  alwaysShowAbbreviation?: boolean; // It can be provided to show the abbreviation regardless of how many permissions they have for the given record type
}

// This lib can be used to add a country abbreviation prefix to an entry/ior when we render more than one WO or IOR type,
// so to avoid showing duplicates.
const formatCountryAbbreviation = ({
  recordType,
  recordTypes = [],
  permissions = [],
  alwaysShowAbbreviation = false,
}: FormatCountryAbbreviationProps) => {
  const countryAbbreviation =
    // we only check whether it's an entry/ior or not
    // because it's the only WO/IOR that we have defined for more than one 1 country currently
    recordTypes.includes(recordType) &&
    (alwaysShowAbbreviation ||
      // Only show the countryAbbreviation when the user has access to more than one entry or ior
      permissions.filter((permission) => permission).length > 1)
      ? `${getCountryAbbreviationFromWorkOrderType(
          pascalCase(recordType) as WorkOrderType,
        )} `
      : '';
  return countryAbbreviation;
};

export const formatWorkOrderCountryAbbreviation = (
  props: FormatCountryAbbreviationProps,
) =>
  formatCountryAbbreviation({
    ...props,
    recordTypes: entryRecordTypes,
    permissions: getPermissions(props.user, entryRecordTypes),
  });

export const formatIorCountryAbbreviation = (
  props: FormatCountryAbbreviationProps,
) =>
  formatCountryAbbreviation({
    ...props,
    recordTypes: iorRecordTypes,
    permissions: getPermissions(props.user, iorRecordTypes),
  });

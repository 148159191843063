import styled from 'styled-components';
import { Img } from 'react-image';
import { Layout } from 'antd';
import { UserStatus } from '@xbcb/client-types';
const { Header } = Layout;

export const StyledHeader = styled(Header)`
  padding: 0;
  flex: 0 1 auto;
  display: flex;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  .main-header {
    display: flex;
    padding-left: 24px;
    flex: 1;
    justify-content: space-between;
    .ant-input-search {
      align-self: center;
      width: 512px;
    }
    .switch-user-icon {
      background: none;
      border: none;
      margin-right: ${({ theme }) => theme.spacing.space_4};
    }
  }
  .main-header div:only-child {
    align-self: flex-end;
    margin-left: auto;
    margin-right: ${({ theme }) => theme.spacing.space_4};
  }
  .logout-text {
    color: #fff;
    font-size: medium;
  }

  display: flex;
  .logout-button {
    border: 0 !important;
    height: ${({ theme }) => theme.spacing.space_6};
  }
  .button-wrapper {
    margin-right: ${({ theme }) => theme.spacing.space_4};
    button.ant-btn {
      border: none;
      .anticon {
        font-size: 18px;
        font-weight: 400px;
      }
    }
  }
  .help {
    height: 64px;
    .help-button {
      line-height: 1;
      margin-top: ${({ theme }) => theme.spacing.space_4};
      border: none;
      font-size: 18px;
      font-weight: 400;
    }
  }
  .notifications {
    margin-right: ${({ theme }) => theme.spacing.space_4};
  }
  .search-recently-viewed-actions {
    display: flex;
  }
  .app-user-actions {
  }
`;

export const StyledSpan = styled.span`
  color: #fff;
  font-size: medium;
  display: flex;
  align-items: center;
`;

export const StyledUserLabel = styled.div`
  h3,
  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: ${({ theme }) => theme.spacing.space_10}) {
    display: none;
  }
  height: ${({ theme }) => theme.spacing.space_6};
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 16px;
  h3 {
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }
`;

export const StyledUserActions = styled.div`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing.space_4};
  margin-left: ${({ theme }) => theme.spacing.space_3};
  display: flex;
`;

export const StyledUserContextActions = styled.div`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing.space_4};
  margin-left: ${({ theme }) => theme.spacing.space_3};
  display: flex;
`;

export const StyledImage = styled(Img)`
  margin-right: ${({ theme }) => theme.spacing.space_4};
  margin-bottom: 3px;
  width: ${({ theme }) => theme.spacing.space_5};
  height: ${({ theme }) => theme.spacing.space_5};
  border-radius: 100%;
`;

export const StyledPrivateActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledImageContainer = styled.span<{ currentStatus?: UserStatus }>`
  position: relative;
  ::after {
    position: absolute;
    content: ' ';
    background-color: ${({ currentStatus, theme }) =>
      currentStatus ? theme.status[currentStatus] : 'transparent'};
    top: 25%;
    left: 60%;
    border-radius: 50%;
    height: 7px;
    width: 7px;
  }
`;

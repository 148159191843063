export const formatAbbreviation = (code: string): string => {
  // add the abbrevations required to be in upper case
  const abbreviations = [
    'VAT',
    'EORI',
    'FTA',
    'GSP',
    'IOR',
    'POA',
    'ISF',
    'ASI',
    'MBL',
    'HBL',
    'CBP',
    'PGA',
    'FDA',
    'NHTSA',
    'APHIS',
    'EPA',
    'FWS',
    'AD CVD',
    'COO FTA',
  ];
  // regex to convert all abbreviations to uppercases
  const abbreviationRegex = new RegExp(
    '\\b' + abbreviations.join('|\\b'),
    'gi',
  );
  return code.replace(abbreviationRegex, (s) => s.toUpperCase());
};

import React from 'react';
import BulkUploadTemplateDownloadButton from 'components/BulkUploadTemplateDownloadButton/component';
import { isGenericBroker } from 'libs/isGenericBroker';
import { useCurrentUser } from 'libs/hooks/useCurrentUser/index';

type BulkUploadButtonProps = {
  templateType: 'Charges' | 'Milestone';
};

const CommonBulkUploadTemplateButton: React.FC<BulkUploadButtonProps> = ({
  templateType,
}) => {
  const currentUser = useCurrentUser();

  const templateTag = isGenericBroker(currentUser.customsBroker?.id)
    ? { key: 'bulkUploadTemplate', value: `${templateType}V2` }
    : { key: 'bulkUploadTemplate', value: templateType };

  return <BulkUploadTemplateDownloadButton templateTag={templateTag} />;
};

export default CommonBulkUploadTemplateButton;

import type { OperatorUser } from '@xbcb/api-gateway-client';
import { getEscalatedToMeTask } from 'libs/sharedQueries';

export const findOperatorWithLeastTasks = async (
  subjectMatterExperts: OperatorUser[],
): Promise<OperatorUser | undefined> => {
  if (!subjectMatterExperts.length) {
    return undefined;
  }

  // Find the number of tasks assigned to each operator
  const taskCounts = await Promise.all(
    subjectMatterExperts.map(
      async (operator) => await getEscalatedToMeTask(operator.id),
    ),
  );

  // Find the operator with the least number of tasks
  const minIndex = taskCounts.indexOf(Math.min(...taskCounts));

  return subjectMatterExperts[minIndex];
};

import React from 'react';
import { Modal } from 'antd';
import { ModalKey } from '@xbcb/ui-types';
import { useModal } from '@xbcb/ui-utils';
import PendingSubPoasModalContents from 'components/PendingSubPoasModalContents';

const PendingSubPoasModal = () => {
  const { closeModal, visible } = useModal(ModalKey.PENDING_SUB_POAS);

  return (
    <Modal
      footer={null}
      maskClosable={false}
      onCancel={closeModal}
      title={null}
      visible={visible}
      width={1000}
    >
      <PendingSubPoasModalContents />
    </Modal>
  );
};

export default PendingSubPoasModal;

import {
  userFragments,
  userWorkDayFields,
  userWorkingHoursFields,
  operatorUserPermissionFields,
} from '@xbcb/client-queries';

const operatorUserFragment = `
fragment operatorUserFields on OperatorUser {
  operator {
    id
  }
  operator {
    id
  }
  assignmentTeams{
    nodes{
      id
      name
    }
  }
  subjectMatterExpertTeams{
    nodes{
      id
      name
    }
  }
  dailyGoal
  skillLevel
  workingHours {
    ...userWorkingHoursFields
  }
  assignmentConfig {
    taskConfigs {
      timePercentage
      workOrderTaskDefinition {
        id
        workOrderType
      }
      usCbpIndustryVerticals
      modesOfTransport
    }
  }
  ${operatorUserPermissionFields}
  ...userFields
  ...recordFields
}
`;
export const operatorUserFragments = `
  ${operatorUserFragment}
  ${userWorkingHoursFields}
  ${userWorkDayFields}
  ${userFragments}
`;

import { PgaWithDisclaim } from './pgaWithDisclaim';
import { safeGet } from '@xbcb/js-utils';
import { AnyObject } from '@xbcb/shared-types';
import {
  AdditionalFormError,
  AppRecordValidateResult,
  NamePath,
} from '@xbcb/ui-types';
import {
  packagingMissingError,
  subjectToAphCore,
  subjectToLaceyAct,
} from './pgaValidationHelpers';

interface ValidateAphProps {
  tariffNamePath: NamePath;
  pga: PgaWithDisclaim;
  pgaFlag: string;
  getField: (field: string | (string | number)[]) => NamePath;
}

export const validateAph = ({
  tariffNamePath,
  pga = {},
  pgaFlag = '',
  getField,
}: ValidateAphProps): AppRecordValidateResult => {
  const validateFields: NamePath[] = [];
  const additionalErrors: AdditionalFormError[] = [];
  const aphFields: (string | (string | number)[])[] = [];
  const { constituentElements, characteristics, packaging } = pga;

  const isSubjectToLaceyAct = subjectToLaceyAct(pgaFlag);
  const isSubjectToAphCore = subjectToAphCore(pgaFlag);

  if (isSubjectToLaceyAct || isSubjectToAphCore) {
    constituentElements?.forEach((_, index) => {
      aphFields.push(
        ['constituentElements', index, 'name'],
        ['constituentElements', index, 'scientificName'],
        ['constituentElements', index, 'harvestCountryCode'],
        ['constituentElements', index, 'quantity', 'value'],
        ['constituentElements', index, 'quantity', 'unit'],
      );
    });
  }

  if (isSubjectToAphCore) {
    aphFields.push(
      'originCountryCode',
      'categoryTypeCode',
      'categoryCode',
      ['source', 'sourceCountryType'],
      ['source', 'countryCode'],
    );

    characteristics?.forEach((_, index) => {
      aphFields.push(
        ['characteristics', index, 'qualifier'],
        ['characteristics', index, 'code'],
      );
    });

    const lpcos = safeGet(pga, 'LPCO') as AnyObject[] | undefined;
    lpcos?.forEach((_, index) => {
      aphFields.push(
        ['LPCO', index, 'lpcoFieldName'],
        ['LPCO', index, 'issuerLocation'],
        ['LPCO', index, 'number'],
        ['LPCO', index, 'date'],
        ['LPCO', index, 'quantity'],
        ['LPCO', index, 'uomCode'],
      );
    });

    if (!packaging) {
      additionalErrors.push(packagingMissingError(tariffNamePath));
    }

    packaging?.forEach((_, index) => {
      aphFields.push(
        ['packaging', index, 'value'],
        ['packaging', index, 'unit'],
      );
    });
  }

  aphFields.forEach((field) => {
    validateFields.push(getField(field));
  });

  return { validateFields, additionalErrors };
};

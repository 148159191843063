import { AppRecordOverrideTitle, AppRecordGetTitleTooltipText } from 'routes';

export const GetShipmentTitleField = (): string => {
  return 'friendlyId';
};

export const shipmentTitleOverride: AppRecordOverrideTitle = ({
  title,
  record,
}) => {
  // We want to use XBTMS booking id as shipment page title
  // if there is no booking id, we use the initial title
  return (
    record?.clientReferences?.bookingId || record?.clientIdentifier || title
  );
};

export const getShipmentTitleTooltipText: AppRecordGetTitleTooltipText = ({
  record,
}) => {
  // We want to display clientIdentifier when hover over shipment title
  return record?.clientIdentifier;
};

export const getShipmentTitleField = GetShipmentTitleField;

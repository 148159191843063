import { RecordType } from '@xbcb/shared-types';
import { EuIor } from '../../loadableComponents';
import euIorRoute from './euIor';
import { gbIorFragments } from '@xbcb/party-queries';

const gbIorRoute = euIorRoute({
  page: EuIor,
  recordType: RecordType.GB_IOR,
  fields: `...gbIorFields`,
  fragments: gbIorFragments,
});

export default gbIorRoute;

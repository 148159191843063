import React, { memo } from 'react';
import { UserStatus } from '@xbcb/client-types';
import { StyledStatusDisplay, StyledStatusIcon } from './styles';
import { useBundle } from '@amzn/react-arb-tools';
import { safeGetMessage } from '@xbcb/ui-utils';

export interface StatusDisplayProps {
  currentStatus: UserStatus;
}

const StatusDisplay: React.FC<StatusDisplayProps> = ({ currentStatus }) => {
  const [appHeaderBundle] = useBundle('components.AppHeader');
  return (
    <StyledStatusDisplay>
      <StyledStatusIcon currentStatus={currentStatus} />
      {safeGetMessage(appHeaderBundle, currentStatus.toLowerCase())}
    </StyledStatusDisplay>
  );
};

export default memo(StatusDisplay);

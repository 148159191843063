import { constantCase } from 'change-case';
import { ModalKey } from '@xbcb/ui-types';

// Given how many places we import these from `'types'`, just reexport from
// here. Additionally, this gives us the ability to import them like we do all
// other types and makes it clear we are using a type
export {
  OPEN_MODAL,
  CLOSE_MODAL,
  TOGGLE_MODAL,
  SET_MODAL,
  MERGE_MODAL,
} from '@xbcb/ui-utils';
export { ModalKey } from '@xbcb/ui-types';

// This function should be used to "create" a `ModalKey` type. This is used in
// cases where we can't add the value to `ModalKey` directly like we have with
// the values above. An example of this is in `RecordSelect` where we often have
// multiple instances of the `RecordSelect` (and thus the modal it renders) so
// we must have unique modal keys. An example implementation is as follows:
// ------------------ example ------------------
// const {
//   visible: quickCreateVisible,
//   openModal: openQuickCreate,
//   closeModal: closeQuickCreate,
// } = useModal(createModalKey(`QUICK_CREATE_${fullNamePath.join('_')}`));
// ------------------ example ------------------
export const createModalKey = (key: string) =>
  constantCase(key).replace('-', '_') as ModalKey;

import React from 'react';
import { get } from 'lodash';
import { selectFilter, getCodes } from '@xbcb/ui-utils';
import { Select, Option, FormItem } from '@xbcb/form-item-components';
import dutyComputation from 'libs/dutyDisplay';
import tooltips from 'libs/tooltips';
import { InlineTooltip } from '@xbcb/feedback-components';
import { StyledFormInlineDiv } from './styles';
import { NamePath } from 'rc-field-form/lib/interface';
import { CssSize } from '@xbcb/ui-types';
import { FormInstance } from 'antd/lib/form';

export interface SPISelectProps {
  localNamePath: NamePath;
  fullNamePath: NamePath;
  label?: string;
  required?: boolean;
  onChange?: (value: any, option: any) => void;
  readOnly?: boolean;
  disabled?: boolean;
  originCountryCode?: string;
  htsNumber?: string;
  htsObj?: any;
  entryDate?: any;
  form: FormInstance;
}

const SPISelect = ({
  entryDate,
  originCountryCode,
  htsNumber,
  htsObj,
  localNamePath,
  fullNamePath,
  label = 'SPI',
  required,
  onChange,
  readOnly = false,
  form,
  disabled,
}: SPISelectProps) => {
  const codes = getCodes();
  const claimCodes = codes.USITC.specialTariffProgram.indicators;
  const groupCodes = codes.USITC.specialTariffProgram.groups;
  const mpfPreferentialTradePrograms =
    codes.USITC.specialTariffProgram.mpfPreferentialTradePrograms;
  const oldIndiaGSP = Boolean(
    originCountryCode === 'IN' &&
      entryDate &&
      entryDate.isSameOrBefore('2019-06-04', 'day'),
  );
  const oldTurkeyGSP = Boolean(
    originCountryCode === 'TR' &&
      entryDate &&
      entryDate.isSameOrBefore('2019-05-16', 'day'),
  );
  const dutyFree = dutyComputation({ htsNumber, ...htsObj }) === 'Free';
  const spiOptions = Object.keys(claimCodes).reduce(
    (acc: any, claimCode, index) => {
      if (
        (claimCode === 'N' && groupCodes.QIZ.includes(originCountryCode)) ||
        (dutyFree && claimCode === 'MX' && originCountryCode === 'MX') ||
        (dutyFree && claimCode === 'CA' && originCountryCode === 'CA') ||
        (!claimCodes[claimCode].hidden &&
          (!originCountryCode ||
            (claimCodes[claimCode].country &&
              originCountryCode === claimCodes[claimCode].country) ||
            (claimCodes[claimCode].group &&
              groupCodes[claimCodes[claimCode].group] &&
              (groupCodes[claimCodes[claimCode].group].includes(
                originCountryCode,
              ) ||
                // india GSP A eligibility ended after 6/4/19
                // Turkey GSP A eligibility ended after 5/16/19
                (claimCode === 'A' && (oldIndiaGSP || oldTurkeyGSP))))) &&
          ((dutyFree && mpfPreferentialTradePrograms?.includes(claimCode)) ||
            (htsObj &&
              htsObj.spi &&
              (htsObj.spi.includes(claimCode) ||
                (claimCode === 'A' &&
                  (htsObj.spi.includes('A*') || htsObj.spi.includes('A+')) &&
                  (get(
                    groupCodes,
                    get(claimCodes, ['A', 'group']),
                    [],
                  ).includes(originCountryCode) ||
                    // india GSP A* eligibility ended after 6/4/19
                    // Turkey GSP A* eligibility ended after 5/16/19
                    oldIndiaGSP ||
                    oldTurkeyGSP) &&
                  (!htsObj.gspExcluded ||
                    !htsObj.gspExcluded.includes(originCountryCode)))))))
      ) {
        acc.push(
          <Option key={index} value={claimCode}>
            {claimCode} - {claimCodes[claimCode].name}
          </Option>,
        );
      }
      return acc;
    },
    [],
  );
  if (!spiOptions.length && form.getFieldsValue([fullNamePath])) {
    form.setFields([{ name: fullNamePath, value: undefined }]);
  }
  return spiOptions.length > 0 ? (
    <StyledFormInlineDiv>
      <FormItem
        label={label}
        $itemSize={CssSize.SHORT}
        name={localNamePath}
        rules={[{ required, message: ' ' }]}
        $readOnly={readOnly}
      >
        <Select
          disabled={disabled}
          showSearch
          allowClear
          onChange={onChange}
          filterOption={selectFilter}
          notFoundContent="None Found"
          dropdownMatchSelectWidth={false}
        >
          {spiOptions}
        </Select>
      </FormItem>
      <InlineTooltip spaceTop title={tooltips.spi} />
    </StyledFormInlineDiv>
  ) : null;
};

export default SPISelect;

import { handleActions } from 'redux-actions';

export default handleActions(
  {
    DATA_GENERAL_REPLACE: (state, { payload }) => {
      return payload.docs
        ? {
            ...state,
            ...payload.docs,
          }
        : state;
    },
    LOGOUT: () => ({}),
  },
  {},
);

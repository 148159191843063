import React, { memo, useEffect } from 'react';
import { Checkbox, CountrySelect } from '@xbcb/form-item-components';
import { NamePath } from '@xbcb/ui-types';
import { Form } from 'antd';

export type AluminumSmeltAndCastCountryDetailProps = {
  localNamePath: NamePath;
  fullNamePath: NamePath;
};

export const secondaryAluminumContentOnlyLabel =
  'Secondary (Recycled) Aluminum Content Only';
export const primaryCountryOfSmeltLabel = 'Primary Country of Smelt';
export const secondaryCountryOfSmeltLabel = 'Secondary Country of Smelt';
export const countryOfCastLabel = 'Country of Cast';

export const notApplicableDisplayText = 'Not Applicable';
const notApplicableCode = 'N/A';

const notApplicableOption = {
  key: notApplicableCode,
  value: notApplicableCode,
  display: notApplicableDisplayText,
};

export enum Section232AluminumRequirementsFields {
  PRIMARY_COUNTRY_OF_SMELT = 'aluminumPrimaryCountryOfSmelt',
  SECONDARY_COUNTRY_OF_SMELT = 'aluminumSecondaryCountryOfSmelt',
  COUNTRY_OF_CAST = 'aluminumCountryOfCast',
  SECONDARY_ALUMINUM_ONLY = 'secondaryAluminumOnly',
}

const aluminumRequirementsFieldPrefix = 'aluminumRequirements';

export const section232AluminumRequirementsFormFieldsNamePaths: NamePath[] =
  Object.values(Section232AluminumRequirementsFields).map((field: string) => [
    aluminumRequirementsFieldPrefix,
    field,
  ]);

export const AluminumSmeltAndCastCountryDetail: React.FC<
  AluminumSmeltAndCastCountryDetailProps
> = ({ localNamePath, fullNamePath }) => {
  const fieldLocalNamePath = (field: Section232AluminumRequirementsFields) => {
    return [...localNamePath, aluminumRequirementsFieldPrefix, field];
  };

  const fieldFullNamePath = (
    fieldName: Section232AluminumRequirementsFields,
  ) => {
    return [...fullNamePath, aluminumRequirementsFieldPrefix, fieldName];
  };

  const form = Form.useFormInstance();
  const countryOfOrigin = Form.useWatch([
    ...fullNamePath,
    'origin',
    'countryCode',
  ]);
  const usCountryCode = 'US';
  const isCountryOfOriginUs = countryOfOrigin === usCountryCode;

  const isSecondaryAluminumContentOnly = Form.useWatch(
    fieldFullNamePath(
      Section232AluminumRequirementsFields.SECONDARY_ALUMINUM_ONLY,
    ),
  );

  const isSecondaryAluminumContentOnlyOrCountryOfOriginIsUs =
    isSecondaryAluminumContentOnly || isCountryOfOriginUs;

  const setAluminumFields = (
    fields: Section232AluminumRequirementsFields[],
    value: string | undefined,
  ) => {
    fields.forEach((field) => {
      form.setFields([
        {
          name: fieldFullNamePath(field),
          value,
        },
      ]);
    });
  };

  const setCountriesOfSmelt = (value: string | undefined) => {
    setAluminumFields(
      [
        Section232AluminumRequirementsFields.PRIMARY_COUNTRY_OF_SMELT,
        Section232AluminumRequirementsFields.SECONDARY_COUNTRY_OF_SMELT,
      ],
      value,
    );
  };

  useEffect(() => {
    if (isCountryOfOriginUs) {
      setCountriesOfSmelt(notApplicableCode);
      setAluminumFields(
        [Section232AluminumRequirementsFields.COUNTRY_OF_CAST],
        usCountryCode,
      );
    }
  }, [countryOfOrigin]);

  const primaryCountryOfSmelt = Form.useWatch(
    fieldFullNamePath(
      Section232AluminumRequirementsFields.PRIMARY_COUNTRY_OF_SMELT,
    ),
  );

  useEffect(() => {
    if (
      !isSecondaryAluminumContentOnlyOrCountryOfOriginIsUs &&
      primaryCountryOfSmelt === notApplicableCode
    ) {
      setAluminumFields(
        [Section232AluminumRequirementsFields.PRIMARY_COUNTRY_OF_SMELT],
        undefined,
      );
    }
  });

  return (
    <div>
      <Checkbox
        localNamePath={fieldLocalNamePath(
          Section232AluminumRequirementsFields.SECONDARY_ALUMINUM_ONLY,
        )}
        fullNamePath={fieldFullNamePath(
          Section232AluminumRequirementsFields.SECONDARY_ALUMINUM_ONLY,
        )}
        form={form}
        text={secondaryAluminumContentOnlyLabel}
        onChange={(event) => {
          if (event.target.checked) {
            setCountriesOfSmelt(notApplicableCode);
          } else {
            setCountriesOfSmelt(undefined);
          }
        }}
      />
      <CountrySelect
        label={primaryCountryOfSmeltLabel}
        localNamePath={fieldLocalNamePath(
          Section232AluminumRequirementsFields.PRIMARY_COUNTRY_OF_SMELT,
        )}
        required
        disabled={isSecondaryAluminumContentOnlyOrCountryOfOriginIsUs}
        additionalOptions={
          isSecondaryAluminumContentOnlyOrCountryOfOriginIsUs
            ? [notApplicableOption]
            : []
        }
      />
      <CountrySelect
        label={secondaryCountryOfSmeltLabel}
        required
        localNamePath={fieldLocalNamePath(
          Section232AluminumRequirementsFields.SECONDARY_COUNTRY_OF_SMELT,
        )}
        disabled={isSecondaryAluminumContentOnlyOrCountryOfOriginIsUs}
        additionalOptions={[notApplicableOption]}
      />
      <CountrySelect
        label={countryOfCastLabel}
        required
        localNamePath={fieldLocalNamePath(
          Section232AluminumRequirementsFields.COUNTRY_OF_CAST,
        )}
        disabled={isCountryOfOriginUs}
      />
    </div>
  );
};

export default memo(AluminumSmeltAndCastCountryDetail);

import type { Invoice } from '@xbcb/api-gateway-client';
import { AccountingSystemReferenceType } from '@xbcb/finance-types';

const isArInvoice = (invoice: Invoice): boolean => {
  const hasQBOInvoiceId = Boolean(
    getInvoiceId(invoice, AccountingSystemReferenceType.QBO_ID),
  );
  const hasARInvoiceId = Boolean(
    getInvoiceId(invoice, AccountingSystemReferenceType.AR_INVOICE_ID),
  );
  return hasARInvoiceId || !hasQBOInvoiceId;
};

const getInvoiceId = (
  invoice: Invoice,
  accountingSystemReferenceType: string,
): string | undefined => {
  const accountingSystemReferences =
    invoice.accountingSystemDetails?.references;
  return accountingSystemReferences?.find(
    (ref) => ref.type === accountingSystemReferenceType,
  )?.value;
};

export default isArInvoice;

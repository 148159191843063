import { get } from 'lodash';
import pluralize from 'pluralize';
import { isRealHts as realHTS } from '@xbcb/entry-utils/dist/lib/htsUtil';
import { formatRecordName } from '@xbcb/js-utils';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { ModeOfTransport } from '@xbcb/shipment-types';
import {
  getCodes,
  isLargeEntryUXEnabled,
  defaultCreatePartyValidatorEmbeddedFields,
  manifestFieldsToValidate,
} from '@xbcb/ui-utils';
import { UsConsumptionEntryCommercialInvoiceAdjustmentType } from '@xbcb/work-order-types';
import { createAccumulator, validateInvoice } from 'libs/entryValidation';
import { roundNumber } from 'libs/format';
import { usCbpEntryFeeCodeToTypeMap } from 'libs/taxesFeesBimaps';
import { getValidationParams } from '../getValidationParams';

const hasInvalidUnitAssist = ({ value }: AnyObject): boolean =>
  value === undefined || value <= 0;

export type SharedValidateUsEntrySummaryProps = {
  input: AnyObject;
  currentUser: AnyObject;
};

export const sharedValidateUsEntrySummary = ({
  input,
  currentUser,
}: SharedValidateUsEntrySummaryProps) => {
  const { accountType } = currentUser;
  const {
    invoices,
    consignee,
    masterBills,
    arrival = {},
    departure = {},
    conveyance = {},
  } = input;
  const largeEntryUXEnabled = isLargeEntryUXEnabled(currentUser?.id);
  const {
    validateFields,
    additionalErrors,
    validatePartySnapshot,
    validateTabs,
  } = getValidationParams({
    input,
  });

  const accumulator = createAccumulator({ validateFields, additionalErrors });

  validateFields.push(
    ['conveyance', 'conveyanceName'],
    ['conveyance', 'modeOfTransport'],
    ['conveyance', 'tripNumber'],
    ['conveyance', 'grossWeight'],
    ['departure', 'exportCountryCode'],
    ['departure', 'exportDate'],
    ['departure', 'portOfLadingCode'],
    ['arrival', 'portOfUnladingCode'],
    ['arrival', 'importDate'],
    ['arrival', 'usDestinationStateCode'],
    ['group', 'id'],
    ['group', 'version'],
    ['operator', 'id'],
    ['operator', 'version'],
    ['arrival', 'inBond', 'portOfEntryCode'], // N.B. it's okay to always add these fields. They'll only validate if they are rendered.
    ['arrival', 'inBond', 'estimatedEntryDate'],
    ['arrival', 'inBond', 'initiationDate'],
    ...manifestFieldsToValidate(masterBills),
  );

  const codes = getCodes();

  // Note: Validate the entryNumber format using `usCbpEntryNumber` from
  // '@xbcb/regex' when we add the EntryNumber component from legacy

  // we need to validate iorNumber in addition to the normal name and address for ior and consignee
  const iorAndConsigneeFields = [
    ...defaultCreatePartyValidatorEmbeddedFields,
    ['iorNumber', 'value'],
    ['iorNumber', 'type'],
  ];

  let formalEntryRequiredByHTS = false;
  let totalValue = 0;

  let adCvdEntry = false;
  if (validateTabs.commercialInvoices) {
    validatePartySnapshot(
      ['ior', 'usIor', 'id'],
      formatRecordName({
        recordType: RecordType.US_IOR,
        accountType,
      }),
      iorAndConsigneeFields,
    );
    if (!consignee?.sameAsIor)
      validatePartySnapshot(
        ['consignee', 'usConsignee', 'id'],
        formatRecordName({
          recordType: RecordType.US_CONSIGNEE,
          accountType,
        }),
        iorAndConsigneeFields,
      );

    // There must always be at least one invoice
    if (!invoices || invoices.length === 0) {
      additionalErrors.push({
        title: 'Commercial Invoices Missing',
        messages: [`At least one commercial invoice must be provided`],
      });
    }

    const commercialInvoiceErrorMessages = [];
    const grossWeight = get(input, ['conveyance', 'grossWeight']);
    let netWeight = 0;
    invoices?.forEach((invoice: any, invoiceIndex: number) => {
      if (largeEntryUXEnabled) {
        accumulator([validateInvoice(invoice, invoiceIndex)]);

        // Collect all product weight (product quantity * unit net weight)
        // to later validate against the gross weight in conveyance info
        invoice.products?.forEach((product: any) => {
          const productQuantity = product.quantity;
          product.lines?.forEach((line: any) => {
            line.tariffs?.forEach((tariff: any, tariffIndex: number) => {
              const validateTariff = tariffIndex === 0 || tariff.htsNumber;
              if (productQuantity && validateTariff) {
                tariff.unitReportingQuantities
                  ?.filter(
                    (quantity: { unit?: string; value?: number }) =>
                      quantity.unit === 'KG' && quantity.value,
                  )
                  .forEach((quantity: any) => {
                    netWeight += productQuantity * quantity.value;
                  });
              }
            });
          });
        });
      } else {
        const invoiceDisplayIndex = invoiceIndex + 1;
        const invoiceNamePath = ['invoices', invoiceIndex];
        validateFields.push([...invoiceNamePath, 'invoiceNumber']);
        validateFields.push([...invoiceNamePath, 'value', 'value']);
        validateFields.push([...invoiceNamePath, 'value', 'currency']);

        const currency = get(input, [...invoiceNamePath, 'currency']);
        if (currency !== 'USD') {
          const currencyRateNamePath = [...invoiceNamePath, 'currencyRate'];
          validateFields.push(currencyRateNamePath);
        }

        const roundedInvoiceValueValue = roundNumber(
          invoice.value?.value || 0,
          2,
        );
        let productsValue = 0;

        invoice.adjustments?.forEach(
          (adjustment: any, adjustmentIndex: number) => {
            const adjustmentNamePath = [
              ...invoiceNamePath,
              'adjustments',
              adjustmentIndex,
            ];
            validateFields.push([...adjustmentNamePath, 'type']);
            validateFields.push([...adjustmentNamePath, 'value', 'value']);
            // adjustment currency should be the same as invoice currency. Since this
            // is a "US" consumptionEntry we assume this is the case as it is not
            // changeable from the UI and should be `USD`.
            validateFields.push([...adjustmentNamePath, 'value', 'currency']);
            validateFields.push([...adjustmentNamePath, 'description']);

            const adjustmentType = adjustment.type;
            const adjustmentValue = adjustment.value?.value;
            if (adjustmentValue && adjustmentType) {
              productsValue +=
                adjustmentType ===
                UsConsumptionEntryCommercialInvoiceAdjustmentType.ADD
                  ? adjustmentValue
                  : -1 * adjustmentValue;
            }
          },
        );

        validatePartySnapshot(
          [...invoiceNamePath, 'seller', 'supplier', 'id'],
          `Invoice ${invoiceDisplayIndex} seller`,
          [...defaultCreatePartyValidatorEmbeddedFields, ['mid']],
        );

        // There must always be at least one product
        if (!invoice.products || invoice.products.length === 0) {
          additionalErrors.push({
            title: 'Commercial Invoice Products Missing',
            path: ['invoices', invoiceIndex],
            messages: [
              `Invoice ${invoiceDisplayIndex}, at least one product must be provided`,
            ],
          });
        }

        invoice.products?.forEach((product: any, productIndex: number) => {
          const productDisplayIndex = productIndex + 1;
          const productNamePath = [
            ...invoiceNamePath,
            'products',
            productIndex,
          ];
          validateFields.push([...productNamePath, 'quantity']);

          const productQuantity = product.quantity;
          if (productQuantity === 0) {
            additionalErrors.push({
              title: 'Invalid Commercial Invoice Product Quantity',
              path: [...productNamePath, 'quantity'],
              messages: [
                `Invoice ${invoiceDisplayIndex}, product ${productDisplayIndex}, quantity must be at least 1`,
              ],
            });
          }

          const totalValueNamePath = [...productNamePath, 'totalValue'];
          validateFields.push([...totalValueNamePath, 'value']);
          validateFields.push([...totalValueNamePath, 'currency']);

          const totalAssistNamePath = [...productNamePath, 'totalAssist'];

          const productTotalValue = get(input, [
            ...totalValueNamePath,
            'value',
          ]);
          const roundedProductTotalValue = roundNumber(
            productTotalValue || 0,
            2,
          );
          if (typeof productTotalValue === 'number') {
            productsValue += productTotalValue || 0;
            totalValue += productTotalValue;
          }

          let linesValue = 0;
          product?.lines?.forEach((line: any, lineIndex: number) => {
            // TODO validate poNumber (used to be required if `linePORequired`
            // was set on importer)

            const { adCase, cvCase, origin, taxOptions, feeOptions, tariffs } =
              line;
            if (adCase?.id || cvCase?.id) {
              adCvdEntry = true;
            }
            const lineDisplayIndex = lineIndex + 1;
            const lineNamePath = [...productNamePath, 'lines', lineIndex];
            validateFields.push([...lineNamePath, 'description']);
            validateFields.push([...lineNamePath, 'origin', 'countryCode']);
            // `stateCode` only required for `CA`
            if (origin?.countryCode === 'CA') {
              validateFields.push([...lineNamePath, 'origin', 'stateCode']);
            }

            const isManufacturerFromChina =
              line.manufacturer?.address?.countryCode === 'CN';
            const manufacturerValidationFields = [
              ...defaultCreatePartyValidatorEmbeddedFields,
              ['mid'],
            ];
            if (isManufacturerFromChina) {
              manufacturerValidationFields.push(['address', 'postalCode']);
            }
            validatePartySnapshot(
              [...lineNamePath, 'manufacturer', 'supplier', 'id'],
              `Invoice ${invoiceDisplayIndex}, product ${productDisplayIndex}, line ${lineDisplayIndex} manufacturer`,
              manufacturerValidationFields,
            );

            // TODO investigate. I had to explicitly validate the first htsNumber. I think that it's related to the fact that we actually render the htsNumber input in the LineItem component, even though the name path is for the tariff level.
            validateFields.push([...lineNamePath, 'tariffs', 0, 'htsNumber']);

            // TODO add validations for ad / cvd case rate once it can be entered
            // from the UI. See where `mapCase` function in `constructCbpEntryData`
            // throws an error for details on how to validate

            // TODO add validations to check if adjustments are found but missing
            // `linesTotalValue` (manually calculated in `constructCbpEntryData`)

            taxOptions?.forEach(
              (
                { disclaim, type }: { disclaim?: boolean; type?: string },
                taxOptionIndex: number,
              ) => {
                if (disclaim) return;

                validateFields.push([
                  ...lineNamePath,
                  'taxOptions',
                  taxOptionIndex,
                  'type',
                ]);

                // TODO in constructCbpEntryData line 847 we also check for a
                // taxRecord. If we eventually use `getHtsDetails` (which is
                // used to get this taxRecord), we should validate it here to
                // prevent an unhandled error. We also check that `taxRecord`
                // for `taxRecord.duty === 'X' && !rate` and throw an error if
                // this is the case. We should validate this as well. Lastly,
                // we use the `taxRecord` to calculate the duty which may also
                // throw an error (see the duty lib in entry-utils) for more
                // info. We should validate these conditions

                if (!type) return;
                // We passed the fee.class to get the taxOptionType. Now we
                // need to retrieve the fee.class back
                const feeClass = usCbpEntryFeeCodeToTypeMap.reverseGet(type);
                // We should also check !disclaim, but we already return early
                // if disclaim is true
                if (codes.CBP.CATAIR.taxClass[feeClass]) {
                  validateFields.push([
                    ...lineNamePath,
                    'taxOptions',
                    taxOptionIndex,
                    'rate',
                  ]);
                }
              },
            );

            feeOptions?.forEach((feeOption: any, feeOptionIndex: number) => {
              validateFields.push([
                ...lineNamePath,
                'feeOptions',
                feeOptionIndex,
                'type',
              ]);

              // TODO in constructCbpEntryData line 915 we also check for a
              // feeRecord. If we eventually use `getHtsDetails` (which is
              // used to get this feeRecord), we should validate it here to
              // prevent an unhandled error. Lastly, we use the `taxRecord`
              // to calculate the duty which may also throw an error (see the
              // duty lib in entry-utils) for more info. We should validate
              // these conditions.
            });

            // There must always be at least one tariff
            if (!tariffs || tariffs.length === 0) {
              additionalErrors.push({
                title: 'Commercial Invoice Tariffs Missing',
                path: lineNamePath,
                messages: [
                  `Invoice ${invoiceDisplayIndex}, product ${productDisplayIndex}, line ${lineDisplayIndex} at least one tariff must be provided`,
                ],
              });
            }

            tariffs?.forEach((tariff: any, tariffIndex: number) => {
              const tariffNamePath = [...lineNamePath, 'tariffs', tariffIndex];
              validateFields.push([...tariffNamePath, 'unitValue', 'value']);
              validateFields.push([...tariffNamePath, 'unitValue', 'currency']);
              // validateFields.push([...tariffNamePath, 'hts', 'id']);
              validateFields.push([...tariffNamePath, 'htsNumber']);
              // TODO also handle formalHtsNumbeRequired when using htsId instead of htsNumber
              // this logic is DUPLICATED from constructCbpEntryData lines 372-378, eventually consolidate, for now need to sync if there are any changes to the regulations
              // Note, we also throw an error if getHtsDetails does not return
              // details for the tariff. We may eventually need to validate.
              // We use the result to calculate the duty which may also throw
              // an error (see the duty lib and dutyComputation lib in
              // entry-utils) for more info. We should validate these conditions.
              // Lastly, we should validate for the `taxRecord`, `feeRecord`, and
              // length of `unitReportingQuantities` (all mentioned below)
              const htsNumber = tariff.htsNumber?.replace(/\./g, '');
              if (
                (htsNumber && htsNumber.startsWith('99')) ||
                '98110060' === htsNumber
              ) {
                formalEntryRequiredByHTS = true;
              }

              const validateTariff = tariffIndex === 0 || htsNumber;
              const tariffUnitValueValue = tariff.unitValue?.value;
              const htsHasUnitValue =
                tariff.unitValue?.value || realHTS(htsNumber);
              if (
                validateTariff &&
                productQuantity &&
                tariffUnitValueValue &&
                htsHasUnitValue
              ) {
                const amount = productQuantity * tariffUnitValueValue;
                if (!isNaN(amount)) linesValue += amount;
              }

              if (tariff.unitAssist) {
                if (hasInvalidUnitAssist(tariff.unitAssist)) {
                  const tariffDisplayIndex = tariffIndex + 1;
                  additionalErrors.push({
                    title: 'Tariff Unit Assist is Invalid',
                    path: [...tariffNamePath, tariffIndex],
                    messages: [
                      `Invoice ${invoiceDisplayIndex}, product ${productDisplayIndex}, line ${lineDisplayIndex}, tariff ${tariffDisplayIndex} has a negative number or zero for its unit assist`,
                    ],
                  });
                } else {
                  validateFields.push([...totalAssistNamePath, 'value']);
                  validateFields.push([...totalAssistNamePath, 'currency']);
                }
              }

              // TODO validate that at least 1 unitReportingQuantity is provided
              // using the logic in constructCbpEntryData starting on line 1071
              // once we eventually use `getHtsDetails` to get the `htsRecord`.
              // Additionally, we should use this to check for missing / wrong uom
              // codes. There are three checks for missing / wrong uom codes inside
              // the `htsRecord.uom` conditional block in `constructCbpEntryData`.

              tariff.unitReportingQuantities?.forEach(
                (quantity: any, quantityIndex: number) => {
                  validateFields.push([
                    ...tariffNamePath,
                    'unitReportingQuantities',
                    quantityIndex,
                    'value',
                  ]);
                  validateFields.push([
                    ...tariffNamePath,
                    'unitReportingQuantities',
                    quantityIndex,
                    'unit',
                  ]);

                  const value = quantity.value;
                  if (
                    validateTariff &&
                    quantity.unit === 'KG' &&
                    value &&
                    productQuantity
                  ) {
                    netWeight += productQuantity * value;
                  }
                },
              );
            });
          });

          if (roundedProductTotalValue !== roundNumber(linesValue, 2)) {
            commercialInvoiceErrorMessages.push(
              `The value of product ${productDisplayIndex} (${roundedProductTotalValue}) on invoice ${invoiceDisplayIndex} (${
                invoice.invoiceNumber
              }) does not match the sum of unit value * quantity (${roundNumber(
                linesValue,
                2,
              )})`,
            );
          }
        });

        if (roundedInvoiceValueValue !== roundNumber(productsValue, 2)) {
          commercialInvoiceErrorMessages.push(
            `The value of invoice ${invoiceDisplayIndex} (${
              invoice.invoiceNumber
            }) (${roundedInvoiceValueValue}) does not match the sum of product values (${roundNumber(
              productsValue,
              2,
            )})`,
          );
        }
      }
    });

    if (netWeight > grossWeight) {
      commercialInvoiceErrorMessages.push(
        `The sum of net weights (${roundNumber(
          netWeight,
          2,
        )} KG) is greater than the gross weight (${roundNumber(
          grossWeight,
          2,
        )} KG)`,
      );
    }

    if (commercialInvoiceErrorMessages.length) {
      additionalErrors.push({
        title: 'Commercial invoice errors',
        messages: commercialInvoiceErrorMessages,
      });
    }
  }

  const { importDate, portOfUnladingCode, inBond = {} } = arrival;
  const { exportDate } = departure;
  const { initiationDate, estimatedEntryDate, portOfEntryCode } = inBond;
  const { modeOfTransport } = conveyance;

  const railSCACCodes = ['CNRU', 'CPRS'];
  if (validateTabs.transportation) {
    const selectedModeOfTransport = input?.conveyance?.modeOfTransport;
    const isValidModeOfTransport = Object.values(ModeOfTransport).includes(
      selectedModeOfTransport,
    );
    if (selectedModeOfTransport && !isValidModeOfTransport) {
      additionalErrors.push({
        title: 'Mode of Transport',
        messages: ['Mode of transport is not supported'],
      });
    }

    if (modeOfTransport !== ModeOfTransport.RAIL) {
      const railMasterBills = (masterBills || []).filter((masterBill: any) =>
        railSCACCodes.includes(masterBill?.number?.substr(0, 4)),
      );
      if (railMasterBills.length) {
        additionalErrors.push({
          title: 'Master Bills',
          messages: [
            `Master ${pluralize(
              'bill',
              railMasterBills.length,
            )} ${railMasterBills
              .map(({ number }: any) => number)
              .join(', ')} begin${
              railMasterBills.length === 1 ? 's' : ''
            } with a Rail-only SCAC Code. Please change the MOT to Rail before submitting.`,
          ],
        });
      }
    }
    if (
      portOfUnladingCode &&
      portOfEntryCode &&
      portOfUnladingCode === portOfEntryCode
    )
      additionalErrors.push({
        title: 'Port of Entry',
        messages: [
          `The port of entry ${portOfEntryCode} must be different than the port of unlading`,
        ],
      });
    if (importDate && exportDate && exportDate.isAfter(importDate, 'day'))
      additionalErrors.push({
        title: 'Export date',
        messages: [
          `The export date ${exportDate.format(
            'YYYY-MM-DD',
          )} cannot be after the import date ${importDate.format(
            'YYYY-MM-DD',
          )}`,
        ],
      });
    if (importDate && initiationDate && estimatedEntryDate) {
      const messages = [];
      if (importDate.isAfter(initiationDate, 'day')) {
        messages.push(
          `The import date ${importDate.format(
            'YYYY-MM-DD',
          )} cannot be after the IT date ${initiationDate.format(
            'YYYY-MM-DD',
          )}`,
        );
      }
      if (initiationDate.isAfter(estimatedEntryDate, 'day')) {
        messages.push(
          `The IT date ${initiationDate.format(
            'YYYY-MM-DD',
          )} cannot be after the estimated entry date ${estimatedEntryDate.format(
            'YYYY-MM-DD',
          )}`,
        );
      }
      if (messages.length) {
        additionalErrors.push({
          title: 'In-bond date',
          messages,
        });
      }
    }
  }

  // TODO once bond bot is set up, only require these fields for operator users.
  // TODO consolidate w/ isInformalEntry in entry-utils
  // Shipments of merchandise not exceeding $2,500 in value (except for articles valued in excess of $250 classified in Chapter 99, Subchapters III and IV, HTSUS) may be entered under informal entry;
  // More information: https://www.law.cornell.edu/cfr/text/19/143.21
  let informalEntry = false;
  if (
    validateTabs.bond &&
    (totalValue > 2500 ||
      (formalEntryRequiredByHTS && totalValue > 250) ||
      adCvdEntry)
  ) {
    // these fields are only present and required in the form if there's no continuous bond
    validateFields.push(['singleTransactionBond', 'amount']);
    validateFields.push(['singleTransactionBond', 'suretyCode']);
    validateFields.push(['singleTransactionBond', 'accountNumber']);
  } else {
    // else it's an informal entry, no bond required
    informalEntry = true;
  }

  return {
    additionalErrors,
    validateFields,
    validatePartySnapshot,
    validateTabs,
    informalEntry, // informalEntry is used by PSC validation lib
  };
};

import { AnyObject } from '@xbcb/shared-types';
import download from 'libs/download';

export type DownloadProps = {
  headers: string[];
  data: AnyObject[];
  fileName: string;
  returnFile?: boolean;
  headerNameMap?: Map<string, string>;
  addonData?: AnyObject[];
  addonHeaders?: string[];
};
/**
 * Sanitizes a value to prevent code injection by adding a single quote to the value if it starts with certain characters.
 * @param {*} value - The value to be sanitized.
 * @return {*} The sanitized value.
 */
const sanitizer = (value: any) => {
  if (value) {
    if (
      ['=', '+', '-', '@'].some((char) => value.toString().startsWith(char))
    ) {
      value = `'${value}`; // simply adding a single quote to prevent code injection.
    }
  }
  return value;
};

/**
 * Downloads CSV data based on the provided props.
 * @param {DownloadProps} props - The download properties.
 * @return {string | void} The CSV data as a string if returnFile is true, otherwise initiates a download.
 */
export default ({
  headers,
  data,
  fileName,
  returnFile,
  headerNameMap,
  addonData,
  addonHeaders,
}: DownloadProps): string | void => {
  let combinedData: AnyObject[] = [];

  if (addonData) {
    data.forEach((dataRow) => {
      const matchingAddonData = addonData.filter(
        (addonDataRow) => addonDataRow.id === dataRow.id,
      );
      if (matchingAddonData.length > 0) {
        matchingAddonData.forEach((addonDataRow) => {
          combinedData.push({ ...dataRow, ...addonDataRow });
        });
      } else {
        combinedData.push({ ...dataRow });
      }
    });
  } else {
    combinedData = [...data];
  }

  const csv = combinedData.map((row) => {
    const rowValues = headers.map((fieldName) => {
      const value = sanitizer(row[fieldName]);
      return `"${(value || '').toString().replace(/"/g, '""')}"`;
    });

    if (addonHeaders) {
      addonHeaders.forEach((header: string) => {
        const value = sanitizer(row[header]);
        rowValues.push(`"${(value || '').toString().replace(/"/g, '""')}"`);
      });
    }

    return rowValues.join(',');
  });

  // Construct headers using headerNameMap or headers directly
  const csvHeaders = headers.map(
    (header) => `"${headerNameMap?.get(header) || header}"`,
  );

  if (addonHeaders) {
    addonHeaders.forEach((header: string) => {
      csvHeaders.push(`"${headerNameMap?.get(header) || header}"`);
    });
  }

  csv.unshift(csvHeaders.join(','));

  const finalCsv = csv.join('\r\n');

  if (returnFile) {
    return finalCsv;
  }

  return download(finalCsv, `${fileName}.csv`);
};

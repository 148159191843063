import { client, cachePersistor } from '@xbcb/apollo-client';

export const apolloLogout = async (resetQueries = false): Promise<void> => {
  await cachePersistor.purge();
  if (resetQueries) {
    // This clears the in-memory cache and re-fetches all active queries
    await client.resetStore();
  } else {
    // Clears the in-memory cache and that's it.
    // Helpful for logging out, since you don't need queried data.
    await client.clearStore();
  }
};

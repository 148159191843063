import { WorkOrderTaskType } from '@xbcb/work-order-types';
import { KebabMenuItemKey } from '@xbcb/ui-types';
import { WorkOrderTaskEvaluatedState } from 'libs/evaluateWorkOrderTaskState';

type KebabMenuItemList = KebabMenuItemKey[];
const kebabMenuItemMapForWorkOrderTask: Record<string, KebabMenuItemList> = {
  [WorkOrderTaskType.US_CONSUMPTION_ENTRY_BUILD_INVOICE]: [
    KebabMenuItemKey.MEMOS,
    KebabMenuItemKey.INQUIRE_WORK_ORDER,
    KebabMenuItemKey.UPLOAD_INVOICE,
  ],
  [WorkOrderTaskType.US_CONSUMPTION_ENTRY_BUILD_TRANSPORTATION_AND_INVOICE]: [
    KebabMenuItemKey.ABI_DATA,
    KebabMenuItemKey.MEMOS,
    KebabMenuItemKey.INQUIRE_WORK_ORDER,
    KebabMenuItemKey.UPLOAD_INVOICE,
    KebabMenuItemKey.CHANGE_MOT,
  ],
};

// In work order task mode, always show kebab menu items "ABI Data", "Memos", "Assign", "Inquire Work Order",
// "Unblock Work Order", "Approve Entry Confirmation" and "Delete Release".
// For the other kebab menu items, use this function so that they only show when the task is activily in progress.
export const checkKebabMenuItemForWorkOrderTask = (
  workOrderTaskType?: WorkOrderTaskType,
  workOrderTaskEvaluatedState?: WorkOrderTaskEvaluatedState,
  kebabMenuItemKey?: KebabMenuItemKey,
): boolean => {
  if (!workOrderTaskType) return true;

  const hasTaskStarted =
    workOrderTaskEvaluatedState === WorkOrderTaskEvaluatedState.STARTED;

  if (kebabMenuItemKey && kebabMenuItemMapForWorkOrderTask[workOrderTaskType]) {
    return (
      hasTaskStarted &&
      kebabMenuItemMapForWorkOrderTask[workOrderTaskType].includes(
        kebabMenuItemKey,
      )
    );
  } else {
    return hasTaskStarted;
  }
};

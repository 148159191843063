import { createAction } from '@reduxjs/toolkit';
import type { NamePath } from '@xbcb/ui-types';
import type { Errors } from 'types';

export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';
export const HANDLE_REMOVE_ITEM = 'HANDLE_REMOVE_ITEM';

export interface SetValidationErrorsAction {
  recordId: string;
  validationResult: { errorMap: Errors; validateFields: NamePath[] };
}

export interface HandleRemoveItemAction {
  recordId: string;
  path: NamePath;
  indices: number[];
}

export const setValidationErrors = createAction<SetValidationErrorsAction>(
  SET_VALIDATION_ERRORS,
);

export const handleRemoveItems =
  createAction<HandleRemoveItemAction>(HANDLE_REMOVE_ITEM);

import { ProductUsPgaInput } from '__generated__/graphql';
import { PgaFlag } from 'libs/pgaHelpers';
import { AppRecordValidateResult } from '@xbcb/ui-types';
import {
  PgaValidationArgs,
  PgaValidator,
} from 'libs/entryValidation/pga/types';

export class BrandNameValidator implements PgaValidator<ProductUsPgaInput> {
  isApplicable({ pgaFlag }: PgaValidationArgs<ProductUsPgaInput>): boolean {
    return PgaFlag.subjectToPesticides(pgaFlag);
  }

  validate(_: PgaValidationArgs<ProductUsPgaInput>): AppRecordValidateResult {
    return {
      validateFields: [['tradeName']],
    };
  }
}

import React from 'react';
import { Input, Checkbox, Form } from 'antd';
import { Select, Option, FormItem } from '@xbcb/form-item-components';
import { selectFilter, getCodes, show, shouldUpdate } from '@xbcb/ui-utils';
import Mask from 'libs/mask';
import { FormInstance } from 'antd/lib/form';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { StyledLpcoDiv, StyledFormItem } from './styles';
import { clearFields } from 'libs/clearFields';

interface LpcoProps {
  fullNamePath: NamePath;
  localNamePath: NamePath;
  type: any;
  form?: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  exemptions?: any;
  multiple?: boolean;
  regex?: any;
}

const Lpco = ({
  form,
  fullNamePath,
  localNamePath,
  readOnly,
  required,
  disabled,
  exemptions,
  multiple,
  regex,
  type,
}: LpcoProps) => {
  const codes = getCodes();
  form = Form.useFormInstance();

  const { LPCO } = codes.CBP.CATAIR.PGA;
  const { type: permits } = LPCO;
  const permit = permits[type] || {};
  const name = permit.name;
  const allowedExemptions = permit.allowedExemptions;

  const licenseMask = regex && new Mask(regex);

  const { getFieldValue } = form;

  const exemptionOptions =
    allowedExemptions &&
    allowedExemptions.map((code: string) => (
      <Option key={code} value={code}>
        {code} - {exemptions[code]}
      </Option>
    ));

  return (
    <StyledLpcoDiv>
      <StyledFormItem
        shouldUpdate={shouldUpdate([[...fullNamePath, 'exempt']])}
      >
        {() => {
          const exempt = getFieldValue([...fullNamePath, 'exempt']);
          return (
            <>
              {!exempt || !allowedExemptions ? (
                <FormItem
                  $itemSize={CssSize.SHORT}
                  label={name}
                  // TODO: Need to re-evaulate this pattern. UsTtbCertificateOfLabelApproval is NOT an Lpco, so should be its own component.
                  name={
                    multiple
                      ? [...localNamePath, 'numbers']
                      : [...localNamePath, 'number']
                  }
                  rules={[{ required, message: ' ' }]}
                  getValueFromEvent={(event: any) => {
                    // TODO get mask working for arrays of values, too
                    // select doesn't pass an event, it passes an array of it's selected values.
                    const value = Array.isArray(event)
                      ? event
                      : event.target.value;
                    return licenseMask
                      ? licenseMask.handleChange(event)
                      : value;
                  }}
                  $readOnly={readOnly}
                >
                  {multiple ? (
                    <Select
                      disabled={disabled}
                      allowClear
                      dropdownMatchSelectWidth={false}
                      mode="tags"
                      tokenSeparators={[',', ' ', '/']}
                      notFoundContent="Type then press enter to add"
                    />
                  ) : (
                    <Input aria-label={`${name} Textbox`} disabled={disabled} />
                  )}
                </FormItem>
              ) : (
                <FormItem
                  $itemSize={CssSize.SHORT}
                  label="Exemption reason"
                  name={[...localNamePath, 'exemptionCode']}
                  rules={[{ required, message: ' ' }]}
                  $readOnly={readOnly}
                >
                  <Select
                    disabled={disabled}
                    showSearch
                    allowClear
                    filterOption={selectFilter}
                    notFoundContent="None Found"
                    dropdownMatchSelectWidth={false}
                  >
                    {exemptionOptions}
                  </Select>
                </FormItem>
              )}
            </>
          );
        }}
      </StyledFormItem>
      {allowedExemptions &&
        show({ readOnly, form, field: [...fullNamePath, 'exempt'] }) && (
          <FormItem
            name={[...localNamePath, 'exempt']}
            valuePropName="checked"
            $readOnly={readOnly}
            $spaceTop
          >
            <Checkbox
              onChange={clearFields({
                form,
                fullNamePath,
                fields: ['exemptionCode'],
              })}
              disabled={disabled}
            >
              Exempt?
            </Checkbox>
          </FormItem>
        )}
    </StyledLpcoDiv>
  );
};

export default Lpco;

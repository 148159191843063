import styled from 'styled-components';

export const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSpacerDiv = styled.div`
  padding: 2.22em;
`;

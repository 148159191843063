import { createSubMenuOptions } from 'libs/createSubMenuOptions';
import { WorkOrderType } from '@xbcb/work-order-types';
import { capitalCase, sentenceCase } from 'change-case';

export const createFlipSubMenuOptions = (
  outputWorkOrderTypes: WorkOrderType[],
) =>
  createSubMenuOptions(
    outputWorkOrderTypes.map((wo: WorkOrderType) => ({
      key: wo,
      displayName: capitalCase(sentenceCase(wo)),
    })),
  );

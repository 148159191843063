import {
  recordFields,
  recordActionFields,
  addressFields,
} from '@xbcb/shared-queries';
import {
  continuousBondsFields,
  usContinuousCustomsBondFragment,
} from '@xbcb/party-queries';
import { inquiryFields } from './inquiryFields';

export const usIorActivationFragment = `
fragment usIorActivationFields on UsIorActivation {
  ...recordFields
  broker {
    usCustomsBroker {
      id
    }
  }
  group {
    id
    forwarders {
      forwarder {
        id
        name
      }
    }
  }
  operator {
    id
  }
  ior {
    usIor {
      id
      version
      status
      name
      activationStatus
      activations {
        status
        customsBroker {
          id
        }
      }
      shippers {
        nodes {
          status
          forwarders {
            nodes {
              id
              name
            }
          }
        }
      }
      addresses {
        physical {
          ...addressFields
        }
        mailing {
          ...addressFields
        }
      }
      businessStructure
      cbpStatus
      cbp5106RejectionReasons {
        name
        code
      }
      industry
      iorNumber {
        type
        value
      }
      cbpNumberHelp
      cbpNumberReasons
      documents {
        deleted {
          time
        }
        tags {
          key
          value
        }
        documentTags
      }
      documentSignRequests {
        ... on DocumentSignRequest {
          url
          status
          documentTags
          created {
            time
          }
          signed {
            time
          }
        }
      }
    ${continuousBondsFields}
    }
  }
  milestones {
    id
    name
    occurenceTime
    source
    created {
      client {
        name
      }
    }
  }
  stateMachine {
    id
    version
  }
  status
  ${inquiryFields}
}
`;

export const usIorActivationFragments = `
${usIorActivationFragment}
${recordFields}
${recordActionFields}
${addressFields}
${usContinuousCustomsBondFragment}
`;

import { WorkOrderType } from '@xbcb/work-order-types';
import { UsFilingType } from '@xbcb/compliance-types';

export const workOrderTypeToUsFilingType = (
  workOrderType: WorkOrderType,
): UsFilingType => {
  switch (workOrderType) {
    case WorkOrderType.UsType86Entry:
      return UsFilingType.US_TYPE86_ENTRY;
    case WorkOrderType.UsConsumptionEntry:
      return UsFilingType.US_CONSUMPTION_ENTRY;
    default:
      throw new Error(
        `Cannot determine filing type for work order type ${workOrderType}`,
      );
  }
};

import { createReducer } from '@reduxjs/toolkit';
import { setGlobalSearchText } from '../../actions';

const globalSearchDefaults = {
  searchText: '',
};

export default createReducer(globalSearchDefaults, (builder) =>
  builder.addCase(setGlobalSearchText, (state, { payload }) => ({
    ...state,
    searchText: payload,
  })),
);

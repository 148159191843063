import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-right: ${({ theme }) => theme.spacing.space_4};
`;

export const StyledAdCvdCaseLinkDiv = styled.div`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.space_4};
  a {
    line-height: 32px;
    color: ${({ theme }) => theme.palette.purples.gamma};
  }
`;

import { WorkOrderTaskTypeGroup } from '@xbcb/ui-types';
import { WorkOrderTaskType } from '@xbcb/work-order-types';

export const workOrderTaskTypeGroups = {
  [WorkOrderTaskTypeGroup.STEPS_OPTIONAL]: [
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_BUILD_INVOICE,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_COMPLETE_INVOICE,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_DOCUMENT_TAGGING,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_SHIPMENT_INFO,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_REVISION,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_CBP_EXAM,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_CBP_HOLD,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_EXCEPTION,
    WorkOrderTaskType.US_ISF_EXCEPTION,
  ],
  [WorkOrderTaskTypeGroup.STEPS_REQUIRED]: [
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_PROTEST,
  ],
  [WorkOrderTaskTypeGroup.SYSTEM_ONLY]: [
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_CARGO_RELEASE_EXCEPTION,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_FILING,
    // As of now PGA Hold is not system complete and we want to show the complete task button for it
    // WorkOrderTaskType.US_CONSUMPTION_ENTRY_PGA_HOLD,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_PGA_REJECTION,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_POST_SUMMARY_CORRECTION,
    WorkOrderTaskType.US_ISF_FILING,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_COMPLETION_SUBMISSION,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_CENSUS_WARNING,
    WorkOrderTaskType.ACQUIRE_US_IOR_CONTINUOUS_BOND,
    WorkOrderTaskType.US_IOR_CONTINUOUS_BOND_CHARGE_BOND_RENEWAL_EXCEPTION,
  ],
};

export const determineWorkOrderTaskTypeGroup = (
  taskType?: WorkOrderTaskType,
) => {
  if (!taskType) return '';
  return Object.entries(workOrderTaskTypeGroups).reduce(
    (group, [workOrderTaskTypeGroup, workOrderTaskTypes]) => {
      if (group) return group;
      const isPartOfGroup = workOrderTaskTypes.some(
        (workOrderTaskType: WorkOrderTaskType) =>
          workOrderTaskType === taskType,
      );
      if (isPartOfGroup) return workOrderTaskTypeGroup;
      return group;
    },
    '',
  );
};

import { get } from 'lodash';
import { AnyObject } from '@xbcb/shared-types';
import { HTS_REGEX } from './constants';
import { formatHTS } from './formatHTS';

export interface HtsHierarchyItem {
  id: string;
  startDate?: string;
  endDate?: string;
}

export const getHtsDataFromHierarchy = (
  htsNumber: string,
  hierarchy: AnyObject,
): AnyObject | undefined => {
  const segments = formatHTS(htsNumber).match(HTS_REGEX);
  if (!segments) return;
  return get(hierarchy, segments);
};

import { AppRecordValidateResult } from '@xbcb/ui-types';
import { VneFieldValidator, VneValidationArgs } from '../types';
import { PgaFlag } from 'libs/pgaHelpers';

export class BaseVneValidator implements VneFieldValidator {
  isApplicable({ pgaFlag }: VneValidationArgs): boolean {
    return PgaFlag.subjectToVehiclesAndEngines(pgaFlag);
  }

  validate(_: VneValidationArgs): AppRecordValidateResult {
    return {
      validateFields: [['regulatoryCategory'], ['vneImportCode']],
    };
  }
}

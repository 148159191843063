import styled from 'styled-components';
import { Option, FormItem, InfoTooltip } from '@xbcb/form-item-components';

export const StyledInlineDiv = styled.div`
  display: flex;
  flex-flow: wrap;
`;

export const StyledLeftSpaceInlineDiv = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-left: ${({ theme }) => theme.spacing.space_3};
`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.spacing.space_3};
`;

export const StyledBothSpaceInfoTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.spacing.space_3};
  margin-right: ${({ theme }) => theme.spacing.space_3};
`;

export const StyledDOTHeader = styled.h4`
  margin-left: ${({ theme }) => theme.spacing.space_3};
`;

export const StyledOption = styled(Option)`
  &&&.ant-select-item-option-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0;
`;

export default {
  EP1: { label: 'EPA ODS', equivalentFlags: ['EP2'] },
  EP3: { label: 'EPA VNE', equivalentFlags: ['EP4'] },
  EP5: { label: 'EPA PS', equivalentFlags: ['EP6'] },
  EP7: { label: 'EPA TS', equivalentFlags: ['EP8'] },
  FS3: { label: 'FSI', equivalentFlags: ['FS4'] },
  NM1: { label: 'NMF 370', equivalentFlags: ['NM2'] },
  NM3: { label: 'NMF AMR', equivalentFlags: ['NM4'] },
  NM5: { label: 'NMF HMS', equivalentFlags: ['NM6'] },
  NM8: { label: 'NMF SIM', equivalentFlags: [] }, // what happened to NM7? This tag technically means something "is required".
  DT1: { label: 'NHT', equivalentFlags: ['DT2'] },
  AQ1: { label: 'APH', equivalentFlags: ['AQ2'] },
  AL1: { label: 'APH APL', equivalentFlags: ['AL2'] },
  FD1: { label: 'FDA', equivalentFlags: ['FD2', 'FD3', 'FD4'] },
  FD3: { label: 'FDA PN', equivalentFlags: ['FD1', 'FD2', 'FD4'] },
  AM1: { label: 'AMS EG', equivalentFlags: ['AM2'] },
  AM3: { label: 'AMS MO', equivalentFlags: ['AM4'] },
  AM6: { label: 'AMS PN', equivalentFlags: [] }, // what happened to AM5? This tag technically means something "is required".
  TB1: { label: 'TTB', equivalentFlags: ['TB2', 'TB3'] },
  OM1: { label: 'OMC', equivalentFlags: ['OM2'] },
  FW1: { label: 'FWS', equivalentFlags: ['FW2', 'FW3'] },
  DE1: { label: 'DEA', equivalentFlags: [] },
};

import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

const customStyles = `
  text-decoration: underline;
  color: white;
`;

export const StyledDiv = styled.div`
  display: inline-flex;
  align-items: center;
  height: 35px;
  margin: 0 ${CssSpace.SPACE_4};
`;

export const StyledLink = styled(Link)`
  ${customStyles};

  :hover {
    ${customStyles};
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;

  img {
    margin: 0;
    margin-right: ${CssSpace.SPACE_3};
    height: 18px;
    width: 18px;
  }
`;

import type { Quantity } from '@xbcb/api-gateway-client';

export const checkUOMOrder = (
  unitReportingQuantities?: Quantity[],
  uoms?: string[],
): boolean => {
  if (!unitReportingQuantities || !uoms) return true;
  return unitReportingQuantities.every(
    (quantity, index: number) =>
      index < uoms.length && quantity?.unit === uoms[index],
  );
};

export const reorderQuantities = (
  unitReportingQuantities?: Quantity[],
  uoms?: string[],
  useDefaultValue = true,
): Quantity[] => {
  if (!unitReportingQuantities) return [];
  if (!uoms) return unitReportingQuantities;
  return uoms.map((uom, index) => {
    const unitReportingQuantity = unitReportingQuantities?.find(
      (quantity) => quantity?.unit === uom,
    );
    const newValue =
      unitReportingQuantity?.value ||
      (useDefaultValue
        ? unitReportingQuantities[index]?.value || 0
        : undefined);
    return { value: newValue, unit: uom };
  });
};

export const getUomDisplayText = (
  unitCodes: { [key: string]: string },
  uom: string,
  uomIdx: number,
): string => {
  const catairCodeUom = unitCodes[uom];
  const unitTextValue =
    catairCodeUom === 'Kilograms' ? 'Net Weight' : catairCodeUom;
  return unitTextValue || `Unit ${uomIdx + 1}`;
};

import { connect } from 'react-redux';
import { getCodes } from '@xbcb/ui-utils';
import SPISelect from './component';

const mapStateToProps = (state: any) => {
  const codes = getCodes();
  return {
    claimCodes: codes.USITC.specialTariffProgram.indicators,
    groupCodes: codes.USITC.specialTariffProgram.groups,
  };
};

export default connect(mapStateToProps)(SPISelect);

import React from 'react';
import { useQuery } from '@apollo/client';
import {
  getSearchProcessingErrorsQueryOptions,
  searchProcessingErrorsQuery,
} from './query';
import { FailSafeErrorBoundary } from 'components/FailSafeErrorBoundary';
import { WarningTwoTone } from '../../icons';
import { Button } from 'antd';
import { formatDisplayText } from './utils';
import { useTheme } from 'styled-components';
import { useModal } from '@xbcb/ui-utils';
import { ProcessingErrorsModal } from './modal';
import { processingErrorsModalKey } from './types';

interface ProcessingErrorsAlertProps {
  recordId: string;
}

const ProcessingErrorsAlert: React.FC<ProcessingErrorsAlertProps> = ({
  recordId,
}) => {
  const theme = useTheme();

  const { openModal } = useModal(processingErrorsModalKey);

  const { data, loading } = useQuery(
    searchProcessingErrorsQuery,
    getSearchProcessingErrorsQueryOptions(recordId),
  );

  const errors = data?.searchProcessingErrors?.results;
  const hasUnresolvedErrors = errors?.length > 0;

  if (loading || !hasUnresolvedErrors) return null;

  return (
    <>
      <Button onClick={openModal}>
        <WarningTwoTone twoToneColor={theme.palette.reds.red} />
        {formatDisplayText(recordId)}
      </Button>
      <ProcessingErrorsModal errors={errors} />
    </>
  );
};

export const FailSafeProcessingErrorsAlertButton: React.FC<
  ProcessingErrorsAlertProps
> = ({ recordId }) => (
  <FailSafeErrorBoundary>
    <ProcessingErrorsAlert recordId={recordId} />
  </FailSafeErrorBoundary>
);

export default FailSafeProcessingErrorsAlertButton;

import { openProduct, closeProduct, closeAllExcept } from '../actions';
import { createReducer } from '@reduxjs/toolkit';
export interface OpenProductsState {
  [key: number]: { [key: number]: boolean };
}

const initialState: OpenProductsState = {
  0: { 0: false },
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(openProduct, (state, { payload }) => {
      const { invoiceId, productId } = payload;
      const oldInvoice = state[invoiceId] || {};

      return { ...state, [invoiceId]: { ...oldInvoice, [productId]: true } };
    })
    .addCase(closeProduct, (state, { payload }) => {
      const { invoiceId, productId } = payload;
      const oldInvoice = state[invoiceId] || {};

      return { ...state, [invoiceId]: { ...oldInvoice, [productId]: false } };
    })
    .addCase(closeAllExcept, (state, { payload }) => {
      const { invoiceId, productId } = payload;
      return { ...state, [invoiceId]: { [productId]: true } };
    });
});

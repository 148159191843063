import React from 'react';
import logo from 'assets/logo-black.svg';
import { Img } from 'react-image';
import { StyledSection } from './styles';

type LogoProps = {
  url?: string;
};
const Logo = ({ url }: LogoProps): React.ReactElement => {
  const loginUrl = url || '';
  return (
    <StyledSection>
      <Img src={[loginUrl, logo]} alt="INLT Logo" />
    </StyledSection>
  );
};

export default Logo;

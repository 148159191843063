import { AnyObject } from '@xbcb/shared-types';
import { WorkOrderMilestoneName } from '@xbcb/work-order-types';
import { InvoiceStatus, ChargeCode } from '@xbcb/finance-types';
import type { UsIorContinuousBondRequest } from '@xbcb/api-gateway-client';

const getMilestoneNames = (record: AnyObject) => {
  return (
    record?.milestones.map(
      ({ name }: { name: WorkOrderMilestoneName }) => name,
    ) || []
  );
};

// checks if continuous bond was acquired successfully or not
// conditions to check:
// 1) a continuous bond acquisition request using bond bot failed and no subsequent success (ideally on a retry)
// 2) The charge bond query didn't succeed
export const continuousBondFailedFromMilestones = (
  record: AnyObject,
): boolean => {
  const milestoneNames = getMilestoneNames(record);
  return (
    milestoneNames.includes(
      WorkOrderMilestoneName.US_IOR_CONTINUOUS_BOND_REQUEST_ACQUISITION_FAILED,
    ) &&
    !milestoneNames.includes(
      WorkOrderMilestoneName.US_IOR_CONTINUOUS_BOND_REQUEST_ACQUISITION_SUCCEEDED,
    ) &&
    !milestoneNames.includes(
      WorkOrderMilestoneName.US_IOR_CONTINUOUS_BOND_REQUEST_CHARGE_BOND_SUCCEEDED,
    )
  );
};

// checks if continuous bond was requested
export const continuousBondRequestedFromMilestones = (
  record: UsIorContinuousBondRequest,
): boolean => {
  const milestoneNames = getMilestoneNames(record);
  return milestoneNames.includes(
    WorkOrderMilestoneName.US_IOR_CONTINUOUS_BOND_REQUEST_ACQUISITION_REQUESTED,
  );
};

export const continuousBondInvoiceExists = (
  record: UsIorContinuousBondRequest,
): boolean => {
  const invoiceExists = record?.group?.invoices?.some(
    ({ lines, status }) =>
      lines &&
      lines.some((line) => line.type === ChargeCode.CONTINUOUS_BOND) &&
      ![InvoiceStatus.PENDING_VOID, InvoiceStatus.VOID].includes(
        status as InvoiceStatus,
      ),
  );
  return Boolean(invoiceExists);
};

import styled from 'styled-components';
import { Button } from 'antd';
import { spaceRight } from '@xbcb/ui-styles';

export const StyledDiv = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  .warning-icon {
    ${({ theme }) => theme.palette.smile};
  }
`;

export const StyledButton = styled(Button)`
  ${spaceRight};
`;

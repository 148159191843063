import React, { MouseEvent } from 'react';
import { PrinterOutlined } from '@ant-design/icons';
import moment from 'moment';
import download from 'libs/download';
import { StyledButton } from './styles';

interface DownloadEdiButtonProps {
  edi: string;
  lastModified: string;
  loading: boolean;
}

const DownloadEDIButton: React.FC<DownloadEdiButtonProps> = ({
  edi,
  lastModified,
  loading,
}) => {
  const handleDownload = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const formattedEDI = edi.replace(/<br\/>/g, '\n').replace('&nbsp;', ' ');
    download(
      formattedEDI,
      `${moment(lastModified).format('MM_DD_YYYY_H_m_s')}.txt`,
    );
  };

  return (
    <StyledButton
      ghost
      type="primary"
      size="small"
      onClick={handleDownload}
      loading={loading}
    >
      <PrinterOutlined />
    </StyledButton>
  );
};

export default DownloadEDIButton;

import { setModal } from 'actions/modals';
import moment from 'moment';

export default ({ latestAppVersion, minimumAppVersion }) =>
  async (dispatch) => {
    if (
      !minimumAppVersion ||
      !process.env.REACT_APP_VERSION ||
      !latestAppVersion
    ) {
      return;
    }
    const clientVersion = moment(process.env.REACT_APP_VERSION);
    const latestVersion = moment(latestAppVersion);
    const minimumVersion = moment(minimumAppVersion);
    if (
      minimumVersion.diff(latestVersion) > 0 ||
      (clientVersion.diff(minimumVersion) >= 0 &&
        latestVersion.diff(clientVersion, 'days') <= 30)
    ) {
      return;
    }
    await dispatch(
      setModal({
        // TODO add key to `ModalKey` enum if we ended up using this
        key: 'newContentModal',
        props: {
          visible: true,
        },
      }),
    );
  };

import { NamePath, WorkOrderTab, AdditionalFormError } from '@xbcb/ui-types';
import { createPartyValidator } from '@xbcb/ui-utils';
import { getWorkOrderTabConfiguration } from 'libs/workOrderTabConfiguration';
import { RecordType, AnyObject } from '@xbcb/shared-types';
import {
  UsConsumptionEntryTaskType,
  WorkOrderTaskType,
} from '@xbcb/work-order-types';
import getQueryParameters from 'libs/getQueryParameters';
import { createBrowserHistory } from 'history'; // TODO should update to import history singleton directly from history/browser once React Router v6 is released (currently in beta)
export type GetValidationParamsProps = {
  input: AnyObject;
};

const workOrderTaskTypesWithCommercialInvoicesTabValidationDisabled: string[] =
  [
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_BUILD_INVOICE,
    WorkOrderTaskType.US_CONSUMPTION_ENTRY_DOCUMENT_TAGGING,
  ];

export const getValidationParams = ({ input }: GetValidationParamsProps) => {
  const history = createBrowserHistory();
  const validateFields: NamePath[] = [];
  const additionalErrors: AdditionalFormError[] = [];

  const validatePartySnapshot = createPartyValidator({
    validateFields,
    additionalErrors,
    input,
  });

  const queryParameters = getQueryParameters(history.location);
  const workOrderTaskType = queryParameters['task'];

  const tabConfiguration = getWorkOrderTabConfiguration(
    RecordType.US_CONSUMPTION_ENTRY,
  )[workOrderTaskType as UsConsumptionEntryTaskType];

  const isBondTabRendered =
    !workOrderTaskType || tabConfiguration?.includes(WorkOrderTab.Bond);
  const isCommercialInvoicesTabRendered =
    !workOrderTaskType ||
    tabConfiguration?.includes(WorkOrderTab.CommercialInvoices);
  const isTransportationTabRendered =
    !workOrderTaskType ||
    tabConfiguration?.includes(WorkOrderTab.Transportation);

  const validateTabs = {
    bond: isBondTabRendered,
    transportation: isTransportationTabRendered,
    // validation is disabled for the build invoice task specifically since
    // ops wants to leverage unskilled people to perform the data entry
    commercialInvoices:
      isCommercialInvoicesTabRendered &&
      !workOrderTaskTypesWithCommercialInvoicesTabValidationDisabled.includes(
        workOrderTaskType as string,
      ),
  };

  return {
    validateFields,
    additionalErrors,
    validatePartySnapshot,
    validateTabs,
  };
};

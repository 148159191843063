import type { WorkOrder, WorkOrderTask } from '@xbcb/api-gateway-client';
import { WorkOrderTaskStatus } from '@xbcb/work-order-types';
import { getRecordType } from '@xbcb/core';
import { paramCase } from 'change-case';
import { pluralize } from '@xbcb/js-utils';
import { RecordType } from '@xbcb/shared-types';

export const isDeletedWorkOrder = (record: WorkOrder): boolean => {
  return Boolean(record?.deleted?.time);
};

export const isNotAssignedWorkOrderTask = (record: WorkOrderTask): boolean => {
  return record?.status === WorkOrderTaskStatus.NOT_ASSIGNED;
};
export const constructRedirectToPath = (record: WorkOrderTask): string => {
  const workOrderType = getRecordType(record.workOrder.id) as RecordType;
  const workOrderTaskType = record?.definition.workOrderTaskType;
  return `/${pluralize(paramCase(workOrderType))}/${
    record.workOrder.id
  }?task=${workOrderTaskType}`;
};

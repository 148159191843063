import React from 'react';
import { Divider } from 'antd';
import { LocationForm } from '@xbcb/form-components';
import Passport from 'components/Passport';
import { PickDate } from '@xbcb/form-item-components';
import { FormInstance } from 'antd/lib/form';
import { DataCySuffix, NamePath } from '@xbcb/ui-types';
import moment from 'moment';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

type PersonalEffectsProps = {
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  allRequired?: boolean;
  fullNamePath?: NamePath;
  localNamePath?: NamePath;
};
const PersonalEffects = ({
  form,
  disabled,
  readOnly,
  allRequired,
  fullNamePath = [],
  localNamePath = [],
}: PersonalEffectsProps) => {
  const [partyBundle] = useBundle('components.Party');
  const { getFieldValue } = form;
  const countryCode = getFieldValue(['addresses', 'mailing', 'countryCode']);
  const isUS = countryCode === 'US';
  const locationName = isUS ? 'lastForeign' : 'newUs';
  return (
    <>
      {
        <div>
          <PickDate
            form={form}
            fullNamePath={[...fullNamePath, 'birthDate']}
            localNamePath={[...localNamePath, 'birthDate']}
            label={safeGetMessage(partyBundle, 'birth_date')}
            readOnly={readOnly}
            disabled={disabled}
            disabledDate={(current) => current && current > moment()}
            required={allRequired}
          />
          {!isUS && (
            <div>
              <Passport
                form={form}
                readOnly={readOnly}
                disabled={disabled}
                localNamePath={localNamePath}
                fullNamePath={fullNamePath}
                allRequired={allRequired}
              />
            </div>
          )}
          <Divider />
          <h4 className="space-bottom">
            {safeGetMessage(
              partyBundle,
              'last_foreign_address_new_us_address',
              { isUS },
            )}
          </h4>
          <LocationForm
            // TODO this fullNamePath needs updated
            fullNamePath={[...fullNamePath, locationName]}
            localNamePath={[...localNamePath, locationName]}
            foreignOnly={isUS}
            readOnly={readOnly}
            disabled={disabled}
            form={form}
            allRequired={allRequired}
            spaceBottom={false}
            dataCySuffix={DataCySuffix.LAST_FOREIGN_ADDRESS}
          />
        </div>
      }
    </>
  );
};

export default PersonalEffects;

import type { UsHts } from '@xbcb/api-gateway-client';
import { HtsObject } from 'types';

/**
 * Light mapping between HTS objects returned from
 * the backend and the shape found in HTS.json.
 * TODO: remove this once HTS Automation is out of UAT.
 */

export const htsResponseToObject = (
  htsResponse?: UsHts,
): HtsObject | undefined => {
  if (!htsResponse) return;
  const {
    ad,
    principalAdCvdCases,
    cv,
    additionalHtssApplicable,
    additionalHTS,
    description,
    endDate,
    fees,
    gspExcluded,
    license,
    text,
    quota,
    rate,
    rateCode,
    restriction,
    spiRates,
    startDate,
    textile,
    trumpList,
    truncatedDescription,
    uom,
    spi,
    pga,
  } = htsResponse;
  return {
    additional: additionalHtssApplicable,
    principalAdCvdCases: principalAdCvdCases ?? [],
    additionalHTS,
    ad,
    cv,
    gspExcluded,
    startDate,
    endDate,
    quota,
    licenseCode: license?.code,
    rate,
    restriction,
    spiRates,
    textile,
    trumpList,
    fees: fees ?? [],
    uom: uom ?? [],
    duty: rateCode,
    text: truncatedDescription || description || text,
    fullText: text,
    spi: spi?.map((spi) => spi.code),
    pga: pga?.map((pga) => pga.flag),
  };
};

export default htsResponseToObject;

import { Modal, message } from 'antd';
import moment from 'moment';
import { memoFragments } from '@xbcb/client-queries';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import type {
  UpdateUsIsfInput,
  UsIsf as ApigUsIsf,
} from '@xbcb/api-gateway-client';
import { updateOneMutation } from '@xbcb/shared-queries';
import {
  workOrderTaskFragments,
  workOrderTaskStepFragments,
} from '@xbcb/work-order-queries';
import {
  UsIsfCbpStatus,
  WorkOrderTaskType,
  IsfType,
  UsIsfShipmentTypeCode,
  WorkOrderType,
  WorkOrderMilestoneName,
} from '@xbcb/work-order-types';
import { isAGLForwarder, isDevicesForwarder } from '@xbcb/party-utils';
import { executeMutation } from 'libs/executeMutation';
import { transformUsIsf } from 'libs/formTransforms';
import {
  createUsIsfCbp,
  createWorkOrderMilestoneMutation,
  deleteUsIsfCbp,
  updateUsIsfCbp,
  usIsfFragments,
} from 'libs/sharedQueries';
import validateUsIsf from 'libs/validateUsIsf';
import { disableWorkOrderTaskCompletionButton } from 'libs/workOrderTaskCompletionButton';
import UsIsfSecondaryHeader from 'components/UsIsfSecondaryHeader';
import UsIsfSecondaryRightHeader from 'components/UsIsfSecondaryRightHeader';
import { UsIsf } from '../../loadableComponents';
import {
  appRecordAbiDataMenuItem,
  appRecordAssignWorkOrderTaskMenuItem,
  findWorkOrderTaskAndModifyStepCompletion,
  appRecordMemoMenuItem,
  appRecordModifyDutyCollectionParty,
  inquireWorkOrderItem,
  unblockWorkOrderItem,
} from '../utils';
import { WorkOrderRoute } from '.';
import { capitalCase } from 'change-case';
import { markWorkOrderButton } from '../utils/markWorkOrdersReadyButton';
import { v4 } from 'uuid';
import { isRecordCanceled, isRecordDeleted } from 'libs/workOrderConditions';
import { checkKebabMenuItemForWorkOrderTask } from '../utils/checkKebabMenuItemForWorkOrderTask';
import { appRecordSendAbiMessage } from '../utils/appRecordSendAbiMessage';

// FBA and Devices shipments acquire bond externally so no need to acquire bond for them
const isBondRequiredForWorkOrder = (record: ApigUsIsf) => {
  const forwarder = record.group?.forwarders?.[0]?.forwarder;
  return !(
    forwarder &&
    (isAGLForwarder(forwarder) || isDevicesForwarder(forwarder))
  );
};
const fields = `
  ...usIsfFields
  status
  deadline
  group {
    id
    documents {
      id
    }
    forwarders {
      forwarder {
        id
        tags {
          key
          value
        }
      }
    }
    shipment {
      id
      legs {
        modeOfTransport
      }
      preferredDutyCollectPartyType
    }
    memos {
      ...memoFields
    }
    shipper {
      id
      billingDetails {
        accountingSystemCustomerReferences {
          type
          value
        }
      }
    }
  }
  ior {
    usIor {
      memos {
        ...memoFields
      }
    }
  }
  pendingUpdates {
    path
    value
    occurrenceTime
    dataSource
  }
  tasks {
    ...workOrderTaskFields
    assignee {
      id
      name
    }
    steps {
      id
      name
      description
      isCompletedBySystem
      isFinalConfirmation
    }
    definition {
      id
      workOrderTaskType
    }
  }
`;

const needsSingleTransactionBond = (record: AnyObject): boolean => {
  const { singleTransactionBond, shipmentTypeCode } = record;
  const continuousBond = record?.ior?.usIor?.continuousBonds?.present;
  const hasSingleTransactionBond =
    singleTransactionBond?.suretyCode && singleTransactionBond?.referenceNumber;
  return (
    shipmentTypeCode === UsIsfShipmentTypeCode.STANDARD &&
    !continuousBond &&
    !hasSingleTransactionBond
  );
};

const allowedToSubmitIsf = ({
  existingRecord,
  currentUser,
  workOrderTaskType,
}: {
  existingRecord: AnyObject;
  currentUser: AnyObject;
  workOrderTaskType?: WorkOrderTaskType;
}) =>
  (!workOrderTaskType ||
    workOrderTaskType === WorkOrderTaskType.US_ISF_FILING ||
    workOrderTaskType === WorkOrderTaskType.US_ISF_EXCEPTION) &&
  !isRecordDeleted(existingRecord) &&
  !isRecordCanceled(existingRecord) &&
  !currentUser.loading &&
  checkAccess(
    currentUser,
    RecordType.US_ISF,
    UserPermissions.CREATE_CBP_TRANSACTION,
  );
// we have to type record as any because AppRecordKebabSubMenuItemActionProps has typed it as any.
// when we modify the type of record in above then this record variable would have better type as well
const modifyIsfType = async (record: any, newIsfType: IsfType) => {
  const usIsfId = record.id;
  const updateUsIsfMutation = updateOneMutation({
    recordName: RecordType.US_ISF,
    fields: 'id version isfType',
  });

  message.info('Processing Request');

  await executeMutation({
    mutation: updateUsIsfMutation,
    variables: {
      version: record.version,
      id: usIsfId,
      input: {
        isfType: newIsfType,
      } as UpdateUsIsfInput,
    },
    successMessage: `ISF Type changed to ${newIsfType}`,
  });
};
const usIsfRoute: WorkOrderRoute =
  // we don't limit the ABI transactions to Operators for the ISF, since we
  // allow Shippers and Forwarders to self-file it
  {
    Page: UsIsf,
    recordType: RecordType.US_ISF,
    getTitleField: () => 'isfNumber',
    fields,
    fragments: `${usIsfFragments}${workOrderTaskFragments}${workOrderTaskStepFragments}${memoFragments}`,
    transformUpdateRecordInput: transformUsIsf.toSchema,
    transformRecordToInput: transformUsIsf.toForm,
    validateUpdate: validateUsIsf,
    SecondaryHeaderContents: [UsIsfSecondaryHeader],
    SecondaryRightHeaderContent: UsIsfSecondaryRightHeader,
    kebabMenuItems: [
      appRecordAbiDataMenuItem,
      appRecordSendAbiMessage,
      appRecordAssignWorkOrderTaskMenuItem,
      appRecordMemoMenuItem,
      appRecordModifyDutyCollectionParty,
      inquireWorkOrderItem,
      unblockWorkOrderItem,
      {
        key: 'resetCbpStatus',
        text: 'Reset CBP Status',
        show: ({ existingRecord, currentUser }) =>
          existingRecord?.cbpStatus &&
          existingRecord?.cbpStatus === UsIsfCbpStatus.SUBMITTED &&
          // There has been no updates to the record in the last 30 minutes
          moment
            .duration(moment().diff(moment(existingRecord?.updated?.time)))
            .asMinutes() > 30 &&
          !currentUser.loading &&
          checkAccess(currentUser, RecordType.US_ISF, UserPermissions.UPDATE),
        action: async ({ record }) => {
          const updateUsIsfMutation = updateOneMutation({
            recordName: RecordType.US_ISF,
            fields: 'id version cbpStatus',
          });
          Modal.confirm({
            title: 'Reset CBP status',
            content: `Are you sure you want to reset CBP status?`,
            width: 500,
            onOk: async () => {
              await executeMutation({
                mutation: updateUsIsfMutation,
                variables: {
                  id: record.id,
                  version: record.version,
                  input: {
                    cbpStatus: null!, // cant send undefined here as it will be a no-op
                  } as UpdateUsIsfInput,
                },
              });
            },
          });
        },
      },
      {
        key: 'deleteIsf',
        text: 'Delete ISF',
        show: ({ existingRecord, currentUser }) =>
          existingRecord?.isfNumber &&
          existingRecord?.cbpStatus &&
          existingRecord?.cbpStatus !== UsIsfCbpStatus.DELETED &&
          !currentUser.loading &&
          checkAccess(currentUser, RecordType.US_ISF, UserPermissions.DELETE),
        action: async ({ record }) => {
          Modal.confirm({
            title: 'Delete ISF',
            content: `Are you sure you want to delete ISF ${record?.isfNumber}?`,
            width: 500,
            onOk: async () => {
              await executeMutation({
                mutation: deleteUsIsfCbp,
                variables: { usIsfId: record.id },
              });
            },
          });
        },
      },
      {
        key: 'changeToIsfType5',
        text: 'Convert to ISF-5',
        show: ({
          existingRecord,
          currentUser,
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        }) =>
          existingRecord.isfType &&
          !currentUser.loading &&
          existingRecord.isfType !== IsfType.ISF_5 &&
          checkAccess(currentUser, RecordType.US_ISF, UserPermissions.UPDATE) &&
          checkKebabMenuItemForWorkOrderTask(
            workOrderTaskType,
            workOrderTaskEvaluatedState,
          ),
        action: async ({ record }) => {
          await modifyIsfType(record, IsfType.ISF_5);
        },
      },
      {
        key: 'changeToIsfType10',
        text: 'Convert to ISF-10',
        show: ({
          existingRecord,
          currentUser,
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        }) =>
          existingRecord.isfType &&
          !currentUser.loading &&
          existingRecord.group?.shipment?.legs?.[0]?.modeOfTransport ===
            'OCEAN' && // only allow ISF 5 for rail
          existingRecord.isfType !== IsfType.ISF_10 &&
          checkAccess(currentUser, RecordType.US_ISF, UserPermissions.UPDATE) &&
          checkKebabMenuItemForWorkOrderTask(
            workOrderTaskType,
            workOrderTaskEvaluatedState,
          ),
        action: async ({ record }) => {
          await modifyIsfType(record, IsfType.ISF_10);
        },
      },
      {
        key: 'convertToStandardIsf',
        text: 'Convert to standard ISF',
        show: ({
          existingRecord,
          currentUser,
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        }) =>
          existingRecord.shipmentTypeCode !== UsIsfShipmentTypeCode.STANDARD &&
          !currentUser.loading &&
          checkAccess(currentUser, RecordType.US_ISF, UserPermissions.UPDATE) &&
          checkKebabMenuItemForWorkOrderTask(
            workOrderTaskType,
            workOrderTaskEvaluatedState,
          ),
        action: async ({ record }) => {
          Modal.confirm({
            title: 'Convert to a standard isf?',
            content: `You cannot undo this action.`,
            width: 500,
            onOk: async () => {
              const updateUsIsfMutation = updateOneMutation({
                recordName: RecordType.US_ISF,
                fields: 'id version isfType shipmentTypeCode',
              });

              await executeMutation({
                mutation: updateUsIsfMutation,
                variables: {
                  version: record.version,
                  id: record.id,
                  input: {
                    shipmentTypeCode: UsIsfShipmentTypeCode.STANDARD,
                  } as UpdateUsIsfInput,
                },
                successMessage: `ISF shipment type code changed to standard`,
              });
            },
          });
        },
      },
    ],
    draftEditMode: ({ existingRecord }) =>
      !existingRecord?.cbpStatus ||
      (existingRecord?.cbpStatus === UsIsfCbpStatus.REJECTED &&
        !existingRecord?.isfNumber),
    defaultEditMode: false,
    submitButtons: [
      markWorkOrderButton(WorkOrderType.UsIsf),
      {
        key: 'submitIsf',
        text: 'Submit ISF',
        show: ({ existingRecord, editMode, currentUser, workOrderTaskType }) =>
          allowedToSubmitIsf({
            existingRecord,
            currentUser,
            workOrderTaskType,
          }) &&
          (editMode === 'draft' ||
            existingRecord.cbpStatus === UsIsfCbpStatus.DELETED) &&
          (!isBondRequiredForWorkOrder(existingRecord) ||
            !needsSingleTransactionBond(existingRecord)),
        onSubmit: async ({ updatedRecord, workOrderTaskType }) => {
          if (workOrderTaskType) {
            await findWorkOrderTaskAndModifyStepCompletion({
              workOrder: updatedRecord,
              workOrderTaskType,
            });
          }
          await executeMutation({
            mutation: createUsIsfCbp,
            variables: { usIsfId: updatedRecord.id },
          });
        },
        disable: ({ existingRecord, workOrderTaskType }) => {
          if (workOrderTaskType) {
            return disableWorkOrderTaskCompletionButton(
              existingRecord,
              workOrderTaskType,
            );
          }
          return false;
        },
      },
      {
        key: 'acquireSingleTransactionBond',
        text: 'Requery IOR 5106',
        show: ({ existingRecord, editMode, currentUser, workOrderTaskType }) =>
          editMode === 'draft' &&
          allowedToSubmitIsf({
            existingRecord,
            currentUser,
            workOrderTaskType,
          }) &&
          isBondRequiredForWorkOrder(existingRecord) &&
          needsSingleTransactionBond(existingRecord),
        onSubmit: async ({ updatedRecord, workOrderTaskType }) => {
          if (workOrderTaskType) {
            await findWorkOrderTaskAndModifyStepCompletion({
              workOrder: updatedRecord,
              workOrderTaskType,
            });
          }
          await executeMutation({
            mutation: createWorkOrderMilestoneMutation,
            variables: {
              idempotencyKey: v4(),
              input: {
                allowDuplicate: true,
                name: WorkOrderMilestoneName.US_ISF_BOND_ACQUISITION_REQUESTED,
                workOrder: {
                  id: updatedRecord.id,
                  version: updatedRecord.version,
                },
              },
            },
          });
        },
        disable: ({ existingRecord, workOrderTaskType }) => {
          if (workOrderTaskType) {
            return disableWorkOrderTaskCompletionButton(
              existingRecord,
              workOrderTaskType,
            );
          }
          return false;
        },
      },
    ],
    editModes: [
      {
        key: 'updateIsf',
        cancelText: 'Cancel ISF update',
        submitText: 'Submit ISF update',
        onSubmit: async ({ updatedRecord }) => {
          await executeMutation({
            mutation: updateUsIsfCbp,
            variables: { usIsfId: updatedRecord.id },
          });
        },
      },
    ],
    editButtons: [
      {
        key: 'updateIsf',
        text: 'Update ISF',
        show: ({ existingRecord, workOrderTaskType }) =>
          (!workOrderTaskType ||
            workOrderTaskType === WorkOrderTaskType.US_ISF_FILING ||
            workOrderTaskType === WorkOrderTaskType.US_ISF_EXCEPTION) &&
          existingRecord?.isfNumber &&
          existingRecord?.cbpStatus &&
          existingRecord?.cbpStatus !== UsIsfCbpStatus.DELETED &&
          !isRecordCanceled(existingRecord) &&
          !isRecordDeleted(existingRecord),
      },
    ],
    getAdditionalWorkOrderInfo: ({ record }) => {
      const { isfNumber, cbpStatus } = record;
      return [
        {
          label: 'ISF #',
          value: isfNumber,
        },
        {
          label: 'CBP status',
          value: capitalCase(cbpStatus || ''),
        },
      ];
    },
  };

export default usIsfRoute;

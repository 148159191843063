import { get } from 'lodash';
import type { AdditionalFormError, NamePath } from '@xbcb/ui-types';
import { ValidationErrorType } from '@xbcb/ui-types';
import {
  defaultCreatePartyValidatorEmbeddedFields,
  validateFormSnapshotFields,
} from '@xbcb/ui-utils';
import { UsConsumptionEntryCommercialInvoiceAdjustmentType } from '@xbcb/work-order-types';
import { CommercialInvoice } from 'types';
import { roundNumber } from '../format';
import { validateProduct } from './validateProduct';
import { createAccumulator } from './accumulator';

const DEFAULT_INVOICE_VALUE = 0;

export const validateInvoice = (
  invoice: CommercialInvoice = {},
  invoiceIndex: number,
) => {
  const additionalErrors: AdditionalFormError[] = [];
  const validateFields: NamePath[] = [];
  const accumulator = createAccumulator({
    additionalErrors,
    validateFields,
  });
  const invoiceDisplayIndex = invoiceIndex + 1;
  const invoiceNamePath = ['invoices', invoiceIndex];
  validateFields.push([...invoiceNamePath, 'invoiceNumber']);
  validateFields.push([...invoiceNamePath, 'value', 'value']);
  validateFields.push([...invoiceNamePath, 'value', 'currency']);

  const currency = get(invoice, ['currency']);
  if (currency !== 'USD') {
    const currencyRateNamePath = ['currencyRate'];
    validateFields.push(currencyRateNamePath);
  }

  const invoiceValue = invoice.value?.value || DEFAULT_INVOICE_VALUE;
  const roundedInvoiceValueValue = roundNumber(invoiceValue, 2);
  let productsValue = 0;
  if ('adjustments' in invoice) {
    // US consumption entries only
    invoice.adjustments?.forEach((adjustment: any, adjustmentIndex: number) => {
      const adjustmentNamePath = [
        ...invoiceNamePath,
        'adjustments',
        adjustmentIndex,
      ];
      validateFields.push([...adjustmentNamePath, 'type']);
      validateFields.push([...adjustmentNamePath, 'value', 'value']);
      // adjustment currency should be the same as invoice currency. Since this
      // is a "US" consumptionEntry we assume this is the case as it is not
      // changeable from the UI and should be `USD`.
      validateFields.push([...adjustmentNamePath, 'value', 'currency']);
      validateFields.push([...adjustmentNamePath, 'description']);

      const adjustmentType = adjustment.type;
      let adjustmentValue = adjustment.value?.value;
      if (adjustmentValue && adjustmentType) {
        if (
          adjustmentType ===
          UsConsumptionEntryCommercialInvoiceAdjustmentType.SUBTRACT
        ) {
          adjustmentValue *= -1;
        }
        productsValue += adjustmentValue;
      }
    });
  }

  if (invoice.seller?.supplier?.id) {
    validateFields.push([...invoiceNamePath, 'seller', 'supplier', 'id']);
    const snapshotErrors = validateFormSnapshotFields({
      input: invoice,
      idPath: ['seller', 'supplier', 'id'],
      displayName: `Invoice ${invoiceDisplayIndex} seller`,
      embeddedFields: [...defaultCreatePartyValidatorEmbeddedFields, ['mid']],
    });

    const snapshotErrorStrings = snapshotErrors
      .map((error) => error.messages)
      .flat();

    if (snapshotErrorStrings.length) {
      additionalErrors.push({
        title: 'Seller snapshot errors',
        type: ValidationErrorType.SNAPSHOT,
        path: [...invoiceNamePath, 'seller'],
        messages: [...snapshotErrorStrings],
      });
    }
  }

  // There must always be at least one product
  if (!invoice.products || invoice.products.length === 0) {
    additionalErrors.push({
      title: 'Commercial Invoice Products Missing',
      type: ValidationErrorType.GENERAL,
      path: [...invoiceNamePath, 'products'],
      messages: [
        `Invoice ${invoiceDisplayIndex}, at least one product must be provided`,
      ],
    });
  }

  invoice.products?.forEach((product: any, productIndex: number) => {
    const {
      errors: currentProductErrors,
      validateFields: currentProductValidateFields,
      productValue,
    } = validateProduct(product, productIndex, invoiceIndex);
    productsValue += productValue;
    accumulator([
      {
        additionalErrors: currentProductErrors,
        validateFields: currentProductValidateFields,
      },
    ]);
  });

  if (roundedInvoiceValueValue !== roundNumber(productsValue, 2)) {
    additionalErrors.push({
      title: 'Invoice value mismatch',
      type: ValidationErrorType.GENERAL,
      path: [...invoiceNamePath, 'value', 'value'],
      messages: [
        `Invoice value (${roundedInvoiceValueValue}) does not match the sum of product values (${roundNumber(
          productsValue,
          2,
        )})`,
      ],
    });
  }

  return {
    additionalErrors,
    validateFields,
  };
};

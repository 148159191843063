import { Col } from 'antd';
import React from 'react';
import { ProductDetails, Remarks, RegulatoryCategory } from './subComponents';
import { VehiclesAndEnginesFormProps } from './types';

const VehiclesAndEnginesForm: React.FC<VehiclesAndEnginesFormProps> = (
  props,
) => {
  return (
    <Col>
      <RegulatoryCategory {...props} />
      <Remarks {...props} />
      <ProductDetails {...props} />
    </Col>
  );
};

export default VehiclesAndEnginesForm;

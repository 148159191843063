import styled from 'styled-components';
import { Timeline, Tag } from 'antd';
import ButterContent from 'components/ButterContent';

export const StyledTimelineItem = styled(Timeline.Item)<{
  $isOpen?: boolean;
}>`
  font-size: 13px;
  background: white;
  padding: ${({ theme }) => theme.spacing.space_4};
  padding-bottom: 0;
  color: var(--dark-grey-3);
  :hover {
    z-index: 1;
    background: none;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.09);
  }
  .ant-timeline-item-tail {
    left: 20px;
    top: 1.75em;
    height: 100%;
    z-index: 1;
  }
  .ant-timeline-item-head {
    z-index: 1;
  }
  ${({ $isOpen }) =>
    $isOpen
      ? `
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.09);
  z-index: 1;
  background: white;
  .ant-timeline-item-tail {
    visibility: hidden;
  }
  `
      : ''}
`;

export const StyledMilestoneParagraph = styled.p<{
  $bold?: boolean;
  $fontSize?: string;
}>`
  ${({ $bold }) => ($bold ? 'font-weight: bold;' : 'font-weight: 500;')}
  ${({ $fontSize }) =>
    $fontSize ? `font-size: ${$fontSize};` : 'font-size: 13px;'}
`;

export const StyledButterContent = styled(ButterContent)`
  padding-top: ${({ theme }) => theme.spacing.space_2};
`;
export const StyledInquiryCodesTag = styled(Tag)`
  width: fit-content;
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

import styled from 'styled-components';
import { FormItem } from '@xbcb/form-item-components';
import { Form } from 'antd';

export const StyledFormItem = styled(FormItem)`
  .ant-form-item-control-input-content {
    .ant-input-number {
      width: 100%;
    }
  }
`;

export const StyledHiddenFormItem = styled(Form.Item)`
  display: none;
`;

import { handleActions } from 'redux-actions';

const defaultCompany = {
  simpleCache: {},
  cacheValid: false,
  loaded: false,
  logoUrl: '',
};

export default handleActions(
  {
    COMPANY_VALIDATE_CACHE: (state, { payload }) => ({
      ...state,
      cacheValid: payload,
    }),
    COMPANY_CACHE: (state, { payload }) => ({
      ...state,
      cacheValid: true,
      simpleCache: payload,
    }),
    COMPANY_LOADED: (state) => ({ ...state, loaded: true }),
    LOGO_CHANGE: (state, { payload }) => ({ ...state, logoUrl: payload }),
    LOGOUT: () => defaultCompany,
    COMPANY_UPDATE_UI: (state, { payload }) => ({ ...state, ...payload }),
  },
  defaultCompany,
);

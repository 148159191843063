export default (event) =>
  JSON.stringify(
    event,
    (k, v) => {
      if (v instanceof Node) {
        return 'Node';
      }
      if (v instanceof Window) {
        return 'Window';
      }
      return v;
    },
    ' ',
  );

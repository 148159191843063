import { handleActions } from 'redux-actions';

export default handleActions(
  {
    INVOICES_LOADING: (state, { payload }) => ({
      ...state,
      isLoading: payload,
      loaded: state.loaded || payload,
    }),
  },
  {
    isLoading: false,
    loaded: false,
  },
);

import type { CbpInBond } from '@xbcb/api-gateway-client';
import { CbpInBondStatus } from '@xbcb/work-order-types';

// This is a helper function that determines whether or not UsInBond should be
// in draftEditMode or not. This is based off the cbpInBonds. It can also be
// reused to determine whether or not to show the "add" button. As if the WO is
// still in draftEditMode, we should not yet allow adding of more CbpInBonds
export const getUsInBondDraftEditMode = (cbpInBonds?: CbpInBond[]) => {
  // It should be draft mode until a CbpInBond is submitted. From there, it
  // will no longer be draft mode and the user will need to click "edit" to
  // make changes (even to add a new CbpInBond)
  // No CbpInBond yet (so none submitted), thus it should be draft mode
  if (!cbpInBonds || cbpInBonds.length === 0) return true;
  // If there is exactly 1 CbpInBond but it has not been submitted yet, then
  // it should be draft mode. If the CbpInBond is not yet submitted the
  // status will either be `undefined` (newly add in the UI and not yet saved
  // to the backend) or `NOT_SUBMITTED` (saved to the backend but not yet
  // submitted to CBP)
  if (cbpInBonds.length === 1) {
    const cbpInBondStatus = cbpInBonds[0].status;
    return (
      !cbpInBondStatus || cbpInBondStatus === CbpInBondStatus.NOT_SUBMITTED
    );
  }
  // This means there's more than 1 CbpInBond, thus it should no longer be
  // draft mode
  return false;
};

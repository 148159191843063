import { Card, Typography, Tooltip, Button, Tag } from 'antd';
import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledCard = styled(Card)`
  margin-bottom: ${CssSpace.SPACE_5};
`;

export const StyledTypographyText = styled(Typography.Text)`
  color: var(--primary-color);
`;

export const StyledTooltip = styled(Tooltip)`
  padding-right: ${CssSpace.SPACE_3};
  font-size: 12px;
  line-height: 18px;
`;

export const StyledButton = styled(Button)`
  border-width: ${CssSpace.SPACE_1};
  margin-bottom: ${CssSpace.SPACE_2};
  margin-right: ${CssSpace.SPACE_2};
`;

export const StyledReplyButton = styled(Button)`
  border-width: ${CssSpace.SPACE_1};
  margin-left: -45px;
  margin-bottom: ${CssSpace.SPACE_2};
`;

export const StyledTextCodeDiv = styled.div`
  font-size: 13px;
  color: var(--text-color);
`;

export const StyledReplyBoxDiv = styled.div`
  margin-left: -45px;
`;

export const StyledAuthorDiv = styled.div`
  font-size: 14px;
`;

export const clickableTextStyle = {
  color: 'purple',
  cursor: 'pointer',
  marginLeft: '-45px',
};

export const verticalLineStyles = {
  borderLeft: '1px solid #000',
  paddingLeft: '10px',
  marginLeft: '5px',
};

export const StyledTag = styled(Tag)`
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
`;

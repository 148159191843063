import { createReducer } from '@reduxjs/toolkit';
import { get, set } from 'lodash';
import { handleRemoveItems, setValidationErrors } from '../actions/validation';
import { ValidationState } from 'types';

const defaultState: ValidationState = {};

const validationReducer = createReducer(defaultState, (builder) =>
  builder
    .addCase(setValidationErrors, (state, { payload }) => {
      const { recordId, validationResult } = payload;
      state[recordId] = {
        errorMap: validationResult.errorMap,
        validateFields: validationResult.validateFields,
      };
    })
    .addCase(handleRemoveItems, (state, { payload }) => {
      const { recordId, path, indices } = payload;
      const record = state[recordId]?.errorMap;
      const array = get(record, path);
      indices.sort((a, b) => b - a);
      indices.forEach((index) => array?.splice(index, 1));
      set(state, [recordId, 'errorMap', ...path], array);
      return state;
    }),
);

export default validationReducer;

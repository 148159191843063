import { useCurrentUser } from 'libs/hooks';
import {
  DateSearchOperator,
  RecordType,
  KeywordSearchOperator,
  AccountType,
} from '@xbcb/shared-types';
import { SearchQuery } from '@xbcb/shared-queries';
import { useQuery } from '@apollo/client';
import { usCustomsBrokerFragments } from '@xbcb/party-queries';

export const useCustomsBrokerId = () => {
  const { operator = {}, accountType, customsBroker } = useCurrentUser();
  const isOperatorUser = accountType === AccountType.OPERATOR;

  const searchCustomsBrokerQuery = SearchQuery({
    recordName: RecordType.US_CUSTOMS_BROKER,
    fields: '...usCustomsBrokerFields',
    fragments: usCustomsBrokerFragments,
  });

  const { data: customsBrokerData } = useQuery(searchCustomsBrokerQuery, {
    // shipper,forwarder user exist only in US. For operator user broker is mandatory so we only skip customsBrokerQuery if OperatorUser is true
    skip: !operator.id || isOperatorUser,
    variables: {
      input: {
        searchCriteria: {
          operatorId: {
            values: operator.id,
            operator: KeywordSearchOperator.EQUALS,
          },
          deletedTime: {
            operator: DateSearchOperator.DOES_NOT_EXIST,
          },
        },
      },
    },
  });

  // shipper forwarder user exist only in US. For operator user broker is mandatory
  if (isOperatorUser) {
    // remove fall back once all operator user has customs broker id
    return customsBroker.id
      ? customsBroker.id
      : customsBrokerData?.searchUsCustomsBrokers?.results?.[0]?.id;
  }

  // If there are multiple customsBrokers, take the first one. We should have only one customsBroker per operator
  return customsBrokerData?.searchUsCustomsBrokers?.results?.[0]?.id;
};

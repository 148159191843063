import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { onboardingRoutes, onboardingPendingRoutes } from 'routes';

import { StyledLayout } from './styles';

interface OnboardingRoutesProps {
  customType?: string;
}

export const OnboardingRoutes: React.FC<OnboardingRoutesProps> = ({
  customType,
}) => {
  if (!customType) return null;
  const href = customType === 'onboarding' ? '/dashboard' : '/pending';
  const routes =
    customType === 'onboarding' ? onboardingRoutes : onboardingPendingRoutes;
  return (
    <StyledLayout>
      <Switch>
        {routes.map(({ path, Page }) => {
          return (
            <Route key={path} path={path}>
              <Page />
            </Route>
          );
        })}
        <Redirect to={href} />
      </Switch>
    </StyledLayout>
  );
};

export default OnboardingRoutes;

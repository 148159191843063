import { gql } from '@apollo/client';

export const SEARCH_PRODUCTS = gql`
  query SearchProducts($input: SearchProductInput!) {
    searchProducts(input: $input) {
      results {
        id
        version
      }
    }
  }
`;

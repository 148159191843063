import React from 'react';
import { InputNumber } from 'antd';
import {
  createDataCyValue,
  CssSize,
  DataCyPrefix,
  DataCySuffix,
  NamePath,
} from '@xbcb/ui-types';
import { getCodes } from '@xbcb/ui-utils';
import { StyledFormItem } from './styles';

interface MoneyInputProps {
  // TODO: names for namePath need to be changed
  fullNamePath: NamePath;
  localNamePath?: NamePath;
  max?: number;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (value: number | undefined) => void;
  step?: number;
  $itemSize?: CssSize | string;
  label?: React.ReactNode;
  precision?: number;
  required?: boolean;
  currency?: string;
  debounce?: boolean;
  min?: number;
  suffix?: DataCySuffix | string;
}

const MoneyInput: React.FC<MoneyInputProps> = ({
  $itemSize = CssSize.LARGE,
  max = 100000000,
  label,
  localNamePath,
  fullNamePath,
  disabled,
  readOnly,
  onChange,
  precision = 2,
  step = 1,
  required = false,
  currency = 'USD',
  debounce = false,
  min = 0, // default value
  suffix,
}) => {
  // input number needs an input mask like text-mask-core, but that doesnt work with it since it has its own formatter and parser methods, so we roll our own
  if (!localNamePath) localNamePath = fullNamePath;

  const symbol = getCodes().ISO.currency.alpha[currency]?.symbol;
  return (
    <StyledFormItem
      label={label}
      name={localNamePath}
      rules={[{ required, message: ' ' }]}
      $itemSize={$itemSize}
      $readOnly={readOnly}
      $inline
      debounce={debounce}
      data-cy={createDataCyValue(DataCyPrefix.MONEY_INPUT, suffix)}
    >
      <InputNumber<number>
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        defaultValue={min}
        onChange={(val) => {
          return (
            onChange &&
            (typeof val === 'number' ? onChange(val) : onChange(undefined))
          );
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        formatter={(value) => {
          let str = '';
          if (value !== undefined) {
            const v = value.toString();
            const split = v.split('.');
            str = `${symbol} ${split[0].replace(
              /(\d)(?=(\d\d\d)+(?!\d))/g,
              '$1,',
            )}`;
            if (split.length > 1) {
              str += '.' + split[1]; // .toString().replace(/0+$/g, '');
            }
          }
          return str;
        }}
        parser={(value) => {
          return Number(
            value
              ? value
                  .replace(symbol, '')
                  .replace(/,/g, '')
                  .replace(/\s?|(,*)/g, '')
              : '',
          );
        }}
        min={min}
        precision={precision}
        max={max}
        step={step}
      />
    </StyledFormItem>
  );
};

export default MoneyInput;

import {
  UsType86EntryTariff,
  UsConsumptionEntryTariff,
  Cost,
} from '@xbcb/api-gateway-client';
import { isRealHts } from '@xbcb/entry-utils/dist/lib/htsUtil';

export const getUnitValueFromProductLineTariffs = (
  tariffs: UsType86EntryTariff[] | UsConsumptionEntryTariff[],
): Cost => {
  const result: Cost = { value: 0, currency: '' };

  tariffs.forEach((tariff) => {
    if (tariff.unitValue && tariff.htsNumber && isRealHts(tariff.htsNumber)) {
      result.value += tariff.unitValue.value;
      // Assuming currency here is always 'USD' for US T86 entries
      result.currency = tariff.unitValue.currency;
    }
  });

  return result;
};

import { useBundle } from '@amzn/react-arb-tools';
import {
  createDataCyValue,
  CssSize,
  DataCyPrefix,
  NamePath,
} from '@xbcb/ui-types';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { capitalCase } from 'change-case';
import { ModeOfTransport } from '@xbcb/shipment-types';
import { Select, Option, FormItem } from '@xbcb/form-item-components';
import { safeGetMessage, show } from '@xbcb/ui-utils';

interface MotSelectProps {
  exclude?: ModeOfTransport[];
  form: FormInstance;
  required?: boolean;
  fullNamePath?: NamePath;
  localNamePath?: NamePath;
  titleAbove?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  $inline?: boolean;
  label?: string;
  $itemSize?: CssSize;
  mode?: 'multiple' | 'tags';
  initialValue?: ModeOfTransport;
  onChange?: (val: any) => any;
  maxTagCount?: number;
  dataCyPrefix?: DataCyPrefix;
}

const MotSelect: React.FC<MotSelectProps> = ({
  exclude = [],
  form,
  required,
  fullNamePath = ['modeOfTransport'],
  localNamePath,
  disabled,
  readOnly,
  $inline,
  label,
  $itemSize,
  mode,
  initialValue,
  onChange,
  maxTagCount,
  dataCyPrefix,
}) => {
  const [motBundle] = useBundle('types.modeOfTransport.enum.ModeOfTransport');
  if (!show({ readOnly, form, field: fullNamePath })) return null;
  return (
    <FormItem
      // If localNamePath is not provided, default to fullNamePath
      name={localNamePath || fullNamePath}
      rules={[{ required }]}
      $itemSize={$itemSize || CssSize.SHORT}
      $inline={$inline}
      label={label}
      $readOnly={readOnly}
      initialValue={initialValue}
      data-cy={dataCyPrefix}
    >
      <Select
        disabled={disabled}
        onChange={(value) => {
          form.setFields([{ name: fullNamePath, value }]);
          onChange && onChange(value);
        }}
        mode={mode}
        maxTagCount={maxTagCount}
        allowClear
      >
        {Object.values(ModeOfTransport)
          .filter((mot) => !exclude.includes(mot))
          .map((mot) => (
            <Option
              value={mot}
              key={mot}
              data-cy={createDataCyValue(
                DataCyPrefix.MODE_OF_TRANSPORT_OPTION,
                mot.toString(),
              )}
            >
              {safeGetMessage(
                motBundle,
                mot.toLowerCase(),
                undefined,
                capitalCase(mot),
              )}
            </Option>
          ))}
      </Select>
    </FormItem>
  );
};

export default MotSelect;

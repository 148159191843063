import { GLITCHWATCH_FEATURE, GlitchWatchFeatures } from '@xbcb/feature-flags';
import { uiStageToBackendStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';

const { stage: envStage } = getEnv();
const stage = uiStageToBackendStage[envStage];

// TODO: Remove Glitchwatch feature flags from UI code
// https://app.asana.com/0/1205995408079733/1206070931845781/f

export const isGlitchWatchUiAdminViewEnabled = false;

export const isGlitchWatchUiAlertButtonEnabled = GLITCHWATCH_FEATURE.isEnabled(
  GlitchWatchFeatures.UI_ALERT_BUTTON,
  {
    stage,
  },
);

import { get } from 'lodash';
import { AppRecordValidateUpdate } from 'routes';
import recordNamePathsWithRequiredPointOfContacts from 'libs/recordNamePathsWithRequiredPointOfContacts';
import { sharedValidateUsEntrySummary } from '../sharedValidateUsEntrySummary';
import { PreferredDutyCollectPartyType } from '@xbcb/finance-types';
import { RecordType } from '@xbcb/shared-types';
import { formatRecordName } from '@xbcb/js-utils';
import { isLargeEntryUXEnabled } from '@xbcb/ui-utils';
import { validateTariffPga } from 'libs/validateTariffPga';
import {
  createAccumulator,
  validateTariffPga as validateTariffPgaV2,
  validateSection232Aluminum,
  validateNRBSNumber,
} from 'libs/entryValidation';
// Edit this if any of the pga forms are changed/added
// TODO There is a lot of duplication of logic here and individual forms. Try to abstract the logic for required fields

const validateUsConsumptionEntry: AppRecordValidateUpdate = ({
  input,
  currentUser,
  store,
}) => {
  const isLargeEntryUX = isLargeEntryUXEnabled(currentUser?.id);
  const validateTariff = isLargeEntryUX
    ? validateTariffPgaV2
    : validateTariffPga;

  const { invoices, buyer, ior, conveyance } = input;
  const { accountType } = currentUser;

  const {
    additionalErrors,
    validateFields,
    validatePartySnapshot,
    validateTabs,
  } = sharedValidateUsEntrySummary({
    input,
    currentUser,
  });
  const accumulator = createAccumulator({ additionalErrors, validateFields });
  const iorName = formatRecordName({
    recordType: RecordType.US_IOR,
    accountType,
  });
  if (validateTabs.commercialInvoices) {
    validatePartySnapshot(['ior', 'usIor', 'id'], iorName, [
      ['preferredPaymentMethod'],
    ]);
    if (!buyer?.sameAsIor) {
      validatePartySnapshot(['buyer', 'buyer', 'id'], 'Buyer');
      const validDunsLengths = [9, 13];
      if (!validDunsLengths.includes(buyer?.duns?.length)) {
        additionalErrors.push({
          title: 'Buyer DUNS Number',
          messages: [`Invalid DUNS number "${buyer?.duns}"`],
        });
      }
    }

    const preferredDutyCollectPartyType =
      input?.group?.shipment?.preferredDutyCollectPartyType;

    invoices?.forEach((invoice: any, invoiceIndex: number) => {
      invoice.products?.forEach((product: any, productIndex: number) => {
        product?.lines?.forEach((line: any, lineIndex: number) => {
          const linePath = [
            'invoices',
            invoiceIndex,
            'products',
            productIndex,
            'lines',
            lineIndex,
          ];
          const arrivalDate = input.arrival.importDate;
          accumulator([
            validateSection232Aluminum(line, linePath, arrivalDate, store),
            validateNRBSNumber(line, lineIndex),
          ]);

          // Only the last tariff needs to be validated as the other tariffs are for
          // additional HTS numbers that only contains duty rates
          if (line.tariffs?.length) {
            const realTariffIndex = line.tariffs.length - 1;
            const tariff = line.tariffs[realTariffIndex];
            const tariffNamePath = [...linePath, 'tariffs', realTariffIndex];
            const descriptionSameAsCBP = tariff?.pgaDescription?.sameAsCbp;
            if (tariff.pga && !descriptionSameAsCBP) {
              validateFields.push([
                ...tariffNamePath,
                'pgaDescription',
                'text',
              ]);
            }
            const tariffErrorString = `Invoice ${invoiceIndex + 1}, product ${
              productIndex + 1
            }, line ${lineIndex + 1}, tariff ${realTariffIndex + 1}`;
            if (tariff.pga) {
              accumulator([
                validateTariff({
                  tariff,
                  iorName,
                  validatePartySnapshot,
                  ior,
                  tariffNamePath,
                  tariffErrorString,
                  line,
                  mot: conveyance?.modeOfTransport,
                }),
              ]);
            }
          }
        });
      });
    });

    //  if preferred duty collection type is CUSTOMS then IOR needs to have a PUN
    if (
      preferredDutyCollectPartyType === PreferredDutyCollectPartyType.CUSTOMS &&
      ior &&
      !ior.payerUnitNumber
    ) {
      additionalErrors.push({
        title: 'Payer Unit Number Missing',
        messages: [
          `Duty Collect Party is set to Customs, but the ${iorName}'s profile does not have a Payer Unit Number. Please enter a valid Payer Unit Number into the ${iorName}'s profile, or change the Duty Collect Party.`,
        ],
      });
    }
    // recordNamePathsWithRequiredPointOfContacts returns NamePath[] where each NamePath
    // is the path to the record (for example `['ior']`) which allows
    // easy access to the record info (ior) as well as the `pointOfContact`
    const recordNamePaths =
      recordNamePathsWithRequiredPointOfContacts(input) || [];
    for (const recordNamePath of recordNamePaths) {
      const recordInfo = get(input, recordNamePath, {});
      const { name, email, phone } = recordInfo?.pointOfContact || {};
      if (!name || !email || !phone?.number) {
        additionalErrors.push({
          title: 'Point of Contact',
          messages: [
            `Please fill out "point of contact" info for ${recordInfo.name} on their page, save, then refresh their dropdown on this page.`,
          ],
        });
      }
    }
  }

  return {
    additionalErrors,
    validateFields,
  };
};

export default validateUsConsumptionEntry;

import { useCallback, useRef } from 'react';

export interface UseTimer {
  startTimer: () => void;
  resetTimer: () => void;
  clearTimer: () => void;
}

type Callback = () => void;

export const useTimer = (interval: number, callback: Callback): UseTimer => {
  const countdown = useRef(0);
  // Timers and hooks
  const startTimer = useCallback(() => {
    countdown.current = window.setTimeout(callback, interval);
  }, [callback, interval]);

  const resetTimer = useCallback(() => {
    window.clearTimeout(countdown.current);
    startTimer();
  }, [startTimer]);

  const clearTimer = useCallback(
    () => window.clearTimeout(countdown.current),
    [countdown],
  );

  return {
    clearTimer,
    startTimer,
    resetTimer,
  };
};

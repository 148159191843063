import { getDutyPayer } from '@xbcb/finance-utils';
import { reportError } from '@xbcb/ui-utils';
export const getPayer = (workOrderGroup: any, workOrderType: any) => {
  let payer = null;
  try {
    payer = getDutyPayer(workOrderGroup, workOrderType);
  } catch (e) {
    reportError(e);
  }
  return payer;
};

import { useLocation } from 'react-router-dom';
import Docs from 'components/Docs';
import { PageContent } from '@xbcb/display-components';
import { RecordProps } from 'routes';
import CloneProductModal from 'components/CloneProductModal';
import ProductPageContents from 'components/ProductPageContents';
import './Product.less';

const Product = (props: RecordProps) => {
  const { disabled, readOnly, record, form, hideDocs } = props;

  const location = useLocation();

  let classificationId = location.search.split('?classificationId=')[1];
  if (!classificationId && record) classificationId = record.classificationId;
  return (
    <>
      <CloneProductModal />
      <PageContent
        rightContent={
          hideDocs ? undefined : (
            <>
              <Docs fullWidth />
            </>
          )
        }
      >
        <ProductPageContents
          form={form}
          record={record}
          disabled={disabled}
          readOnly={readOnly}
        />
      </PageContent>
    </>
  );
};

export default Product;

import { RecordType } from '@xbcb/shared-types';
import { DEFAULT } from './workOrderTabConfiguration';
import { getRecordType } from '@xbcb/core';
import { CustomsDeclaration } from '__generated__/graphql';

export const getImportCountryCodeForCuDe = (
  record?: CustomsDeclaration,
): string => {
  if (!record) return DEFAULT;
  if (getRecordType(record.id) !== RecordType.CUSTOMS_DECLARATION)
    return DEFAULT;
  return record?.goodsShipment?.[0]?.importCountry?.[0]?.country || DEFAULT;
};

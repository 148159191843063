import {
  recordFields,
  recordActionFields,
  addressFields,
} from '@xbcb/shared-queries';
import { WorkOrderType } from '@xbcb/work-order-types';
import { inquiryFields } from './inquiryFields';

export const getEuIorActivationFragment = (
  activationFieldsName: string,
  workOrdertype: WorkOrderType,
): string => {
  const fragment = `fragment ${activationFieldsName} on ${workOrdertype} {
    ...recordFields
    broker {
      customsBroker {
        id
      }
    }
    group {
      id
      forwarders {
        forwarder {
          id
          name
        }
      }
    }
    operator {
      id
    }
    tasks {
      assignee {
        id
      }
    }
    ior {
      ior {
        id
        version
        name
        activations {
          status
          customsBroker {
            id
          }
          brokerRepresentation
          rejectionReasons
        }
        shippers {
          nodes {
            forwarders {
                nodes {
                id
                name
              }
            }
          }
        }
        addresses {
          physical {
            ...addressFields
          }
          mailing {
            ...addressFields
          }
        }
        businessStructure
        industry
        vatNumber
        eori
        documents {
          deleted {
            time
          }
          tags {
            key
            value
          }
          documentTags
        }
      }
    }
    milestones {
      id
      name
      occurenceTime
      source
      created {
        client {
          name
        }
      }
    }
    stateMachine {
      id
      version
    }
    status
    ${inquiryFields}
  }`;
  return fragment;
};

export const getEuIorActivationFragments = (fragment: string): string => {
  return `${fragment}
  ${recordFields}
  ${recordActionFields}
  ${addressFields}
  `;
};

export const gbIorActivationFragment = getEuIorActivationFragment(
  'gbIorActivationFields',
  WorkOrderType.GbIorActivation,
);

export const deIorActivationFragment = getEuIorActivationFragment(
  'deIorActivationFields',
  WorkOrderType.DeIorActivation,
);

export const nlIorActivationFragment = getEuIorActivationFragment(
  'nlIorActivationFields',
  WorkOrderType.NlIorActivation,
);

export const frIorActivationFragment = getEuIorActivationFragment(
  'frIorActivationFields',
  WorkOrderType.FrIorActivation,
);

export const gbIorActivationFragments = getEuIorActivationFragments(
  gbIorActivationFragment,
);

export const deIorActivationFragments = getEuIorActivationFragments(
  deIorActivationFragment,
);

export const nlIorActivationFragments = getEuIorActivationFragments(
  nlIorActivationFragment,
);

export const frIorActivationFragments = getEuIorActivationFragments(
  frIorActivationFragment,
);

import { FormInstance } from 'antd/lib/form';
import { validateForm } from '@xbcb/ui-utils';
import { NamePath, AdditionalFormError } from '@xbcb/ui-types';
import type { UsConsumptionEntryCommercialInvoice as ApiGUsConsumptionEntryCommercialInvoice } from '@xbcb/api-gateway-client';
import { UsConsumptionEntryCommercialInvoiceAdjustmentType } from '@xbcb/work-order-types';
import { roundNumber } from 'libs/format';

type validateEntryForDraftSummaryProps = {
  form: FormInstance;
  record: any;
};

// For details on the validations refer: https://app.asana.com/0/0/1201272531703380/1201348396172264/f
export const validateEntryForDraftSummary = async ({
  form,
  record,
}: validateEntryForDraftSummaryProps): Promise<boolean> => {
  const validateParams: {
    form: FormInstance;
    additionalErrors?: AdditionalFormError[];
    validateFields?: NamePath[];
  } = { form };
  const errors: string[] = [];

  // IOR
  if (!record.ior) errors.push('IOR missing for the entry');
  // Consignee
  if (!record.consignee) errors.push('Consignee missing for the entry');
  // Buyer
  if (!record.buyer) errors.push('Buyer missing for the entry');

  // Entry Number
  if (!record.entryNumber)
    errors.push('Entry Number missing for the the entry');

  const invoices: ApiGUsConsumptionEntryCommercialInvoice[] = record.invoices;

  if (!invoices || !invoices.length) {
    errors.push('invoice data missing');
  }

  invoices?.forEach((invoice, invoiceIdx: number) => {
    const invoicePrefix = `[Invoice: ${invoiceIdx + 1}]`;
    // Invoice #
    if (!invoice.invoiceNumber)
      errors.push(`${invoicePrefix} Invoice number missing`);

    // Invoice value = calculated value
    const products = invoice.products;
    const productsValue =
      products?.reduce(
        (acc: number, product) => acc + (product?.totalValue?.value || 0),
        0,
      ) || 0;
    const adjustments = invoice.adjustments || [];
    const netAdjustment = adjustments.reduce((acc: number, adjustment) => {
      const type =
        adjustment?.type as UsConsumptionEntryCommercialInvoiceAdjustmentType;
      const value = adjustment?.value?.value;
      if (type && value) {
        if (type === UsConsumptionEntryCommercialInvoiceAdjustmentType.ADD)
          return acc + value;
        if (type === UsConsumptionEntryCommercialInvoiceAdjustmentType.SUBTRACT)
          return acc - value;
      }
      return acc;
    }, 0);
    const calculatedValue = roundNumber(productsValue + netAdjustment, 2) || 0;
    const invoiceValue = invoice?.value?.value || 0;
    const valueMatch = Boolean(invoiceValue === calculatedValue);

    if (!valueMatch)
      errors.push(
        `${invoicePrefix} invoice value: ${invoiceValue} is not equal to calculated value from the products: ${calculatedValue}`,
      );
    // Exchange Rate
    if (invoice.value?.currency !== 'USD' && !invoice.currencyRate)
      errors.push(`${invoicePrefix} currency rate missing`);

    products?.forEach((product, productIdx: number) => {
      // Line total values
      const productPrefix = `${invoicePrefix}:[ Product ${productIdx + 1}]`;
      if (!product.totalValue)
        errors.push(`${productPrefix} missing totalValue`);
      const lines = product.lines || [];
      lines.forEach((line, lineIdx) => {
        const linePrefix = `${productPrefix}: [Line ${lineIdx + 1}]`;
        const tariffs = line.tariffs;
        // CBP descriptions
        if (!line.description)
          errors.push(`${linePrefix} cbp description missing`);

        // Manufacturers
        if (!line.manufacturer)
          errors.push(`${linePrefix} manufacturer missing`);

        // COOs
        if (!line.origin?.countryCode)
          errors.push(`${linePrefix} country of origin  missing`);

        tariffs?.forEach((tariff, tariffIdx: number) => {
          const tariffPrefix = `${linePrefix}: [Tariff ${tariffIdx + 1}]`;

          // Line quantities
          tariff.unitReportingQuantities?.forEach(({ value, unit }) => {
            if (!unit) errors.push(`${tariffPrefix} quantity unit missing`);
            if (!value) errors.push(`${tariffPrefix} quantity value missing`);

            // Currency
            if (!tariff.unitValue?.currency)
              errors.push(`${tariffPrefix} Currency missing`);
          });

          // HTSUS codes
          if (!tariff.htsNumber)
            errors.push(`${tariffPrefix} HTS code missing`);
        });
      });
    });
  });
  if (errors.length)
    validateParams.additionalErrors = [
      {
        title: 'Please fix the following errors',
        messages: errors,
      },
    ];
  const valid = await validateForm(validateParams);
  return valid;
};

import styled from 'styled-components';
import { FormItem, InfoTooltip } from '@xbcb/form-item-components';

export const StyledFlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledFlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0;
`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  margin-top: ${({ theme }) => theme.spacing.space_1};
`;

import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import LpcoAph from 'components/LpcoAph';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from '@xbcb/ui-types';
import { StyledDiv, StyledAddButton } from './styles';

export interface AphCoreFormLpcosProps {
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  required?: boolean;
}

const AphCoreFormLpcos = ({
  form,
  readOnly,
  disabled,
  localNamePath,
  fullNamePath,
  required,
}: AphCoreFormLpcosProps) => {
  return (
    <StyledDiv>
      <h4 className="space-bottom">
        Licenses, Permits, Certificates or Other (LPCO)
      </h4>
      <Form.List name={[...localNamePath]} key="LPCO">
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <LpcoAph
                    form={form}
                    fullNamePath={[...fullNamePath, index]}
                    localNamePath={[index]}
                    readOnly={readOnly}
                    disabled={disabled}
                    required={required}
                    onRemove={() => remove(field.name)}
                    allowDelete
                    index={index}
                  />
                </Form.Item>
              </div>
            ))}
            {!readOnly && (
              <StyledAddButton onClick={add}>
                <PlusOutlined />
                LPCO
              </StyledAddButton>
            )}
          </div>
        )}
      </Form.List>
    </StyledDiv>
  );
};

export default AphCoreFormLpcos;

import styled from 'styled-components';
import { Spin } from 'antd';

export const StyledSpin = styled(Spin)<{ $whiteSpinner: boolean }>`
  ${({ $whiteSpinner }) =>
    $whiteSpinner &&
    ` color: white;
      .ant-spin-dot i {
        background-color: white;
      }`}
`;
export const StyledButterContentLoaderDiv = styled.div`
  text-align: center;
`;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { awsRumClient } from '@xbcb/cloudwatch-rum';
import { getRecordType } from '@xbcb/core';

export const formatUrl = (url: string): string => {
  const segments = url.split('/');
  const formattedSegments = segments.map((segment) =>
    getRecordType(segment) ? '*' : segment,
  );
  return formattedSegments.join('/');
};

export const useTrackPageLoad = (): void => {
  const location = useLocation();
  const path = formatUrl(location.pathname);
  useEffect(() => {
    awsRumClient.recordPageView(path);
  }, [path]);
};

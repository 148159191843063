import React from 'react';
import { Checkbox } from 'antd';
/* import { get } from 'lodash'; */
import RecordSelect from 'components/RecordSelect';
import { AccountType, RecordType, AnyObject } from '@xbcb/shared-types';
import { FormInstance } from 'antd/lib/form';
import { SingleFormCard } from '@xbcb/display-components';
import { CssSize, NamePath, DataCyPrefix } from '@xbcb/ui-types';
import { useCurrentUser } from 'libs/hooks';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

export type ShipperSelectProps = {
  form: FormInstance;
  shipperIds?: string[];
  disabled?: boolean;
  readOnly?: boolean;
  recordType?: RecordType;
  recordId?: string; // should not be required. create record is an edge case.
  unassignedId?: string;
  noCardWrap?: boolean;
  multiple?: boolean;
  namePath?: NamePath;
  // Additional search fields that are used in the searchCriteriaInput
  // for the table query.
  additionalSearchCriteria?: AnyObject;
  additionalSearchFields?: string;
  onChange?: (val: any) => void;
  // If true would select the first available record by default.
  selectOneRecordByDefault?: boolean;
  // Would disable the record select if there is only record to be choosen. Works best in conjunction to selectFirstRecordByDefault.
  disableSelectionForSingleRecord?: boolean;
};

const ShipperSelect = ({
  form,
  shipperIds = [],
  disabled,
  readOnly,
  recordType,
  recordId,
  unassignedId,
  noCardWrap,
  additionalSearchCriteria,
  multiple = true,
  namePath = ['shipper'],
  additionalSearchFields,
  selectOneRecordByDefault,
  disableSelectionForSingleRecord,
  onChange,
}: ShipperSelectProps) => {
  /* const shipperSelectDisabled = disabled; */
  /* const shipperSelectReadOnly = readOnly; */
  const [shipperSelectBundle] = useBundle('components.ShipperSelect');
  const { getFieldValue } = form;
  const { accountType } = useCurrentUser();
  const selectAll = (evt: any) => {
    if (!evt || !evt.target) return;
    const { checked } = evt.target;
    if (checked) {
      form.setFieldsValue({ shippers: shipperIds });
    } else {
      form.setFieldsValue({ shippers: [] });
    }
  };

  if (accountType === AccountType.SHIPPER) return null;
  if (recordType === RecordType.SHIPPER) return null;

  /* if ( */
  /*   (recordType === RecordType.FORWARDER && */
  /*     accountType === AccountType.OPERATOR) || */
  /*   (recordType === RecordType.PRODUCT && recordId !== 'create') || */
  /*   (recordType === RecordType.CLASSIFICATION && */
  /*     (accountType === AccountType.OPERATOR || */
  /*       (!getFieldValue(['shipperId']).startsWith('F') && */
  /*         recordId !== 'create'))) */
  /* ) { */
  /*   shipperSelectDisabled = true; */
  /*   shipperSelectReadOnly = true; */
  /* } */

  const shipperSelect = (
    <RecordSelect
      label=""
      fullNamePath={namePath}
      multiple={multiple}
      recordType={RecordType.SHIPPER}
      $itemSize={CssSize.SHORT}
      required={
        !(
          accountType === AccountType.OPERATOR &&
          recordType === RecordType.FORWARDER
        )
      }
      form={form}
      hideLabel={true}
      disabled={disabled}
      readOnly={readOnly}
      unassignedId={unassignedId}
      onChange={onChange}
      additionalSearchCriteria={additionalSearchCriteria}
      additionalSearchFields={additionalSearchFields}
      selectOneRecordByDefault={selectOneRecordByDefault}
      disableSelectionForSingleRecord={disableSelectionForSingleRecord}
      dataCySuffix={DataCyPrefix.SHIPPER_SELECT}
    />
  );

  const shippersValue = getFieldValue('shipper');
  const selectedCount = shippersValue ? shippersValue.length : 0;
  const storedCount = shipperIds.length;
  const allSelected = selectedCount === storedCount;
  const indeterminate = selectedCount > 0 && selectedCount < storedCount;

  const content = (
    <div
      className={
        multiple
          ? 'shipper-associations select-all-toggle'
          : 'shipper-associations'
      }
    >
      {shipperSelect}
      {false && // Remove this once we finalize on the approach. For now disable the check all
        multiple &&
        !disabled && (
          <Checkbox
            className="select-all"
            checked={allSelected}
            indeterminate={indeterminate}
            disabled={disabled} // for isFetching
            onChange={selectAll}
          >
            {safeGetMessage(shipperSelectBundle, 'select_all')}
          </Checkbox>
        )}
    </div>
  );

  return noCardWrap ? (
    content
  ) : (
    <SingleFormCard
      title={safeGetMessage(shipperSelectBundle, 'shipper', { multiple })}
    >
      {content}
    </SingleFormCard>
  );
};

export default ShipperSelect;

import { AccountType } from '@xbcb/shared-types';

export const LOGOUT = 'LOGOUT';
export const USER_CHANGE_TYPE = 'USER_CHANGE_TYPE';
export const USER_LOGIN_CHANGE = 'USER_LOGIN_CHANGE';
export const AVATAR_CHANGE = 'AVATAR_CHANGE';

export type UserLoginState = {
  userToken?: string;
  'custom:type'?: string;
  'custom:onboardingType'?: string;
  avatarUrl?: string;
  email?: string;
  // eslint-disable-next-line camelcase
  email_verified?: boolean;
  sub?: string;
  userId?: string;
  accountType?: AccountType;
  masterView?: boolean;
};

export type UserLoginPayload = UserLoginState;

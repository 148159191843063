import React from 'react';
import { safeGet } from '@xbcb/js-utils';
import { RecordType } from '@xbcb/shared-types';
import SubMenuOptions, {
  SubMenuOptionItems,
  SubMenuOptionsProps,
} from 'components/SubMenuOptions';
import { useMemos } from 'libs/hooks';

interface AppRecordMemoKebabSubMenuOptionsProps extends SubMenuOptionsProps {
  recordType: RecordType;
}

const AppRecordMemoKebabSubMenuOptions = ({
  action,
  record,
  recordType,
  ...restProps
}: AppRecordMemoKebabSubMenuOptionsProps) => {
  const { configurations } = useMemos(recordType);
  const items = configurations.reduce((items, config) => {
    // We only want to show memos for fields that exist. For example,
    // if an entry does not have a Shipper or US IOR yet, these options
    // should not show up in the kebab menu.
    const {
      key,
      label = '',
      labelNamePath = '',
      namePathToRecordWithMemos = '',
      text,
    } = config;
    const memoPath = namePathToRecordWithMemos
      ? `${namePathToRecordWithMemos}.memos`
      : 'memos';
    const hasMemos = safeGet(record, memoPath);
    if (hasMemos) {
      items[key] = {
        item: (
          <div key={key}>
            <span>{text}</span>
          </div>
        ),
        itemProps: {
          ...config,
          label,
          labelNamePath,
          namePathToRecordWithMemos,
        },
      };
    }
    return items;
  }, {} as SubMenuOptionItems);
  return (
    <SubMenuOptions
      {...restProps}
      action={action}
      record={record}
      items={items}
    />
  );
};

export default AppRecordMemoKebabSubMenuOptions;

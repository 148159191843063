import { WorkOrderRoute } from '.';
import { RecordType } from '@xbcb/shared-types';
import { FrCustomsEntry } from '../../loadableComponents';
import { frCustomsEntryFragments } from '@xbcb/work-order-queries';
import {
  getSharedCustomsEntrySubmitButtons,
  sharedCustomsEntryKebabMenuItems,
  sharedCustomsEntryFields,
} from '../utils';
import { WorkOrderType } from '@xbcb/work-order-types';
import { transformEuCustomsEntry } from 'libs/formTransforms/workOrder/euCustomsEntry';
import CustomsEntrySecondaryHeader from 'components/CustomsEntrySecondaryHeader';

const frCustomsEntryFields = `
  ...frCustomsEntryFields
`;

const frCustomsEntryRoute: WorkOrderRoute = {
  Page: FrCustomsEntry,
  recordType: RecordType.FR_CUSTOMS_ENTRY,
  fields: `${sharedCustomsEntryFields}${frCustomsEntryFields}`,
  fragments: frCustomsEntryFragments,
  SecondaryHeaderContents: [CustomsEntrySecondaryHeader],
  transformUpdateRecordInput: transformEuCustomsEntry.toSchema,
  defaultEditMode: false,
  kebabMenuItems: sharedCustomsEntryKebabMenuItems,
  submitButtons: getSharedCustomsEntrySubmitButtons(
    WorkOrderType.FrCustomsEntry,
  ),
};

export default frCustomsEntryRoute;

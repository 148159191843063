import { AnyObject } from '@xbcb/shared-types';

export const transformBusinessSupportTeam = {
  toForm: ({
    existingRecord: newRecord,
  }: {
    existingRecord: AnyObject;
  }): AnyObject => {
    const { operatorUsers } = newRecord;
    const members: { id: string[] } = { id: [] };

    operatorUsers?.edges?.forEach((edge: AnyObject) => {
      const { id } = edge.node;
      members.id.push(id);
    });

    return {
      ...newRecord,
      members,
    };
  },

  toSchema: ({
    input,
  }: {
    input: any;
    existingRecord?: any;
    isCreate?: boolean;
  }): AnyObject => {
    const values = { ...input };
    if (values.members) {
      values.members = values.members.id.map((id: string) => ({ userId: id }));
    }
    delete values.operator;
    delete values.operatorUsers;

    return values;
  },
};

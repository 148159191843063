import React from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { RecordType } from '@xbcb/shared-types';
import RecordSelect from 'components/RecordSelect';
import { NamePath, CssSize } from '@xbcb/ui-types';
import { INLTSwitch as Switch } from '@xbcb/form-item-components';
import { clearFields } from 'libs/clearFields';
import { capitalCase } from 'change-case';
import { LabelAbove } from '@xbcb/static-text-components';
import {
  getAddressRequiredFields,
  getNameRequiredField,
  getDunsRequiredField,
} from 'libs/requiredEmbeddedFieldHelpers';
import {
  StyledDiv,
  StyledRecordSelectDiv,
  StyledCustomsAgentDiv,
} from './styles';

export type FdaCustomsAgentProps = {
  fullNamePath: NamePath;
  localNamePath: NamePath;
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  type: 'foreignSupplierVerificationProgram' | 'deviceInitialImporter';
};

const FdaCustomsAgent = ({
  fullNamePath,
  localNamePath,
  form,
  readOnly,
  disabled,
  type,
}: FdaCustomsAgentProps) => {
  const baseFullNamePath = [...fullNamePath, type];
  const fullSameAsIorNamePath = [...baseFullNamePath, 'sameAsIor'];
  const localSameAsIorNamePath = [...localNamePath, type, 'sameAsIor'];

  let embeddedFields = `name ...addressesFields`;
  const pointOfContact =
    'pointOfContact { name email phone { country number extension } }';

  if (type === 'foreignSupplierVerificationProgram') {
    embeddedFields += ` ${pointOfContact} duns`;
  }

  const shouldShowSwitch = () => {
    const iorCountry = form.getFieldValue(['ior', 'address', 'countryCode']);
    return iorCountry === 'US';
  };

  const requiredEmbeddedFields = [
    ...getAddressRequiredFields(baseFullNamePath),
    getNameRequiredField(baseFullNamePath),
  ];
  if (type === 'foreignSupplierVerificationProgram')
    requiredEmbeddedFields.push(getDunsRequiredField(baseFullNamePath));

  return (
    <Form.Item noStyle shouldUpdate={true}>
      {() => {
        const isSwitchVisible = shouldShowSwitch();
        const sameAsIor = form.getFieldValue(fullSameAsIorNamePath);
        return (
          <StyledCustomsAgentDiv>
            <LabelAbove text={capitalCase(type)} />
            <StyledDiv>
              {!sameAsIor && (
                <StyledRecordSelectDiv>
                  <RecordSelect
                    hideLabel
                    required
                    recordType={RecordType.CUSTOMS_AGENT}
                    fullNamePath={[...baseFullNamePath, 'customsAgent']}
                    localNamePath={[...localNamePath, type, 'customsAgent']}
                    form={form}
                    disabled={disabled}
                    versioned
                    readOnly={readOnly}
                    $itemSize={CssSize.SHORT}
                    embeddedFields={embeddedFields}
                    requiredEmbeddedFields={requiredEmbeddedFields}
                    mapEmbeddedFields={(record: any) => {
                      const { addresses, ...newRecord } = record;
                      newRecord.address =
                        addresses?.mailing || addresses?.physical;
                      return newRecord;
                    }}
                    getExcludedOptions={() => ['199900-02534']} // prevent Amazon's DC CAIN from being entered
                    create
                  />
                </StyledRecordSelectDiv>
              )}

              {isSwitchVisible && (
                <StyledDiv>
                  <Switch
                    size="small"
                    field={localSameAsIorNamePath}
                    disabled={disabled}
                    readOnly={readOnly}
                    onWord=""
                    offWord=""
                    $inline
                    initialValue={
                      form.getFieldValue(fullSameAsIorNamePath) === undefined &&
                      true
                    }
                    onChange={(checked: boolean) => {
                      if (checked) {
                        clearFields({
                          form,
                          fullNamePath: [...baseFullNamePath],
                          exclude: ['sameAsIor'],
                        })();
                      }
                    }}
                  />
                  {sameAsIor ? 'Same as' : 'Different than'} Importer of Record
                </StyledDiv>
              )}
            </StyledDiv>
          </StyledCustomsAgentDiv>
        );
      }}
    </Form.Item>
  );
};

export default FdaCustomsAgent;

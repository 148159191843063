import React from 'react';
import { Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from 'rc-field-form/lib/interface';
import { getNumber, show, safeGetMessage } from '@xbcb/ui-utils';
import { CssSize, DataCyPrefix } from '@xbcb/ui-types';
import { StyledDiv, StyledFormItem, StyledTooltip } from './styles';
import { useBundle } from '@amzn/react-arb-tools';

interface FEINumberProps {
  disabled?: boolean;
  readOnly?: boolean;
  form: FormInstance;
  $inline?: boolean;
  field?: NamePath;
}

const FEINumber = ({
  disabled,
  readOnly,
  form,
  $inline,
  field = 'fei',
}: FEINumberProps) => {
  const [partyBundle] = useBundle('components.Party');
  const showComponent = show({ readOnly, form, field });
  return showComponent ? (
    <StyledDiv $inline={$inline}>
      <StyledFormItem
        $itemSize={CssSize.TINY_SHORT}
        name={field}
        rules={[{ required: false, message: ' ', min: 4, max: 11 }]}
        getValueFromEvent={getNumber(11)}
        label={safeGetMessage(partyBundle, 'fda_fei_number')}
        $readOnly={readOnly}
        data-cy={DataCyPrefix.FEI_NUMBER}
      >
        <Input placeholder="NNNNNNNNNNN" disabled={disabled} />
      </StyledFormItem>
      <StyledTooltip
        spaceTop
        title={safeGetMessage(partyBundle, 'fda_fei_number_tooltip')}
      />
    </StyledDiv>
  ) : null;
};

export default FEINumber;

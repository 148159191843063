import {
  workOrderTaskFragments,
  workOrderTaskStepFragments,
  usPostSummaryCorrectionFragments as defaultUsPostSummaryCorrectionFragments,
} from '@xbcb/work-order-queries';

export const usPostSummaryCorrectionFields = `
  ...usPostSummaryCorrectionFields
  status
  ior {
    usIor {
      industry
    }
  }
  group{
    shipment{
      preferredDutyCollectPartyType
    }
    documents {
      deleted {
        time
      }
      documentTags
    }
    shipper {
      id
      status
      billingDetails {
        disbursement
        subscriptionPlan
        status
        customFeeConfig {
          singleEntryBond {
            rate
            minimum
          }
          disbursement {
            rate
          }
        }
      }
      forwarders {
        nodes {
          id
          billingDetails {
            disbursement
            subscriptionPlan
          }
        }
      }
    }
    forwarders {
      forwarder {
        id
        billingDetails {
          disbursement
          subscriptionPlan
          status
          customFeeConfig {
            singleEntryBond {
              rate
              minimum
            }
            disbursement {
              rate
            }
          }
        }
      }      
    }
  }
  tasks {
    ...workOrderTaskFields
    assignee {
      id
      name
    }
    steps {
      id
      name
      description
      isCompletedBySystem
      isFinalConfirmation
    }
    definition {
      id
      workOrderTaskType
    }
  }
  milestones {
    id
    name
    occurenceTime
    source
    created {
      client {
        name
      }
    }
  }
`;

export const usPostSummaryCorrectionFragments = `${defaultUsPostSummaryCorrectionFragments}${workOrderTaskFragments}${workOrderTaskStepFragments}`;

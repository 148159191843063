import styled from 'styled-components';

export const StyledHyperlink = styled.a`
  width: fit-content;
  display: block;
  color: ${({ theme }) => theme.palette.greens.forest};
  &:hover {
    color: ${({ theme }) => theme.palette.purples.gamma};
  }
`;

import {
  EuCustomsEntryExceptionTaskType,
  EuCustomsEntryWorkOrderType,
  WorkOrderTaskType,
  WorkOrderType,
} from '@xbcb/work-order-types';
import { camelCase } from 'change-case';
const workOrderTypeToNoPrefixWorkOrderTaskTypeMap = new Map<
  WorkOrderType,
  WorkOrderTaskType[]
>([
  [
    WorkOrderType.UsIorContinuousBondRequest,
    [
      WorkOrderTaskType.ACQUIRE_US_IOR_CONTINUOUS_BOND,
      WorkOrderTaskType.US_IOR_CONTINUOUS_BOND_CHARGE_BOND_RENEWAL_EXCEPTION,
      WorkOrderTaskType.US_IOR_CONTINUOUS_BOND_VERIFY_RENEWAL_EXCEPTION,
    ],
  ],
  ...Object.keys(EuCustomsEntryWorkOrderType).map(
    (workOrderType) =>
      [workOrderType, [...Object.keys(EuCustomsEntryExceptionTaskType)]] as [
        WorkOrderType,
        WorkOrderTaskType[],
      ],
  ),
]);
// This filters the WorkOrderTaskTypes on the basis of the WorkOrderType passed. If the WorkOrderTypes and WorkOrderTaskTypes do not follow the naming convention then they
// are filtered through the workOrderTypeToNoPrefixWorkOrderTaskTypeMap (It is present in filterWorkOrderTaskTypes file and contains ones which do not follow the naming convention).
export const filterWorkOrderTaskTypes = (
  workOrderType: WorkOrderType,
  workOrderTaskTypes: WorkOrderTaskType[] = Object.values(
    WorkOrderTaskType,
  ).map((wotType) => wotType),
): WorkOrderTaskType[] => {
  return workOrderTaskTypes.filter((workOrderTaskType) => {
    if (camelCase(workOrderTaskType).startsWith(camelCase(workOrderType)))
      return true;
    else if (
      workOrderTypeToNoPrefixWorkOrderTaskTypeMap
        .get(workOrderType)
        ?.includes(workOrderTaskType)
    )
      return true;
    else return false;
  });
};

import { Button } from 'antd';
import styled from 'styled-components';
import { WarningOutlined } from '@ant-design/icons';
import { FormItem } from '@xbcb/form-item-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledHtsSummaryDiv = styled.div<{ readOnly?: boolean }>`
  margin-top: ${({ readOnly }) => (readOnly ? 0 : '12px')};
`;

export const StyledTextParagraph = styled.p`
  margin-bottom: 0;
`;

export const StyledHtsDutyParagraph = styled.p`
  margin-right: ${({ theme }) => theme.spacing.space_3};
  margin-bottom: 0;
`;

export const StyledFlexDiv = styled.div`
  display: flex;
`;

export const StyledObjIconDiv = styled.div`
  line-height: 22px;
  margin-right: ${({ theme }) => theme.spacing.space_3};
`;

export const StyledSectionFormItem = styled(FormItem)`
  margin: 0;
  .ant-form-item-control-input {
    min-height: 0;
  }
`;

export const StyledAdditionalHtsButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing.space_3};
`;

export const StyledWarningOutlined = styled(WarningOutlined)`
  color: var(--primary-color);
`;

export const StyledLoadingWrapper = styled.div`
  margin-top: ${CssSpace.SPACE_2};
  display: flex;
  align-items: center;
`;

import getQueryParameters from '../../../libs/getQueryParameters';
import { WorkOrderTaskStatus, WorkOrderTaskType } from '@xbcb/work-order-types';
import type { WorkOrderTask } from '@xbcb/api-gateway-client';
import { Location } from 'history';

export const blockUnblockTaskShowFunctionHelper = (
  block: boolean,
  // record has any type in AppRecordKebabMenuShowItemProps
  record: any,
  location: Location,
): boolean => {
  const queryParameters = getQueryParameters(location);
  const workOrderTaskType = queryParameters['task'];
  const { tasks } = record;

  const task = tasks.find(
    (task: WorkOrderTask) =>
      task.definition?.workOrderTaskType === workOrderTaskType,
  );

  if (!task) {
    return false;
  }

  const isBlockedWorkOrderTask =
    task && task.status === WorkOrderTaskStatus.BLOCKED;

  const isVerifyRenewalTask =
    task.definition?.workOrderTaskType ===
    WorkOrderTaskType.US_IOR_CONTINUOUS_BOND_VERIFY_RENEWAL_EXCEPTION;

  return block
    ? isVerifyRenewalTask && !isBlockedWorkOrderTask
    : isVerifyRenewalTask && isBlockedWorkOrderTask;
};

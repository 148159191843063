import { Row } from 'antd';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
  padding-bottom: ${({ theme }) => theme.spacing.space_3};
`;

// Matching style of FormItem
export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.space_4};
`;

import styled from 'styled-components';
import { Modal, Table } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-footer {
    border-top: none;
    padding: 0 24px 24px 24px;
  }
  .ant-modal-body {
    padding: 24px 24px 8px 24px;
  }
`;

export const StyledTable = styled(Table)`
  .ant-form-item {
    margin: 0;
  }
`;

import { OperatingRegion } from '@xbcb/shared-types';

const operatingRegionMap: { [key: string]: OperatingRegion } = {
  usCustomsBroker: OperatingRegion.US,
  gbCustomsBroker: OperatingRegion.GB,
};

export const getOperatingRegion = (customsBrokerId: string) => {
  const customsBrokerIdPrefix = customsBrokerId?.split('_')[0];
  if (customsBrokerIdPrefix in operatingRegionMap) {
    return operatingRegionMap[customsBrokerIdPrefix];
  }
  return;
};

import {
  createSelector,
  defaultMemoize,
  createSelectorCreator,
} from 'reselect';
import {
  getDocFolder,
  getCodes,
  getCountryCodes,
  getCurrencyCodes,
  reportError,
} from '@xbcb/ui-utils';
import { AccountType, RecordType } from '@xbcb/shared-types';

import { omit, get, isEqual } from 'lodash';
import moment from 'moment';
import { titleCase, formatDate } from 'libs/display';

export * from './charts';

export const getIntendedUse = (intendedUseCode) => {
  const codes = getCodes().CBP.CATAIR.PGA.intendedUse; // Here's the object with all the info that we want to extract now that we know our allowed Intended use codes
  if (!intendedUseCode) return undefined;
  if (!codes) return undefined;
  const raw = intendedUseCode.replace(/\./g, '');

  const baseCode = raw.substr(0, 3);
  const subCode = raw.substr(3, 6);

  const baseObj = get(codes, baseCode, {});
  const subObj = get(baseObj, ['subCodes', subCode]);
  if (!subObj) return;
  const consolidatedObj = {
    baseName: baseObj.name,
    baseDescription: baseObj.description,
    subName: subObj.name,
    subDescription: subObj.description,
  };
  return consolidatedObj;
};

export const entity = (state) => {
  const idName = `${state.data.profile.accountType}Id`;
  const entityId = state.data.profile[idName];
  return { [idName]: entityId };
};

export const getEntityId = (state) => {
  const idName = `${state.data.profile.accountType}Id`;
  return state.data.profile[idName];
};

export const importerForwarders = createSelector(
  (state) => state.data.profile.accountType,
  (state) => state.data.forwarders,
  (state, { importerId }) => importerId,
  (accountType, forwarders, importerId) => {
    if (accountType !== AccountType.OPERATOR) {
      return {};
    } else {
      return Object.keys(forwarders).reduce((acc, forwarderId) => {
        const forwarder = forwarders[forwarderId];
        if (
          forwarder.importerIds &&
          forwarder.importerIds.includes(importerId) &&
          forwarder.props &&
          forwarder.props.name
        ) {
          acc[forwarderId] = forwarder.props.name;
        }
        return acc;
      }, {});
    }
  },
);

export const getAccountType = ({ data }) => data.profile.accountType;
export const allowAccountDetailEditing = createSelector(
  getAccountType,
  ({ data }) => get(data, 'profile.permissions.users'),
  ({ data }) => get(data, 'company.plan'),
  getEntityId,
  (state, { entity, profile }) => ({ entity, profile }),
  (accountType, userPermission, companyPlan, entityId, { entity, profile }) => {
    return {
      allowEditing:
        profile ||
        accountType === AccountType.OPERATOR ||
        (userPermission >= 2 &&
          companyPlan &&
          !get(entity, 'plan') &&
          get(entity, 'parentId') === entityId),
    };
  },
);

export const accountTypeExtended = createSelector(
  ({ data }) => data.profile.accountType,
  ({ data }) => data.company.plan,
  (accountType, plan) =>
    accountType === AccountType.FORWARDER
      ? plan
        ? 'forwarder'
        : 'agent'
      : accountType === AccountType.SHIPPER
      ? plan
        ? 'importer'
        : 'npi'
      : accountType,
);

const objectViewsTransform = (
  accountType,
  extendedAccountType,
  permissions,
  parentId,
  grandParentId,
) =>
  Object.keys(omit(RecordType, [RecordType.USER, RecordType.TEMPLATE])).reduce(
    (acc, recordType) => {
      if (permissions) {
        if (
          accountType !== recordType.slice(0, -1) ||
          recordType === RecordType.FORWARDER
        ) {
          if (
            (extendedAccountType !== 'agent' ||
              recordType === RecordType.SHIPMENT ||
              (recordType === RecordType.SHIPPER &&
                parentId &&
                parentId.startsWith('F') &&
                !grandParentId) ||
              (recordType === RecordType.FORWARDER && !grandParentId)) &&
            (extendedAccountType !== 'npi' ||
              ![RecordType.FORWARDER, RecordType.INVOICE].includes(recordType))
          ) {
            if (permissions[recordType] && permissions[recordType] >= 1) {
              acc.push(recordType);
            }
          }
        }
      }
      return acc;
    },
    [],
  );

export const objectViews = createSelector(
  ({ data }) => data.profile.accountType,
  accountTypeExtended,
  (state) => state.data.profile.permissions,
  (state) => state.data.company.parentId,
  (state) => state.data.company.grandParentId,
  (state) => state.data.company.plan,
  objectViewsTransform,
);

export const objectsLoading = createSelector(
  objectViews,
  (state) => state.ui,
  (objects, ui) =>
    objects.reduce(
      (acc, recordType) => ({ ...acc, [recordType]: ui[recordType].isLoading }),
      {},
    ),
);

export const allSearchObjectsLoaded = createSelector(
  objectViews,
  (state) => state.ui,
  (objects, ui) =>
    objects.reduce((acc, recordType) => acc && ui[recordType].loaded, true),
);

const helpers = {};

export const makeDataMapHelper = (recordType) => {
  if (helpers[recordType]) return helpers[recordType];
  helpers[recordType] = createSelector(
    ({ data }) => data.suppliers,
    ({ data }) => data.importers,
    ({ data }) => data.forwarders,
    ({ data }) => data.shipments,
    ({ data }) => data.classifications,
    ({ data }) => data.profile.accountType,
    (
      suppliers,
      importers,
      forwarders,
      shipments,
      classifications,
      accountType,
    ) => {
      const helper = {
        countryCodes: getCountryCodes(),
        accountType,
      };
      if (
        accountType !== AccountType.SHIPPER &&
        ![RecordType.USER, RecordType.SHIPPER, RecordType.INVOICE].includes(
          recordType,
        )
      ) {
        helper.importers = importers;
        helper.forwarders = forwarders;
      }
      if (
        accountType === AccountType.OPERATOR &&
        recordType === RecordType.SHIPPER
      ) {
        helper.forwarders = forwarders;
      }
      if (
        [RecordType.PRODUCT, RecordType.SHIPMENT, RecordType.INVOICE].includes(
          recordType,
        )
      ) {
        helper.suppliers = suppliers;
      }
      if (recordType === RecordType.PRODUCT) {
        helper.currencyCodes = getCurrencyCodes();
      }
      if (recordType === RecordType.SHIPMENT) {
        helper.portOfLadingCodes = getCodes().USACE.scheduleK;
        helper.portOfUnladingCodes = getCodes().CBP.port;
      }
      if (recordType === RecordType.INVOICE) {
        helper.importers = importers;
        helper.forwarders = forwarders;
        helper.shipments = shipments;
        helper.classifications = classifications;
      }
      reportError('DEPRECATED: call to makeDataMapHelper');
      return helper;
    },
  );
  return helpers[recordType];
};

export const invoicesProps = createSelector(
  ({ data }) => data.profile.accountType,
  ({ data }) => data.invoices,
  (accountType, invoices) => {
    const props = {};
    Object.values(invoices).forEach((invoice) => {
      if (
        invoice.type === 'sub' &&
        accountType !== AccountType.OPERATOR &&
        !props.subscriptionText
      ) {
        props.subscriptionText = `${titleCase(invoice.plan)} subcription`;
        props.periodText = `through ${formatDate(
          moment(invoice.invoicedTime).add(
            1,
            invoice.period === 'monthly' ? 'M' : 'Y',
          ),
        )}`;
      }
    });
    return props;
  },
);

export const intendedUseCodes = createSelector(
  ({ agencyCode }) => agencyCode,
  ({ programCode }) => programCode,
  ({ processingCode }) => processingCode,
  ({ drugForm }) => drugForm,
  (agencyCode, programCode, processingCode, drugForm) => {
    if (drugForm) {
      drugForm = drugForm.toLowerCase();
    }

    const allowedUseCodesMap = get(
      getCodes(),
      'CBP.CATAIR.PGA.allowedIntendedUse',
    );

    const agency = get(allowedUseCodesMap, agencyCode, []);

    if (Array.isArray(agency)) return agency;

    const program = get(agency, programCode, []);
    if (Array.isArray(program)) return program;

    const processing = get(program, processingCode, []);
    if (Array.isArray(processing)) return processing;

    if (agencyCode === 'FDA' && programCode === 'DRU') {
      return processing[drugForm];
    }
    return [];
  },
);

export const objectDocs = createSelector(
  (state, { ids }) => ids,
  (state, { recordType }) => recordType,
  (state) => state.ui.docs,
  (ids, recordType, allDocs) => {
    const folder = getDocFolder(ids, recordType);
    const docIds = Object.keys(allDocs).reduce((acc, docKey) => {
      if (
        docKey.startsWith(folder) &&
        (!allDocs[docKey].tags ||
          !allDocs[docKey].tags.includes('Product Image'))
      ) {
        const split = docKey.split('/');
        acc.push(split[split.length - 1]);
      }
      return acc;
    }, []);

    const docs = docIds.map((docId) => allDocs[`${folder}/${docId}`]);

    return { docs, docIds };
  },
);

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
);

export const docTagsSelector = createDeepEqualSelector(
  objectDocs,
  ({ docs }) => {
    const docTags = Object.keys(
      docs.reduce((acc, doc) => {
        get(doc, 'tags', []).forEach((tag) => (acc[tag] = true));
        return acc;
      }, {}),
    );

    return docTags;
  },
);

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const pane = document.querySelector('.ant-layout-content');
    if (pane) pane.scrollTop = 0;
  }, [location]);

  return children;
};

import { AnyObject } from '@xbcb/shared-types';

// Base transform function for WOs. Ideally meant to be non-specific to any
// particular WO, and more so meant to make changes at the WO interface level
export const transformWorkOrderBase = {
  toForm: ({ existingRecord }: { existingRecord: AnyObject }): AnyObject => {
    return existingRecord;
  },
  // NOTE: toSchema returns a cleaned copy of the input
  toSchema: ({
    input,
    existingRecord,
  }: {
    input: AnyObject;
    existingRecord: AnyObject;
  }): AnyObject => {
    const cleanedOutput = JSON.parse(JSON.stringify(input));
    // Remove all WorkOrder interface fields that are not on the input
    delete cleanedOutput.group;
    delete cleanedOutput.operator;
    delete cleanedOutput.stateMachine;
    delete cleanedOutput.tasks;
    delete cleanedOutput.milestones;
    delete cleanedOutput.status;
    delete cleanedOutput.deadline;
    delete cleanedOutput.inquiries;
    delete cleanedOutput.documents;
    delete cleanedOutput.pendingUpdates;
    delete cleanedOutput.confirmations;
    delete cleanedOutput.details;
    delete cleanedOutput.category;
    delete cleanedOutput.subCategory;

    return cleanedOutput;
  },
};

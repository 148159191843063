/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from 'lodash';
import {
  defaultCreatePartyValidatorEmbeddedFields,
  manifestFieldsToValidate,
  createPartyValidator,
  getEnv,
} from '@xbcb/ui-utils';
import {
  AdditionalFormError,
  NamePath,
  WorkOrderTab,
  UiStage,
  uiStageToBackendStage,
} from '@xbcb/ui-types';
import { getWorkOrderTabConfiguration } from 'libs/workOrderTabConfiguration';
import { ModeOfTransport } from '@xbcb/shipment-types';
import { roundNumber } from 'libs/format';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { formatRecordName } from '@xbcb/js-utils';
import { createBrowserHistory } from 'history';
import getQueryParameters from 'libs/getQueryParameters';
import { UsType86EntryTaskType } from '@xbcb/work-order-types';
import { client as apolloClient } from '@xbcb/apollo-client';
import { SEARCH_US_TYPE86_ENTRIES } from 'libs/sharedQueries/queries/usType86Enty';
import type { UsType86Entry } from '@xbcb/api-gateway-client';
import { isPocInfoRequiredButMissing } from '@xbcb/work-order-utils/dist/isPocInfoRequiredButMissing';
import { isRealHts as realHTS } from '@xbcb/entry-utils/dist/lib/htsUtil';
import { importDateIsWithinType86EtaWindow } from '@xbcb/entry-utils/dist/lib/importDateIsWithinType86EtaWindow';
import {
  PATHFINDER_FEATURE_FLAG_SERVICE,
  PathfinderFeatures,
} from '@xbcb/feature-flags';
import { UsFilingType } from '@xbcb/compliance-types';
import { classifyUsCustomsFiling } from 'libs/usEntryCustomsClassificationTransformers/classifyCustomsFiling';

const { stage } = getEnv();

const PATHFINDER_PHASE_2_ENABLED = PATHFINDER_FEATURE_FLAG_SERVICE.isEnabled(
  PathfinderFeatures.PHASE_2,
  { stage: uiStageToBackendStage[stage] },
);

const PATHFINDER_PHASE_3_ENABLED = PATHFINDER_FEATURE_FLAG_SERVICE.isEnabled(
  PathfinderFeatures.PHASE_3,
  { stage: uiStageToBackendStage[stage] },
);

export type SharedValidateUsEntryReleaseProps = {
  input: AnyObject;
  currentUser: AnyObject;
  existingRecord: AnyObject;
};
const history = createBrowserHistory();
const TYPE_86_DEMINIMIS_VALUE = 800;

export const sharedValidateUsEntryRelease = async ({
  input,
  existingRecord,
  currentUser,
}: SharedValidateUsEntryReleaseProps) => {
  const { accountType } = currentUser;
  const { id } = existingRecord;
  const {
    invoices,
    masterBills,
    arrival = {},
    departure = {},
    ior = {},
  } = input;

  const validateFields: NamePath[] = [];
  const additionalErrors: AdditionalFormError[] = [];

  const validatePartySnapshot = createPartyValidator({
    validateFields,
    additionalErrors,
    input,
  });

  const queryParameters = getQueryParameters(history.location);
  const workOrderTaskType = queryParameters['task'];

  const tabConfiguration = getWorkOrderTabConfiguration(
    RecordType.US_TYPE86_ENTRY,
  )[workOrderTaskType as UsType86EntryTaskType];

  const isCommercialInvoicesTabRendered =
    !workOrderTaskType ||
    tabConfiguration?.includes(WorkOrderTab.CommercialInvoices);
  const isTransportationTabRendered =
    !workOrderTaskType ||
    tabConfiguration?.includes(WorkOrderTab.Transportation);

  const validateTabs = {
    transportation: isTransportationTabRendered,
    commercialInvoices: isCommercialInvoicesTabRendered,
  };

  validateFields.push(
    ['conveyance', 'conveyanceName'],
    ['conveyance', 'modeOfTransport'],
    ['conveyance', 'tripNumber'],
    ['conveyance', 'grossWeight'],
    ['departure', 'exportDate'],
    ['departure', 'portOfLadingCode'],
    ['arrival', 'portOfUnladingCode'],
    ['arrival', 'importDate'],
    ['arrival', 'usDestinationStateCode'],
    ['group', 'id'],
    ['group', 'version'],
    ['operator', 'id'],
    ['operator', 'version'],
    ...manifestFieldsToValidate(masterBills),
  );

  // we need to validate iorNumber in addition to the normal name and address for ior and consignee
  const iorAndConsigneeFields = [
    ...defaultCreatePartyValidatorEmbeddedFields,
    ['iorNumber', 'value'],
    ['iorNumber', 'type'],
  ];

  let formalEntryRequiredByHTS = false;
  const formalEntryHTSNumbers = new Set<string>();
  let totalValue = 0;

  if (validateTabs.commercialInvoices) {
    validatePartySnapshot(
      ['ior', 'usIor', 'id'],
      formatRecordName({
        recordType: RecordType.US_IOR,
        accountType,
      }),
      iorAndConsigneeFields,
    );

    // There must always be at least one invoice
    if (!invoices || invoices.length === 0) {
      additionalErrors.push({
        title: 'Commercial Invoices Missing',
        messages: [`At least one commercial invoice must be provided`],
      });
    }

    const commercialInvoiceErrorMessages = [];
    invoices?.forEach((invoice: any, invoiceIndex: number) => {
      const invoiceDisplayIndex = invoiceIndex + 1;
      const invoiceNamePath = ['invoices', invoiceIndex];
      validateFields.push([...invoiceNamePath, 'value', 'value']);
      validateFields.push([...invoiceNamePath, 'value', 'currency']);

      const currency = get(input, [...invoiceNamePath, 'currency']);
      if (currency !== 'USD') {
        const currencyRateNamePath = [...invoiceNamePath, 'currencyRate'];
        validateFields.push(currencyRateNamePath);
      }

      const roundedInvoiceValueValue = roundNumber(
        invoice.value?.value || 0,
        2,
      );
      let productsValue = 0;

      validatePartySnapshot(
        [...invoiceNamePath, 'seller', 'supplier', 'id'],
        `Invoice ${invoiceDisplayIndex} seller`,
        [...defaultCreatePartyValidatorEmbeddedFields, ['mid']],
      );

      // There must always be at least one product
      if (!invoice.products || invoice.products.length === 0) {
        additionalErrors.push({
          title: 'Commercial Invoice Products Missing',
          messages: [
            `Invoice ${invoiceDisplayIndex}, at least one product must be provided`,
          ],
        });
      }

      invoice.products?.forEach((product: any, productIndex: number) => {
        const productDisplayIndex = productIndex + 1;
        const productNamePath = [...invoiceNamePath, 'products', productIndex];
        validateFields.push([...productNamePath, 'quantity']);

        const productQuantity = product.quantity;
        if (productQuantity === 0) {
          additionalErrors.push({
            title: 'Invalid Commercial Invoice Product Quantity',
            messages: [
              `Invoice ${invoiceDisplayIndex}, product ${productDisplayIndex}, quantity must be at least 1`,
            ],
          });
        }

        const totalValueNamePath = [...productNamePath, 'totalValue'];
        validateFields.push([...totalValueNamePath, 'value']);
        validateFields.push([...totalValueNamePath, 'currency']);

        const productTotalValue = get(input, [...totalValueNamePath, 'value']);
        const roundedProductTotalValue = roundNumber(productTotalValue || 0, 2);
        if (typeof productTotalValue === 'number') {
          productsValue += productTotalValue || 0;
          totalValue += productTotalValue;
        }

        let linesValue = 0;
        product?.lines?.forEach((line: any, lineIndex: number) => {
          const { tariffs } = line;
          const lineDisplayIndex = lineIndex + 1;
          const lineNamePath = [...productNamePath, 'lines', lineIndex];
          validateFields.push([...lineNamePath, 'description']);
          validateFields.push([...lineNamePath, 'origin', 'countryCode']);

          const isManufacturerFromChina =
            line.manufacturer?.address?.countryCode === 'CN';
          const manufacturerValidationFields = [
            ...defaultCreatePartyValidatorEmbeddedFields,
            ['mid'],
          ];
          if (isManufacturerFromChina) {
            manufacturerValidationFields.push(['address', 'postalCode']);
          }
          validatePartySnapshot(
            [...lineNamePath, 'manufacturer', 'supplier', 'id'],
            `Invoice ${invoiceDisplayIndex}, product ${productDisplayIndex}, line ${lineDisplayIndex} manufacturer`,
            manufacturerValidationFields,
          );

          validateFields.push([...lineNamePath, 'tariffs', 0, 'htsNumber']);

          // There must always be at least one tariff
          if (!tariffs || tariffs.length === 0) {
            additionalErrors.push({
              title: 'Commercial Invoice Tariffs Missing',
              messages: [
                `Invoice ${invoiceDisplayIndex}, product ${productDisplayIndex}, line ${lineDisplayIndex} at least one tariff must be provided`,
              ],
            });
          }

          tariffs?.forEach((tariff: any, tariffIndex: number) => {
            const tariffNamePath = [...lineNamePath, 'tariffs', tariffIndex];
            // validateFields.push([...tariffNamePath, 'hts', 'id']);
            validateFields.push([...tariffNamePath, 'htsNumber']);
            const htsNumber = tariff.htsNumber?.replace(/\./g, '');
            if (realHTS(htsNumber)) {
              validateFields.push([...tariffNamePath, 'unitValue', 'currency']);
              validateFields.push([...tariffNamePath, 'unitValue', 'value']);
            }
            if (
              (htsNumber &&
                !htsNumber?.startsWith('9903') &&
                htsNumber.startsWith('99')) ||
              '98110060' === htsNumber
            ) {
              formalEntryRequiredByHTS = true;
              formalEntryHTSNumbers.add(htsNumber);
            }

            const validateTariff = tariffIndex === 0 || htsNumber;
            const tariffUnitValueValue = tariff.unitValue?.value;
            const htsHasUnitValue =
              tariff.unitValue?.value || realHTS(htsNumber);
            if (
              validateTariff &&
              productQuantity &&
              tariffUnitValueValue &&
              htsHasUnitValue
            ) {
              const amount = productQuantity * tariffUnitValueValue;
              if (!isNaN(amount)) linesValue += amount;
            }
            if (isPocInfoRequiredButMissing(ior, tariff)) {
              additionalErrors.push({
                title: 'Invalid Import of Record',
                messages: [
                  'Update IOR point of contact and sync IOR information',
                ],
              });
            }
          });
        });

        if (roundedProductTotalValue !== roundNumber(linesValue, 2)) {
          commercialInvoiceErrorMessages.push(
            `The value of product ${productDisplayIndex} (${roundedProductTotalValue}) on invoice ${invoiceDisplayIndex} (${
              invoice.invoiceNumber
            }) does not match the sum of unit value * quantity (${roundNumber(
              linesValue,
              2,
            )})`,
          );
        }
      });

      if (roundedInvoiceValueValue !== roundNumber(productsValue, 2)) {
        commercialInvoiceErrorMessages.push(
          `The value of invoice ${invoiceDisplayIndex} (${
            invoice.invoiceNumber
          }) (${roundedInvoiceValueValue}) does not match the sum of product values (${roundNumber(
            productsValue,
            2,
          )})`,
        );
      }
    });
  }

  const { importDate } = arrival;
  const { exportDate } = departure;

  if (validateTabs.transportation) {
    const selectedModeOfTransport = input?.conveyance?.modeOfTransport;
    const isValidModeOfTransport = Object.values(ModeOfTransport).includes(
      selectedModeOfTransport,
    );
    if (selectedModeOfTransport && !isValidModeOfTransport) {
      additionalErrors.push({
        title: 'Mode of Transport',
        messages: ['Mode of transport is not supported'],
      });
    }

    if (importDate && exportDate && exportDate.isAfter(importDate, 'day'))
      additionalErrors.push({
        title: 'Export date',
        messages: [
          `The export date ${exportDate.format(
            'YYYY-MM-DD',
          )} cannot be after the import date ${importDate.format(
            'YYYY-MM-DD',
          )}`,
        ],
      });
  }

  if (formalEntryRequiredByHTS) {
    additionalErrors.push({
      title: 'Formal entry required by by US HTS number',
      messages: [
        `The following US HTS numbers require a formal entry: ${Array.from(
          formalEntryHTSNumbers,
        ).join(', ')}`,
      ],
    });
  }

  if (totalValue > TYPE_86_DEMINIMIS_VALUE) {
    additionalErrors.push({
      title: 'De-minimis shipment criteria',
      messages: [
        `The total shipment value shouldn't exceed $${TYPE_86_DEMINIMIS_VALUE} for Type86 Entry filing. Please file a formal entry instead.`,
      ],
    });
  }

  if (PATHFINDER_PHASE_2_ENABLED) {
    if (!importDate) {
      additionalErrors.push({
        title: 'Import date missing ',
        messages: [`Import date missing for entry id ${existingRecord.id}`],
      });
    }

    if (!ior?.usIor.iorNumber) {
      additionalErrors.push({
        title: 'Ior number missing ',
        messages: [
          `Ior number missing for ior ${ior.name} on arrival date ${importDate}`,
        ],
      });
    }

    if (PATHFINDER_PHASE_3_ENABLED) {
      if (!importDateIsWithinType86EtaWindow(importDate)) {
        additionalErrors.push({
          title: 'Import date out of ETA window',
          messages: [
            `Import date is outside of the Type86 ETA window for filing`,
          ],
        });
      }
    }

    const classifications = await classifyUsCustomsFiling({
      id: existingRecord.id,
      ...input,
    } as UsType86Entry);
    if (!classifications) {
      additionalErrors.push({
        title: 'Unable to transform Entry to classification input',
        messages: [
          `Unable to transform US Type86 Entry to classification input for entry id ${existingRecord.id}`,
        ],
      });
    }
    if (
      !classifications?.some(
        (classification) =>
          classification.filingType === UsFilingType.US_TYPE86_ENTRY,
      )
    ) {
      additionalErrors.push({
        title: 'Shipment is not eligible for US Type86 Entry',
        messages: [
          `The shipment no longer meets the requirements to be classified as a US Type86 Entry. Filing type classifications returned: ${classifications
            ?.map((c) => c.filingType)
            .join(', ')}`,
        ],
      });
    }
  } else if (importDate && ior?.usIor.id) {
    const searchCriteria = {
      deletedTime: {
        operator: 'DOES_NOT_EXIST',
      },
      iorId: {
        values: [ior.usIor.id],
        operator: 'EQUALS',
      },
      importDate: {
        value: importDate,
        operator: 'EQUALS',
      },
    };

    const searchResponse = await apolloClient.query({
      query: SEARCH_US_TYPE86_ENTRIES,
      variables: {
        input: {
          searchCriteria,
        },
      },
    });

    const type86EntriesWithSameImportDateAndIor =
      searchResponse.data?.searchUsType86Entries.results;
    let newTotalValue = totalValue;
    const entryIds: string[] = [];
    if (type86EntriesWithSameImportDateAndIor.length) {
      type86EntriesWithSameImportDateAndIor
        .filter((entry: UsType86Entry) => entry.id !== id)
        .forEach((entry: UsType86Entry) => {
          newTotalValue += entry.totalValue || 0;
          const entryReference = entry.entryNumber || entry.id;
          entryIds.push(entryReference);
        });
    }

    if (newTotalValue > TYPE_86_DEMINIMIS_VALUE) {
      additionalErrors.push({
        title: 'De-minimis shipment criteria for multiple entries',
        messages: [
          `Shipment value exceeded the de minimis value ${TYPE_86_DEMINIMIS_VALUE} for ior ${ior.name} with following entries ${entryIds} on arrival date ${importDate}`,
        ],
      });
    }

    // TODO: remove the temporary disabe of bill on file check after Pathfinder P1 UAT
    const temporarilyDisableBillOnFileCheckForPathfinderUat =
      stage !== UiStage.PROD;
    if (
      !temporarilyDisableBillOnFileCheckForPathfinderUat &&
      masterBills.some((masterbill: any) => !masterbill.billOnFile)
    ) {
      additionalErrors.push({
        title: 'Bill on file criteria for type86 entry',
        messages: [
          `Bill is not on file for this entry. Please wait until bill is on file to submit entry.`,
        ],
      });
    }
  }

  return {
    additionalErrors,
    validateFields,
    validatePartySnapshot,
    validateTabs,
  };
};

import { PgaWithDisclaim } from './pgaWithDisclaim';
import { AppRecordValidateResult, NamePath } from '@xbcb/ui-types';
import {
  hasDriveSideRequirement,
  hasEmbassyCountryRequirement,
  hasGlazingManufacturerRequirement,
  hasManufactureMonthRequirement,
  hasPassportRequirement,
  hasPermissionLetterRequirement,
  hasRegisteredImporterNumberRequirement,
  hasTireManufacturerRequirement,
  hasTradeNameRequirement,
  hasVehicleEligibilityNumberAndDotBondRequirement,
  hasVehicleRequirement,
} from './pgaValidationHelpers';

interface ValidateNhtProps {
  pga: PgaWithDisclaim;
  getField: (field: string | string[]) => NamePath;
}

export const validateNht = ({
  pga = {},
  getField,
}: ValidateNhtProps): AppRecordValidateResult => {
  const validateFields: NamePath[] = [];
  const programCode = pga.programCode ?? undefined;
  const categoryCode = pga.categoryCode ?? '';
  const conformanceDeclaration = pga.conformanceDeclaration ?? '';
  const iorIsVehicleManufacturer = pga.iorIsVehicleManufacturer ?? undefined;

  const nhtFields: (string | string[])[] = ['conformanceDeclaration'];

  if (programCode) {
    nhtFields.push('categoryCode');
  }
  if (hasTradeNameRequirement({ programCode })) {
    nhtFields.push('tradeName');
  }
  if (hasGlazingManufacturerRequirement(categoryCode)) {
    nhtFields.push('glazingManufacturerCode');
  }
  if (hasTireManufacturerRequirement(categoryCode)) {
    nhtFields.push('tireManufacturerCode');
  }
  if (hasManufactureMonthRequirement(conformanceDeclaration)) {
    nhtFields.push(['vehicle', 'manufactureMonth']);
  }
  if (hasVehicleRequirement(programCode)) {
    nhtFields.push(
      ['vehicle', 'vehicleIdentificationNumbers'],
      ['vehicle', 'model'],
      ['vehicle', 'modelYear'],
    );
    if (hasEmbassyCountryRequirement(conformanceDeclaration)) {
      nhtFields.push('embassyCountryCode');
    }
    if (hasPassportRequirement(conformanceDeclaration)) {
      nhtFields.push('passportNumber', 'passportCountryCode');
    }
    if (hasDriveSideRequirement(conformanceDeclaration)) {
      nhtFields.push('driveSide');
    }
  }
  if (hasRegisteredImporterNumberRequirement(conformanceDeclaration)) {
    nhtFields.push(['registeredImporterNumber', 'number']);
  }
  if (
    hasVehicleEligibilityNumberAndDotBondRequirement(conformanceDeclaration)
  ) {
    nhtFields.push(
      ['vehicleEligibilityNumber', 'number'],
      ['dotBond', 'suretyCode'],
      ['dotBond', 'serialNumber'],
      ['dotBond', 'amount'],
    );
  }
  if (
    hasPermissionLetterRequirement(
      conformanceDeclaration,
      iorIsVehicleManufacturer,
    )
  ) {
    nhtFields.push(
      ['permissionLetter', 'number'],
      ['permissionLetter', 'quantity'],
      ['permissionLetter', 'date'],
    );
  }

  nhtFields.forEach((field) => {
    validateFields.push(getField(field));
  });

  return { validateFields };
};

import type { ProductUsPgaInput } from '__generated__/graphql';
import { safeGet } from '@xbcb/js-utils';
import { ModeOfTransport } from '@xbcb/shared-types';
import {
  AdditionalFormError,
  AppRecordValidateResult,
  NamePath,
} from '@xbcb/ui-types';
import { getCodes } from '@xbcb/ui-utils';
import { AocCode, aocFieldsMap } from 'components/Aoc/aocFields';
import drugAOCs from 'libs/drugAOCs';
import isPackagingNeeded from 'libs/isPackagingNeeded';
import subjectToPNFunction from 'libs/subjectToPN';
import {
  hasIntendedUseRequirement,
  hasLaboratoryRequirement,
  hasTradeNameRequirement,
  hasDrugFormRequirement,
  requireDevAOC,
  hasDeviceFormRequirement,
  getDrugAocFields,
  getFoodAcidityAocFields,
  packagingMissingError,
  intendedUseManufacturerError,
} from './pgaValidationHelpers';

interface ValidateFdaProps {
  tariffNamePath: NamePath;
  pgaFlag: string;
  pga: ProductUsPgaInput;
  getField: (field: string | (string | number)[]) => NamePath;
  manufacturerCountryOfOrigin?: string;
  mot?: ModeOfTransport;
}

const devRequiredAocs = [
  AocCode.PM,
  AocCode.DDM,
  AocCode.DEV,
  AocCode.DFE,
  AocCode.IDE,
  AocCode.LST,
  AocCode.DA,
  AocCode.IND,
  AocCode.LWC,
];

export const validateFda = ({
  tariffNamePath,
  pgaFlag,
  pga = {},
  getField,
  manufacturerCountryOfOrigin,
  mot,
}: ValidateFdaProps): AppRecordValidateResult => {
  const validateFields: NamePath[] = [];
  const additionalErrors: AdditionalFormError[] = [];

  const codes = getCodes();
  const devAOCs = codes.CBP.CATAIR.PGA.DEVAOC;
  const { foodAcidityIndustry } = codes.CBP.CATAIR.PGA;
  const foodAcidityIndustryCodes = foodAcidityIndustry;
  const { performanceStandard, priorNotice } = pga;
  const programCode = pga.programCode ?? '';
  const processingCode = pga.processingCode ?? '';
  const intendedUse = pga.intendedUse ?? '';

  const devAocRequired = requireDevAOC(programCode, intendedUse, devAOCs);

  const subjectToPN = subjectToPNFunction({
    pgaFlag,
    processingCode,
    programCode,
  });
  const pnConditionsMet = priorNotice?.conditionsNotApplicable;

  const productCode = pga.fdaProductCode ?? '';
  const industryCode = productCode.slice(0, 2);
  const classCode = productCode.charAt(2);
  const pic = productCode.charAt(4);

  const lacf =
    foodAcidityIndustryCodes.includes(industryCode) && ['F', 'E'].includes(pic);
  const af = foodAcidityIndustryCodes.includes(industryCode) && pic === 'I';
  const showFoodAcidity = lacf || af;
  const isInfantFormula =
    industryCode === '40' && ['C', 'N', 'O', 'P', 'R'].includes(classCode);

  const fdaFields: (string | (string | number)[])[] = [
    'fdaProductCode',
    ['source', 'sourceCountryType'],
    ['source', 'countryCode'],
  ];

  if (
    hasTradeNameRequirement({
      performanceStandard: !!performanceStandard,
      programCode,
      processingCode,
    })
  ) {
    fdaFields.push('tradeName');
  }
  if (hasLaboratoryRequirement(programCode, processingCode)) {
    fdaFields.push('laboratoryTypeCode');
  }
  if (hasIntendedUseRequirement(programCode, processingCode)) {
    fdaFields.push('intendedUse');
  }
  if (hasDrugFormRequirement(programCode, processingCode)) {
    fdaFields.push('drugForm');
  }
  if (hasDeviceFormRequirement(programCode)) {
    fdaFields.push('deviceForm');
  }
  if (subjectToPN) {
    fdaFields.push('shipmentCountryCode');
    const sameAsIor = pga.foreignSupplierVerificationProgram?.sameAsIor;
    if (!sameAsIor) {
      fdaFields.push([
        'foreignSupplierVerificationProgram',
        'customsAgent',
        'id',
      ]);
    }
    if (!pnConditionsMet) {
      if (showFoodAcidity || isInfantFormula) {
        fdaFields.push('lotNumber');
      }
      const facilityExempt = safeGet(pga, [
        'foodFacilityRegistration',
        'exempt',
      ]);
      if (facilityExempt) {
        fdaFields.push(aocFieldsMap[AocCode.FME]);
      } else {
        fdaFields.push(
          aocFieldsMap[processingCode === 'NSF' ? AocCode.GFR : AocCode.PFR],
        );
      }
      if (mot === ModeOfTransport.RAIL) {
        fdaFields.push(aocFieldsMap[AocCode.RNO]);
      }
    }
  }

  fdaFields.push(...getFoodAcidityAocFields(showFoodAcidity));
  fdaFields.push(
    ...getDrugAocFields(
      drugAOCs({ programCode, processingCode, iuc: intendedUse }),
    ),
  );
  devRequiredAocs.forEach((aoc) => {
    if (devAocRequired(aoc) === 'M') {
      fdaFields.push(aocFieldsMap[aoc]);
    }
  });

  const needPackaging = isPackagingNeeded(
    subjectToPN,
    pnConditionsMet,
    performanceStandard,
  );
  if (needPackaging && !pga?.packaging) {
    additionalErrors.push(packagingMissingError(tariffNamePath));
  }
  pga.packaging?.forEach((_, index) => {
    fdaFields.push(['packaging', index, 'value'], ['packaging', index, 'unit']);
  });

  if (programCode === 'DEV') {
    const sameAsIor = pga.deviceInitialImporter?.sameAsIor;
    if (!sameAsIor) {
      fdaFields.push(['deviceInitialImporter', 'customsAgent', 'id']);
    }
    if (
      intendedUse &&
      ['081.001', '081.002', '081.004'].includes(intendedUse) &&
      manufacturerCountryOfOrigin === 'US'
    ) {
      additionalErrors.push(
        intendedUseManufacturerError(tariffNamePath, intendedUse),
      );
    }
  }

  fdaFields.forEach((field) => {
    validateFields.push(getField(field));
  });

  return {
    validateFields,
    additionalErrors,
  };
};

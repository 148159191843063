import { conformToMask } from 'text-mask-core';

const mask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const HtsMask = class {
  previousValue: string;
  constructor() {
    this.previousValue = '';
  }

  handleChange(val?: string): string | undefined {
    if (!val) return val || undefined;

    const result = conformToMask(val, mask, {
      guide: false,
      previousConformedValue: this.previousValue,
    }).conformedValue;
    this.previousValue = result;
    return result || undefined;
  }
};

export default HtsMask;

import { RecordType } from '@xbcb/shared-types';
import { EuIor } from '../../loadableComponents';
import euIorRoute from './euIor';
import { nlIorFragments } from '@xbcb/party-queries';

const nlIorRoute = euIorRoute({
  page: EuIor,
  recordType: RecordType.NL_IOR,
  fields: `...nlIorFields`,
  fragments: nlIorFragments,
});

export default nlIorRoute;

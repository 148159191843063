import { combineReducers } from 'redux';
import { modalsReducer } from '@xbcb/ui-utils';
import onboardingReducer from './onboarding';
import loginReducer from './login';
import shipmentReducer from './shipment';
import spreadsheetReducer from './spreadsheet';
import questionnaireReducer from './questionnaire';
import profileReducer from './profile';
import companyReducer from './company';
import globalSearchReducer from './globalSearch';
import invoicesReducer from './invoices';
import docsReducer from './docs';
import newContent from './newContent';
import owner from './owner';
import mqtt from './mqtt';

const uiReducer = combineReducers({
  modals: modalsReducer,
  questionnaire: questionnaireReducer,
  profile: profileReducer,
  company: companyReducer,
  globalSearch: globalSearchReducer,
  onboarding: onboardingReducer,
  login: loginReducer,
  invoices: invoicesReducer,
  spreadsheet: spreadsheetReducer,
  docs: docsReducer,
  shipment: shipmentReducer,
  mqtt,
  newContent,
  owner,
});

export default uiReducer;
type UiRootState = ReturnType<typeof uiReducer>;

import { OnboardingDashboard, PendingDashboard } from './loadableComponents';
import { IndependentRoute } from './independentRoutes';

// IndependentRoutes are the ones rendered by AuthenticatedRoutes, but are not specific to a record type. Thus, it should be rendered here rather than as part of the AppRecord framework.
export const onboardingRoutes: IndependentRoute[] = [
  {
    Page: OnboardingDashboard,
    path: '/dashboard',
  },
];

export const onboardingPendingRoutes: IndependentRoute[] = [
  {
    Page: PendingDashboard,
    path: '/pending',
  },
];

import { ReactNode } from 'react';
import { FireTwoTone } from '@ant-design/icons';

const hotIcon: ReactNode = () => {
  return (
    <>
      <FireTwoTone twoToneColor="#FF0000" />
    </>
  );
};

export default hotIcon;

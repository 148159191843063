import styled from 'styled-components';
import { Input } from 'antd';

export const StyledProductPoNumbersDiv = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledColumnWrapperDiv = styled.div`
  display: flex;
`;

export const StyledLeftColumnDiv = styled.div`
  width: 100%;
  margin-right: ${({ theme }) => theme.spacing.space_4};
`;

export const StyledRightColumnDiv = styled.div`
  width: 312px;
  min-width: 312px;
  .new-doc,
  .new-doc-form-upload {
    margin-right: 0;
  }
`;

export const StyledFormInlineDiv = styled.div`
  .ant-row {
    display: inline-block;
    /* margin-right: ${({ theme }) => theme.spacing.space_4}; */
  }
  .form-inline {
    display: inline-block;
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;

import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const StyledRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const StyledIconBox = styled.div`
  margin-bottom: 0;
`;

const baseIconStyles = `
  width: 35px;
  height: 35px;
  padding: 10px;
  border: solid 1px #d9d9d9;
  font-weight: 500;
`;

export const StyledTrashIcon = styled(DeleteOutlined)`
  ${baseIconStyles}
  margin-right: 8px;
`;

export const StyledClipboard = styled(CopyOutlined)`
  ${baseIconStyles}
`;

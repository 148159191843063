import React from 'react';
export default ({ size }) => (
  <svg
    className="mot-icon"
    height={size}
    width={size}
    viewBox="0 0 256 256"
    id="svg12"
  >
    <g transform="matrix(3.35079 0 0 3.35079 -39.54 -39.54)" id="g6">
      <path
        id="path4"
        d="M88.2 70.5v-8.1L55.7 41.7v-12s0-17-5.7-17-5.7 17-5.7 17v12L11.8 62.4v8.1l32.5-10.1v9.7l-8.1 9.1v8.1L50 80.8l13.8 6.5v-8.1l-8.1-9.1v-9.7z"
      />
    </g>
  </svg>
);

// inquiries is an output only field, make sure it's cleared when update the WO
export const inquiryFields = `
    inquiries {
      reasonCodes
      clarifyDocuments {
        id
        fileName
        extension
      }
      attachDocuments {
        id
        fileName
        extension
      }
      requestDocuments
      milestone {
        id
      }
      description
      task {
        id
      }
    }
`;

import React, { useState } from 'react';
import { Popover } from 'antd';
import { gaEvent, safeGetMessage } from '@xbcb/ui-utils';
import { useHistory, useParams } from 'react-router-dom';
import { StyledTable, StyledButton } from './style';
import { useSelector } from 'react-redux';
import { AnyObject } from '@xbcb/shared-types';
import { get } from 'lodash';
import { recordTypeToPath } from 'libs/recordTypeToPath';
import { useBundle } from '@amzn/react-arb-tools';

const RecentlyViewed = () => {
  const [appHeaderBundle] = useBundle('components.AppHeader');
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const recent = useSelector((state) =>
    get(state, 'recentlyViewed.viewedRecords', []),
  );
  const { recordId } = useParams<{ recordId: string }>();

  const columns = [
    {
      title: 'Object',
      key: 'id',
      render: ({ label }: AnyObject) => <p>{label}</p>,
    },
  ];

  const handleRowClick = (record: AnyObject, index?: number) => {
    setIsVisible(false);
    const { id, recordType } = record;
    const url = `${recordTypeToPath(recordType)}/${id}`;

    if (id === recordId) {
      return;
    }
    gaEvent('Recently Viewed', 'Row Clicked', id, index);
    history.push(url);
  };

  const RecentlyViewedTable = () => (
    <StyledTable
      columns={columns}
      dataSource={recent}
      pagination={false}
      onRow={(record, index) => ({
        onClick: () => handleRowClick(record, index),
      })}
      rowKey="id"
      showHeader={false}
      locale={{
        emptyText: safeGetMessage(
          appHeaderBundle,
          'no_recently_viewed_records',
        ),
      }}
    />
  );

  const visibilityChangeHandler = (visible: boolean) => {
    gaEvent('Recent Viewed', visible ? 'Shown' : 'Hidden');
    setIsVisible(visible);
  };

  return (
    <Popover
      title={null}
      placement="bottomLeft"
      content={RecentlyViewedTable}
      trigger={'click'}
      onOpenChange={visibilityChangeHandler}
      open={isVisible}
    >
      <StyledButton>
        {safeGetMessage(appHeaderBundle, 'recently_viewed')}
      </StyledButton>
    </Popover>
  );
};

export default RecentlyViewed;

import { gql } from '@apollo/client';

export const assignmentRequest = gql`
  query WorkOrderTaskAssignment(
    $operatorUserId: ID!
    $operatorUserVersion: Int!
  ) {
    WorkOrderTaskAssignmentRequest(
      operatorUserId: $operatorUserId
      operatorUserVersion: $operatorUserVersion
    ) {
      workOrderTasks {
        id
        version
      }
      requestTime
      isSupportTeamAssignment
    }
  }
`;

export const assignWorkOrderTask = gql`
  mutation UpdateWorkOrderTask(
    $id: ID!
    $version: Int!
    $input: UpdateWorkOrderTaskInput!
  ) {
    updateWorkOrderTask(id: $id, version: $version, input: $input) {
      record {
        id
        version
        workOrder {
          id
        }
        definition {
          workOrderTaskType
        }
        assignee {
          id
        }
      }
    }
  }
`;

import { Cascader } from 'antd';
import styled from 'styled-components';
import { CssSize, CssSpace } from '@xbcb/ui-types';

export const StyledContainer = styled.div`
  margin-right: ${CssSpace.SPACE_3};
`;

export const StyledCascader = styled(Cascader)`
  min-width: ${CssSize.SHORT};
`;

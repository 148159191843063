import styled from 'styled-components';
import { Option } from '@xbcb/form-item-components';

export const StyledTooltipOption = styled(Option)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledFormInlineDiv = styled.div`
  display: inline-block;
`;

export const StyledFlexDiv = styled.div`
  display: flex;
`;

import { AccountType, RecordType } from '@xbcb/shared-types';
import { setModal } from 'actions';
import { ModalKey } from 'types';
import { AppRecordKebabMenuItem } from '../../types';
import { showInquireWorkOrderKebabMenuItemFunc } from 'libs/showInquireWorkOrderKebabMenuItemFunc';
import { executeMutation } from 'libs/executeMutation';
import { gql } from '@apollo/client';
import {
  WorkOrderStatus,
  WorkOrderTaskStatus,
  WorkOrderTaskSubStatus,
  WorkOrderTaskType,
} from '@xbcb/work-order-types';
import getQueryParameters from 'libs/getQueryParameters';
import type { WorkOrderTask } from '@xbcb/api-gateway-client';
import { fetchLatestRecordAndExecuteMutation } from 'libs/fetchLatestRecordAndExecuteMutation';
import { updateWorkOrderTaskStatus } from 'libs/sharedQueries';
import { KebabMenuItemKey } from '@xbcb/ui-types';

// To use inquireWorkOrderItem, make sure ReasonCodes exist for the WorkOrder type. (Check typeCategoryMap in InquireWorkOrderModal/inquireReasonCodes.ts)
export const inquireWorkOrderItem: AppRecordKebabMenuItem = {
  text: 'Inquire Work Order',
  key: KebabMenuItemKey.INQUIRE_WORK_ORDER,
  action: async ({ dispatch }) => {
    dispatch(
      setModal({
        key: ModalKey.INQUIRE_WORK_ORDER,
        props: {
          visible: true,
          // set document to null since we don't want to inquire about a specific document from kebab menu
          document: null,
        },
      }),
    );
  },
  show: ({
    existingRecord,
    currentUser,
    workOrderTaskType,
    workOrderTaskEvaluatedState,
  }) => {
    return (
      currentUser.accountType === AccountType.OPERATOR &&
      showInquireWorkOrderKebabMenuItemFunc({
        status: existingRecord.status,
        taskType: workOrderTaskType as WorkOrderTaskType,
        workOrderTasks: existingRecord.tasks,
      })
    );
  },
};

const unblockWorkOrder = gql`
  mutation UnblockWorkOrder($id: ID!, $version: Int!) {
    unblockWorkOrder(id: $id, version: $version) {
      workOrder {
        id
        version
        status
      }
    }
  }
`;

export const unblockWorkOrderItem: AppRecordKebabMenuItem = {
  text: 'Unblock Work Order',
  key: 'unblockWorkOrder',
  action: async ({ record, location }) => {
    await executeMutation({
      mutation: unblockWorkOrder,
      variables: {
        id: record.id,
        version: record.version,
      },
      successMessage: 'Successfully unblocked work order.',
    });
    const queryParameters = getQueryParameters(location);
    const workOrderTaskType = queryParameters['task'];
    if (workOrderTaskType) {
      const task = record.tasks?.find(
        ({ definition }: WorkOrderTask) =>
          definition?.workOrderTaskType === workOrderTaskType,
      );
      if (task) {
        const isTaskAssigned = task.assignee?.id;
        const updatedStatus = isTaskAssigned
          ? WorkOrderTaskStatus.IN_PROGRESS
          : WorkOrderTaskStatus.NOT_ASSIGNED;
        const updatedSubStatus = isTaskAssigned
          ? WorkOrderTaskSubStatus.ACTIVELY_IN_PROGRESS
          : null;
        // This mutation is added so that the UI re-renders with the updated task status.
        await fetchLatestRecordAndExecuteMutation({
          id: task.id,
          fields: 'status',
          recordType: 'WORK_ORDER_TASK' as RecordType,
          mutation: updateWorkOrderTaskStatus,
          successMessage:
            `Work Order Task is now unblocked` +
            (updatedSubStatus === WorkOrderTaskSubStatus.ACTIVELY_IN_PROGRESS
              ? ' and actively in progress.'
              : '.'),
          constructVariablesWithIdVersion: (id, version) => ({
            id,
            version,
            input: {
              status: updatedStatus,
              subStatus: updatedSubStatus,
            },
          }),
        });
      }
    }
  },
  show: ({ existingRecord, currentUser }) => {
    return (
      currentUser.accountType === AccountType.OPERATOR &&
      existingRecord.status === WorkOrderStatus.BLOCKED
    );
  },
};

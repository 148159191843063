import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from '@xbcb/ui-types';
import { PlusOutlined } from '../../icons';
import { StyledButton } from '../CommercialInvoiceTab/styles';

export interface AddUnitAssistButtonProps {
  form: FormInstance;
  disabled?: boolean;
  readOnly?: boolean;
  fullNamePath: NamePath;
}

const AddUnitAssistButton: React.FC<AddUnitAssistButtonProps> = ({
  form,
  disabled,
  readOnly,
  fullNamePath,
}: AddUnitAssistButtonProps) => {
  const { setFields } = form;

  const setInitialAssist = () => {
    setFields([
      {
        name: fullNamePath,
        value: { value: 0, currency: 'USD' },
      },
    ]);
  };

  return (
    <>
      {!readOnly && (
        <StyledButton
          type="dashed"
          className="add-assist-button"
          disabled={disabled}
          onClick={setInitialAssist}
        >
          <PlusOutlined />
          Assist
        </StyledButton>
      )}
    </>
  );
};

export default AddUnitAssistButton;

import { modifyPayeeAccount } from '../../../libs/sharedQueries/mutations/modifyPayeeAccount';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { executeMutation } from 'libs/executeMutation/index';
import {
  AccountType,
  RecordType,
  BillingPartyAccountStatus,
  AnyObject,
} from '@xbcb/shared-types';
import { AppRecordKebabMenuItem } from '../../types';
import { UiStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import { isFinanceTeamUser } from 'libs/isFinanceTeamUser';

const { stage } = getEnv();

export const appRecordSuspendAccountMenuItem = (
  payee: typeof RecordType.SHIPPER | typeof RecordType.FORWARDER,
): AppRecordKebabMenuItem => {
  const recordSuspendAccountMenuItem: AppRecordKebabMenuItem = {
    key: `suspend${payee}Account`,
    text: 'Suspend Payee Account',
    show: ({ currentUser, existingRecord }) =>
      currentUser?.accountType === AccountType.OPERATOR &&
      checkAccess(currentUser, RecordType.OPERATOR, UserPermissions.UPDATE) &&
      isAccountActive(existingRecord) &&
      isFinanceTeamUser(currentUser) &&
      stage !== UiStage.PROD,
    action: async ({ record }) => {
      await executeMutation({
        mutation: modifyPayeeAccount(
          payee,
          'record { id }',
          BillingPartyAccountStatus.SUSPENDED,
        ),
        variables: {
          id: record.id,
          version: record.version,
        },
      });
    },
    confirmAction: true,
  };
  return recordSuspendAccountMenuItem;
};

export const appRecordActivateAccountMenuItem = (
  payee: typeof RecordType.SHIPPER | typeof RecordType.FORWARDER,
): AppRecordKebabMenuItem => {
  const recordActivateAccountMenuItem: AppRecordKebabMenuItem = {
    key: `activate${payee}Account`,
    text: 'Activate Payee Account',
    show: ({ currentUser, existingRecord }) =>
      currentUser?.accountType === AccountType.OPERATOR &&
      checkAccess(currentUser, RecordType.OPERATOR, UserPermissions.UPDATE) &&
      !isAccountActive(existingRecord) &&
      isFinanceTeamUser(currentUser) &&
      stage !== UiStage.PROD,
    action: async ({ record }) => {
      await executeMutation({
        mutation: modifyPayeeAccount(
          payee,
          'record { id }',
          BillingPartyAccountStatus.ACTIVE,
        ),
        variables: {
          id: record.id,
          version: record.version,
        },
      });
    },
    confirmAction: true,
  };
  return recordActivateAccountMenuItem;
};

const isAccountActive = (existingRecord: AnyObject) => {
  return [null, undefined, BillingPartyAccountStatus.ACTIVE].includes(
    existingRecord.billingDetails?.status,
  );
};

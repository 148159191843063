import { AdditionalFormError } from '@xbcb/ui-types';

const validateCreateShipment = ({ input }: { input: any }) => {
  // TODO: use validateFields
  const { usCustomsBroker, forwarder } = input;
  const additionalErrors: AdditionalFormError[] = [];

  if (!usCustomsBroker?.id) {
    additionalErrors.push({
      title: 'Broker not found',
      messages: [`Broker not found for current operator`],
    });
  }

  return { additionalErrors };
};

export default validateCreateShipment;

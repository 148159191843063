const recordActionFragment = `
fragment recordActionFragment on RecordAction {
  time
  client {
    id
    name
  }
}`;

export const bulkUploadFieldsFragmentName = 'bulkUploadRequestFields';

const bulkUploadResultsFieldsFragmentName = 'bulkUploadFieldsFragment'; // used to simplify the coordination of fields fragments for all bulk upload types

/**
 * Creates a Bulk Upload Request fragment for use on the UI.
 * Utility necessary because all Bulk Upload requests share all fields except for their results.fields
 * @param {string} bulkUploadRecordType The type of Bulk Upload record that this fragment is on
 * @param {string} bulkUploadFieldsFragment The fragment for the given record type's results.fields
 * @return  {string} A fragment for the given record type that will get all fields
 */
const bulkUploadRequestFields = (
  bulkUploadRecordType: string,
  bulkUploadFieldsFragment: string,
) => `
fragment ${bulkUploadFieldsFragmentName} on ${bulkUploadRecordType} {
  id
  version
  created {
    ...recordActionFragment
  }
  deleted {
    ...recordActionFragment
  }
  updated {
    ...recordActionFragment
  }
  operator {
    id
  } 
  status
  totalFailures
  totalRows
  results {
    status
    failureReason
    fields {
      ...${bulkUploadResultsFieldsFragmentName}
    }
  }
  document {
    id
    content {
      downloadLink
    }
  }
}
${recordActionFragment}
${bulkUploadFieldsFragment}`;

export const bulkMilestoneUploadRequestFragments = bulkUploadRequestFields(
  'BulkMilestoneUploadRequest',
  `fragment ${bulkUploadResultsFieldsFragmentName} on BulkMilestoneUploaderFields {
    identifier
    identifierType
    milestoneName
    occurenceTime
  }`,
);

export const bulkChargesUploadRequestFragments = bulkUploadRequestFields(
  'BulkChargesUploadRequest',
  `fragment ${bulkUploadResultsFieldsFragmentName} on BulkChargesUploaderFields {
    identifier
    identifierType
    charge
    amount
    currency
  }`,
);

export const bulkComplianceUploadRequestFragments = bulkUploadRequestFields(
  'BulkComplianceUploadRequest',
  `fragment ${bulkUploadResultsFieldsFragmentName} on BulkComplianceUploaderFields {
    identifier
    identifierType
    importEntryNumber
  }`,
);

// "blue" is really INLT red-violet
export default {
  noRushRequested: {
    name: 'No Rush Entry Requested',
    color: 'blue',
  },
  hotRequested: {
    name: 'Hot Entry Requested',
    color: 'blue',
  },
  pendingDocs: {
    name: 'Pending Documents',
    color: 'blue',
  },
  created: {
    name: 'Shipment Created',
    color: 'blue',
  },
  canceled: {
    name: 'Shipment Canceled',
    color: 'red',
  },
  readyForInBond: {
    name: 'Ready For In-Bond',
    color: 'green',
  },
  docsSubmitted: {
    name: 'Ready For Entry',
    color: 'green',
  },
  disSubmitted: {
    name: 'Submitted to DIS',
    color: 'blue',
  },
  isfSubmitted: {
    name: 'ISF Submitted',
    color: 'green',
  },
  isfUpdated: {
    name: 'ISF Updated',
    color: 'green',
  },
  isfSkipped: {
    name: 'ISF Already Filed',
    color: 'blue',
  },
  isfUnskipped: {
    name: 'ISF Needs Filing',
    color: 'blue',
  },
  isfNeedsBond: {
    name: 'Acquiring ISF Bond',
    color: 'blue',
  },
  needsSingleEntryBond: {
    name: 'Acquiring Single Entry Bond',
    color: 'blue',
  },
  isfRejected: {
    name: 'ISF Rejected',
    color: 'red',
  },
  isfFiled: {
    name: 'ISF Accepted',
    color: 'green',
  },
  inBondSubmitted: {
    name: 'In-Bond Submitted',
    color: 'green',
  },
  inBondAuthorized: {
    name: 'In-Bond Authorized',
    color: 'green',
  },
  inBondArrived: {
    name: 'In-Bond Arrived',
    color: 'green',
  },
  inBondBillArrived: {
    name: 'In-Bond Bill Arrived',
    color: 'green',
  },
  inBondContainerArrived: {
    name: 'In-Bond Container Arrived',
    color: 'green',
  },
  inBondExported: {
    name: 'In-Bond Exported',
    color: 'green',
  },
  inBondBillExported: {
    name: 'In-Bond Bill Exported',
    color: 'green',
  },
  inBondContainerExported: {
    name: 'In-Bond Container Exported',
    color: 'green',
  },
  inBondTransferred: {
    name: 'In-Bond Liability Transferred',
    color: 'green',
  },
  inBondDiversion: {
    name: 'In-Bond Diversion Requested',
    color: 'green',
  },
  inBondDeleted: {
    name: 'In-Bond Deleted',
    color: 'red',
  },
  isfDeleted: {
    name: 'ISF Deleted',
    color: 'red',
  },
  entrySkipped: {
    name: 'Entry Not Required',
    color: 'blue',
  },
  entryConsolidated: {
    name: 'Entry Consolidated',
    color: 'green',
  },
  entrySubmitted: {
    name: 'Entry Submitted',
    color: 'green',
  },
  pendingConfirmation: {
    name: 'Pending Entry Confirmation',
    color: 'blue',
  },
  pendingInquiry: {
    name: 'Pending Inquiry',
    color: 'blue',
  },
  entryConfirmed: {
    name: 'Entry Confirmed',
    color: 'green',
  },
  entryFiled: {
    name: 'Entry Filed',
    color: 'green',
  },
  entryUpdated: {
    name: 'Entry Updated',
    color: 'green',
  },
  entryDeleted: {
    name: 'Entry Deleted',
    color: 'red',
  },
  releaseDeleted: {
    name: 'Release Deleted',
    color: 'red',
  },
  customsRelease: {
    name: 'Customs Release',
    color: 'green',
  },
  customsHold: {
    name: 'Customs Hold',
    color: 'red',
  },
  customsExam: {
    name: 'Customs Exam',
    color: 'red',
  },
  pgaHold: {
    name: 'PGA Hold',
    color: 'red',
  },
  pgaRelease: {
    name: 'PGA Release',
    color: 'green',
  },
  pgaPending: {
    name: 'Pending PGA',
    color: 'blue',
  },
  doIssued: {
    name: 'DO Issued',
    color: 'blue',
  },
  forwarderTagged: {
    name: 'Forwarder Tagged',
    color: 'blue',
  },
  fullService: {
    name: 'Sent to INLT',
    color: 'blue',
  },
  billMatch: {
    name: 'Bill Match',
    color: 'green',
  },
  freightRelease: {
    name: 'Freight/Telex Release',
    color: 'green',
  },
  containerOutgate: {
    name: 'Container Outgate',
    color: 'blue',
  },
  delivered: {
    name: 'Delivered',
    color: 'green',
  },
  emptyReturned: {
    name: 'Empty Container Returned',
    color: 'green',
  },
  lfdEstablished: {
    name: 'LFD Established',
    color: 'blue',
  },
  lfdWarning: {
    name: 'LFD Warning',
    color: 'red',
  },
  availableForPickup: {
    name: 'Available For Pickup',
    color: 'green',
  },
  liquidated: {
    name: 'Liquidated',
    color: 'green',
  },
  reliquidated: {
    name: 'Re-liquidated',
    color: 'green',
  },
  liquidationSuspended: {
    name: 'Liquidation Suspended',
    color: 'blue',
  },
  liquidationExtended: {
    name: 'Liquidation Extended',
    color: 'blue',
  },
  pscSubmitted: {
    name: 'PSC Submitted',
    color: 'green',
  },
  pscFiled: {
    name: 'PSC Filed',
    color: 'green',
  },
  // TODO
  pscPending: {
    name: 'PSC Pending',
    color: 'blue',
  },
  pscApproved: {
    name: 'PSC Approved',
    color: 'green',
  },
};

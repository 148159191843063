import subjectToPNFunction from 'libs/subjectToPN';
import isPackagingNeeded from 'libs/isPackagingNeeded';
import { AnyObject, ModeOfTransport } from '@xbcb/shared-types';
import { NamePath, ValidationErrorType } from '@xbcb/ui-types';

export const validateTariffPga = (args: {
  tariff: AnyObject;
  iorName: string;
  validatePartySnapshot: (
    idPath: NamePath,
    displayName: string,
    embeddedFields?: NamePath[],
  ) => void;
  ior: AnyObject;
  tariffNamePath: NamePath;
  tariffErrorString: string;
  line: AnyObject;
  mot?: ModeOfTransport;
}) => {
  const {
    tariff,
    iorName,
    validatePartySnapshot,
    ior,
    tariffNamePath,
    tariffErrorString,
    line,
  } = args;

  const validateFields = [];
  const additionalErrors = [];

  // TODO: we need to validate the rest of the PGA fields
  for (const pgaFlag of Object.keys(tariff.pga)) {
    const pga = tariff.pga[pgaFlag] || {};

    const {
      agencyCode,
      programCode,
      processingCode,
      foreignSupplierVerificationProgram: fsvp,
      deviceInitialImporter: dii,
      intendedUse,
      performanceStandard,
    } = pga;

    const pnConditionsMet = pga.priorNotice?.conditionsNotApplicable;

    const subjectToPN = subjectToPNFunction({
      pgaFlag,
      processingCode,
      programCode,
    });

    if (subjectToPN && fsvp) {
      if (fsvp.sameAsIor) {
        if (!ior.duns || ior.duns === 'UNK') {
          additionalErrors.push({
            title: 'Foreign Supplier Verification Program',
            type: ValidationErrorType.GENERAL,
            messages: [`Missing DUNS info in ${iorName} (required for FSVP)`],
          });
        }
      } else {
        validatePartySnapshot(
          [
            ...tariffNamePath,
            'pga',
            pgaFlag,
            'foreignSupplierVerificationProgram',
            'customsAgent',
            'id',
          ],
          'Foreign Supplier Verification Program',
        );

        if (!fsvp.duns || fsvp.duns === 'UNK') {
          additionalErrors.push({
            title: 'Foreign Supplier Verification Program',
            type: ValidationErrorType.GENERAL,
            messages: ['Missing DUNS info in FSVP'],
          });
        }
      }
    }

    // If `pga?.programCode === 'DEV'`, then:
    //    1. If `dii` is `undefined`, we should validate `deviceInitialImporter`
    //       since `sameAsIor` or a `deviceInitialImporter` must be selected
    //       and currently neither are selected (this is most commonly happening
    //       when an IOR has a non-US `countryCode` because then we don't show the
    //       `sameAsIor` toggle and thus `dii` is `undefined` by default rather
    //       than defaulting to `sameAsIor = true` like we do when an IOR has a US
    //       `countryCode`). This is effectively the same as #2 below because
    //       if `dii` is `undefined`, `sameAsIor` is considered `false` and so
    //       `deviceInitialImporter` should show as required
    //    2. If `!dii.sameAsIor`, then `deviceInitialImporter` must
    //       be selected (so we should go into the conditional and validate)
    if (programCode === 'DEV' && !dii?.sameAsIor) {
      validatePartySnapshot(
        [
          ...tariffNamePath,
          'pga',
          pgaFlag,
          'deviceInitialImporter',
          'customsAgent',
          'id',
        ],
        'Device Initial Importer',
      );
    }
    const getPgaValidationField = (fieldName: string | (string | number)[]) => {
      if (typeof fieldName === 'string') {
        return [...tariffNamePath, 'pga', pgaFlag, fieldName];
      } else {
        return [...tariffNamePath, 'pga', pgaFlag, ...fieldName];
      }
    };

    validateFields.push(getPgaValidationField('programCode'));
    validateFields.push(getPgaValidationField('processingCode'));
    validateFields.push(getPgaValidationField('disclaimerCode'));
    let pgaValidationFields: (string | (string | number)[])[] = [
      'programCode',
      'processingCode',
      'disclaimerCode',
    ];
    // Handle validations for errors thrown in `constructFDA` lib
    if (agencyCode === 'FDA') {
      pgaValidationFields = [
        ...pgaValidationFields,
        // Common fields
        'fdaProductCode',
        ['source', 'sourceCountryType'],
        ['source', 'countryCode'],
        'railCarNumbers',
        // Biology
        'tradeName',
        // Cosmetics

        // Medical
        'intendedUse',
        'intendedUseDescription',
        'deviceInitialImporter',
        'deviceForm',
        'devicePremarketNumber',
        'deviceDomesticManufacturer',
        'deviceForeignManufacturer',
        'deviceForeignExporter',
        'investigationalDeviceExemptionNumber',
        'deviceListingNumber',
        'electrode',
        'lotNumber',

        // Drug
        'investigationNewDrugApplicationNumber',
        'drugRegistrationNumber',
        'drugListingNumber',
        'drugApplicationNumber',
        'drugForm',

        // Food
        ['foodFacilityRegistration', 'number'],
        ['foodFacilityRegistration', 'exemption'],
        'shipmentCountryCode',
        'foodCanningEstablishmentNumber',
        'scheduleIdentifierNumber',
        'foodVolume',
        ['foreignSupplierVerificationProgram', 'customsAgent', 'id'],

        // Radiation Emitting Products
        'intendedUse',
        // Tobacco
        'laboratoryTypeCode',
        ['laboratory', 'facility', 'id'],
        // Animal Drugs and Devices
      ];

      const needPackaging = isPackagingNeeded(
        subjectToPN,
        pnConditionsMet,
        performanceStandard,
      );
      if (needPackaging && !pga?.packaging) {
        additionalErrors.push({
          title: 'Packaging Missing',
          type: ValidationErrorType.GENERAL,
          path: tariffNamePath,
          messages: [
            `${tariffErrorString} At least one level of packaging is needed`,
          ],
        });
      }
      pga?.packaging?.forEach(
        (packagingElement: AnyObject, packagingIndex: AnyObject) => {
          validateFields.push([
            ...getPgaValidationField('packaging'),
            packagingIndex,
            'value',
          ]);
          validateFields.push([
            ...getPgaValidationField('packaging'),
            packagingIndex,
            'unit',
          ]);
        },
      );
      if (programCode === 'DEV') {
        if (
          ['081.001', '081.002', '081.004'].includes(intendedUse) &&
          line.manufacturer?.countryCode === 'US'
        ) {
          additionalErrors.push({
            title: 'Intended Use Code Manufacturer',
            type: ValidationErrorType.GENERAL,
            path: tariffNamePath,
            messages: [
              `${tariffErrorString} manufacturer must be foreign for Intended Use Code ${intendedUse}`,
            ],
          });
        }
      }
    }
    if (agencyCode === 'TTB') {
      pgaValidationFields = [
        ...pgaValidationFields,
        ['ttbImporterPermitNumber', 'number'],
        ['ttbImporterPermitNumber', 'exemptionCode'],
        ['certificateOfLabelApproval', 'numbers'],
        ['certificateOfLabelApproval', 'exemptionCode'],
        ['ircRegistryNumber', 'number'],
        ['foreignCertificate', 'countryCode'],
        'paperOrTubeQuantity',
      ];
    }
    if (agencyCode === 'APH') {
      pgaValidationFields = [...pgaValidationFields];
      pga?.constituentElements?.forEach(
        (constituentElement: AnyObject, elementIndex: number) => {
          pgaValidationFields.push(
            ['constituentElements', elementIndex, 'name'],
            ['constituentElements', elementIndex, 'scientificName'],
            ['constituentElements', elementIndex, 'harvestCountryCode'],
            ['constituentElements', elementIndex, 'quantity', 'value'],
            ['constituentElements', elementIndex, 'quantity', 'unit'],
          );
        },
      );
      if (['AQ1', 'AQ2'].includes(pgaFlag) && !pga?.disclaimerCode) {
        pgaValidationFields.push(
          'aphProductCode',
          'productQualifierCode',
          ['source', 'sourceCountryType'],
          ['source', 'countryCode'],
          'intendedUse',
          'categoryTypeCode',
          'categoryCode',
          'originCountryCode',
        );
        if (pga?.LPCO) {
          pga?.LPCO?.forEach((lpco: AnyObject, elementIndex: number) => {
            pgaValidationFields.push(
              ['LPCO', elementIndex, 'lpcoFieldName'],
              ['LPCO', elementIndex, 'issuerLocation'],
              ['LPCO', elementIndex, 'number'],
              ['LPCO', elementIndex, 'date'],
              ['LPCO', elementIndex, 'quantity'],
              ['LPCO', elementIndex, 'uom'],
            );
          });
        }
        if (!pga?.packaging) {
          additionalErrors.push({
            title: 'Packaging Missing',
            type: ValidationErrorType.GENERAL,
            path: tariffNamePath,
            messages: [
              `${tariffErrorString} At least one level of packaging is needed`,
            ],
          });
        }
        pga?.packaging?.forEach(
          (packaging: AnyObject, elementIndex: number) => {
            pgaValidationFields.push(
              ['packaging', elementIndex, 'value'],
              ['packaging', elementIndex, 'unit'],
            );
          },
        );
        pga?.characteristics?.forEach(
          (characteristic: AnyObject, elementIndex: number) => {
            pgaValidationFields.push(
              ['characteristics', elementIndex, 'qualifier'],
              ['characteristics', elementIndex, 'code'],
            );
          },
        );
      }
    }
    if (agencyCode === 'NHT') {
      pgaValidationFields = [
        ...pgaValidationFields,
        // Common
        'conformanceDeclaration',
        'categoryCode',
        // MVS
        'tradeName',
        ['vehicle', 'model'],
        ['vehicle', 'modelYear'],
        ['permissionLetter', 'number'],
        ['vehicle', 'vehicleIdentificationNumbers'],

        // declaration === 1
        'tireManufacturerCode',

        // declaration === 1
        ['vehicle', 'manufactureMonth'],

        // declaration === 2B
        'driveSide',

        // declaration === 3
        ['vehicleEligibilityNumber', 'number'],
        ['registeredImporterNumber', 'number'],
        ['dotBond', 'suretyCode'],
        ['dotBond', 'serialNumber'],
        ['dotBond', 'amount'],

        // declaration === 5
        'passportNumber',
        'passportCountryCode',

        // declaration === 6
        'embassyCountryCode',

        // declaration === 7
        'glazingManufacturerCode',
        'iorIsVehicleManufacturer',
        ['permissionLetter', 'quantity'],
        ['permissionLetter', 'date'],
      ];
    }
    pgaValidationFields.forEach((field) =>
      validateFields.push(getPgaValidationField(field)),
    );
  }

  return {
    validateFields,
    additionalErrors,
  };
};

export const transformReportReconciliationRequest = {
  toSchema: ({ input }: { input: any }) => {
    input.reports = [];
    input.documentIds.forEach((docId: string) => {
      const report = {
        type: input.reportType,
        document: { id: docId },
      };
      input.reports.push(report);
    });

    delete input.reportType;
    delete input.documentIds;

    return input;
  },
};

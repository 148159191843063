import type { UsPrincipalAdCvdCase } from '@xbcb/api-gateway-client';
import { AnyObject } from '@xbcb/shared-types';
import { getEnv } from '@xbcb/ui-utils';
const { stage } = getEnv();
export interface CascaderOption {
  value?: string | number | null;
  label?: React.ReactNode;
  disabled?: boolean;
  children?: AnyObject[];
  id?: string;
  isLeaf?: boolean;
}

// Note: child cases will have their manufacturer information appended to their label (char 0-2 will be the last 3 digits of the caseNumber)
export const getCascaderOptions = (
  principalCases: UsPrincipalAdCvdCase[],
): CascaderOption[] => {
  return principalCases.map((principalCase) => {
    return {
      value: principalCase.id,
      label: principalCase.principalCaseNumber,
      isLeaf: false,
      children: principalCase.adCvdCases?.map(
        ({ caseNumber, id, manufacturerName }) => ({
          label:
            caseNumber.slice(-3) +
            (manufacturerName ? ` (${manufacturerName})` : ''),
          value: id,
          isLeaf: true,
        }),
      ),
    };
  });
};

import { AccountType, RecordType } from '@xbcb/shared-types';
import {
  transformParty,
  transformProduct,
  transformAssignmentTeam,
  transformUser,
  transformSubjectMatterExpertTeam,
  transformBusinessSupportTeam,
} from 'libs/formTransforms';
import validateOperatorUser from 'libs/validateOperatorUser';
import { validateProductCreate } from 'libs/validateProduct';
import React from 'react';
import {
  AppRecordValidateResult,
  AppRecordTransformCreateInputProps,
} from '@xbcb/ui-types';
import {
  Consignee,
  CustomsAgent,
  Facility,
  Forwarder,
  Invoice,
  Product,
  Supplier,
  Team,
  Trucker,
  User,
  OnboardUsIor,
  SubjectMatterExpertTeam,
  BusinessSupportTeam,
} from './loadableComponents';
import { validateAssignmentTeamCreate } from 'libs/validateAssignmentTeam';

// TODO make everything generic once we have apollo-client generated types
export type AppRecordValidateCreateProps = { input: any; operatorId?: string };
export type TransformCreateRecordInput = (
  props: AppRecordTransformCreateInputProps,
) => any;

export type AppRecordCreateProps = {
  recordType: RecordType; // e.g. usConsumptionEntry
  fields?: string;
  fragments?: string;
  validateCreate?: (
    props: AppRecordValidateCreateProps,
  ) => AppRecordValidateResult | Promise<AppRecordValidateResult>; // validations in addition to the automatic form ones
  transformCreateRecordInput?: TransformCreateRecordInput;
  quickCreate?: boolean;
  onCreate?: (value: any) => void;
  hideCreateButton?: boolean; // UI which does not use create button to create records e.g. UsIor onboarding UI.
  // TODO post create hook (generic or always modal?)
  shipperId?: string;
};

export type AppRecordCreateRoute = AppRecordCreateProps & {
  Page: React.FC<any>;
  // TODO change this name
  blacklist?: {
    accountTypes: AccountType[];
    redirectTo: string;
  };
};

export const appRecordCreateRoutes: AppRecordCreateRoute[] = [
  {
    Page: OnboardUsIor,
    recordType: RecordType.US_IOR,
    transformCreateRecordInput: transformParty.toSchema,
    hideCreateButton: true,
  },
  {
    Page: OnboardUsIor,
    recordType: RecordType.SHIPPER,
    transformCreateRecordInput: transformParty.toSchema,
    hideCreateButton: true,
  },
  {
    Page: Product,
    recordType: RecordType.PRODUCT,
    validateCreate: validateProductCreate,
    transformCreateRecordInput: transformProduct.toCreateSchema,
  },
  {
    Page: Invoice,
    recordType: RecordType.INVOICE,
  },
  {
    Page: Forwarder,
    recordType: RecordType.FORWARDER,
    transformCreateRecordInput: transformParty.toSchema,
  },
  {
    Page: Consignee,
    recordType: RecordType.US_CONSIGNEE,
    transformCreateRecordInput: transformParty.toSchema,
  },
  {
    Page: CustomsAgent,
    recordType: RecordType.CUSTOMS_AGENT,
    transformCreateRecordInput: transformParty.toSchema,
  },
  {
    recordType: RecordType.TRUCKER,
    Page: Trucker,
    transformCreateRecordInput: transformParty.toSchema,
  },
  {
    Page: Facility,
    recordType: RecordType.FACILITY,
    transformCreateRecordInput: transformParty.toSchema,
  },
  {
    Page: Supplier,
    recordType: RecordType.SUPPLIER,
    transformCreateRecordInput: transformParty.toSchema,
  },
  {
    Page: User,
    recordType: RecordType.OPERATOR_USER,
    transformCreateRecordInput: transformUser.toSchema,
    validateCreate: validateOperatorUser,
  },
  {
    Page: User,
    recordType: RecordType.FORWARDER_USER,
    transformCreateRecordInput: transformUser.toSchema,
  },
  {
    Page: User,
    recordType: RecordType.SHIPPER_USER,
    transformCreateRecordInput: transformUser.toSchema,
  },
  {
    Page: Team,
    recordType: RecordType.ASSIGNMENT_TEAM,
    validateCreate: validateAssignmentTeamCreate,
    transformCreateRecordInput: transformAssignmentTeam.toSchema,
  },
  {
    Page: SubjectMatterExpertTeam,
    recordType: RecordType.SUBJECT_MATTER_EXPERT_TEAM,
    transformCreateRecordInput: transformSubjectMatterExpertTeam.toSchema,
  },
  {
    Page: BusinessSupportTeam,
    recordType: RecordType.BUSINESS_SUPPORT_TEAM,
    transformCreateRecordInput: transformBusinessSupportTeam.toSchema,
  },
];

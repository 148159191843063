import React, { useCallback } from 'react';
import { Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from '@xbcb/ui-types';
import DisclaimerSelect from 'components/DisclaimerSelect';
import { StyledContainer } from './styles';

export interface DisclaimProps {
  disclaimerCodes: Record<string, string>;
  fieldName: string;
  form: FormInstance;
  fullNamePath: NamePath;
  isDisclaimed: boolean;
  setIsDisclaimed: React.Dispatch<React.SetStateAction<boolean>>;
  localNamePath: NamePath;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
}

export const Disclaim: React.FC<DisclaimProps> = ({
  disclaimerCodes,
  fieldName,
  form,
  fullNamePath,
  isDisclaimed,
  localNamePath,
  setIsDisclaimed,
  required,
  ...readOnlyDisabled
}) => {
  const { setFields } = form;
  const handleChange = useCallback(() => {
    setIsDisclaimed((disclaimed) => !disclaimed);
    setFields([
      {
        name: [...fullNamePath, fieldName],
        value: undefined,
      },
    ]);
  }, [fieldName, fullNamePath, setIsDisclaimed, setFields]);

  return (
    <StyledContainer>
      {isDisclaimed ? (
        <DisclaimerSelect
          localNamePath={[...localNamePath, fieldName]}
          disclaimerCodes={disclaimerCodes}
          {...readOnlyDisabled}
          required={required || isDisclaimed}
        />
      ) : null}
      <Checkbox
        checked={isDisclaimed}
        onChange={handleChange}
        {...readOnlyDisabled}
      >
        Disclaim?
      </Checkbox>
    </StyledContainer>
  );
};

export default Disclaim;

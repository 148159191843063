import { getEnv } from '@xbcb/ui-utils';
import { UiStage } from '@xbcb/ui-types';
import { RecordType } from '@xbcb/shared-types';

// helper lib for restricted launches.

const { stage } = getEnv();

export enum FEATURE_RELEASE_KEY {
  TASK_ESCALATION = 'TASK_ESCALATION',
  PERSONNEL_DASHBOARD = 'PERSONNEL_DASHBOARD',
  TEAM_FAST_FOLLOWS = 'TEAM_FAST_FOLLOWS',
}

const featureReleaseConfiguration: {
  [key: string]: { restrictedStages: string[]; pendingUat: boolean };
} = {
  [RecordType.TABLE_FILTER]: {
    restrictedStages: [],
    pendingUat: false,
  },
  [RecordType.ASSIGNMENT_TEAM]: {
    restrictedStages: [],
    pendingUat: false,
  },
  [FEATURE_RELEASE_KEY.TASK_ESCALATION]: {
    restrictedStages: [],
    pendingUat: false,
  },
  [FEATURE_RELEASE_KEY.PERSONNEL_DASHBOARD]: {
    restrictedStages: [UiStage.PROD],
    pendingUat: true,
  },
  [FEATURE_RELEASE_KEY.TEAM_FAST_FOLLOWS]: {
    restrictedStages: [],
    pendingUat: false,
  },
  [RecordType.CUSTOMS_DECLARATION]: {
    restrictedStages: [UiStage.PROD],
    pendingUat: true,
  },
  [RecordType.IMPORTER_ENTITY_ACTIVATION]: {
    restrictedStages: [UiStage.PROD],
    pendingUat: true,
  },
};
export const restrictedDeployment = (feature: string): boolean => {
  const configuration = featureReleaseConfiguration[feature];
  return (
    configuration.restrictedStages.includes(stage) && configuration.pendingUat
  );
};

import { formatRecordName } from '@xbcb/js-utils';
import { AccountType, RecordType } from '@xbcb/shared-types';
import { openModal } from 'actions';
import { ModalKey } from 'types';
import { AppRecordKebabMenuItem } from '../../types';

export const appRecordCreateUsIorContinuousBondRequestMenuItem: AppRecordKebabMenuItem =
  {
    key: 'createUsIorContinuousBondRequest',
    text: `Create ${formatRecordName({
      recordType: RecordType.US_IOR_CONTINUOUS_BOND_REQUEST,
      accountType: AccountType.OPERATOR,
    })}`,
    // All account type should be able to see this button. Additionally, there
    // are not any validations, so it should always show
    show: () => true,
    action: async ({ dispatch }) => {
      dispatch(
        openModal({
          key: ModalKey.CREATE_US_IOR_CONTINUOUS_BOND_REQUEST,
        }),
      );
    },
  };

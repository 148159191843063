import { memoFragments } from '@xbcb/client-queries';
import {
  customsAgentFragments,
  facilityFragments,
  forwarderFragments,
  shipperFragments,
  supplierFragments,
  truckerFragments,
} from '@xbcb/party-queries';
import { RecordType } from '@xbcb/shared-types';
import { executeMutation } from 'libs/executeMutation';
import { transformParty } from 'libs/formTransforms';
import { upsertSupplierCbpMid } from 'libs/sharedQueries';
import {
  CustomsAgent,
  Facility,
  Forwarder,
  Shipper,
  Supplier,
  Trucker,
} from '../../loadableComponents';
import { AppRecordRoute } from '../../types';
import {
  appRecordAbiDataMenuItem,
  appRecordCreateUsIorContinuousBondRequestMenuItem,
  appRecordMemoMenuItem,
  appRecordSuspendAccountMenuItem,
  appRecordActivateAccountMenuItem,
} from '../utils';
import usConsigneeRoute from './usConsignee';
import usIorRoute from './usIor';
import gbIorRoute from './gbIor';
import deIorRoute from './deIor';
import nlIorRoute from './nlIor';
import frIorRoute from './frIor';
import { getEnv } from '@xbcb/ui-utils';
import { UiStage } from '@xbcb/ui-types';

const { stage } = getEnv();

const partyRoutes: AppRecordRoute[] = [
  {
    Page: CustomsAgent,
    recordType: RecordType.CUSTOMS_AGENT,
    fields: `...customsAgentFields`,
    fragments: customsAgentFragments,
    transformUpdateRecordInput: transformParty.toSchema,
    transformRecordToInput: transformParty.toForm,
  },
  {
    Page: Forwarder,
    recordType: RecordType.FORWARDER,
    fields: `...forwarderFields supportsPersonalEffects`,
    fragments: forwarderFragments,
    transformUpdateRecordInput: transformParty.toSchema,
    transformRecordToInput: transformParty.toForm,
    kebabMenuItems: [
      appRecordSuspendAccountMenuItem(RecordType.FORWARDER),
      appRecordActivateAccountMenuItem(RecordType.FORWARDER),
    ],
  },
  {
    Page: Facility,
    recordType: RecordType.FACILITY,
    fields: `...facilityFields`,
    fragments: facilityFragments,
    transformUpdateRecordInput: transformParty.toSchema,
    transformRecordToInput: transformParty.toForm,
  },
  {
    Page: Shipper,
    recordType: RecordType.SHIPPER,
    fields: `...shipperFields memos { ...memoFields } usIors { nodes { id } }`,
    fragments: `${shipperFragments}${memoFragments}`,
    transformUpdateRecordInput: transformParty.toSchema,
    transformRecordToInput: transformParty.toForm,
    kebabMenuItems: [
      appRecordCreateUsIorContinuousBondRequestMenuItem,
      appRecordMemoMenuItem,
      appRecordSuspendAccountMenuItem(RecordType.SHIPPER),
      appRecordActivateAccountMenuItem(RecordType.SHIPPER),
    ],
    hideDeleteKebabMenuItem: () => stage !== UiStage.PROD, // The operator should use cancel button now
    showCancelUncancelKebabMenuItem: () => stage !== UiStage.PROD,
  },
  {
    Page: Supplier,
    recordType: RecordType.SUPPLIER,
    fields: `...supplierFields`,
    fragments: supplierFragments,
    transformUpdateRecordInput: transformParty.toSchema,
    transformRecordToInput: transformParty.toForm,
    kebabMenuItems: [
      appRecordAbiDataMenuItem,
      {
        key: 'upsertSupplierCbpMid',
        text: 'Update MID',
        show: ({ existingRecord }) =>
          existingRecord?.complianceDetails?.us?.mid,
        action: async ({ record }) => {
          await executeMutation({
            mutation: upsertSupplierCbpMid,
            variables: { supplierId: record?.id },
          });
        },
      },
    ],
  },
  {
    Page: Trucker,
    recordType: RecordType.TRUCKER,
    fields: `...truckerFields`,
    fragments: truckerFragments,
    transformUpdateRecordInput: transformParty.toSchema,
    transformRecordToInput: transformParty.toForm,
  },
  usConsigneeRoute,
  usIorRoute,
  gbIorRoute,
  deIorRoute,
  nlIorRoute,
  frIorRoute,
];

export default partyRoutes;

import SubMenuOptions, { SubMenuOptionsProps } from 'components/SubMenuOptions';
import { AnyObject } from '@xbcb/shared-types';
import { amsIndicatorOptionsDisplayMap } from 'libs/amsIndicatorOptionsConfiguration';

const AmsIndicatorOptions = ({
  action,
  record,
  ...restProps
}: SubMenuOptionsProps) => {
  const items = Object.keys(amsIndicatorOptionsDisplayMap).reduce(
    (acc: AnyObject, key) => {
      acc[key] = {
        item: (
          <span>
            {
              amsIndicatorOptionsDisplayMap[
                key as keyof typeof amsIndicatorOptionsDisplayMap
              ]
            }
          </span>
        ),
      };
      return acc;
    },
    {},
  );

  return (
    <SubMenuOptions
      {...restProps}
      items={items}
      action={action}
      record={record}
    />
  );
};

export default AmsIndicatorOptions;

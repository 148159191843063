import { VneFieldValidator, VneValidationArgs } from '../types';
import { VneRegulatoryCategory } from '__generated__/graphql';
import { AppRecordValidateResult } from '@xbcb/ui-types';

export class HeavyDutyVneValidator implements VneFieldValidator {
  isApplicable = ({ pga }: VneValidationArgs) =>
    pga.regulatoryCategory === VneRegulatoryCategory.HeavyDutyHighwayAndNonRoad;

  validate(_: VneValidationArgs): AppRecordValidateResult {
    return {
      validateFields: [['vneIndustryCode']],
    };
  }
}

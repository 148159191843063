import React from 'react';
import copyToClipboard from 'libs/copyToClipboard';
import { AppRecordSecondaryHeaderContentProps } from 'routes';
import { LinkOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

const StyledDiv = styled.div`
  font-size: 18px;

  .warning-icon {
    color: var(--primary-color);
  }

  .anticon:not(:first-child) {
    margin-left: var(--space-4);
  }
`;

const UsIsfSecondaryHeader: React.FC<AppRecordSecondaryHeaderContentProps> = ({
  record,
}) => {
  return (
    <StyledDiv>
      <Tooltip title="Copy the ISF URL" placement="top">
        <LinkOutlined onClick={() => copyToClipboard(window.location.href)} />
      </Tooltip>
    </StyledDiv>
  );
};

export default UsIsfSecondaryHeader;

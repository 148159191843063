import { TableOptions } from '@xbcb/table-utils';
import { SortOrder } from '@xbcb/shared-types';

export const TABLES_SAVE = 'TABLES_SAVE';
export const TABLES_UPDATE = 'TABLES_UPDATE';
export const TABLE_REPLACE = 'TABLE_REPLACE';
export const TABLES_REPLACE = 'TABLES_REPLACE';
export const TABLE_MOVE_COLUMN = 'TABLE_MOVE_COLUMN';

export interface TableState extends TableOptions {
  columns?: string[];
  filters?: {
    [key: string]: {
      operator: string;
      type?: string;
      value?: string;
    };
  };
}

export type TablesState = {
  [key: string]: TableState;
};

export interface TablesPayload {
  [key: string]: TableState;
}

export interface TableReplacePayload {
  tableName: string;
  table: TableState;
}

export interface TableMoveColumnPayload {
  columnIndex: number;
  tableName: string;
  direction: 'left' | 'right';
  columnOptions: string[];
}

export type SortOption = {
  // name of the primary field used to sort the table
  // for field names, refer to @xbcb/table-utils
  field: string;
  // indicates if the field should be sorted in ascending, or descending order
  order: SortOrder;
};

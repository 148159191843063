export default ({ programCode, processingCode, iuc }) => {
  let showDrugApplication;
  let showDrugRegistration;
  let showDrugListing;
  let showInvestigationalNewDrug;
  if (programCode === 'DRU') {
    if (processingCode === 'OTC') {
      if (
        ['130.000', '150.007', '150.013', '150.017', '155.009'].includes(iuc)
      ) {
        showDrugRegistration = true;
        showDrugListing = true;
      }
    } else if (processingCode === 'PRE') {
      if (
        [
          '080.012',
          '150.007',
          '150.013',
          '150.017',
          '155.009',
          '980.000',
        ].includes(iuc)
      ) {
        showDrugRegistration = true;
        showDrugListing = true;
      }
      if (['080.012', '150.007'].includes(iuc)) showDrugApplication = true;
    } else if (processingCode === 'INV') {
      if (iuc === '180.009') showInvestigationalNewDrug = true;
    }
  }
  return {
    showDrugRegistration,
    showDrugListing,
    showDrugApplication,
    showInvestigationalNewDrug,
  };
};

import React, { ReactNode } from 'react';
import { Form, Input } from 'antd';
import {
  Option,
  FormItem,
  Select,
  HiddenField,
  Checkbox,
} from '@xbcb/form-item-components';
import { intendedUseCodes } from 'selectors';
import {
  selectFilter,
  getCATAIR,
  getCodes,
  shouldUpdate,
} from '@xbcb/ui-utils';
import { clearFields } from 'libs/clearFields';
import DisclaimerSelect from 'components/DisclaimerSelect';
// When new form is added or one of these are modified, please make changes in libs/validateUsConsumptionEntry/index.ts
import FdaForm from 'components/FdaForm';
import AphForm from 'components/AphForm';
import TtbForm from 'components/TtbForm';
import NhtForm from 'components/NhtForm';
import EpaForm from 'components/EpaForm';
import { get } from 'lodash';
import { FormInstance } from 'antd/lib/form';
import { PgaFormBaseProps } from '../../types';
import {
  StyledTooltipOption,
  StyledFormInlineDiv,
  StyledFlexDiv,
} from './styles';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { UsPgaFlag } from '__generated__/graphql';
import IntendedUseSelect from 'components/IntendedUseSelect';
import { useWorkOrderTaskConfiguration } from 'libs/hooks';
export interface PgaFormProps {
  form: FormInstance;
  formDisabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  pgaFlag: UsPgaFlag;
  dispatch?: any;
  countsPath?: any;
  tab?: ReactNode;
}

// TODO Need to add a status hidden field based on the response when submitting a cargo release.

const PgaForm: React.FC<PgaFormProps> = ({
  form,
  localNamePath,
  fullNamePath,
  dispatch,
  countsPath,
  formDisabled,
  readOnly = false,
  required,
  pgaFlag,
  tab,
  ...restProps
}) => {
  const { readWriteControlsConfig } = useWorkOrderTaskConfiguration();

  const isPGAReadOnly =
    !!readWriteControlsConfig['invoices.products.lines.tariffs.pga']?.readOnly;
  const isPGARequired =
    !!readWriteControlsConfig['invoices.products.lines.tariffs.pga']?.required;

  const agencyCodes = getCodes().CBP.CATAIR.PGA.agency;

  const pgaFlags = getCodes().CBP.CATAIR.PGA.flag;

  const { getFieldValue, setFields } = form;

  const flag = pgaFlags[pgaFlag];

  const { agencyCode, text, disclaimerCodes: filterDisclaimerCodes } = flag;
  const disclaimerCodes = getCATAIR().PGA.disclaimer;

  const agency = agencyCodes[agencyCode];

  const program = (code: string) => get(agency, `program.${code}`, {});

  // needs to be a function because we need to evaluate this after
  const getProcessingCodes = () => {
    const programCode = getFieldValue([...fullNamePath, 'programCode']);
    const selectedProgram = program(programCode);
    return Object.entries(get(selectedProgram, 'processing', {})).map(
      ([code, name]: [string, any]) => (
        <StyledTooltipOption key={code} value={code}>
          {name}
        </StyledTooltipOption>
      ),
    );
  };

  return (
    <>
      <HiddenField
        localNamePath={[...localNamePath, 'agencyCode']}
        value={agencyCode}
        fullNamePath={[...fullNamePath, 'agencyCode']}
        form={form}
      />
      <StyledFormInlineDiv>
        <p>{text}</p>
        <StyledFlexDiv>
          <Form.Item
            shouldUpdate={shouldUpdate([[...fullNamePath, 'disclaim']])}
            noStyle
          >
            {() => {
              const disclaim = getFieldValue([...fullNamePath, 'disclaim']);
              const programCodes =
                typeof flag.programCode === 'string'
                  ? [
                      <Option key={flag.programCode} value={flag.programCode}>
                        {flag.programCode} - {program(flag.programCode).name}
                      </Option>,
                    ]
                  : flag.programCode
                      .filter(
                        // Only OFF is allowed for disclaimed NHTSA shipments.
                        (code: string) =>
                          pgaFlag === 'DT1' && disclaim && code !== 'OFF'
                            ? false
                            : true,
                      )
                      .map((p: any) => (
                        <StyledTooltipOption key={p} value={p}>
                          {program(p).name}
                        </StyledTooltipOption>
                      ));
              return (
                <>
                  {!(disclaim && agencyCode === 'FDA') && (
                    <FormItem
                      label="Program Code"
                      name={[...localNamePath, 'programCode']}
                      rules={[{ required, message: ' ' }]}
                      $readOnly={readOnly || isPGAReadOnly}
                      $itemSize={CssSize.SHORT}
                    >
                      <Select
                        disabled={formDisabled}
                        onChange={clearFields({
                          form,
                          fullNamePath,
                          exclude: [
                            'countryCode',
                            'fdaProductCode',
                            'agencyCode',
                            'programCode',
                            'disclaim',
                            'disclaimerCode',
                          ],
                        })}
                        showSearch
                        allowClear
                        filterOption={selectFilter}
                        notFoundContent={`None Found`}
                        dropdownMatchSelectWidth={false}
                      >
                        {programCodes}
                      </Select>
                    </FormItem>
                  )}{' '}
                </>
              );
            }}
          </Form.Item>
          <Form.Item
            shouldUpdate={shouldUpdate([
              [...fullNamePath, 'programCode'],
              [...fullNamePath, 'disclaim'],
            ])}
            noStyle
          >
            {() => {
              const disclaim = getFieldValue([...fullNamePath, 'disclaim']);
              return (
                <>
                  {!disclaim && getProcessingCodes().length > 0 && (
                    <FormItem
                      label="Processing Code"
                      name={[...localNamePath, 'processingCode']}
                      rules={[{ required, message: ' ' }]}
                      $readOnly={readOnly || isPGAReadOnly}
                      $itemSize={CssSize.SHORT}
                    >
                      <Select
                        disabled={formDisabled}
                        onChange={clearFields({
                          form,
                          fullNamePath,
                          exclude: [
                            'source',
                            'fdaProductCode',
                            'agencyCode',
                            'processingCode',
                            'programCode',
                            'disclaim',
                            'disclaimerCode',
                          ],
                        })}
                        showSearch
                        allowClear
                        filterOption={selectFilter}
                        notFoundContent={`None Found`}
                        dropdownMatchSelectWidth={false}
                      >
                        {getProcessingCodes()}
                      </Select>
                    </FormItem>
                  )}
                </>
              );
            }}
          </Form.Item>
          <Form.Item
            shouldUpdate={shouldUpdate([[...fullNamePath, 'disclaim']])}
            noStyle
          >
            {() => {
              const disclaim = getFieldValue([...fullNamePath, 'disclaim']);
              return (
                <>
                  {flag.required === 'M' && disclaim && (
                    <DisclaimerSelect
                      disclaimerCodes={disclaimerCodes}
                      localNamePath={[...localNamePath, 'disclaimerCode']}
                      required={disclaim || isPGARequired}
                      disabled={formDisabled}
                      readOnly={readOnly || isPGAReadOnly}
                      filter={filterDisclaimerCodes}
                    />
                  )}
                </>
              );
            }}
          </Form.Item>
          {/* TODO: disclaim field is not in schema, need to add a transform */}
          <Form.Item
            shouldUpdate={shouldUpdate([[...fullNamePath, 'disclaim']])}
          >
            {() => {
              const disclaim = getFieldValue([...fullNamePath, 'disclaim']);
              return (
                <>
                  {/* AQ2 does not allow disclaiming. Check Section II – Disclaim and HTS Flagging Guidance (https://www.cbp.gov/sites/default/files/assets/documents/2020-May/APHIS%20Supplemental%20Trade%20Guide%20-%20Appendix%20APH-A%20%28ver.%202.0%29.pdf) */}
                  {flag.required === 'M' &&
                    pgaFlag !== UsPgaFlag.Aq2 &&
                    (!readOnly || disclaim) && (
                      <Checkbox
                        form={form}
                        fullNamePath={[...localNamePath, 'disclaim']}
                        $spaceTop
                        $removeSpaceBottom
                        onChange={(value) => {
                          if (value && pgaFlag === 'DT1') {
                            // Highway Traffic Safety Administration only allows OFF code if disclaiming
                            setFields([
                              {
                                name: [...fullNamePath, 'programCode'],
                                value: 'OFF',
                              },
                            ]);
                          }
                          clearFields({
                            form,
                            fullNamePath,
                            exclude: [
                              'agencyCode',
                              'processingCode',
                              'programCode',
                              'disclaim',
                            ],
                          })();
                        }}
                        disabled={formDisabled}
                        text="Disclaim?"
                      />
                    )}
                </>
              );
            }}
          </Form.Item>
        </StyledFlexDiv>
        {[UsPgaFlag.Aq1, UsPgaFlag.Aq2].includes(pgaFlag) && (
          <StyledFlexDiv>
            <Form.Item
              shouldUpdate={shouldUpdate([
                [...fullNamePath, 'programCode'],
                [...fullNamePath, 'intendedUse'],
              ])}
              noStyle
            >
              {() => {
                const codes = intendedUseCodes({
                  agencyCode: 'APH',
                  drugForm: '',
                  processingCode: '',
                  programCode: '',
                });
                return (
                  <IntendedUseSelect
                    disabled={formDisabled}
                    readOnly={readOnly || isPGAReadOnly}
                    form={form}
                    required={required || isPGARequired}
                    localNamePath={[...localNamePath, 'intendedUse']}
                    fullNamePath={[...fullNamePath, 'intendedUse']}
                    codes={codes}
                  />
                );
              }}
            </Form.Item>
            <Form.Item
              shouldUpdate={shouldUpdate([[...fullNamePath, 'intendedUse']])}
            >
              {() => {
                const intendedUseCode = getFieldValue([
                  ...fullNamePath,
                  'intendedUse',
                ]);
                return (
                  // Intended Use Description should present for 980.000(For Other Use)
                  intendedUseCode === '980.000' && (
                    <FormItem
                      name={[...localNamePath, 'intendedUseDescription']}
                      label="Intended Use Description"
                      $itemSize={CssSize.SHORT}
                      $readOnly={readOnly}
                      $inline
                      debounce
                    >
                      <Input.TextArea
                        autoSize
                        disabled={formDisabled}
                        maxLength={21}
                      />
                    </FormItem>
                  )
                );
              }}
            </Form.Item>
          </StyledFlexDiv>
        )}
      </StyledFormInlineDiv>
      {/* We're passing programCode to make FDA Form re-render with the rest of PGA Form because we're doing a bunch of imperative shit in FDA Form that gets messed up otherwise.*/}
      <Form.Item shouldUpdate={shouldUpdate([[...fullNamePath, 'disclaim']])}>
        {() => {
          const disclaim = getFieldValue([...fullNamePath, 'disclaim']);
          const mot = getFieldValue(['conveyance', 'modeOfTransport']);
          const pgaProps: PgaFormBaseProps = {
            pgaFlag,
            disabled: formDisabled,
            readOnly: readOnly || isPGAReadOnly,
            required: required || isPGARequired,
            fullNamePath,
            localNamePath,
          };
          return (
            <>
              {!disclaim &&
                (agencyCode === 'FDA' ? (
                  <FdaForm mot={mot} {...pgaProps} />
                ) : agencyCode === 'APH' ? (
                  <AphForm {...pgaProps} />
                ) : agencyCode === 'TTB' ? (
                  <TtbForm {...pgaProps} />
                ) : agencyCode === 'EPA' ? (
                  <EpaForm {...pgaProps} />
                ) : (
                  agencyCode === 'NHT' && <NhtForm {...pgaProps} />
                ))}
            </>
          );
        }}
      </Form.Item>
    </>
  );
};

export default PgaForm;

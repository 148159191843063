import type {
  GbCustomsEntryLine,
  DeCustomsEntryLine,
  FrCustomsEntryLine,
  NlCustomsEntryLine,
} from '@xbcb/api-gateway-client';
import { AnyObject } from '@xbcb/shared-types';
import { pick } from 'lodash';

type EuCustomsEntryLine =
  | GbCustomsEntryLine
  | DeCustomsEntryLine
  | FrCustomsEntryLine
  | NlCustomsEntryLine;

export const transformLines = {
  toSchema: ({ lines }: { lines?: AnyObject[] }): EuCustomsEntryLine[] => {
    lines?.forEach((line: AnyObject) => {
      if (line.tariffs) {
        line.tariffs.forEach((tariff: AnyObject) => {
          if (tariff.unitValue && !tariff.unitValue.currency) {
            tariff.unitValue = {
              currency: 'USD',
              value: tariff.unitValue.value,
            };
          }
        });
      }
      if (line?.manufacturer?.supplier) {
        // Supplier in CustomsEntrySupplierInput is IdInput
        // https://cbms-schema.inlt.com/customsentrysupplierinput.doc.html
        line.manufacturer.supplier = pick(line.manufacturer.supplier, ['id']);
      }
    });
    return lines as EuCustomsEntryLine[];
  },
};

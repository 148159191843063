import styled from 'styled-components';
import { Button } from 'antd';

export const StyledNotFoundContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  // Needed to patch an antd bug; can be removed when version is >5
  pointer-events: auto;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

import type { UsPrincipalAdCvdCase } from '@xbcb/api-gateway-client';
import { AdCvdCaseStatus } from '@xbcb/trade-data-types';

export enum UsPrincipalAdCvdCaseType {
  AD = 'AD',
  CV = 'CV',
}

export interface UsPrincipalAdCvdCaseFilterCriteria {
  countryCode?: string;
  caseType?: UsPrincipalAdCvdCaseType;
}

export const filterForActiveAdCvdCases = (
  cases: UsPrincipalAdCvdCase[],
  criteria: UsPrincipalAdCvdCaseFilterCriteria = {},
) => {
  return cases
    .map(removeInactiveCasesFromPrincipalCase)
    .filter((principalCase) => isActiveMatchingCase(principalCase, criteria));
};

const removeInactiveCasesFromPrincipalCase = (
  principalCase: UsPrincipalAdCvdCase,
) => ({
  ...principalCase,
  adCvdCases: principalCase.adCvdCases?.filter(
    ({ status }) => status === AdCvdCaseStatus.ACTIVE,
  ),
});

const isActiveMatchingCase = (
  principalCase: UsPrincipalAdCvdCase,
  criteria: UsPrincipalAdCvdCaseFilterCriteria,
) => {
  const {
    adCvdCases,
    countryCode,
    principalCaseNumber: caseNumber,
  } = principalCase;

  const { countryCode: targetCountryCode, caseType } = criteria;
  const targetPrefix = determineTargetCasePrefix(caseType);

  const hasActiveCases = adCvdCases && adCvdCases.length > 0;
  const countryMatch = !targetCountryCode || countryCode === targetCountryCode;
  const typeMatch = !targetPrefix || caseNumber.startsWith(targetPrefix);

  return hasActiveCases && countryMatch && typeMatch;
};

const determineTargetCasePrefix = (targetCaseType?: UsPrincipalAdCvdCaseType) =>
  targetCaseType ? caseTypeToPrefixMapping[targetCaseType] : undefined;

const caseTypeToPrefixMapping: Record<UsPrincipalAdCvdCaseType, string> = {
  [UsPrincipalAdCvdCaseType.AD]: 'A',
  [UsPrincipalAdCvdCaseType.CV]: 'C',
};

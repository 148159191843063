import React from 'react';
import { YearSelect } from '@xbcb/form-item-components';
import { Form } from 'antd';
import moment from 'moment';
import { YEAR_FIRST_CAR_WAS_MANUFACTURED } from 'libs/vehicles';
import { ConditionalOnRemarksCodes } from '../types';
import { VneRequirements } from 'libs/pgaHelpers/epa';

export const ModelYear: ConditionalOnRemarksCodes = ({
  importCode,
  readOnly,
  disabled,
  localNamePath,
  required,
}) => {
  const form = Form.useFormInstance();
  const currentYear = moment().year();

  const shouldShow = VneRequirements.isModelYearRequired(importCode);

  return shouldShow ? (
    <YearSelect
      form={form}
      localNamePath={[...localNamePath, 'vehicle', 'modelYear']}
      readOnly={readOnly}
      aria-label={'Model Year Select'}
      disabled={disabled}
      label="Model Year"
      startYear={YEAR_FIRST_CAR_WAS_MANUFACTURED}
      endYear={currentYear}
      required={required}
    />
  ) : null;
};

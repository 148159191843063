import React from 'react';
import { useCurrentUser } from 'libs/hooks';
import { AuthenticatedRoutesProvidedProps } from 'routes/types';
import { ActivatedRoutes } from './ActivatedRoutes';
import { OnboardingRoutes } from './OnboardingRoutes';

// N.B. any component that is rendered inside of `AppRecord` or `AppRecordCreate` received props of the type `RecordProps`, which can be found in XbcbNewAppUi/packages/react/src/routes/types.ts
const AuthenticatedRoutes: React.FC<AuthenticatedRoutesProvidedProps> = ({
  activated,
  customType,
}) => {
  const currentUser = useCurrentUser();
  const { accountType, ...user } = currentUser;

  return activated ? (
    <ActivatedRoutes accountType={accountType} user={user} />
  ) : (
    <OnboardingRoutes customType={customType} />
  );
};

export default AuthenticatedRoutes;

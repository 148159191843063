import styled from 'styled-components';
import { Modal, Form } from 'antd';

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  label {
    font-size: 18px;
  }
  .space-right {
    padding-right: 4px;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledForm = styled(Form)`
  display: inline;
  margin: 0;
`;

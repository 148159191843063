import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    background: var(--layout-body-background);

    .ant-page-header {
      max-width: unset;
    }
  }
`;

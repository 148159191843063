import IsfNotificationModalContent from 'components/ISFNotificationModalContent';
import DuplicateDocModal from 'components/DuplicateDocModal';
// import DuplicateReferenceModal from 'components/DuplicateReferenceModal';
import JSONModal from 'components/JSONModal';

const modalHash = {
  ISFNotificationModal: IsfNotificationModalContent,
  DuplicateDocModal,
  // DuplicateReferenceModal,
  JSONModal,
};

const ModalComponents = (key: string) => {
  return modalHash[key as keyof typeof modalHash];
};

export default ModalComponents;

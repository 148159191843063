import React from 'react';
import { Form } from 'antd';
import { useQuery } from '@apollo/client';
import { OptionalCompanyInfo } from '@xbcb/form-components';
import type { UsIor as ApigUsIor } from '@xbcb/api-gateway-client';
import PartyForm from 'components/PartyForm';
import RequestCbpNumberModal from 'components/RequestCbpNumberModal';
import { shouldUpdate, useModal, safeGetMessage } from '@xbcb/ui-utils';
import { RecordType, AccountType, TagKey } from '@xbcb/shared-types';
import { CssSize, DataCySuffix } from '@xbcb/ui-types';
import {
  UsIorActivationStatus,
  UsIorPaymentTerms,
  UsIorPmsStatus,
} from '@xbcb/party-types';
import { SimpleSelect } from '@xbcb/form-item-components';
import TeamDataModal from 'components/TeamDataModal';
import BondInfoCard from 'components/BondInfoCard';
import { ModalKey } from 'types';
import CreateUsIorContinuousBondRequestModal from 'components/CreateUsIorContinuousBondRequestModal';
import { RecordProps } from 'routes';
import LinkForwarderToUsIorModal from 'components/LinkForwarderToUsIorModal';
import PersonalEffects from 'components/PersonalEffects';
import SignLinksWrapper from 'components/SignLinksWrapper';
import { getRecordFromResponseV2, SearchQuery } from '@xbcb/shared-queries';
import { usIorActivationFragments } from 'libs/sharedQueries';
import { useCurrentUser, useCustomsBrokerId } from 'libs/hooks';
import { isFinanceTeamUser } from 'libs/isFinanceTeamUser';
import PaymentTermsCard from 'components/PaymentTermsCard';
import { iscbpReg2ChangesFeatureEnabled } from 'featureFlags';
import { useBundle } from '@amzn/react-arb-tools';

const UsIor: React.FC<RecordProps> = ({
  form,
  disabled,
  readOnly,
  hideDocs,
  // record may be undefined on the create page, thus default it to {} here
  record = {},
}) => {
  const [partyBundle] = useBundle('components.Party');
  const countryCodeFieldNamePath = ['addresses', 'mailing', 'countryCode'];
  const { closeModal } = useModal(ModalKey.TEAM_DATA);
  const usIor = record as ApigUsIor;
  const { activations = [], activationStatus } = usIor;

  // supportsPersonalEffects is related to IOR pages personalEffects related fields
  // if IOR has related forwarder with supportsPersonalEffects flag true
  // or IOR has no forwarder but only shipper customer, we should display personal effects fields
  const forwarderSupportsPEFlag = usIor?.shippers?.nodes?.some((shipper) => {
    const forwarders = shipper.forwarders?.nodes;
    return forwarders?.some(
      (forwarder) => forwarder?.supportsPersonalEffects === true,
    );
  });
  const iorWithoutForwarderRelationshipFlag = !usIor?.shippers?.nodes?.some(
    (shipper) => {
      const forwarders = shipper.forwarders?.nodes;
      return Boolean(forwarders?.length);
    },
  );
  const forwarders = usIor?.shippers?.nodes?.flatMap(
    (node) => node.forwarders?.nodes,
  );
  const iorWithShipperRelationShipFlag = usIor?.shippers?.nodes?.length > 0;
  const supportsPersonalEffects =
    forwarderSupportsPEFlag ||
    (iorWithoutForwarderRelationshipFlag && iorWithShipperRelationShipFlag);

  const searchUsIorActivationQuery = SearchQuery({
    recordName: RecordType.US_IOR_ACTIVATION,
    fields: '...usIorActivationFields',
    fragments: usIorActivationFragments,
  });
  const paymentTermsMap: Record<string, string> = {
    [UsIorPaymentTerms.DAILY_STATEMENT]: 'Daily Statement',
    [UsIorPaymentTerms.PREPAID]: 'Prepaid',
  };

  if (usIor.pmsStatus === UsIorPmsStatus.ACTIVE)
    paymentTermsMap[UsIorPaymentTerms.PMS] = 'Periodic Monthly Statement';

  const paymentTermsNamePath = ['paymentTerms'];
  // Code below assumes that firewall logic will automatically filter result by forwarder id.
  const searchQueryVariables = {
    input: {
      searchCriteria: {
        iorId: {
          values: [record.id],
          operator: 'EQUALS',
        },
      },
    },
  };
  const { data, loading: iorActivationQueryLoading } = useQuery(
    searchUsIorActivationQuery,
    {
      variables: searchQueryVariables,
      skip: !record.id,
    },
  );
  const currentUser = useCurrentUser();
  const currentAccountType = currentUser.accountType;
  const brokerId = useCustomsBrokerId();
  const { results = [] } =
    getRecordFromResponseV2({
      response: { data },
      crudOrSearchType: 'search',
      recordName: RecordType.US_IOR_ACTIVATION,
    }) || {};
  // there should be 1 activation work order for this Forwarder-UsIor combination
  const usIorActivation = results[0];

  const isImporterActivated =
    activationStatus === UsIorActivationStatus.ACTIVATED ||
    activations?.[0]?.status === UsIorActivationStatus.ACTIVATED;

  return (
    <Form.Item shouldUpdate={shouldUpdate([countryCodeFieldNamePath])} noStyle>
      {() => {
        return (
          <>
            <RequestCbpNumberModal />
            <TeamDataModal
              closeModal={closeModal}
              recordType={RecordType.US_IOR}
            />
            <CreateUsIorContinuousBondRequestModal
              record={record}
              recordType={RecordType.US_IOR}
            />
            <LinkForwarderToUsIorModal record={record} />
            <PartyForm
              recordType={RecordType.US_IOR}
              form={form}
              disabled={disabled}
              readOnly={readOnly}
              showUsCbp5106AddressType
              showUnknownDuns
              showBusinessWebsite={iscbpReg2ChangesFeatureEnabled}
              contactInfoRequired
              enforceNamePattern
              hideDocs={hideDocs}
              customPermissions={
                // Forwarders should not be able to edit or delete documents,
                // all other users fallback to the default (undefined) which
                // will end up relying on the `checkAccess` lib
                currentAccountType === AccountType.FORWARDER
                  ? { READ: true }
                  : undefined
              }
              additionalRightContent={
                <>
                  <BondInfoCard record={record} />
                  <PaymentTermsCard record={record} />
                </>
              }
              additionalCompanyInfo={
                <>
                  {isFinanceTeamUser(currentUser) && (
                    <SimpleSelect
                      inline={true}
                      $itemSize={CssSize.SHORT}
                      label={safeGetMessage(partyBundle, 'payment_terms')}
                      localNamePath={paymentTermsNamePath}
                      fullNamePath={paymentTermsNamePath}
                      disabled={disabled}
                      readOnly={readOnly}
                      form={form}
                      map={paymentTermsMap}
                      dataCySuffix={DataCySuffix.US_IOR_PAYMENT_TERMS}
                    />
                  )}
                  {supportsPersonalEffects && (
                    <PersonalEffects
                      form={form}
                      disabled={disabled}
                      readOnly={readOnly}
                      localNamePath={['personalEffects']}
                      fullNamePath={['personalEffects']}
                      allRequired={false}
                    />
                  )}
                </>
              }
            >
              <OptionalCompanyInfo
                form={form}
                disabled={disabled}
                readOnly={readOnly}
                accountType={currentAccountType}
              />
              {
                // Sign link wrapper should be visible to only ForwarderUser when its activation status
                // is not ACTIVATED in order to generate new links and see unsigned links.
                ((currentAccountType === AccountType.FORWARDER &&
                  !isImporterActivated) ||
                  currentAccountType === AccountType.OPERATOR) &&
                  !iorActivationQueryLoading && (
                    <SignLinksWrapper
                      deleted={Boolean(record.deleted)}
                      canceled={Boolean(record.status === 'CANCELED')}
                      ior={record}
                      // operator user wants to look at all forwarders linked to Ior, forwarder user should only get its own forwarder as we only allow OperatorUser or ForwarderUser to view this component.
                      forwarders={
                        currentAccountType === AccountType.OPERATOR
                          ? forwarders
                          : [currentUser.account]
                      }
                      dsrTags={[
                        {
                          key: TagKey.US_IOR_ID,
                          value: record.id,
                        },
                        ...(usIorActivation
                          ? [
                              {
                                key: TagKey.US_IOR_ACTIVATION_ID,
                                value: usIorActivation.id,
                              },
                            ]
                          : []),
                        {
                          key: TagKey.US_CUSTOMS_BROKER_ID,
                          value: brokerId,
                        },
                      ]}
                    />
                  )
              }
            </PartyForm>
          </>
        );
      }}
    </Form.Item>
  );
};

export default UsIor;

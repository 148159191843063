import moment from 'moment';
import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { Popover, FormItemProps } from 'antd';
import { cloneDeep } from 'lodash';
import { NamePath } from '@xbcb/ui-types';
import { StyledButton, StyledDiv, StyledSpan } from './styles';
import { DataSourceType, AccountType } from '@xbcb/shared-types';
import { useCurrentUser } from 'libs/hooks';
export interface PendingUpdatesHighlightProps extends FormItemProps {
  form: FormInstance;
  fullNamePath: NamePath;
  children: React.ReactNode;
}

type PendingUpdateDetails = {
  path: NamePath;
  pendingUpdate: any;
  existingValue: any;
};

const findPendingUpdateForPath = (
  pendingUpdates: any[],
  fullNamePath: NamePath,
) => {
  return pendingUpdates.find(
    ({ path }) => path === fullNamePath.toString().replace(/\,/g, '.'),
  );
};

const findCorrespondingVersionPendingUpdateForId = (
  pendingUpdates: any[],
  fullNamePath: NamePath,
  form: FormInstance,
): PendingUpdateDetails | undefined => {
  if (fullNamePath.length < 1 || fullNamePath[fullNamePath.length - 1] !== 'id')
    return;
  const fullNamePathForVersion = fullNamePath.slice(0, -1);
  fullNamePathForVersion.push('version');
  const pendingUpdateForVersion = findPendingUpdateForPath(
    pendingUpdates,
    fullNamePathForVersion,
  );
  if (!pendingUpdateForVersion) return;
  return {
    path: fullNamePathForVersion,
    pendingUpdate: pendingUpdateForVersion,
    existingValue: cloneDeep(form.getFieldValue(fullNamePathForVersion)),
  };
};

// returns a color on a spectrum from red to green based on a low or high confidence score from 0 to 1
const getColorFromScore = (score: number) => {
  score = Math.max(0, Math.min(1, score));
  const red = Math.floor(255 * (1 - score));
  const green = Math.floor(255 * score);
  return `rgb(${red}, ${green}, 0)`;
};

// When a data field has pending update, it has Popover which contains all possible updates.
// Each pending update consists of updated value, updated time and data source.
// Operator can pick up a value or discard the change.
const PendingUpdatesHighlight: React.FC<PendingUpdatesHighlightProps> = ({
  children,
  form,
  fullNamePath,
}) => {
  const { accountType } = useCurrentUser();
  if (!form.getFieldValue('pendingUpdates')) {
    return <>{children}</>;
  }
  const existingValue = cloneDeep(form.getFieldValue(fullNamePath));
  const updateValues = cloneDeep(form.getFieldValue('pendingUpdates'));
  const items: any[] = [];
  const amsValues: string[] = [];
  const externalClientValues: string[] = [];
  const shipdocsExtractionValues: string[] = [];
  const pendingUpdateValue = findPendingUpdateForPath(
    updateValues,
    fullNamePath,
  );
  if (pendingUpdateValue) {
    const { value, dataSource, occurrenceTime, confidenceScore } =
      pendingUpdateValue;
    items.push(
      <div>
        <StyledSpan>{value}</StyledSpan>
        <StyledSpan>{occurrenceTime.format('MM/DD/YYYY, HH:mm:ss')}</StyledSpan>
        <StyledSpan>{dataSource}</StyledSpan>
        <StyledSpan style={{ color: getColorFromScore(confidenceScore) }}>
          {confidenceScore}
        </StyledSpan>
      </div>,
    );
    if (dataSource === DataSourceType.AMS_QUERY) {
      amsValues.push(value);
    }
    if (dataSource === DataSourceType.EXTERNAL_CLIENT) {
      externalClientValues.push(value);
    }
    if (dataSource === DataSourceType.SHIP_DOCS_EXTRACTION) {
      shipdocsExtractionValues.push(value);
    }
  }

  const versionPendingUpdate = findCorrespondingVersionPendingUpdateForId(
    updateValues,
    fullNamePath,
    form,
  );

  if (items.length === 0) {
    return <>{children}</>;
  }

  const handleReplace = (updatedValue: string) => {
    form.setFields([
      {
        name: fullNamePath,
        value: fullNamePath.toString().includes('Date')
          ? moment(updatedValue)
          : updatedValue,
      },
    ]);

    if (versionPendingUpdate) {
      form.setFields([
        {
          name: versionPendingUpdate.path,
          value: versionPendingUpdate.pendingUpdate.value,
        },
      ]);
    }
  };

  const handleDiscard = () => {
    form.setFields([
      {
        name: fullNamePath,
        value: existingValue,
      },
    ]);
    if (versionPendingUpdate) {
      form.setFields([
        {
          name: versionPendingUpdate.path,
          value: versionPendingUpdate.existingValue,
        },
      ]);
    }
  };

  return accountType === AccountType.OPERATOR ? (
    <Popover
      placement="top"
      title="Pending Updates"
      content={
        <>
          <div>{items}</div>
          <StyledButton
            $inline={amsValues.length > 0}
            onClick={() => handleReplace(amsValues[0])}
          >
            Ams Query
          </StyledButton>
          <StyledButton
            $inline={externalClientValues.length > 0}
            onClick={() => handleReplace(externalClientValues[0])}
          >
            External Client
          </StyledButton>
          <StyledButton
            $inline={shipdocsExtractionValues.length > 0}
            onClick={() => handleReplace(shipdocsExtractionValues[0])}
          >
            ShipDocs Extraction
          </StyledButton>
          <StyledButton $inline={true} onClick={handleDiscard}>
            Discard
          </StyledButton>
        </>
      }
      trigger="click"
    >
      <StyledDiv $highlight={items.length > 0}>{children}</StyledDiv>
    </Popover>
  ) : (
    <StyledDiv> {children} </StyledDiv>
  );
};

export default PendingUpdatesHighlight;

import { AppRecordSubmitButton } from 'routes';

import { message } from 'antd';
import { formatRecordName } from '@xbcb/js-utils';
import {
  AccountType,
  RecordType,
  RecordStatus,
  ObjectType,
} from '@xbcb/shared-types';
import { AppRecordOnConfirmPayload } from '../../types';
import { setModal } from '@xbcb/ui-utils';
import { ModalKey } from 'types';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { executeMutation } from 'libs/executeMutation';
import { WorkOrderStatus } from '@xbcb/work-order-types';
import { cancelOneMutation } from '@xbcb/shared-queries';
import { pascalCase } from 'change-case';

export const cancelWorkOrderButton = (
  recordType: RecordType,
): AppRecordSubmitButton => {
  const displayName = formatRecordName({
    recordType,
    accountType: AccountType.OPERATOR,
  });
  const name = `Cancel ${displayName}`;
  const workOrderButton: AppRecordSubmitButton = {
    key: `cancel${pascalCase(recordType)}`,
    text: name,
    show: ({ existingRecord, currentUser }) => {
      return (
        ![
          RecordStatus.CANCELED,
          WorkOrderStatus.COMPLETED,
          WorkOrderStatus.REPLACED,
        ].includes(existingRecord.status) &&
        !currentUser.loading &&
        checkAccess(currentUser, recordType, UserPermissions.UPDATE)
      );
    },
    skipValidation: () => true,
    skipUpdateRecord: () => true,
    onConfirm: ({ dispatch, isValid }) =>
      new Promise<AppRecordOnConfirmPayload>((resolve, reject) => {
        if (isValid) {
          dispatch(
            setModal({
              key: ModalKey.CANCEL_WORK_ORDER,
              props: {
                visible: true,
                sendConfirmationDecision: resolve,
              },
            }),
          );
        } else {
          return resolve({ canceled: true });
        }
      }),
    onSubmit: async ({ updatedRecord, confirmationData }) => {
      const { cancelEntryCbpReference, cancellationReason } =
        confirmationData || {};
      if (!cancellationReason) {
        message.error(`Please fill out all the fields`);
        return;
      }

      const cancelWorkOrderMutation = cancelOneMutation({
        recordName: ObjectType.WORK_ORDER,
      });
      await executeMutation({
        mutation: cancelWorkOrderMutation,
        variables: {
          id: updatedRecord.id,
          version: updatedRecord.version,
          cancellationReason: {
            text: cancellationReason,
            replacementReferenceNumber: cancelEntryCbpReference,
          },
        },
        successMessage: `${displayName} canceled`,
      });
    },
  };
  return workOrderButton;
};

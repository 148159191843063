import { Layout } from 'antd';
import styled from 'styled-components';

// passed in collapsed prop to change margin accordingly
export const StyledLayout = styled(Layout)<{ collapsed?: boolean }>`
  flex: 1 1 auto;
  transition: margin-right 0.3s ease;
  margin-right: ${({ collapsed }) => (collapsed ? '0px' : '155px')};
`;

export const StyledMenuButton = styled.div`
  cursor: pointer;
  font-size: 12px;
  color: white;
  background: #643d52;
  border-radius: 3px;
  z-index: 1;
  transition: background-color 0.5s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  margin: 10px;
  padding: ${({ theme }) => theme.spacing.space_2};
  &:hover {
    background: #805c72;
  }
`;

import { RemarksCode, toRemarksCodeOption } from '../types';

export const industryCodes: RemarksCode[] = [
  {
    code: 'A',
    description:
      'Heavy-duty highway engines (for use in motor vehicles with gross vehicle weight rating above 8500LBS',
  },
  {
    code: 'B',
    description: 'Locomotives or locomotive engines',
  },
  {
    code: 'C',
    description: 'Marine compression-ignition engines',
  },
  {
    code: 'D',
    description: 'Other non-road compression-ignition engines',
  },
  {
    code: 'E',
    description: 'Marine spark-ignition engine',
  },
  {
    code: 'F',
    description:
      'Recreational engines and vehicles, including snowmobiles, off-highway motorcycles, all-terrain vehicles, and off-road utility vehicles',
  },
  {
    code: 'G',
    description:
      'Other non-road spark-ignition engines at or below 19kW or below 30kW if total displacement is at or below 1000cc',
  },
  {
    code: 'H',
    description: 'Other non-road spark-ignition engines above 19kW',
  },
  {
    code: 'I',
    description: 'Stationary compression-ignition engines',
  },
  {
    code: 'J',
    description: 'Stationary Spark-ignition engines',
  },
];

export const industryCodeOptions = industryCodes.map(toRemarksCodeOption);

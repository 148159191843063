import React, { useState } from 'react';
import { Button, List, Modal, Tooltip } from 'antd';
import { capitalCase } from 'change-case';
import type { WorkOrderTask } from '@xbcb/api-gateway-client';
import {
  StyledDivFlexWrap,
  StyledDivRowElement,
  StyledOverflow,
} from './styles';
import {
  TERMINAL_WORK_ORDER_TASK_STATUS,
  TaskExecutionStatus,
  WorkOrderTaskStatus,
} from '@xbcb/work-order-types';
import { get } from 'lodash';
import { useBundle } from '@amzn/react-arb-tools';
import { safeGetMessage } from '@xbcb/ui-utils';

export interface WorkOrderTaskAutoExecutionDetailsProps {
  workOrderTask: WorkOrderTask;
}

const WorkOrderTaskAutoExecutionDetails: React.FC<
  WorkOrderTaskAutoExecutionDetailsProps
> = ({ workOrderTask }) => {
  const [taskAutoExecutionDetailsBundle] = useBundle(
    'components.WorkOrderTaskAutoExecutionDetails',
  );
  const [taskExecutionStatusBundle] = useBundle(
    'types.workOrder.enums.TaskExecutionStatus',
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { status, executionResult } = workOrderTask;
  const autoExecutionStatus = executionResult?.status;
  const autoExecutionErrors = executionResult?.errors || {};
  const showAutoExecutionErrors =
    !TERMINAL_WORK_ORDER_TASK_STATUS.includes(status as WorkOrderTaskStatus) &&
    autoExecutionErrors;

  return (
    <>
      {autoExecutionStatus && (
        <>
          <StyledDivFlexWrap>
            <Tooltip
              placement="top"
              title={safeGetMessage(
                taskAutoExecutionDetailsBundle,
                'auto_execution_details',
              )}
            >
              <StyledDivRowElement
                $marginRight="5px"
                data-testid="auto-execution-details"
              >
                {safeGetMessage(
                  taskAutoExecutionDetailsBundle,
                  'auto_execution_status',
                  {
                    status:
                      autoExecutionStatus ===
                      TaskExecutionStatus.AUTO_EXECUTION_FAILED
                        ? safeGetMessage(
                            taskAutoExecutionDetailsBundle,
                            'failed',
                          )
                        : safeGetMessage(
                            taskExecutionStatusBundle,
                            autoExecutionStatus.toLowerCase(),
                            undefined,
                            capitalCase(autoExecutionStatus),
                          ),
                  },
                )}
              </StyledDivRowElement>
            </Tooltip>
            <StyledDivRowElement>
              {showAutoExecutionErrors && (
                <Button
                  size="small"
                  danger
                  onClick={showModal}
                  data-testid="show-auto-execution-errors-button"
                >
                  {safeGetMessage(
                    taskAutoExecutionDetailsBundle,
                    'show_errors',
                  )}
                </Button>
              )}
            </StyledDivRowElement>
          </StyledDivFlexWrap>

          <Modal
            title={safeGetMessage(
              taskAutoExecutionDetailsBundle,
              'auto_execution_errors',
            )}
            open={isModalOpen}
            footer={null}
            closable
            maskClosable
            onCancel={handleCancel}
            data-testid="auto-execution-errors-modal"
          >
            <StyledOverflow>
              <List
                dataSource={Object.keys(autoExecutionErrors)}
                renderItem={(item) => {
                  return (
                    <List.Item>
                      {
                        <ul>
                          {(get(autoExecutionErrors, item) || []).map(
                            (error: string) => (
                              <li key={error}>{error}</li>
                            ),
                          )}
                        </ul>
                      }
                    </List.Item>
                  );
                }}
              ></List>
            </StyledOverflow>
          </Modal>
        </>
      )}
    </>
  );
};

export default WorkOrderTaskAutoExecutionDetails;

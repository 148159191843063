import { createReducer } from '@reduxjs/toolkit';
import { ActiveBrokerState } from 'types';
import { setActiveBroker } from '../actions';

export const activeBrokerLocalStorageKey = 'ACTIVE_BROKER_ID';

const defaultState: ActiveBrokerState = {
  currentActiveBroker: localStorage.getItem(activeBrokerLocalStorageKey) || '',
};

const activeBrokerReducer = createReducer(defaultState, (builder) =>
  builder.addCase(setActiveBroker, (state, { payload }) => {
    state.currentActiveBroker = payload;
    localStorage.setItem(activeBrokerLocalStorageKey, payload ?? '');
    return state;
  }),
);

export default activeBrokerReducer;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Modal, message, Radio, Space, Button } from 'antd';
import { prepareInitialValues, useModal } from '@xbcb/ui-utils';
import { ModalKey } from 'types';
import {
  ResolutionReasonCode,
  WorkOrderTaskStatus,
  WorkOrderTaskType,
} from '@xbcb/work-order-types';
import { codeToText } from '@xbcb/js-utils';
import { gql, useMutation } from '@apollo/client';
import AsteriskSymbol from 'components/AsteriskSymbol';
import { FormItem } from '@xbcb/form-item-components';
import { createDataCyValue, DataCyPrefix } from '@xbcb/ui-types';
import { StyledDiv, StyledDivForRadioButton } from './styles';
import type {
  WorkOrderTask,
  TaskResolutionDetailsInput,
  WorkOrder,
  ResolutionReasonCode as ApigResolutionReasonCode,
  OperatorUser,
} from '@xbcb/api-gateway-client';
import { RecordType } from '@xbcb/shared-types';
import moment from 'moment';
import { fetchLatestRecordAndExecuteMutation } from 'libs/fetchLatestRecordAndExecuteMutation';
import {
  updateWorkOrderTaskStatus,
  getWorkOrderQuery,
} from 'libs/sharedQueries';

const resolveWorkOrderTaskMutation = gql`
  mutation ResolveWorkOrderTaskEscalation(
    $input: TaskResolutionDetailsInput!
    $taskId: ID!
    $version: Int!
  ) {
    resolveWorkOrderTaskEscalation(
      input: $input
      taskId: $taskId
      version: $version
    ) {
      workOrderTask {
        id
      }
    }
  }
`;

interface ResolveWorkOrderTaskEscalationModalProps {
  currentUser: OperatorUser;
  workOrderTaskType: WorkOrderTaskType;
  workOrderTasks: WorkOrderTask[];
  record: WorkOrder;
}

const ResolveWorkOrderTaskEscalationModal: React.FC<
  ResolveWorkOrderTaskEscalationModalProps
> = ({ record, workOrderTaskType, workOrderTasks, currentUser }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const initialValues = prepareInitialValues({
    recordType: RecordType.WORK_ORDER_TASK,
  });
  const {
    closeModal,
    visible,
  }: {
    closeModal: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    visible?: boolean;
  } = useModal(ModalKey.RESOLUTION_WORK_ORDER_TASK);

  let task;
  if (workOrderTaskType) {
    task = workOrderTasks.find(
      ({ definition }: WorkOrderTask) =>
        definition.workOrderTaskType === workOrderTaskType,
    );
  }
  if (!task && visible) {
    message.error('There is no task found to resolve');
  }
  const taskId = task?.id;
  const version = task?.version;

  const [resolve] = useMutation(resolveWorkOrderTaskMutation, {
    refetchQueries: () => [
      {
        query: getWorkOrderQuery,
        variables: { id: record.id, version: record.version },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    try {
      await form.validateFields();
    } catch (e) {
      message.error('Please fill all required fields');
      return;
    }
    try {
      setIsLoading(true);
      const input: TaskResolutionDetailsInput = {
        details: form.getFieldValue(['detail']) as string,
        resolutionReasons: [
          form.getFieldValue(['resolutionCodes']) as ApigResolutionReasonCode,
        ],
        resolutionTime: moment.utc(Date.now()).toISOString(),
        resolvedBy: { id: currentUser.id },
      };
      await resolve({
        variables: {
          taskId,
          input,
          version,
        },
      });
      setIsLoading(false);
      closeModal(e);

      // This mutation is added so that the UI re-renders with the updated task status.
      await fetchLatestRecordAndExecuteMutation({
        id: taskId as string,
        fields: 'status subStatus',
        recordType: RecordType.WORK_ORDER_TASK,
        successMessage: 'Work order task resolved successfully.',
        mutation: updateWorkOrderTaskStatus,
        constructVariablesWithIdVersion: (id, version) => ({
          id,
          version,
          input: {
            status: WorkOrderTaskStatus.ASSIGNED,
            subStatus: null,
          },
        }),
      });
      // Redirect to assignment workspace.
      history.push('/assignment-workspace');
    } catch (e) {
      setIsLoading(false);
    }
  };

  const resolutionCodes = Object.keys(ResolutionReasonCode);
  const resolutionCodeSelections = resolutionCodes.map((code) => (
    <StyledDivForRadioButton key={code}>
      <Radio
        value={code}
        data-cy={createDataCyValue(
          DataCyPrefix.TASK_ESCALATION_CATEGORY,
          code.toString(),
        )}
      >
        {codeToText(code)}
      </Radio>
    </StyledDivForRadioButton>
  ));
  return (
    <Modal
      key={record.id}
      okText={`Resolve`}
      open={visible}
      width={'580px'}
      title={`Resolve Task Escalation`}
      onCancel={closeModal}
      footer={[
        <Button
          onClick={closeModal}
          size="large"
          key="cancelResolveWorkOrderTask"
        >
          Cancel
        </Button>,
        <Button
          onClick={handleSubmit}
          type="primary"
          loading={isLoading}
          size="large"
          htmlType="submit"
          key="resolveWorkOrderTask"
        >
          Resolve
        </Button>,
      ]}
      className={'task-escalation-resolve-modal'}
    >
      <Form form={form} initialValues={initialValues}>
        <StyledDiv>
          <AsteriskSymbol />
          &nbsp;Resolution code
        </StyledDiv>
        <StyledDiv>
          <FormItem
            name={['resolutionCodes']}
            rules={[
              { required: true, message: 'Please select one resolution code' },
            ]}
          >
            <Radio.Group>
              <Space direction="vertical">{resolutionCodeSelections}</Space>
            </Radio.Group>
          </FormItem>
        </StyledDiv>
        <StyledDiv>
          <AsteriskSymbol />
          &nbsp;Detail
        </StyledDiv>
        <FormItem
          name={['detail']}
          rules={[{ required: true, message: 'Detail is required' }]}
        >
          <Input.TextArea
            maxLength={10000}
            data-cy={createDataCyValue(DataCyPrefix.RESOLUTION_DETAILS)}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ResolveWorkOrderTaskEscalationModal;

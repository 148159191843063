import React, { memo } from 'react';
import { Form } from 'antd';
import { useTheme } from 'styled-components';
import { CountrySelect } from '@xbcb/form-item-components';
import Lpco from 'components/Lpco';
import Quantity from 'components/Quantity';
import { getCodes } from '@xbcb/ui-utils';
import { StyledLicensesDiv } from './styles';
import { LpcoFields } from 'components/Lpco/lpcoFields';
import { PgaFormBaseProps } from 'types';

const TtbForm = ({
  fullNamePath,
  localNamePath,
  readOnly,
  required,
  disabled,
}: PgaFormBaseProps): React.ReactElement => {
  const form = Form.useFormInstance();
  const theme = useTheme();
  const exemptions = () => getCodes().CBP.CATAIR.PGA.ttbExemptionCodes;
  const processingCode = Form.useWatch(
    [...fullNamePath, 'processingCode'],
    form,
  );
  const programCode = Form.useWatch([...fullNamePath, 'programCode'], form);
  return (
    <div>
      {/* PG14 */}
      {/* TODO find out format for permit number */}
      {/* Permit Number */}
      <StyledLicensesDiv className="licenses">
        {/* Permit number not required for cigarette tubes and papers */}
        <>
          {!['T51', 'T52', 'T54', 'T55'].includes(processingCode) && (
            <Lpco
              form={form}
              fullNamePath={[
                ...fullNamePath,
                LpcoFields.TTB_IMPORTER_PERMIT_NUMBER,
              ]}
              localNamePath={[
                ...localNamePath,
                LpcoFields.TTB_IMPORTER_PERMIT_NUMBER,
              ]}
              readOnly={readOnly}
              disabled={disabled}
              required={required}
              type="TZ3"
              regex={[
                /[A-Z]/,
                /[A-Z]/,
                '-',
                /[A-Z]/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              exemptions={exemptions()}
            />
          )}
        </>
        {/* Label Approval Number */}
        {/* TODO If you make this exempt then the styling gets messed up for some reason*/}

        <>
          {['BER', 'DSP', 'WIN'].includes(programCode) && (
            <Lpco
              form={form}
              fullNamePath={[...fullNamePath, 'certificateOfLabelApproval']}
              localNamePath={[...localNamePath, 'certificateOfLabelApproval']}
              readOnly={readOnly}
              disabled={disabled}
              type="TZ1"
              multiple={true}
              regex={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              exemptions={exemptions()}
              required={required}
            />
          )}
        </>
      </StyledLicensesDiv>
      <div className="form-inline">
        {/* IRC registry number */}
        <Lpco
          form={form}
          fullNamePath={[...fullNamePath, LpcoFields.IRC_REGISTRY_NUMBER]}
          localNamePath={[...localNamePath, LpcoFields.IRC_REGISTRY_NUMBER]}
          readOnly={readOnly}
          disabled={disabled}
          type="TZ5"
          regex={[
            /[A-Z]/,
            /[A-Z]/,
            /[A-Z]/,
            '-',
            /[A-Z]/,
            /[A-Z]/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          exemptions={exemptions()}
        />

        <>
          {['DSP', 'WIN'].includes(programCode) && (
            <CountrySelect
              $itemSize={theme.size.short}
              localNamePath={[
                ...localNamePath,
                'foreignCertificate',
                'countryCode',
              ]}
              label={`${
                programCode === 'WIN' ? 'Wine' : 'Distilled spirits'
              } certificate issuer location`}
              readOnly={readOnly}
              disabled={disabled}
              required={true}
            />
          )}
        </>

        <>
          {['T51', 'T52', 'T54', 'T55'].includes(processingCode) && (
            <Quantity
              field={[...localNamePath, 'paperOrTubeQuantity']}
              label={`Number of ${
                ['T51', 'T52'].includes(processingCode) ? 'tubes' : 'papers'
              }`}
              readOnly={readOnly}
              disabled={disabled}
              $itemSize={theme.size.tiny_short}
              required={required}
              max={9999999999}
            />
          )}
        </>
      </div>
    </div>
  );
};

export default memo(TtbForm);

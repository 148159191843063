import React from 'react';
import { Form, Input } from 'antd';
import { shouldUpdate, getCodes } from '@xbcb/ui-utils';
import { FormItem, Option } from '@xbcb/form-item-components';
import { ModalKey } from 'types';
import { FormInstance } from 'antd/lib/form';
import {
  WorkOrderMilestoneName,
  nonCancellableWorkOrderMilestones as shipmentNonCancellableMilestones,
} from '@xbcb/work-order-types';
import { useCurrentUser } from 'libs/hooks';
import type { UsConsumptionEntry, WorkOrder } from '@xbcb/api-gateway-client';
import { formatRecordName } from '@xbcb/js-utils';
import { RecordType, AccountType } from '@xbcb/shared-types';
import { getRecordType } from '@xbcb/core';
import {
  cbpReasonCodeToCbmsEnum,
  referenceNames,
} from 'libs/deleteUsConsumptionEntryRelease';
import CancelModalBase from 'components/CancelModalBase';
import { DataCySuffix } from '@xbcb/ui-types';
import CancelReasonsDropdown from 'components/CancelReasonsDropdown';

interface CancelWorkOrderModalProps {
  record: WorkOrder;
}

const CancelWorkOrderModal: React.FC<CancelWorkOrderModalProps> = ({
  record,
}) => {
  const { id, milestones } = record;
  const reasonNamePath = ['reason'];
  const recordType = getRecordType(id) as RecordType;
  const displayName = formatRecordName({
    recordType,
    accountType: AccountType.OPERATOR,
  });
  // if usIsf is filed, we allow to cancel shipment but no usIsf itself
  const nonCancellableWorkOrderMilestonesOccured = milestones?.some(
    (milestone) =>
      [
        ...shipmentNonCancellableMilestones,
        WorkOrderMilestoneName.US_ISF_FILED,
      ].includes(milestone.name as WorkOrderMilestoneName),
  );

  // If payment is authorized, we do not allow to cancel
  const entryPaymentAuthorized =
    recordType === RecordType.US_CONSUMPTION_ENTRY &&
    Boolean((record as UsConsumptionEntry)?.dailyStatement?.authorizationTime);
  // If entry is filed, we need to delete summary and release from CBP
  const entryFiled =
    recordType === RecordType.US_CONSUMPTION_ENTRY &&
    nonCancellableWorkOrderMilestonesOccured;
  const allowCancelEntryFromCbp = !entryPaymentAuthorized && entryFiled;

  const cbpDeleteReasonCodes = getCodes()?.CBP?.CATAIR?.release?.deleteReason;
  // According to https://app.asana.com/0/1172440442930369/1201466170315635, we only want to display three reasons
  // for external customers, operators should be able to see all reason codes
  const externalCustomerViewDeleteReasonCodes = ['02', '03', '04'];
  const { accountType } = useCurrentUser();
  const deleteReasonCodes =
    accountType !== AccountType.OPERATOR
      ? externalCustomerViewDeleteReasonCodes
      : Object.keys(cbpDeleteReasonCodes);
  const deleteReasonOptions = deleteReasonCodes.reduce(
    (acc: React.ReactElement[], cbpReasonCode, index) => {
      acc.push(
        <Option
          key={index}
          value={
            cbpReasonCodeToCbmsEnum[
              cbpReasonCode as keyof typeof cbpReasonCodeToCbmsEnum
            ]
          }
        >
          {cbpDeleteReasonCodes[cbpReasonCode]}
        </Option>,
      );
      return acc;
    },
    [],
  );

  const Content = ({ form }: { form: FormInstance }) => (
    <>
      {!nonCancellableWorkOrderMilestonesOccured ? (
        <CancelReasonsDropdown
          reasonNamePath={reasonNamePath}
          recordType={recordType}
          accountType={accountType}
          dataCySuffix={DataCySuffix.WORK_ORDER}
          instructionText={`Are you sure you want to cancel this ${displayName}? All associated tasks will be cancelled.`}
        />
      ) : allowCancelEntryFromCbp ? (
        <CancelReasonsDropdown
          reasonNamePath={reasonNamePath}
          recordType={recordType}
          accountType={accountType}
          dataCySuffix={DataCySuffix.WORK_ORDER}
          instructionText={`This entry has been filed, please identify a reason for cancelling
        the entry and provide the required information. You will be charged
        for the entry.`}
          customCancelReasonOptions={deleteReasonOptions}
          extraContent={
            <Form.Item shouldUpdate={shouldUpdate([reasonNamePath])} noStyle>
              {() => {
                return (
                  Object.keys(referenceNames).includes(
                    form.getFieldValue(reasonNamePath),
                  ) && (
                    <FormItem
                      label={
                        referenceNames[
                          form.getFieldValue(
                            reasonNamePath,
                          ) as keyof typeof referenceNames
                        ]
                      }
                      name={['reference']}
                      rules={[{ required: true, message: ' ' }]}
                    >
                      <Input />
                    </FormItem>
                  )
                );
              }}
            </Form.Item>
          }
        />
      ) : (
        <>
          <p>
            {entryPaymentAuthorized
              ? `We cannot cancel the ${displayName} because payment has been authorized`
              : `We cannot cancel the ${displayName} because it is already filed.`}
          </p>
        </>
      )}
    </>
  );
  return (
    <CancelModalBase
      modalKey={ModalKey.CANCEL_WORK_ORDER}
      modalTitle={`Cancel ${displayName}`}
      className={'cancel-work-order-modal'}
      ModalContent={Content}
      // we want to allow entry cancellation if external entry is filed but payment is not authorized
      allowCancel={
        !nonCancellableWorkOrderMilestonesOccured || allowCancelEntryFromCbp
      }
      constructConfirmationData={({ form }) => {
        const { reason, reference } = form.getFieldsValue();
        return {
          cancellationReason: reason,
          cancelEntryCbpReference: reference,
        };
      }}
    />
  );
};

export default CancelWorkOrderModal;

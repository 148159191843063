import React from 'react';
import { Button } from 'antd';
import { DataCyPrefix } from '@xbcb/ui-types';
import { openModal } from 'actions';
import { useDispatch } from 'react-redux';
import { ModalKey } from 'types';

export interface OpenModalButtonProps {
  modalKey: ModalKey;
  modal: React.FC;
  shape?: 'circle' | 'round';
}

const OpenModalButton: React.FC<OpenModalButtonProps> = ({
  children,
  modalKey,
  modal: Modal,
  shape = 'round',
}) => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(openModal({ key: modalKey }));
  return (
    <>
      <Modal />
      <Button
        onClick={onClick}
        shape={shape}
        data-cy={DataCyPrefix.OPEN_MODAL_BUTTON}
      >
        {children}
      </Button>
    </>
  );
};

export default OpenModalButton;

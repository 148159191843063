import { message } from 'antd';
import { AnyObject } from '@xbcb/shared-types';
import { getHtsDataFromHierarchy } from 'libs/htsHelpers';
import moment from 'moment';

export const validateEffectiveHTS = (
  htsNumber: string,
  htsEffectiveDate = moment(),
  hierarchyData: AnyObject = {},
): void => {
  const htsObj = getHtsDataFromHierarchy(htsNumber, hierarchyData);
  if (!htsObj?.id) {
    throw new Error('Invalid HTS number');
  }
  const { startDate, endDate } = htsObj;

  const isValidHts = isHtsValidForEffectiveDate(htsObj, htsEffectiveDate);
  if (!isValidHts) {
    const errorMessage = endDate
      ? `Error: ${htsNumber} invalid for effective date ${htsEffectiveDate} for start date ${startDate}, end date ${endDate}`
      : `Error: ${htsNumber} invalid for effective date ${htsEffectiveDate} for start date ${startDate}`;
    message.error(errorMessage, 5.0);
    throw new Error('HTS number not valid for effective date');
  }
};

export const isHtsValidForEffectiveDate = (
  htsObj: AnyObject,
  htsEffectiveDate = moment(),
): boolean => {
  const { startDate, endDate } = htsObj;
  if (startDate && endDate) {
    return htsEffectiveDate.isBetween(startDate, endDate, 'days', '[]');
  } else if (startDate) {
    return htsEffectiveDate.isSameOrAfter(startDate);
  }
  return false;
};

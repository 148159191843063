import { AnyObject } from '@xbcb/shared-types';
import { transformWorkOrderBase } from './base';

export const transformDeliveryOrder = {
  // We need to do this as <Select> has issues for mode === multiple.
  // when mode === multiple the value is an array of strings but we need an array of objects.
  toForm: ({ existingRecord }: { existingRecord: AnyObject }): AnyObject => {
    const newRecord = transformWorkOrderBase.toForm({ existingRecord });
    if (newRecord?.email?.ccParties) {
      newRecord.email.ccParties = newRecord.email.ccParties.map(
        ({ id }: AnyObject) => id,
      );
    }

    return newRecord;
  },
  toSchema: ({
    input,
    existingRecord,
  }: {
    input: AnyObject;
    existingRecord: AnyObject;
  }): AnyObject => {
    const cleanedOutput = transformWorkOrderBase.toSchema({
      input,
      existingRecord,
    });
    // Only take id, version for these fields
    const { trucker, destination, email, billTo } = cleanedOutput;

    if (billTo) {
      cleanedOutput.billTo = { id: billTo.id };
    }

    if (trucker?.trucker?.id) {
      cleanedOutput.trucker.trucker = {
        id: trucker?.trucker?.id,
      };
    }
    if (!trucker.dispatchPhone?.number) {
      delete cleanedOutput.trucker.dispatchPhone; // allows empty phone number on UI
    }
    if (destination?.location?.id) {
      cleanedOutput.destination.location = {
        id: destination?.location?.id,
      };
    }

    if (!destination.receivingPhone?.number) {
      delete cleanedOutput.destination.receivingPhone; // allows empty phone number on UI
    }

    if (Object.keys(cleanedOutput.destination).length === 0) {
      delete cleanedOutput.destination;
    }

    // We need to do this as <Select> has issues for mode === multiple
    if (email?.ccParties) {
      const partyIds = email.ccParties;
      cleanedOutput.email.ccParties = partyIds.map((id: string) => {
        return {
          id,
        };
      });
    }
    return cleanedOutput;
  },
};

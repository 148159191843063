import React from 'react';
import { Form } from 'antd';
import { MinusButton } from '@xbcb/button-components';
import { Select, FormItem, Option } from '@xbcb/form-item-components';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { FormInstance } from 'antd/lib/form';
import { StyledDiv, StyledFlexDiv } from './styles';
import { selectFilter, getCodes, shouldUpdate } from '@xbcb/ui-utils';
import { clearFields } from 'libs/clearFields';

interface AphCommodityCodesProps {
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  required?: boolean;
  index: number;
  onRemove?: any;
  allowDelete?: boolean;
}

const AphCommodityCodes = ({
  form,
  readOnly,
  disabled,
  fullNamePath,
  localNamePath,
  index,
  onRemove,
  required,
  allowDelete,
}: AphCommodityCodesProps) => {
  const firstRow = index === 0;

  const { categoryTypeCode } = form.getFieldValue(fullNamePath.slice(0, -2));

  const codes = getCodes();
  const { commodity } = codes.CBP.CATAIR.PGA;

  const commodityCodes = commodity?.[categoryTypeCode] || {};
  const commodityCodesOptions = Object.entries(commodityCodes).map(
    ([key, value]: [string, any]) => (
      <Option key={key} value={key}>
        {key} - {value.name}
      </Option>
    ),
  );

  return (
    <StyledDiv key={index}>
      <StyledFlexDiv>
        {/* PG10 */}
        <FormItem
          $itemSize={CssSize.SHORT}
          label={firstRow ? 'Commodity Qualifier Code' : undefined}
          name={[...localNamePath, 'qualifier']}
          rules={[{ required }]}
          $readOnly={readOnly}
        >
          <Select
            disabled={disabled}
            showSearch
            allowClear
            filterOption={selectFilter}
            notFoundContent="None Found"
            dropdownMatchSelectWidth={false}
            onChange={clearFields({
              form,
              fullNamePath,
              fields: ['code'],
            })}
          >
            {commodityCodesOptions}
          </Select>
        </FormItem>
        <Form.Item
          shouldUpdate={shouldUpdate([[...fullNamePath, 'qualifier']])}
          noStyle
        >
          {() => {
            const commodityCode = form.getFieldValue([
              ...fullNamePath,
              'qualifier',
            ]);
            const characteristicCodes =
              commodityCodes[commodityCode]?.characteristicCodes || {};
            const characteristicCodesOptions = Object.entries(
              characteristicCodes,
            ).map(([key, value]: [string, any]) => (
              <Option key={key} value={key}>
                {key} - {value.name}
              </Option>
            ));

            return (
              <FormItem
                $itemSize={CssSize.SHORT}
                label={firstRow ? 'Commodity Characteristic Code' : undefined}
                name={[...localNamePath, 'code']}
                rules={[{ required }]}
                $readOnly={readOnly}
              >
                <Select
                  disabled={disabled}
                  showSearch
                  allowClear
                  filterOption={selectFilter}
                  notFoundContent="None Found"
                  dropdownMatchSelectWidth={false}
                >
                  {characteristicCodesOptions}
                </Select>
              </FormItem>
            );
          }}
        </Form.Item>
        {allowDelete && !readOnly && (
          <MinusButton
            label="commodity code"
            firstRow={firstRow}
            disabled={disabled}
            onRemove={onRemove}
          />
        )}
      </StyledFlexDiv>
    </StyledDiv>
  );
};

export default AphCommodityCodes;

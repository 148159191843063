import { createReducer } from '@reduxjs/toolkit';
import { UserStatus } from '@xbcb/client-types';
import { setUserStatus } from '../actions';
import { UserStatusState } from 'types';

const defaultState: UserStatusState = {
  currentStatus: UserStatus.ACTIVE,
};

const userStatusReducer = createReducer(defaultState, (builder) =>
  builder.addCase(setUserStatus, (state, { payload }) => {
    state.currentStatus = payload;
    return state;
  }),
);

export default userStatusReducer;

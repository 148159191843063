export default (url, fileName) => {
  const a = document.createElement('a');
  a.style = 'display: none';
  a.href = url;
  if (fileName) a.setAttribute('download', fileName);
  a.rel = 'noopener noreferrer';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

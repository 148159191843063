import styled from 'styled-components';
import { Button, Modal } from 'antd';

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const StyledButton = styled(Button)`
  height: auto;
  padding: 0;
  box-shadow: none;
  margin-left: var(--space-4);
  border: none;
`;

export const StyledModal = styled(Modal)`
  padding: 48px;
  width: 400px;
  font-size: 16px;
  label {
    font-size: 18px;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    background: var(--layout-body-background);
    .ant-page-header {
      max-width: unset;
    }
  }
`;

export const StyledSpan = styled.span`
  text-align: right;
`;

export const StyledInlineH4 = styled.h4`
  display: inline-block;
  margin-right: var(--space-4);
`;

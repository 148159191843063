import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.space_4};
  switch-user-icon. {
    background-color: transparent;
    margin-right: ${({ theme }) => theme.spacing.space_4};
  }
  button.ant-btn. {
    border: none;
  }
`;

import {
  UsConsumptionEntry,
  UsConsumptionEntryCommercialInvoice,
  UsConsumptionEntryCensusWarning,
} from '@xbcb/api-gateway-client';
import {
  CENSUS_WARNING_MAP,
  CensusWarningCode,
  WarningStatus,
} from '@xbcb/work-order-types';

export const getWarningMessages = (
  warnings: UsConsumptionEntryCensusWarning[],
  format: (warning: UsConsumptionEntryCensusWarning) => string,
): string[] =>
  warnings
    .filter((warning) => warning.warningStatus === WarningStatus.ACTIVE)
    .map(format);

export const formatCensusWarning = (
  warning: UsConsumptionEntryCensusWarning,
  line?: number,
  invoice?: number,
): string => {
  const { warningCode } = warning;
  if (!warningCode) {
    // This case should never happen; this is a sanity/TS check
    return 'Unknown Census Warning';
  }
  const warningKey = CensusWarningCode[warningCode];
  const { description } = CENSUS_WARNING_MAP[warningKey];

  return `${invoice ? `Invoice ${invoice}, ` : ''}${
    line ? `Line ${line}: ` : ''
  }${description}`;
};

export const getCensusWarningsFromEntry = (
  entry: UsConsumptionEntry,
): string[] => {
  const invoices = entry.invoices ?? [];
  return invoices.map(getCensusWarningsFromInvoice).flat();
};

export const getCensusWarningsFromInvoice = (
  invoice: UsConsumptionEntryCommercialInvoice,
  invoiceIndex: number,
): string[] => {
  const products = invoice.products || [];
  const warnings: string[] = [];
  products.forEach((product, productIndex) => {
    const lines = product.lines ?? [];
    const productWarnings = lines.map((line, lineIndex) => {
      const censusWarnings = line.censusWarnings ?? [];
      return censusWarnings
        .filter((warning) => warning.warningStatus === WarningStatus.ACTIVE)
        .map((warning) =>
          formatCensusWarning(
            warning,
            productIndex + lineIndex + 1,
            invoiceIndex + 1,
          ),
        );
    });
    warnings.push(...productWarnings.flat());
  });
  return warnings;
};

import type { WorkOrderTask } from '@xbcb/api-gateway-client';
import {
  WorkOrderTaskStatus,
  WorkOrderTaskSubStatus,
} from '@xbcb/work-order-types';

export enum WorkOrderTaskEvaluatedState {
  READY_TO_START = 'READY_TO_START',
  STARTED = 'STARTED',
  CANNOT_START = 'CANNOT_START',
}

// The evaluated task state will be used to determine if a task can be started/stopped
// or neither. It is evaluated by both task status and sub status.
export const evaluateWorkOrderTaskState = (task: WorkOrderTask | undefined) => {
  if (task?.status === WorkOrderTaskStatus.IN_PROGRESS) {
    return task.subStatus === WorkOrderTaskSubStatus.ACTIVELY_IN_PROGRESS
      ? WorkOrderTaskEvaluatedState.STARTED
      : task.subStatus === WorkOrderTaskSubStatus.PASSIVELY_IN_PROGRESS ||
        !task.subStatus
      ? WorkOrderTaskEvaluatedState.READY_TO_START
      : WorkOrderTaskEvaluatedState.CANNOT_START;
  }
  return task?.status === WorkOrderTaskStatus.ASSIGNED
    ? WorkOrderTaskEvaluatedState.READY_TO_START
    : WorkOrderTaskEvaluatedState.CANNOT_START;
};

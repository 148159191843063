import React from 'react';
import { FormItem, Select } from '@xbcb/form-item-components';
import { useTheme } from 'styled-components';
import { importCodeOptions } from './importCodes';
import { RemarksTypeCodeProps } from '../types';

export const ImportCode: React.FC<RemarksTypeCodeProps> = ({
  localNamePath,
  readOnly,
  disabled,
  regulatoryCategory,
  onChange,
  required,
}) => {
  const theme = useTheme();

  const options = importCodeOptions(regulatoryCategory);

  return (
    <FormItem
      $itemSize={theme.size.medium}
      label="Import Code"
      name={[...localNamePath, 'vneImportCode']}
      $readOnly={readOnly}
      rules={[{ required }]}
    >
      <Select
        aria-label={'Import Code Select'}
        disabled={disabled}
        onChange={onChange}
        options={options}
      />
    </FormItem>
  );
};

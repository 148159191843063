import { message } from 'antd';
import type { WorkOrderGroup } from '@xbcb/api-gateway-client';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { getRecordType } from '@xbcb/core';
import { formatRecordName } from '@xbcb/js-utils';
import { AccountType, RecordType } from '@xbcb/shared-types';
import { transformUsConsumptionEntryToUsPostSummaryCorrectionInput } from '@xbcb/work-order-utils/dist/transformUsConsumptionEntryToUsPostSummaryCorrectionInput';
import { AppRecordKebabMenuItem } from 'routes/types';
import { executeMutation } from 'libs/executeMutation';
import {
  usPostSummaryCorrectionFields,
  usPostSummaryCorrectionFragments,
} from 'libs/sharedQueries';
import { createOneMutation } from '@xbcb/shared-queries';
import { reportError } from '@xbcb/ui-utils';
import { checkKebabMenuItemForWorkOrderTask } from './checkKebabMenuItemForWorkOrderTask';
import { KebabMenuItemKey } from '@xbcb/ui-types';

const accountType = AccountType.OPERATOR;
const formattedPostSummaryCorrectionName = formatRecordName({
  recordType: RecordType.US_POST_SUMMARY_CORRECTION,
  accountType,
});

export const createUsPostSummaryCorrectionFromUsConsumptionEntry: AppRecordKebabMenuItem =
  {
    text: `Create ${formattedPostSummaryCorrectionName}`,
    key: KebabMenuItemKey.CREATE_US_POST_SUMMARY_CORRECTION,
    action: async ({ record }) => {
      try {
        const input =
          transformUsConsumptionEntryToUsPostSummaryCorrectionInput(record);
        const createUsPostSummaryCorrection = createOneMutation({
          recordName: RecordType.US_POST_SUMMARY_CORRECTION,
          fields: usPostSummaryCorrectionFields,
          fragments: usPostSummaryCorrectionFragments,
        });
        const { id, version } = record;
        await executeMutation({
          mutation: createUsPostSummaryCorrection,
          variables: { id, version, input },
          successMessage: `Successfully created ${formattedPostSummaryCorrectionName}.`,
        });
      } catch (e) {
        reportError(e);
        message.error(`The following error occurred: ${e}`);
        return;
      }
    },
    show: ({
      existingRecord,
      currentUser,
      workOrderTaskType,
      workOrderTaskEvaluatedState,
    }) => {
      const userCanCreate = checkAccess(
        currentUser,
        RecordType.US_POST_SUMMARY_CORRECTION,
        UserPermissions.CREATE,
      );
      if (!userCanCreate) return false;
      // Should find the group regardless of whether the kebab menu item is on a
      // WO page (i.e. `group`) or shipment page (i.e. `workOrderGroup`)
      const group = (existingRecord?.group ||
        existingRecord?.workOrderGroup) as WorkOrderGroup | undefined;
      // If a group isn't found something went wrong or the record is still
      // loading, return false to be safe
      if (!group) return false;
      // Only show the kebab menu item if the WOG does _not_ already have a PSC
      return (
        !group.workOrders.find(
          ({ id }) =>
            getRecordType(id) === RecordType.US_POST_SUMMARY_CORRECTION,
        ) &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
          KebabMenuItemKey.CREATE_US_POST_SUMMARY_CORRECTION,
        )
      );
    },
  };

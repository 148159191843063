import React from 'react';
import { AppRecordSecondaryHeaderContentProps } from 'routes';
import { getBadgeIcons } from 'components/AppRecordBadge';
import { getWorkOrderBadges } from 'libs/getRecordBadges';
import { StyledDiv } from './styles';

const CustomsEntrySecondaryHeader: React.FC<
  AppRecordSecondaryHeaderContentProps
> = ({ record }) => {
  const badgeIcons = getBadgeIcons(record, getWorkOrderBadges);

  return <StyledDiv>{badgeIcons}</StyledDiv>;
};

export default CustomsEntrySecondaryHeader;

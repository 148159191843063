import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledDiv = styled.div`
  background-color: white;
  padding: ${CssSpace.SPACE_3};
`;

export const StyledDescriptionDiv = styled.div`
  margin-left: calc(${CssSpace.SPACE_2} + ${CssSpace.SPACE_1});
`;

export const StyledWorkOrderTaskStepDiv = styled.div<{ isTop: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme, isTop }) => (isTop ? '0px' : theme.spacing.space_2)};
`;

import React from 'react';
import { RecordType, AccountType } from '@xbcb/shared-types';
import PartyForm from 'components/PartyForm';
import EINNumber from 'components/EINNumber';
import AccountDetails from 'components/AccountDetails';
import SCACSelect from 'components/SCACSelect';
import SupportsPersonalEffects from 'components/SupportsPersonalEffects';
import { useCurrentUser } from 'libs/hooks';
import { RecordProps } from 'routes';
import CustomerDetails from 'components/CustomerDetails';

const Forwarder: React.FC<RecordProps> = ({
  disabled,
  readOnly,
  form,
  record,
  hideDocs,
}) => {
  const { accountType } = useCurrentUser();
  return (
    <PartyForm
      recordType={RecordType.FORWARDER}
      disabled={disabled}
      readOnly={readOnly}
      form={form}
      addressRequired
      hideDocs={hideDocs}
      hideBusinessStructure={true}
      additionalCompanyInfo={
        <>
          <EINNumber
            form={form}
            disabled={disabled}
            readOnly={readOnly}
            localNamePath={['ein']}
            fullNamePath={['ein']}
          />
          <SCACSelect
            form={form}
            disabled={disabled}
            readOnly={readOnly}
            localNamePath={['scac']}
            fullNamePath={['scac']}
            $inline
          />
          <SupportsPersonalEffects
            form={form}
            disabled={disabled}
            readOnly={readOnly}
            localNamePath={['supportsPersonalEffects']}
            fullNamePath={['supportsPersonalEffects']}
          />
        </>
      }
    >
      <CustomerDetails
        record={record}
        form={form}
        disabled={disabled}
        readOnly={readOnly}
      />
      {record && accountType !== AccountType.SHIPPER && (
        <AccountDetails
          record={record}
          form={form}
          recordType={RecordType.FORWARDER}
        />
      )}
      {/* TODO: add ForwarderAccess back when schema implements access */}
      {/* <ForwarderAccess
        accountType={accountType}
        form={form}
        readOnly={readOnly}
        disabled={disabled}
        recordId={recordId}
      /> */}
    </PartyForm>
  );
};

export default Forwarder;

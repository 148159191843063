import { RecordType, ModeOfTransport, AnyObject } from '@xbcb/shared-types';
import { getOneQuery } from '@xbcb/shared-queries';
import { gql } from '@apollo/client';
import { WorkOrderType } from '@xbcb/work-order-types';
import { checkUserAccessToRequestWorkOrder } from 'libs/checkUserAccessToRequestWorkOrder';

export const SEARCH_SHIPMENTS_QUERY = gql`
  query searchShipments($input: SearchShipmentInput!) {
    searchShipments(input: $input) {
      results {
        id
        friendlyId
      }
    }
  }
`;

export const SEARCH_PENDING_US_IOR_BOND_REQS_QUERY = gql`
  query searchUsIorContinuousBondRequests(
    $input: SearchUsIorContinuousBondRequestInput!
  ) {
    searchUsIorContinuousBondRequests(input: $input) {
      results {
        id
      }
    }
  }
`;

const customerFields = `
defaultWorkOrdersConfig {
  us {
    modesOfTransport
    workOrderTypes
  }
}
billingDetails {
  subscriptionPlan
  customFees {type value{value currency}}
}`;

export const GET_SHIPPER_DETAILS_QUERY = getOneQuery({
  recordName: RecordType.SHIPPER,
  fields: customerFields,
});

export const GET_FORWARDER_DETAILS_QUERY = getOneQuery({
  recordName: RecordType.FORWARDER,
  fields: customerFields,
});

export const GET_IOR_DETAILS_QUERY = getOneQuery({
  recordName: RecordType.US_IOR,
  fields: `
  activations {
    status
    customsBroker {
      id
    }
  }
  activationStatus
  continuousBonds {
    present {
      terminationDate
    }
  }`,
});

export const GET_SHIPMENT_WORKORDERS_QUERY = getOneQuery({
  recordName: RecordType.SHIPMENT,
  fields: `
  workOrderGroup {
    workOrders{
      id
    }
  }`,
});

export enum DestinationCountry {
  US = 'US',
}

// Defining  defaultWorkOrdersConfig only until authority code is pushed.
export const customerDefaultWorkOrdersConfig = {
  us: [
    {
      modesOfTransport: [ModeOfTransport.OCEAN],
      workOrderTypes: [WorkOrderType.UsConsumptionEntry, WorkOrderType.UsIsf],
    },
    {
      modesOfTransport: [
        ModeOfTransport.AIR,
        ModeOfTransport.RAIL,
        ModeOfTransport.TRUCK,
      ],
      workOrderTypes: [WorkOrderType.UsConsumptionEntry],
    },
  ],
};

const usBaseAllowedWorkOrders = [
  WorkOrderType.UsType86Entry,
  WorkOrderType.UsConsumptionEntry,
  WorkOrderType.UsInBond,
  WorkOrderType.DeliveryOrder,
];

const allowedWorkOrdersPerMotAndRegionConfig = {
  US: {
    [ModeOfTransport.OCEAN]: [...usBaseAllowedWorkOrders, WorkOrderType.UsIsf], // ISF-10
    [ModeOfTransport.AIR]: usBaseAllowedWorkOrders,
    [ModeOfTransport.RAIL]: [...usBaseAllowedWorkOrders, WorkOrderType.UsIsf], // ISF-5
    [ModeOfTransport.TRUCK]: usBaseAllowedWorkOrders,
  },
};

export const getAllowedWorkOrders = (
  user: AnyObject,
  destinationCountry: DestinationCountry,
  mot: ModeOfTransport,
) =>
  allowedWorkOrdersPerMotAndRegionConfig[destinationCountry][mot]?.filter(
    (wo) => checkUserAccessToRequestWorkOrder(user, wo),
  );

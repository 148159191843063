import { handleActions } from 'redux-actions';

const defaultOnboarding = {
  // validation state
  tab: '1',
  submitFailed: false,
  valid: {
    profile: false,
    company: false,
    subscription: false,
    bond: false,
    duties: false,
  },
  redirect: null,
  subscriptionPayment: {},
};

const onboardingReducer = handleActions(
  {
    ONBOARDING_TAB: (state, { payload }) => ({
      ...state,
      tab: payload.toString(),
    }),
    ONBOARDING_FAIL: (state) => ({ ...state, redirect: true }),
    ONBOARDING_LOAD: (state) => ({ ...state, redirect: false }),
    ONBOARDING_UNLOAD: (state) => ({ ...state, redirect: null }),
    ONBOARDING_VALIDATE_ALL: (state, { payload: isValid }) => ({
      ...state,
      submitFailed: !isValid,
    }),
    ONBOARDING_VALIDATE: (state, { payload }) => ({
      ...state,
      valid: { ...state.valid, ...payload },
    }),
  },
  defaultOnboarding,
);

export default onboardingReducer;

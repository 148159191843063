import { RecordType } from '@xbcb/shared-types';
import { EscalationMessage } from '../../loadableComponents';
import { AppRecordRoute } from 'routes/types';

const workOrderTaskEscalationMessageFragments = `fragment workOrderTaskEscalationMessageFields on WorkOrderTaskEscalationMessage {
  ...recordFields
  escalationDetails {
    details
    escalatedBy {
      name
    }
    escalatedTo {
      name
    }
    escalationReasons {
      reasonCategory
      reasonSubCategory
    }
    escalationType
    escalatedTime
  }
  replyDetails {
    details
    repliedBy {
      name
    }
    replyTime
  }
  resolutionDetails {
    details
    resolutionReasons
    resolvedBy {
      name
    }
    resolutionTime  
  }
  workOrderTask {
    name
    deadline
    workOrder {
      id
    }
    definition {
      workOrderTaskType
    }
    assignmentTeam {
      subjectMatterExpertTeam {
        name
      }
    }
    assignee {
      name
    }
    status
  }
}
`;

const escalationMessageRoutes: AppRecordRoute[] = [
  {
    Page: EscalationMessage,
    recordType: RecordType.WORK_ORDER_TASK_ESCALATION_MESSAGE,
    fields: `...workOrderTaskEscalationMessageFields`,
    fragments: workOrderTaskEscalationMessageFragments,
    hideDefaultEditButton: () => true,
    hideDeleteKebabMenuItem: () => true,
  },
];

export default escalationMessageRoutes;

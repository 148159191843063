import { handleActions } from 'redux-actions';

export interface DefaultLogin {
  form: 'login' | 'verification' | 'newPassword';
  flow: 'login' | 'forgot';
  isLoading: boolean;
  remember: boolean;
  delivery: any;
  user: any;
  userAttributes: any;
}
const defaultLogin: DefaultLogin = {
  form: 'login',
  flow: 'login',
  isLoading: false,
  remember: true,
  delivery: {},
  user: {},
  userAttributes: {},
};

const loginReducer = handleActions(
  {
    LOGIN_CHANGE: (state, { payload }) => ({ ...state, ...payload }),
    LOGOUT: () => defaultLogin,
  },
  defaultLogin,
);

export default loginReducer;

import { useHistory, useLocation } from 'react-router-dom';
import qs, { ParsedQs } from 'qs';
import getQueryParameters from 'libs/getQueryParameters';

export type QueryString = string | string[] | ParsedQs | ParsedQs[] | undefined;

export const useQueryParams = (
  key: string,
): readonly [QueryString, (value?: string) => void] => {
  const history = useHistory();
  const location = useLocation();
  const queryParameters = getQueryParameters(location);
  const value: QueryString = queryParameters[key];

  const setQueryStringParameter = (value?: string) => {
    const newQueryParameters = {
      ...getQueryParameters(location),
      [key]: value,
    };
    const newQueryString = qs.stringify(newQueryParameters);
    history.replace(`${location.pathname}?${newQueryString}`);
  };

  // N.B.
  // This uses a TS feature called const assertions. See here: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#const-assertions
  // Depending on the context it can have a few different effects, all of which serve to tell the compiler that the relevant values are not dynamic. So in the case of arrays, `as const` makes them read-only tuples. Otherwise the return type for this function would be (QueryString, (value: any) => void)[] which is less correct and annoying to work w/ since the first value will always be QueryString and the second value will always be (value: any) => void).
  return [value, setQueryStringParameter] as const;
};

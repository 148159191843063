import { gql } from '@apollo/client';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { client } from '@xbcb/apollo-client';
import { AnyObject } from '@xbcb/shared-types';
import { HTS_HIERARCHY_FETCH, SET_HTS_DATA, HtsData } from '../types';

export interface SetHtsData {
  effectiveDate: string;
  data: HtsData;
}
export const setHtsData = createAction<SetHtsData>(SET_HTS_DATA);

const HTS_LINK_QUERY = gql`
  query {
    fetchHtsHierarchy {
      downloadUrl
      versionId
    }
  }
`;

interface HtsQueryResult {
  fetchHtsHierarchy: {
    downloadUrl: string;
    versionId: string;
  };
}

interface HtsHierarchyObject {
  htsHierarchy: AnyObject;
  versionId: string;
}

export const fetchHtsHierarchy = createAsyncThunk<HtsHierarchyObject>(
  HTS_HIERARCHY_FETCH,
  async (_, { getState }) => {
    const response = await client.query<HtsQueryResult>({
      query: HTS_LINK_QUERY,
    });
    const state = getState() as AnyObject;
    const link = response?.data?.fetchHtsHierarchy?.downloadUrl;
    const versionId = response?.data?.fetchHtsHierarchy?.versionId;
    let { versionId: storedVersionId, htsHierarchy } = state.hts.usHtsHierarchy;
    if (versionId !== storedVersionId) {
      const response = await (await fetch(link)).json();
      htsHierarchy = response.htsHierarchy;
    }
    return {
      htsHierarchy,
      versionId,
    };
  },
);

import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledLinkOptionDiv = styled.div`
  a {
    color: black;
    display: flex;
  }
  .anticon-link,
  .anticon-mail {
    margin-right: ${CssSpace.SPACE_4};
    font-size: 32px;
  }
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
`;

export const StyledLinkOptionSpan = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
`;

export const StyledLinkDiv = styled.div`
  width: 384px;
  margin: 0 auto;

  .ant-divider {
    margin: ${CssSpace.SPACE_4} 0;
  }
`;

import { CssSpace } from '@xbcb/ui-types';
import styled from 'styled-components';

export const StyledDivForRadioButton = styled.div`
  display: flex;
  margin-bottom: ${CssSpace.SPACE_2};
`;

export const StyledDiv = styled.div`
  display: flex;
  margin-bottom: ${CssSpace.SPACE_4};
`;

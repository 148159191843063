import { KebabMenuItemKey, UiStage } from '@xbcb/ui-types';
import { openModal } from 'actions';
import { ModalKey } from 'types';
import { AppRecordKebabMenuItem } from '../../types';
import { getEnv } from '@xbcb/ui-utils';

const { stage } = getEnv();

export const appRecordSendAbiMessage: AppRecordKebabMenuItem = {
  text: 'Send/Receive ABI messages',
  key: KebabMenuItemKey.SEND_ABI_MESSAGE,
  action: ({ dispatch }) => {
    dispatch(openModal({ key: ModalKey.APP_RECORD_SEND_ABI_MESSAGE }));
  },

  show: (_) => stage !== UiStage.PROD,
};

import { AnyObject, ModeOfTransport } from '@xbcb/shared-types';
import {
  ValidationErrorType,
  type AdditionalFormError,
  type NamePath,
} from '@xbcb/ui-types';
import subjectToPNFunction from 'libs/subjectToPN';
import { createAccumulator, validatePga } from '.';
import { UsPgaFlag } from '__generated__/graphql';

export const validateTariffPga = (args: {
  tariff: AnyObject;
  iorName: string;
  validatePartySnapshot: (
    idPath: NamePath,
    displayName: string,
    embeddedFields?: NamePath[],
  ) => void;
  ior: AnyObject;
  tariffNamePath: NamePath;
  tariffErrorString: string;
  line: AnyObject;
  mot?: ModeOfTransport;
}): { validateFields: NamePath[]; additionalErrors: AdditionalFormError[] } => {
  const {
    tariff,
    iorName,
    validatePartySnapshot,
    ior,
    tariffNamePath,
    line,
    mot,
  } = args;

  const validateFields: NamePath[] = [];
  const additionalErrors: AdditionalFormError[] = [];
  const accumulator = createAccumulator({ additionalErrors, validateFields });

  for (const pgaFlag of Object.keys(tariff.pga) as UsPgaFlag[]) {
    const pga = tariff.pga[pgaFlag];
    if (pga === null) break;

    const {
      agencyCode,
      programCode,
      processingCode,
      foreignSupplierVerificationProgram: fsvp,
    } = pga;

    const subjectToPN = subjectToPNFunction({
      pgaFlag,
      processingCode,
      programCode,
    });

    if (subjectToPN && fsvp) {
      if (fsvp.sameAsIor) {
        if (!ior.duns || ior.duns === 'UNK') {
          additionalErrors.push({
            title: 'Foreign Supplier Verification Program',
            type: ValidationErrorType.GENERAL,
            messages: [`Missing DUNS info in ${iorName} (required for FSVP)`],
          });
        }
      } else {
        validatePartySnapshot(
          [
            ...tariffNamePath,
            'pga',
            pgaFlag,
            'foreignSupplierVerificationProgram',
            'customsAgent',
            'id',
          ],
          'Foreign Supplier Verification Program',
        );

        if (!fsvp.duns || fsvp.duns === 'UNK') {
          additionalErrors.push({
            title: 'Foreign Supplier Verification Program',
            type: ValidationErrorType.GENERAL,
            messages: ['Missing DUNS info in FSVP'],
          });
        }
      }
    }

    accumulator([
      validatePga(
        tariffNamePath,
        pgaFlag,
        agencyCode,
        pga,
        validatePartySnapshot,
        line?.manufacturer?.countryCode,
        mot,
      ),
    ]);
  }

  return {
    validateFields,
    additionalErrors,
  };
};

import type { OperatorUserAssignmentTeamEdge } from '@xbcb/api-gateway-client';
import { OperatorUserTeamRole } from '@xbcb/shared-types';
import { intersection } from 'lodash';
import { isOperatorUser } from '@xbcb/ui-utils';

export const isAssignmentTeamLead = (user: unknown): boolean => {
  if (isOperatorUser(user)) {
    return !!user?.assignmentTeams?.edges.some(
      (edge: OperatorUserAssignmentTeamEdge) =>
        Boolean(
          intersection(
            [
              OperatorUserTeamRole.PRIMARY_TEAM_LEAD,
              OperatorUserTeamRole.SECONDARY_TEAM_LEAD,
            ],
            edge.relationship?.roles,
          )?.length,
        ),
    );
  }
  return false;
};

export const isSubjectMatterExpert = (user: unknown): boolean => {
  if (isOperatorUser(user)) {
    return Boolean(user?.subjectMatterExpertTeams?.edges.length);
  }
  return false;
};

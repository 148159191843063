import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const StyledCaseDiv = styled.div`
  p {
    margin-bottom: 0;
  }
  float: left;
  width: 100%;
`;

export const StyledExclamationCircleOutlined = styled(
  ExclamationCircleOutlined,
)`
  vertical-align: middle;
  svg {
    color: ${({ theme }) => theme.palette.pinks.coral};
    margin-right: ${({ theme }) => theme.spacing.space_3};
  }
`;

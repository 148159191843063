import localForage from 'localforage';
import { DateTime } from 'luxon';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import {
  persistCombineReducers,
  createTransform,
  persistReducer,
} from 'redux-persist';
import { StateFromReducersMapObject } from 'redux';
import ui from './ui';
import userLogin from './userLogin';
import hts from './hts';
import tables from './tables';
import isFetching from './isFetching';
import codeVersion from './code';
import openProducts from './openProducts';
import activeProduct from './activeProduct';
import recentlyViewed from './recentlyViewed';
import userStatus from './userStatus';
import validation from './validation';
import activeBroker from './activeBroker';
import referencedRecords from './referencedRecords';
import { ReferencedRecordsState } from 'types';
import menuCollapse from './menuCollapse';
const resetPageNumbers = (tables: { [key: string]: { current: number } }) => {
  const keys = Object.keys(tables);
  for (const k of keys) {
    tables[k].current = 1;
  }
  return tables;
};

const safeTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    return inboundState;
  },
  // transform state being rehydrated.
  // TODO fix typing once we have types for the tables slice of Redux.
  (outboundState: any, key) => {
    return resetPageNumbers(outboundState);
  },
  { whitelist: ['tables'] },
);

const expireReferencedRecords = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState: ReferencedRecordsState) => {
    return inboundState;
  },
  // transform state being rehydrated.
  // Expire referenced records persisted over 30 days ago
  (outboundState: ReferencedRecordsState) => {
    const expiredRecords: string[][] = [];
    Object.entries(outboundState).forEach(
      ([referenceField, referencedRecords]) => {
        Object.entries(referencedRecords).forEach(
          ([referenceKey, { time }]) => {
            if (DateTime.fromISO(time) < DateTime.now().minus({ days: 30 })) {
              expiredRecords.push([referenceField, referenceKey]);
            }
          },
        );
      },
    );
    for (const [referenceField, referenceKey] of expiredRecords) {
      delete outboundState[referenceField][referenceKey];
    }
    return outboundState;
  },
  { whitelist: ['referencedRecords'] },
);

const htsPersist = persistReducer(
  {
    key: 'hts',
    storage: localForage,
    whitelist: ['usHtsHierarchy'],
  },
  hts,
);

const config = {
  key: 'inlt',
  whitelist: [
    'notifications',
    'codeVersion',
    'charts',
    'tables',
    'recentlyViewed',
    'activeBroker',
    'referencedRecords',
    'menuCollapse',
  ],
  storage: localForage,
  throttle: 2000, // default is 0
  transforms: [safeTransform, expireReferencedRecords],
};

const reducerMap = {
  isFetching,
  codeVersion,
  ui,
  tables,
  userLogin,
  openProducts,
  activeBroker,
  activeProduct,
  recentlyViewed,
  hts: htsPersist,
  userStatus,
  validation,
  menuCollapse,
  referencedRecords,
};

export const rootReducer = persistCombineReducers(config, reducerMap);

export type RootState = StateFromReducersMapObject<typeof reducerMap>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

import styled from 'styled-components';
import { Button } from 'antd';

export const StyledLineItemsDiv = styled.div`
  & > .ant-collapse {
    background: none;
    margin-bottom: ${({ theme }) => theme.spacing.space_4};
    .ant-collapse-item {
      border: none;
      .ant-collapse-content {
        padding: 0;
        overflow: visible;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  background: none !important;
  border: none !important;
  .ant-btn {
    background: none !important;
    border: none !important;
  }
`;

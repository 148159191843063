import { ProductUsPgaInput } from '__generated__/graphql';
import { PgaFlag } from 'libs/pgaHelpers';
import { CompoundPgaValidator } from 'libs/entryValidation/pga/compoundPgaValidator';
import { PgaValidationArgs } from 'libs/entryValidation/pga/types';

export class PesticidesValidator extends CompoundPgaValidator<ProductUsPgaInput> {
  isApplicable({ pgaFlag }: PgaValidationArgs<ProductUsPgaInput>): boolean {
    return PgaFlag.subjectToPesticides(pgaFlag);
  }
}

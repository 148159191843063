import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledDiv = styled.div`
  background-color: white;
`;

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
`;

export const StyledParagraph = styled.p`
  margin-bottom: ${CssSpace.SPACE_3};
`;

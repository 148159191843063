import { useMutation } from '@apollo/client';
import { createOneMutation } from '@xbcb/shared-queries';
import { RecordType, Tag } from '@xbcb/shared-types';
import { Button, Form } from 'antd';
import { camelCase } from 'change-case';
import { UploadDocBox, DocumentFields } from '@xbcb/document-components';
import { CssSize } from '@xbcb/ui-types';
import { ReportReconciliationType } from '@xbcb/finance-types';
import { SimpleSelect, HiddenField } from '@xbcb/form-item-components';
import withModal from 'components/withModal';
import { transformReportReconciliationRequest } from 'libs/formTransforms';
import handleRecordCreate from 'libs/handleRecordCreate';
import { useCurrentUser } from 'libs/hooks';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StyledForm, StyledModal } from './styles';
import { ModalKey } from 'types';

interface CreateReportReconciliationRequestModalProps {
  closeModal: any;
}

const CreateReportReconciliationRequestModal: React.FC<
  CreateReportReconciliationRequestModalProps
> = ({ closeModal }) => {
  const { visible }: { visible: boolean } = useSelector((state) =>
    get(
      state,
      `ui.modals.${ModalKey.CREATE_REPORT_RECONCILIATION_REQUEST}`,
      {},
    ),
  );
  const user = useCurrentUser();
  const [form] = Form.useForm();
  const history = useHistory();
  const createMutation = createOneMutation({
    recordName: RecordType.REPORT_RECONCILIATION_REQUEST,
    fields: `
      id
    `,
  });
  const [createRecord, { loading }] = useMutation(createMutation);
  const [isUploading, setIsUploading] = useState(false);
  const [documentList, setDocumentList] = useState<DocumentFields[]>([]);
  const idName = `${camelCase(RecordType.REPORT_RECONCILIATION_REQUEST)}Id`;
  const { operator } = user;
  const operatorId = operator?.id;
  const documentIdList = documentList.map((document) => document.id);
  const tags: Tag[] = [
    {
      key: idName,
      // This is needed to handle the case where documents are uploaded on new
      // records before they are created (since they do not have an ID yet)
      value: operatorId,
    },
  ];

  return (
    <StyledForm form={form}>
      <StyledModal
        destroyOnClose
        title="New Report"
        maskClosable={loading}
        onCancel={() => closeModal()}
        open={visible}
        width={700}
        footer={[
          <Button key="back" onClick={() => closeModal()}>
            Cancel
          </Button>,
          <Button
            key="submit"
            disabled={!documentList.length}
            type="primary"
            onClick={async () =>
              handleRecordCreate({
                form,
                history,
                recordType: RecordType.REPORT_RECONCILIATION_REQUEST,
                transformCreateRecordInput:
                  transformReportReconciliationRequest.toSchema,
                createRecord,
                clean: false,
                closeModal,
                user,
              })
            }
          >
            Create
          </Button>,
        ]}
      >
        <div>
          <h4 className="space-right">Report type :</h4>
          <SimpleSelect
            form={form}
            $itemSize={CssSize.SHORT}
            fullNamePath={['reportType']}
            localNamePath={['reportType']}
            selectOptions={Object.values(ReportReconciliationType)}
          />
          <h4 className="space-right">Report upload : </h4>
          <UploadDocBox
            key="upload"
            multiple={true}
            acceptedFileTypes={'.csv,.xls,.xlsx'}
            uploadLoadText={'Click or drag an spreadsheet (.csv/.xls/.xlsx)'}
            showUploadList={true}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            documentList={documentList}
            setDocumentList={setDocumentList}
            searchQueryTags={tags}
            tagsForNewDocuments={tags}
            operatorId={operatorId}
          />
          <HiddenField
            localNamePath={['documentIds']}
            fullNamePath={['documentIds']}
            value={documentIdList}
            form={form}
          />
        </div>
      </StyledModal>
    </StyledForm>
  );
};

export default withModal(ModalKey.CREATE_REPORT_RECONCILIATION_REQUEST)(
  CreateReportReconciliationRequestModal,
);

import React from 'react';
import { Skeleton, Tabs } from 'antd';
import { useQuery } from '@apollo/client';
import { getRecordFromResponse } from '@xbcb/shared-queries';
import { RecordType } from '@xbcb/shared-types';
import Milestones from 'components/Milestones';
import WorkOrderTaskSteps from 'components/WorkOrderTaskSteps';
import WorkOrderTaskInfoCard from 'components/WorkOrderTaskInfoCard';
import AutoExecutionDetails from 'components/WorkOrderTaskAutoExecutionDetails';
import { workOrderTaskQuery } from 'libs/sharedQueries';
import { StyledTabs } from './styles';
import { DataCyTabPrefix, UiStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import type {
  WorkOrder,
  WorkOrderInquiry,
  WorkOrderConfirmation,
} from '@xbcb/api-gateway-client';
import {
  evaluateWorkOrderTaskState,
  WorkOrderTaskEvaluatedState,
} from 'libs/evaluateWorkOrderTaskState';

const { TabPane } = Tabs;

interface WorkOrderTabsProps {
  record: WorkOrder;
  recordType: RecordType;
  additionalInfoCards?: React.ReactNode[];
  loading?: boolean;
  milestones: any[];
  inquiries: WorkOrderInquiry[];
  pgaDispositions: any[];
  confirmations?: WorkOrderConfirmation[];
  taskId?: string;
  setTaskEvaluatedState: (
    value: WorkOrderTaskEvaluatedState | undefined,
  ) => void;
}

const WorkOrderTabs = ({
  record,
  recordType,
  additionalInfoCards,
  loading,
  milestones,
  confirmations,
  pgaDispositions,
  inquiries,
  // The presence of taskId indicates that the user is currently in the work order
  // task view. If it exists, then we show the WOT step checkboxes and info about the task.
  taskId,
  setTaskEvaluatedState,
}: WorkOrderTabsProps) => {
  const queryResponse = useQuery(workOrderTaskQuery, {
    variables: {
      id: taskId,
    },
    skip: !taskId,
  });
  const { loading: queryLoading } = queryResponse;
  const { stage } = getEnv();
  const isDisabledForTesting = stage !== UiStage.PROD;
  const workOrderTask =
    getRecordFromResponse(queryResponse, 'get', RecordType.WORK_ORDER_TASK) ||
    {};

  if (taskId) {
    setTaskEvaluatedState(evaluateWorkOrderTaskState(workOrderTask));
  } else {
    // This is set to STARTED so that it won't affect WO readonly/disabled state
    setTaskEvaluatedState(WorkOrderTaskEvaluatedState.STARTED);
  }

  return (
    <StyledTabs
      type="card"
      defaultActiveKey="2"
      data-cy={DataCyTabPrefix.WORK_ORDER_TABS}
    >
      <TabPane tab="Milestones" key="1">
        <Milestones
          loading={loading}
          milestones={milestones}
          confirmations={confirmations}
          pgaDispositions={pgaDispositions}
          inquiries={inquiries}
        />
      </TabPane>
      {taskId ? (
        <>
          <TabPane tab="Steps" key="2">
            {isDisabledForTesting && (
              <AutoExecutionDetails workOrderTask={workOrderTask} />
            )}
            <WorkOrderTaskSteps
              record={record}
              loading={loading || queryLoading}
              workOrderRecordType={recordType}
              workOrderTask={workOrderTask}
            />
          </TabPane>
          <TabPane tab="Task Info" key="3">
            <WorkOrderTaskInfoCard
              loading={loading || queryLoading}
              workOrderTask={workOrderTask}
            />
          </TabPane>
        </>
      ) : (
        additionalInfoCards && (
          <>
            <TabPane tab="Additional Info" key="2">
              {loading ? (
                <Skeleton active />
              ) : (
                additionalInfoCards.map((Card) => Card)
              )}
            </TabPane>
          </>
        )
      )}
    </StyledTabs>
  );
};

export default WorkOrderTabs;

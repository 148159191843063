import { createReducer } from '@reduxjs/toolkit';
import { fetchHtsHierarchy, setHtsData } from '../actions/hts';
import { HtsHierarchy } from 'types';
import { isEqual } from 'lodash';

const defaultState: HtsHierarchy = {
  isLoading: false,
  usHtsHierarchy: { htsHierarchy: {}, versionId: '' },
  usHtsData: {},
};
const htsReducer = createReducer(defaultState, (builder) =>
  builder
    .addCase(fetchHtsHierarchy.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    .addCase(fetchHtsHierarchy.fulfilled, (state, { payload }) => ({
      ...state,
      usHtsHierarchy: payload,
      isLoading: false,
    }))
    .addCase(fetchHtsHierarchy.rejected, (state) => ({
      ...state,
      isLoading: false,
    }))
    .addCase(setHtsData, (state, { payload: { effectiveDate, data } }) => {
      const oldState = state.usHtsData[effectiveDate] ?? {};
      const newState = { ...oldState, ...data };
      if (isEqual(newState, oldState)) {
        return state;
      }
      return {
        ...state,
        usHtsData: { ...state.usHtsData, [effectiveDate]: newState },
      };
    }),
);

export default htsReducer;

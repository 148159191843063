import { WorkOrderStatus, WorkOrderTaskType } from '@xbcb/work-order-types';
import type { WorkOrderTask } from '@xbcb/api-gateway-client';
import { taskIsInProgress } from '@xbcb/work-order-utils/dist/findMostRecentInProgressTask';

const permissibleInquireWorkOrderStates = [
  WorkOrderStatus.IN_PROGRESS,
  WorkOrderStatus.COMPLETED,
];

export const showInquireWorkOrderKebabMenuItemFunc = ({
  status,
  taskType,
  workOrderTasks,
}: {
  status?: WorkOrderStatus;
  taskType?: WorkOrderTaskType;
  workOrderTasks?: WorkOrderTask[];
}) => {
  const task = workOrderTasks?.find(
    (task: WorkOrderTask) => task.definition?.workOrderTaskType === taskType,
  );
  const isException = Boolean(
    task?.isException ||
      workOrderTasks?.find(
        (task: WorkOrderTask) => task.definition?.isException,
      ),
  );

  if (status && permissibleInquireWorkOrderStates.includes(status)) {
    // not on task page
    if (!taskType && (isException || status === WorkOrderStatus.IN_PROGRESS)) {
      return true;
    }
    // on task page
    if (task && taskIsInProgress(task)) {
      return true;
    }
  }
  return false;
};

import styled from 'styled-components';

export const StyledAsteriskSymbol = styled.div`
  display: inline;
  margin-left: 5px;
  color: red;
  ::before {
    content: '*';
  }
`;

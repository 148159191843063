import { memoFragments } from '@xbcb/client-queries';
import {
  usIorFragments as defaultUsIorFragments,
  personalEffectsFragment,
} from '@xbcb/party-queries';
import { iscbpReg2ChangesFeatureEnabled } from 'featureFlags';

export const usIorFields = `
  ...usIorFields
  ${iscbpReg2ChangesFeatureEnabled ? 'businessWebsite' : ''}
  operator {
    id
  }
  created {
    client {
      ...on ForwarderUser {
        forwarder {
          id
        }
      }
    }
  }
  status
  paymentTerms
  paymentTermsUpdated {
    time
  }
  memos {
    ...memoFields
  }
  personalEffects {
    ...personalEffectsFields
  }
  shippers {
    nodes {
      id
      status
      forwarders {
        nodes {
          id
          name
          supportsPersonalEffects
        }
      }
    }
  }
  documentSignRequests {
    created {
      time
    }
    url
    documentTags
    signed {
      time
    }
  }
`;

export const usIorFragments = `${defaultUsIorFragments}${memoFragments}${personalEffectsFragment}`;

export const usIorSubscriptionFields = `
...usIorFields
operator {
  id
}
personalEffects {
  ...personalEffectsFields
}
shippers {
  nodes {
    id
    forwarders {
      nodes {
        id
        name
        supportsPersonalEffects
      }
    }
  }
}
`;

export const usIorSubscriptionFragments = `${defaultUsIorFragments}${personalEffectsFragment}`;

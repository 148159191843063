import { AnyObject } from '@xbcb/shared-types';
import {
  AdditionalHts,
  HtsRate,
  HtsFee,
  HtsRestriction,
  HtsSpiRate,
  UsPrincipalAdCvdCase,
} from '@xbcb/api-gateway-client';

export interface HtsObject {
  ad?: boolean;
  principalAdCvdCases?: UsPrincipalAdCvdCase[];
  cv?: boolean;
  additional?: AdditionalHts[];
  additionalHTS?: boolean;
  duty?: string;
  fees?: HtsFee[];
  gspExcluded?: string[];
  pga?: string[];
  quota?: boolean;
  startDate?: string;
  endDate?: string;
  restriction?: HtsRestriction;
  text?: string;
  fullText?: string;
  textile?: string;
  trumpList?: string;
  spi?: string[];
  licenseCode?: string;
  rate?: HtsRate;
  spiRates?: HtsSpiRate[];
  uom?: string[];
}

export type HtsData = Record<string, HtsObject | undefined>;
export type HtsEffectiveData = Record<string, HtsData>;
export interface HtsHierarchyData {
  versionId: string;
  htsHierarchy: AnyObject;
}

export const HTS_HIERARCHY_FETCH = 'HTS_HIERARCHY_FETCH';
export const SET_HTS_DATA = 'SET_HTS_DATA';
export interface HtsHierarchy {
  usHtsHierarchy: HtsHierarchyData;
  usHtsData: HtsEffectiveData;
  isLoading: boolean;
}

import { CssSpace } from '@xbcb/ui-types';
import styled from 'styled-components';

export const StyledDiv = styled.div`
  position: relative;
  bottom: 2px;

  button {
    border: none;
  }

  button:first-child {
    margin-right: ${({ theme }) => theme.spacing.space_4};
  }

  // TODO add this margin when implementing PMSOverrideButton
  .importer-pms-override-button {
    margin-left: ${({ theme }) => theme.spacing.space_3};
  }
`;

export const StyledIsVoidedDiv = styled.div`
  margin-bottom: ${CssSpace.SPACE_4};
`;

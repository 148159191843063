import {
  RecordType,
  UserRecordType,
  PartyRecordType,
} from '@xbcb/shared-types';

const nameTitles = [
  ...Object.keys(UserRecordType),
  ...Object.keys(PartyRecordType),
  RecordType.WORK_ORDER_TASK_DEFINITION,
  RecordType.TEAM,
  RecordType.ASSIGNMENT_TEAM,
  RecordType.SUBJECT_MATTER_EXPERT_TEAM,
  RecordType.BUSINESS_SUPPORT_TEAM,
].reduce(
  (acc, key) => ({
    ...acc,
    [key]: 'name',
  }),
  {},
);

const titleFields = {
  [RecordType.HTS]: 'number',
  [RecordType.US_ISF]: 'isfNumber',
  [RecordType.US_CONSUMPTION_ENTRY]: 'entryNumber',
  [RecordType.PRODUCT]: 'clientIdentifier',
  [RecordType.AD_CVD_CASE]: 'caseNumber',
  [RecordType.SHIPMENT]: 'friendlyId',
  [RecordType.EXCHANGE_MESSAGE]: 'email',
  [RecordType.IMPORTER_ENTITY]: 'id',
  ...nameTitles,
};

export default titleFields;

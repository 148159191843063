import React from 'react';
import {
  StyledDiv,
  StyledFormItem,
  StyledInputNumber,
  StyledWrapper,
} from './styles';
import { CssSize, createDataCyValue, DataCyPrefix } from '@xbcb/ui-types';
// TODO switch to our internal name path that enforces (string | number)[]
import { NamePath } from 'rc-field-form/lib/interface';

export type DecimalQuantityProps = {
  required?: boolean;
  label?: React.ReactNode;
  hideLabel?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value?: number) => void;
  defaultValue?: number;
  field: NamePath;
  units?: string;
  $itemSize?: string;
  min?: number;
  max?: number;
  debounce?: boolean;
  dataCySuffix?: string;
  useAddonAfter?: boolean;
};

const DecimalQuantity: React.FC<DecimalQuantityProps> = ({
  required = false,
  label = 'Quantity',
  hideLabel = false,
  disabled = false,
  readOnly = false,
  onChange,
  defaultValue,
  field,
  units,
  $itemSize = CssSize.MICRO,
  min = 0,
  max,
  debounce = false,
  dataCySuffix,
  useAddonAfter = false,
}) => {
  return (
    <StyledWrapper>
      <StyledFormItem
        $itemSize={$itemSize}
        $inline
        label={hideLabel ? undefined : label}
        name={field}
        rules={[{ required, message: ' ' }]}
        $readOnly={readOnly}
        debounce={debounce}
        data-cy={createDataCyValue(DataCyPrefix.DECIMAL_QUANTITY, dataCySuffix)}
      >
        <StyledInputNumber
          disabled={disabled}
          onChange={(val) =>
            onChange && (typeof val === 'number' ? onChange(val) : undefined)
          }
          defaultValue={defaultValue}
          min={min}
          max={max}
          addonAfter={useAddonAfter ? units : undefined}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        />
      </StyledFormItem>
      {units && !useAddonAfter && <StyledDiv>{units}</StyledDiv>}
    </StyledWrapper>
  );
};

export default DecimalQuantity;

import { Modal } from 'antd';
import React from 'react';
import PresentLink from 'components/PresentLink';
import { StyledHeader } from './styles';

export const openLinkModal = (content: JSX.Element) =>
  Modal.success({
    content,
    width: 600,
  });

type LinkModalContentProps = {
  heading: string;
  link: string;
  type: string;
};

export const linkModalContent = ({
  heading,
  link,
  type,
}: LinkModalContentProps) => (
  <div>
    <StyledHeader>{heading}</StyledHeader>
    <PresentLink link={link} linkType={type} />
  </div>
);

import { Layout } from 'antd';
import styled, { css } from 'styled-components';

const { Sider } = Layout;

export const appSiderWidthStyle = css`
  width: 200px;
`;

export const StyledSider = styled(Sider).attrs(() => ({
  trigger: null,
  collapsible: true,
  collapsedWidth: 45,
  width: 205,
}))`
  background: inherit;
  margin: theme.spacing.space_4;
`;

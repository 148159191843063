import React from 'react';
import { VehiclesAndEnginesFormProps } from '../../types';
import { Form } from 'antd';
import { ConditionalRemarks } from './conditional';
import { RemarksTypeCodes } from './remarksTypeCodes';

export const Remarks: React.FC<VehiclesAndEnginesFormProps> = (props) => {
  const { fullNamePath } = props;
  const form = Form.useFormInstance();

  const useWatchVneField = (fieldName: string) =>
    Form.useWatch([...fullNamePath, fieldName], form);

  const regulatoryCategory = useWatchVneField('regulatoryCategory');
  const importCode = useWatchVneField('vneImportCode');
  const industryCode = useWatchVneField('vneIndustryCode');

  const remarksTypeCodesProps = {
    ...props,
    regulatoryCategory,
  };

  const conditionalRemarksProps = {
    ...props,
    importCode,
    industryCode,
  };

  return (
    <>
      <RemarksTypeCodes {...remarksTypeCodesProps} />
      <ConditionalRemarks {...conditionalRemarksProps} />
    </>
  );
};

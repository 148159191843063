import { recordFields, recordActionFields } from '@xbcb/shared-queries';

export const deliveryOrderTruckerFragment = `fragment deliveryOrderTruckerFields on DeliveryOrderTrucker {
  trucker {
    id
    version
  }
  dispatchEmail
  dispatchPhone {
    country
    number
    extension
  }
}`;

export const deliveryOrderPickupFragment = `fragment deliveryOrderPickupFields on DeliveryOrderPickup {
  firmsCode
  lastFreeDate
  pickupNumber
  specialInstructions
}`;

export const deliveryOrderOptionalEmailFragment = `fragment deliveryOrderOptionalEmailFields on DeliveryOrderEmailInfo {
  attachPackingList
  ccEmails
  ccParties {
    id
    name
    email
  }
  sendEmail
  subject
}`;

export const deliveryOrderDestinationFragment = `fragment deliveryOrderDestinationFields on DeliveryOrderDestination {
  location {
    id
    version
    addresses {
      mailing {
        address
        city
        postalCode
        stateCode
        countryCode
      }
      physical {
        address
        city
        postalCode
        stateCode
        countryCode
      }
    }
  }
  name
  receivingEmail
  receivingPhone {
    country
    number
    extension
  }
}
`;

export const deliveryOrderContainerFragment = `fragment deliveryOrderContainerFields on ShippingContainer {
  number
  seal
  type
  weight
  quantity
  description
}`;

export const billingDetailsFragment = `fragment billingDetailsFields on BillingDetails {
  disbursement
  status
  subscriptionPlan
}`;

export const deliveryOrderFragment = `fragment deliveryOrderFields on DeliveryOrder {
  ...recordFields
  group {
    id
    customsBroker {
      id
    }
    shipper {
      id
      name
      version
      billingDetails {
        ...billingDetailsFields
      }
    }
    forwarders {
      forwarder {
        id
        name
        version
        billingDetails {
          ...billingDetailsFields
        }
      }
    }
    shipment {
      id
    }
    workOrders {
      ... on UsConsumptionEntry {
        broker {
          usCustomsBroker {
            id
            name
            version
          }
        }
      } 
    }
  }
  operator {
    id
    name
  }
  status
  deadline
  billTo {
    id
    version
  }
  milestones {
    name
    occurenceTime
  }
  status
  containers {
    ...deliveryOrderContainerFields
  }
  destination {
    ...deliveryOrderDestinationFields
  }
  email {
    ...deliveryOrderOptionalEmailFields
  }
  
  pickup {
    ...deliveryOrderPickupFields
  }

  trucker {
    ...deliveryOrderTruckerFields
  }
 }`;

export const deliveryOrderFragments = `
${deliveryOrderFragment}
${deliveryOrderContainerFragment}
${deliveryOrderTruckerFragment}
${deliveryOrderPickupFragment}
${deliveryOrderOptionalEmailFragment}
${deliveryOrderDestinationFragment}
${recordFields}
${recordActionFields}
${billingDetailsFragment}`;

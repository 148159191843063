import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { v4 } from 'uuid';
import { formatRecordName } from '@xbcb/js-utils';
import { RecordType, AccountType } from '@xbcb/shared-types';
import {
  WorkOrderMilestoneName,
  WorkOrderStatus,
  WorkOrderTaskType,
} from '@xbcb/work-order-types';
import { continuousBondInvoiceExists } from 'libs/continuousBondFailedFromMilestones';
import { executeMutation } from 'libs/executeMutation';
import {
  createWorkOrderMilestoneMutation,
  queryUsIorCbpWithChargeContinuousBond,
} from 'libs/sharedQueries';
import { checkKebabMenuItemForWorkOrderTask } from './checkKebabMenuItemForWorkOrderTask';
import { AppRecordKebabMenuItem } from '../../types';

export const usIorContinuousBondRequestChargeBondKebabMenuItem: AppRecordKebabMenuItem =
  {
    text: 'Charge Bond',
    key: 'chargeBond',
    action: async ({ record }) => {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: `Are you sure?`,
        content: `This means it’s already been acquired from the surety, we are just querying the ${formatRecordName(
          {
            recordType: RecordType.US_IOR,
            accountType: AccountType.OPERATOR,
          },
        )} profile with CBP to confirm it has been acquired and then we will charge on our end if so.`,
        visible: true,
        onOk: async () => {
          await executeMutation({
            mutation: queryUsIorCbpWithChargeContinuousBond,
            variables: {
              usIorId: record.usIor.id,
              input: {
                chargeContinuousBond: {
                  usIorContinuousBondRequestId: record.id,
                },
              },
            },
          });
          await executeMutation({
            mutation: createWorkOrderMilestoneMutation,
            variables: {
              idempotencyKey: v4(),
              input: {
                allowDuplicate: true,
                name: WorkOrderMilestoneName.US_IOR_CONTINUOUS_BOND_REQUEST_CHARGE_BOND_REQUESTED,
                workOrder: {
                  id: record.id,
                  version: record.version,
                },
                operator: {
                  id: record.operator?.id,
                },
              },
            },
          });
        },
      });
    },
    show: ({
      existingRecord,
      currentUser,
      workOrderTaskType,
      workOrderTaskEvaluatedState,
    }) => {
      const isOperator = currentUser.accountType === AccountType.OPERATOR;
      const isCanceled = [WorkOrderStatus.CANCELED].includes(
        existingRecord?.status,
      );
      return (
        isOperator &&
        !isCanceled &&
        !continuousBondInvoiceExists(existingRecord) &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        ) &&
        ![
          WorkOrderTaskType.US_IOR_CONTINUOUS_BOND_CHARGE_BOND_RENEWAL_EXCEPTION,
          WorkOrderTaskType.US_IOR_CONTINUOUS_BOND_VERIFY_RENEWAL_EXCEPTION,
        ].some((taskType) => taskType === workOrderTaskType)
      );
    },
  };

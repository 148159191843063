import {
  UsType86EntryLine,
  UsConsumptionEntryLine,
  UsComplianceDetailsInput,
} from '@xbcb/api-gateway-client';
import { getHtsNumbersFromProductLine } from './getHtsNumbersFromProductLine';
import { getUnitValueFromProductLineTariffs } from './getUnitValueFromProductLineTariffs';
import {
  UsCbpEntryLineFeeType,
  UsCbpEntryTaxType,
} from '@xbcb/compliance-types';

export const transformUsEntryLineToComplianceDetailInput = (
  entryLine: UsType86EntryLine | UsConsumptionEntryLine,
  ignoreAdCvdAndFees = false,
): UsComplianceDetailsInput | undefined => {
  const {
    tariffs,
    adDisclaimerCode,
    cvDisclaimerCode,
    feeOptions,
    taxOptions,
  } = entryLine;

  if (!tariffs || !tariffs.length) {
    return undefined;
  }

  const htsNumbers = getHtsNumbersFromProductLine(entryLine);
  if (!htsNumbers.length) {
    return undefined;
  }

  const unitValue = getUnitValueFromProductLineTariffs(tariffs);
  if (!unitValue.value || !unitValue.currency) {
    return undefined;
  }

  const originCountryCode = entryLine.origin?.countryCode;
  if (!originCountryCode) {
    return undefined;
  }

  const disclaimedFees = feeOptions
    // remove undefined
    ?.filter((fee) => fee.disclaim && fee.type)
    ?.map((fee) => fee.type) as UsCbpEntryLineFeeType[] | undefined;

  const disclaimedTaxes = taxOptions
    // remove undefined
    ?.filter((tax) => tax.disclaim && tax.type)
    ?.map((tax) => tax.type) as UsCbpEntryTaxType[] | undefined;

  return {
    unitValue,
    htsNumbers,
    adDisclaimed: ignoreAdCvdAndFees || !!adDisclaimerCode,
    cvDisclaimed: ignoreAdCvdAndFees || !!cvDisclaimerCode,
    originCountryCode,
    disclaimedFees,
    disclaimAllFees: ignoreAdCvdAndFees,
    disclaimedTaxes,
    disclaimAllTaxes: ignoreAdCvdAndFees,
  };
};

import { FormInstance } from 'antd/lib/form';
import { NamePath } from '@xbcb/ui-types';

export const getNameRequiredField = (baseFullNamePath: NamePath) => ({
  fullNamePath: [...baseFullNamePath, 'name'],
});

export const getAddressRequiredFields = (baseFullNamePath: NamePath) => [
  { fullNamePath: [...baseFullNamePath, 'address', 'address'] },
  { fullNamePath: [...baseFullNamePath, 'address', 'city'] },
  { fullNamePath: [...baseFullNamePath, 'address', 'stateCode'] },
  { fullNamePath: [...baseFullNamePath, 'address', 'countryCode'] },
];

export const getPostalCodeRequiredField = (baseFullNamePath: NamePath) => {
  const postalCodeFullNamePath = [...baseFullNamePath, 'address', 'postalCode'];
  return {
    validator: (form: FormInstance) => {
      const messages = [];
      const countryCode = form.getFieldValue([
        ...baseFullNamePath,
        'address',
        'countryCode',
      ]);
      const postalCode = form.getFieldValue(postalCodeFullNamePath);
      if (countryCode === 'CN' && !postalCode) {
        // Field's full path will be reported under error notification - "Missing Required Embedded Fields"
        messages.push(postalCodeFullNamePath.join('.'));
      }
      return messages;
    },
  };
};

export const getMidRequiredField = (baseFullNamePath: NamePath) => ({
  fullNamePath: [...baseFullNamePath, 'mid'],
});

export const getDunsRequiredField = (baseFullNamePath: NamePath) => ({
  fullNamePath: [...baseFullNamePath, 'duns'],
  validator: (form: FormInstance) => {
    const messages = [];
    const unknownDunsValue = form.getFieldValue([
      ...baseFullNamePath,
      'unknownDuns',
    ]);
    if (!unknownDunsValue) messages.push('Missing DUNS info');
    return messages;
  },
});

export const getIorNumberRequiredFields = (baseFullNamePath: NamePath) => [
  { fullNamePath: [...baseFullNamePath, 'iorNumber', 'value'] },
  { fullNamePath: [...baseFullNamePath, 'iorNumber', 'type'] },
];

import { IsfType, UsIsfShipmentTypeCode } from '@xbcb/work-order-types';
import { manifestFieldsToValidate } from '@xbcb/ui-utils';
import { AppRecordProps } from 'routes';
import { get } from 'lodash';
import { getValidationParams } from '../getValidationParams';
import { formatRecordName } from '@xbcb/js-utils';
import { RecordType } from '@xbcb/shared-types';

// TODO consolidate logic w/ validateUsConsumptionEntry
const validateUsIsf: AppRecordProps['validateUpdate'] = ({
  input,
  existingRecord,
  currentUser,
}) => {
  const { shipmentTypeCode, isfType } = existingRecord;
  const { buyer, consignee, masterBills, manufacturers, sellers, informal } =
    input;
  const { accountType } = currentUser;

  const { validateFields, additionalErrors, validatePartySnapshot } =
    getValidationParams({ input });

  // TODO once bond bot is set up, only require these fields for operator users.
  validateFields.push(['singleTransactionBond', 'suretyCode']);
  validateFields.push(['singleTransactionBond', 'referenceNumber']);

  // all ISF types require bills of lading reporting
  validateFields.push(...manifestFieldsToValidate(masterBills));

  const iorNumberFields = [
    ['iorNumber', 'value'],
    ['iorNumber', 'type'],
  ];
  validatePartySnapshot(
    ['ior', 'usIor', 'id'],
    formatRecordName({
      recordType: RecordType.US_IOR,
      accountType,
    }),
    iorNumberFields,
  );

  // house hold goods/personal effects are super stripped down.
  if (shipmentTypeCode !== UsIsfShipmentTypeCode.HHG_PE) {
    validatePartySnapshot(['shipTo', 'shipTo', 'id'], 'Ship To');

    for (let i = 0; i < manufacturers?.length; i++) {
      const manufacturer = get(manufacturers, i);
      const mKey = ['manufacturers', i];
      if (isfType !== IsfType.ISF_5) {
        validatePartySnapshot(
          [...mKey, 'supplier', 'id'],
          `Manufacturer ${i + 1}`,
        );
      }

      // minimum length 1
      const productsLength = get(manufacturer, 'products', []).length || 1;
      for (let j = 0; j < productsLength; j++) {
        const pKey = [...mKey, 'products', j];
        const htsKey = [...pKey, 'htsNumber'];
        validateFields.push(htsKey);
        const htsNumber = get(input, htsKey);
        if (htsNumber && htsNumber.replace(/\./g, '').length < 6) {
          additionalErrors.push({
            title: 'HTS length',
            messages: [
              `HTS Number ${htsNumber} must be reported to at least 6 digits.`,
            ],
          });
        }
        if (isfType !== IsfType.ISF_5) {
          validateFields.push([...pKey, 'originCountryCode']);
        }
      }
    }

    // ISF-5 vs ISF-10 have different reporting requirements.
    if (isfType === IsfType.ISF_5) {
      validateFields.push(['portOfUnladingCode'], ['placeOfDelivery']);
      validatePartySnapshot(
        ['bookingParty', 'bookingParty', 'id'],
        'Booking Party',
      );
    } else {
      // TODO add DUNS/IOR number embedded fields validation
      if (!buyer?.sameAsIor) {
        validatePartySnapshot(['buyer', 'buyer', 'id'], 'Buyer');
      }
      if (!consignee?.sameAsIor) {
        validatePartySnapshot(
          ['consignee', 'usConsignee', 'id'],
          formatRecordName({
            recordType: RecordType.US_CONSIGNEE,
            accountType,
          }),
          iorNumberFields,
        );
      }
      validatePartySnapshot(
        ['consolidator', 'consolidator', 'id'],
        'Consolidator',
      );
      validatePartySnapshot(
        ['stuffingLocation', 'stuffingLocation', 'id'],
        'Stuffing Location',
      );
      // at least 1 seller
      validatePartySnapshot(['sellers', 0, 'seller', 'id'], `Seller 1`);
      if (sellers?.length > 1) {
        sellers.slice(1).forEach((seller: any, s: number) => {
          if (seller?.seller?.id) {
            validatePartySnapshot(
              ['sellers', s + 1, 'seller', 'id'],
              `Seller ${s + 2}`,
            );
          }
        });
      }
    }

    // informal entries require additional info.
    if (informal) {
      validateFields.push(
        ['informal', 'estimatedValue', 'value'],
        ['informal', 'estimatedValue', 'currency'],
        ['informal', 'estimatedQuantity', 'value'],
        ['informal', 'estimatedQuantity', 'unit'],
        ['informal', 'estimatedWeight', 'value'],
        ['informal', 'estimatedWeight', 'unit'],
      );
    }
  }
  return {
    additionalErrors,
    validateFields,
  };
};
export default validateUsIsf;

import { get, omit } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import {
  spreadsheetLoadData,
  spreadsheetReset,
  spreadsheetUnload,
  spreadsheetUploaded,
} from 'actions';

const defaultSpreadsheet: AnyObject & { recordType?: RecordType } = {};

const spreadsheetReducer = createReducer(defaultSpreadsheet, (builder) =>
  builder
    .addCase(spreadsheetLoadData, (state, { payload }) => {
      const { recordType } = payload;
      const existingState = get(state, [recordType], {});
      return {
        ...state,
        [recordType]: {
          ...existingState,
          ...omit(payload, 'recordType'),
        },
      };
    })
    .addCase(spreadsheetReset, (state, { payload }) => {
      const { recordType } = payload;
      return {
        ...state,
        [recordType]: {
          uploaded: false,
        },
      };
    })
    .addCase(spreadsheetUploaded, (state, { payload }) => {
      const { recordType } = payload;
      const existingState = get(state, [recordType], {});
      return {
        ...state,
        [recordType]: {
          ...existingState,
          ...omit(payload, 'recordType'),
          uploaded: true,
        },
      };
    })
    .addCase(spreadsheetUnload, (state, { payload }) => {
      const { recordType } = payload;
      return {
        ...state,
        [recordType]: {
          failed: false,
          uploaded: false,
        },
      };
    }),
);

export default spreadsheetReducer;

import HTSModal from 'components/HTSModal';
import { store } from 'store';
import { Modal } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

export const showHTSModal = (id, htsNumber) => {
  const modal = Modal.info();
  const itcLink = (
    <a
      href={`https://hts.usitc.gov/?query=${htsNumber}`}
      target="_blank"
      rel="noopener noreferrer"
      className="hts-number-link"
    >
      <LinkOutlined />
    </a>
  );
  const modalUpdate = {
    title: (
      <div>
        {htsNumber} {itcLink}
      </div>
    ),
    content: (
      <HTSModal id={id} number={htsNumber} modal={modal} store={store} />
    ),
    okText: 'Close',
    maskClosable: true,
    width: 550,
  };
  modal.update(modalUpdate);
};

export default showHTSModal;

import {
  WorkOrderTaskFieldConfiguration,
  WorkOrderTaskFieldKey,
  UseWorkOrderTaskConfiguration,
  ReadWriteControlsConfig,
} from 'libs/hooks/workOrderTaskConfiguration/types';
import { WOTConfigurationData } from 'libs/hooks/workOrderTaskConfiguration/wotConfigurationData';
import { useWorkOrderTaskType } from 'libs/hooks/useWorkOrderTaskType';
import { UiStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';

const { stage } = getEnv();

export function useWorkOrderTaskConfiguration(): UseWorkOrderTaskConfiguration {
  const [workOrderTaskType] = useWorkOrderTaskType();
  const readWriteControlsConfig: Record<
    WorkOrderTaskFieldKey,
    WorkOrderTaskFieldConfiguration
  > = {};

  if ([UiStage.PROD].includes(stage)) {
    return { readWriteControlsConfig };
  }

  if (workOrderTaskType && WOTConfigurationData[workOrderTaskType]) {
    const workOrderTask = WOTConfigurationData[workOrderTaskType];
    const readWriteControlsConfig = getReadWriteControlsConfig(
      workOrderTask?.requiredFields,
      workOrderTask?.readOnlyFields,
    );
    return { readWriteControlsConfig };
  }
  return { readWriteControlsConfig };
}

export function getReadWriteControlsConfig(
  required: string[] = [],
  readOnly: string[] = [],
): ReadWriteControlsConfig {
  const readWriteControlsConfig: Record<
    WorkOrderTaskFieldKey,
    WorkOrderTaskFieldConfiguration
  > = {};
  for (const field of required) {
    readWriteControlsConfig[field] = {
      required: true,
    };
  }

  for (const field of readOnly) {
    // append value or create new value if it doesn't exist
    if (readWriteControlsConfig[field]) {
      readWriteControlsConfig[field]['readOnly'] = true;
    } else {
      readWriteControlsConfig[field] = {
        readOnly: true,
      };
    }
  }
  return readWriteControlsConfig;
}

import { VehiclesAndEnginesUsPgaProgramInput } from '__generated__/graphql';
import { EpaValidationArgs } from '../types';
import { CompoundPgaValidator } from 'libs/entryValidation/pga/compoundPgaValidator';
import { PgaFlag } from 'libs/pgaHelpers';

export class VneValidator extends CompoundPgaValidator<VehiclesAndEnginesUsPgaProgramInput> {
  isApplicable({ pgaFlag }: EpaValidationArgs): boolean {
    return PgaFlag.subjectToVehiclesAndEngines(pgaFlag);
  }
}

import { RecordType } from '@xbcb/shared-types';
import { frIorActivationFragments } from 'libs/sharedQueries';
import { EuIorActivation } from '../../loadableComponents';
import euIorActivationRoute from './euIorActivation';

const frIorActivationRoute = euIorActivationRoute({
  page: EuIorActivation,
  recordType: RecordType.FR_IOR_ACTIVATION,
  fields: `...frIorActivationFields`,
  fragments: frIorActivationFragments,
});

export default frIorActivationRoute;

import { RecordType } from '@xbcb/shared-types';
import { validateCbp5106Party } from '@xbcb/ui-utils';
import { AppRecordProps } from 'routes';

const validateUsIor: AppRecordProps['validateUpdate'] = ({
  input,
  currentUser,
}) =>
  validateCbp5106Party({ input, recordType: RecordType.US_IOR, currentUser });

export default validateUsIor;

import {
  CONTINUOUS_BOND_AUTOMATION_FEATURE,
  continuousBondAutomationFeature,
} from '@xbcb/feature-flags';
import { AccountType } from '@xbcb/shared-types';
import { uiStageToBackendStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import { WorkOrderTaskType } from '@xbcb/work-order-types';
import { setModal } from 'actions';
import { ModalKey } from 'types';
import { AppRecordKebabMenuItem } from '../../types';

const isContinuousBondAutomationEnabled =
  CONTINUOUS_BOND_AUTOMATION_FEATURE.isEnabled(
    continuousBondAutomationFeature,
    { stage: uiStageToBackendStage[getEnv().stage] },
  );

export const usIorContinuousBondRequestChargeBondRenewalKebabMenuItem: AppRecordKebabMenuItem =
  {
    key: 'chargeBondRenewal',
    text: 'Charge Bond Renewal',
    show: ({ currentUser, workOrderTaskType }) =>
      currentUser.accountType === AccountType.OPERATOR &&
      isContinuousBondAutomationEnabled &&
      workOrderTaskType ===
        WorkOrderTaskType.US_IOR_CONTINUOUS_BOND_CHARGE_BOND_RENEWAL_EXCEPTION,
    action: ({ dispatch, record }) => {
      dispatch(
        setModal({
          key: ModalKey.CHARGE_CONTINUOUS_BOND_RENEWAL,
          props: {
            usIorContinuousBondRequest: record,
            visible: true,
          },
        }),
      );
    },
  };

import {
  recordFields,
  recordActionFields,
  addressFields,
  costFields,
  externalComplianceReferenceFields,
  phoneFields,
} from '@xbcb/shared-queries';
import {
  iorNumberFields,
  shipperContainerFragment,
  usType86EntryPointOfContactFields,
  type86PgaRequirementFragments,
} from '@xbcb/work-order-queries';
import { inquiryFields } from './inquiryFields';
import { gql } from '@apollo/client';
import { pointOfContactFields } from '@xbcb/party-queries';

export const SEARCH_US_TYPE86_ENTRIES = gql`
  query SearchUsType86Entries($input: SearchUsType86EntryInput!) {
    searchUsType86Entries(input: $input) {
      results {
        id
        version
        totalValue
        totalAssist
        entryNumber
        ior {
          name
        }
      }
    }
  }
`;

const continuousBondFields = `
  {
    number
    effectiveDate
    renewalDate
    terminationDate
    suretyCode
  }
`;

export const usType86EntryBaseFragment = `fragment usType86EntryBaseFields on UsType86Entry {
  ...recordFields
  operator {
    id
  }
  ior {
    name
    address {
      ...addressFields
    }
    iorNumber {
      ...iorNumberFields
    } 
    usIor {
      id
      version
    }
    pointOfContact {
      ...usType86EntryPointOfContactFields
    }
  }
  consignee {
    name
    address {
      ...addressFields
    }
    iorNumber {
      ...iorNumberFields
    }
    sameAsIor
    usConsignee {
      id
      version
    }
  }
  buyer {
    ein
    name
    address {
      ...addressFields
    }
    sameAsIor
    buyer {
      id
      version
    }
  }
  status
  releaseStatus 
  releaseDate
  releaseDispositions {
    billNumber
    code
  }
  releaseRejectionReasons {
    name
    code
  }
  pgaDispositions {
    status
    code
  }
  splitShipmentReleaseMethod
  cbpTeam
  collectionStatus
  entryNumber
  brokerNumber
  entryType
  invoices {
    invoiceNumber
    value {
      ...costFields
    }
    assist {
      ...costFields
    }
    currencyRate
    seller {
      name
      address {
        ...addressFields
      }
      mid
      supplier {
        id
        version
      }
    }
    products {
      quantity
      totalValue {
        ...costFields
      }
      totalAssist {
        ...costFields
      }
      product {
        id
        version
      }
      lines {
        adDisclaimerCode
				cvDisclaimerCode
        externalReference {
          ...externalComplianceReferenceFields
        }
        value
        assist
        description
        ruling {
          type
          number
        }
        poNumber
        spi
        licenseCode
        licenseNumber
        steelExclusionNumber
        aluminumExclusionNumber
        productClaimCode
        origin {
          countryCode
          stateCode
        }
        feeOptions {
          required
          disclaim
          type
        }
        taxOptions {
          required
          disclaim
          type
          defer
          rate
        }
        manufacturer {
          name
          address {
            ...addressFields
          }
          mid
          supplier {
            id
            version
          }
        }
        tariffs {
          unitValue {
            value
            currency
          }
          unitAssist {
            value
            currency
          }
          unitReportingQuantities {
            value
            unit
          }
          pga {
            ...type86PgaRequirementFields
          }
          htsNumber
          hts {
            id
            version
          }
          extraPgaFlags
        }
      }
    } 
  }
  masterBills {
    number
    houseBills {
      number
      quantity
      inBondNumber
    }
    billOnFile
  }
  containers {
    ...shipperContainerFields
  }
  departure {
    exportDate
    exportCountryCode
    portOfLadingCode
  }
  conveyance {
    modeOfTransport
    containerized
    conveyanceName
    tripNumber
    grossWeight
  }
  arrival {
    portOfUnladingCode
    firmsCode
    preferredExamSite
    goNumber
    importDate
    usDestinationStateCode
    inBond {
      portOfEntryCode
      estimatedEntryDate
      initiationDate
    }
  }
  broker {
    name
    address {
      ...addressFields
    }
    usCustomsBroker {
      id
    }
  }
  loadType
  group {
    id
    version
    shipper {
      id
      version
    }
    forwarders {
      forwarder {
        id
        version
      }
    }
    tags {
      key
      value
    }
  }
  stateMachine {
    id
    version
    workOrderType
  }
  milestones {
    id
    name
    occurenceTime
    source
    tags {
      key
      value
    }
  }
  exam {
    site
    status 
    subType
    type
    containers {
      ...shipperContainerFields
    }
  }
  }`;

export const usType86EntryFragment = `fragment usType86EntryFields on UsType86Entry {
  ...usType86EntryBaseFields
  ior {
      usIor {
      id
      version
      status
      continuousBonds {
        past ${continuousBondFields}
        present ${continuousBondFields}
        future ${continuousBondFields}
      }
    }
  }
  group {
    id
    shipment {
      id
      friendlyId
      clientIdentifier
      preferredDutyCollectPartyType
      version
      legs {
        transportationServiceName
      }
      clientReferences {
        bookingId
      }
      tags {
        key
        value
      }
    }
    shipper {
      id
      status
      billingDetails {
        disbursement
        subscriptionPlan
        status
      }
      forwarders {
        nodes {
          id
          billingDetails {
            disbursement
            subscriptionPlan
          }
        }
      }
    }
    forwarders {
      forwarder {
        id
        billingDetails {
          disbursement
          subscriptionPlan
          status
        }
      }      
    }
  }
  stateMachine {
    id
    version
    workOrderType
  }
  milestones {
    created {
      client {
        name
      }
    }
  }
  ${inquiryFields}
}`;

const sharedType86Fragments = `
${usType86EntryBaseFragment}
${recordFields}
${recordActionFields}
${addressFields}
${iorNumberFields}
${shipperContainerFragment}
${usType86EntryPointOfContactFields}
${costFields}
${pointOfContactFields}
${phoneFields}
${type86PgaRequirementFragments}
${externalComplianceReferenceFields}`;

export const usType86EntryFragments = `
${usType86EntryFragment}
${sharedType86Fragments}`;

export const usType86EntrySubscriptionFragments = `
${sharedType86Fragments}`;

import { memoFragments } from '@xbcb/client-queries';
import { AccountType } from '@xbcb/shared-types';
import { AppRecordProps, AppRecordRoute } from '../../types';
import { appRecordMemoMenuItem } from '../utils';

type EuIorRoute = Pick<
  AppRecordProps,
  'recordType' | 'fields' | 'fragments'
> & {
  page: React.FC<any>;
};

const euIorRoute = ({
  page,
  recordType,
  fields,
  fragments,
}: EuIorRoute): AppRecordRoute => {
  return {
    Page: page,
    recordType,
    fields:
      fields +
      `...recordFields
        operator { id }
        memos { ...memoFields }
        shippers {
          nodes {
            id
            forwarders {
              nodes {
                id
                name
                supportsPersonalEffects
              }
            }
          }
        }
      `,
    fragments: `${fragments || ''}${memoFragments}`,
    kebabMenuItems: [appRecordMemoMenuItem],
    // Forwarder and OPERATOR users should not be able to edit the IOR, i.e. let's hide the
    // edit button when they are logged in
    hideDefaultEditButton: ({ accountType }) => {
      return (
        accountType === AccountType.FORWARDER ||
        accountType === AccountType.OPERATOR
      );
    },
    // Forwarder users should not be able to delete the IOR, i.e. let's hide the
    // delete kebab menu item when they are logged in
    hideDeleteKebabMenuItem: ({ accountType }) =>
      accountType === AccountType.FORWARDER,
  };
};

export default euIorRoute;

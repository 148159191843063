export enum AmsIndicatorOptionsConfiguration {
  AMS = 'AMS',
  NON_AMS = 'NON_AMS',
}

// These are the strings that will be displayed in the UI
export const amsIndicatorOptionsDisplayMap = {
  [AmsIndicatorOptionsConfiguration.AMS]: 'AMS',
  [AmsIndicatorOptionsConfiguration.NON_AMS]: 'Non-AMS',
};

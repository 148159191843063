import { WorkOrderRoute } from '.';
import { RecordType } from '@xbcb/shared-types';
import { NlCustomsEntry } from '../../loadableComponents';
import { nlCustomsEntryFragments } from '@xbcb/work-order-queries';
import {
  getSharedCustomsEntrySubmitButtons,
  sharedCustomsEntryKebabMenuItems,
  sharedCustomsEntryFields,
} from '../utils';
import { WorkOrderType } from '@xbcb/work-order-types';
import { transformEuCustomsEntry } from 'libs/formTransforms/workOrder/euCustomsEntry';
import CustomsEntrySecondaryHeader from 'components/CustomsEntrySecondaryHeader';

const nlCustomsEntryFields = `
  ...nlCustomsEntryFields
`;

const nlCustomsEntryRoute: WorkOrderRoute = {
  Page: NlCustomsEntry,
  recordType: RecordType.NL_CUSTOMS_ENTRY,
  fields: `${sharedCustomsEntryFields}${nlCustomsEntryFields}`,
  fragments: nlCustomsEntryFragments,
  SecondaryHeaderContents: [CustomsEntrySecondaryHeader],
  transformUpdateRecordInput: transformEuCustomsEntry.toSchema,
  defaultEditMode: false,
  kebabMenuItems: sharedCustomsEntryKebabMenuItems,
  submitButtons: getSharedCustomsEntrySubmitButtons(
    WorkOrderType.NlCustomsEntry,
  ),
};

export default nlCustomsEntryRoute;

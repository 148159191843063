import styled from 'styled-components';
import { Button } from 'antd';
export const StyledTitleDiv = styled.div`
  display: flex;
  align-items: center;
  float: right;
`;

export const StyledButton = styled(Button)`
  position: relative;
  left: 5px;
  top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { createAction } from '@reduxjs/toolkit';
import {
  OPEN_PRODUCT,
  CLOSE_PRODUCT,
  CLOSE_ALL_EXCEPT,
  OpenProductsState,
} from '../types';

export const openProduct = createAction<OpenProductsState>(OPEN_PRODUCT);

export const closeProduct = createAction<OpenProductsState>(CLOSE_PRODUCT);

export const closeAllExcept = createAction<OpenProductsState>(CLOSE_ALL_EXCEPT);

import { createReducer } from '@reduxjs/toolkit';
import { setReferencedRecord } from 'actions/referencedRecords';
import { encryptRecord } from 'libs/encryptDecryptReferencedRecord';
import { DateTime } from 'luxon';
import { ReferencedRecordsState } from 'types';

const defaultState: ReferencedRecordsState = {};

const referencedRecordsReducer = createReducer(defaultState, (builder) =>
  builder.addCase(setReferencedRecord, (state, { payload }) => {
    const { referenceField, referenceKey, record } = payload;
    const encryptedRecord = encryptRecord(record);
    if (!state[referenceField]) {
      state[referenceField] = {};
    }
    state[referenceField][referenceKey] = {
      record: encryptedRecord,
      time: DateTime.now().toISO() as string,
    };
  }),
);

export default referencedRecordsReducer;

import { gql } from '@apollo/client';
import { getOneQueryLite } from '@xbcb/shared-queries';
import { client as apolloClient } from '@xbcb/apollo-client';
import { RecordType } from '@xbcb/shared-types';
import { get } from 'lodash';

const fragments = gql`
  fragment recordActionFields on RecordAction {
    source {
      __typename
      ... on InternalSystem {
        internalSystemName: name
      }
      ... on InternalWebsite {
        internalWebsiteName: name
      }
      ... on ClientSystem {
        clientSystemName: name
      }
    }
    time
    reason {
      code
      text
    }
    client {
      id
    }
    request {
      ipAddress
      userAgent
      requestId
    }
  }

  fragment recordFields on Record {
    created {
      ...recordActionFields
    }
    updated {
      ...recordActionFields
    }
    deleted {
      ...recordActionFields
    }
    id
    version
    tags {
      key
      value
    }
  }
`;

export const queryImporterEntityActivation = gql`
  query ImporterEntityActivation($id: ID!) {
    importerEntityActivation(id: $id) {
      id
      version
      status
      deadline
      broker {
        id
      }
      milestones {
        tags {
          key
          value
        }
        id
        name
        occurenceTime
        source
        created {
          client {
            name
          }
        }
      }
      tasks {
        ...recordFields
        name
        workOrderType
        status
        assignee {
          id
          name
        }
        deadline
        steps {
          id
          name
          description
          isCompletedBySystem
          isFinalConfirmation
          completed {
            ...recordActionFields
          }
        }
        definition {
          id
          estimatedDuration
          workOrderTaskType
        }
      }
      group {
        documents {
          documentTags
        }
        customsBroker {
          tags {
            key
            value
          }
        }
        forwarders {
          forwarder {
            id
            name
            billingDetails {
              disbursement
              status
              subscriptionPlan
            }
          }
        }
        shipment {
          id
          clientReferences {
            bookingId
          }
          legs {
            transportationServiceName
          }
          preferredDutyCollectPartyType
        }
        invoices {
          id
        }
        id
        shipper {
          id
          shipperExternallySourcedDetails {
            externalReferenceId
          }
          billingDetails {
            disbursement
            status
            subscriptionPlan
          }
        }
      }
      countryCode
      importerEntity {
        id
        version
        activations {
          activated {
            time
          }
          status
          externalIorReferences {
            id
            referenceType
            version
            documents {
              documentTag
              externalReference {
                externalReferenceType
                externalReferencePartition
                id
                version
              }
            }
          }
          customsBroker {
            id
          }
        }
        externalClientReferences
        importer {
          addressList {
            type
            cityName
            country
            countrySubDivision
            countrySubDivisionName
            line
            postCode
            streetName
            houseNumber
            houseNumberAddition
            postOfficeBox
          }
          externalClientReferences
          id
          name
          identifiers {
            identification
            identificationType
          }
          phone {
            country
            extension
            number
          }
        }
        identifiers {
          identification
          identificationType
        }
        pointOfContact {
          email
          name
          phone {
            country
            extension
            number
          }
          title
          user {
            id
            name
          }
        }
        updated {
          time
        }
      }
    }
  }
  ${fragments}
`;

export const updateImporterEntityActivation = gql`
  mutation UpdateImporterEntityActivation(
    $id: ID!
    $version: Int!
    $input: UpdateImporterEntityActivationInput!
  ) {
    updateImporterEntityActivation(input: $input, version: $version, id: $id) {
      record {
        id
        version
      }
    }
  }
`;

export const upsertImporterEntityActivationMutation = () => {
  return gql`
    mutation upsertImporterEntityActivation(
      $id: ID!
      $version: Int!
      $input: UpsertImporterEntityActivationInput!
    ) {
      upsertImporterEntityActivation(
        id: $id
        version: $version
        input: $input
      ) {
        importerEntity {
          id
          version
        }
      }
    }
  `;
};

export const getImporterEntityVersion = async (importerEntityId: string) => {
  const importerEntityVersionQuery = getOneQueryLite({
    recordName: RecordType.IMPORTER_ENTITY,
    fields: 'version',
    queryName: `getImporterEntityVersion`,
  });

  const { data } = await apolloClient.query({
    query: importerEntityVersionQuery,
    variables: { id: importerEntityId },
    errorPolicy: 'all',
  });
  const version = get(data, [`importerEntity`, `version`]);
  return version;
};

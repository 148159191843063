import React from 'react';
import { Input } from 'antd';
import './TradeName.less';
import { FormItem } from '@xbcb/form-item-components';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { useWorkOrderTaskConfiguration } from 'libs/hooks';

/* for PG07 */
/* TODO: Add a tooltip saying to use proper name if not trade name is available */

interface TradeNameProps {
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  localNamePath: NamePath;
  size?: string;
}

const TradeName = ({
  localNamePath,
  required,
  disabled,
  readOnly,
  size = CssSize.MEDIUM,
}: TradeNameProps) => {
  const { readWriteControlsConfig } = useWorkOrderTaskConfiguration();
  return (
    <FormItem
      $itemSize={size}
      className="trade-name"
      label="Trade Name/Brand Name"
      name={[...localNamePath, 'tradeName']}
      rules={[{ required, message: ' ' }]}
      $readOnly={
        readOnly ||
        !!readWriteControlsConfig['invoices.products.lines.tariffs.pga']
          ?.readOnly
      }
    >
      <Input disabled={disabled} />
    </FormItem>
  );
};

export default TradeName;

import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledParagraph = styled.p<{
  $spaceTop?: boolean;
  $removeSpaceBottom?: boolean;
}>`
  margin-bottom: ${({ $removeSpaceBottom }) =>
    $removeSpaceBottom ? '0' : CssSpace.SPACE_4};
  ${({ $spaceTop }) => $spaceTop && `margin-top: ${CssSpace.SPACE_4}`};
`;

export const StyledHeader = styled.h2`
  margin-bottom: ${CssSpace.SPACE_4};

  img {
    vertical-align: top;
    margin-right: ${CssSpace.SPACE_3};
  }
`;

import { SEARCH_ASSIGNMENT_TEAMS } from 'libs/sharedQueries';
import { client as apolloClient } from '@xbcb/apollo-client';
import { ApolloQueryResult } from '@apollo/client';
import { AnyObject } from '@xbcb/shared-types';

export const fetchAssignmentTeams = async (
  searchCriteria: AnyObject,
): Promise<ApolloQueryResult<AnyObject>> => {
  const response = await apolloClient.query({
    query: SEARCH_ASSIGNMENT_TEAMS,
    variables: {
      input: {
        searchCriteria,
      },
    },
  });
  return response;
};

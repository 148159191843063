/**
 * Downloads content from the URL to the user
 * @param {string} url - the URL from which to download the file
 * @param {string} fileName - optional - the name to give to the downloaded file
 */
export const downloadURL = (url: string, fileName?: string): void => {
  const a = document.createElement('a');
  a.setAttribute('style', 'display: none');
  a.href = url;
  if (fileName) a.setAttribute('download', fileName);
  a.rel = 'noopener noreferrer';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

// TODO This should return status success/failure
/**
 * Creates a URL out of passed in data and downloads it to the user.  A blob
 * is created from the data string, which is turned into a CSV to be downloaded.
 * @param {string} data - the string of information to be turned into a blob
 * @param {string} fileName the name to give to the downloaded file
 */
const download = (data: string, fileName: string): void => {
  const blob = new Blob(['\ufeff', data], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  downloadURL(url, fileName);
};
export default download;

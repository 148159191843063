import { Input } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { FormItem } from '@xbcb/form-item-components';
import { SingleFormCard } from '@xbcb/display-components';
import { getNumber } from '@xbcb/ui-utils';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import LineItems from 'components/LineItems';
import ShipperSelect from 'components/ShipperSelect';
import { StyledFormInlineDiv, StyledInput } from './styles';
import { useTheme } from 'styled-components';
import './Product.less';
import { ProductSecondaryIdentifierType } from '@xbcb/compliance-types';

type ProductPageContentsProps = {
  form: FormInstance;
  record?: AnyObject;
  disabled?: boolean;
  readOnly?: boolean;
};

const ProductPageContents: React.FC<ProductPageContentsProps> = ({
  form,
  record,
  disabled,
  readOnly,
}) => {
  const asinIndex = Math.max(
    record?.secondaryIdentifiers?.findIndex(
      (si: { type: ProductSecondaryIdentifierType }) =>
        si.type === ProductSecondaryIdentifierType.ASIN,
    ) || 0,
    0,
  );
  const asinPath = ['secondaryIdentifiers', asinIndex];
  const theme = useTheme();
  return (
    <>
      <ShipperSelect
        disabled={disabled}
        readOnly={readOnly || Boolean(record?.id)}
        form={form}
        recordId={record?.id}
        recordType={RecordType.PRODUCT}
        multiple={false}
      />

      <SingleFormCard title="Basic Info">
        <StyledFormInlineDiv>
          <FormItem
            label="Item Number"
            name={['clientIdentifier']}
            rules={[{ required: true, message: ' ' }]}
            $itemSize={theme.size.short}
            $readOnly={readOnly}
          >
            <StyledInput disabled={disabled} maxLength={100} />
          </FormItem>
          <FormItem
            label="Display Name"
            name={['localizedDetails', 0, 'name']}
            rules={[{ required: false, message: ' ' }]}
            $itemSize={theme.size.short}
            $readOnly={readOnly}
          >
            <StyledInput disabled={disabled} maxLength={100} />
          </FormItem>
          <Form.Item
            name={['secondaryIdentifiers', asinIndex, 'type']}
            initialValue={ProductSecondaryIdentifierType.ASIN}
            hidden={true}
          />
          <FormItem
            label="ASIN"
            name={[...asinPath, 'value']}
            $itemSize={theme.size.short}
            rules={[{ required: false, message: ' ' }]}
            $readOnly={readOnly}
          >
            <StyledInput disabled={disabled} maxLength={100} />
          </FormItem>
        </StyledFormInlineDiv>
        <StyledFormInlineDiv>
          <FormItem
            label="UPC Number"
            name={['upc']}
            rules={[{ required: false, message: ' ' }]}
            getValueFromEvent={getNumber(12)}
            $itemSize={theme.size.short}
            $readOnly={readOnly}
          >
            <StyledInput
              placeholder="NNNNNNNNNNNN"
              disabled={disabled}
              maxLength={12}
            />
          </FormItem>
        </StyledFormInlineDiv>
        <FormItem
          label="Notes"
          name={['notes']}
          rules={[{ required: false, message: ' ' }]}
          $itemSize={theme.size.medium}
          $readOnly={readOnly}
        >
          <Input.TextArea disabled={disabled} autoSize maxLength={1000} />
        </FormItem>
      </SingleFormCard>
      <LineItems
        form={form}
        readOnly={readOnly}
        disabled={disabled}
        // TODO: pass consumptionEntry to LineItems
        consumptionEntry=""
        importerId={record?.importerId}
        fullNamePath={['complianceDetails', 'us', 'entryLines']}
        recordType={RecordType.PRODUCT}
      />
    </>
  );
};

export default ProductPageContents;

import { HtsObject } from 'types';

export const section232AluminumInfoEffectiveDate = new Date('2023-05-10');

export const isSection232AluminumSmeltAndCastApplicableForAnyTariff: (
  htsObjects: (HtsObject | undefined)[],
  estimatedImportDate: Date | undefined,
) => boolean = (htsObjects, estimatedImportDate) => {
  return htsObjects.some((tariff) =>
    Boolean(
      containsAdditionalHtsWithSection232AluminiumType(tariff) &&
        estimatedImportDateIsOnOrAfterEffectiveDate(estimatedImportDate),
    ),
  );
};

const containsAdditionalHtsWithSection232AluminiumType: (
  hts?: HtsObject,
) => boolean = (hts) => {
  return Boolean(
    hts?.additional?.some(
      (additionalHts) => section232AluminiumTariffType === additionalHts?.type,
    ),
  );
};

const estimatedImportDateIsOnOrAfterEffectiveDate: (
  estimatedImportDate: Date | undefined,
) => boolean = (estimatedImportDate) => {
  return Boolean(
    estimatedImportDate &&
      estimatedImportDate >= section232AluminumInfoEffectiveDate,
  );
};

export const section232AluminiumTariffType = 'SECTION_232_ALUMINIUM';

import React from 'react';
import { ModalProps } from 'antd/lib/modal';
import Consignee from 'components/Consignee';
import UsIor from 'components/UsIor';
import Forwarder from 'components/Forwarder';
import Supplier from 'components/Supplier';
import Trucker from 'components/Trucker';
import CustomsAgent from 'components/CustomsAgent';
import Facility from 'components/Facility';
import Product from 'components/Product';
import { RecordType } from '@xbcb/shared-types';
import AppRecordCreate from 'components/AppRecordCreate';
import { StyledModal } from './styles';
import { transformParty, transformProduct } from 'libs/formTransforms';
import { TransformCreateRecordInput } from 'routes';
import { titleFields } from 'libs/display';

const componentMap = {
  [RecordType.FORWARDER]: {
    formComponent: Forwarder,
    formTransform: transformParty.toSchema,
  },
  [RecordType.SUPPLIER]: {
    formComponent: Supplier,
    formTransform: transformParty.toSchema,
  },
  [RecordType.TRUCKER]: {
    formComponent: Trucker,
    formTransform: transformParty.toSchema,
  },
  [RecordType.FACILITY]: {
    formComponent: Facility,
    formTransform: transformParty.toSchema,
  },
  [RecordType.PRODUCT]: {
    formComponent: Product,
    formTransform: transformProduct.toCreateSchema,
  },
  [RecordType.US_CONSIGNEE]: {
    formComponent: Consignee,
    formTransform: transformParty.toSchema,
  },
  // TODO should this be removed now?
  [RecordType.US_IOR]: {
    formComponent: UsIor,
    formTransform: transformParty.toSchema,
  },
  [RecordType.CUSTOMS_AGENT]: {
    formComponent: CustomsAgent,
    formTransform: transformParty.toSchema,
  },
};

export type CreateRecordProps = {
  recordType: RecordType;
  visible?: boolean;
  onCancel: ModalProps['onCancel'];
  onCreate?: (value: any) => void;
  shipperId?: string;
};

const CreateRecord = ({
  recordType,
  visible,
  onCreate,
  onCancel,
  shipperId,
}: CreateRecordProps) => {
  const { formComponent: RecordForm, formTransform } = componentMap[
    recordType as keyof typeof componentMap
  ] as { formComponent: React.FC; formTransform: TransformCreateRecordInput };

  const titleField = titleFields[
    recordType as keyof typeof titleFields
  ] as string;
  const fields = `id version ${titleField}`;

  return (
    <StyledModal
      maskClosable
      open={visible}
      width={1024}
      destroyOnClose
      footer={null}
      wrapClassName="create-record"
      onCancel={onCancel}
    >
      <AppRecordCreate
        recordType={recordType}
        quickCreate
        onCreate={onCreate}
        transformCreateRecordInput={formTransform}
        fields={fields}
        shipperId={shipperId}
      >
        <RecordForm />
      </AppRecordCreate>
    </StyledModal>
  );
};

export default CreateRecord;

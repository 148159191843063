import { WorkOrderRoute } from '.';
import { RecordType } from '@xbcb/shared-types';
import { DeCustomsEntry } from '../../loadableComponents';
import { deCustomsEntryFragments } from '@xbcb/work-order-queries';
import {
  getSharedCustomsEntrySubmitButtons,
  sharedCustomsEntryKebabMenuItems,
  sharedCustomsEntryFields,
} from '../utils';
import { WorkOrderType } from '@xbcb/work-order-types';
import { transformEuCustomsEntry } from 'libs/formTransforms/workOrder/euCustomsEntry';
import CustomsEntrySecondaryHeader from 'components/CustomsEntrySecondaryHeader';

const deCustomsEntryFields = `
  ...deCustomsEntryFields
`;

const deCustomsEntryRoute: WorkOrderRoute = {
  Page: DeCustomsEntry,
  recordType: RecordType.DE_CUSTOMS_ENTRY,
  fields: `${sharedCustomsEntryFields}${deCustomsEntryFields}`,
  fragments: deCustomsEntryFragments,
  SecondaryHeaderContents: [CustomsEntrySecondaryHeader],
  transformUpdateRecordInput: transformEuCustomsEntry.toSchema,
  defaultEditMode: false,
  kebabMenuItems: sharedCustomsEntryKebabMenuItems,
  submitButtons: getSharedCustomsEntrySubmitButtons(
    WorkOrderType.DeCustomsEntry,
  ),
};

export default deCustomsEntryRoute;

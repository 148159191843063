import { conformToMask } from 'text-mask-core';

export default class {
  constructor() {
    this.previousValue = '';
  }

  handleChange(val) {
    if (!val) return val || undefined;
    // we allow this specific value 'UNK' for intendedUse
    if (val === 'UNK') {
      return val;
    }
    const mask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/];
    const result = conformToMask(val, mask, {
      guide: false,
      previousConformedValue: this.previousValue,
    }).conformedValue;
    this.previousValue = result;
    return result || undefined;
  }
}

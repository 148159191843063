/** Unit of Measurements that have associated default unit quantity values in Commericial Invoice Product Summary
BRD Link: https://share.amazon.com/sites/GlobalSelling/_layouts/15/WopiFrame2.aspx?sourcedoc=%7BAA2CFA1E-A687-469B-8E47-CEB90C42D5BA%7D&file=BRD_Compliance-Improvements_v3.0.docx&action=default (Epic 2)
**/

export const defaultUOM = ['DOZ', 'DPR', 'GR', 'NO', 'HUN', 'K'];

// Allow editing of UOM for certain HTS numbers as per
// https://t.corp.amazon.com/V712470115/communication#bcb46825-aca9-4cf3-b2f9-5a3dade8241a
export const isUOMEditAllowedForHts = (htsNumber: string) => {
  const uomAllowedForHtsChapters = ['91'];
  return uomAllowedForHtsChapters.includes(htsNumber.substring(0, 2));
};

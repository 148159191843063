import styled from 'styled-components';
import { AppRadioGroup, FormItem } from '@xbcb/form-item-components';

export const StyledDrugFormRadioGroup = styled(AppRadioGroup)`
  .ant-radio-group {
    .finished {
      width: 195px;
    }

    .api {
      width: 430px;
    }
  }
`;

export const StyledDeviceFormRadioGroup = styled(AppRadioGroup)`
  .ant-radio-group {
    .component {
      width: 115px;
    }

    .finished {
      width: 155px;
    }
  }
`;

export const StyledFdaFormDiv = styled.div`
  .exempt-checkbox {
    display: inline-block;
    margin: var(--space-4) 0 0 ${({ theme }) => theme.spacing.space_4};
  }
  .ant-input-number {
    width: 100%;
  }
  .inline-tooltip {
    margin-left: ${({ theme }) => theme.spacing.space_4};
  }
  .performance-standard * {
    color: black;
  }
`;

export const StyledFlexDiv = styled.div`
  display: flex;
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0;
`;

export const StyledParagraph = styled.p`
  a {
    color: ${({ theme }) => theme.palette.neutrals.snow};
    text-decoration: underline;
  }
`;

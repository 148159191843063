import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { Form, Select, Modal } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { Option, HiddenField } from '@xbcb/form-item-components';
import CreateRecord from 'components/CreateRecord';
import { titleFields } from 'libs/display';
import { pascalCase, paramCase } from 'change-case';
import { formatRecordName, pluralize } from '@xbcb/js-utils';
import { useHistory } from 'react-router-dom';
import {
  show,
  shouldUpdate,
  getCountryCodes,
  useModal,
  usePrevious,
  safeGetMessage,
} from '@xbcb/ui-utils';
import { getRecordType } from '@xbcb/core';
import {
  RecordType,
  AccountType,
  isUser,
  AnyObject,
  KeywordSearchOperator,
  DateSearchOperator,
  PartyRecordType,
} from '@xbcb/shared-types';
import { last, get, debounce, omit } from 'lodash';
import { fetchRecordSelectOptions, getShouldFetchAlternateName } from './query';
import { useApolloClient, useQuery, useLazyQuery } from '@apollo/client';
import {
  CssSize,
  NamePath,
  DataCyPrefix,
  createDataCyValue,
} from '@xbcb/ui-types';
import { getOneQueryLite, getRecordFromResponse } from '@xbcb/shared-queries';
import {
  addressesFields,
  usContinuousCustomsBondFragment,
} from '@xbcb/party-queries';
import { useCurrentUser } from 'libs/hooks';
import {
  createModalKey,
  RequiredEmbeddedField,
  RecordSelectOption,
} from 'types';
import {
  StyledFormItem,
  StyledButton,
  StyledSpan,
  StyledSelectOutlined,
} from './styles';
import { isAGLForwarder } from '@xbcb/party-utils';
import { useBundle } from '@amzn/react-arb-tools';

// Creator function used for the options rendered by RecordSelect so we standardize the data-cy prop
const createOption = ({
  key,
  value,
  label,
  suffix,
}: {
  key: string | number;
  value: string;
  label: React.ReactNode;
  suffix?: string;
}) => {
  return (
    <Option
      key={key}
      value={value}
      data-cy={createDataCyValue(
        DataCyPrefix.RECORD_SELECT_OPTION,
        suffix ? key.toString() + '-' + suffix : key.toString(),
      )}
    >
      {label}
    </Option>
  );
};

// TODO type something and only search by that.
type RecordSelectProps = {
  recordType: RecordType;
  // extraRecordType?: RecordType;
  includeShipper?: boolean;
  form: FormInstance;
  getExcludedOptions?: () => string[];
  localNamePath?: NamePath;
  create?: boolean;
  label?: string;
  multiple?: boolean;
  horizontal?: boolean;
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  // extraIsLoading?: boolean;
  readOnly?: boolean;
  unassignedId?: string;
  hideLabel?: boolean;
  // NOTE: if not provided, the width must be assigned manually
  $itemSize?: CssSize | string;
  showAddress?: boolean;
  showName?: boolean;
  onChange?: any;
  accountType?: AccountType;
  dropdownStyle?: any;
  targetBlank?: boolean;
  adding?: any;
  fullNamePath: NamePath;
  // Note: if `embeddedFields` are provided, the record will always be `versioned`
  versioned?: boolean;
  embeddedFields?: string; // the "snapshot-ed" fields to be set next to the record id and version
  mapEmbeddedFields?: (embeddedFieldsResult: any) => any; // The consumer of mapEmbeddedFields gets latitude to map embedded fields however they like. They provided fields are specified in the embeddedFields prop.
  onCompletedEmbeddingFields?: (values: any) => Promise<void> | void;
  usOnly?: boolean;
  // Customs function to generate the label for the dropdown. By default it just uses the title field.
  getCustomLabel?: (value: any) => string;
  // Additional attributes to be fetched from the record search, that might be required for customLabelFn or any other custom fn.
  additionalSearchFields?: string;
  // Additional search fields that are used in the searchCriteriaInput
  // for the table query.
  additionalSearchCriteria?: AnyObject;
  // Defaults to True unless hideLabel is true
  includeHyperlinkInLabel?: boolean;
  // Provide a fullNamePath to the embedded fields that are required in order
  // to ensure they are set after a record is selected. If they are not set, a
  // modal will prompt the user to set them and then refresh the RecordSelect.
  // Optionally, a `validator` function can be provided instead of the default
  // presence check.
  requiredEmbeddedFields?: RequiredEmbeddedField[];
  // If true would select the record if its the only record. (record.length ===1)
  selectOneRecordByDefault?: boolean;
  // Would disable the record select if there is only record to be choosen. Works best in conjunction to selectFirstRecordByDefault.
  disableSelectionForSingleRecord?: boolean;
  // represent additional fields to fetch beyond just `id` / `version` (or
  // provided `embeddedFields` and/or `additionalSearchFields`) for the
  // selected record. These will be fetched after loading the initial record or
  // selecting a record rather than as we search for records. This can be used
  // for fields like `tags` which are not represented on the search table row
  // for records.
  fieldsToFetchForSelectedRecord?: string;
  $removeSpaceBottom?: boolean;
  $removeSpaceLeft?: boolean;
  // Indicates whether the search results should be sorted or not (default = true)
  isSorted?: boolean;
  // In rare cases where we are only visually showing the already selected
  // record, we may not want to search at all for any other records. One
  // example use case would be when signed in as a shipper user, we don't want
  // to search for other shippers in the RecordSelect because they should not
  // be able to switch shippers. Additionally, if we allow search the firewall
  // will fail as shippers are not allowed to search for other shippers
  skipSearch?: boolean;
  dataCySuffix?: string;
  // In some cases where there are multiple RecordSelects on the same component
  // of the same record type, an additional suffix is needed for the data-cy
  // value for each option to be unique accross the component.
  dataCyOptionSuffix?: string;
  // In some use cases we don't want right margin on the refresh button. This
  // would be the case in situations where no more content is showed "inline"
  // with the FormItem (containing the selected record) and we want the
  // FormItem to be as wide as possible without exceeding the width of the
  // parent element (for example BcoCard).
  $removeRefreshSpaceRight?: boolean;
  // In scenarios where updating embeded fields might be required, even though
  // the record select is disabled, set forceAlowRefresh to be true.
  forceAlowRefresh?: boolean;
  notFoundText?: string;
  notFoundAction?: (value: string) => void;
  placeHolder?: string;
  maxTagCount?: number;
  pageSize?: number;
  createRefetchSearchCriteriaOverride?: (value?: string) => AnyObject;
  // Allow overriding the logic that clears embedded fields when the Select is cleared
  onClearEmbeddedFields?: () => void;
};

// NOTE: Do not use as a styled component. It prevents CSS from being applied properly.
const RecordSelect: React.FC<RecordSelectProps> = ({
  recordType,
  includeShipper,
  form,
  getExcludedOptions,
  localNamePath,
  create,
  label,
  multiple,
  horizontal,
  required,
  disabled,
  isLoading,
  readOnly,
  unassignedId,
  hideLabel,
  $itemSize,
  showAddress,
  showName,
  onChange,
  accountType: providedAccountType,
  dropdownStyle,
  targetBlank,
  adding,
  fullNamePath,
  versioned,
  embeddedFields = '',
  mapEmbeddedFields,
  onCompletedEmbeddingFields,
  usOnly,
  getCustomLabel,
  additionalSearchFields,
  additionalSearchCriteria,
  includeHyperlinkInLabel = true,
  requiredEmbeddedFields,
  selectOneRecordByDefault,
  disableSelectionForSingleRecord,
  fieldsToFetchForSelectedRecord,
  $removeSpaceBottom,
  $removeSpaceLeft,
  isSorted = true,
  skipSearch,
  dataCySuffix,
  dataCyOptionSuffix,
  $removeRefreshSpaceRight,
  forceAlowRefresh = false,
  notFoundText,
  notFoundAction,
  placeHolder,
  maxTagCount,
  pageSize = 100,
  createRefetchSearchCriteriaOverride,
  onClearEmbeddedFields,
}) => {
  const [recordSelectBundle] = useBundle('components.RecordSelect');
  const [sharedBundle] = useBundle('shared');
  const currentUser = useCurrentUser();
  const accountType =
    providedAccountType || (currentUser.accountType as AccountType);
  const [createdRecord, setCreatedRecord] = useState();
  const history = useHistory();
  const [version, setVersion] = useState();
  const [enteredText, setEnteredText] = useState('');

  // TODO refactor or consolidate into a shared lib if we like the fragment injection pattern
  let fragments = '';
  if (embeddedFields.includes('...addressesFields')) {
    fragments += ` ${addressesFields}`;
  }
  if (embeddedFields.includes('...usContinuousCustomsBondFields')) {
    fragments += ` ${usContinuousCustomsBondFragment}`;
  }
  // We always want to query for version so that we can update the record to
  // the latest id and version
  if (embeddedFields) embeddedFields += ` version`;

  // cant use useLazyQuery and fetchPolicy: 'network-only' due to https://github.com/apollographql/react-apollo/issues/3355
  // The above referenced issue belongs to a now-archived repository... perhaps it's now fixed.
  const client = useApolloClient();
  const [
    recordSelectEmbeddedFieldsLoading,
    setRecordSelectEmbeddedFieldsLoading,
  ] = useState(false);

  const versionFullNamePath = useMemo(
    () => [...fullNamePath, 'version'],
    [fullNamePath],
  );

  if (last(fullNamePath) === 'id') {
    throw new Error(
      safeGetMessage(recordSelectBundle, 'do_not_pass_id', {
        path: JSON.stringify(fullNamePath),
      }),
    );
  }

  const titleField = titleFields[
    recordType as keyof typeof titleFields
  ] as string;

  const getShipperId = () => {
    // add more to this pattern if needed
    const shipperId =
      form.getFieldValue(['group', 'shipper', 'id']) ||
      form.getFieldValue(['shipper', 'id']);
    return shipperId;
  };

  const shipperId = getShipperId();
  const usCountryCode = get(getCountryCodes()['US'], 'name');

  let baseSearchCriteria: AnyObject = {
    deletedTime: { operator: DateSearchOperator.DOES_NOT_EXIST },
  };
  // TODO: keep an array of nonOperatorRecords in shared
  const nonOperatorRecords: RecordType[] = [
    RecordType.AD_CVD_CASE,
    RecordType.HTS,
  ];
  if (!nonOperatorRecords.includes(recordType)) {
    baseSearchCriteria.operatorId = {
      operator: KeywordSearchOperator.EQUALS,
      values: [currentUser.operator?.id],
    };
  }
  if (usOnly) {
    baseSearchCriteria.countryName = {
      operator: KeywordSearchOperator.EQUALS,
      values: [usCountryCode],
    };
  }
  if (additionalSearchCriteria) {
    baseSearchCriteria = {
      ...baseSearchCriteria,
      ...additionalSearchCriteria,
    };
  }
  // show only the records that have the same shipper as the form we are on
  const recordTypesUnrelatedToShipper: RecordType[] = [
    RecordType.SHIPPER,
    RecordType.TEAM,
    RecordType.ADCVD_CASE,
    RecordType.AD_CVD_CASE,
    RecordType.HTS,
  ];

  // add more to this pattern if needed
  const forwarders = form.getFieldValue(['group', 'forwarders']);
  const forwarder =
    forwarders?.[0]?.forwarder || form.getFieldValue(['forwarder']);
  // TODO: remove this once we have a solution to enable record select which are not linked to shipper
  const disableShipperFirewall = isAGLForwarder(forwarder) || false;
  if (
    shipperId &&
    !recordTypesUnrelatedToShipper.includes(recordType) &&
    !disableShipperFirewall
  ) {
    baseSearchCriteria.shipperId = {
      operator: KeywordSearchOperator.EQUALS,
      values: [shipperId],
    };
  }
  const sortOptions = isSorted ? [{ field: titleField }] : [];
  const paginationOptions = { pageSize };

  const optionsQuery = fetchRecordSelectOptions({
    recordType,
    fields: additionalSearchFields,
  });
  const optionsQueryVariables = {
    variables: {
      input: {
        searchCriteria: baseSearchCriteria,
        sortOptions,
        paginationOptions,
      },
    },
  };
  const {
    data,
    loading: fetchRecordSelectOptionsLoading,
    refetch: refetchOptions,
  } = useQuery(optionsQuery, {
    skip: !currentUser.operator?.id || skipSearch,
    ...optionsQueryVariables,
  });

  const fullIdNamePath = useMemo(() => [...fullNamePath, 'id'], [fullNamePath]);
  // We want to explicitly provide a single option on first render so that we can control the rendering of the item (we don't want to render the record id, which is the default if a value is set but no options are provided);
  const { id: initialValueId, version: initialValueVersion } =
    form.getFieldValue([...fullNamePath]) || {};
  const initialOptions = initialValueId ? [{ id: initialValueId }] : [];
  let records: any[] = get(
    data,
    [`search${pascalCase(recordType)}Table`, 'results'],
    initialOptions,
  );

  const [getFieldsForSelectedRecord, getFieldsForSelectedRecordResponse] =
    useLazyQuery(
      getOneQueryLite({
        recordName: recordType,
        fields: `${fieldsToFetchForSelectedRecord}`,
        queryName: 'getFieldsForSelectedRecord',
      }),
    );

  // Anytime getFieldsForSelectedRecordResponse changes, update the form with the change
  useEffect(() => {
    // "additionalFields" refers to the fields asked for via fieldsToFetchForSelectedRecord
    const recordWithAdditionalFields = getRecordFromResponse(
      getFieldsForSelectedRecordResponse,
      'get',
      recordType,
    );

    const additionalFields = omit(recordWithAdditionalFields, [
      // Don't override `id` (the field we always fetch and set by default)
      // as it's already set. Also, no need to set `__typename` in the form.
      // Anything remaining are the additional fields
      'id',
      '__typename',
    ]);

    Object.entries(additionalFields).forEach(([field, value]) => {
      form.setFields([{ name: [...fullNamePath, field], value }]);
    });
  }, [getFieldsForSelectedRecordResponse]); // eslint-disable-line

  const additionalInitialFields = getShouldFetchAlternateName(recordType)
    ? 'alternateName'
    : '';

  const [getInitialValueRecord, initialValueQueryResponse] = useLazyQuery(
    getOneQueryLite({
      recordName: recordType,
      fields: `version ${titleField} ${additionalInitialFields}`,
      queryName: 'getRecordSelectInitialValueRecord',
    }),
  );

  // only want to fetch this for the first initialValue
  useEffect(() => {
    const getInitialValueRecordAndAdditionalFields = async () => {
      if (
        currentUser.operator?.id &&
        initialValueId &&
        !Array.isArray(initialValueId)
      ) {
        await getInitialValueRecord({
          variables: { id: initialValueId, version: initialValueVersion },
        });

        // Now that we've loaded the initial record, query any additional fields
        // (if asked for via fieldsToFetchForSelectedRecord) that are needed
        if (fieldsToFetchForSelectedRecord && initialValueId) {
          await getFieldsForSelectedRecord({
            variables: { id: initialValueId, version: initialValueVersion },
          });
        }
      }
    };
    getInitialValueRecordAndAdditionalFields();
  }, [currentUser.operator?.id]); // eslint-disable-line

  const initialValueRecord = getRecordFromResponse(
    initialValueQueryResponse,
    'get',
    recordType,
  );

  if (initialValueRecord?.id) {
    if (records.every((record) => record?.id !== initialValueRecord?.id)) {
      records = [initialValueRecord, ...records];
    }
  }
  if (createdRecord) {
    records = [...records, createdRecord];
  }

  const debouncedFetchOptions = useCallback(
    debounce(async (text?: string) => {
      const refetchSearchCriteria = createRefetchSearchCriteriaOverride
        ? createRefetchSearchCriteriaOverride(text)
        : {
            [titleField]: {
              values: [text],
              operator: 'INCLUDES',
            },
          };
      const searchCriteria = {
        ...baseSearchCriteria,
        ...refetchSearchCriteria,
      };
      refetchOptions({
        input: { searchCriteria, sortOptions, paginationOptions },
      });
      text ? setEnteredText(text) : setEnteredText('');
      // TODO support more than 1 select value
      // const recordTypes = [recordType];
      // if (extraRecordType) recordTypes.push(extraRecordType);
      // TODO implement
      // if (mostRecentValue) {
      //   if (Array.isArray(mostRecentValue)) {
      //     input.specificRecordIds.push(...mostRecentValue);
      //   } else {
      //     input.specificRecordIds.push(mostRecentValue);
      //   }
      // }
      // if (includeShipper && shipperId) input.specificRecordIds = [shipperId];
    }, 250),
    [baseSearchCriteria, sortOptions, paginationOptions],
  );

  const handleSearch = async (value: string) => {
    await debouncedFetchOptions(value);
  };

  const handleRedirect = useCallback(
    (e: any, openInNewTab?: boolean) => {
      const id = form.getFieldValue(fullIdNamePath);
      const valRecordType = getRecordType(id);
      if (!valRecordType) return;
      const path = `/${paramCase(pluralize(valRecordType))}/${id}`;
      if (e.ctrlKey || targetBlank || openInNewTab) {
        window.open(path, '_blank');
      } else {
        if (history) history.push(path);
      }
    },
    [form, fullIdNamePath, history, targetBlank],
  );

  const registerClick = useCallback(() => {
    const container = get(ref, 'ref.current.select.rcSelect.rootRef');
    if (container && !container.onclick) {
      container.onclick = (e: any) => {
        // need this.props (new value), destructed value would be stale
        const clickable = readOnly && !multiple;
        if (clickable) handleRedirect(e);
      };
    }
  }, [handleRedirect, multiple, readOnly]);

  // mounted
  useEffect(() => {
    registerClick();
  }, [refetchOptions, registerClick]);

  const ref = useRef(null);

  const {
    visible: quickCreateVisible,
    openModal: openQuickCreate,
    closeModal: closeQuickCreate,
  } = useModal(createModalKey(`QUICK_CREATE_${fullNamePath.join('_')}`));

  /* const { */
  /*   visible: extraQuickCreateVisible, */
  /*   openModal: openExtraQuickCreate, */
  /*   closeModal: closeExtraQuickCreate, */
  /* } = useModal(createModalKey(`EXTRA_QUICK_CREATE_${fullNamePath.join('_')}`)); */

  const getValueFromEvent = (value: string | string[]) => {
    if (!value) {
      return undefined;
    }
    if (Array.isArray(value)) {
      if (value.includes('create')) {
        openQuickCreate();
        value.splice(value.indexOf('create'), 1);
        return value;
      }
    } else {
      if (value === 'create') {
        openQuickCreate();
        return null;
      }
    }
    return value;
  };

  const { getFieldValue } = form;
  const fieldValue = getFieldValue(fullIdNamePath);
  const fieldValuePresent: AnyObject = {};

  const getOptionLabel = ({
    record,
    recordId,
  }: {
    record: any;
    recordId: string;
  }) => {
    let optionLabel = getCustomLabel
      ? getCustomLabel(record)
      : get(record, titleField, '');
    if (showAddress) {
      const streetAddress = get(record, ['addresses', 'physical', 'address']);
      if (streetAddress) {
        optionLabel += ` (${streetAddress.substr(0, 6)}...)`;
      }
    }
    const { name, alternateName } = record || {};
    if (
      Object.values(PartyRecordType).includes(recordType as PartyRecordType) &&
      name &&
      alternateName
    ) {
      if (multiple) optionLabel = alternateName;
      else {
        optionLabel = `${
          Array.isArray(name) ? name[1] : name
        } (DBA ${alternateName})`;
      }
    }
    if (isUser(recordType) && showName) {
      optionLabel = get(record, ['name'], '');
    }
    return optionLabel || recordId;
  };

  let excludedOptions: string[] = [];
  if (getExcludedOptions) {
    excludedOptions = getExcludedOptions();
  }

  const eligibleRecords = records.filter((r: AnyObject) => {
    return !excludedOptions.includes(r.id) || r.id === fieldValue;
  });

  const recordSelectOptions: RecordSelectOption[] = eligibleRecords.map(
    (record) => {
      const recordId = record.id;
      if (
        Array.isArray(fieldValue)
          ? fieldValue.includes(recordId)
          : recordId === fieldValue
      )
        fieldValuePresent[recordId] = true;
      const optionLabel = getOptionLabel({ record, recordId });
      return {
        key: recordId,
        value: recordId,
        label: optionLabel,
        version: record.version,
        suffix: dataCyOptionSuffix,
      };
    },
  );
  const recordOptions: JSX.Element[] = recordSelectOptions.map(createOption);
  const selectedRecord: RecordSelectOption | undefined =
    recordSelectOptions.find((option) => option.value === fieldValue);

  const isAllowedToCreate = () => {
    // Add to this list when we add more countries
    const iors: RecordType[] = [
      RecordType.US_IOR,
      RecordType.DE_IOR,
      RecordType.GB_IOR,
      RecordType.NL_IOR,
      RecordType.FR_IOR,
    ];
    // Only Shippers can create an IOR
    if (iors.includes(recordType) && accountType !== AccountType.SHIPPER)
      return false;
    // Only Forwarders can create a shipper
    if (
      recordType === RecordType.SHIPPER &&
      accountType !== AccountType.FORWARDER
    )
      return false;
    // team can't create forwarders
    if (
      accountType === AccountType.OPERATOR &&
      recordType === RecordType.FORWARDER
    )
      return false;
    return true;
  };
  if (create && isAllowedToCreate()) {
    recordOptions.push(
      createOption({
        key: 'create',
        value: 'create',
        label: (
          <>
            <PlusOutlined className="create-record-icon" />
            {safeGetMessage(recordSelectBundle, 'create_record_name', {
              record: formatRecordName({ recordType, accountType }),
            })}
          </>
        ),
      }),
    );
  }

  if (unassignedId) {
    recordOptions.unshift(
      createOption({
        key: 'unassigned',
        value: unassignedId,
        label: safeGetMessage(recordSelectBundle, 'unassigned'),
      }),
    );
  }

  const labelText =
    typeof label !== 'undefined'
      ? label
      : formatRecordName({ recordType, accountType, pluralize: multiple });

  // Find the id of the currently selected record
  const id = form.getFieldValue(fullIdNamePath);
  // Find the recordType of the currently selected record
  let valRecordType: RecordType | undefined;

  // This is needed in case when multiple === true
  if (Array.isArray(id)) {
    valRecordType = getRecordType(id[0]);
  } else {
    valRecordType = getRecordType(id);
  }
  // We should only show the hyperlink if there is a record currently
  // selected and it's a valid recordType. If there is not a record selected
  // `id` will be `undefined` and `getRecordType` will return `undefined`
  // so we can use `valRecordType` to determine whether to show the hyperlink

  const labelElement: React.ReactNode = (
    <>
      <StyledSpan>{labelText}</StyledSpan>
      {includeHyperlinkInLabel && valRecordType && (
        <StyledSelectOutlined onClick={(e) => handleRedirect(e, true)} />
      )}
    </>
  );

  registerClick();

  const loading =
    fetchRecordSelectOptionsLoading ||
    initialValueQueryResponse.loading ||
    recordSelectEmbeddedFieldsLoading;

  const getEmbeddedFields = useCallback(
    async (options: { variables: { id: string; version?: number } }) => {
      if (!['create', 'extraCreate'].includes(options?.variables?.id)) {
        setRecordSelectEmbeddedFieldsLoading(true);
        const { data, errors } = await client.query({
          query: getOneQueryLite({
            recordName: recordType,
            fields: embeddedFields,
            fragments,
            queryName: 'recordSelectEmbeddedFields',
          }),
          variables: options.variables,
          fetchPolicy: 'network-only',
        });
        setRecordSelectEmbeddedFieldsLoading(false);
        if (!errors) {
          const record = getRecordFromResponse({ data }, 'get', recordType);
          if (record) {
            // TODO implement a validation check on the required fields. Until we allow ops to fill in any missing data themselves, we can warn with an antd message, notification or modal that the record doesn't have all the CBP fields.
            // TODO Refactor/clean-up our embedded fields pattern.
            // embedded/snapshot-ed fields are stored one level above the id + version leaf fields.
            const baseFullNamePath = fullNamePath.slice(0, -1);
            const idVersionField = last(fullNamePath) as string;
            let { id, version, ...embeddedFields } = record;
            if (mapEmbeddedFields) {
              embeddedFields = mapEmbeddedFields(embeddedFields);
            }
            const fieldsToSet = Object.entries(embeddedFields).map(
              ([field, value]) => ({
                name: [...baseFullNamePath, field],
                value,
              }),
            );
            // Should set the `embeddedFields` as well as the `idVersionField`
            // with the latest id and version as we always want `version` updated
            setVersion(version);
            if (versioned)
              fieldsToSet.push({
                name: versionFullNamePath,
                value: version,
              });
            form.setFields(fieldsToSet);
            if (onCompletedEmbeddingFields) {
              await onCompletedEmbeddingFields({
                [idVersionField]: { id, version },
                ...embeddedFields,
              });
            }

            let missingEmbeddedFields: string[] = [];
            requiredEmbeddedFields?.forEach((requiredEmbeddedField) => {
              const {
                fullNamePath: requiredEmbeddedFieldFullNamePath,
                validator,
              } = requiredEmbeddedField;
              const defaultMessages = [];
              if (!form.getFieldValue(requiredEmbeddedFieldFullNamePath))
                defaultMessages.push(
                  requiredEmbeddedFieldFullNamePath.join('.'),
                );
              missingEmbeddedFields = missingEmbeddedFields.concat(
                validator ? validator(form) : defaultMessages,
              );
            });

            // Open the modal to prompt the user to provide the required fields
            if (missingEmbeddedFields.length) {
              const id = form.getFieldValue(fullIdNamePath);
              const record = records.find((record) => record.id === id);
              const recordTypeAndName = `${formatRecordName({
                recordType,
                accountType,
              })}${record?.name ? ` ${record.name}` : ''}`;

              Modal.error({
                title: safeGetMessage(
                  recordSelectBundle,
                  'missing_required_embedded_fields',
                ),
                width: 1024,
                content: (
                  <>
                    <div>
                      {safeGetMessage(
                        recordSelectBundle,
                        'missing_required_fields_message',
                        { recordTypeAndName, id },
                      )}
                    </div>
                    <ul>
                      {missingEmbeddedFields.map((missingEmbeddedField) => (
                        <li>{missingEmbeddedField}</li>
                      ))}
                    </ul>
                    <div>
                      {safeGetMessage(
                        recordSelectBundle,
                        'click_to_open_record',
                        {
                          link: () => (
                            <a onClick={(e) => handleRedirect(e, true)}>
                              {safeGetMessage(recordSelectBundle, 'here')}
                            </a>
                          ),
                        },
                      )}
                    </div>
                  </>
                ),
              });
            }
          }
        } else {
          // TODO reset ID (and optionally version) to previous value, so the data is not in a bad state
        }
      }
    },
    [
      accountType,
      client,
      embeddedFields,
      form,
      fragments,
      fullIdNamePath,
      fullNamePath,
      handleRedirect,
      mapEmbeddedFields,
      onCompletedEmbeddingFields,
      recordType,
      records,
      requiredEmbeddedFields,
      versionFullNamePath,
      versioned,
    ],
  );

  // the value of RecordSelect can be changed in two ways, this function normalizes them.
  // 1. Regular Select value is picked of an existing record. In this case, the string id is passed
  // 2. A record is created on-the-fly via the CreateRecord modal. In this case, we pass the newly created record to onChange
  const normalizedOnChange = useCallback(
    async (val: any) => {
      let version;
      let id;
      if (typeof val === 'string') {
        // regular Select case
        const record = records.find((record) => record.id === val);
        version = record?.version;
        id = val;
      } else if (typeof val === 'object') {
        // on-the-fly record creation case.
        version = val.version;
        id = val.id;
        if (id) {
          form.setFields([
            {
              name: [...fullNamePath, 'id'],
              value: id,
            },
          ]);
        }
      }

      if (embeddedFields) {
        if (id) {
          // getEmbeddedFields handles `versioned` since it is also used for
          // the refresh button's `onClick` and is always `versioned`
          await getEmbeddedFields({ variables: { id: id, version } });
        } else {
          // clear embedded fields if RecordSelect is cleared (id is falsy)
          if (onClearEmbeddedFields) {
            // @todo the slice below is a scythe that assumes all state is stored at the
            // parent level. We should clean up embeddedFields to allow more control.
            // In the meantime, allow opting out of the scythe.
            // Asana: https://app.asana.com/0/1206096446668322/1206174872936972/f
            onClearEmbeddedFields();
          } else {
            form.setFields([
              { name: fullNamePath.slice(0, -1), value: undefined },
            ]);
          }
          if (versioned) {
            setVersion(undefined);
          }
        }
      } else if (versioned) {
        // If the record is versioned and we are clearing the RecordSelect we
        // also need to clear the version otherwise we risk the user then trying
        // to save and validations will fail bc the version is in the form but
        // there isn't an ID.
        // Note: All other updates to version are handled via the `version` local
        // state variable (we're passing it to `HiddenField` below). But
        // `HiddenField` falls back to the form value if the value passed is
        // undefined. So we must "clear" it manually here.
        if (!val) {
          form.setFields([{ name: versionFullNamePath, value: undefined }]);
        }
        setVersion(version);
      }

      if (fieldsToFetchForSelectedRecord) {
        if (id) {
          // Await incase the onChange relies on checking the additional fields
          await getFieldsForSelectedRecord({
            variables: { id, version },
          });
        } else {
          // clear all fields if RecordSelect is cleared (id is falsy) except for
          // 'id' as RecordSelect handles it by default
          const formObject = form.getFieldValue(fullNamePath) || {};
          const fieldsToSet: FieldData[] = [];
          Object.keys(formObject).forEach((field) => {
            if (field !== 'id')
              fieldsToSet.push({
                name: [...fullNamePath, field],
                value: undefined,
              });
          });
          form.setFields(fieldsToSet);
        }
      }

      if (onChange) {
        return onChange(id, version);
      }
    },
    [
      embeddedFields,
      fieldsToFetchForSelectedRecord,
      form,
      fullNamePath,
      getEmbeddedFields,
      getFieldsForSelectedRecord,
      onChange,
      records,
      versionFullNamePath,
      versioned,
    ],
  );

  // this useEffect will ensure that if fieldValue is altered, either internally by RecordSelect or externally,
  // all embedded fields are filled out.  This solves mismatches that we saw due to fieldValue being set
  // externally, as the fieldValue would be set but all embedded fields would remain set to the previous fieldValue's
  const previousFieldValue = usePrevious(fieldValue);
  // This variable will consume the first update to the fieldValue, and stop the useEffect from running.
  // For a RecordSelect that already has a value stored on the entry, that means it'll consume the "change" from blank to
  // having a value that happens on page load.  For a RecordSelect with no value saved to the entry, it'll
  // consume the first time that the the value is set by some other force.  If set by RecordSelect itself, RecordSelect will
  // already handle setting the embedded fields.  If an outside force sets the fieldValue, this will consume that, meaning
  // embedded fields will not be set by this useEffect in that case.
  const hasLoaded = useRef(false);
  useEffect(() => {
    if (
      embeddedFields && // don't need this logic for fieldValues without embedded fields
      fieldValue !== previousFieldValue
    ) {
      if (hasLoaded.current) {
        normalizedOnChange(fieldValue);
      } else {
        hasLoaded.current = true;
      }
    }
  }, [fieldValue, previousFieldValue, embeddedFields, normalizedOnChange]);

  const defaultFirstValue =
    selectOneRecordByDefault && eligibleRecords.length === 1
      ? eligibleRecords[0]?.id
      : undefined;

  // This is needed as onChange is not triggered if the default value is set outside the dropdown.
  useEffect(() => {
    if (
      defaultFirstValue &&
      form.getFieldValue(fullIdNamePath) !== defaultFirstValue
    ) {
      // Need to manually invoke form.SetFields as FormItem.initialValues wont work since during the initial render records are undefined, hence the value is undefined.
      form.setFields([{ name: fullIdNamePath, value: defaultFirstValue }]);
      // Manually trigger on change method, as it is not triggered if not changed from the dropdown.
      normalizedOnChange(defaultFirstValue);
    }
  });

  if (!show({ readOnly, form, field: fullIdNamePath })) return null;

  const quickCreateOnChange = async (val: any) => {
    await normalizedOnChange(val);
    await form.validateFields([[...fullNamePath, 'id']]);
    closeQuickCreate();
    setCreatedRecord(val);
  };
  const renderEmpty = () => {
    if (!notFoundText) return safeGetMessage(sharedBundle, 'none_found');

    if (!notFoundAction) return notFoundText;

    return (
      <div>
        <a onClick={() => notFoundAction(enteredText)}> {notFoundText}</a>
      </div>
    );
  };

  return (
    <>
      {create && (
        <CreateRecord
          recordType={recordType}
          visible={quickCreateVisible}
          onCreate={quickCreateOnChange}
          onCancel={closeQuickCreate}
          shipperId={shipperId}
        />
      )}
      <StyledFormItem
        label={hideLabel ? '' : labelElement}
        name={localNamePath ? [...localNamePath, 'id'] : fullIdNamePath}
        rules={[{ required, message: ' ' }]}
        getValueFromEvent={(e: any) => getValueFromEvent(e)}
        $itemSize={$itemSize}
        $inline
        $readOnly={readOnly}
        $removeSpaceBottom={$removeSpaceBottom}
        $hasSyncButton={Boolean(embeddedFields)}
        $removeSpaceLeft={$removeSpaceLeft}
        data-cy={createDataCyValue(DataCyPrefix.RECORD_SELECT, dataCySuffix)}
      >
        <Select
          loading={loading}
          showAction={['click', 'focus']}
          ref={ref}
          mode={multiple ? 'multiple' : undefined}
          disabled={
            disabled ||
            isLoading ||
            initialValueQueryResponse.loading ||
            recordSelectEmbeddedFieldsLoading ||
            (disableSelectionForSingleRecord && eligibleRecords.length === 1)
          }
          showSearch
          optionLabelProp="children"
          allowClear
          maxTagCount={multiple ? maxTagCount || 4 : undefined}
          filterOption={() => true}
          dropdownMatchSelectWidth={false}
          dropdownStyle={dropdownStyle}
          notFoundContent={renderEmpty()}
          onChange={normalizedOnChange}
          onSearch={handleSearch}
          placeholder={placeHolder}
        >
          {recordOptions}
        </Select>
      </StyledFormItem>
      {embeddedFields && (
        <Form.Item shouldUpdate={shouldUpdate([fullIdNamePath])} noStyle>
          {() => {
            const id = form.getFieldValue(fullIdNamePath);
            return (
              <StyledButton
                $removeRefreshSpaceRight={$removeRefreshSpaceRight}
                $isHidden={readOnly || !id}
                $hasLabel={Boolean(!hideLabel && labelElement)}
                size="small"
                shape="circle"
                // Icon does not need spin as button is disabled on load
                icon={<SyncOutlined />}
                // TODO It would be great to be able to disabled the button
                // if it is up to date. Right now, I don't think it would be
                // straight forward and would require at minimum a lot of
                // fetching to check for the most recent version. Using a
                // `latest` flag on records could prove useful. We could maybe
                // include latest as a field in our various queries and use that to
                // know if anything is out of date. Or the inverse, obsolete.
                disabled={(disabled && !forceAlowRefresh) || loading}
                onClick={() => getEmbeddedFields({ variables: { id } })}
              />
            );
          }}
        </Form.Item>
      )}
      <HiddenField
        fullNamePath={versionFullNamePath}
        localNamePath={
          localNamePath ? [...localNamePath, 'version'] : versionFullNamePath
        }
        form={form}
        value={versioned ? selectedRecord?.version || version : version}
      />
    </>
  );
};

export default RecordSelect;

import styled from 'styled-components';
import { Modal } from 'antd';
import { CssSpace } from '@xbcb/ui-types';

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-bottom: 0;
  }
`;

export const StyledActionsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  button:last-child {
    margin-left: ${CssSpace.SPACE_3};
  }
`;

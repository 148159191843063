import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: ${CssSpace.SPACE_3};
`;

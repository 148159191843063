import React from 'react';
import { PageHeader } from 'antd';
import { BreadcrumbProps, Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { PageHeaderProps } from 'antd/lib/page-header';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledPageHeader = styled(PageHeader)<{
  $headerPaddingTop?: string;
  $headingPaddingBottom?: string;
  $maxWidth?: string;
}>`
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing.space_4};

  max-width: ${({ $maxWidth }) =>
    $maxWidth ? $maxWidth : 'calc(100% - 50px)'};

  .ant-page-header-heading {
    flex-wrap: wrap;
    margin: 0;
    ${({ $headingPaddingBottom }) =>
      $headingPaddingBottom && `padding-bottom: ${$headingPaddingBottom}`};
  }

  .ant-page-header-heading-title {
    display: flex;
  }

  .ant-page-header-content {
    padding: 0;
    height: 100%;
  }

  ${({ $headerPaddingTop }) =>
    $headerPaddingTop && `padding-top: ${$headerPaddingTop}`};
`;

const itemRender: BreadcrumbProps['itemRender'] = (
  route,
  params,
  routes,
  paths,
) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={'/' + paths.join('/')}>{route.breadcrumbName}</Link>
  );
};

const AppPage: React.FC<
  Omit<PageHeaderProps, 'breadcrumb'> & {
    routes?: Route[];
    // The padding at the top of the header, (aka the component itself - `PageHeader`)
    $headerPaddingTop?: string;
    // The padding at the bottom of the heading (the first div inside the component itself)
    $headingPaddingBottom?: string;
    $maxWidth?: string;
  }
> = ({
  title,
  subTitle,
  routes,
  tags,
  extra,
  children,
  $headerPaddingTop,
  $headingPaddingBottom,
  $maxWidth,
}) => {
  const breadcrumb = routes && { routes, itemRender };
  return (
    <StyledPageHeader
      title={title}
      subTitle={subTitle}
      tags={tags}
      extra={extra}
      breadcrumb={breadcrumb}
      $headerPaddingTop={$headerPaddingTop}
      $headingPaddingBottom={$headingPaddingBottom}
      $maxWidth={$maxWidth}
    >
      {children}
    </StyledPageHeader>
  );
};

export default AppPage;

import React, { useState } from 'react';
import { Select, Input, DatePicker, Form } from 'antd';
import Aoc from 'components/Aoc';
import { getCodes, selectFilter, shouldUpdate } from '@xbcb/ui-utils';
import { HiddenField, FormItem } from '@xbcb/form-item-components';
import { get } from 'lodash';
import { conformToMask } from 'text-mask-core';
import { clearFields } from 'libs/clearFields';
import { AocCode } from 'components/Aoc/aocFields';
import { FormInstance } from 'antd/lib/form';
import { NamePath, CssSize } from '@xbcb/ui-types';

import { StyledHeader, StyledAccessionNumberDiv } from './styles';

const { Option } = Select;

const mask = [
  /\d/,
  /\d/,
  /[a-zA-Z\d]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
];

interface RadiationStandardsProps {
  form: FormInstance;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  disabled?: boolean;
  readOnly?: boolean;
}

const RadiationStandards = ({
  form,
  fullNamePath,
  localNamePath,
  readOnly,
  disabled,
}: RadiationStandardsProps) => {
  const fullDeclarationCodeNamePath = [...fullNamePath, 'declarationCode'];
  const localDeclarationNamePath = [...localNamePath, 'declarationCode'];
  const fullReasonCodeNamePath = [...fullNamePath, 'reasonCode'];
  const localReasonCodeNamePath = [...localNamePath, 'reasonCode'];
  const [previousConformedValue, setPreviousConformedValue] = useState('');

  const handleAccessionChange = (e: any) => {
    if (!e || !e.target) return e;
    const value = e.target.value;
    const { conformedValue } = conformToMask(value, mask, {
      guide: false,
      previousConformedValue: previousConformedValue,
    });

    setPreviousConformedValue(conformedValue);
    return conformedValue;
  };

  const declarationCodes = () =>
    Object.entries(
      get(getCodes(), 'CBP.CATAIR.PGA.radiationStandards', {}),
    ).map(([code, { text }]: [any, any]) => (
      <Option key={code} value={code}>
        {code} - {text}
      </Option>
    ));

  const reasonCodes = () =>
    Object.entries(
      get(
        getCodes(),
        `CBP.CATAIR.PGA.radiationStandards.${form.getFieldValue(
          fullDeclarationCodeNamePath,
        )}.values`,
        {},
      ),
    ).map(([code, { text }]: [any, any]) => (
      <Option key={code} value={code}>
        {code} - {text}
      </Option>
    ));

  return (
    <div>
      <StyledHeader>Radiation Control Standards</StyledHeader>
      <FormItem
        label="Question 1"
        name={localDeclarationNamePath}
        rules={[{ required: true, message: ' ' }]}
        $readOnly={readOnly}
        $itemSize={CssSize.SHORT}
      >
        <Select
          disabled={disabled}
          showSearch
          allowClear
          filterOption={selectFilter}
          notFoundContent="None Found"
          dropdownMatchSelectWidth={false}
          onChange={clearFields({
            form,
            fullNamePath: [...fullNamePath],
            fields: ['reasonCode'],
          })}
        >
          {declarationCodes()}
        </Select>
      </FormItem>
      <Form.Item
        shouldUpdate={shouldUpdate([
          fullDeclarationCodeNamePath,
          fullReasonCodeNamePath,
        ])}
        noStyle
      >
        {() => {
          const declarationCode = form.getFieldValue(
            fullDeclarationCodeNamePath,
          );
          const reasonCode = form.getFieldValue(fullReasonCodeNamePath);
          const complianceCodeType =
            declarationCode && reasonCode && `R${declarationCode}${reasonCode}`;

          const additionalQuestionType = get(
            getCodes(),
            `CBP.CATAIR.PGA.radiationStandards.${declarationCode}.values.${reasonCode}.type`,
          );
          const complianceTitle = get(
            getCodes(),
            `USFDA.compliance.${complianceCodeType}.name`,
          );
          return (
            <>
              {declarationCode && (
                <FormItem
                  label="Question 2"
                  name={localReasonCodeNamePath}
                  rules={[{ required: true, message: ' ' }]}
                  $readOnly={readOnly}
                  $itemSize={CssSize.SHORT}
                >
                  <Select
                    disabled={disabled}
                    showSearch
                    allowClear
                    filterOption={selectFilter}
                    notFoundContent="None Found"
                    dropdownMatchSelectWidth={false}
                    onChange={
                      complianceCodeType
                        ? clearFields({
                            form,
                            fullNamePath: [...fullNamePath],
                            fields: [
                              'complianceCode',
                              'eprcAccessionNumber',
                              'eprcAnnualReportAccessionNumber',
                            ],
                          })
                        : undefined
                    }
                  >
                    {reasonCodes()}
                  </Select>
                </FormItem>
              )}
              {complianceCodeType && (
                <HiddenField
                  form={form}
                  fullNamePath={[...fullNamePath, 'complianceCode', 'type']}
                  localNamePath={[...localNamePath, 'complianceCode', 'type']}
                  value={complianceCodeType}
                />
              )}
              {['date', 'text'].includes(additionalQuestionType) && (
                <FormItem
                  label={complianceTitle}
                  name={[...localNamePath, 'complianceCode', 'value']}
                  rules={[{ required: true, message: ' ' }]}
                  $readOnly={readOnly}
                  $itemSize={CssSize.SHORT}
                >
                  {additionalQuestionType === 'date' ? (
                    <DatePicker disabled={disabled} />
                  ) : (
                    additionalQuestionType === 'text' && <Input />
                  )}
                </FormItem>
              )}
              {['indicator'].includes(additionalQuestionType) && (
                <HiddenField
                  form={form}
                  fullNamePath={[...fullNamePath, 'complianceCode', 'value']}
                  localNamePath={[...localNamePath, 'complianceCode', 'value']}
                  value={'true'}
                />
              )}
              {complianceCodeType === 'RB1' && (
                <StyledAccessionNumberDiv>
                  <Aoc
                    code={AocCode.ACC}
                    form={form}
                    localNamePath={localNamePath}
                    fullNamePath={fullNamePath}
                    inline={true}
                    mask={handleAccessionChange}
                    disabled={disabled}
                    readOnly={readOnly}
                  />

                  <span className="or">OR</span>
                  <Aoc
                    code={AocCode.ANC}
                    form={form}
                    localNamePath={localNamePath}
                    fullNamePath={fullNamePath}
                    inline={true}
                    mask={handleAccessionChange}
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                </StyledAccessionNumberDiv>
              )}
            </>
          );
        }}
      </Form.Item>
    </div>
  );
};

export default RadiationStandards;

import { RecordType } from '@xbcb/shared-types';
import { EuIor } from '../../loadableComponents';
import euIorRoute from './euIor';
import { deIorFragments } from '@xbcb/party-queries';

const deIorRoute = euIorRoute({
  page: EuIor,
  recordType: RecordType.DE_IOR,
  fields: `...deIorFields`,
  fragments: deIorFragments,
});

export default deIorRoute;

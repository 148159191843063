import { Button } from 'antd';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  margin-bottom: ${({ theme }) => theme.spacing.space_5};
`;

export const StyledBlueTextBox = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.space_4};
  margin-top: ${({ theme }) => theme.spacing.space_3};
  background-color: #e6f7ff;
`;

export const StyledLinkButton = styled(Button)`
  padding-left: 0;
  padding-right: ${({ theme }) => theme.spacing.space_1};
  color: ${({ theme }) => theme.palette.purples.gamma};
`;

export const StyledList = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledPopoverContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledOverflow = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.purples.gamma};
`;

import React, { useState, useEffect } from 'react';
import { RawHTML } from '@xbcb/static-text-components';
import { butter } from '@xbcb/ui-utils';
import { Spin } from 'antd';
import { StyledTooltip, StyledWarningOutlined } from './styles';

interface TrumpListTooltipProps {
  list: string;
}

const TrumpListTooltip = (props: TrumpListTooltipProps) => {
  const { list } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [trumpListData, setTrumpListData] = useState<any>({});
  useEffect(() => {
    const fetchContent = async () => {
      if (isLoading) {
        const response = await butter('content', 'retrieve', [
          'trump_list1',
          'trump_list2',
          'trump_list3',
        ]);
        if (response) {
          const data = response.data.data;
          setIsLoading(false);
          setTrumpListData(data);
        }
      }
    };
    fetchContent();
  });

  const lists: { [key: string]: string } = {
    '1': trumpListData.trump_list1,
    '2': trumpListData.trump_list2,
    '3': trumpListData.trump_list3,
  };

  const title = isLoading ? <Spin /> : <RawHTML html={lists[list]} />;
  return (
    <StyledTooltip title={title} placement="right">
      <div>
        <StyledWarningOutlined className="trump-warning" /> Trump list {list}
      </div>
    </StyledTooltip>
  );
};

export default TrumpListTooltip;

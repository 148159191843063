import styled from 'styled-components';
import { Form } from 'antd';
import { CssSpace } from '@xbcb/ui-types';
import { spaceLeft, spaceRight } from '@xbcb/ui-styles';

export const StyledActionsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  button:last-child {
    margin-left: ${CssSpace.SPACE_3};
  }
  ${spaceRight}
`;

// Adding spaceLeft to accommodate the spaceRight that all the form items
// within the modal leave to the right. This will allow for the same amount of
// space on both sides of the form items
export const StyledForm = styled(Form)`
  ${spaceLeft}
`;

import { gql } from '@apollo/client';
import { SearchQuery, getRecordFromResponseV2 } from '@xbcb/shared-queries';
import {
  DateSearchOperator,
  KeywordSearchOperator,
  RecordType,
} from '@xbcb/shared-types';

export const SEARCH_ASSIGNMENT_TEAMS = gql`
  query SearchAssignmentTeams($input: SearchAssignmentTeamInput!) {
    searchAssignmentTeams(input: $input) {
      results {
        id
        name
        verticals {
          modeOfTransport
          productTypes
          loadTypes
          forwarders {
            id
          }
          shippers {
            id
          }
          iors {
            id
          }
        }
        workOrderTaskTypes
        operator {
          id
        }
        customsBroker {
          id
        }
      }
    }
  }
`;

export const searchAssignmentTeamsQuery = SearchQuery({
  recordName: RecordType.ASSIGNMENT_TEAM,
  fields: `id name`,
});

// A support team is a team which the user's any primary team serves
// as a backup team, so we search teams by backupTeamId.
export const getSearchSupportTeamsQueryVariables = (
  backupTeamIds: string[],
) => ({
  input: {
    searchCriteria: {
      backupTeamId: {
        values: backupTeamIds,
        operator: KeywordSearchOperator.ONE_OF,
      },
      deletedTime: {
        operator: DateSearchOperator.DOES_NOT_EXIST,
      },
    },
  },
});

// A SME supported team is a team that the user's any SME team supports,
// so we search teams by subjectMatterExpertTeamId.
export const getSearchSmeSupportTeamsQueryVariables = (
  subjectMatterExpertTeamIds: string[],
) => ({
  input: {
    searchCriteria: {
      subjectMatterExpertTeamId: {
        values: subjectMatterExpertTeamIds,
        operator: KeywordSearchOperator.ONE_OF,
      },
      deletedTime: {
        operator: DateSearchOperator.DOES_NOT_EXIST,
      },
    },
  },
});

export const getSearchAssignmentTeamResults = (data: any) =>
  getRecordFromResponseV2({
    response: { data },
    crudOrSearchType: 'search',
    recordName: RecordType.ASSIGNMENT_TEAM,
  })?.results;

import {
  UsType86EntryCommercialInvoice,
  UsConsumptionEntryCommercialInvoice,
  ClassifyUsCustomsFilingCommercialInvoiceInput,
  ClassifyUsProductInput,
} from '@xbcb/api-gateway-client';
import { transformUsEntryProductToClassifyUsProductInput } from './transformUsEntryProductToClassifyUsProductInput';

export const transformUsEntryCommercialInvoicesToClassifyUsCustomsFilingCommercialInvoices =
  (
    entryInvoices?:
      | UsType86EntryCommercialInvoice[]
      | UsConsumptionEntryCommercialInvoice[],
    ignoreAdCvdAndFees = false,
  ): ClassifyUsCustomsFilingCommercialInvoiceInput[] | undefined => {
    if (!entryInvoices) return undefined;

    const mappedInvoices = entryInvoices.map((invoice) =>
      transformUsEntryCommercialInvoiceToClassifyUsCustomsFilingCommercialInvoice(
        invoice,
        ignoreAdCvdAndFees,
      ),
    );
    if (mappedInvoices.includes(undefined)) {
      return undefined;
    }
    // at this point we can be sure that result does not contain undefined
    return mappedInvoices as ClassifyUsCustomsFilingCommercialInvoiceInput[];
  };

const transformUsEntryCommercialInvoiceToClassifyUsCustomsFilingCommercialInvoice =
  (
    entry: UsType86EntryCommercialInvoice | UsConsumptionEntryCommercialInvoice,
    ignoreAdCvdAndFees = false,
  ): ClassifyUsCustomsFilingCommercialInvoiceInput | undefined => {
    const mappedProducts = entry.products?.map((product) =>
      transformUsEntryProductToClassifyUsProductInput(
        product,
        ignoreAdCvdAndFees,
      ),
    );
    if (!mappedProducts || mappedProducts.includes(undefined)) {
      return undefined;
    }

    return {
      // at this point we can be sure that mappedProducts does not contain undefined
      products: mappedProducts as ClassifyUsProductInput[],
    };
  };

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { message, Modal, Button, Form } from 'antd';
import { Link } from 'react-router-dom';
import type {
  WorkOrderGroup,
  WorkOrderGroupForwarderInput,
  ShipmentForwarderInput,
} from '@xbcb/api-gateway-client';
import { MinusButton } from '@xbcb/button-components';
import { formatRecordName } from '@xbcb/js-utils';
import { updateOneMutation } from '@xbcb/shared-queries';
import {
  AccountType,
  RecordType,
  ExternalIntegrationType,
} from '@xbcb/shared-types';
import { CssSize } from '@xbcb/ui-types';
import { reportError, useModal } from '@xbcb/ui-utils';
import ObjectIcon from 'components/ObjectIcon';
import RecordSelect from 'components/RecordSelect';
import { titleCase } from 'libs/display';
import { useCurrentUser } from 'libs/hooks';
import { ModalKey } from 'types';
import { StyledParagraph, StyledHeader } from './styles';

type TagForwarderProps = {
  workOrderGroup: WorkOrderGroup;
  isWorkOrder?: boolean;
};

const TagForwarder: React.FC<TagForwarderProps> = ({
  workOrderGroup,
  isWorkOrder,
}) => {
  const { id, version, forwarders, shipment } = workOrderGroup;
  const [form] = Form.useForm();
  const { closeModal, visible } = useModal(ModalKey.TAG_FORWARDER);
  const [isUpdating, setIsUpdating] = useState(false);
  const { accountType, forwarder: currentUserForwarder } = useCurrentUser();
  const shipmentId = shipment?.id;
  const shipmentVersion = shipment?.version;

  const updateWorkOrderGroupMutation = updateOneMutation({
    recordName: RecordType.WORK_ORDER_GROUP,
    fields: 'id forwarders { forwarder { id name } }',
  });

  const updateShipmentMutation = updateOneMutation({
    recordName: RecordType.SHIPMENT,
    fields: 'id forwarders { forwarder { id name } }',
  });

  // TODO use cache and update manually instead of useMutation
  const [updateWorkOrderGroup, { loading: updateWogMutationLoading }] =
    useMutation(updateWorkOrderGroupMutation);

  const [updateShipment, { loading: updateShipmentMutationLoading }] =
    useMutation(updateShipmentMutation);

  const isLoading =
    isUpdating || updateWogMutationLoading || updateShipmentMutationLoading;

  const forwarderFullNamePath = ['forwarder', 'id'];
  const getName = (pluralize?: boolean) => {
    const formattedName = formatRecordName({
      recordType: RecordType.FORWARDER,
      accountType,
      pluralize,
    });
    return formattedName.toLowerCase();
  };
  // If accountType === FORWARDER then the text will be `agent(s)`, so use `an`
  const getArticle = () => (accountType === AccountType.FORWARDER ? 'an' : 'a');
  const handleUpdateWorkOrderGroup = async (
    workOrderGroupForwarderInputs: WorkOrderGroupForwarderInput[],
  ) => {
    try {
      await updateWorkOrderGroup({
        variables: {
          id,
          version,
          input: { forwarders: workOrderGroupForwarderInputs },
        },
      });
    } catch (e) {
      reportError(e);
      message.error(
        'Sorry, an error has occurred. Please try again later.',
        5.0,
      );
    }
  };

  const handleUpdateShipment = async (
    shipmentForwarderInputs: ShipmentForwarderInput[],
  ) => {
    try {
      await updateShipment({
        variables: {
          id: shipmentId,
          version: shipmentVersion,
          input: { forwarders: shipmentForwarderInputs },
        },
      });
    } catch (e) {
      reportError(e);
      message.error(
        'Sorry, an error has occurred while updating Shipment. Please try again later.',
        5.0,
      );
    }
  };
  const untagForwarder = async (untagForwarderId: string) => {
    // Create forwarderInputs from the existing forwarders (don't
    // include the forwarder with id === untagForwarderId)
    const forwarderInputs =
      forwarders?.reduce(
        (
          forwarderInputs:
            | WorkOrderGroupForwarderInput[]
            | ShipmentForwarderInput[],
          { forwarder },
        ) => {
          const { id: currentForwarderId } = forwarder || {};
          // We want to include all forwarders except for the untagForwarderId
          if (currentForwarderId !== untagForwarderId) {
            forwarderInputs.push({
              // TS thinks currentForwarderId could be undefined based off how
              // it's defined in the schema (aka when `forwarder` is undefined)
              // but we know if forwarder is set there must be an id set
              forwarder: { id: currentForwarderId as string },
            });
          }
          return forwarderInputs;
        },
        [],
      ) || [];

    setIsUpdating(true);

    // update shipment forwarders, if WOG has a associated shipment
    if (shipmentId) {
      // Since Wog forwarder are created based on shipment forwarders, so they should be in sync
      await handleUpdateShipment(forwarderInputs as ShipmentForwarderInput[]);
    } else {
      // update the WOG , if WOG does not has a associated shipment
      await handleUpdateWorkOrderGroup(
        forwarderInputs as WorkOrderGroupForwarderInput[],
      );
    }
    setIsUpdating(false);
  };
  const tagForwarder = async (tagForwarderId: string) => {
    // Create forwarderInputs from the existing forwarders
    const forwarderInputs =
      forwarders?.map(({ forwarder }) => ({
        // Only IdInput for forwarder
        // TS thinks the forwarder, specifically the id, could be undefined
        // based off how it's defined in the schema, but we know it will be
        // defined if there was a corresponding WorkOrderGroupForwarder
        forwarder: { id: forwarder?.id as string },
      })) || [];

    // Include the new forwarder being tagged
    forwarderInputs.push({ forwarder: { id: tagForwarderId } });

    // update shipment forwarders, if WOG has a associated shipment
    if (shipmentId) {
      // Since Wog forwarder are created based on shipment forwarders, so they should be in sync
      await handleUpdateShipment(forwarderInputs as ShipmentForwarderInput[]);
    } else {
      // update the WOG , if WOG does not has a associated shipment
      await handleUpdateWorkOrderGroup(
        forwarderInputs as WorkOrderGroupForwarderInput[],
      );
    }
  };
  const handleSubmit: React.MouseEventHandler<HTMLElement> = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    try {
      await form.validateFields();
    } catch (e) {
      message.error(`Please select ${getArticle()} ${getName()}`);
      setIsUpdating(false);
      return;
    }

    await tagForwarder(form.getFieldValue(forwarderFullNamePath));
    // Reset the form so it's ready for another selection
    form.setFields([{ name: forwarderFullNamePath, value: undefined }]);
    setIsUpdating(false);
  };
  const recordText = isWorkOrder ? 'work order' : 'shipment';
  const listText = `These ${getName(
    true,
  )} have access to view and edit this ${recordText}:`;
  const noneText = `No ${getName(
    true,
  )} currently have access to this ${recordText}.`;
  const forwardersList = forwarders?.map(({ forwarder }) => {
    const { id, name, externalIntegrationReferences = [] } = forwarder || {};
    const isTmsIntegrationType = externalIntegrationReferences?.some(
      ({ externalIntegrationType }) =>
        externalIntegrationType ===
        ExternalIntegrationType.TRANSPORTATION_MANAGEMENT_SYSTEM,
    );
    // If the forwarder is TMS integrated (i.e. CW) they cannot be untagged.
    // Additionally, the forwarder user cannot remove itself
    // The forwarder can be removed if they have a TMS integration, as long as
    // THIS shipment/WOG was not created by their TMS. This case should be
    // pretty rare but a TODO might be needed so we don't have this issue at scale
    const hideUntagButton =
      isTmsIntegrationType || id === currentUserForwarder?.id;
    return (
      id &&
      name && (
        <StyledParagraph $removeSpaceBottom key={id}>
          <Link to={`/forwarders/${id}`}>
            <b>{name}</b>
          </Link>
          {!hideUntagButton && (
            <MinusButton
              disabled={isLoading}
              label={name}
              onRemove={() => untagForwarder(id)}
            />
          )}
        </StyledParagraph>
      )
    );
  });

  // TODO we don't have an equivalent for `canceled` in CBMS yet but we need
  // this, as it is a big feature
  const canceled = false;

  return (
    <Modal
      maskClosable={!isLoading}
      open={visible}
      width={400}
      closable={false}
      footer={null}
      onCancel={closeModal}
    >
      <StyledHeader>
        <ObjectIcon recordType="forwarders" active /> {titleCase(getName())}{' '}
        Access
      </StyledHeader>
      <StyledParagraph>
        {forwardersList?.length ? listText : noneText}
      </StyledParagraph>
      {forwardersList}
      {!canceled && (
        <div>
          <StyledParagraph $spaceTop>
            Select {getArticle()} {getName()} that you want to give access to
            this {recordText}:
          </StyledParagraph>
          <Form form={form}>
            <RecordSelect
              $removeSpaceLeft
              $itemSize={CssSize.MEDIUM}
              // Don't include 'id', RecordSelect handles adding it
              fullNamePath={forwarderFullNamePath.slice(0, -1)}
              create
              getExcludedOptions={() => {
                if (!forwarders) return [];
                // Don't allow for forwarders to be selected that are already
                // defined in the WOG
                return forwarders?.reduce(
                  (forwarderIds: string[], { forwarder }) => {
                    const { id } = forwarder || {};
                    if (id) forwarderIds.push(id);
                    return forwarderIds;
                  },
                  [],
                );
              }}
              hideLabel
              recordType={RecordType.FORWARDER}
              required
              form={form}
              disabled={isLoading}
              isLoading={isLoading}
            />
            <Button
              onClick={handleSubmit}
              loading={isLoading}
              disabled={isLoading}
              type="primary"
              htmlType="submit"
            >
              {`Add ${getName()}`}
            </Button>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default TagForwarder;

import React, { useState, useRef } from 'react';
import { Input, InputRef, Modal } from 'antd';
import FdaProductCodeBuilder from 'components/FdaProductCodeBuilder';
import { getCodes, useModal } from '@xbcb/ui-utils';
import { get } from 'lodash';
import { FormInstance } from 'antd/lib/form';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { FormItem } from '@xbcb/form-item-components';
import { pascalCase } from 'change-case';
import { createModalKey } from 'types';

interface FdaProductCodeProps {
  form: FormInstance;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  onChange?: any;
}

const FdaProductCode: React.FC<FdaProductCodeProps> = ({
  form,
  fullNamePath,
  localNamePath,
  disabled,
  readOnly,
  required,
  onChange,
}) => {
  const inputEl = useRef<InputRef>(null);
  const { openModal, closeModal, visible } = useModal(
    createModalKey(`PRODUCT_CODE_${pascalCase(fullNamePath.join('_'))}`),
  );
  // comingFromModal is a hack so that the Input doesn't refocus when closing the modal (this caused the modal to open again)
  const [comingFromModal, setComingFromModal] = useState(false);

  const allowedIndustryCodes = get(
    getCodes(),
    'CBP.CATAIR.PGA.allowedIndustry',
  );

  const { setFields, getFieldValue } = form;
  const programCode = getFieldValue([...fullNamePath, 'programCode']);
  const processingCode = getFieldValue([...fullNamePath, 'processingCode']);

  const fdaProductCode = getFieldValue([...fullNamePath, 'fdaProductCode']);
  let allowedCodes = get(allowedIndustryCodes, `FDA.${programCode}`);
  if (allowedCodes && !Array.isArray(allowedCodes)) {
    allowedCodes = allowedCodes[processingCode];
  }

  return (
    <div>
      <FormItem
        $itemSize={CssSize.SHORT}
        label="FDA Product Code"
        name={[...localNamePath, 'fdaProductCode']}
        rules={[{ required, message: ' ' }]}
        $readOnly={readOnly}
        required={required}
      >
        <Input
          ref={inputEl}
          onFocus={() => {
            if (comingFromModal) {
              setComingFromModal(false);
              if (inputEl && inputEl.current) {
                inputEl.current.blur();
              }
            } else {
              openModal();
            }
          }}
          onClick={openModal}
          onKeyDown={(e) => {
            // Don't let people edit anything in the Input manually, but still let them tab out of it.
            if (e.key !== 'Tab') e.preventDefault();
          }}
          disabled={disabled}
        />
      </FormItem>
      <Modal
        footer={null}
        open={visible}
        onCancel={() => {
          closeModal();
          setComingFromModal(true);
        }}
        destroyOnClose={true}
      >
        <FdaProductCodeBuilder
          allowedIndustryCodes={allowedCodes}
          onSubmit={(newCode: string) => {
            if (newCode !== fdaProductCode) {
              onChange(newCode);
              setFields([
                {
                  name: [...fullNamePath, 'fdaProductCode'],
                  value: newCode,
                },
              ]);
            }
            setComingFromModal(true);
            closeModal();
          }}
          fdaProductCode={fdaProductCode}
        />
      </Modal>
    </div>
  );
};

export default FdaProductCode;

import { createStore, applyMiddleware, Dispatch, Middleware } from 'redux';
import { useDispatch } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer } from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, Persistor } from 'redux-persist';
import { UiStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';

declare global {
  interface Window {
    dispatch: Dispatch;
    Intercom?: any;
  }
}

export let store: ReturnType<typeof createStore>;
export let persistor: Persistor;

export const customCreateStore = () => {
  const { stage } = getEnv();

  const logMiddleWare = createLogger({
    predicate: (getState, action) => {
      if (
        action.type.startsWith('FETCH_') ||
        action.type.startsWith('@@router/') ||
        action.type.endsWith('_LOADING')
      ) {
        return false;
      } else {
        return true;
      }
    },
    collapsed: true,
  }) as Middleware;

  const middleware = [];
  middleware.push(thunkMiddleware);

  if (stage !== UiStage.PROD) {
    middleware.push(logMiddleWare);
  }

  const composeEnhancers = composeWithDevTools({
    name: 'INLT',
    maxAge: 10,
    latency: 500,
  });

  const enhancer = composeEnhancers(applyMiddleware(...middleware));

  store = createStore(rootReducer, enhancer);
  persistor = persistStore(store);
  return store;
};

export type AppDispatch = typeof store.dispatch;
// Is this the right place to export this?
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default customCreateStore;

import styled from 'styled-components';
import { CssSize, CssSpace } from '@xbcb/ui-types';

export const StyledDivRowElement = styled.div<{ $marginRight?: string }>`
  display: inline-block;
  ${({ $marginRight }) =>
    $marginRight ? `margin-right: ${$marginRight};` : ''}
`;

export const StyledDivFlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  padding: ${CssSpace.SPACE_4};
  margin-bottom: ${CssSpace.SPACE_3};
`;

export const StyledOverflow = styled.div`
  max-height: ${CssSize.SHORT};
  overflow: auto;
`;

import { AnyObject } from '@xbcb/shared-types';
import { getHtsDataFromHierarchy } from './getHtsDataFromHierarchy';

/**
 * An HTS number is considered valid if it exists
 * inside the hierarchical data for the current country.
 * Navigating through the hierarchy by chapter/heading/subheading/etc.
 * should result in an object with an "id" property.
 */

export const isValidHtsNumber = (
  htsNumber: string,
  hierarchy: AnyObject,
): boolean => Boolean(getHtsDataFromHierarchy(htsNumber, hierarchy)?.id);

import React from 'react';
import { Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from '@xbcb/ui-types';
import { StyledCheckboxFormItem } from './styles';
import { FormItem } from '@xbcb/form-item-components';
import { useBundle } from '@amzn/react-arb-tools';
import { safeGetMessage } from '@xbcb/ui-utils';

export enum riskTypeEnum {
  Textiles = '0',
  AddCvd = '4',
  Fda = '5',
  General = '8',
}
const renderCheckbox = ({
  name,
  label,
  disabled,
  readOnly,
}: {
  name: NamePath;
  label: string;
  disabled?: boolean;
  readOnly?: boolean;
}) => (
  <FormItem $readOnly={readOnly} $inline={false}>
    <StyledCheckboxFormItem
      name={name}
      rules={[{ required: false }]}
      valuePropName="checked"
      $inline
    >
      <Checkbox disabled={disabled}>{label}</Checkbox>
    </StyledCheckboxFormItem>
  </FormItem>
);

export type UsIorContinuousBondRequestRiskUnderwritingInfoProps = {
  form: FormInstance;
  disabled?: boolean;
  readOnly?: boolean;
  baseNamePath: NamePath;
  riskType: string;
};
const UsIorContinuousBondRequestRiskUnderwritingInfo: React.FC<
  UsIorContinuousBondRequestRiskUnderwritingInfoProps
> = ({ form, baseNamePath, disabled, riskType, readOnly }) => {
  const [bundle] = useBundle('components.UsIorContinuousBondRequestInfoFields');

  const alcoholNamePath = [...baseNamePath, 'alcohol'];
  const honeyNamePath = [...baseNamePath, 'honey'];
  const tobaccoNamePath = [...baseNamePath, 'tobacco'];
  const ecigNamePath = [...baseNamePath, 'ecig'];
  const humanNamePath = [...baseNamePath, 'human'];

  // this condition is based on fastbond.roanoke.com so if there is a change on the portal, please make the same changes here
  // ref: https://roanoketrade.com/managing-your-customs-bonds-the-financial-review/
  const showUnderwritingInfo = [
    riskTypeEnum.Fda,
    riskTypeEnum.General,
  ].includes(riskType as riskTypeEnum);

  if (!showUnderwritingInfo) {
    [
      alcoholNamePath,
      honeyNamePath,
      tobaccoNamePath,
      ecigNamePath,
      humanNamePath,
    ].forEach((name) => form.setFields([{ name, value: undefined }]));
  }
  return showUnderwritingInfo ? (
    <>
      {safeGetMessage(bundle, 'underwriting_info_required')}
      {renderCheckbox({
        name: alcoholNamePath,
        label: safeGetMessage(bundle, 'alcoholic_beverages'),
        disabled,
        readOnly,
      })}
      {renderCheckbox({
        name: honeyNamePath,
        label: safeGetMessage(bundle, 'honey'),
        disabled,
        readOnly,
      })}
      {renderCheckbox({
        name: tobaccoNamePath,
        label: safeGetMessage(bundle, 'tobacco_products'),
        disabled,
        readOnly,
      })}
      {renderCheckbox({
        name: ecigNamePath,
        label: safeGetMessage(bundle, 'electronic_cigarettes'),
        disabled,
        readOnly,
      })}
      {renderCheckbox({
        name: humanNamePath,
        label: safeGetMessage(
          bundle,
          'ingestible_commodities_or_human_medical_substances_devices',
        ),
        disabled,
        readOnly,
      })}
    </>
  ) : null;
};

export default UsIorContinuousBondRequestRiskUnderwritingInfo;

import { LinkOutlined } from '@ant-design/icons';
import React, { memo } from 'react';
import type { UsPrincipalAdCvdCase } from '@xbcb/api-gateway-client';
import {
  StyledBlueTextBox,
  StyledContainer,
  StyledLinkButton,
  StyledList,
  StyledOverflow,
  StyledPopoverContent,
} from './styles';
import { Popover } from 'antd';

const overflowCutoff = 10;

export interface LineItemCaseHeaderProps {
  principalAdCvdCases: UsPrincipalAdCvdCase[];
  caseType: 'ad' | 'cv';
}

interface PrincipalCaseInfo {
  caseNumber: string;
  scopeUrl?: string;
}

const LineItemCaseHeader: React.FC<LineItemCaseHeaderProps> = ({
  principalAdCvdCases = [],
  caseType,
}) => {
  const principalCases: PrincipalCaseInfo[] = principalAdCvdCases.map(
    (principalCase) => ({
      caseNumber: principalCase.principalCaseNumber,
      scopeUrl: principalCase.scopeUrl,
    }),
  );
  const helperText = principalCases.length ? (
    <div>
      The following principal case numbers may apply to this product's HTS code:
      {` `}
      <PrincipalCaseList principalCases={principalCases} />
      You can view the scope or look up these case numbers in the ACE system to
      complete the remaining 3 digits.
    </div>
  ) : (
    'No recommendations available based on provided HTS code.'
  );
  return (
    <StyledContainer>
      <p>
        Provide 10-digit {caseType.toLocaleUpperCase()} case number to get the
        duty rate for this product.
      </p>
      <StyledBlueTextBox>{helperText}</StyledBlueTextBox>
    </StyledContainer>
  );
};

// Helper components

interface PrincipalCaseListProps {
  principalCases: PrincipalCaseInfo[];
}

const PrincipalCaseList: React.FC<PrincipalCaseListProps> = memo(
  ({ principalCases }) => {
    const primary = principalCases.slice(0, overflowCutoff);
    const overflow = principalCases.slice(overflowCutoff);
    const overflowLinks = (
      <StyledPopoverContent>
        {overflow.map(({ caseNumber, scopeUrl }, idx) => (
          <ScopeLink caseNumber={caseNumber} scopeUrl={scopeUrl} idx={idx} />
        ))}
      </StyledPopoverContent>
    );

    return (
      <StyledList>
        {primary.map(({ caseNumber, scopeUrl }, idx) => (
          <StyledLinkButton
            key={`${caseNumber}-${idx}`}
            type="link"
            href={scopeUrl}
            target="blank"
          >
            {caseNumber}
            <LinkOutlined style={{ marginLeft: 2 }} />
            {idx < primary.length - 1 || overflow.length ? ', ' : ''}
          </StyledLinkButton>
        ))}
        {overflow.length ? (
          <Popover content={overflowLinks}>
            <StyledOverflow>+ {overflow.length} more</StyledOverflow>
          </Popover>
        ) : null}
      </StyledList>
    );
  },
);

interface ScopeLinkProps {
  caseNumber: string;
  scopeUrl?: string;
  idx: number;
}

export const ScopeLink: React.FC<ScopeLinkProps> = memo(
  ({ caseNumber, scopeUrl, idx }) => {
    if (!scopeUrl) return <>{caseNumber}</>;
    return (
      <StyledLinkButton
        key={`${caseNumber}-${idx}`}
        type="link"
        href={scopeUrl}
        target="blank"
      >
        {caseNumber}
        <LinkOutlined style={{ marginLeft: 2 }} />
      </StyledLinkButton>
    );
  },
);

export default memo(LineItemCaseHeader);

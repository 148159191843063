import React from 'react';
import { Tooltip } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { AppRecordSecondaryHeaderContentProps } from 'routes';
import styled from 'styled-components';
import { getBadgeIcons } from 'components/AppRecordBadge';
import { getWorkOrderBadges } from 'libs/getRecordBadges';
import titleCase from 'libs/titleCase';
import MOTIcon from 'components/MOTIcon';
import copyToClipboard from 'libs/copyToClipboard';

const StyledDiv = styled.div`
  font-size: 18px;

  .anticon:not(:first-child) {
    margin-left: var(--space-4);
  }
`;

const StyledSpan = styled.span`
  margin-left: var(--space-4);
`;

const UsInBondSecondaryHeader: React.FC<
  AppRecordSecondaryHeaderContentProps
> = ({ record }) => {
  const badgeIcons = getBadgeIcons(record, getWorkOrderBadges);
  const motTitle = titleCase(record?.modeOfTransport);

  return (
    <StyledDiv>
      <Tooltip title="Copy the InBond URL" placement="top">
        <LinkOutlined onClick={() => copyToClipboard(window.location.href)} />
      </Tooltip>
      <Tooltip title={motTitle} placement="top">
        <StyledSpan>
          <MOTIcon mot={record?.modeOfTransport} size="18" />
        </StyledSpan>
      </Tooltip>
      {badgeIcons}
    </StyledDiv>
  );
};

export default UsInBondSecondaryHeader;

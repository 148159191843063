import { Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { TooltipProps } from 'antd/lib/tooltip';

import styled from 'styled-components';

export const StyledTooltip = styled<React.ComponentType<TooltipProps>>(Tooltip)`
  display: inline;
`;

export const StyledWarningOutlined = styled(WarningOutlined)`
  color: var(--primary-color);
`;

import { FormItem } from '@xbcb/form-item-components';
import { NamePath } from '@xbcb/ui-types';
import { Button, Form, Input, Row } from 'antd';
import React from 'react';
import { StyledCol, StyledSectionTitle } from '../../styles';

interface DynamicColumnParams {
  label: string;
  fieldPath: NamePath;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  minLength?: number;
}

export const DynamicColumn: React.FC<DynamicColumnParams> = ({
  label,
  fieldPath,
  disabled,
  readOnly,
  maxLength,
  minLength,
}) => (
  <StyledCol>
    <StyledSectionTitle>{label}</StyledSectionTitle>
    <Form.List name={fieldPath}>
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field) => {
              return (
                <Row key={field.key}>
                  <FormItem $readOnly={readOnly} {...field}>
                    <Input.TextArea
                      autoSize={true}
                      maxLength={maxLength}
                      minLength={minLength}
                      disabled={disabled}
                    />
                  </FormItem>
                  {!disabled && (
                    <Button onClick={() => remove(field.name)}>Remove</Button>
                  )}
                </Row>
              );
            })}

            {!disabled && <Button onClick={() => add()}>Add</Button>}
          </>
        );
      }}
    </Form.List>
  </StyledCol>
);

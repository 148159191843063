import { Button, Dropdown, Menu, Tooltip, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useCurrentUser } from 'libs/hooks';
import {
  AccountType,
  KeywordSearchOperator,
  RecordType,
} from '@xbcb/shared-types';
import { useHistory } from 'react-router-dom';
import * as uuid from 'uuid';
import {
  createOneMutation,
  getRecordFromResponse,
  SearchQuery,
} from '@xbcb/shared-queries';
import { useMutation, useQuery } from '@apollo/client';
import { documentTemplateFragments } from '@xbcb/document-queries';
import { DocumentTemplateStatus } from '@xbcb/document-types';
import { get } from 'lodash';

const CreateTemplateButton = (): JSX.Element => {
  const history = useHistory();
  const { accountType, forwarder, operator } = useCurrentUser();
  const operatorId = operator?.id;
  const createMutation = createOneMutation({
    recordName: RecordType.DOCUMENT_TEMPLATE,
    fields: `
      id
    `,
  });
  const [createRecord] = useMutation(createMutation);

  const searchQuery = SearchQuery({
    recordName: RecordType.DOCUMENT_TEMPLATE,
    fields: '...documentTemplateFields',
    fragments: documentTemplateFragments,
  });
  const searchQueryVariables = {
    input: {
      searchCriteria: {
        accountId: {
          values: operatorId,
          operator: KeywordSearchOperator.EQUALS,
        },
        status: {
          values: DocumentTemplateStatus.ACTIVATED,
          operator: KeywordSearchOperator.EQUALS,
        },
      },
    },
  };
  const { data: searchData } = useQuery(searchQuery, {
    skip: !operatorId,
    variables: searchQueryVariables,
  });

  const handleOptionClick = async ({ key }: any) => {
    const { results } = get(searchData, [`searchDocumentTemplates`]);
    const validTemplate = results?.filter(
      (template: any) => template.templateType === key,
    );
    if (validTemplate?.length) {
      const { jsonHtml, html } = validTemplate[0];
      const input = {
        name: key,
        templateType: key,
        account: {
          id: forwarder?.id || operator?.id,
        },
        // Use default json and html from existing operator id
        jsonHtml,
        html,
      };
      const response = await createRecord({
        variables: {
          idempotencyKey: uuid.v4(),
          input,
        },
      });
      const record = getRecordFromResponse(
        response,
        'create',
        RecordType.DOCUMENT_TEMPLATE,
      );
      message.success('Template created', 5.0);
      history.push(`document-templates/${record.id}`);
    } else {
      message.error(
        'Sorry, an error has occurred. Please try again later.',
        5.0,
      );
    }
  };

  const dropdownMenu = () => (
    <Menu
      className="create-template-button-dropdown"
      onClick={handleOptionClick}
    >
      {accountType === AccountType.FORWARDER && (
        <Menu.Item key="DIRECT_POWER_OF_ATTORNEY">Direct POA</Menu.Item>
      )}
    </Menu>
  );
  return (
    <>
      <Dropdown
        placement="bottomRight"
        overlay={dropdownMenu()}
        trigger={['click']}
        disabled={false}
      >
        <Button key="create" shape="round">
          <Tooltip placement="top" title="Create a new record">
            <PlusOutlined /> Create
          </Tooltip>
        </Button>
      </Dropdown>
    </>
  );
};

export default CreateTemplateButton;

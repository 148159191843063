import { RecordType } from '@xbcb/shared-types';
import { AppRecordRoute } from 'routes/types';
import { transformExchangeMessage } from 'libs/formTransforms/exchangeMessage';
import { InboxEmail } from 'routes/loadableComponents';
import { queryExchangeMessage } from './exchangeMessageQueries.query';

const exchangeMessageRoutes: AppRecordRoute[] = [
  {
    Page: InboxEmail,
    recordType: RecordType.EXCHANGE_MESSAGE,
    hideDefaultEditButton: () => true,
    transformRecordToInput: transformExchangeMessage.toForm,
    kebabMenuItems: [
      {
        key: 'addEmailToWorkOrder',
        text: 'Assign to WorkOrder',
        action: (): void | Promise<void> => {
          // TODO add logic to assign email to workOrder
        },
      },
    ],
    queries: { get: queryExchangeMessage },
    getTitleField: () => 'subject',
  },
];

export default exchangeMessageRoutes;

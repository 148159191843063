import { CssSize, NamePath } from '@xbcb/ui-types';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { capitalCase } from 'change-case';
import { PreferredDutyCollectPartyType } from '@xbcb/finance-types';
import { Select, Option, FormItem } from '@xbcb/form-item-components';

interface PreferredDutyCollectPartyTypeProps {
  form: FormInstance;
  required?: boolean;
  fieldName?: NamePath;
  disabled?: boolean;
}

const PreferredDutyCollectPartyTypeSelect: React.FC<
  PreferredDutyCollectPartyTypeProps
> = ({
  form,
  required,
  fieldName = ['preferredDutyCollectPartyType'],
  disabled,
}) => {
  return (
    <FormItem name={fieldName} rules={[{ required }]} $itemSize={CssSize.SHORT}>
      <Select disabled={disabled}>
        {Object.values(PreferredDutyCollectPartyType).map((pdcp) => (
          <Option value={pdcp} key={pdcp}>
            {capitalCase(pdcp)}
          </Option>
        ))}
      </Select>
    </FormItem>
  );
};

export default PreferredDutyCollectPartyTypeSelect;

import React from 'react';
import { RecordType } from '@xbcb/shared-types';
import { DataCySuffix } from '@xbcb/ui-types';
import EINNumber from 'components/EINNumber';
import FEINumber from 'components/FEINumber';
import PartyForm from 'components/PartyForm';
import { RecordProps } from 'routes';
import { PointOfContact } from '@xbcb/form-components';
import { Divider } from 'antd';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

const Facility: React.FC<RecordProps> = ({
  form,
  disabled,
  readOnly,
  hideDocs,
}) => {
  const [partyBundle] = useBundle('components.Party');
  const receivingPointOfContactNamePath = ['receivingPointOfContact'];
  const usComplianceDetailsBaseNamePath = ['complianceDetails', 'us'];
  const usComplianceDetailsEinNamePath = [
    ...usComplianceDetailsBaseNamePath,
    'ein',
  ];
  const usComplianceDetailsFeiNamePath = [
    ...usComplianceDetailsBaseNamePath,
    'feiNumber',
  ];
  return (
    <PartyForm
      recordType={RecordType.FACILITY}
      readOnly={readOnly}
      disabled={disabled}
      form={form}
      hideDocs={hideDocs}
      addressRequired
      hideBusinessStructure={true}
      additionalCompanyInfo={
        <>
          <EINNumber
            form={form}
            readOnly={readOnly}
            disabled={disabled}
            localNamePath={usComplianceDetailsEinNamePath}
            fullNamePath={usComplianceDetailsEinNamePath}
          />
          <FEINumber
            form={form}
            readOnly={readOnly}
            disabled={disabled}
            field={usComplianceDetailsFeiNamePath}
            $inline
          />

          <Divider />
          <h4>{safeGetMessage(partyBundle, 'receiving_point_of_contact')}</h4>
          <PointOfContact
            readOnly={readOnly}
            disabled={disabled}
            form={form}
            fullNamePath={receivingPointOfContactNamePath}
            localNamePath={receivingPointOfContactNamePath}
            defaultCountryCode="1"
            allowClearCountryCode={false}
            dataCySuffix={DataCySuffix.RECEIVING_POINT_OF_CONTACT}
          />
        </>
      }
    />
  );
};

export default Facility;

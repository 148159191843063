import React from 'react';
import { MehTwoTone, FrownTwoTone, SmileTwoTone } from '@ant-design/icons';
import {
  MessagingPartner,
  ExchangeMessageType,
} from '@xbcb/message-exchange-types';
import { ConnectionStatus, OperatingRegion } from '@xbcb/shared-types';

export const connectionStatusIconMap = {
  [OperatingRegion.US]: {
    [ConnectionStatus.CONNECTED]: <SmileTwoTone twoToneColor="green" />,
    [ConnectionStatus.DISCONNECTED]: <FrownTwoTone twoToneColor="red" />,
    [ConnectionStatus.UNKNOWN]: <MehTwoTone twoToneColor="grey" />,
  },
};

export const connectionStatusTooltipTextMap = {
  [OperatingRegion.US]: {
    [ConnectionStatus.CONNECTED]: 'CBMS is connected to ACE',
    [ConnectionStatus.DISCONNECTED]: 'CBMS is not connected to ACE',
    [ConnectionStatus.UNKNOWN]: 'CBMS connection to ACE is unknown',
  },
};

export const channelConnectionQueryInput = {
  [OperatingRegion.US]: {
    sender: MessagingPartner.US_CBP,
    receiver: MessagingPartner.INLT,
    messageType: ExchangeMessageType.CATAIR,
    historyMinutes: 4320, // last 72 hours
  },
};

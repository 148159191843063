import { Children, cloneElement, isValidElement, ReactNode } from 'react';
import { AnyObject } from '@xbcb/shared-types';

interface CloneReactChildrenArgs {
  children: ReactNode;
  childProps: AnyObject;
}

// I was worried about the performance of using cloneElement, but apparently this is the exact use case for it and performance is okay
// https://stackoverflow.com/questions/54922160/react-cloneelement-in-list-performance
export const cloneReactChildren = ({
  children,
  childProps,
}: CloneReactChildrenArgs) =>
  Children.map(
    Children.only(children), // util method that ensures only one child is passed.
    (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, childProps);
      } else {
        return child;
      }
    },
  );

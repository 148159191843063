import { WorkOrderRoute } from '.';
import { RecordType } from '@xbcb/shared-types';
import { GbCustomsEntry } from '../../loadableComponents';
import { gbCustomsEntryFragments } from '@xbcb/work-order-queries';
import {
  getSharedCustomsEntrySubmitButtons,
  sharedCustomsEntryKebabMenuItems,
  sharedCustomsEntryFields,
} from '../utils';
import { WorkOrderType } from '@xbcb/work-order-types';
import { transformEuCustomsEntry } from 'libs/formTransforms/workOrder/euCustomsEntry';
import CustomsEntrySecondaryHeader from 'components/CustomsEntrySecondaryHeader';

const gbCustomsEntryFields = `
  ...gbCustomsEntryFields
`;

const gbCustomsEntryRoute: WorkOrderRoute = {
  Page: GbCustomsEntry,
  recordType: RecordType.GB_CUSTOMS_ENTRY,
  fields: `${sharedCustomsEntryFields}${gbCustomsEntryFields}`,
  fragments: gbCustomsEntryFragments,
  SecondaryHeaderContents: [CustomsEntrySecondaryHeader],
  transformUpdateRecordInput: transformEuCustomsEntry.toSchema,
  defaultEditMode: false,
  kebabMenuItems: sharedCustomsEntryKebabMenuItems,
  submitButtons: getSharedCustomsEntrySubmitButtons(
    WorkOrderType.GbCustomsEntry,
  ),
};

export default gbCustomsEntryRoute;

export default [
  '91',
  '51',
  '52',
  '53',
  '57',
  '58',
  '59',
  '61',
  '62',
  '03',
  '21',
  '24',
  '31',
  '72',
  '73',
  '80',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '89',
  '90',
  '96',
  '99',
];

import { RecordType } from '@xbcb/shared-types';

import { ModalKey } from 'types';
import { transformBulkUploadRequest } from 'libs/formTransforms/bulkUploadRequest';
import CreateBulkUploadRequestModal, {
  CreateBulkUploadRequestModalProps,
} from 'components/CreateBulkUploadRequestModal';

const createBulkComplianceUploadRequestModalProps: CreateBulkUploadRequestModalProps =
  {
    modalKey: ModalKey.CREATE_BULK_COMPLIANCE_UPLOAD_REQUEST,
    recordName: RecordType.BULK_COMPLIANCE_UPLOAD_REQUEST,
    tagKey: `bulkComplianceUploadRequestId`,
    title: 'Bulk Compliance Upload Request',
    transformCreateRecordInput: transformBulkUploadRequest.toSchema,
  };

const CreateBulkComplianceUploadRequestModal = (): JSX.Element =>
  CreateBulkUploadRequestModal(createBulkComplianceUploadRequestModalProps);

export default CreateBulkComplianceUploadRequestModal;

import { useQueryParams } from 'libs/hooks';
import { WorkOrderTaskType } from '@xbcb/work-order-types';

export const isWorkOrderTaskType = (
  value: unknown,
): value is WorkOrderTaskType =>
  Object.values(WorkOrderTaskType).includes(value as WorkOrderTaskType);

export const useWorkOrderTaskType = () => {
  const [value, setWorkOrderTaskType] = useQueryParams('task');

  let workOrderTaskType;
  if (isWorkOrderTaskType(value)) {
    workOrderTaskType = value;
  } else if (value) {
    setWorkOrderTaskType(undefined);
  }

  return [workOrderTaskType, setWorkOrderTaskType] as const;
};

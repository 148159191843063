import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { INLTSwitch } from '@xbcb/form-item-components';
import { NamePath } from '@xbcb/ui-types';
import { StyledDiv, StyledTooltip } from './styles';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

type SupportsPersonalEffectsProps = {
  disabled?: boolean;
  form: FormInstance;
  readOnly?: boolean;
  localNamePath: NamePath;
  fullNamePath: NamePath;
  $inline?: boolean;
};

const SupportsPersonalEffects: React.FC<SupportsPersonalEffectsProps> = ({
  disabled,
  form,
  readOnly,
  localNamePath,
  fullNamePath,
  $inline,
}) => {
  const [partyBundle] = useBundle('components.Party');
  const [sharedBundle] = useBundle('shared');
  const supportsPersonalEffects = form.getFieldValue(fullNamePath);
  return (
    <StyledDiv $inline={$inline}>
      <INLTSwitch
        onWord={safeGetMessage(sharedBundle, 'yes')}
        offWord={safeGetMessage(sharedBundle, 'no')}
        label={safeGetMessage(partyBundle, 'supports_personal_effects')}
        field={localNamePath}
        readOnly={readOnly}
        disabled={disabled}
        initialValue={supportsPersonalEffects === true}
      />
      <StyledTooltip
        title={safeGetMessage(partyBundle, 'supports_personal_effects_tooltip')}
        spaceTop
      />
    </StyledDiv>
  );
};

export default SupportsPersonalEffects;

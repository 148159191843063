import React from 'react';
import { Form, Button, Divider } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { PlusOutlined } from '@ant-design/icons';
import { isRequired, shouldUpdate, show, safeGetMessage } from '@xbcb/ui-utils';
import { NamePath } from '@xbcb/ui-types';
import { MinusButton } from '@xbcb/button-components';
import { LocationForm, PointOfContact } from '@xbcb/form-components';
import { StyledDiv } from './styles';
import Emails from 'components/Emails';
import { useTheme } from 'styled-components';
import { useBundle } from '@amzn/react-arb-tools';

export type AccountingPointOfContactsProps = {
  form: FormInstance;
  disabled?: boolean;
  readOnly?: boolean;
  enforceNamePattern?: boolean;
  fullNamePath: NamePath;
};

const AccountingPointOfContacts = ({
  form,
  disabled,
  readOnly,
  enforceNamePattern,
  fullNamePath,
}: AccountingPointOfContactsProps) => {
  const [accountingPointOfContactsBundle] = useBundle(
    'components.AccountingPointOfContacts',
  );
  const theme = useTheme();
  if (!show({ readOnly, form, field: fullNamePath })) return null;
  return (
    <Form.List name={fullNamePath} minimum={1}>
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => {
              const pointOfContactFieldName = 'pointOfContact';
              const addressFieldName = 'address';
              const accountingCcEmailsFieldName = 'accountingCcEmails';
              const baseFullNamePath = [
                ...fullNamePath,
                field.name,
                pointOfContactFieldName,
              ];
              const fieldsToCheck = [
                [...baseFullNamePath, 'name'],
                [...baseFullNamePath, 'title'],
                [...baseFullNamePath, 'email'],
                [...baseFullNamePath, 'phone', 'number'],
                [...baseFullNamePath, 'phone', 'extension'],
              ];
              const getSomeSubFieldsFilledOut = () =>
                isRequired(form, fieldsToCheck);
              const showHeader =
                index === 0 && (!readOnly || getSomeSubFieldsFilledOut());
              return (
                <>
                  {showHeader && (
                    <h4>
                      {safeGetMessage(
                        accountingPointOfContactsBundle,
                        'accounting_point_of_contacts',
                      )}
                    </h4>
                  )}
                  <Form.Item
                    shouldUpdate={shouldUpdate(fieldsToCheck)}
                    noStyle
                    key={field.key}
                  >
                    {() => {
                      const someSubFieldsFilledOut =
                        getSomeSubFieldsFilledOut();
                      if (readOnly && !someSubFieldsFilledOut) return null;
                      return (
                        <div>
                          <StyledDiv>
                            <div>
                              <PointOfContact
                                readOnly={readOnly}
                                disabled={disabled}
                                form={form}
                                enforceNamePattern={enforceNamePattern}
                                fullNamePath={baseFullNamePath}
                                localNamePath={[
                                  field.name,
                                  pointOfContactFieldName,
                                ]}
                                requiredMap={{
                                  name: someSubFieldsFilledOut,
                                  email: someSubFieldsFilledOut,
                                }}
                                defaultCountryCode="1"
                              />
                            </div>
                            {!readOnly && fields.length > 1 && (
                              <MinusButton
                                label={safeGetMessage(
                                  accountingPointOfContactsBundle,
                                  'point_of_contact',
                                )}
                                firstRow
                                onRemove={() => remove(field.name)}
                                disabled={disabled}
                              />
                            )}
                          </StyledDiv>
                          <Emails
                            form={form}
                            $itemSize={theme.size.medium}
                            label={safeGetMessage(
                              accountingPointOfContactsBundle,
                              'additional_emails_to_cc',
                            )}
                            name={[field.name, accountingCcEmailsFieldName]}
                            fullNamePath={[
                              ...fullNamePath,
                              accountingCcEmailsFieldName,
                            ]}
                            readOnly={readOnly}
                            disabled={disabled}
                          />
                          <LocationForm
                            ior={false}
                            readOnly={readOnly}
                            disabled={disabled}
                            form={form}
                            spaceBottom
                            fullNamePath={[
                              ...fullNamePath,
                              field.name,
                              addressFieldName,
                            ]}
                            localNamePath={[field.name, addressFieldName]}
                          />
                          {index < fields.length - 1 && <Divider />}
                        </div>
                      );
                    }}
                  </Form.Item>
                </>
              );
            })}
            {!readOnly && (
              <Button type="dashed" onClick={add} disabled={disabled}>
                <PlusOutlined />
                {safeGetMessage(
                  accountingPointOfContactsBundle,
                  'point_of_contact_capitalized',
                )}
              </Button>
            )}
          </>
        );
      }}
    </Form.List>
  );
};

export default AccountingPointOfContacts;

export enum AocCode {
  GFR = 'GFR',
  PFR = 'PFR',
  FME = 'FME',
  RNO = 'RNO',
  REG = 'REG',
  DLS = 'DLS',
  DA = 'DA',
  IND = 'IND',
  FCE = 'FCE',
  SID = 'SID',
  VOL = 'VOL',
  PM = 'PM#',
  DDM = 'DDM',
  DEV = 'DEV',
  DFE = 'DFE',
  IDE = 'IDE',
  LST = 'LST',
  LWC = 'LWC',
  IRC = 'IRC',
  ACC = 'ACC',
  ANC = 'ANC',
}

export const aocFieldsMap = {
  [AocCode.GFR]: ['foodFacilityRegistration', 'number'],
  [AocCode.PFR]: ['foodFacilityRegistration', 'number'],
  [AocCode.FME]: ['foodFacilityRegistration', 'exemption'],
  [AocCode.RNO]: ['railCarNumbers'],
  [AocCode.REG]: ['drugRegistrationNumber'],
  [AocCode.DLS]: ['drugListingNumber'],
  [AocCode.DA]: ['drugApplicationNumber'],
  [AocCode.IND]: ['investigationNewDrugApplicationNumber'],
  [AocCode.FCE]: ['foodCanningEstablishmentNumber'],
  [AocCode.SID]: ['scheduleIdentifierNumber'],
  [AocCode.VOL]: ['foodVolume'],
  [AocCode.PM]: ['devicePremarketNumber'],
  [AocCode.DDM]: ['deviceDomesticManufacturer'],
  [AocCode.DEV]: ['deviceForeignManufacturer'],
  [AocCode.DFE]: ['deviceForeignExporter'],
  [AocCode.IDE]: ['investigationalDeviceExemptionNumber'],
  [AocCode.LST]: ['deviceListingNumber'],
  [AocCode.LWC]: ['electrode'],
  [AocCode.IRC]: ['impactResistanceLensCertification'],
  [AocCode.ACC]: ['eprcAccessionNumber'],
  [AocCode.ANC]: ['eprcAnnualReportAccessionNumber'],
};

// used in FDAForms, clear AOC fields
export const aocFields = [
  'foodFacilityRegistration',
  'railCarNumbers',
  'drugRegistrationNumber',
  'drugListingNumber',
  'drugApplicationNumber',
  'investigationNewDrugApplicationNumber',
  'foodCanningEstablishmentNumber',
  'scheduleIdentifierNumber',
  'foodVolume',
  'devicePremarketNumber',
  'deviceDomesticManufacturer',
  'deviceForeignManufacturer',
  'deviceForeignExporter',
  'investigationalDeviceExemptionNumber',
  'deviceListingNumber',
  'electrode',
  'impactResistanceLensCertification',
];

import { gql } from '@apollo/client';
import type { EffectiveUsHtssInput } from '@xbcb/api-gateway-client';
import { searchUsHtsFields } from './fields';

export const EFFECTIVE_US_HTS = gql`
  query effectiveUsHts ($input: EffectiveUsHtssInput!, $effectiveDate: Date) {
    effectiveUsHtss(input: $input) {
      ${searchUsHtsFields}
    }
  }
`;

export const getEffectiveUsHtssInput = (
  htsNumbers: string[],
  effectiveDate: string,
): EffectiveUsHtssInput => ({
  htsNumbers,
  effectiveDate,
});

// only used in FormSummary during onboarding
export default {
  forwarder: {
    importerId: true,
    tags: true,
    dispatchEmail: true,
    email: true,
    fromEmail: true,
    ctpat: true,
    licenseNumber: true,
    scac: true,
    ein: true,
    duns: true,
    dispatchPhone: true,
    dispatchPhoneCountry: true,
    phone: true,
    phoneCountry: true,
  },
  importer: {
    dba: true,
    officerEmail: true,
    officerName: true,
    officerTitle: true,
    secondaryOfficerName: true,
    secondaryOfficerEmail: true,
    secondaryOfficerTitle: true,
  },
  profile: {
    mobilePhone: true,
    mobilePhoneCountry: true,
  },
  company: {
    dba: true,
    officerEmail: true,
    officerName: true,
    officerTitle: true,
    secondaryOfficerName: true,
    secondaryOfficerEmail: true,
    secondaryOfficerTitle: true,
    accountingName: true,
    accountingEmail: true,
  },
  duties: {
    payerUnitNumber: true,
    paymentMethod: true,
  },
  bond: {
    hasReason: true,
    duties: true,
    coverage: true,
    quote: true,
    complex: true,
  },
};

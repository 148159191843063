import React from 'react';
import { Skeleton, Tooltip } from 'antd';
import { capitalCase } from 'change-case';
import { useBundle } from '@amzn/react-arb-tools';
import type {
  WorkOrderTask,
  WorkOrderTaskDefinition,
} from '@xbcb/api-gateway-client';
import { safeGet } from '@xbcb/js-utils';
import { safeGetMessage } from '@xbcb/ui-utils';
import { StyledDiv, StyledParagraph } from './styles';

interface WorkOrderTaskInfoCardProps {
  loading?: boolean;
  workOrderTask: WorkOrderTask;
}

const WorkOrderTaskInfoCard: React.FC<WorkOrderTaskInfoCardProps> = ({
  loading,
  workOrderTask,
}) => {
  const [taskInfoCardBundle] = useBundle('components.WorkOrderTaskInfoCard');
  const [taskStatusBundle] = useBundle(
    'types.workOrder.enums.WorkOrderTaskStatus',
  );
  const {
    assignee = {},
    definition = {} as WorkOrderTaskDefinition,
    name,
    status,
  } = workOrderTask;
  const { estimatedDuration = 15 } = definition;
  const currentAssignee = safeGet(
    assignee,
    'name',
    safeGetMessage(taskInfoCardBundle, 'nobody'),
  );

  return loading ? (
    <Skeleton active />
  ) : (
    <StyledDiv>
      <h3>{capitalCase(name)}</h3>
      <hr></hr>
      <Tooltip title={currentAssignee}>
        <StyledParagraph
          $overflow="hidden"
          $textOverflow="ellipsis"
          $whitespace="nowrap"
        >
          {safeGetMessage(taskInfoCardBundle, 'assigned_to', {
            assignee: currentAssignee,
          })}
        </StyledParagraph>
      </Tooltip>
      <StyledParagraph>
        {safeGetMessage(taskInfoCardBundle, 'status', {
          status: safeGetMessage(
            taskStatusBundle,
            status.toLowerCase(),
            undefined,
            capitalCase(status),
          ),
        })}
      </StyledParagraph>
      <StyledParagraph>
        {safeGetMessage(taskInfoCardBundle, 'estimated_duration', {
          estimatedDuration,
        })}
      </StyledParagraph>
    </StyledDiv>
  );
};

export default WorkOrderTaskInfoCard;

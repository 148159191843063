import { pick } from 'lodash';
import { AnyObject } from '@xbcb/shared-types';
import { transformWorkOrderBase } from './base';

export const transformUsInBond = {
  toSchema: ({
    input,
    existingRecord,
  }: {
    input: AnyObject;
    existingRecord: AnyObject;
  }) => {
    const cleanedOutput = transformWorkOrderBase.toSchema({
      input,
      existingRecord,
    });

    delete cleanedOutput.memos;
    // broker is not updatable
    delete cleanedOutput.broker;

    // Only take id version for usIor if provided
    const usIor = cleanedOutput.ior?.usIor;
    if (usIor) cleanedOutput.ior.usIor = pick(usIor, ['id', 'version']);

    // Only take id version for usConsignee if provided
    const usConsignee = cleanedOutput.consignee?.usConsignee;
    if (usConsignee) {
      cleanedOutput.consignee.usConsignee = pick(usConsignee, [
        'id',
        'version',
      ]);
    }

    const carrierTruckerFields = ['trucker', 'name'];
    cleanedOutput.cbpInBonds = cleanedOutput.cbpInBonds?.map(
      (cbpInBond: any) => {
        delete cbpInBond.number;
        // statuses are output only fields
        delete cbpInBond.status;
        const cbpInBondTrucker = cbpInBond.carrier?.trucker;
        if (cbpInBondTrucker) {
          // Only take `trucker` (which contains id/version), and allowed embeddedFields (name)
          cbpInBond.carrier.trucker = pick(
            cbpInBondTrucker,
            carrierTruckerFields,
          );
        }
        cbpInBond.bills = cbpInBond.bills?.map((bill: any) => {
          delete bill.status;
          return bill;
        });
        cbpInBond.actions = cbpInBond.actions?.map((action: any) => {
          delete action.status;
          const actionTrucker = action.carrier?.trucker;
          if (actionTrucker) {
            // Only take `trucker` (which contains id/version), and allowed embeddedFields (name)
            action.carrier.trucker = pick(actionTrucker, carrierTruckerFields);
          }
          return action;
        });
        return cbpInBond;
      },
    );

    return cleanedOutput;
  },
};

import { gql } from '@apollo/client';

export const SEARCH_ADCVD_CASES = gql`
  query searchAdCvdCaseTable($input: SearchAdCvdCaseTableInput!) {
    searchAdCvdCaseTable(input: $input) {
      results {
        id
        caseNumber
      }
    }
  }
`;

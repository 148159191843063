import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import { KeywordSearchOperator, RecordType } from '@xbcb/shared-types';
import { UsIorCbpStatus } from '@xbcb/party-types';
import HelpLink from 'components/HelpLink';
import { SingleFormCard } from '@xbcb/display-components';
import ReadOnlyData from 'components/ReadOnlyData';
import { StyledDiv, StyledIsVoidedDiv } from './styles';
import { safeGet } from '@xbcb/js-utils';
import { SearchQuery, getRecordFromResponseV2 } from '@xbcb/shared-queries';
import { determineBusinessUnit } from './determineBusinessUnit';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

const recordName = RecordType.US_IOR_CONTINUOUS_BOND_REQUEST;

export const searchUsIorContinuousBondRequestQuery = SearchQuery({
  recordName,
  fields: 'bondNumber group { forwarders { forwarder { tags { key value }}}}',
});

export interface BondInfoCardProps {
  record?: any; // For create us-ior usecase.
}

const BondInfoCard = (props: BondInfoCardProps) => {
  const [partyBundle] = useBundle('components.Party');
  const { record = {} } = props;
  const { future } = record.continuousBonds || {};
  const past = safeGet(record.continuousBonds, 'past', []);
  const present = safeGet(record.continuousBonds, 'present', {});
  const bonds = [...past, present];
  const presentBondIndex = bonds.length ? bonds.length - 1 : 0;
  if (future) bonds.push(future);
  const bondNumbers = bonds.reduce((numbers, bond) => {
    if (bond.number) numbers.push(bond.number);
    return numbers;
  }, [] as string[]);

  const { data: queryData } = useQuery(searchUsIorContinuousBondRequestQuery, {
    variables: {
      input: {
        searchCriteria: {
          operatorId: {
            values: record?.operator?.id,
            operator: KeywordSearchOperator.EQUALS,
          },
          deletedTime: { operator: KeywordSearchOperator.DOES_NOT_EXIST },
          bondNumber: {
            values: bondNumbers,
            operator: KeywordSearchOperator.ONE_OF,
          },
        },
      },
    },
    skip: !record.id || !record?.operator?.id || !bondNumbers?.length,
  });
  const { results = [] } =
    getRecordFromResponseV2({
      response: { data: queryData },
      crudOrSearchType: 'search',
      recordName,
    }) || {};

  const [currentIndex, setCurrentIndex] = useState(presentBondIndex);

  const handleClickPrevious = useCallback(
    (e: React.MouseEvent) => {
      setCurrentIndex(currentIndex - 1);
    },
    [currentIndex],
  );

  const handleClickNext = useCallback(
    (e: React.MouseEvent) => {
      setCurrentIndex(currentIndex + 1);
    },
    [currentIndex],
  );

  const isVoided = record.cbpStatus === UsIorCbpStatus.VOIDED;
  const viewingPresentBond = currentIndex === presentBondIndex;
  const bondInfoFields = isVoided
    ? ['voided']
    : !isEmpty(present) || !viewingPresentBond
    ? [
        'bondTypeCode',
        'bondSuretyCode',
        'bondAmount',
        'bondNumber',
        'bondEffectiveDate',
        'bondTerminationDate',
        'bondExpires',
        'businessUnit',
        'pms',
        'payingDirect',
      ]
    : ['bond', 'pms', 'payingDirect'];

  const { pmsStatus, poaMethod, payerUnitNumber } = record;

  const currentBond = bonds[currentIndex];
  const businessUnit = determineBusinessUnit({
    currentBondNumber: currentBond.number,
    usIorContinuousBondRequests: results,
  });
  const data = {
    bondTypeCode: currentBond?.typeCode,
    bondSuretyCode: currentBond?.suretyCode,
    bondAmount: currentBond?.amount,
    bondNumber: currentBond?.number,
    bondRenewalTime: currentBond?.renewalDate,
    bondEffectiveDate: currentBond?.effectiveDate,
    bondTerminationDate: currentBond?.terminationDate,
    pms: pmsStatus,
    payingDirect: poaMethod,
    payerUnitNumber,
    voided: isVoided,
    businessUnit,
  };

  const cardButtons = bonds.length > 1 && (
    <StyledDiv>
      <Button
        type="default"
        shape="circle"
        icon={<LeftOutlined />}
        size="small"
        onClick={handleClickPrevious}
        disabled={currentIndex < 1}
      />
      <Button
        type="default"
        shape="circle"
        icon={<RightOutlined />}
        size="small"
        onClick={handleClickNext}
        disabled={currentIndex >= bonds.length - 1}
      />
    </StyledDiv>
  );

  return (
    <SingleFormCard
      title={safeGetMessage(partyBundle, 'bond_info')}
      extraContent={cardButtons}
      titlePosition="top"
    >
      <ReadOnlyData
        displayMapKey={RecordType.SHIPPER}
        data={data}
        keys={bondInfoFields}
        append={
          {
            // TODO convert PMSOverrideButton to TS and implement the mutation
            // PMS: <PMSOverrideButton importerId={record.importerId} />,
          }
        }
      />
      {isVoided && (
        <StyledIsVoidedDiv>
          <HelpLink
            link="https://help.inlt.com/customs-brokerage/reactivating-a-voided-importer"
            text={safeGetMessage(partyBundle, 'how_to_reactivate')}
          />
        </StyledIsVoidedDiv>
      )}
    </SingleFormCard>
  );
};

export default BondInfoCard;

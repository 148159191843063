import styled from 'styled-components';
import { Button } from 'antd';
import { CssSpace } from '@xbcb/ui-types';

export const StyledButton = styled(Button)``;

export const StyledDiv = styled.div`
  margin-right: ${CssSpace.SPACE_4};
  button.ant-btn {
    border: none;
    .anticon {
      font-size: 18px;
      font-weight: 400px;
    }
  }
`;

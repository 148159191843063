import styled from 'styled-components';
import { Button } from 'antd';

export const StyledDiv = styled.div`
  margin-right: ${({ theme }) => theme.spacing.space_2};
  margin-left: ${({ theme }) => theme.spacing.space_2};
  button.ant-btn {
    border: none;
    .anticon {
      font-size: ${({ theme }) => theme.font.size.small};
      font-weight: 400px;
    }
  }
`;

export const StyledButton = styled(Button)``;

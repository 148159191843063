import styled from 'styled-components';
import { Switch } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { CssSpace } from '@xbcb/ui-types';

export const StyledSwitch = styled(Switch)`
  align-self: flex-end;
  margin-right: ${CssSpace.SPACE_5};
`;

export const StyledWarningParagraph = styled.p`
  color: var(--warning-color);
`;

export const StyledBlackDiv = styled.div`
  color: black;
`;

export const StyledCenteredParagraph = styled.p`
  text-align: center;
`;

export const StyledCloseCircleOutlined = styled(CloseCircleOutlined)`
  color: red;
`;

import React, { Component } from 'react';
import JSONTree from 'react-json-tree';
import treeTheme from 'libs/treeTheme';
import './style.less';

class JSONModal extends Component {
  render() {
    const { description, response } = this.props;
    return (
      <>
        <h4 className="json-query-modal-title">{description}</h4>
        <div className="json-query-modal-response-data">
          <JSONTree
            data={response}
            hideRoot={true}
            theme={treeTheme}
            invertTheme={false}
            shouldExpandNode={() => true}
          />
        </div>
      </>
    );
  }
}
export default JSONModal;

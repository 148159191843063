import React, { useState } from 'react';
import csvBuilder from 'libs/csvBuilder';
import ReadOnlyData from 'components/ReadOnlyData';
import { Tooltip, message, Table } from 'antd';
import { AppRecordProvidedProps, RecordProps } from 'routes';
import { SingleFormCard } from '@xbcb/display-components';
import { StyledTitleDiv, StyledButton } from './style';
import { getRecordCreatedTime } from 'libs/getRecordCreatedTime';
import { DownloadOutlined } from '@ant-design/icons';

export type BulkUploadRequestProps = {
  headers: string[];
  resultMapFunc: (result: any) => any;
};

/*
 * A function to create a BulkUploadRequest Page.  Created so that BulkChargesRequest and BulkMilestoneRequest could re-use common code.
 * @param {BulkUploadRequestProps} props - the column headers related to this BulkUploadRequest record and the function required to map the results field into the columns corresponding to those headers
 * @returns {React.FC<AppRecordProvidedProps>} - a React.FC that takes RecordProps, as required by appRecordRoutes's Page field
 */
const bulkUploadRequestPageMaker = ({
  headers,
  resultMapFunc,
}: BulkUploadRequestProps): React.FC<AppRecordProvidedProps> => {
  const BulkUploadRequest: React.FC<RecordProps> = ({ record }) => {
    const [isDownLoadButtonDisabled, setIsDownloadButtonDisabled] =
      useState(false);

    const columns = headers.map((header) => ({
      title: header,
      dataIndex: header,
      key: header,
    }));
    const numberOfFailures = record.totalFailures || 0;
    const data = record.results?.map(resultMapFunc) || [];
    const handleDownload = async () => {
      setIsDownloadButtonDisabled(true);
      message.info('Downloading...');
      await csvBuilder({
        headers,
        data,
        fileName: `BulkUploadRequestTableSummary`,
        returnFile: false,
      });
      message.success(`Downloaded ${numberOfFailures} rows`);
      setIsDownloadButtonDisabled(false);
    };

    // summary table
    const bulkUploadRequestSummary = [
      'uploader',
      'status',
      'createTime',
      'totalRows',
    ];
    const summaryData = {
      uploader: record.created?.client?.name,
      status: record.status,
      createdTime: getRecordCreatedTime(record),
      totalRows: record.totalRows,
    };
    return (
      <>
        <SingleFormCard title="Summary">
          <ReadOnlyData
            displayMapKey={'bulkUploadRequestSummary'}
            data={summaryData}
            keys={bulkUploadRequestSummary}
          />
        </SingleFormCard>
        <SingleFormCard title="Results">
          <Table
            bordered
            pagination={{
              showSizeChanger: true,
              position: ['bottomRight'],
              pageSizeOptions: ['25', '50', '100'],
              defaultPageSize: 25,
              defaultCurrent: 1,
              total: record?.totalRows,
            }}
            columns={columns}
            dataSource={data}
          />
        </SingleFormCard>
        <StyledTitleDiv>
          <h4>Download Bulk Upload Results</h4>
          <Tooltip title="Download Results Table">
            <StyledButton
              data-testid={'download-results-table'}
              shape="circle"
              icon={<DownloadOutlined />}
              onClick={handleDownload}
              disabled={isDownLoadButtonDisabled}
            />
          </Tooltip>
        </StyledTitleDiv>
      </>
    );
  };
  return BulkUploadRequest;
};

export default bulkUploadRequestPageMaker;

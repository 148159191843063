import { RecordType } from '@xbcb/shared-types';
import { gbIorActivationFragments } from 'libs/sharedQueries';
import { EuIorActivation } from '../../loadableComponents';
import euIorActivationRoute from './euIorActivation';

const gbIorActivationRoute = euIorActivationRoute({
  page: EuIorActivation,
  recordType: RecordType.GB_IOR_ACTIVATION,
  fields: `...gbIorActivationFields`,
  fragments: gbIorActivationFragments,
});

export default gbIorActivationRoute;

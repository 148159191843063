import { KebabMenuItemKey } from '@xbcb/ui-types';
import { AppRecordKebabMenuItem } from '../../types';
import { blockUnblockTaskShowFunctionHelper } from './blockUnblockTaskShowFunction';
import { blockUnblockTaskActionFunctionHelper } from './blockUnblockTaskActionFunction';

export const appRecordBlockTaskMenuItem: AppRecordKebabMenuItem = {
  text: 'Block task',
  key: KebabMenuItemKey.BLOCK_TASK,
  action: async ({ record, location }) => {
    await blockUnblockTaskActionFunctionHelper(true, record, location);
  },
  confirmAction: true,
  show: ({ existingRecord, location }) => {
    return blockUnblockTaskShowFunctionHelper(true, existingRecord, location);
  },
};

import { getCodes } from '@xbcb/ui-utils';

export const pgaHasProcessingCodes = (
  agencyCode: string,
  programCode = '',
): boolean => {
  /** For all PGAs, processingCode is required iff
   * the program code for the agency includes "processing" information.
   */
  const agencyCodes = getCodes().CBP.CATAIR.PGA.agency;
  const agency = agencyCodes[agencyCode];
  return Boolean(agency?.program[programCode]?.processing);
};

import { Component, ErrorInfo, ReactNode } from 'react';
import { reportError } from '@xbcb/ui-utils';

interface Props {
  children?: ReactNode;
  fallback?: JSX.Element;
}

interface State {
  hasError: boolean;
}

/**
 * This class component wraps children components in an error boundary that fails safe.
 * An error thrown in children components will be caught and logged here.
 * There is currently no way to write an error boundary as a function component.
 *
 * @class FailSafeErrorBoundary
 * @see https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 *
 */
export class FailSafeErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, _: ErrorInfo): void {
    reportError(error);
  }

  public render(): ReactNode | null {
    if (this.state.hasError) {
      return this.props.fallback ?? null;
    }

    return this.props.children ?? null;
  }
}

import { paramCase } from 'change-case';
import { pluralize } from '@xbcb/js-utils';
import { RecordType } from '@xbcb/shared-types';

export const getRecordPath = ({
  recordType,
  id,
}: {
  recordType: RecordType;
  id: string;
}): string => `/${pluralize(paramCase(recordType))}/${id}`;

import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledConstituentElementsDiv = styled.div`
  .info-tooltip {
    margin-left: ${CssSpace.SPACE_3};
    display: inline-block;
  }
  h4 {
    display: inline-block;
  }
  margin-bottom: ${CssSpace.SPACE_4};
`;

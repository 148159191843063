import { recordFields, recordActionFields } from '@xbcb/shared-queries';
import { inquiryFields } from './inquiryFields';

const cbpInBondCarrierFragment = `fragment cbpInBondCarrierFields on CbpInBondCarrier {
  ein
  code
  trucker {
    trucker {
      id
      version
    }
  }
}`;

const cbpInBondFragment = `fragment cbpInBondFields on CbpInBond {
  entryType
  modeOfTransport
  foreignPortOfDestination
  goodsNowAt
  importingConveyanceArrivalPort
  consignee {
    usConsignee {
      ...on UsConsignee {
        id
        version
      }
      ...on UsIor {
        id
        version
      }
    }
  }
  usPortOfDestination
  valueOfGoods
  previousCbpInBond {
    date
    number
    port
  }
  carrier {
    ...cbpInBondCarrierFields
  } 
  subjectToFdaBioterrorismAct
  actions {
    status
    actualDate
    firmsCode
    type
    billNumber
    arrivalPort
    carrier {
      ...cbpInBondCarrierFields
    }
    city
    stateCode
  }
  bills {
    number
    quantity
    status
  }
  number
  status
  additionalReferenceIdentifiers {
    qualifier
    referenceIdentifier
  }
}`;

export const usInBondFragment = `fragment usInBondFields on UsInBond {
  ...recordFields
  badges
  loadType
  group {
    id
    shipment {
      id
      created {
        time
      }
      friendlyId
      clientIdentifier
      preferredDutyCollectPartyType
      version
      legs {
        transportationServiceName
      }
      clientReferences {
        bookingId
      }
      tags {
        key
        value
      }
    }
    shipper {
      id
      status
      billingDetails {
        disbursement
        subscriptionPlan
        status
        accountingSystemCustomerReferences {
          type
          value
        }
        customFeeConfig {
          singleEntryBond {
            rate
            minimum
          }
          disbursement {
            rate
          }
        }
      }
      forwarders {
        nodes {
          id
          billingDetails {
            disbursement
            subscriptionPlan
          }
        }
      }
    }
    forwarders {
      forwarder {
        id
        billingDetails {
          disbursement
          subscriptionPlan
          status
          customFeeConfig {
            singleEntryBond {
              rate
              minimum
            }
            disbursement {
              rate
            }
          }
        }
      }      
    }
  }
  operator {
    id
  }
  stateMachine {
    id
    version
  }
  status
  deadline
  milestones {
    id
    version
    name
    occurenceTime
    source
    created {
      client {
        name
      }
    }
  }
  entryDate
  modeOfTransport
  arrival {
    time
    portOfUnlading
  }
  departure {
    time
    portOfLading
    country
  }
  conveyance {
    conveyanceName
    grossWeight
    tripNumber
    containerized
  }
  containerNumbers
  masterBills {
    number
    houseBills {
      number
      quantity
    }
  }
  ior {
    usIor {
      id
      version
      status
      name
      iorNumber {
        value
      }
    }
  }
  broker {
    usCustomsBroker {
      id
      version
    }
  }
  consignee {
    usConsignee {
      id
      version
      name
    }
  }
  cbpInBonds {
    ...cbpInBondFields
  }
  ${inquiryFields}
}`;

export const usInBondFragments = `
 ${usInBondFragment}
 ${recordFields}
 ${recordActionFields}
 ${cbpInBondFragment}
 ${cbpInBondCarrierFragment}
 `;

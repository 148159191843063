import styled from 'styled-components';
import { WarningOutlined } from '@ant-design/icons';
import { CssSpace } from '@xbcb/ui-types';
import { spaceBottom } from '@xbcb/ui-styles';

export const StyledDiv = styled.div`
  font-size: 18px;

  .anticon:not(:first-child) {
    margin-left: ${CssSpace.SPACE_4};
  }
`;

export const StyledWarningOutline = styled(WarningOutlined)`
  color: var(--primary-color);
`;

export const StyledParagraph = styled.p`
  ${spaceBottom}
`;

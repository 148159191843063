import { Button } from 'antd';
import styled from 'styled-components';
import { AppRadioGroup } from '@xbcb/form-item-components';
import { spaceBottom } from '@xbcb/ui-styles';
import { CssSpace } from '@xbcb/ui-types';

// Unique case where we want to override the margin of FormItem for this
// specific modal. Thus, we need to use `!important` rather than change the
// functionality of FormItem for this one use case
// TODO remove important and use `&` instead
export const StyledAppRadioGroup = styled(AppRadioGroup)`
  width: 205px;
  margin: 0 auto ${CssSpace.SPACE_4} auto !important;
`;

export const StyledH4 = styled.h4`
  ${spaceBottom}
`;

export const StyledH3 = styled.h3`
  ${spaceBottom}
`;

export const StyledButton = styled(Button)`
  width: ${CssSpace.SPACE_8};
  margin: 0 auto;
  display: block;
`;

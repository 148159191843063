import React from 'react';
import { ToolOutlined } from '@ant-design/icons';
import { Dropdown, MenuItemProps } from 'antd';
import DelayedTooltip from 'components/DelayedTooltip';
import { StyledButton } from './style';
import { useModal, safeGetMessage } from '@xbcb/ui-utils';
import { ModalKey } from 'types';
import StartOnboardingModal from 'components/StartOnboardingModal';
import { useBundle } from '@amzn/react-arb-tools';

const TeamActionsButton: React.FC = () => {
  const [appHeaderBundle] = useBundle('components.AppHeader');
  const { openModal, visible, closeModal } = useModal(
    ModalKey.ONBOARDING_MODAL,
  );
  const handleOptionClick: MenuItemProps['onClick'] = ({ key }) => {
    if (key === 'startOnboarding') {
      openModal();
    }
  };
  const dropdownItems = [
    {
      key: 'startOnboarding',
      label: safeGetMessage(appHeaderBundle, 'start_onboarding'),
    },
  ];

  return (
    <div className="team-actions button-wrapper">
      <StartOnboardingModal visible={visible} closeModal={closeModal} />
      <DelayedTooltip title={safeGetMessage(appHeaderBundle, 'team_actions')}>
        <Dropdown
          placement="bottomRight"
          menu={{ items: dropdownItems, onClick: handleOptionClick }}
        >
          <StyledButton disabled={false} shape="circle">
            <ToolOutlined />
          </StyledButton>
        </Dropdown>
      </DelayedTooltip>
    </div>
  );
};

export default TeamActionsButton;

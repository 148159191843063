import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, Tooltip } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { useTheme } from 'styled-components';
import {
  UsFdaDrugForm,
  UsFdaRadiationEmittingDeviceForm,
} from '@xbcb/compliance-types';
import {
  CountrySelect,
  FormItem,
  Option,
  Select,
} from '@xbcb/form-item-components';
import { RecordType } from '@xbcb/shared-types';
import { getCodes, getNumber, selectFilter, show } from '@xbcb/ui-utils';
import { aocFields } from 'components/Aoc/aocFields';
import FdaAocs from 'components/FdaAocs';
import FdaCustomsAgent from 'components/FdaCustomsAgent';
import FdaProductCode from 'components/FdaProductCode';
import IntendedUseSelect from 'components/IntendedUseSelect';
import ProductPackaging from 'components/ProductPackaging';
import RadiationStandards from 'components/RadiationStandards';
import RecordSelect from 'components/RecordSelect';
import TradeName from 'components/TradeName';
import { clearFields } from 'libs/clearFields';
import {
  hasLaboratoryRequirement,
  hasIntendedUseRequirement,
  hasTradeNameRequirement,
  hasDrugFormRequirement,
  hasDeviceFormRequirement,
} from 'libs/entryValidation';
import isPackagingNeeded from 'libs/isPackagingNeeded';
import { isComponent } from 'libs/medicalDeviceIndicators';
import subjectToPNFunction from 'libs/subjectToPN';
import { intendedUseCodes } from 'selectors';
import { PgaFormBaseProps } from 'types';
import {
  StyledDeviceFormRadioGroup,
  StyledDrugFormRadioGroup,
  StyledFdaFormDiv,
  StyledFlexDiv,
  StyledParagraph,
} from './styles';

export interface FdaFormProps extends PgaFormBaseProps {
  mot?: any;
  packagingLevelsOverride?: any;
}

const FdaForm: React.FC<FdaFormProps> = ({
  fullNamePath,
  localNamePath,
  readOnly,
  required,
  disabled,
  pgaFlag,
  mot,
  packagingLevelsOverride,
}) => {
  const form = Form.useFormInstance();
  const theme = useTheme();
  const consumptionEntryType =
    fullNamePath && fullNamePath.includes('invoices');

  const { setFields } = form;

  const codes = getCodes();
  const { source, foodAcidityIndustry } = codes.CBP.CATAIR.PGA;
  const sourceTypeCodes = source;
  const foodAcidityIndustryCodes = foodAcidityIndustry;

  const programCode = Form.useWatch([...fullNamePath, 'programCode'], form);
  const processingCode = Form.useWatch(
    [...fullNamePath, 'processingCode'],
    form,
  );
  const drugForm = Form.useWatch([...fullNamePath, 'drugForm'], form);
  const intendedUse = Form.useWatch([...fullNamePath, 'intendedUse'], form);

  const productCode =
    Form.useWatch([...fullNamePath, 'fdaProductCode'], form) || '';
  // if this is true, either  PN conditions have been previously met or they don't actually apply.
  const pnConditionsMet = Form.useWatch(
    [...fullNamePath, 'priorNotice', 'conditionsNotApplicable'],
    form,
  );
  const performanceStandard = Form.useWatch(
    [...fullNamePath, 'performanceStandard'],
    form,
  );

  const deviceForm = Form.useWatch([...fullNamePath, 'deviceForm'], form);

  const sourceCountryType = Form.useWatch(
    [...fullNamePath, 'source', 'sourceCountryType'],
    form,
  );

  const subjectToPN = subjectToPNFunction({
    pgaFlag,
    processingCode,
    programCode,
  });

  const needPackaging = isPackagingNeeded(
    subjectToPN,
    pnConditionsMet,
    performanceStandard,
  );

  const showTradeName = hasTradeNameRequirement({
    performanceStandard,
    programCode,
    processingCode,
  });
  const showPerformanceStandard =
    ((programCode === 'DEV' && processingCode === 'RED') ||
      programCode === 'RAD') &&
    (performanceStandard || !readOnly);
  const industryCode = productCode.slice(0, 2);
  const classCode = productCode.charAt(2);
  // PIC: Process Indicator Code
  const pic = productCode.charAt(4);
  // LACF: Low-Acid Canned Food
  const lacf =
    foodAcidityIndustryCodes.includes(industryCode) && ['F', 'E'].includes(pic);
  // AF: Acidified Food
  const af = foodAcidityIndustryCodes.includes(industryCode) && pic === 'I';
  const showFoodAcidity = lacf || af;
  const isInfantFormula =
    industryCode === '40' && ['C', 'N', 'O', 'P', 'R'].includes(classCode);
  const iucCodes = intendedUseCodes({
    agencyCode: 'FDA',
    programCode,
    processingCode,
    drugForm,
  });
  const showLotNumber =
    subjectToPN && !pnConditionsMet && (showFoodAcidity || isInfantFormula);

  const showDrugForm = hasDrugFormRequirement(programCode, processingCode);
  const showDeviceForm =
    hasDeviceFormRequirement(programCode) && (deviceForm || !readOnly);
  const showLaboratory = hasLaboratoryRequirement(programCode, processingCode);
  const showIntendedUse = hasIntendedUseRequirement(
    programCode,
    processingCode,
  );

  // drugFormRadioOptions
  const drugFormRadioOptions = [
    {
      value: UsFdaDrugForm.FINISHED,
      label: 'Finished Dosage Form',
    },
    {
      value: UsFdaDrugForm.API,
      label: 'Active Pharmaceutical Ingredient / Bulk Drug Substance',
    },
  ];

  // sourceTypeOptions
  const sourceTypeCodeOptions: any[] = [];
  if (programCode === 'FOO') {
    if (processingCode) {
      sourceTypeCodeOptions.push(processingCode === 'NSF' ? '262' : '39');
    }
  } else if (programCode === 'DEV') {
    if (intendedUse) {
      sourceTypeCodeOptions.push(isComponent(intendedUse) ? '30' : '39');
    }
  } else if (programCode === 'RAD') {
    if (deviceForm) {
      sourceTypeCodeOptions.push(deviceForm === 'component' ? '30' : '39');
    }
  } else {
    sourceTypeCodeOptions.push('39');
    if (['BIO', 'DRU', 'DEV', 'RAD', 'VME'].includes(programCode)) {
      sourceTypeCodeOptions.push('30');
    }
  }

  const sourceTypeOptions = sourceTypeCodeOptions.map((codeOption) => (
    <Option key={codeOption} value={codeOption}>
      {get(sourceTypeCodes, [codeOption, 'name'])}
    </Option>
  ));

  if (!sourceCountryType && sourceTypeCodeOptions.length === 1) {
    // TODO this is causing a React warning about updating during a render. Find an alternative.
    setFields([
      {
        name: [...fullNamePath, 'source', 'sourceCountryType'],
        value: sourceTypeCodeOptions[0],
      },
    ]);
  }

  // deviceFormRadioOptions
  const deviceFormRadioOptions = [
    {
      value: UsFdaRadiationEmittingDeviceForm.COMPONENT,
      label: 'Component',
      className: 'component',
    },
    {
      value: UsFdaRadiationEmittingDeviceForm.FINISHED,
      label: 'Finished product',
      className: 'finished',
    },
  ];

  return (
    <StyledFdaFormDiv>
      {showDrugForm && (
        <StyledDrugFormRadioGroup
          readOnly={readOnly}
          disabled={disabled}
          label="Drug Form"
          options={drugFormRadioOptions}
          localNamePath={[...localNamePath, 'drugForm']}
          rules={[{ required, message: ' ' }]}
        />
      )}
      {showDeviceForm && (
        <StyledDeviceFormRadioGroup
          readOnly={readOnly}
          disabled={disabled}
          label="Device form"
          options={deviceFormRadioOptions}
          localNamePath={[...localNamePath, 'deviceForm']}
          rules={[{ required, message: ' ' }]}
        />
      )}
      {subjectToPN && (
        <>
          {show({
            readOnly,
            form,
            field: [...fullNamePath, 'priorNotice', 'conditionsNotApplicable'],
          }) && (
            <FormItem
              $itemSize={theme.size.short_medium}
              name={[
                ...localNamePath,
                'priorNotice',
                'conditionsNotApplicable',
              ]}
              rules={[{ required: false }]}
              valuePropName="checked"
              $readOnly={readOnly}
            >
              <Checkbox
                disabled={disabled}
                onChange={clearFields({
                  form,
                  fullNamePath,
                  fields: ['lotNumber', 'packaging'],
                })}
              >
                Prior Notice Requirements Previously Met?
              </Checkbox>
            </FormItem>
          )}
          {pnConditionsMet && (
            <FormItem
              $itemSize={theme.size.short}
              className="pnc"
              name={[...localNamePath, 'priorNotice', 'confirmationNumber']}
              rules={[{ required, message: ' ' }]}
              getValueFromEvent={getNumber(12)}
              label="Prior Notice Confirmation Number"
              $readOnly={readOnly}
            >
              <Input disabled={disabled} />
            </FormItem>
          )}
        </>
      )}
      {showPerformanceStandard && (
        <FormItem
          name={[...localNamePath, 'performanceStandard']}
          rules={[{ required: false }]}
          valuePropName="checked"
          $readOnly={readOnly}
        >
          <Checkbox
            disabled={disabled}
            onChange={clearFields({
              form,
              fullNamePath,
              fields: ['tradeName', 'packaging'],
            })}
          >
            <span>Subject to a performance standard</span>
            <Tooltip
              placement="right"
              title={
                <StyledParagraph>
                  Radiation-emitting electronic products subject to{' '}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.fda.gov/Radiation-EmittingProducts/ElectronicProductRadiationControlProgram/GettingaProducttoMarket/RegulatedProducts/ucm2007147.htm"
                  >
                    U.S. Federal Performance Standard
                  </a>{' '}
                  require submission of{' '}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.fda.gov/downloads/AboutFDA/ReportsManualsForms/Forms/UCM080778.pdf"
                  >
                    Form FDA-2877, Declaration for Imported Electronic Products
                    Subject to Radiation Control Standards
                  </a>
                  , at the time of entry.
                </StyledParagraph>
              }
            >
              <InfoCircleOutlined className="inline-tooltip" />
            </Tooltip>
          </Checkbox>
        </FormItem>
      )}
      {/* for PG01 */}
      {showIntendedUse && (
        <IntendedUseSelect
          disabled={disabled}
          readOnly={readOnly}
          form={form}
          required={required}
          localNamePath={[...localNamePath, 'intendedUse']}
          fullNamePath={[...fullNamePath, 'intendedUse']}
          codes={iucCodes}
        />
      )}
      {/* for PG02 */}
      <FdaProductCode
        form={form}
        fullNamePath={fullNamePath}
        localNamePath={localNamePath}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        onChange={clearFields({ form, fullNamePath, fields: aocFields })}
      />
      {/* for PG06 */}
      <StyledFlexDiv>
        <FormItem
          $itemSize={theme.size.short}
          label="Source Type"
          name={[...localNamePath, 'source', 'sourceCountryType']}
          rules={[{ required, message: ' ' }]}
          $readOnly={readOnly}
        >
          <Select
            disabled={disabled}
            showSearch
            allowClear
            filterOption={selectFilter}
            notFoundContent="None Found"
            dropdownMatchSelectWidth={false}
          >
            {sourceTypeOptions}
          </Select>
        </FormItem>
        <CountrySelect
          $itemSize={theme.size.short}
          localNamePath={[...localNamePath, 'source', 'countryCode']}
          required={required}
          label="Country"
          disabled={disabled}
          readOnly={readOnly}
        />
      </StyledFlexDiv>
      {subjectToPN && (
        <>
          <CountrySelect
            $itemSize={theme.size.short}
            localNamePath={[...localNamePath, 'shipmentCountryCode']}
            required={required}
            label="Country of shipment"
            disabled={disabled}
            readOnly={readOnly}
          />
          {/* TODO: maybe add a tooltip saying that this is required if the food has been rejected from another country */}
          {show({
            readOnly,
            form,
            field: [...fullNamePath, 'refusalCountryCode'],
          }) && (
            <CountrySelect
              $itemSize={theme.size.short}
              localNamePath={[...localNamePath, 'refusalCountryCode']}
              label="Country of refusal"
              disabled={disabled}
              readOnly={readOnly}
            />
          )}
        </>
      )}
      {showTradeName && (
        <TradeName
          localNamePath={localNamePath}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {/* PG19 */}
      {showLaboratory && (
        <StyledFlexDiv>
          <FormItem
            $itemSize={theme.size.short}
            label="Laboratory Type"
            name={[...localNamePath, 'laboratoryTypeCode']}
            rules={[{ required, message: ' ' }]}
            $readOnly={readOnly}
            required={required}
          >
            <Select
              disabled={disabled}
              showSearch
              allowClear
              filterOption={selectFilter}
              notFoundContent="None Found"
              dropdownMatchSelectWidth={false}
            >
              <Option value="ITL">Independent Third Party Laboratory</Option>
              <Option value="LAB">Laboratory or Clinical Site</Option>
            </Select>
          </FormItem>

          <RecordSelect
            label="Laboratory"
            fullNamePath={
              consumptionEntryType
                ? [...fullNamePath, 'laboratory', 'facility']
                : [...fullNamePath, 'laboratory']
            }
            localNamePath={
              consumptionEntryType
                ? [...localNamePath, 'laboratory', 'facility']
                : [...localNamePath, 'laboratory']
            }
            recordType={RecordType.FACILITY}
            form={form}
            create={true}
            disabled={disabled}
            readOnly={readOnly}
            $itemSize={theme.size.short}
          />
        </StyledFlexDiv>
      )}
      <FdaAocs
        form={form}
        fullNamePath={fullNamePath}
        localNamePath={localNamePath}
        mot={mot}
        required={required}
        subjectToPN={subjectToPN}
        disabled={disabled}
        readOnly={readOnly}
      />
      {/* for PG25 */}
      {showLotNumber && (
        <FormItem
          $itemSize={theme.size.short}
          label="Lot Number"
          name={[...localNamePath, 'lotNumber']}
          rules={[{ required, message: ' ', max: 25 }]}
          $readOnly={readOnly}
          required={required}
        >
          <Input disabled={disabled} />
        </FormItem>
      )}
      {subjectToPN && (
        <FdaCustomsAgent
          fullNamePath={fullNamePath}
          localNamePath={localNamePath}
          form={form}
          readOnly={readOnly}
          disabled={disabled}
          type="foreignSupplierVerificationProgram"
        />
      )}
      {programCode === 'DEV' && (
        <FdaCustomsAgent
          fullNamePath={fullNamePath}
          localNamePath={localNamePath}
          form={form}
          readOnly={readOnly}
          disabled={disabled}
          type="deviceInitialImporter"
        />
      )}
      {performanceStandard && (
        <RadiationStandards
          disabled={disabled}
          readOnly={readOnly}
          form={form}
          fullNamePath={[...fullNamePath, 'radiationDeclaration']}
          localNamePath={[...localNamePath, 'radiationDeclaration']}
        />
      )}
      {/* for PG26 */}
      <ProductPackaging
        packagingLevelsOverride={packagingLevelsOverride}
        disabled={disabled}
        readOnly={readOnly}
        fullNamePath={[...fullNamePath, 'packaging']}
        localNamePath={[...localNamePath, 'packaging']}
        form={form}
        required={required && needPackaging}
        optional={!needPackaging}
        programCode={programCode}
        agencyCode={'FDA'}
      />
    </StyledFdaFormDiv>
  );
};

export default FdaForm;

import styled from 'styled-components';

export const StyledDiv = styled.div`
  background-color: white;
  padding: ${({ theme }) => theme.spacing.space_4};
`;

export const StyledParagraph = styled.p<{
  $overflow?: string;
  $textOverflow?: string;
  $whitespace?: string;
}>`
  ${({ $overflow }) => ($overflow ? `overflow: ${$overflow};` : '')}
  ${({ $textOverflow }) =>
    $textOverflow ? `text-overflow: ${$textOverflow};` : ''}
  ${({ $whitespace }) => ($whitespace ? `white-space: ${$whitespace};` : '')}
  margin-bottom: ${({ theme }) => theme.spacing.space_3};
`;

import { Button, Form, Input, Menu } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { get } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { possibleZeroValueHts } from '@xbcb/entry-utils/dist/lib/possibleZeroValueHts';
import { isRealHts as realHTS } from '@xbcb/entry-utils/dist/lib/htsUtil';
import {
  HiddenField,
  INLTSwitch as Switch,
  SimpleSelect,
} from '@xbcb/form-item-components';
import { AnyObject } from '@xbcb/shared-types';
import { LabelAbove } from '@xbcb/static-text-components';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { getCodes, shouldUpdate } from '@xbcb/ui-utils';
import CurrencyField from 'components/CurrencyField';
import DecimalQuantity from 'components/DecimalQuantity';
import TrumpListTooltip from 'components/TrumpListTooltip';
import dutyComputation from 'libs/dutyDisplay';
import extraPGAs from 'libs/extraPGAs';
import { defaultUOM, isUOMEditAllowedForHts } from 'libs/uomDefaults';
import { checkUOMOrder, reorderQuantities } from 'libs/uomUtils';
import {
  StyledDiv,
  StyledDropdown,
  StyledExtraComplianceDiv,
  StyledFormInlineDiv,
  StyledFormItem,
  StyledHtsDutyParagraph,
  StyledHtsInfoDiv,
  StyledTariffUnitValueWrapperDiv,
  StyledTextParagraph,
  StyledUomQuantityDiv,
} from './styles';
import { useWorkOrderTaskConfiguration } from 'libs/hooks';
import { HtsObject, ValueField } from 'types';
import UnitAssistInput from 'components/UnitAssistInput';
import AddUnitAssistButton from 'components/AddUnitAssistButton';
import { isAssistEnabled } from 'libs/featureFlags';

const lineLevelNamePathDepth = 4;

export interface TariffProps {
  form: FormInstance;
  formDisabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  originCountryCode?: string;
  tab?: string;
  htsObj: HtsObject;
  consumptionEntryType?: boolean;
  isPsc?: boolean;
  setCvTabManuallyAdded: (show: boolean) => void;
  setAdTabManuallyAdded: (show: boolean) => void;
  adTabVisible: boolean;
  cvTabVisible: boolean;
}

// cant use disabled prop beacuse it messes with antd-tabs
const Tariff: React.FC<TariffProps> = ({
  form,
  fullNamePath,
  formDisabled,
  readOnly,
  required,
  originCountryCode,
  localNamePath,
  tab,
  htsObj,
  consumptionEntryType,
  isPsc,
  setAdTabManuallyAdded,
  setCvTabManuallyAdded,
  adTabVisible,
  cvTabVisible,
}: TariffProps) => {
  const { readWriteControlsConfig } = useWorkOrderTaskConfiguration();
  const { getFieldValue, setFields } = form;

  const htsKey = useMemo(() => [...fullNamePath, 'htsNumber'], [fullNamePath]);
  let htsNumber = getFieldValue(htsKey);
  htsNumber = htsNumber || 'Tariff Info';

  const codes = getCodes();

  const unitReportingQuantities = getFieldValue([
    ...fullNamePath,
    'unitReportingQuantities',
  ]);

  useEffect(() => {
    /**
     * If we are loading a saved record, the order of the tariff.reportingQuantities
     * may be different from the order in which the UOMs appear in the HTS object, which
     * throws off the index-based form mapping below. In this case, syncronize the order.
     */
    if (
      unitReportingQuantities?.length &&
      htsObj?.uom?.length &&
      unitReportingQuantities.length === htsObj?.uom?.length
    ) {
      if (!checkUOMOrder(unitReportingQuantities, htsObj.uom)) {
        // There is a UOM/unit mismatch
        const newReportingQuantities = reorderQuantities(
          unitReportingQuantities,
          htsObj.uom,
        );
        setFields([
          {
            name: [...fullNamePath, 'unitReportingQuantities'],
            value: newReportingQuantities,
          },
        ]);
      }
    }
  }, [fullNamePath, htsObj, setFields, unitReportingQuantities]);

  const unitCodes = codes.CBP.CATAIR.unit;
  const lockedUnitCodes = codes.CBP.CATAIR.lockedUnit;

  const sameAsCbpField = [...fullNamePath, 'pgaDescription', 'sameAsCbp'];

  const normalPGAFlags: string[] = get(htsObj, 'pga', []);

  const sameAsCbpFieldValue = form.getFieldValue([
    ...fullNamePath,
    'pgaDescription',
    'sameAsCbp',
  ]);

  if (sameAsCbpFieldValue === undefined && !isPsc) {
    setFields([{ name: sameAsCbpField, value: true }]);
  }

  const extraPGAOptions = Object.entries(extraPGAs).reduce(
    (acc: AnyObject, [extraFlag, { equivalentFlags, label }]) => {
      if (
        !normalPGAFlags.includes(extraFlag) && // check that "main" flag isn't already present (the key in extraPGAs)
        !equivalentFlags.some(
          (equivalentFlag) => normalPGAFlags.includes(equivalentFlag), // check that "equivalent" flags aren't already present.
        )
      ) {
        acc[extraFlag] = label;
      }
      return acc;
    },
    {},
  );
  const ad = 'ad';
  const cv = 'cv';
  const AdCvdCaseNumberMenu = (
    <Menu
      onClick={(e) => {
        if (e.key === ad) {
          setAdTabManuallyAdded(true);
        } else if (e.key === cv) {
          setCvTabManuallyAdded(true);
        }
      }}
    >
      {!adTabVisible && <Menu.Item key={ad}>Add Anti-Dumping Duty</Menu.Item>}
      {!cvTabVisible && <Menu.Item key={cv}>Add Countervailing Duty</Menu.Item>}
    </Menu>
  );
  const extraPGAFlags = getFieldValue([...fullNamePath, 'extraPgaFlags']) || [];
  const pgaFlags = [...normalPGAFlags, ...extraPGAFlags];

  // currency is stored at the invoice level. Expected fullNamePath: `invoices[0].products[0].lines[0].tariffs[0]`
  let currencySelectProps: AnyObject;
  if (consumptionEntryType) {
    currencySelectProps = {
      currencyNamePath: [...fullNamePath.slice(0, 2), 'currency'],
    };
  } else {
    currencySelectProps = { currency: 'USD' };
  }

  const prefillUnitLevelBasedOnLineLevel = (
    unitField: ValueField,
    lineField: ValueField,
  ) => {
    const unitLevelFieldPath = [...fullNamePath, unitField, 'value'];
    const unitLevelFieldValue = getFieldValue(unitLevelFieldPath);

    const lineLevelPath = [...fullNamePath.slice(0, lineLevelNamePathDepth)];
    const lineLevelQuantity = getFieldValue([...lineLevelPath, 'quantity']);
    const lineLevelFieldValue = getFieldValue([
      ...lineLevelPath,
      lineField,
      'value',
    ]);

    if (
      lineLevelFieldValue &&
      lineLevelQuantity &&
      realHTS(htsNumber) &&
      (unitLevelFieldValue === undefined || unitLevelFieldPath === null)
    ) {
      const calculatedUnitLevelValue = lineLevelFieldValue / lineLevelQuantity;

      setFields([
        {
          name: unitLevelFieldPath,
          value: calculatedUnitLevelValue,
        },
      ]);
    }
  };

  prefillUnitLevelBasedOnLineLevel(
    ValueField.UNIT_VALUE,
    ValueField.TOTAL_VALUE,
  );

  prefillUnitLevelBasedOnLineLevel(
    ValueField.UNIT_ASSIST,
    ValueField.TOTAL_ASSIST,
  );

  const hasAssist = () => {
    const tariffAssistPath = [...fullNamePath, 'unitAssist'];
    return !!getFieldValue(tariffAssistPath);
  };

  const isOnlyOrRealHts = () => {
    return !get(htsObj, 'additionalHTS') || realHTS(htsNumber);
  };

  return (
    <>
      <div>
        <StyledHtsInfoDiv>
          {htsObj && <StyledTextParagraph>{htsObj.text}</StyledTextParagraph>}
          <div>
            {htsObj && (
              <StyledHtsDutyParagraph>
                Duty: {dutyComputation({ htsNumber, ...htsObj })}
              </StyledHtsDutyParagraph>
            )}
            {htsObj &&
              htsObj.trumpList &&
              (!originCountryCode || originCountryCode === 'CN') && (
                <TrumpListTooltip list={htsObj.trumpList} />
              )}
          </div>
          {htsObj && htsObj.endDate && <p>End Date: {htsObj.endDate}</p>}
        </StyledHtsInfoDiv>
        <StyledFormInlineDiv>
          {isOnlyOrRealHts() && (
            <Form.Item
              noStyle
              shouldUpdate={shouldUpdate([[...fullNamePath, 'unitValue']])}
            >
              {() => (
                <StyledTariffUnitValueWrapperDiv>
                  <LabelAbove text="Unit Value" />
                  <CurrencyField
                    form={form}
                    {...currencySelectProps}
                    debounce={true}
                    fullNamePath={[...fullNamePath, 'unitValue']}
                    localNamePath={[...localNamePath, 'unitValue']}
                    $itemSize={CssSize.TINY_SHORT}
                    precision={6}
                    required={
                      required ||
                      !!readWriteControlsConfig[
                        'invoices.products.lines.tariffs.unitvalue'
                      ]?.required
                    }
                    max={100000000}
                    readOnly={
                      !isUOMEditAllowedForHts(htsNumber) ||
                      !!readWriteControlsConfig[
                        'invoices.products.lines.tariffs.unitvalue'
                      ]?.readOnly
                    }
                    disabled={!isUOMEditAllowedForHts(htsNumber)}
                    min={possibleZeroValueHts(htsNumber) ? 0.0 : undefined}
                  />
                </StyledTariffUnitValueWrapperDiv>
              )}
            </Form.Item>
          )}
          {isAssistEnabled && isOnlyOrRealHts() && (
            <Form.Item
              noStyle
              shouldUpdate={shouldUpdate([[...fullNamePath, 'unitAssist']])}
            >
              {() =>
                hasAssist() && (
                  <StyledTariffUnitValueWrapperDiv>
                    <UnitAssistInput
                      form={form}
                      {...currencySelectProps}
                      debounce={true}
                      fullNamePath={[...fullNamePath, 'unitAssist']}
                      localNamePath={[...localNamePath, 'unitAssist']}
                      $itemSize={CssSize.TINY_SHORT}
                      precision={6}
                      required={
                        required ||
                        !!readWriteControlsConfig[
                          'invoices.products.lines.tariffs.unitassist'
                        ]?.required
                      }
                      max={100000000}
                      readOnly={
                        readOnly ||
                        !!readWriteControlsConfig[
                          'invoices.products.lines.tariffs.unitassist'
                        ]?.readOnly
                      }
                      min={0.0}
                      disabled={formDisabled}
                    />
                  </StyledTariffUnitValueWrapperDiv>
                )
              }
            </Form.Item>
          )}
          <Form.Item
            noStyle
            shouldUpdate={shouldUpdate([
              htsKey, // uoms are dependent on the hts code
              [...fullNamePath, 'unitReportingQuantities'], // if the value is cleared from elsewhere (e.g. clearFields in onHtsChange in LineItem), re-compute the uom values to re-set them.
            ])}
          >
            {() => {
              const uoms =
                htsObj?.uom?.length && htsObj.uom[0] !== 'X'
                  ? htsObj.uom
                  : null;
              if (!uoms) return null;
              return (
                <StyledTariffUnitValueWrapperDiv>
                  {uoms.map((uom: string, i: number) => {
                    const unitField = [
                      ...localNamePath,
                      'unitReportingQuantities',
                      i,
                      'unit',
                    ];

                    const unitTextValue = unitCodes[uom];
                    const displayUom =
                      unitTextValue === 'Kilograms'
                        ? 'Net Weight'
                        : unitTextValue;

                    const unitText = unitCodes[uom]
                      ? `Unit ${displayUom}`
                      : `Unit ${i + 1}`;
                    const defaultUOMValue = defaultUOM.includes(uom)
                      ? lockedUnitCodes[uom]
                      : undefined;
                    return (
                      <StyledUomQuantityDiv key={uom}>
                        <HiddenField
                          localNamePath={unitField}
                          value={uom}
                          form={form}
                          fullNamePath={[
                            ...fullNamePath,
                            'unitReportingQuantities',
                            i,
                            'unit',
                          ]}
                        />
                        <DecimalQuantity
                          debounce={true}
                          dataCySuffix={unitText}
                          field={[
                            ...localNamePath,
                            'unitReportingQuantities',
                            i,
                            'value',
                          ]}
                          label={unitText}
                          defaultValue={defaultUOMValue}
                          required={
                            required ||
                            !!readWriteControlsConfig[
                              'invoices.products.lines.tariffs.unitReportingQuantities.value'
                            ]?.required
                          }
                          $itemSize={CssSize.TINY_SHORT}
                          units={uom.toString().toLowerCase()}
                          readOnly={
                            Boolean(defaultUOMValue) &&
                            !isUOMEditAllowedForHts(htsNumber)
                          }
                          disabled={
                            Boolean(defaultUOMValue) &&
                            !isUOMEditAllowedForHts(htsNumber)
                          }
                          max={100000000}
                        />
                      </StyledUomQuantityDiv>
                    );
                  })}
                </StyledTariffUnitValueWrapperDiv>
              );
            }}
          </Form.Item>
          {isAssistEnabled && isOnlyOrRealHts() && (
            <StyledFormInlineDiv>
              <Form.Item
                shouldUpdate={shouldUpdate([[...fullNamePath, 'unitAssist']])}
              >
                {() =>
                  !hasAssist() && (
                    <AddUnitAssistButton
                      form={form}
                      fullNamePath={[...fullNamePath, 'unitAssist']}
                      disabled={formDisabled}
                      readOnly={readOnly}
                    />
                  )
                }
              </Form.Item>
            </StyledFormInlineDiv>
          )}
          {!isPsc && htsObj && pgaFlags.length > 0 && (
            <div>
              {!readOnly && (
                <StyledDiv>
                  PGA description same as CBP?{' '}
                  <Switch
                    field={[...localNamePath, 'pgaDescription', 'sameAsCbp']}
                    size="small"
                    disabled={formDisabled}
                    onWord=""
                    offWord=""
                    $inline
                    initialValue={sameAsCbpFieldValue}
                  />
                </StyledDiv>
              )}
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  get(prevValues, sameAsCbpField) !==
                  get(curValues, sameAsCbpField)
                }
                noStyle
              >
                {() =>
                  !form.getFieldValue(sameAsCbpField) ? (
                    <StyledFormItem
                      required={required}
                      label="PGA Description"
                      name={[...localNamePath, 'pgaDescription', 'text']}
                      rules={[{ required: required, message: ' ' }]}
                      $itemSize={CssSize.SHORT}
                      $readOnly={readOnly}
                    >
                      <Input.TextArea
                        disabled={formDisabled}
                        autoSize
                        maxLength={50}
                      />
                    </StyledFormItem>
                  ) : (
                    readOnly && <div>PGA description is same as CBP</div>
                  )
                }
              </Form.Item>
            </div>
          )}
        </StyledFormInlineDiv>
      </div>{' '}
      {isOnlyOrRealHts() && (
        <StyledExtraComplianceDiv>
          <SimpleSelect
            form={form}
            label="Additional Compliance Data"
            disabled={formDisabled}
            readOnly={
              readOnly ||
              !!readWriteControlsConfig['invoices.products.lines.tariffs.pga']
                ?.readOnly
            }
            dropdownMatchSelectWidth={false}
            multiple
            $itemSize={CssSize.SHORT}
            required={
              !!readWriteControlsConfig['invoices.products.lines.tariffs.pga']
                ?.required
            }
            fullNamePath={[...fullNamePath, 'extraPgaFlags']}
            localNamePath={[...localNamePath, 'extraPgaFlags']}
            map={extraPGAOptions}
            maxTagCount={2}
          />
          {!readWriteControlsConfig['invoices.products.lines.tariffs.pga']
            ?.readOnly &&
            htsObj &&
            !(adTabVisible && cvTabVisible) &&
            !formDisabled &&
            !readOnly &&
            form.getFieldValue(['entryType']) !== '86' && (
              <StyledDropdown overlay={AdCvdCaseNumberMenu}>
                <Button>Add Case Number</Button>
              </StyledDropdown>
            )}
        </StyledExtraComplianceDiv>
      )}
    </>
  );
};

export default Tariff;

import { Form } from 'antd';
import React, { memo } from 'react';
import ConstituentElements from 'components/ConstituentElements';
import AphCoreForm from 'components/AphCoreForm';
import { PgaFormBaseProps } from 'types';
import { subjectToAphCore, subjectToLaceyAct } from 'libs/entryValidation';

const AphForm = ({
  pgaFlag,
  readOnly,
  disabled,
  localNamePath,
  fullNamePath,
  required,
}: PgaFormBaseProps): React.ReactElement => {
  const form = Form.useFormInstance();

  return (
    <div>
      {subjectToLaceyAct(pgaFlag) && (
        <ConstituentElements
          form={form}
          disabled={disabled}
          readOnly={readOnly}
          fullNamePath={[...fullNamePath, 'constituentElements']}
          localNamePath={[...localNamePath, 'constituentElements']}
          required={required}
        />
      )}
      {/* TODO: Unknown harvest source or species of product */}
      {/* TODO: Multiple countries of harvest input */}
      {/* TODO PG14 LPCO (Licenses, Permits, Certificates, or Other)... CITES Permit in particular for Lacey Act */}
      {/* TODO PG29, PG50, PG51 Something about possible species for fuel wood and wood charcoal? TBH I'm not really sure, but we don't have any business with it atm. */}
      {subjectToAphCore(pgaFlag) && (
        <AphCoreForm
          disabled={disabled}
          readOnly={readOnly}
          fullNamePath={fullNamePath}
          localNamePath={localNamePath}
          required={required}
        />
      )}
    </div>
  );
};

export default memo(AphForm);

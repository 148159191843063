import { RecordType } from '@xbcb/shared-types';
import { deIorActivationFragments } from 'libs/sharedQueries';
import { EuIorActivation } from '../../loadableComponents';
import euIorActivationRoute from './euIorActivation';

const deIorActivationRoute = euIorActivationRoute({
  page: EuIorActivation,
  recordType: RecordType.DE_IOR_ACTIVATION,
  fields: `...deIorActivationFields`,
  fragments: deIorActivationFragments,
});

export default deIorActivationRoute;

import { CrudPermissionName } from '@xbcb/client-types';
import { UserPermissions, checkAccess } from '@xbcb/client-utils';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { message } from 'antd';
import { downloadURL } from 'libs/download';
import { transformBulkUploadRequest } from 'libs/formTransforms';
import {
  bulkChargesUploadRequestFragments,
  bulkMilestoneUploadRequestFragments,
  bulkComplianceUploadRequestFragments,
  bulkUploadFieldsFragmentName,
} from 'libs/sharedQueries';
import { AppRecordRoute } from 'routes/types';
import BulkChargesUploadRequest from 'components/BulkChargesUploadRequest';
import BulkComplianceUploadRequest from 'components/BulkComplianceUploadRequest';
import BulkMilestoneUploadRequest from 'components/BulkMilestoneUploadRequest';

const bulkUploadsFieldsInlineFragment = `...${bulkUploadFieldsFragmentName}`;

/**
 * Util function to return the AppRecordRoute fields common to Bulk Upload Requests
 * @param {RecordType} recordType the type of bulk upload request; to be passed down for permission-checking
 * @return {AnyObject} an object containing the common AppRecordRoute fields
 */
const commonFields = (recordType: RecordType) => {
  return {
    fields: bulkUploadsFieldsInlineFragment,
    defaultEditMode: false,
    hideDeleteKebabMenuItem: () => true,
    transformUpdateRecordInput: transformBulkUploadRequest.toSchema,
    kebabMenuItems: [
      {
        key: 'downloadBulkUploadRequestDocument',
        text: 'Download Request Document',
        action: async ({ record }: AnyObject): Promise<void> => {
          message.info('Downloading the document for this Request', 5.0);
          const { downloadLink } = record?.document?.content;
          await downloadURL(downloadLink);
          message.success('Download complete');
        },
        show: ({ currentUser, existingRecord }: AnyObject): boolean => {
          return (
            checkAccess(currentUser, recordType, UserPermissions.READ) &&
            existingRecord.document?.content?.downloadLink
          );
        },
      },
    ],
    status: {
      show: ({ currentUser }: AnyObject): boolean => {
        return checkAccess(currentUser, recordType, CrudPermissionName.ADMIN);
      },
    },
  };
};

const bulkUploadRoutes: AppRecordRoute[] = [
  {
    Page: BulkMilestoneUploadRequest,
    recordType: RecordType.BULK_MILESTONE_UPLOAD_REQUEST,
    fragments: bulkMilestoneUploadRequestFragments,
    ...commonFields(RecordType.BULK_MILESTONE_UPLOAD_REQUEST),
  },
  {
    Page: BulkChargesUploadRequest,
    recordType: RecordType.BULK_CHARGES_UPLOAD_REQUEST,
    fragments: bulkChargesUploadRequestFragments,
    ...commonFields(RecordType.BULK_CHARGES_UPLOAD_REQUEST),
  },
  {
    Page: BulkComplianceUploadRequest,
    recordType: RecordType.BULK_COMPLIANCE_UPLOAD_REQUEST,
    fragments: bulkComplianceUploadRequestFragments,
    ...commonFields(RecordType.BULK_COMPLIANCE_UPLOAD_REQUEST),
  },
];

export default bulkUploadRoutes;

import { SearchTableQuery } from '@xbcb/shared-queries';
import { PartyRecordType, RecordType } from '@xbcb/shared-types';
import titleFields from 'libs/titleFields';

export const getShouldFetchAlternateName = (recordType: RecordType) =>
  Object.values(PartyRecordType).includes(recordType as PartyRecordType);

interface FetchRecordSelectOptionsProps {
  recordType: RecordType;
  fields?: string;
}
export const fetchRecordSelectOptions = (
  props: FetchRecordSelectOptionsProps,
) => {
  const { fields = '', recordType } = props;
  const titleField = titleFields[recordType as keyof typeof titleFields];
  if (!titleField) {
    throw new Error(
      `${recordType} is not supported in libs/titleFields yet, please add an entry`,
    );
  }
  // There are sometimes when we need additional fields beyond just the
  // `titleField` to render the select options. For example, all party records
  // should also display the `alternateName` if they have one
  const additionalTitleFields = getShouldFetchAlternateName(recordType)
    ? 'alternateName'
    : '';
  return SearchTableQuery({
    recordName: recordType,
    fields: `
        id
        version
        ${titleField}
        ${fields}
        ${additionalTitleFields}
    `,
  });
};

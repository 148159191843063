import React, { memo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import ProductLevelErrorRow, {
  ProductSuggestion,
} from 'components/InquireWorkOrderProductLevelErrorRow';
import { StyledHeader } from 'components/InquireWorkOrderModal/styles';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

export interface InquireWorkOrderProductLevelErrorsProps {
  productIdentifierSuggestions?: ProductSuggestion[];
}

export const InquireWorkOrderProductLevelErrors: React.FC<
  InquireWorkOrderProductLevelErrorsProps
> = ({ productIdentifierSuggestions = [] }) => {
  const [documentsPageBundle] = useBundle('components.DocumentsPage');
  const [sharedBundle] = useBundle('shared');
  return (
    <>
      <StyledHeader>
        {safeGetMessage(documentsPageBundle, 'line_level_errors')}
      </StyledHeader>
      <Form.List name="productLevelErrors" minimum={1}>
        {(fields, { add, remove }) => (
          <>
            <Row>
              <Col>
                {fields.map((field, index) => (
                  <ProductLevelErrorRow
                    key={field.key}
                    index={index}
                    productIdentifierSuggestions={productIdentifierSuggestions}
                    showMinus={fields.length > 1}
                    firstRow={index === 0}
                    add={add}
                    remove={() => {
                      remove(field.name);
                    }}
                    fullNamePath={['productLevelErrors', field.name]}
                    localNamePath={[field.name]}
                  />
                ))}
                <Button type="dashed" onClick={add}>
                  <PlusOutlined />
                  {safeGetMessage(sharedBundle, 'add')}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </>
  );
};

export default memo(InquireWorkOrderProductLevelErrors);

import { FormInstance } from 'antd/lib/form';
import moment from 'moment';

export const arrivalNamePath = ['arrival', 'inBond', 'estimatedEntryDate'];
export const importDateNamePath = ['arrival', 'importDate'];

export const getArrivalAsMoment = (
  value: string | moment.Moment,
): string | undefined => {
  return moment.isMoment(value) ? value.format('YYYY-MM-DD') : value;
};
export const getArrivalDate = (form: FormInstance): string | undefined => {
  const value = getArrivalDateMoment(form);
  return getArrivalAsMoment(value);
};

export const getArrivalDateMoment = (form: FormInstance): moment.Moment => {
  return (
    form.getFieldValue(arrivalNamePath) ||
    form.getFieldValue(importDateNamePath)
  );
};

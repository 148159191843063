import { gql } from '@apollo/client';
import { capitalCase } from 'change-case';
import { createOneMutation, getOneQueryLite } from '@xbcb/shared-queries';
import { RecordType } from '@xbcb/shared-types';
import { client as apolloClient } from '@xbcb/apollo-client';
import { EuCountryCodes } from '@xbcb/work-order-types';
import { get } from 'lodash';
import { uiStageToBackendStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import {
  PATHFINDER_FEATURE_FLAG_SERVICE,
  PathfinderFeatures,
} from '@xbcb/feature-flags';

const { stage } = getEnv();

const isPathfinderPhase1Enabled = PATHFINDER_FEATURE_FLAG_SERVICE.isEnabled(
  PathfinderFeatures.PHASE_1,
  {
    stage: uiStageToBackendStage[getEnv().stage],
  },
);

export const updateWorkOrderStatus = gql`
  mutation UpdateWorkOrder(
    $id: ID!
    $version: Int!
    $input: UpdateWorkOrderInput!
  ) {
    updateWorkOrder(id: $id, version: $version, input: $input) {
      workOrder {
        id
        version
        status
      }
    }
  }
`;

export const updateWorkOrderTaskStatus = gql`
  mutation UpdateWorkOrderTask(
    $id: ID!
    $version: Int!
    $input: UpdateWorkOrderTaskInput!
  ) {
    updateWorkOrderTask(id: $id, version: $version, input: $input) {
      record {
        id
        version
        status
        subStatus
      }
    }
  }
`;

export const createWorkOrderMilestoneMutation = createOneMutation({
  recordName: RecordType.WORK_ORDER_MILESTONE,
  fields: 'id',
});

export const updateUsConsumptionEntry = gql`
  mutation updateUsConsumptionEntry(
    $id: ID!
    $version: Int!
    $input: UpdateUsConsumptionEntryInput!
  ) {
    updateUsConsumptionEntry(id: $id, version: $version, input: $input) {
      record {
        id
        version
        badges
        status
      }
    }
  }
`;

export const updateEuCustomsEntry = (euCountryCode: EuCountryCodes) => {
  const formattedCountryCode = capitalCase(euCountryCode);
  return gql`
  mutation update${formattedCountryCode}CustomsEntry(
    $id: ID!
    $version: Int!
    $input: Update${formattedCountryCode}CustomsEntryInput!
  ) {
    update${formattedCountryCode}CustomsEntry(
      id: $id
      version: $version
      input: $input
    ) {
      record {
        id
        version
        badges
        status
      }
    }
  }
`;
};

export const getIorVersion = async (
  iorId: string,
  iorRecordType: RecordType,
  euCountryCode: EuCountryCodes,
) => {
  const country = capitalCase(euCountryCode);
  const euIorVersionQuery = getOneQueryLite({
    recordName: iorRecordType,
    fields: 'version',
    queryName: `get${country}IorVersion`,
  });

  const { data } = await apolloClient.query({
    query: euIorVersionQuery,
    variables: { id: iorId },
    errorPolicy: 'all',
  });
  const localIorPathName = [`${country.toLowerCase()}Ior`, 'version'];
  const version = get(data, localIorPathName);
  return version;
};

export const updateEuIorActivationStatus = (euCountryCode: EuCountryCodes) => {
  const formattedCountryCode = capitalCase(euCountryCode);
  return gql`
  mutation update${formattedCountryCode}IorActivationStatus(
    $id: ID!
    $version: Int!
    $activationInput: Update${formattedCountryCode}IorActivationStatusInput!
  ) {
    update${formattedCountryCode}IorActivationStatus(
      id: $id
      version: $version
      input: $activationInput
    ) {
      ${formattedCountryCode.toLowerCase()}Ior {
        id
        version
      }
    }
  }
`;
};

export const flipWorkOrder = isPathfinderPhase1Enabled
  ? gql`
      mutation FlipUsType86Entry(
        $id: ID!
        $idempotencyKey: String!
        $outputType: WorkOrderType!
      ) {
        flipWorkOrder(
          id: $id
          idempotencyKey: $idempotencyKey
          outputType: $outputType
        ) {
          record {
            id
            version
          }
        }
      }
    `
  : gql`
      mutation FlipUsType86Entry(
        $id: ID!
        $version: Int!
        $input: UpdateShipmentInput!
      ) {
        updateShipment(id: $id, version: $version, input: $input) {
          record {
            id
            version
          }
        }
      }
    `;

export const cloneWorkOrderMutation = gql`
  mutation CloneWorkOrder(
    $input: CloneWorkOrderInput!
    $cloneWorkOrderId: ID!
  ) {
    cloneWorkOrder(input: $input, id: $cloneWorkOrderId) {
      clonedWorkOrder {
        id
      }
    }
  }
`;

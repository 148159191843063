import { executeMutation } from '../../../libs/executeMutation';
import getQueryParameters from '../../../libs/getQueryParameters';
import {
  WorkOrderTaskStatus,
  WorkOrderTaskStatusReason,
} from '@xbcb/work-order-types';
import type { WorkOrderTask } from '@xbcb/api-gateway-client';
import { RecordType } from '@xbcb/shared-types';
import { message } from 'antd';
import { updateOneMutation } from '@xbcb/shared-queries';
import { Location } from 'history';

export const blockUnblockTaskActionFunctionHelper = async (
  block: boolean,
  // record has any type in AppRecordKebabSubMenuItemActionProps
  record: any,
  location: Location,
): Promise<void> => {
  const queryParameters = getQueryParameters(location);
  const workOrderTaskType = queryParameters['task'];
  const { tasks } = record;
  const task = tasks.find(
    (task: WorkOrderTask) =>
      task.definition?.workOrderTaskType === workOrderTaskType,
  );

  const { id: taskId, version } = task ?? {};

  if (!task) {
    message.error('Unexpected error as we cannot find the task information.');
    return;
  }

  const fields = 'status statusReason';
  const recordType = RecordType.WORK_ORDER_TASK;

  const updateTaskStatus = updateOneMutation({
    recordName: recordType,
    fields: fields,
  });

  await executeMutation({
    mutation: updateTaskStatus,
    variables: {
      version: version,
      id: taskId,
      input: {
        status: block
          ? WorkOrderTaskStatus.BLOCKED
          : WorkOrderTaskStatus.IN_PROGRESS,
        statusReason: block
          ? WorkOrderTaskStatusReason.PENDING_CONFIRMATION
          : null,
      },
    },
    successMessage: block
      ? `Task has been successfully blocked`
      : `Task has been successfully unblocked`,
  });

  return;
};

import openTab from 'libs/openTab';

export default ({ event, history, path, targetBlank = false }) => {
  if (event.ctrlKey || targetBlank) {
    openTab(`${window.location.origin}${path}`);
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  } else {
    history.push(path);
  }
};

import React from 'react';
import { SelectValue } from 'antd/lib/select';
import { Select, Option, FormItem } from '@xbcb/form-item-components';
import { NamePath, CssSize } from '@xbcb/ui-types';
import { invoiceSourceMap } from 'libs/display';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

// CC removed 10/16/19
const options = Object.keys(invoiceSourceMap)
  .filter((x) => !['creditCard', 'paypal'].includes(x))
  .map((key) => (
    <Option key={key} value={key}>
      {invoiceSourceMap[key as keyof typeof invoiceSourceMap]}
    </Option>
  ));

export interface SourceSelectProps {
  localNamePath: NamePath;
  required?: boolean;
  onChange?: (value: SelectValue) => void;
  $inline?: boolean;
  $itemSize?: CssSize;
  readOnly?: boolean;
  disabled?: boolean;
}
const SourceSelect: React.FC<SourceSelectProps> = ({
  localNamePath,
  required,
  onChange,
  $inline,
  $itemSize,
  readOnly,
  disabled,
}) => {
  const [partyBundle] = useBundle('components.Party');
  return (
    <FormItem
      label={safeGetMessage(partyBundle, 'payment_method')}
      name={localNamePath}
      required={required}
      $inline={$inline}
      $itemSize={$itemSize}
      $readOnly={readOnly}
    >
      <Select
        disabled={disabled}
        optionLabelProp="children"
        dropdownMatchSelectWidth={false}
        onChange={onChange}
      >
        {options}
      </Select>
    </FormItem>
  );
};
export default SourceSelect;

import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { documentFragments } from '@xbcb/document-queries';
import { client as apolloClient } from '@xbcb/apollo-client';
import { SearchQuery } from '@xbcb/shared-queries';
import {
  DateSearchOperator,
  KeywordSearchOperator,
  RecordType,
  SortOrder,
} from '@xbcb/shared-types';
import { Tooltip, Button, message } from 'antd';
import { downloadURL } from 'libs/download';

const searchQuery = SearchQuery({
  recordName: RecordType.DOCUMENT,
  fields: '...documentFields',
  fragments: documentFragments,
});

export type BulkUploadTemplateDownloadButtonProps = {
  templateTag: { key: string; value: string };
};

const BulkUploadTemplateDownloadButton: React.FC<
  BulkUploadTemplateDownloadButtonProps
> = ({ templateTag }: BulkUploadTemplateDownloadButtonProps) => {
  const [isDownLoadButtonDisabled, setIsDownloadButtonDisabled] =
    useState(false);
  const searchQueryVariables = {
    input: {
      searchCriteria: {
        deletedTime: { operator: DateSearchOperator.DOES_NOT_EXIST },
        tags: {
          values: [templateTag],
          operator: KeywordSearchOperator.EQUALS,
        },
      },
      sortOptions: [{ field: 'createdTime', order: SortOrder.DESC }],
    },
  };
  const handleDownload = async () => {
    setIsDownloadButtonDisabled(true);
    message.info('Downloading Bulk Upload Request Template', 5.0);
    const { data } = await apolloClient.query({
      query: searchQuery,
      variables: searchQueryVariables,
    });
    // this currently takes just the newest document with the correct tag.  This logic will need to
    // change should we introduce multiple templates (i.e. add a template per broker / region).
    const { downloadLink } = data?.searchDocuments?.results[0]?.content || {};

    if (downloadLink) {
      await downloadURL(downloadLink);
      message.success('Download complete');
    } else {
      message.error('Sorry, we were unable to retrieve the template.', 5.0);
    }
    setIsDownloadButtonDisabled(false);
  };
  return (
    <Tooltip placement="top" title="Template for a new Request">
      <Button
        key="export"
        onClick={handleDownload}
        shape="round"
        disabled={isDownLoadButtonDisabled}
      >
        <DownloadOutlined />
        <span>Template</span>
      </Button>
    </Tooltip>
  );
};

export default BulkUploadTemplateDownloadButton;

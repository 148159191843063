import { createAction } from '@reduxjs/toolkit';
import { PUSH_RECENTLY_VIEWED, CLEAR_RECENTLY_VIEWED } from '../types';

export const pushRecentlyViewed = createAction<
  recentObject,
  typeof PUSH_RECENTLY_VIEWED
>(PUSH_RECENTLY_VIEWED);

export interface recentObject {
  id: string;
  recordType: string;
  label: string;
}

export const clearRecentlyViewed = createAction(CLEAR_RECENTLY_VIEWED);

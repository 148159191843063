import React, { memo } from 'react';
import { StyledButton, StyledNotFoundContainer } from './styles';

export interface NotFoundProps {
  notFoundText: string;
  buttonText: string;
  onClick: () => void;
}

const NotFound: React.FC<NotFoundProps> = memo(
  ({ buttonText, notFoundText, onClick }) => {
    return (
      <StyledNotFoundContainer>
        <p>{notFoundText}</p>
        <StyledButton type="default" onClick={onClick}>
          {buttonText}
        </StyledButton>
      </StyledNotFoundContainer>
    );
  },
);

export default NotFound;

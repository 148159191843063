import styled from 'styled-components';
import { MinusButton } from '@xbcb/button-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CssSpace } from '@xbcb/ui-types';
import { spaceBottom, spaceTop } from '@xbcb/ui-styles';

export const StyledHeader = styled.h4`
  margin-bottom: 0;
`;

export const StyledLevelDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledMinusButton = styled(MinusButton)`
  margin-top: 0;
  display: inline-block;
  ${spaceBottom}
`;

export const StyledLevelSpan = styled.span<{ $firstLevel: boolean }>`
  ${({ $firstLevel }) => $firstLevel && spaceTop}
  ${spaceBottom}
  margin-right:  ${CssSpace.SPACE_3};
  font-weight: bold;
  display: inline-block;
`;

export const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  margin-left: ${CssSpace.SPACE_3};
`;

export const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${CssSpace.SPACE_3};
`;

import React from 'react';
import './MOTIcon.less';
import { ModeOfTransport } from '@xbcb/shipment-types';
import ocean from './icons/ocean';
import air from './icons/air';
import truck from './icons/truck';
import rail from './icons/rail';

const iconMap = {
  [ModeOfTransport.OCEAN]: ocean,
  [ModeOfTransport.AIR]: air,
  [ModeOfTransport.TRUCK]: truck,
  [ModeOfTransport.RAIL]: rail,
};

interface MotIconProps {
  mot: ModeOfTransport;
  size?: string;
}

const MOTIcon = ({ mot, size }: MotIconProps) => {
  if (!mot) return null;
  const C = iconMap[mot];
  return <C size={size} />;
};

MOTIcon.defaultProps = {
  size: '24',
};

export default MOTIcon;

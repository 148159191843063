import { isEmpty } from 'lodash';
import { AnyObject, ModeOfTransport } from '@xbcb/shared-types';
import type {
  CreateShipmentInput,
  IdInput,
  ShipmentLegIorInput,
} from '@xbcb/api-gateway-client';
import { getEnv } from '@xbcb/ui-utils';

const { stage } = getEnv();

type toCreateSchemaProps = CreateShipmentInput & {
  modeOfTransport?: ModeOfTransport; // has to be optional as we are deleting this
  masterBill?: string;
  houseBills?: string[];
  ior?: ShipmentLegIorInput;
  destinationCountry?: string; // has to be optional as we are deleting this
  sourceCountry?: string; // has to be optional as we are deleting this
  usCustomsBroker?: IdInput;
  forwarder?: IdInput; // has to be optional as we are deleting this
  arrival?: AnyObject;
  workOrderGroup?: AnyObject; // has to be optional as we are deleting this
  friendlyId?: string; // has to be optional as we are deleting this
};
export const transformShipment = {
  toForm: ({ existingRecord }: { existingRecord: any }) => {
    return existingRecord;
  },
  toCreateSchema: ({
    input,
  }: {
    input: toCreateSchemaProps;
  }): CreateShipmentInput => {
    const {
      modeOfTransport,
      masterBill,
      houseBills,
      ior,
      destinationCountry,
      sourceCountry,
      usCustomsBroker,
      forwarder,
      legs,
      arrival,
    } = input;
    if (!legs && modeOfTransport) {
      input.legs = [
        // TODO temporary until we develop a UI for shipment legs
        {
          modeOfTransport,
          departure: { country: sourceCountry as string },
          arrival: {
            country: destinationCountry as string,
            customsBroker: {
              customsBroker: usCustomsBroker,
            },
            time: arrival?.time,
          },
          ...((masterBill || houseBills) && {
            masterBills: {
              number: masterBill as string,
              houseBills: houseBills?.map((billNumber) => {
                return { number: billNumber };
              }),
            },
          }),
          ior,
        },
      ];
      delete input.modeOfTransport;
      delete input.arrival;
      delete input.sourceCountry;
      delete input.destinationCountry;
      delete input.masterBill;
      delete input.houseBills;
      delete input.ior;
      delete input.usCustomsBroker;
      delete input.workOrderGroup;
      delete input.friendlyId;
      delete input.operator;
    }

    if (!isEmpty(forwarder)) {
      input.forwarders = [
        {
          forwarder,
        },
      ];
    }
    delete input.forwarder;
    return input;
  },
  toUpdateSchema: ({ input }: { input: any }) => {
    const { legs } = input;
    legs?.forEach((leg: AnyObject) => {
      const shipmentLegIor = leg.ior?.ior;
      if (shipmentLegIor) {
        // leg.ior.ior only takes IdInput, don't include the other values like
        // name and version
        leg.ior.ior = { id: shipmentLegIor.id };
      }
    });
    delete input.requestedWorkOrders;
    delete input.friendlyId;
    delete input.operator;
    delete input.workOrderGroup;
    delete input.status;
    return input;
  },
};

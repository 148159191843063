import styled from 'styled-components';
import { Table, Input, Button } from 'antd';
import { CssSpace } from '@xbcb/ui-types';

export const StyledPopOverTable = styled(Table)`
  width: 490px;
  padding: 0;
  .text-match {
    em {
      font-style: normal;
      background: none;
      font-weight: bold;
      padding: 0;
    }
  }

  .ant-popover-inner-content,
  .ant-table-title,
  .ant-table-footer {
    padding: 0;
  }
  .ant-table-cell {
    padding: 4px 8px;
  }
  .ant-spin-dot {
    left: 256px;
  }
  td {
    cursor: pointer;
    .text-match {
      max-width: ${CssSpace.SPACE_8};
      text-overflow: ellipsis;
      overflow: hidden;
    }
    p {
      max-width: 224px;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
      img {
        margin-right: ${CssSpace.SPACE_3};
        height: 20px;
      }
    }
  }
  .ant-table-title {
    top: 0;
  }
  .ant-table-footer {
    .ant-btn {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border: none;
    }
  }
`;

export const StyledSearchInput = styled(Input.Search)`
  position: relative;
  z-index: 999;
  width: ${CssSpace.SPACE_9};
  height: var(--header-height);
  line-height: var(--header-height);
  margin-top: ${CssSpace.SPACE_4};
  input {
    width: ${CssSpace.SPACE_9};
    min-height: ${CssSpace.SPACE_5};
  }

  .ant-input-suffix {
    font-size: 16px;
  }

  input,
  .ant-input-search-button {
    border-radius: 100px;
    height: ${CssSpace.SPACE_5};
    background-color: ${({ theme }) => theme.palette.neutrals.snow};
    box-shadow: none;
  }
  button.ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
    color: black;
    border: none;
    border-radius: 0 100px 100px 0;
  }
  .ant-input-group-addon {
    border-radius: 100px;
  }
`;

export const StyledMoreButton = styled(Button)`
  width: 100%;
  border-radius: 0;
  padding: 0;
`;

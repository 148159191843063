import {
  UsType86EntryLine,
  UsConsumptionEntryLine,
} from '@xbcb/api-gateway-client';

export const getHtsNumbersFromProductLine = (
  line: UsType86EntryLine | UsConsumptionEntryLine,
): string[] => {
  const result: string[] = [];
  line.tariffs?.forEach(({ htsNumber }) => {
    if (htsNumber) result.push(htsNumber);
  });
  return result;
};

import styled from 'styled-components';
import { Button } from 'antd';
import { CssSpace } from '@xbcb/ui-types';

export const StyledDiv = styled.div`
  margin-bottom: ${CssSpace.SPACE_4};
  .info-tooltip,
  h4 {
    margin-left: ${CssSpace.SPACE_3};
    display: inline-block;
  }
`;

export const StyledAddButton = styled(Button)`
  margin-left: ${CssSpace.SPACE_3};
`;

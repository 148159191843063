import { RecordType } from '@xbcb/shared-types';

import { ModalKey } from 'types';
import { transformBulkUploadRequest } from 'libs/formTransforms/bulkUploadRequest';
import CreateBulkUploadRequestModal, {
  CreateBulkUploadRequestModalProps,
} from 'components/CreateBulkUploadRequestModal';

const createBulkChargesUploadRequestModalProps: CreateBulkUploadRequestModalProps =
  {
    modalKey: ModalKey.CREATE_BULK_CHARGES_UPLOAD_REQUEST,
    recordName: RecordType.BULK_CHARGES_UPLOAD_REQUEST,
    tagKey: `bulkChargesUploadRequestId`,
    title: 'Bulk Charges Upload Request',
    transformCreateRecordInput: transformBulkUploadRequest.toSchema,
  };

const CreateBulkChargesUploadRequestModal = (): JSX.Element =>
  CreateBulkUploadRequestModal(createBulkChargesUploadRequestModalProps);

export default CreateBulkChargesUploadRequestModal;

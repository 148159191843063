import { formatRecordName } from '@xbcb/js-utils';
import { AccountType, RecordType } from '@xbcb/shared-types';
import { getRecordType } from '@xbcb/core';

export const formatDisplayText = (recordId: string) => {
  const formattedRecordName = formatRecordName({
    recordType: getRecordType(recordId) as RecordType,
    accountType: AccountType.OPERATOR,
  });
  return `${formattedRecordName} has errors`;
};

import { WorkOrderType } from '@xbcb/work-order-types';
import { uiStageToBackendStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import {
  PATHFINDER_FEATURE_FLAG_SERVICE,
  PathfinderFeatures,
} from '@xbcb/feature-flags';
import { AnyObject } from '@xbcb/shared-types';

const { stage } = getEnv();

const isPathfinderPhase1Enabled = PATHFINDER_FEATURE_FLAG_SERVICE.isEnabled(
  PathfinderFeatures.PHASE_1,
  {
    stage: uiStageToBackendStage[stage],
  },
);

const stageToAglOperatorId = {
  ALPHA: 'operator_5c8bbe43-f02c-4198-b9cf-7ac2040c39a6',
  BETA: 'operator_a8d08734-014c-406a-bcee-9471bd3c37d8',
  LOCAL: 'operator_38895a71-6c5e-4dc1-b25a-50a582e8399c', // same as gamma
  GAMMA: 'operator_38895a71-6c5e-4dc1-b25a-50a582e8399c',
  PROD: 'operator_49b4c4ec-cb26-4c2a-b136-496673166519',
};

const isAglOperator = (user: AnyObject): boolean =>
  user?.operator?.id === stageToAglOperatorId[stage];

export const checkUserAccessToRequestWorkOrder = (
  user: AnyObject,
  wo: WorkOrderType,
) => {
  switch (wo) {
    case WorkOrderType.UsType86Entry: {
      return isPathfinderPhase1Enabled && isAglOperator(user);
    }
    default: {
      return true;
    }
  }
};

import styled from 'styled-components';

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const StyledRecordSelectDiv = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const StyledCustomsAgentDiv = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.space_4};
`;

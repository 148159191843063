import { NamePath } from '@xbcb/ui-types';
import React from 'react';
import {
  StyledFlexColumnDiv,
  StyledFlexRowDiv,
  StyledInfoTooltip,
} from './styles';
import { FormItem, INLTSwitch as Switch } from '@xbcb/form-item-components';
import { Input } from 'antd';
import Lpco from '../Lpco';
import { UsPgaFlag } from '__generated__/graphql';
import ProductPackaging from 'components/ProductPackaging';
import { getCodes } from '@xbcb/ui-utils';

export type PesticidesFormProps = {
  fullNamePath: NamePath;
  localNamePath: NamePath;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
};

const BRAND_NAME_MAX_LENGTH = 35;

export const PesticidesForm: React.FC<PesticidesFormProps> = ({
  fullNamePath,
  localNamePath,
  disabled,
  readOnly,
  required,
}) => {
  const packagingCodesConfig = getCodes().CBP.CATAIR.PGA.packaging;

  return (
    <>
      <StyledFlexColumnDiv>
        <StyledFlexRowDiv>
          <FormItem
            label={'Brand Name'}
            name={[...localNamePath, 'tradeName']}
            $readOnly={readOnly}
            rules={[{ required }]}
          >
            <Input.TextArea
              aria-label={'Brand Name Textbox'}
              disabled={disabled}
              autoSize={true}
              maxLength={BRAND_NAME_MAX_LENGTH}
            />
          </FormItem>
          <Lpco
            required
            fullNamePath={fullNamePath}
            localNamePath={[...localNamePath, 'epaRegistrationNumber']}
            type={UsPgaFlag.Ep8}
            readOnly={readOnly}
            disabled={disabled}
          />
        </StyledFlexRowDiv>
        <StyledFlexRowDiv>
          <Switch
            required
            disabled={disabled}
            readOnly={readOnly}
            field={[...localNamePath, 'isImageSubmitted']}
            onWord="Yes"
            offWord="No"
            label="Pesticide Label Submitted"
          />
          <StyledInfoTooltip title="At least one image of an on-product pesticides label must be submitted via DIS." />
        </StyledFlexRowDiv>
        <StyledFlexRowDiv>
          <ProductPackaging
            required
            agencyCode={'EPA'}
            fullNamePath={[...fullNamePath, 'packaging']}
            localNamePath={[...localNamePath, 'packaging']}
            uomConfig={packagingCodesConfig}
            readOnly={readOnly}
            disabled={disabled}
          />
        </StyledFlexRowDiv>
      </StyledFlexColumnDiv>
    </>
  );
};

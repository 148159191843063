import React from 'react';
import dutyComputationRaw from 'libs/dutyComputation';

export default (input) => {
  const raw = dutyComputationRaw(input);
  if (raw === 'Calculated')
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://hts.usitc.gov/?query=${input.htsNumber}`}
      >
        See HTS
      </a>
    );
  else return raw;
};

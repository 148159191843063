export const titleCase = (string) => {
  if (typeof string === 'undefined') return null;
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const formatCaseRate = (rate) => {
  if (!rate) return rate;
  const { adValorem, specific, uom, uomDescription } = rate;
  const strings = [];
  if (adValorem) strings.push(`${adValorem}%`);
  if (specific)
    strings.push(
      `${specific}/${uom}${uomDescription ? ` ${uomDescription}` : ''}`,
    );
  return strings.join(' ');
};
export const roundDate = (iso) =>
  iso && typeof iso === 'string' ? iso.substr(0, 10) : iso;
export const roundNumber = (value, decimals) =>
  typeof value !== 'undefined'
    ? Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    : undefined;
export const formatHTSNumber = (v) => {
  if (!v) return v;
  const periodsRemoved = v.replace(/\./g, '');
  return (
    periodsRemoved.substr(0, 4) +
    '.' +
    periodsRemoved.substr(4, 2) +
    '.' +
    periodsRemoved.substr(6)
  );
};

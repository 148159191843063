import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { capitalCase } from 'capital-case';
import { Location } from 'history';

type GetBreadcrumbRoutesArgs = {
  location: Location;
  constructBreadcrumbName?: (segment: string) => string;
};

export const getBreadcrumbRoutes = ({
  location,
  constructBreadcrumbName,
}: GetBreadcrumbRoutesArgs): Route[] => {
  let routes: Route[] = [];
  const pathSegments = location.pathname.split('/');
  routes = pathSegments.map((segment) => ({
    path: segment,
    breadcrumbName: constructBreadcrumbName
      ? constructBreadcrumbName(segment)
      : capitalCase(segment),
  }));

  return routes;
};

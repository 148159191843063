import React from 'react';
import { Form, Modal, Button } from 'antd';
import {
  useCurrentUser,
  useCustomsBrokerId,
  useGlobalMileForwarder,
} from 'libs/hooks';
import { useModal } from '@xbcb/ui-utils';
import { useMutation } from '@apollo/client';
import { RecordType } from '@xbcb/shared-types';
import { StyledActionsDiv } from './styles';
import { ModalKey } from 'types';
import { createOneMutation } from '@xbcb/shared-queries';
import handleRecordCreate from 'libs/handleRecordCreate';
import { useHistory } from 'react-router-dom';
import { HiddenField } from '@xbcb/form-item-components';
import MotSelect from 'components/MotSelect';
import ShipperSelect from 'components/ShipperSelect';
import { formatRecordName } from '@xbcb/js-utils';

/* type CreateUsPostSummaryCorrectionModalProps = {}; */

/*
TODO the properties are optional because createButtonModal does not pass properties. 
Modify either this modal or the createButtonModal to pass in ModalProps
*/
const CreateUsPostSummaryCorrectionModal = () => {
  const [form] = Form.useForm();
  const { visible, closeModal } = useModal(
    ModalKey.CREATE_US_POST_SUMMARY_CORRECTION,
  );
  const createMutation = createOneMutation({
    recordName: RecordType.US_POST_SUMMARY_CORRECTION,
    fields: `
      id
    `,
  });
  const [createRecord, { loading }] = useMutation(createMutation);
  const history = useHistory();
  const user = useCurrentUser();
  const customsBrokerId = useCustomsBrokerId();
  const { id: forwarderId } = useGlobalMileForwarder();
  return (
    <Modal
      open={visible}
      width={600}
      footer={null}
      closable={false}
      onCancel={closeModal}
    >
      <Form form={form}>
        <h3>Create Post Summary Correction</h3>
        <h4>
          Select the{' '}
          {formatRecordName({
            recordType: RecordType.SHIPPER,
            accountType: user.accountType,
          })}
        </h4>
        <ShipperSelect
          form={form}
          noCardWrap
          multiple={false}
          disabled={loading}
          namePath={['shipper']}
        />
        <h4>Select the mode of transport</h4>
        <MotSelect
          fullNamePath={['conveyance', 'modeOfTransport']}
          form={form}
          required={true}
          disabled={loading}
        />
        <StyledActionsDiv>
          <Button loading={loading} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={() =>
              handleRecordCreate({
                form,
                history,
                recordType: RecordType.US_POST_SUMMARY_CORRECTION,
                /* transformCreateRecordInput: transformShipment.toSchema, */
                /* validateCreate: validateCreateShipment, */
                createRecord,
                /* clean: false, */
                closeModal,
                user,
              })
            }
            type="primary"
          >
            Submit
          </Button>
        </StyledActionsDiv>
        <HiddenField
          localNamePath={['broker', 'usCustomsBroker', 'id']}
          fullNamePath={['broker', 'usCustomsBroker', 'id']}
          value={customsBrokerId}
          form={form}
        />
        <HiddenField
          localNamePath={['forwarders', 0, 'id']}
          fullNamePath={['forwarders', 0, 'id']}
          value={forwarderId}
          form={form}
        />
      </Form>
    </Modal>
  );
};

export default CreateUsPostSummaryCorrectionModal;

export interface LanguageEntry {
  name: string;
  code: string;
  region: string;
}

export const languages: Record<string, LanguageEntry> = {
  de: {
    name: 'Deutsch',
    code: 'de-DE',
    region: 'Deutschland',
  },
  us: {
    name: 'English',
    code: 'en-US',
    region: 'United States',
  },
  gb: {
    name: 'English',
    code: 'en-GB',
    region: 'Great Britain',
  },
  es: {
    name: 'Español',
    code: 'es-ES',
    region: 'España',
  },
  fr: {
    name: 'Français',
    code: 'fr-FR',
    region: 'France',
  },
  in: {
    name: 'हिन्दी',
    code: 'hi-IN',
    region: 'India',
  },
  jp: {
    name: '日本語',
    code: 'ja-JP',
    region: '日本',
  },
  nl: {
    name: 'Dutch',
    code: 'nl-NL',
    region: 'Nederland',
  },
  br: {
    name: 'Português',
    code: 'pt-BR',
    region: 'Brasil',
  },
  cn: {
    name: '中文 (简体)',
    code: 'zh-CN',
    region: '中国大陆',
  },
};

export type LanguageCode = keyof typeof languages;

import React from 'react';
import { Divider, Form } from 'antd';
import { PassportType } from '@xbcb/shared-types';
import { capitalCase } from 'change-case';
import { shouldUpdate, getAN, safeGetMessage } from '@xbcb/ui-utils';
import {
  ANNumber,
  PickDate,
  CountrySelect,
  SimpleSelect,
} from '@xbcb/form-item-components';
import { useBundle } from '@amzn/react-arb-tools';

import { StyledDiv } from './styles';
import moment from 'moment';
import { FormInstance } from 'antd/lib/form';
import { CssSize, NamePath } from '@xbcb/ui-types';

type PassportProps = {
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  allRequired?: boolean;
  fullNamePath?: NamePath;
  localNamePath?: NamePath;
};

// TODO add ShipperSelect?
const Passport: React.FC<PassportProps> = ({
  form,
  readOnly,
  disabled,
  allRequired,
  fullNamePath = [],
  localNamePath = [],
}) => {
  const [partyBundle] = useBundle('component.Party');
  const { getFieldValue } = form;
  const passportNamePath = [...localNamePath, 'passport'];
  const passportNumberNamePath = [...passportNamePath, 'number'];
  const passportCountryCodeNamePath = [...passportNamePath, 'countryCode'];
  const passportExpirationNamePath = [...passportNamePath, 'expiration'];
  const passportTypeNamePath = [...passportNamePath, 'type'];
  return (
    <Form.Item shouldUpdate={shouldUpdate([passportNamePath])}>
      {() => {
        const passportNumber = getFieldValue(passportNumberNamePath);
        const passportCountryCode = getFieldValue(passportCountryCodeNamePath);
        const passportType = getFieldValue(passportTypeNamePath);
        const passportExpiration = getFieldValue(passportExpirationNamePath);
        return (
          <div>
            <Divider />
            <h3>{safeGetMessage(partyBundle, 'passport')}</h3>
            <StyledDiv>
              <ANNumber
                form={form}
                debounce={false}
                readOnly={readOnly}
                disabled={disabled}
                localNamePath={[...localNamePath, 'passport', 'number']}
                fullNamePath={[...fullNamePath, 'passport', 'number']}
                mask={getAN}
                $itemSize={CssSize.TINY_SHORT}
                max={13}
                label={safeGetMessage(partyBundle, 'number')}
                required={
                  allRequired ||
                  Boolean(
                    passportCountryCode || passportType || passportExpiration,
                  )
                }
              />
              <CountrySelect
                readOnly={readOnly}
                disabled={disabled}
                localNamePath={[...localNamePath, 'passport', 'countryCode']}
                $itemSize={CssSize.TINY_SHORT}
                label={safeGetMessage(partyBundle, 'country_of_issuance')}
                required={
                  allRequired ||
                  Boolean(passportNumber || passportType || passportExpiration)
                }
                foreignOnly
              />
            </StyledDiv>
            <StyledDiv>
              <PickDate
                form={form}
                localNamePath={[...localNamePath, 'passport', 'expiration']}
                fullNamePath={[...fullNamePath, 'passport', 'expiration']}
                label={safeGetMessage(partyBundle, 'expiration')}
                disabledDate={(current) =>
                  current && current < moment().subtract(1, 'day')
                }
                readOnly={readOnly}
                disabled={disabled}
                required={
                  allRequired ||
                  Boolean(passportCountryCode || passportType || passportNumber)
                }
              />
              <SimpleSelect
                form={form}
                $itemSize={CssSize.TINY_SHORT}
                label={safeGetMessage(partyBundle, 'type')}
                readOnly={readOnly}
                required={
                  allRequired ||
                  Boolean(
                    passportCountryCode || passportNumber || passportExpiration,
                  )
                }
                localNamePath={[...localNamePath, 'passport', 'type']}
                fullNamePath={[...fullNamePath, 'passport', 'type']}
                selectOptions={Object.values(PassportType)}
                map={(typeCode: PassportType) => capitalCase(typeCode)}
                inline
                allowClear
              />
            </StyledDiv>
          </div>
        );
      }}
    </Form.Item>
  );
};

export default Passport;

import { AnyObject } from '@xbcb/shared-types';

export const transformExchangeMessage = {
  toForm: ({
    existingRecord: newRecord,
  }: {
    existingRecord: AnyObject;
  }): AnyObject => {
    const { inboxMetadata, created } = newRecord;
    const { from, to, subject, body, workOrderId } = inboxMetadata;
    const createdTime = created.time;
    return {
      ...newRecord,
      from,
      to,
      subject,
      body,
      workOrderId,
      createdTime,
    };
  },
};

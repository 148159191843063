import styled from 'styled-components';
import { FormItem, InfoTooltip } from '@xbcb/form-item-components';

export const StyledEpaFormDiv = styled.div`
  display: flex;
  .ant-switch {
    width: 115px;
  }
  .ant-switch-checked::after {
    left: 95px;
  }
`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  margin-top: ${({ theme }) => theme.spacing.space_1};
`;

export const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0;
`;

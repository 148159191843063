import { AdditionalFormError } from '@xbcb/ui-types';

const validateOperatorUser = ({ input }: { input: any }) => {
  const { assignmentConfig, activeTaskDefinitions } = input;
  const additionalErrors: AdditionalFormError[] = [];

  let totalTaskAssignmentPercentage = 0;
  let hasTaskConfiguration = false; // TODO maybe improve this. This is an easy way to only enforce that all assignment time percentages must add up to 100 for users w/ the configuration enabled. This way non-brokerage users (like software dev engineers) can still be updated.

  if (assignmentConfig) {
    for (const workOrderType of Object.keys(assignmentConfig)) {
      const { taskConfigs } = assignmentConfig[workOrderType];
      for (const taskDefinitionId of Object.keys(taskConfigs)) {
        if (activeTaskDefinitions?.[taskDefinitionId]?.active) {
          hasTaskConfiguration = true;
          const taskConfig = taskConfigs[taskDefinitionId];
          totalTaskAssignmentPercentage += taskConfig.timePercentage || 0;
        }
      }
    }
  }

  if (hasTaskConfiguration && totalTaskAssignmentPercentage > 100) {
    additionalErrors.push({
      title:
        'Task assignment configuration time percentages cannot exceed 100%',
      messages: [
        'The percentage of time alotted for every work order task should sum to 100% or less',
      ],
    });
  }
  return { additionalErrors };
};

export default validateOperatorUser;

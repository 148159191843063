import React from 'react';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { Select, Option, FormItem } from '@xbcb/form-item-components';
import { selectFilter, getCodes } from '@xbcb/ui-utils';
import get from 'lodash/get';

export interface CanadianProvinceSelectProps {
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  localNamePath: NamePath;
}

const CanadianProvinceSelect = ({
  disabled,
  readOnly,
  required,
  localNamePath,
}: CanadianProvinceSelectProps) => {
  const provinceCodes = get(getCodes(), 'ISO.country.alpha2.CA.subdivision');
  const provinceOptions = Object.keys(provinceCodes).map((provinceCode) => (
    <Option key={provinceCode} value={provinceCode}>
      {provinceCodes[provinceCode].name}
    </Option>
  ));

  return (
    <FormItem
      label="Canadian Province"
      $itemSize={CssSize.TINY_SHORT}
      name={localNamePath}
      rules={[{ required, message: ' ' }]}
      $readOnly={readOnly}
    >
      <Select
        disabled={disabled}
        showSearch
        filterOption={selectFilter}
        notFoundContent="None Found"
        dropdownMatchSelectWidth={false}
      >
        {provinceOptions}
      </Select>
    </FormItem>
  );
};

export default CanadianProvinceSelect;

import React from 'react';
import { FormItem, PickDate } from '@xbcb/form-item-components';
import { Form, Input, Row } from 'antd';
import { VehiclesAndEnginesFormProps } from '../../types';
import { DynamicColumn } from './dynamicColumn';

const MODEL_NUMBER_MAX_LENGTH = 15;
const VIN_LENGTH = 17;
const ENGINE_SERIAL_NUMBER_MAX_LENGTH = 17;
const VEHICLE_SERIAL_NUMBER_MAX_LENGTH = 17;

export const ProductDetails: React.FC<VehiclesAndEnginesFormProps> = ({
  localNamePath,
  fullNamePath,
  disabled,
  readOnly,
  required,
}) => {
  const form = Form.useFormInstance();

  return (
    <>
      <Row>
        <FormItem
          label={'Model Number'}
          name={[...localNamePath, 'vehicle', 'model']}
          $readOnly={readOnly}
          rules={[{ required }]}
        >
          <Input.TextArea
            aria-label={'Model Number Textbox'}
            disabled={disabled}
            autoSize={true}
            maxLength={MODEL_NUMBER_MAX_LENGTH}
          />
        </FormItem>

        <FormItem $readOnly={readOnly} rules={[{ required }]}>
          <PickDate
            label={'Manufacture Date'}
            localNamePath={[...localNamePath, 'vehicle', 'manufactureMonth']}
            fullNamePath={[...fullNamePath, 'vehicle', 'manufactureMonth']}
            monthOnly={true}
            form={form}
            disabled={disabled}
            required={required}
          />
        </FormItem>
      </Row>

      <Row>
        <DynamicColumn
          label={'VINs'}
          fieldPath={[
            ...localNamePath,
            'vehicle',
            'vehicleIdentificationNumbers',
          ]}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={VIN_LENGTH}
          minLength={VIN_LENGTH}
        />
        <DynamicColumn
          label={'Engine Serial Numbers'}
          fieldPath={[...localNamePath, 'engines', 'serialNumbers']}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={ENGINE_SERIAL_NUMBER_MAX_LENGTH}
        />
        <DynamicColumn
          label={'Vehicle Serial Numbers'}
          fieldPath={[...localNamePath, 'vehicle', 'serialNumbers']}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={VEHICLE_SERIAL_NUMBER_MAX_LENGTH}
        />
      </Row>
    </>
  );
};

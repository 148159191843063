import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';
import { Collapse } from 'antd';

export const StyledH4 = styled.h4`
  margin-bottom: ${CssSpace.SPACE_4};
`;
export const StyledUl = styled.ul`
  margin: 0 !important;
  padding: 0;
`;

export const StyledCollapse = styled(Collapse.Panel)`
  &&& {
    border: none;
    border-radius: 0px;
    background-color: #ffffff;
    box-shadow: none;
  }
  .ant-collapse-content {
    background: #ffffff;
  }
`;

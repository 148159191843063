import React from 'react';
import { SolutionOutlined } from '@ant-design/icons';
import { StyledHyperlink } from './styles';

interface HelpLinkProps {
  link: string;
  text: string;
}

const HelpLink = ({ text, link }: HelpLinkProps) => {
  return (
    <StyledHyperlink target="_blank" href={link} rel="noopener noreferrer">
      <SolutionOutlined /> {text}
    </StyledHyperlink>
  );
};

export default HelpLink;

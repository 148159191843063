import styled from 'styled-components';
import { Button, Table } from 'antd';

export const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.space_4};
  margin-right: ${({ theme }) => theme.spacing.space_2};
  margin-left: ${({ theme }) => theme.spacing.space_2};
  border: none;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 400;
`;

export const StyledTable = styled(Table)`
  font-size: var(--font-size-body);
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-table-cell {
    padding: ${({ theme }) =>
      `${theme.spacing.space_2} ${theme.spacing.space_4}`};
  }
  td {
    cursor: pointer;
    p {
      width: 320px;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
    }
  }
`;

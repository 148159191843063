import styled from 'styled-components';
import { CssSize, CssSpace } from '@xbcb/ui-types';
import { spaceRight, spaceBottom } from '@xbcb/ui-styles';

// When `$displayHelperTextInSeparateLine` is true, we want the content to be
// displayed as "block" and need to include `spaceBottom` because the content
// is being displayed stacked on top of one another (i.e. in the modal).
// Otherwise, we want it to be `inline-flex` without spaceBottom to mirror how
// FormItems act inline because the content is being displayed side by side
// (i.e. not the modal, instead on the detail / summary page for the WO)
export const StyledEstimatedCostsWrapperDiv = styled.div<{
  $displayHelperTextInSeparateLine?: boolean;
}>`
  display: ${({ $displayHelperTextInSeparateLine }) =>
    $displayHelperTextInSeparateLine ? 'block' : 'inline-flex'};
  ${({ $displayHelperTextInSeparateLine }) =>
    $displayHelperTextInSeparateLine && spaceBottom}
`;

// Width of the `HelperText` such that it matches the width of FormItem
const width = `width: calc(${CssSize.MEDIUM} - ${CssSpace.SPACE_4});`;

// When the StyledParagraph is used standalone (aka not $warn) then we need to
// handle the margin / width styling. Otherwise, if it is $warn, we'll let the
// `StyledWarningDiv` handle the margin / width since we expect the
// `StyledParagraph` to be wrapped by it. But, we do need to change the color.
export const StyledParagraph = styled.p<{ $warn?: boolean }>`
  margin: 0;
  ${({ $warn }) => $warn && 'color: var(--warning-color);'}
  ${({ $warn }) => !$warn && spaceRight}
  ${({ $warn }) => !$warn && spaceBottom}
  ${({ $warn }) => !$warn && width}
`;

export const StyledSpan = styled.span`
  font-weight: bold;
`;

// Needs to handle margin / width styling since it wraps the `StyledParagraph`
// and we don't handle margin / width in that component when it's being wrapped
// (i.e. $warn is true)
export const StyledWarningDiv = styled.div`
  display: flex;
  ${spaceBottom}
  ${spaceRight}
  ${width}

  span:first-child {
    color: var(--warning-color);
    ${spaceRight};
    display: flex;
    align-items: center;
  }
`;

import {
  AppRecordValidateCreateProps,
  AppRecordValidateUpdateProps,
} from 'routes';
import { getCountryCodes } from '@xbcb/ui-utils';
import { AdditionalFormError } from '@xbcb/ui-types';
import { SEARCH_PRODUCTS } from 'libs/sharedQueries';
import { client as apolloClient } from '@xbcb/apollo-client';
import { AnyObject } from '@xbcb/shared-types';

export const validateProductCreate = (
  props: AppRecordValidateCreateProps,
): Promise<{
  additionalErrors: AdditionalFormError[];
}> => {
  return validateProduct({
    payload: props.input,
    productId: undefined,
    operatorId: props.operatorId,
  });
};

export const validateProductUpdate = (
  props: AppRecordValidateUpdateProps,
): Promise<{
  additionalErrors: AdditionalFormError[];
}> => {
  return validateProduct({
    payload: props.input,
    productId: props.existingRecord.id,
    operatorId: props.currentUser?.operator?.id,
  });
};

type ValidateProductProps = {
  payload: AnyObject;
  operatorId?: string;
  productId?: string;
};

// Currently validates if there is already an existing product with the same ClientIdentifier, Manufacturer and CountryOfOrigin combination.
const validateProduct = async ({
  payload: { clientIdentifier, shipper, complianceDetails } = {},
  operatorId,
  productId,
}: ValidateProductProps) => {
  const errors: AdditionalFormError[] = [];

  const countryCodes = getCountryCodes();

  const shipperId = shipper?.id;
  const { manufacturer, origin } = complianceDetails?.us?.entryLines?.[0] || {};
  const manufacturerId = manufacturer?.id;
  const countryOfOriginCode = origin?.countryCode;
  const countryOfOriginName = countryCodes[countryOfOriginCode]?.name;

  const searchCriteria = {
    // Active products
    deletedTime: {
      operator: 'DOES_NOT_EXIST',
    },
    ...(shipperId && {
      shipperId: {
        values: [shipperId],
        operator: 'EQUALS',
      },
    }),
    clientIdentifier: {
      values: [clientIdentifier],
      operator: 'EQUALS',
    },
    countryName: countryOfOriginName
      ? {
          values: [countryOfOriginName],
          operator: 'EQUALS',
        }
      : {
          operator: 'DOES_NOT_EXIST',
        },
    supplierId: manufacturerId
      ? {
          values: [manufacturerId],
          operator: 'EQUALS',
        }
      : {
          operator: 'DOES_NOT_EXIST',
        },

    ...(operatorId && {
      operatorId: { values: [operatorId], operator: 'EQUALS' },
    }),
  };

  const searchResponse = await apolloClient.query({
    query: SEARCH_PRODUCTS,
    variables: {
      input: {
        searchCriteria,
      },
    },
  });

  const existingProducts = searchResponse.data?.searchProducts.results;

  if (existingProducts.length) {
    if (
      // Create Product usecase
      !productId ||
      // Update usecase. If the product found is not the same as the current one.
      existingProducts.filter((result: any) => result.id !== productId).length
    ) {
      errors.push({
        title: 'Duplicate Product',
        messages: [
          `Product with the given Client Identifier, Manufacturer, and Country Of Origin already exists.`,
        ],
      });
    }
  }
  return { additionalErrors: errors };
};

import styled from 'styled-components';
import { Tabs } from 'antd';
import { FormItem } from '@xbcb/form-item-components';

export const StyledTabs = styled(Tabs)<{ $noMarginBottom?: boolean }>`
  overflow: visible;
  ${({ $noMarginBottom }) =>
    !$noMarginBottom &&
    'margin-bottom: ${({ theme }) => theme.spacing.space_4};'}

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-bar {
    margin: 0;
    border: none;
    .ant-tabs-tab {
      border: none !important;
      border-radius: var(--space-2) var(--space-2) 0 0 !important;
      margin-right: var(--space-2) !important;
      background: white !important;
      padding-bottom: 2px !important;
    }
  }
  .ant-tabs-content {
    background: white;
    padding: 24px 32px;
    border-radius: 0 0 var(--space-2) ${({ theme }) => theme.spacing.space_2};
    box-shadow: var(--drop-shadow);
  }
`;
export const StyledHelpLinkDiv = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.space_4};
`;

export const StyledLineFeeParagraph = styled.p`
  display: inline-block;
  line-height: ${({ theme }) => theme.spacing.space_5};
  margin-right: ${({ theme }) => theme.spacing.space_4};
  margin-bottom: 0;
`;

export const StyledInlineDiv = styled.div`
  .ant-row {
    display: inline-block;
  }
  .form-inline {
    display: inline-block;
  }
`;

export const StyledFlexDiv = styled.div`
  display: flex;
`;

export const StyledSteelLicenseGenerateEntryNumberDiv = styled.div`
  margin-top: ${({ theme }) => theme.spacing.space_4};
  display: inline-block;
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0;
`;

const { TabPane } = Tabs;

export const StyledTariffInfoTabPane = styled(TabPane)`
  display: flex;
  justify-content: space-between;
`;

export const StyledPgaFormTab = styled(TabPane)`
  .ant-select-selection-selected-value {
    .info-tooltip {
      display: none;
    }
  }
`;

import { FormInstance } from 'antd/lib/form';
import { NamePath } from '@xbcb/ui-types';

// TODO consider making this not return a callback. returning a callback makes it so that you don't have to wrap clearFields in a callback when passing to onChange for simple cases, but when you need to use it w/ more complex logic it means having to remember to invoke the callback, which is not intuitive.
type clearFieldsProps = {
  form: FormInstance;
  fullNamePath: NamePath;
  fields?: string[];
  exclude?: string[];
};
// only fields or exclude can be used
export const clearFields =
  ({ form, fullNamePath, fields, exclude = [] }: clearFieldsProps) =>
  (): void => {
    //
    // if no specific fields are specified, clear all fields.
    // still need to iterate through the fields and build up the values object so we can check them against the values in exclude
    let allFields: string[] = [];

    if (form.getFieldValue(fullNamePath)) {
      allFields = Object.keys(form.getFieldValue(fullNamePath));
    }

    if (!fields)
      fields = allFields.filter((fieldName) => !exclude.includes(fieldName));

    const clearObj = fields.map((field) => {
      return { name: [...fullNamePath, field], value: undefined };
    });
    form.setFields(clearObj);
  };

import { Checkbox, Form, Input } from 'antd';
import { get, pick } from 'lodash';
import moment from 'moment';
import React, { memo } from 'react';
import { useTheme } from 'styled-components';
import { VehicleDriveSide } from '@xbcb/compliance-types';
import {
  AppRadioGroup,
  CountrySelect,
  FormItem,
  InfoTooltip,
  PickDate,
  Select,
  YearSelect,
} from '@xbcb/form-item-components';
import { LabelAbove } from '@xbcb/static-text-components';
import { getAN, getCodes, getNumber, selectFilter, show } from '@xbcb/ui-utils';
import ButterContent from 'components/ButterContent';
import Lpco from 'components/Lpco';
import { LpcoFields } from 'components/Lpco/lpcoFields';
import Quantity from 'components/Quantity';
import TradeName from 'components/TradeName';
import Mask from 'libs/mask';
import { PgaFormBaseProps } from 'types';
import {
  StyledBothSpaceInfoTooltip,
  StyledDOTHeader,
  StyledInfoTooltip,
  StyledInlineDiv,
  StyledLeftSpaceInlineDiv,
  StyledOption,
} from './styles';
import { YEAR_FIRST_CAR_WAS_MANUFACTURED } from 'libs/vehicles';

const NhtForm = ({
  readOnly,
  disabled,
  localNamePath,
  fullNamePath,
  required,
}: PgaFormBaseProps) => {
  const form = Form.useFormInstance();
  const theme = useTheme();
  const endYear = moment().add('1', 'year').year();

  const codes = getCodes();
  const declarationData: any = pick(codes.CBP.CATAIR.PGA.document, [
    '165',
    '871',
    '872',
    '873',
    '874',
    '875',
    '958',
  ]);

  const categoryCodes: any = pick(codes.CBP.CATAIR.PGA.category, [
    'MVSTYP',
    'REITYP',
    'TPETYP',
    'OEITYP',
    'OFFTYP',
  ]);

  const bondSerialNumberMask = new Mask([
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ]);

  const declarationDocument = (code: string) => (
    <>
      {declarationData[code].name}
      <StyledBothSpaceInfoTooltip title={declarationData[code].description} />
    </>
  );

  const radioOptions = Object.values(VehicleDriveSide).map((value) => ({
    value,
    label: (value as string)[0] + (value as string).slice(1).toLowerCase(),
  }));

  const programCode = Form.useWatch([...fullNamePath, 'programCode'], form);
  const category = Form.useWatch([...fullNamePath, 'categoryCode'], form);
  const declaration = Form.useWatch(
    [...fullNamePath, 'conformanceDeclaration'],
    form,
  );
  const ovm = Form.useWatch(
    [...fullNamePath, 'iorIsVehicleManufacturer'],
    form,
  );

  const conformanceCodes = [];
  if (programCode === 'MVS') {
    conformanceCodes.push(
      '1',
      '2A',
      '2B',
      '3',
      '4',
      '5',
      '6',
      '7',
      '9',
      '10',
      '12',
      '13',
    );
  } else if (programCode === 'OFF') {
    conformanceCodes.push('8');
  } else if (programCode === 'REI') {
    conformanceCodes.push('2A', '7');
  } else if (programCode === 'TPE') {
    conformanceCodes.push('11');
  } else if (programCode === 'OEI') {
    conformanceCodes.push('1', '9');
  }
  const conformanceOptions = conformanceCodes.map((code) => (
    <StyledOption key={code} value={code}>
      {code}{' '}
      <InfoTooltip title={<ButterContent code={`nhtDeclaration_${code}`} />} />
    </StyledOption>
  ));

  // categoryOptions
  const categoryOptions = Object.entries(
    get(categoryCodes, `${programCode}TYP.categoryCodes`, {}),
  ).map(([code, data]: [string, any]) => (
    <StyledOption key={code} value={code}>
      {code} - {data.name}
      <StyledInfoTooltip title={data.description} />
    </StyledOption>
  ));

  return (
    <div>
      <StyledInlineDiv>
        <FormItem
          $itemSize={theme.size.tiny_short}
          name={[...localNamePath, 'conformanceDeclaration']}
          label="Conformance Declaration"
          rules={[{ required, message: ' ' }]}
          $readOnly={readOnly}
        >
          <Select
            disabled={disabled}
            showSearch
            allowClear
            filterOption={selectFilter}
            notFoundContent="None Found"
            dropdownMatchSelectWidth={false}
          >
            {conformanceOptions}
          </Select>
        </FormItem>

        {programCode && (
          <FormItem
            $itemSize={theme.size.tiny_short}
            label="Category"
            name={[...localNamePath, 'categoryCode']}
            rules={[{ required, message: ' ' }]}
            $readOnly={readOnly}
          >
            <Select
              disabled={disabled}
              showSearch
              allowClear
              filterOption={selectFilter}
              notFoundContent="None Found"
              dropdownMatchSelectWidth={false}
            >
              {categoryOptions}
            </Select>
          </FormItem>
        )}

        {['MVS', 'REI'].includes(programCode) && (
          <TradeName
            localNamePath={localNamePath}
            required={required}
            size={theme.size.tiny_short}
            readOnly={readOnly}
            disabled={disabled}
          />
        )}

        {category === 'REI7' && (
          <FormItem
            $itemSize={theme.size.tiny_short}
            name={[...localNamePath, 'glazingManufacturerCode']}
            label="Glazing Manufacturer Code"
            rules={[{ required, message: ' ' }]}
            $readOnly={readOnly}
          >
            <Input disabled={disabled} />
          </FormItem>
        )}

        {category === 'REI1' && (
          <FormItem
            $itemSize={theme.size.tiny_short}
            label="Tire Manufacturer Code"
            name={[...localNamePath, 'tireManufacturerCode']}
            rules={[{ required, message: ' ' }]}
            getValueFromEvent={getAN(3)}
            $readOnly={readOnly}
          >
            <Input disabled={disabled} />
          </FormItem>
        )}

        {programCode === 'MVS' && (
          <FormItem
            $itemSize={theme.size.tiny_short}
            label="Model"
            name={[...localNamePath, 'vehicle', 'model']}
            rules={[{ required, message: ' ' }]}
            $readOnly={readOnly}
          >
            <Input disabled={disabled} />
          </FormItem>
        )}

        {declaration === '1' && (
          <PickDate
            form={form}
            label="Manufacture Month"
            fullNamePath={[...fullNamePath, 'vehicle', 'manufactureMonth']}
            localNamePath={[...localNamePath, 'vehicle', 'manufactureMonth']}
            readOnly={readOnly}
            disabled={disabled}
            disabledDate={(current) =>
              current && current > moment().endOf('month')
            }
            required={required}
            monthOnly
          />
        )}
      </StyledInlineDiv>

      {programCode === 'MVS' && (
        <StyledInlineDiv>
          <FormItem
            $itemSize={theme.size.tiny_short}
            label="Vehicle Identification Numbers"
            name={[...localNamePath, 'vehicle', 'vehicleIdentificationNumbers']}
            rules={[{ required, message: ' ' }]}
            $readOnly={readOnly}
          >
            <Select
              mode="tags"
              disabled={disabled}
              notFoundContent="Type then press enter to add"
              tokenSeparators={[',', ' ', '/']}
            />
          </FormItem>

          <YearSelect
            form={form}
            localNamePath={[...localNamePath, 'vehicle', 'modelYear']}
            readOnly={readOnly}
            disabled={disabled}
            required={required}
            label="Model Year"
            startYear={YEAR_FIRST_CAR_WAS_MANUFACTURED}
            endYear={endYear}
          />
          {declaration === '6' && (
            <CountrySelect
              localNamePath={[...localNamePath, 'embassyCountryCode']}
              required={required}
              label="Embassy country"
              readOnly={readOnly}
              disabled={disabled}
              foreignOnly={true}
              displayCode={true}
              $itemSize={theme.size.tiny_short}
            />
          )}
          {declaration === '5' && (
            <>
              <FormItem
                $itemSize={theme.size.tiny_short}
                label="Passport number"
                name={[...localNamePath, 'passportNumber']}
                rules={[{ required, message: ' ' }]}
                getValueFromEvent={getAN(35)}
                $readOnly={readOnly}
              >
                <Input disabled={disabled} />
              </FormItem>

              <CountrySelect
                localNamePath={[...localNamePath, 'passportCountryCode']}
                required={required}
                label="Passport country"
                readOnly={readOnly}
                disabled={disabled}
                foreignOnly={true}
                displayCode={true}
                $itemSize={theme.size.tiny_short}
              />
            </>
          )}
          {['2B', '3'].includes(declaration) && (
            <AppRadioGroup
              label="Drive side"
              readOnly={readOnly}
              disabled={disabled}
              options={radioOptions}
              localNamePath={[...localNamePath, 'driveSide']}
              rules={[{ required, message: ' ' }]}
            />
          )}
        </StyledInlineDiv>
      )}

      <StyledInlineDiv>
        {['3', '13'].includes(declaration) && (
          <Lpco
            form={form}
            fullNamePath={[
              ...fullNamePath,
              LpcoFields.REGISTERED_IMPORTER_NUMBER,
            ]}
            localNamePath={[
              ...localNamePath,
              LpcoFields.REGISTERED_IMPORTER_NUMBER,
            ]}
            readOnly={readOnly}
            disabled={disabled}
            required={required}
            type="NH0"
            regex={[/[A-Z]/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
          />
        )}
        {declaration === '3' && (
          <Lpco
            form={form}
            fullNamePath={[
              ...fullNamePath,
              LpcoFields.VEHICLE_ELIGIBILITY_NUMBER,
            ]}
            localNamePath={[
              ...localNamePath,
              LpcoFields.VEHICLE_ELIGIBILITY_NUMBER,
            ]}
            readOnly={readOnly}
            disabled={disabled}
            required={required}
            type="NH3"
            regex={[/[A-Z]/, /[A-Z]/, /[A-Z]/, '-', /\d/, /\d/, /\d/]}
          />
        )}
      </StyledInlineDiv>

      {declaration === '7' &&
        show({
          readOnly,
          form,
          field: [...fullNamePath, 'iorIsVehicleManufacturer'],
        }) && (
          <FormItem
            $itemSize={theme.size.short_medium}
            name={[...localNamePath, 'iorIsVehicleManufacturer']}
            rules={[{ required: false }]}
            valuePropName="checked"
            $readOnly={readOnly}
          >
            <Checkbox disabled={disabled}>
              Original vehicle manufacturer?{' '}
              <StyledInfoTooltip title="The importer is an original manufacturer of motor vehicles (or a wholly owned subsidiary thereof) that is certified to conform to all applicable Federal Motor Vehicle Safety Standards (FMVSS). Use on the public roads must be authorized specifically. [591.6(f )(1) or (2)];" />
            </Checkbox>
          </FormItem>
        )}
      {((!ovm && declaration === '7') ||
        ['10', '13'].includes(declaration)) && (
        <StyledInlineDiv>
          <Lpco
            form={form}
            fullNamePath={[...fullNamePath, LpcoFields.PERMISSION_LETTER]}
            localNamePath={[...localNamePath, LpcoFields.PERMISSION_LETTER]}
            readOnly={readOnly}
            disabled={disabled}
            required={required}
            type="NH2"
            regex={[
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
          />
          <Quantity
            field={[...localNamePath, 'permissionLetter', 'quantity']}
            label="Letter quantity"
            readOnly={readOnly}
            disabled={disabled}
            $itemSize={theme.size.tiny_short}
            required={required}
            max={999999999999}
          />

          <PickDate
            form={form}
            fullNamePath={[...fullNamePath, 'permissionLetter', 'date']}
            localNamePath={[...localNamePath, 'permissionLetter', 'date']}
            label="Date issued/signed"
            readOnly={readOnly}
            disabled={disabled}
            disabledDate={(current) =>
              current && current > moment().endOf('day')
            }
            required={required}
          />
        </StyledInlineDiv>
      )}
      {declaration === '3' && (
        <div>
          <StyledDOTHeader>DOT Conformance Bond</StyledDOTHeader>
          <FormItem
            $itemSize={theme.size.tiny}
            label="Surety code"
            name={[...localNamePath, 'dotBond', 'suretyCode']}
            rules={[{ required, message: ' ' }]}
            getValueFromEvent={getNumber(3)}
            $readOnly={readOnly}
            $inline
          >
            <Input disabled={disabled} />
          </FormItem>
          <FormItem
            $itemSize={theme.size.tiny}
            label="Serial number"
            name={[...localNamePath, 'dotBond', 'serialNumber']}
            rules={[{ required, message: ' ' }]}
            getValueFromEvent={(e: any) => bondSerialNumberMask.handleChange(e)}
            $readOnly={readOnly}
            $inline
          >
            <Input disabled={disabled} />
          </FormItem>
          <FormItem
            $itemSize={theme.size.tiny}
            label="Amount/value"
            name={[...localNamePath, 'dotBond', 'amount']}
            rules={[{ required, message: ' ' }]}
            getValueFromEvent={getNumber(8)}
            $readOnly={readOnly}
            $inline
          >
            <Input disabled={disabled} />
          </FormItem>
        </div>
      )}
      <StyledLeftSpaceInlineDiv>
        {['2B', '3', '6', '9', '12'].includes(declaration) && (
          <Form.Item help={null}>
            <LabelAbove
              text={
                <>
                  Mandatory submitted documents
                  <StyledBothSpaceInfoTooltip title="By filing this entry you are affirming that these electronic images have already been uploaded via DIS." />
                </>
              }
            />
            {declaration === '2B' && declarationDocument('872')}
            {declaration === '3' && declarationDocument('165')}
            {['6', '12'].includes(declaration) && declarationDocument('874')}
            {declaration === '9' && (
              <>
                {declarationDocument('875')}
                {declarationDocument('958')}
              </>
            )}
          </Form.Item>
        )}
        {['3', '7', '8'].includes(declaration) && (
          <Form.Item help={null}>
            <LabelAbove
              text={
                <>
                  Additional documents
                  <StyledInfoTooltip title="By filing this entry, you are affirming that you are in posession of the following documents (contracts, PGA permission letters, etc.) needed to import a product that is under a government agency jurisdiction." />
                </>
              }
            />
            {['7', '8'].includes(declaration) && declarationDocument('871')}
            {declaration === '3' && declarationDocument('873')}
          </Form.Item>
        )}
      </StyledLeftSpaceInlineDiv>
    </div>
  );
};

export default memo(NhtForm);

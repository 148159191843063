import {
  Classifications,
  GlobalSearchTable,
  Tutorials,
  Queries,
  AssignmentWorkspace,
} from './loadableComponents';

export type IndependentRoute = {
  Page: React.FC;
  path: string;
};

// IndependentRoutes are the ones rendered by AuthenticatedRoutes, but are not specific to a record type. Thus, it should be rendered here rather than as part of the AppRecord framework.
export const independentRoutes: IndependentRoute[] = [
  {
    Page: GlobalSearchTable,
    path: '/search',
  },
  {
    Page: Tutorials,
    path: '/help',
  },
  {
    Page: Queries,
    path: '/queries',
  },
  {
    Page: Classifications,
    path: '/classifications',
  },
  {
    Page: AssignmentWorkspace,
    path: '/assignment-workspace',
  },
];

import React from 'react';
import { Option, FormItem, Select } from '@xbcb/form-item-components';
import { selectFilter } from '@xbcb/ui-utils';
import { CssSize, NamePath } from '@xbcb/ui-types';

export interface DisclaimerSelectProps {
  localNamePath: NamePath;
  label?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  filter?: string[];
  disclaimerCodes?: Record<string, string>;
}

const DisclaimerSelect: React.FC<DisclaimerSelectProps> = ({
  disclaimerCodes = {},
  filter,
  localNamePath,
  label = 'Reason',
  required,
  disabled,
  readOnly = false,
}) => {
  const options = Object.keys(disclaimerCodes).reduce(
    (acc: JSX.Element[], disclaimerCode, index) => {
      if (!filter || filter.includes(disclaimerCode)) {
        acc.push(
          <Option key={index} value={disclaimerCode} className="tooltip-option">
            {disclaimerCodes[disclaimerCode]}
          </Option>,
        );
      }
      return acc;
    },
    [],
  );
  return (
    <FormItem
      label={label}
      name={localNamePath}
      rules={[{ required, message: ' ' }]}
      $readOnly={readOnly}
      $itemSize={CssSize.SHORT}
    >
      <Select
        aria-label={'disclaim-reason'}
        disabled={disabled}
        showSearch
        allowClear
        filterOption={selectFilter}
        notFoundContent="None Found"
        dropdownMatchSelectWidth={false}
      >
        {options}
      </Select>
    </FormItem>
  );
};

export default DisclaimerSelect;

import React, { useState } from 'react';
import { AutoComplete } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { conformToMask } from 'text-mask-core';
import { FormItem } from '@xbcb/form-item-components';
import { getCodes, show, safeGetMessage } from '@xbcb/ui-utils';
import { CssSize, DataCyPrefix, NamePath } from '@xbcb/ui-types';
import { StyledDiv, StyledTooltip } from './styles';
import { useBundle } from '@amzn/react-arb-tools';

const { Option } = AutoComplete;

const mask = [/[A-Z]/, /[A-Z]/, /[A-Z]/, /[A-Z]/];

type SCACSelectProps = {
  disabled?: boolean;
  form: FormInstance;
  $inline?: boolean;
  readOnly?: boolean;
  localNamePath: NamePath;
  fullNamePath: NamePath;
  label?: string;
  required?: boolean;
  $itemSize?: CssSize;
};

const SCACSelect: React.FC<SCACSelectProps> = ({
  disabled,
  form,
  $inline,
  readOnly,
  localNamePath,
  fullNamePath,
  label,
  required,
  $itemSize,
}) => {
  const [partyBundle] = useBundle('components.Party');
  const [sharedBundle] = useBundle('shared');
  const scacCodes = getCodes().NMFTA.SCAC;
  // TODO update this default
  const [dataSource, setDataSource] = useState<string[]>([]);
  const [scacPreviousConformedValue, setScacPreviousConformedValue] = useState([
    '',
  ]);

  const getValueFromEvent = (val: any) => {
    const result = !val
      ? val
      : conformToMask(val.toUpperCase(), mask, {
          guide: false,
          previousConformedValue: scacPreviousConformedValue,
        }).conformedValue;
    setDataSource(
      result ? Object.keys(scacCodes).filter((s) => s.startsWith(result)) : [],
    );
    setScacPreviousConformedValue(result);
    return result;
  };

  const options = dataSource.map((scacCode) => {
    return (
      <Option key={scacCode} value={scacCode}>
        {scacCode} {scacCodes[scacCode]}
      </Option>
    );
  });

  if (!show({ readOnly, form, field: fullNamePath })) return null;
  return (
    <StyledDiv $inline={$inline}>
      <FormItem
        label={label ? label : safeGetMessage(partyBundle, 'scac')}
        name={localNamePath}
        rules={[{ len: 4, message: ' ' }]}
        getValueFromEvent={getValueFromEvent}
        required={required}
        $inline={$inline}
        $readOnly={readOnly}
        $itemSize={$itemSize || CssSize.MICRO_TINY}
        data-cy={DataCyPrefix.SCAC}
      >
        <AutoComplete
          disabled={disabled}
          allowClear
          placeholder="AAAA"
          notFoundContent={safeGetMessage(sharedBundle, 'none_found')}
          // optionLabelProp="value"
          dropdownMatchSelectWidth={false}
        >
          {options}
        </AutoComplete>
      </FormItem>
      <StyledTooltip
        spaceTop
        title={safeGetMessage(partyBundle, 'standard_carrier_alpha_code')}
        spaceRight
      />
    </StyledDiv>
  );
};

export default SCACSelect;

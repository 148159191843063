import React from 'react';
import { Option } from '@xbcb/form-item-components';
import { formatCurrency } from '@xbcb/js-utils';

// Note: The chart / functionality in this Asana task dictates the
// functionality throughout this file:
// https://app.asana.com/0/1199988180183028/1200507550844301

// To mirror the guide ops uses we will supported these bond amounts (they are
// represented as X number of thousands). See the Asana task for more details.
// NOTE: This array should stay in sorted order.
const validRequestedBondAmounts = [
  50, 60, 70, 80, 90, 100, 200, 300, 400, 500,
].sort((num1, num2) => (num1 < num2 ? num1 : num2));

export const smallestSupportedBondAmount = validRequestedBondAmounts[0];

export const largestSupportedBondAmount =
  validRequestedBondAmounts[validRequestedBondAmounts.length - 1];

// We always round up. And we always round to the nearest 100,000 until we
// hit 1,000,000 then we round to the nearest 1,000,000. See the chart in the
// Asana task for more clarification.
export const convertShippingValueEstimateToBondAmount = (
  shippingValueEstimate: number,
): number => {
  // We want to have the functionality where values such as 600,000 yield a
  // bond amount of 70K (since it's outside of the 500,000 - 599,999.99 band).
  // Thus, we always add one penny (0.01) to the provided shippingValueEstimate
  // in case the user provides an exact value such as 600,000. This if because
  // each bond amount covers everything _up to but not including_ 10 times the
  // bond amount. That's because it must cover at least 10% of the shipperValueEstimate
  const calculatedEstimate = shippingValueEstimate + 0.01;
  const precision = calculatedEstimate < 1000000 ? 100000 : 1000000;
  const roundedResult = Math.ceil(calculatedEstimate / precision) * precision;
  // Now take the roundedResult and multiple by 0.1 since bond amount is always
  // 10% of the shippingValueEstimate. Then divide by 1000 as the bondAmount is
  // shown in X number of thousands
  return (roundedResult * 0.1) / 1000;
};

// validRequestedBondAmounts are represented in thousands of dollars, thus add
// a `$` to the beginning and `K` to the end representing X number of thousands
export const formatBondAmount = (amount: number): string => `$${amount}K`;

// Takes a bond amount (represented in X number of thousands) and returns the
// formatted max shipping value estimate for the provided bond amount
export const convertBondAmountToFormattedMaxShippingValueEstimate = (
  amount: number,
): string => {
  // The bond amount is represented in X number of thousands, thus we multiple by
  // 1000. It also covers 10% of the shipping value estimate so we multiple the
  // result by 10. Lastly, it must _cover_ 10% meaning everything up to but not
  // including exactly 10% so we subtract 1 cent (.01). For example a 50K bond
  // coverage amount should yield a max shipping value estimate of $499,999.99
  const maxShippingValueEstimate = amount * 1000 * 10 - 0.01;
  return formatCurrency(maxShippingValueEstimate.toString(), '$');
};

// validRequestedBondAmounts are represented in thousands of dollars, thus
// we need to multiple by 1000 to get the actual bond amount, which is what we
// want to store.
const convertBondAmountToStoredBondAmount = (amount: number) => amount * 1000;

// Create the bond amount options from the validRequestedBondAmounts
export const bondAmountOptions = validRequestedBondAmounts.map(
  (validRequestedBondAmount) => {
    const storedValue = convertBondAmountToStoredBondAmount(
      validRequestedBondAmount,
    );
    return (
      <Option key={storedValue} value={storedValue}>
        {formatBondAmount(validRequestedBondAmount)}
      </Option>
    );
  },
);

// Now that we've added the supported bond amount options, add one last option
// which is for rare use cases where the requested bond amount is larger than
// 500K (i.e. the estimated duties/taxes/fess are 5M or greater). Since the
// largest supported bond amount is 500K, we'll store this value as 600K
const customQuoteValue = convertBondAmountToStoredBondAmount(
  largestSupportedBondAmount + 100000,
);
bondAmountOptions.push(
  <Option key={customQuoteValue} value={customQuoteValue}>
    More than {formatBondAmount(largestSupportedBondAmount)}
  </Option>,
);

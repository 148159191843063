import { WorkOrderRoute } from '../.';
import { RecordType } from '@xbcb/shared-types';
import { CustomsDeclaration } from '../../../loadableComponents';
import {
  getSharedCustomsEntrySubmitButtons,
  sharedCustomsEntryKebabMenuItems,
} from '../../utils';
import CustomsEntrySecondaryHeader from 'components/CustomsEntrySecondaryHeader';
import { transformEuCustomsEntry } from 'libs/formTransforms/workOrder/euCustomsEntry';
import { WorkOrderType } from '@xbcb/work-order-types';

import {
  queryCustomsDeclaration,
  updateCustomsDeclaration,
} from './customsDeclaration.query';

const customsDeclarationRoute: WorkOrderRoute = {
  Page: CustomsDeclaration,
  recordType: RecordType.CUSTOMS_DECLARATION,
  queries: { get: queryCustomsDeclaration, update: updateCustomsDeclaration },
  SecondaryHeaderContents: [CustomsEntrySecondaryHeader],
  transformUpdateRecordInput: transformEuCustomsEntry.toSchema, // TODO, will likely have its own transform
  defaultEditMode: false,
  kebabMenuItems: sharedCustomsEntryKebabMenuItems,
  submitButtons: getSharedCustomsEntrySubmitButtons(
    WorkOrderType.CustomsDeclaration,
  ),
};

export default customsDeclarationRoute;

import { Tooltip } from 'antd';
import React, { memo } from 'react';
import type { UsConsumptionEntry } from '@xbcb/api-gateway-client';
import { DataCyPrefix, DataCySuffix, createDataCyValue } from '@xbcb/ui-types';
import { getCensusWarningsFromEntry } from 'libs/getWarningMessages';
import { StyledWarningOutline, StyledWarningTitle } from './styles';

// There is no subheader currently for Type86 entries.
// We can do a union `UsConsumptionEntry | UsType86Entry`
// when that becomes possible.
export interface WorkOrderCensusWarningProps {
  record?: UsConsumptionEntry;
}

const titleDataCy = createDataCyValue(
  DataCyPrefix.CENSUS_WARNING,
  DataCySuffix.TOOLTIP_TITLE,
);
const iconDataCy = createDataCyValue(
  DataCyPrefix.CENSUS_WARNING,
  DataCySuffix.WARNING_ICON,
);

export const WorkOrderCensusWarning: React.FC<WorkOrderCensusWarningProps> = ({
  record,
}) => {
  if (!record) return null;
  const censusWarnings = getCensusWarningsFromEntry(record);

  if (!censusWarnings.length) return null;

  const title = (
    <div data-cy={titleDataCy}>
      <StyledWarningTitle>Entry Summary (AX) Census Issues:</StyledWarningTitle>
      {censusWarnings.map((warning: string, index: number) => (
        <p key={`${warning}-${index}`}>{warning}</p>
      ))}
    </div>
  );

  return (
    <Tooltip title={title} placement="top" overlayStyle={{ maxWidth: '750px' }}>
      <StyledWarningOutline data-cy={iconDataCy} />
    </Tooltip>
  );
};

export default memo(WorkOrderCensusWarning);

import { WorkOrderTaskConfiguration } from 'libs/hooks/workOrderTaskConfiguration/types';
import { WorkOrderTaskType } from '@xbcb/work-order-types';
import {
  requiredCommonFields,
  readOnlyCommonFields,
  transportationTab,
} from './sharedUsEntry/common.json';

type WorkOrderTaskTypeRecord = {
  [key in WorkOrderTaskType]?: WorkOrderTaskConfiguration;
};
// TODO: Implement code in such a way that we can use localNamePath /fullPathName as key for the configMap. Refer following asana comments :https://app.asana.com/0/1202349248932865/1202944827785759
export const WOTConfigurationData: WorkOrderTaskTypeRecord = {
  [WorkOrderTaskType.US_CONSUMPTION_ENTRY_BUILD_INVOICE]: {
    requiredFields: [...requiredCommonFields],
    readOnlyFields: [...readOnlyCommonFields],
  },
  [WorkOrderTaskType.US_CONSUMPTION_ENTRY_BUILD_TRANSPORTATION_AND_INVOICE]: {
    requiredFields: [...requiredCommonFields, ...transportationTab],
    readOnlyFields: [...readOnlyCommonFields],
  },
  [WorkOrderTaskType.US_CONSUMPTION_ENTRY_CENSUS_WARNING]: {
    requiredFields: [...requiredCommonFields],
    readOnlyFields: [...readOnlyCommonFields],
  },
};

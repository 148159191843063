/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import copyTextToClipboard from 'libs/copyToClipboard';
import { LinkOutlined, MailOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { getMailTo } from '@xbcb/ui-utils';
import {
  StyledLinkOptionDiv,
  StyledLinkOptionSpan,
  StyledLinkDiv,
} from './styles';

type PresentLinkProps = {
  link: string;
  linkType: string;
};

const PresentLink = ({ link, linkType }: PresentLinkProps) =>
  link && linkType ? (
    <StyledLinkDiv>
      <StyledLinkOptionDiv>
        <a rel="noopener noreferrer" onClick={() => copyTextToClipboard(link)}>
          <LinkOutlined />
          <StyledLinkOptionSpan>
            Click to copy the link to your clipboard
          </StyledLinkOptionSpan>
        </a>
      </StyledLinkOptionDiv>
      <Divider>
        <div className="or">or</div>
      </Divider>
      <StyledLinkOptionDiv>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={getMailTo(linkType, link)}
        >
          <MailOutlined />
          <StyledLinkOptionSpan>
            Click to draft an email with the link
          </StyledLinkOptionSpan>
        </a>
      </StyledLinkOptionDiv>
    </StyledLinkDiv>
  ) : null;

export default PresentLink;

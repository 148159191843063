import { pick } from 'lodash';
import { AnyObject } from '@xbcb/shared-types';
import { UsIsfShipmentTypeCode } from '@xbcb/work-order-types';
import { transformWorkOrderBase } from './base';

export const transformUsIsf = {
  toForm: ({ existingRecord }: { existingRecord: AnyObject }) => {
    const newRecord = transformWorkOrderBase.toForm({ existingRecord });

    if (newRecord.shipmentTypeCode === UsIsfShipmentTypeCode.INFORMAL) {
      newRecord.isInformal = true;
    } else {
      newRecord.isInformal = false;
    }
    const continuousBonds = newRecord.ior?.usIor?.continuousBonds; // TODO refactor RecordSelect nesting to fix this.
    if (continuousBonds) {
      newRecord.ior.continuousBonds = continuousBonds;
    }
    const addresses = newRecord.ior?.usIor?.addresses; // TODO refactor RecordSelect nesting to fix this.
    if (addresses) {
      newRecord.ior.address = addresses?.mailing || addresses?.physical;
    }
    return newRecord;
  },
  toSchema: ({
    input,
    existingRecord,
  }: {
    input: AnyObject;
    existingRecord: AnyObject;
  }) => {
    const cleanedOutput = transformWorkOrderBase.toSchema({
      input,
      existingRecord,
    });
    const masterBills = cleanedOutput.masterBills;
    masterBills?.forEach((masterBill: AnyObject) => {
      if (masterBill?.number === undefined) {
        const index = masterBills.indexOf(masterBill, 0);
        masterBills.splice(index, 1); // not using delete as it sets the element to undefined causing required value not provided exception.
      }
      const houseBills = masterBill?.houseBills;
      houseBills?.forEach((houseBill: AnyObject) => {
        if (houseBill?.number === undefined) {
          const index = houseBills.indexOf(houseBill, 0);
          houseBills.splice(index, 1);
        }
      });
    });
    delete cleanedOutput.broker;
    delete cleanedOutput.isInformal; // want to delete even if this is false so we don't include it in the GraphQL input object.
    delete cleanedOutput.ior?.continuousBonds; // we don't transmit this field, but we need to fetch it and keep it in the form to know whether to render the Bond tab.
    delete cleanedOutput.ior?.address;
    if (cleanedOutput.ior?.usIor) {
      // usIor is an IdVersion input
      cleanedOutput.ior.usIor = pick(cleanedOutput.ior.usIor, [
        'id',
        'version',
      ]);
    }
    return cleanedOutput;
  },
};

// This component no longer takes in errors.
// If they are needed please refer the older version of this file
// or the file in legacy repo (packages/components/ISFNotificationModal/component.js)
import React from 'react';
import { Collapse } from 'antd';
import { StyledH4, StyledUl, StyledCollapse } from './styles';

type PropsForIsfNotificationModalContent = {
  notifications: string[];
};

const IsfNotificationModalContent = ({
  notifications,
}: PropsForIsfNotificationModalContent) => {
  const reviewHeader =
    'After reviewing the notifications, refresh the page to finish';
  return (
    <div>
      <StyledH4>{reviewHeader}</StyledH4>

      <Collapse bordered={false}>
        <StyledCollapse key="isf-upload-panel" header="Details">
          <StyledUl>
            {notifications.map((l) => (
              <li>{l}</li>
            ))}
          </StyledUl>
        </StyledCollapse>
      </Collapse>
    </div>
  );
};
export default IsfNotificationModalContent;

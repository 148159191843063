import React from 'react';
import PartyForm from 'components/PartyForm';
import { RecordType } from '@xbcb/shared-types';
import { useModal } from '@xbcb/ui-utils';
import TeamDataModal from 'components/TeamDataModal';
import { ModalKey } from 'types';
import { RecordProps } from 'routes';

const Consignee: React.FC<RecordProps> = ({
  disabled,
  readOnly,
  form,
  hideDocs,
}) => {
  const { closeModal } = useModal(ModalKey.TEAM_DATA);
  return (
    <>
      <TeamDataModal
        closeModal={closeModal}
        recordType={RecordType.US_CONSIGNEE}
      />
      <PartyForm
        recordType={RecordType.US_CONSIGNEE}
        disabled={disabled}
        readOnly={readOnly}
        form={form}
        addressRequired
        showUsCbp5106AddressType
        showUnknownDuns
        contactInfoRequired
        enforceNamePattern
        usOnly
        hideDocs={hideDocs}
        iorNumberRequired
      />
    </>
  );
};

export default Consignee;

import React, { useState } from 'react';
import { Input, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { conformToMask } from 'text-mask-core';
import { show, safeGetMessage } from '@xbcb/ui-utils';
import { FormItem } from '@xbcb/form-item-components';
import { CssSize, DataCyPrefix, NamePath } from '@xbcb/ui-types';
import { useBundle } from '@amzn/react-arb-tools';

// NOTE: Do not use the mask from @xbcb/form-item-components/dist/IORNumber/helper
// This mask is different and our schema does not currently support the mask
// defined there. This component is meant to be used when the schema expects
// `EmployeeIdentificationNumber` which is defined as: /^\d{2}-\d{7}$/
// The mask mentioned above is meant to be used when the schema expects
// `UsIorNumberValue` which supports /^\d{2}-\d{7}[A-Z0-9]{2}$/. Thus, we don't
// want to limit the functionality of `UsIorNumberValue` (which can include two
// more digits) just to support this component. Therefore, we must redefine the
// mask here strictly for `EmployeeIdentificationNumber`
// TODO explore whether `EmployeeIdentificationNumber` should be updated in the
// schema to support /^\d{2}-\d{7}[A-Z0-9]{2}$/ like `UsIorNumberValue`
const mask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

const einLength = 10;

interface EINNumberProps {
  localNamePath: NamePath;
  fullNamePath: NamePath;
  disabled?: boolean;
  required?: boolean;
  form: FormInstance;
  readOnly?: boolean;
}

const EINNumber: React.FC<EINNumberProps> = ({
  localNamePath,
  fullNamePath,
  disabled,
  required,
  form,
  readOnly,
}) => {
  const [partyBundle] = useBundle('components.Party');
  const [einPreviousConformedValue, setEinPreviousConformedValue] =
    useState('');

  const handleEINChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e || !e.currentTarget) return e;

    // Had to change this to currentTarget for ts to be happy
    const { value } = e.currentTarget;
    const { conformedValue } = conformToMask(value, mask, {
      guide: false,
      previousConformedValue: einPreviousConformedValue,
    });

    setEinPreviousConformedValue(conformedValue);

    return conformedValue;
  };

  const showComponent = show({
    readOnly,
    form,
    field: fullNamePath,
  });
  // TODO remove this check once all data is corrected
  // If the ein is the long version (NN-NNNNNNNXX) then we need to
  // truncate it by removing the `00` at the end. This is a temp fix
  // until we've fixed all truckers as they should have NN-NNNNNNN
  // format. The misformatted ones are from the data migration
  const ein = form.getFieldValue(fullNamePath);
  if (ein?.length === 12) {
    if (ein.endsWith('00')) {
      // override the einValue to only be the first 10 digits (i.e.
      // `NN-NNNNNNN`), and dropping the `00`
      const einValue = ein.slice(0, einLength);
      form.setFields([{ name: fullNamePath, value: einValue }]);
    } else {
      // This is extremely rare, potentially never but showing an error
      // just in case and making sure we don't override the EIN number
      // as we can't just simply drop the `00`
      message.error(safeGetMessage(partyBundle, 'trucker_ein_incorrect'));
    }
  }
  return showComponent ? (
    <FormItem
      $itemSize={CssSize.TINY_SHORT}
      name={localNamePath}
      rules={[
        {
          required,
          min: einLength,
          max: einLength,
          message: ' ',
        },
      ]}
      getValueFromEvent={handleEINChange}
      label={safeGetMessage(partyBundle, 'federal_tax_id')}
      $inline
      $readOnly={readOnly}
      data-cy={DataCyPrefix.EIN_NUMBER}
    >
      <Input placeholder="NN-NNNNNNN" disabled={disabled} />
    </FormItem>
  ) : null;
};

export default EINNumber;

import { handleActions } from 'redux-actions';
import { set, get } from 'lodash';

// TODO Do we need an inBondCache + inBondsCountCache?
const singleUIDefaults = {
  loaded: false,
  isLoading: false,
  saving: false,
  simpleCache: {},
  cacheValid: false,
  chargeKeys: [0],
  activeInvoice: '0',
  activeProduct: 0,
  addingDO: false,
  taggingForwarder: false,
  isUpdatingISF: false,
  inBonds: {},
  isUpdatingEntry: false,
  isUpdatingBill: false,
  isReplacingRelease: false,
  isUpdatingPGA: false,
  isChoosingDoc: false,
  chosenDocs: {},
  notes: [],
  notesLoaded: false,
  inBondKeys: [0],
  inBondActionKeys: { 0: [0] },
  inBondBillKeys: { 0: [0] },

  isfCache: {},
  isfCountsCache: {},
  // stored in isfCountsCache
  isfSellerKeys: [0],
  isfManufacturerKeys: [0],
  isfProductKeys: { 0: [0] },
  isfMasterKeys: [0],
  isfAdditionalPartyKeys: [],

  entryCache: {},
  entryCountsCache: {},
  // stored in entryCountsCache
  masterKeys: [0],
  houseKeys: { 0: [0] },
  containerKeys: [0],
  invoiceKeys: [0],
  adjustmentKeys: { 0: [0] },
  productKeys: { 0: [{ key: 0, open: true }] },
  lineKeys: { 0: { 0: [0] } },
  // e.g. constituentElementKeys: { 0: { 0: {0: {0: {AP2: [0]}}} } },
};

export const singleUIReducer = handleActions(
  {
    SHIPMENT_CHANGE: (state) => ({ ...state, cacheValid: false }),
    SHIPMENT_CACHE: (state, { payload }) => ({
      ...state,
      cacheValid: true,
      simpleCache: payload,
    }),
    SHIPMENT_VALIDATE_CACHE: (state, { payload }) => ({
      ...state,
      cacheValid: payload,
    }),
    SHIPMENT_UNLOAD: () => singleUIDefaults,
    SHIPMENT_LOADED: (state) => ({ ...state, loaded: true }),
    SHIPMENT_SAVING: (state, { payload }) => ({ ...state, saving: payload }),
    SHIPMENT_COUNT: (state, { payload }) => ({ ...state, ...payload }),
    SHIPMENT_ADJUSTMENT_KEYS_COUNT: (state, { payload }) => ({
      ...state,
      adjustmentKeys: { ...state.adjustmentKeys, ...payload },
    }),
    SHIPMENT_COUNT_SET: (state, { payload }) => {
      const existingKeys = JSON.parse(
        JSON.stringify(get(state, payload.key, payload.default)),
      );
      set(existingKeys, payload.countsPath, payload.newKeys);
      return { ...state, [payload.key]: existingKeys };
    },
    SHIPMENT_INVOICE: (state, { payload }) => ({
      ...state,
      activeInvoice: payload.toString(),
    }),
    SHIPMENT_ADDING_DO: (state, { payload }) => ({
      ...state,
      addingDO: payload,
    }),
    SHIPMENT_TAGGING_FORWARDER: (state, { payload }) => ({
      ...state,
      taggingForwarder: payload,
    }),
    SHIPMENT_UPDATE_UI: (state, { payload }) => ({ ...state, ...payload }),
    SHIPMENT_NOTES_ADD: (state, { payload }) => ({
      ...state,
      notes: state.notes.concat(payload),
      notesLoaded: true,
    }),
    SHIPMENT_NOTES_LOAD: (state, { payload }) => ({
      ...state,
      notes: payload,
      notesLoaded: true,
    }),
    SHIPMENT_TOGGLE_CHOSEN_DOC: (state, { payload: docKey }) => ({
      ...state,
      chosenDocs: { ...state.chosenDocs, [docKey]: !state.chosenDocs[docKey] },
    }),
    LOGOUT: () => singleUIDefaults,
    SHIPMENT_UPDATE_IN_BOND_UI: (state, { payload }) => {
      const currentInBondsUI = get(state, ['inBonds']);
      const { inBondKey, ...inBondPayload } = payload;
      const currentInBondUI = get(currentInBondsUI, inBondKey, {});
      return {
        ...state,
        inBonds: {
          ...currentInBondsUI,
          [inBondKey]: { ...currentInBondUI, ...inBondPayload },
        },
      };
    },
  },
  singleUIDefaults,
);

export default singleUIReducer;

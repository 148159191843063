import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { RcFile } from 'antd/lib/upload';
import { merge, omit } from 'lodash';
import { client } from '@xbcb/apollo-client';
import { safeGet, StringMap } from '@xbcb/js-utils';
import { spreadsheetPreviewQuery } from '@xbcb/shared-queries';
import {
  AnyObject,
  IdVersion,
  RecordType,
  SpreadsheetActionType,
} from '@xbcb/shared-types';
import { reportError } from '@xbcb/ui-utils';
import workerScript from 'libs/spreadsheetWorker';

type SpreadsheetPayload = AnyObject & {
  recordType: RecordType;
  uploadedRecordType?: RecordType;
};

export const SPREADSHEET_LOAD_DATA = 'SPREADSHEET_LOAD_DATA';
export const SPREADSHEET_RESET = 'SPREADSHEET_RESET';
export const SPREADSHEET_SUBMIT = 'SPREADSHEET_SUBMIT';
export const SPREADSHEET_UNLOAD = 'SPREADSHEET_UNLOAD';
export const SPREADSHEET_UPLOADED = 'SPREADSHEET_UPLOADED';
export const SPREADSHEET_UPLOAD = 'SPREADSHEET_UPLOAD';

export const spreadsheetLoadData = createAction<
  SpreadsheetPayload,
  typeof SPREADSHEET_LOAD_DATA
>(SPREADSHEET_LOAD_DATA);

export const spreadsheetReset = createAction<
  SpreadsheetPayload,
  typeof SPREADSHEET_RESET
>(SPREADSHEET_RESET);

export const spreadsheetUnload = createAction<
  SpreadsheetPayload,
  typeof SPREADSHEET_UNLOAD
>(SPREADSHEET_UNLOAD);

export const spreadsheetUploaded = createAction<
  SpreadsheetPayload,
  typeof SPREADSHEET_UPLOADED
>(SPREADSHEET_UPLOADED);

export const spreadsheetUpload =
  (
    payload: SpreadsheetPayload & { file: RcFile },
  ): ((dispatch: Dispatch) => Promise<StringMap[]>) =>
  async (dispatch: Dispatch) =>
    new Promise((resolve, reject): void => {
      const { file, recordType } = payload;
      const uploadPayload = omit(payload, 'file');
      try {
        const fileName = file.name;
        const myWorker = new Worker(workerScript);
        myWorker.onmessage = (evt) => {
          myWorker.terminate();
          const rows = evt.data;
          dispatch(
            spreadsheetLoadData({
              ...uploadPayload,
              fileName,
              recordType,
              rows,
            }),
          );
          resolve(evt.data);
        };
        myWorker.postMessage(file);
      } catch (e) {
        reportError(e);
        // eslint-disable-next-line prefer-promise-reject-errors
        reject([]);
      }
    });

type Values = {
  // Dictates the action that we take if we encounter a duplicate record
  duplicateAction?: SpreadsheetActionType;
  // Indicates if the spreadsheet contains headers
  hasHeaders: boolean;
  // Invoice number of the commercial invoice being uploaded
  invoiceNumber?: string;
  // Indicates if the selected supplier should be used to overwrite
  // the supplier for all of the uploaded records
  sameSupplier?: boolean;
  // The shipper related to the records being uploaded
  shipper: IdVersion;
  // The seller of the products selected by the user
  seller?: IdVersion;
};

export const spreadsheetPreview =
  ({
    recordType,
    record,
    values,
  }: SpreadsheetPayload & { record?: AnyObject; values: Values }) =>
  async (dispatch: Dispatch, getState: () => AnyObject) => {
  return new Promise(async (resolve, reject) => { // eslint-disable-line
      try {
        const state = getState();
        const isUsConsumptionEntry =
          recordType === RecordType.US_CONSUMPTION_ENTRY;
        const uploadedRecordType = isUsConsumptionEntry
          ? RecordType.PRODUCT
          : recordType;
        let { duplicateAction, hasHeaders, seller, shipper, sameSupplier } =
          values;

        if (isUsConsumptionEntry) {
          shipper = {
            id: safeGet(record, 'group.shipper.id'),
          };
        }

        const columns = omit(values, [
          'invoiceNumber',
          'sameSupplier',
          'duplicateAction',
          'hasHeaders',
        ]);
        const rows = safeGet(
          state,
          ['ui', 'spreadsheet', recordType, 'rows'],
          [],
        );
        const input = {
          hasHeaders,
          duplicateAction,
          columns,
          rows,
          shipper,
        };

        // Provide the seller only if "sameSupplier" is marked as "true" and the record type is NOT suppliers
        if (recordType !== RecordType.SUPPLIER) {
          merge(input, {
            supplier: (sameSupplier && seller?.id && seller) || null,
          });
        }

        const previewResponse = await client.query({
          query: spreadsheetPreviewQuery(uploadedRecordType),
          variables: {
            input,
          },
          fetchPolicy: 'network-only',
        });
        resolve(previewResponse);
      } catch (e) {
        reportError(e);
        reject(e);
      }
    });
  };

import { RecordType } from '@xbcb/shared-types';
import { recordTypeToPath } from 'libs/recordTypeToPath';
import { UploadSpreadsheet } from './loadableComponents';
import { AppRecordRouteBaseProps, AppRecordUploadProps } from './types';

export type AppRecordUploadRoute = AppRecordRouteBaseProps &
  AppRecordUploadProps;

const Page = UploadSpreadsheet;

export const appRecordUploadRoutes: AppRecordUploadRoute[] = [
  {
    Page,
    fields: 'id',
    recordType: RecordType.PRODUCT,
    title: 'Upload Products',
  },
  {
    Page,
    fields: `
      group {
        shipper {
          id
        }
      }
      tasks {
        id
        status
        subStatus
        isException
        definition {
          id
          workOrderTaskType
        }
      }
      invoices {
        invoiceNumber
        seller {
          supplier {
            id
          }
        }
      }
   `,
    recordType: RecordType.US_CONSUMPTION_ENTRY,
    title: 'Upload a Commercial Invoice',
    path: `${recordTypeToPath(
      RecordType.US_CONSUMPTION_ENTRY,
    )}/:recordId/upload`,
  },
  {
    Page,
    fields: 'id',
    recordType: RecordType.SUPPLIER,
    title: 'Upload Suppliers',
  },
];

import { modifyWorkOrderTaskStepsCompletionMutation } from '@xbcb/shared-queries';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { findMatchingWorkOrderTask } from 'libs/findMatchingWorkOrderTask';
import {
  workOrderTaskFields,
  workOrderTaskFragment,
  workOrderTaskQuery,
} from 'libs/sharedQueries';
import { fetchLatestRecordAndExecuteMutation } from '../../../libs/fetchLatestRecordAndExecuteMutation';

type TransactModifyWorkOrderTaskStepsCompletionProps = {
  workOrderTask: AnyObject;
};

const requestModifyWorkOrderTaskStepsCompletion = async ({
  workOrderTask,
}: TransactModifyWorkOrderTaskStepsCompletionProps) => {
  const { id: taskId, steps } = workOrderTask;
  const modifiedWorkOrderTaskStepInput = steps.map((step: AnyObject) => {
    let isCompleted = true;
    if (step.isCompletedBySystem) isCompleted = Boolean(step.completed);
    return { stepId: step.id, isCompleted };
  });

  const constructVariablesWithIdVersion = (id: string, version: number) => ({
    taskId: id,
    steps: modifiedWorkOrderTaskStepInput,
    version: version,
  });

  return fetchLatestRecordAndExecuteMutation({
    id: taskId,
    recordType: RecordType.WORK_ORDER_TASK,
    mutation: modifyWorkOrderTaskStepsCompletionMutation({
      fields: workOrderTaskFields,
      fragments: workOrderTaskFragment,
    }),
    constructVariablesWithIdVersion,
    refetchQueries: [
      {
        query: workOrderTaskQuery,
        variables: { id: taskId },
      },
    ],
    successMessage: 'All non-system only steps have been completed.',
  });
};

type FindWorkOrderTaskAndModifyStepCompletionProps = {
  workOrder: AnyObject;
  workOrderTaskType: string;
};

export const findWorkOrderTaskAndModifyStepCompletion = async ({
  workOrder,
  workOrderTaskType,
}: FindWorkOrderTaskAndModifyStepCompletionProps) => {
  const workOrderTask = findMatchingWorkOrderTask(workOrder, workOrderTaskType);
  await requestModifyWorkOrderTaskStepsCompletion({
    workOrderTask,
  });
};

import React from 'react';
import { FormItem, Select } from '@xbcb/form-item-components';
import { ConditionalOnRemarksCodes } from '../types';
import { useTheme } from 'styled-components';

export const BondExemptionStatus: ConditionalOnRemarksCodes = ({
  localNamePath,
  importCode,
  industryCode,
  required,
}) => {
  const theme = useTheme();
  const shouldShow = isBondExemptionStatusRequired(importCode, industryCode);

  return shouldShow ? (
    <FormItem
      label="Bond Exemption Status"
      name={[...localNamePath, 'vneBondExemptionNumber']}
      $itemSize={theme.size.short_medium}
      rules={[{ required }]}
    >
      <Select
        aria-label={'Bond Exemption Status Code Select'}
        options={BOND_EXEMPTION_STATUS_OPTIONS}
      />
    </FormItem>
  ) : null;
};

const BOND_EXEMPTION_STATUS_OPTIONS = [
  { value: 'E1Y', label: 'The engine is exempt from Bond' },
  { value: 'E1N', label: 'The engine is not exempt from Bond' },
];

const isBondExemptionStatusRequired = (
  importCode: string,
  industryCode: string,
): boolean => {
  if (importCode === '22') {
    return true;
  }

  return importCode === '1' && industryCode === 'G';
};

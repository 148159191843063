import styled from 'styled-components';
import { Tag } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

export const StyledDiv = styled.div`
  font-size: ${({ theme }) => theme.font.size.large};

  .anticon:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing.space_4};
  }
`;

export const StyledWarningOutline = styled(WarningOutlined)`
  color: var(--primary-color);
`;

export const StyledSpan = styled.span`
  margin-left: ${({ theme }) => theme.spacing.space_4};
`;

export const StyledTag = styled(Tag)`
  margin-left: ${({ theme }) => theme.spacing.space_2};
`;

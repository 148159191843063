import { AnyObject } from '@xbcb/shared-types';
import { HTS_REGEX, INVALID_HTS_CHARACTERS_REGEX } from './constants';
import moment from 'moment';
import { isHtsValidForEffectiveDate } from '../validateEffectiveHTS';

/**
 * TODO: After HTS automation UAT, remove any references to the old
 * HTS object shape (e.g. obj.text instead of obj.id)
 */

export const getHtsSuggestions = (
  rawHtsNumber: string,
  htsHierarchy: AnyObject,
  effectiveDate?: moment.Moment,
): string[] => {
  if (INVALID_HTS_CHARACTERS_REGEX.test(rawHtsNumber)) {
    // Sanity check; this case can't happen in our existing code
    return [];
  }
  const segments = rawHtsNumber.match(HTS_REGEX);
  if (!segments) return [];
  const [chapter, heading, subHeading, product, suffix] = segments;
  const suggestions = [];
  if (chapter && htsHierarchy[chapter]) {
    const chapterObj = htsHierarchy[chapter];
    if (heading && chapterObj[heading]) {
      const headingObj = chapterObj[heading];
      if (subHeading && headingObj[subHeading]) {
        const subHeadingObj = headingObj[subHeading];
        if (product && subHeadingObj[product]) {
          const productObj = subHeadingObj[product];
          if (suffix && productObj[suffix]) {
            suggestions.push(
              `${chapter}${heading}.${subHeading}.${product}${suffix}`,
            );
          } else if (!productObj.text && !productObj.id) {
            Object.keys(productObj).forEach((s) => {
              if (
                shouldSuggest(
                  htsHierarchy[chapter][heading][subHeading][product][s],
                  effectiveDate,
                )
              ) {
                suggestions.push(
                  `${chapter}${heading}.${subHeading}.${product}${s}`,
                );
              }
            });
          } else {
            if (productObj.text || productObj.id) {
              // no suffix (98 and 99)
              if (
                shouldSuggest(
                  htsHierarchy[chapter][heading][subHeading][product],
                )
              ) {
                suggestions.push(
                  `${chapter}${heading}.${subHeading}.${product}`,
                );
              }
            }
          }
        } else {
          Object.keys(subHeadingObj).forEach((p) => {
            const productObj = subHeadingObj[p];
            if (productObj.text || productObj.id) {
              // no suffix (98 and 99)
              if (
                shouldSuggest(htsHierarchy[chapter][heading][subHeading][p])
              ) {
                suggestions.push(`${chapter}${heading}.${subHeading}.${p}`);
              }
            } else {
              Object.keys(productObj).forEach((s) => {
                if (!product || p.startsWith(product)) {
                  if (
                    shouldSuggest(
                      htsHierarchy[chapter][heading][subHeading][p][s],
                      effectiveDate,
                    )
                  ) {
                    suggestions.push(
                      `${chapter}${heading}.${subHeading}.${p}${s}`,
                    );
                  }
                }
              });
            }
          });
        }
      } else {
        Object.keys(headingObj).forEach((s) => {
          if (!subHeading || s.startsWith(subHeading)) {
            suggestions.push(`${chapter}${heading}.${s}`);
          }
        });
      }
    } else {
      Object.keys(chapterObj).forEach((h) => {
        if (!heading || h.startsWith(heading)) {
          suggestions.push(`${chapter}${h}`);
        }
      });
    }
  } else {
    Object.keys(htsHierarchy).forEach((c) => {
      if (!chapter || c.startsWith(chapter)) {
        suggestions.push(`${c}`);
      }
    });
  }
  return suggestions;
};

const shouldSuggest = (htsObj: AnyObject, effectiveDate?: moment.Moment) => {
  if (!effectiveDate) {
    return true;
  }
  return isHtsValidForEffectiveDate(htsObj, effectiveDate);
};

import React from 'react';
import PropTypes from 'prop-types';
import './DelayedTooltip.less';
import { Tooltip } from 'antd';

const DelayedTooltip = ({ children, ...otherProps }) => (
  <Tooltip {...otherProps}>{children}</Tooltip>
);

DelayedTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
  mouseEnterDelay: PropTypes.number,
};

DelayedTooltip.defaultProps = {
  mouseEnterDelay: 1,
};

export default DelayedTooltip;

import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Select, Tooltip } from 'antd';
import DecimalQuantity from 'components/DecimalQuantity';
import { getCodes, selectFilter } from '@xbcb/ui-utils';
import { times } from 'lodash';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { FormItem } from '@xbcb/form-item-components';
import {
  StyledFlexDiv,
  StyledHeader,
  StyledInfoCircleOutlined,
  StyledLevelDiv,
  StyledLevelSpan,
  StyledMinusButton,
} from './styles';

const { Option } = Select;

type UomCode = string;
type DisplayName = string;

export type PackagingUomConfig = {
  [key: UomCode]: DisplayName;
};

type PackagingLevelType = 'base' | 'unit';

interface ProductPackagingProps {
  disabled?: boolean;
  readOnly?: boolean;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  form?: FormInstance;
  required?: boolean;
  optional?: any;
  programCode?: any;
  packagingLevelsOverride?: any;
  agencyCode: 'FDA' | 'APH' | 'EPA';
  uomConfig?: PackagingUomConfig;
}

const ProductPackaging = ({
  fullNamePath,
  localNamePath,
  disabled,
  readOnly,
  required,
  optional,
  programCode,
  packagingLevelsOverride,
  agencyCode,
  uomConfig,
}: ProductPackagingProps) => {
  const form = Form.useFormInstance();
  const codes = getCodes();

  // TODO: refactor to use Form.List
  const packagingArray = form.getFieldValue(fullNamePath);
  const [numLevels, setNumLevels] = useState(
    (packagingArray && packagingArray.length) ||
      packagingLevelsOverride ||
      (required ? 1 : 0),
  );

  const pgaCodes = codes?.CBP?.CATAIR?.PGA;

  const aphUoms: PackagingUomConfig = pgaCodes?.aphPackaging;
  const aphUomOptions = Object.entries(aphUoms).map(toUomOption);

  const fdaUomOptions = (levelType: PackagingLevelType) => {
    const packagingLevelConfig: Record<UomCode, DisplayName> =
      pgaCodes.fdaPackaging[levelType];

    const withDisplayName = (code: UomCode): [UomCode, DisplayName] => {
      const displayName = packagingLevelConfig[code];
      return [code, displayName];
    };

    const programSpecificCodes: UomCode[] =
      pgaCodes.allowedFDAPackaging[programCode]?.[levelType];

    const uomEntries = programSpecificCodes
      ? programSpecificCodes.map(withDisplayName)
      : Object.entries(packagingLevelConfig);

    return uomEntries.map(toUomOption);
  };

  const getUomOptions = (levelType: PackagingLevelType) => {
    if (uomConfig) {
      return Object.entries(uomConfig).map(toUomOption);
    }

    if (agencyCode === 'APH') {
      return aphUomOptions;
    }

    if (agencyCode === 'FDA') {
      return fdaUomOptions(levelType);
    }

    return [];
  };

  return (
    <div>
      <StyledFlexDiv>
        <StyledHeader>Product Packaging</StyledHeader>
        {optional ? (
          <Tooltip
            placement="right"
            title="Although optional, transmitting these accurately will assist in reviewing the product in a timely manner. Omitting them may result in delays while the missing information is obtained."
          >
            <div> (Optional)</div>
          </Tooltip>
        ) : (
          ' (Required)'
        )}
        <Tooltip
          placement="right"
          title="Outermost (largest=1) packages to the innermost (smallest=6) packages. There can be up to 6 levels of packaging. If reporting only one level, show the total quantity for the item and report that as level 1. Add the appropriate number of levels before inputing data."
        >
          <StyledInfoCircleOutlined />
        </Tooltip>
      </StyledFlexDiv>
      {times(numLevels, (index) => {
        const isLastLevel = index + 1 === numLevels;
        const packagingLevelType: PackagingLevelType = isLastLevel
          ? 'base'
          : 'unit';
        const uomOptions = getUomOptions(packagingLevelType);

        return (
          <StyledLevelDiv key={index}>
            <StyledLevelSpan $firstLevel={index === 0}>
              Level {index + 1}
            </StyledLevelSpan>
            <DecimalQuantity
              disabled={disabled}
              readOnly={readOnly}
              required={true}
              hideLabel={index !== 0}
              field={[...localNamePath, index, 'value']}
              $itemSize={CssSize.MICRO_TINY}
              max={9999999999}
            />
            <FormItem
              label={
                index === 0 ? 'Unit of Measure (Packaging Level)' : undefined
              }
              $itemSize={CssSize.SHORT}
              name={[...localNamePath, index, 'unit']}
              rules={[{ required: true, message: ' ' }]}
              $readOnly={readOnly}
              $inline
              required={true}
            >
              <Select
                aria-label={`Packaging UOM - ${index}`}
                disabled={disabled}
                showSearch
                allowClear
                filterOption={selectFilter}
                notFoundContent="None Found"
                placeholder={isLastLevel ? 'Base unit' : 'Container'}
              >
                {uomOptions}
              </Select>
            </FormItem>
            {!readOnly &&
              isLastLevel && // only last level is deletable
              (!required || index !== 0) && ( // if packaging is required, can't delete first level.
                <StyledMinusButton
                  disabled={disabled}
                  label={`level ${index + 1}`}
                  onRemove={() => {
                    form.setFields([
                      {
                        name: [...fullNamePath, numLevels - 2, 'unit'],
                        value: undefined,
                      },
                    ]);
                    setNumLevels(numLevels - 1);
                  }}
                />
              )}
          </StyledLevelDiv>
        );
      })}
      {!readOnly && numLevels <= 5 && (
        <Button
          type="dashed"
          disabled={disabled}
          onClick={() => {
            form.setFields([
              {
                name: [...fullNamePath, numLevels - 1, 'unit'],
                value: undefined,
              },
            ]);
            setNumLevels(numLevels + 1);
          }}
        >
          <PlusOutlined /> Level
        </Button>
      )}
    </div>
  );
};

const toUomOption = ([code, displayName]: [UomCode, DisplayName]) => (
  <Option key={code} value={code}>{`${code} - ${displayName}`}</Option>
);

export default ProductPackaging;
